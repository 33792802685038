import { useState, memo, useCallback, useEffect } from 'react';
import no_image from 'assets/no_image.png';
import ContentBodyMat from 'shared/components/atoms/ContentBodyMat';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import { Content } from 'shared/components/molecules/ContentsArea';
import styles from 'shared/styles/styles';
import { dateToYMDHMS } from 'shared/utils/converter/date';
import styled from 'styled-components';
import {
  EdgeDevice,
  RequestEdgeDeviceKeyPut,
  edgeDevicesKeyGetAPI,
  edgeDevicesKeyPutAPI,
  edgeDevicesKeyThumbnailGetAPI,
} from 'user/api/edgeDevices';
import { toEdgeDeviceStatusJP } from 'shared/models/EdgeDeviceStatus';
import { ISO8601, toISO8601 } from 'shared/models/ISO8601';
import { getSpecifiedMinute } from 'shared/utils/get';
import delay from 'shared/utils/else/delay';

// -- type declaration --

type Params = {
  edge_device?: EdgeDevice;
};

// -- external functions --

// -- main component --
const EdgeDevicesOverviews = memo(function DevicesIdDetailOverviews({ edge_device }: Params) {
  // -- render part --
  return (
    <Content>
      {edge_device ? (
        <ContentBodyMat flexible={true}>
          <LeftComponent edge_device={edge_device} />
          <RightComponent edge_device={edge_device} />
        </ContentBodyMat>
      ) : (
        <Spinner />
      )}
    </Content>
  );
});

// 左側の要素
const LeftComponent = ({ edge_device }: { edge_device: EdgeDevice }) => {
  const [now, setNow] = useState<ISO8601>(toISO8601(Number(getSpecifiedMinute(0)) * 1000));
  const [heartbeat_at, setHeartbeatAt] = useState<ISO8601>(edge_device.heartbeat_at);
  useEffect(() => {
    // 30秒ごとに現在時刻nowを更新
    const interval = setInterval(async () => {
      const currentTime = toISO8601(Date.now());
      setNow(currentTime);
      const res = await edgeDevicesKeyGetAPI({ edge_device_key: edge_device.edge_device_key });
      if (res.status === 200) {
        setHeartbeatAt(res.data.heartbeat_at);
      }
    }, 30000);

    return () => clearInterval(interval);
  });
  return (
    <RoundedCardLeftArea>
      <TextsArea>
        <TextArea>
          <div>デバイス名</div>
          <ValueText>{edge_device.heartbeat_edge_device_name}</ValueText>
        </TextArea>
        <TextArea>
          <div>バージョン</div>
          <ValueText>{edge_device.app_version}</ValueText>
        </TextArea>
        <TextArea>
          <div>ステータス</div>
          <ValueText>{toEdgeDeviceStatusJP({ now: now, edge_device_heartbeat_at: heartbeat_at })}</ValueText>
        </TextArea>
        <TextArea>
          <div>ハートビート時刻</div>
          <ValueText>{dateToYMDHMS(heartbeat_at)}</ValueText>
        </TextArea>
      </TextsArea>
    </RoundedCardLeftArea>
  );
};

// 右側の要素
const RightComponent = ({ edge_device }: { edge_device: EdgeDevice }) => {
  // local_states
  const [image, setImage] = useState<string | undefined>('');
  const [load_image, setLoadImage] = useState<boolean>(false);

  const getNowImage = async (bbox?: boolean) => {
    setLoadImage(true);
    const param: RequestEdgeDeviceKeyPut = { edge_device_key: edge_device.edge_device_key };
    if (bbox) {
      param['immediate_image_acquisition_bbox'] = 'True';
    } else {
      param['immediate_image_acquisition'] = 'True';
    }
    const res = await edgeDevicesKeyPutAPI(param);
    if (res.status === 200) {
      await delay(10);
      await getImage();
      setLoadImage(false);
    }
    setLoadImage(false);
  };

  const getImage = useCallback(async () => {
    if (!edge_device) return;
    let return_thumbnail = '';
    const res = await edgeDevicesKeyThumbnailGetAPI({ edge_device_key: edge_device.edge_device_key });
    if (res.status === 200) {
      const blob = new Blob([res.data], { type: 'image/png' });
      const url = window.URL || window.webkitURL;
      return_thumbnail = url.createObjectURL(blob);
      setImage(return_thumbnail);
    }
  }, [edge_device]);

  useEffect(() => {
    getImage();
  }, []); /* eslint-disable-line */

  return (
    <RoundedCardRightArea>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <RoundedButton
          text='カメラ画像取得'
          onClick={() => getNowImage(false)}
          style={{
            width: 140,
            height: styles.super_small_button_height,
            marginBottom: 7,
          }}
        />
        <RoundedButton
          text='BOX画像取得'
          onClick={() => getNowImage(true)}
          style={{
            width: 140,
            height: styles.super_small_button_height,
            marginBottom: 7,
          }}
        />
      </div>
      {!load_image ? (
        <Img src={image || no_image} />
      ) : (
        <div style={{ maxWidth: '580px', position: 'relative', top: '100px' }}>
          <Spinner />
        </div>
      )}
    </RoundedCardRightArea>
  );
};

// -- styled components --
const RoundedCardLeftArea = styled.div`
  width: 280px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: ${styles.interval_margin};
`;

const RoundedCardRightArea = styled.div`
  width: calc(100% - 280px - ${styles.interval_margin});
  height: auto;
  display: flex;
  flex-direction: column;
`;

const TextsArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const ValueText = styled.div`
  margin-top: 5px;
  font-weight: bold;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  max-width: 580px;
  max-height: 360px;
`;

// -- finally export part --
export default EdgeDevicesOverviews;
