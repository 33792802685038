import React, { useCallback, useEffect, useState } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import history from 'shared/browserHistory';
import { PageWholeArea, Title, TopArea } from 'shared/components/molecules/ContentsArea';
import PageSwitchingTabs from 'shared/components/molecules/PageSwitchingTabs';
import { Stream, streamsIdGetAPI } from 'user/api/streams';
import { systemSelector } from 'user/redux/slices/systemSlice';
import FileProcessesOverviews from './FileProcessesOverviews/FileProcessesOverviews';
import FileProcessesResults from './FileProcessesResults/FileProcessesResults';
import { useStreamDataItems } from 'user/hooks/useStreamDataItems/useStreamDataItems';
import { useChannel } from 'user/hooks/useChannel/useChannel';

// -- main component --

const FileProcessesIdDetail: React.FC<
  RouteComponentProps<{
    channel_id: string;
    panel_id: string;
  }>
> = (params) => {
  const system_state = useSelector(systemSelector);

  // -- local states --
  const { channel, loadChannel } = useChannel({ channel_id: params.match.params.channel_id });
  const [stream, setStream] = useState<Stream | null>(null);
  const { stream_data_items, loadStreamDataItems } = useStreamDataItems({ stream_id: stream?.stream_id ?? '' });

  // -- handlers --

  // ストリームをロードする
  const loadStream = useCallback(async () => {
    let stream_id = '';
    if (channel) {
      stream_id = channel.input_stream_ids.length > 0 ? channel.input_stream_ids[0] : '';
    }
    // それでもstream_idが存在しなければreturn
    if (!stream_id) {
      return;
    }
    const res = await streamsIdGetAPI({ stream_id: stream_id });
    if (res.status === 200) {
      setStream(res.data);
    }
  }, [channel]);

  /** タブをクリックした時の関数**/
  const handleTabsClick = useCallback((tab_id: string, channel_id: string) => {
    history.push(`/file-processes/${channel_id}/${tab_id}`);
  }, []);

  // マウント時のロード(ストリーム)
  useEffect(() => {
    void (async function () {
      await loadStream();
    })();
  }, [loadStream]);

  // -- render part --
  return (
    <PageWholeArea data-testid='FileProcessesIdDetail'>
      <TopArea>
        <Title text={channel ? channel.channel_name : ''} />
      </TopArea>

      <React.Fragment>
        <PageSwitchingTabs
          display_datas={[
            { id: 'overviews', text: 'データ概要' },
            { id: 'results', text: '推論結果一覧' },
          ]}
          handleClick={(id: string) => handleTabsClick(id, params.match.params.channel_id)}
          selected_id={params.match.params.panel_id}
          initial_selected_id='overviews'
        />
        {!system_state.loading.preloading && channel && stream && (
          <Switch>
            <Route
              exact
              path='/file-processes/:channel_id/overviews'
              render={() => (
                <FileProcessesOverviews
                  channel={channel}
                  stream={stream}
                  onStreamChanged={loadChannel}
                  loadStreamDataList={loadStreamDataItems}
                  stream_data_list={stream_data_items}
                  loadChannel={loadChannel}
                  loadStream={loadStream}
                />
              )}
            />

            <Route
              exact
              path='/file-processes/:channel_id/results'
              render={() => <FileProcessesResults streamDataList={stream_data_items} stream={stream} />}
            />
          </Switch>
        )}
      </React.Fragment>
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default FileProcessesIdDetail;
