import React from 'react';
import { Spinner } from '@blueprintjs/core';
import { Route, Switch, RouteComponentProps } from 'react-router';
import history from 'shared/browserHistory';
import InputBox from 'shared/components/atoms/InputBox';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import { SelectData } from 'shared/components/atoms/SelectBox';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import { LiveTextSettingPaneValues } from 'shared/components/molecules/LiveTextPropertiesPane';
import PageSwitchingTabs from 'shared/components/molecules/PageSwitchingTabs';
import { WidgetGraphSettingPanel } from 'shared/components/molecules/WidgetSettingPanels/WidgetGraphSettingPanel';
import { widgetPutValidator } from 'user/utils/validators/widgets';
import {
  AreaCountSetting,
  dashboardsIdWidgetsNumPutAPI,
  DashboardWidget,
  Metric,
  SumUpGraphSettings,
} from 'user/api/dashboardsWidgets';
import { CachedStreams, Stream } from 'user/api/streams';
import DashboardWidgetDataInformation from 'user/pages/Dashboards/DetailPage/DashboardWidgetDataInformation/DashboardWidgetDataInformation';
import { GraphSettingPanelValues } from '../components/GraphSettingPanel';
import { AreaCountSettingPanelValues } from '../components/AreaCountSettingPanel';
import { MetricGraphType } from 'shared/models/MetricGraphType';
import { SumUpGraphSettingPanelValues } from '../components/SumUpGraphSettingPanel';

type DetailDashboardWidgetDialogProps = RouteComponentProps<{
  dashboard_id: string;
  panel_id?: string;
}> & {
  isOpen: boolean;
  onClose: (isCanceled: boolean) => void;
  widget: DashboardWidget;
  metricStreams: CachedStreams;
  playerStreams: CachedStreams;
};
interface DetailDashboardWidgetDialogState {
  dashboard_widget_name: string;
  metrics?: Metric[];
  metricStreams?: Stream[];
  playerStreams?: Stream[];
  html?: string;
  html_script?: string;
  create_graph: boolean;
  graph_type?: MetricGraphType;
  jsonpath_group_id?: string;
  player_stream_id?: string;
  od_stream_id?: string;
  od_age?: boolean;
  od_gender?: boolean;
  hm_stream_id?: string;
  gp_stream_id?: string;
  ac_stream_id?: string;
  area_count_settings?: AreaCountSetting[];
  sum_up_settings?: SumUpGraphSettings;
  y_min?: string;
  y_max?: string;
}
export default class DetailDashboardWidgetDialog extends React.PureComponent<
  DetailDashboardWidgetDialogProps,
  DetailDashboardWidgetDialogState
> {
  constructor(props: DetailDashboardWidgetDialogProps) {
    super(props);
    this.state = {
      dashboard_widget_name: props.widget.dashboard_widget_name,
      metrics: props.widget.metrics,
      html: props.widget.html,
      html_script: props.widget.html_script,
      create_graph: true,
      graph_type: props.widget.graph_type ? props.widget.graph_type : '',
      jsonpath_group_id: props.widget.jsonpath_group_id,
      player_stream_id: props.widget.player_stream_id,
      od_stream_id: props.widget.od_stream_id,
      od_age: props.widget.od_age,
      od_gender: props.widget.od_gender,
      hm_stream_id: props.widget.hm_stream_id,
      gp_stream_id: props.widget.gp_stream_id,
      ac_stream_id: props.widget.ac_stream_id,
      area_count_settings: props.widget.area_count_settings,
      sum_up_settings: props.widget.sum_up_settings,
      y_min: props.widget.widget_setting?.y_min ? String(props.widget.widget_setting.y_min) : '',
      y_max: props.widget.widget_setting?.y_max ? String(props.widget.widget_setting.y_max) : '',
    };
  }
  componentDidMount() {
    // ストリーム一覧の取得
    this.props.metricStreams.get().then((data) => {
      const new_stream_id_names: SelectData[] = [];
      data.forEach((stream_item) => {
        new_stream_id_names.push({
          value: stream_item.stream_id,
          name: stream_item.stream_name,
        });
      });
      this.setState({ metricStreams: data });
    });
    // widget_typeがVIDEOPLAYER用のストリーム一覧の取得
    this.props.playerStreams.get().then((data) => {
      // ただdata_number_typeがTIMESTAMPのみを受け付けるのでフィルタリングしてstateに加える
      const newPlayerStreams: Stream[] = data.filter((d) => {
        return d.data_number_type === 'TIMESTAMP';
      });
      this.setState({ playerStreams: newPlayerStreams });
    });
  }
  private handleFinishClick = () => {
    const {
      dashboard_widget_name,
      y_min,
      y_max,
      html,
      html_script,
      graph_type,
      jsonpath_group_id,
      player_stream_id,
      od_stream_id,
      od_age,
      od_gender,
      hm_stream_id,
      gp_stream_id,
      ac_stream_id,
      area_count_settings,
      sum_up_settings,
      metrics,
    } = this.state;
    const { widget_type, dashboard_id, dashboard_widget_number, widget_setting } = this.props.widget;
    try {
      // validation後のrequestデータ取得
      const request = widgetPutValidator({
        dashboard_id,
        dashboard_widget_name,
        dashboard_widget_number,
        widget_type,
        widget_setting: {
          ...widget_setting,
          y_min,
          y_max,
        },
        html,
        html_script,
        graph_type,
        jsonpath_group_id,
        player_stream_id,
        od_stream_id,
        od_age,
        od_gender,
        hm_stream_id,
        gp_stream_id,
        ac_stream_id,
        area_count_settings,
        sum_up_settings,
        metrics,
      });
      dashboardsIdWidgetsNumPutAPI(request).then((res) => {
        if (res.status === 200) {
          AlertDialog.show('ウィジェットの更新に成功しました');
          this.props.onClose(false);
        }
      });
    } catch (e) {
      if (e instanceof Error) {
        AlertDialog.show(e.message);
      }
      return;
    }
  };

  private handleLiveTextSettingsChange = (v: LiveTextSettingPaneValues) => {
    this.setState({ html: v.html, html_script: v.htmlScript });
  };

  private handleGraphSettingsChange = (v: GraphSettingPanelValues) => {
    const category: string | undefined = this.getCategory(this.state.gp_stream_id);
    const new_category: string | undefined = this.getCategory(v.gp_stream_id);

    if (category != new_category) {
      v.jsonpath_group_id = '';
    }
    this.setState({
      jsonpath_group_id: v.jsonpath_group_id,
      gp_stream_id: v.gp_stream_id,
      graph_type: v.graph_type,
    });
  };

  private handleAreaCountSettingChange = (v: AreaCountSettingPanelValues) => {
    this.setState({ ac_stream_id: v.ac_stream_id, graph_type: v.graph_type });
  };

  private handleSumUpGraphSettingChange = (v: SumUpGraphSettingPanelValues) => {
    this.setState({ graph_type: v.graph_type });
  };

  private handleTabsClick = (tab_id: string, dashboard_id: string) => {
    history.push(`/dashboards/${dashboard_id}/${tab_id}`);
  };

  private getCategory = (stream_id: string | undefined) => {
    let category = '';
    if (this.state.metricStreams && stream_id) {
      for (const stream of this.state.metricStreams) {
        if (stream.stream_id == stream_id) {
          category = stream.category;
        }
      }
    }
    return category;
  };

  private get getDisplayData() {
    const displays = [
      {
        id: '',
        text: 'データ情報',
      },
    ];
    if (this.props.widget.widget_type === 'GRAPH') {
      displays.push({
        id: 'graph-setting',
        text: 'グラフ設定',
      });
    }
    return displays;
  }
  render() {
    return (
      <PfDialog isOpen={this.props.isOpen} title='ウィジェット詳細' onClose={() => this.props.onClose(true)}>
        <InputComponent text='ウィジェット名' required>
          <InputBox
            placeholder='入力してください'
            value={this.state.dashboard_widget_name}
            onChange={(e) => this.setState({ dashboard_widget_name: e.currentTarget.value })}
          />
        </InputComponent>
        {!this.state.metricStreams || !this.state.playerStreams ? (
          <Spinner />
        ) : (
          <>
            <React.Fragment>
              <PageSwitchingTabs
                display_datas={this.getDisplayData}
                handleClick={(id: string) => this.handleTabsClick(id, this.props.match.params.dashboard_id)}
                selected_id={this.props.match.params.panel_id}
                initial_selected_id=''
              />
              <Switch>
                <Route
                  exact
                  path='/dashboards/:dashboard_id/'
                  render={() => (
                    <DashboardWidgetDataInformation
                      widget_type={this.props.widget.widget_type}
                      player_streams={this.state.playerStreams ?? []}
                      metric_streams={this.state.metricStreams ?? []}
                      metrics={this.state.metrics}
                      html={this.state.html}
                      html_script={this.state.html_script}
                      create_graph={this.state.create_graph}
                      graph_type={this.state.graph_type}
                      jsonpath_group_id={this.state.jsonpath_group_id}
                      category={this.getCategory(this.state.gp_stream_id)}
                      player_stream_id={this.state.player_stream_id}
                      od_stream_id={this.state.od_stream_id}
                      od_age={this.state.od_age}
                      od_gender={this.state.od_gender}
                      hm_stream_id={this.state.hm_stream_id}
                      gp_stream_id={this.state.gp_stream_id}
                      ac_stream_id={this.state.ac_stream_id}
                      area_count_settings={this.state.area_count_settings}
                      sum_up_settings={this.state.sum_up_settings}
                      onPlayerStreamIdChange={(value) => this.setState({ player_stream_id: value })}
                      onOdStreamIdChange={(value) => this.setState({ od_stream_id: value })}
                      onOdAgeChange={(value) => this.setState({ od_age: value })}
                      onOdGenderChange={(value) => this.setState({ od_gender: value })}
                      onHmStreamIdChange={(value) => this.setState({ hm_stream_id: value })}
                      onAreaCountSettingsChange={(value) => this.setState({ area_count_settings: value })}
                      onHandleAreaCountSettingChange={this.handleAreaCountSettingChange}
                      onSumUpGraphSettingsChange={(value) => this.setState({ sum_up_settings: value })}
                      onHandleSumUpGraphSettingChange={this.handleSumUpGraphSettingChange}
                      onMetricsChange={(value) => this.setState({ metrics: value })}
                      onGrahpCreateChange={(value) => this.setState({ create_graph: value })}
                      onLiveTextSettingChange={this.handleLiveTextSettingsChange}
                      onGrahpSettingChange={this.handleGraphSettingsChange}
                    />
                  )}
                />
                {this.props.widget.widget_type === 'GRAPH' && (
                  <Route
                    exact
                    path='/dashboards/:dashboard_id/graph-setting'
                    render={(_props) => (
                      <WidgetGraphSettingPanel
                        y_min={this.state.y_min}
                        y_max={this.state.y_max}
                        setYMin={(y_min) => this.setState({ y_min })}
                        setYMax={(y_max) => this.setState({ y_max })}
                      />
                    )}
                  />
                )}
              </Switch>
            </React.Fragment>

            <Footer>
              <RoundedButton onClick={() => this.props.onClose(true)} text='キャンセル' is_white is_margin_right />
              <RoundedButton onClick={this.handleFinishClick} text_type='UPDATE' />
            </Footer>
          </>
        )}
      </PfDialog>
    );
  }
}

// -- styled components --
