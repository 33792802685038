import { AxiosResponse } from 'axios';
import sendAxios from 'shared/axios/sendAxios';
import { ISO8601 } from 'shared/models/ISO8601';
import { Query } from 'shared/models/Query';
import { StatusType } from 'shared/models/StatusType';
import { getClient } from './base';
import { CommonAPIRequestType, isCommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';
import { StringBoolean } from 'shared/models/StringBoolean';

/*** エンティティ ***/

export interface NotificationCheck {
  notification_id: string;
  title?: string;
  show_date?: string;
  status: string;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

export interface NotificationsCheckWithPaging {
  items: NotificationCheck[];
  has_next: boolean;
}

const sample_notification_check_1: NotificationCheck = {
  notification_id: '00000001',
  title: 'test',
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: null,
};

const sample_notification_check_2: NotificationCheck = {
  notification_id: '00000002',
  status: 'DELETED',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
};

/*** Caching mechanism ***/

export class CachedNotificationsCheck {
  private searched = false;
  private cache: NotificationCheck[] = [];
  private params: RequestNotificationsCheckGet;
  constructor(params: RequestNotificationsCheckGet) {
    this.params = params;
  }
  async get() {
    if (!this.searched) {
      let esk: string | undefined = undefined;
      let has_next = true;
      while (has_next) {
        const res: AxiosResponse<NotificationsCheckWithPaging> = await notificationsCheckGetAPI({
          ...this.params,
          exclusive_start_notification_id: esk,
        });
        if (res.status === 200) {
          this.cache = [...this.cache, ...res.data.items];
          has_next = res.data.has_next;
          esk = res.data.items.map((d) => d.notification_id).reduce((a, b) => (a > b ? a : b), '');
        } else {
          has_next = true;
          break;
        }
      }
      this.searched = true;
    }
    return this.cache;
  }
}

/*** [GET] /api/notifications/check ***/
export interface RequestNotificationsCheckGet {
  latest?: StringBoolean;
  status?: StatusType;
  exclusive_start_notification_id?: string;
}

export const notificationsCheckGetAPI = (params: RequestNotificationsCheckGet) => {
  const { latest, status, exclusive_start_notification_id, disabled_load, disabled_error_message, ended_load } =
    toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/notifications/check`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    latest,
    status,
    exclusive_start_notification_id,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };
  // 送信
  return sendAxios<NotificationsCheckWithPaging>(axios, path, query, form, method, {
    items: [sample_notification_check_1, sample_notification_check_2],
    has_next: false,
  });
};

/*** [POST] /api/notifications/{notification_id}/check ***/

export interface RequestNotificationsIdCheckPost extends CommonAPIRequestType {
  notification_id: string;
}

export const notificationsIdCheckPostAPI = (params: RequestNotificationsIdCheckPost) => {
  const { disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/notifications/{notification_id}/check`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (isCommonAPIRequestType(key)) {
      continue;
    }
    form.append(key, value);
  }

  // 送信
  return sendAxios<NotificationCheck>(axios, path, query, form, method, sample_notification_check_1);
};
