import React, { useState } from 'react';

import { Redirect } from 'react-router-dom';
import id_svg from 'assets/id.svg';
import pw_svg from 'assets/pw.svg';
import { useSelector } from 'react-redux';
import history from 'shared/browserHistory';
import FunctionalText from 'shared/components/atoms/FunctionalText';
import InputBox from 'shared/components/atoms/InputBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import {
  LoginArea,
  LoginBackGround,
  LoginLogo,
  LoginBaseArea,
  LoginNormalText,
} from 'shared/components/molecules/ContentsArea';
import InputBoxWithIcon from 'shared/components/molecules/InputBoxWithIcon';
import styles from 'shared/styles/styles';
import { isNotOnlySpace } from 'shared/utils/is';
import styled from 'styled-components';
import { accountsForgotpasswordConfirmPostAPI, accountsForgotpasswordPostAPI } from 'user/api/accounts';
import { authSelector } from 'user/redux/slices/authSlice';

// -- main component --

const LoginForgotPassword: React.FC = () => {
  // -- redux preparations --
  const auth_state = useSelector(authSelector);

  // -- local states --
  const [login_id, setLoginId] = useState('');
  const [confirmation_code, setConfirmationCode] = useState('');
  const [password, setPassword] = useState('');
  const [password_confirmation, setPasswordConfirmation] = useState('');
  const [step, setStep] = useState(0);

  // -- handlers --
  const handleSubmit = async (request_data: { login_id: string }) => {
    if (!request_data.login_id) {
      AlertDialog.show('入力欄に空欄があります');
      return;
    }
    const res = await accountsForgotpasswordPostAPI(request_data);
    if (res.status === 200) {
      AlertDialog.show(
        <div>
          入力したユーザー名に紐づいたメールアドレスに
          <br />
          認証コードを送信しました
        </div>,
      );
      setStep(1);
    }
  };

  const handleFinished = async (
    request_data: {
      password: string;
      confirmation_code: string;
      login_id: string;
    },
    password_confirmation: string,
  ) => {
    if (!request_data.password || !password_confirmation || !request_data.confirmation_code) {
      AlertDialog.show('入力欄に空欄があります');
      return;
    }

    if (request_data.password !== password_confirmation) {
      AlertDialog.show('新しいパスワードと新しいパスワード確認用が一致しません');
      return;
    }

    const res = await accountsForgotpasswordConfirmPostAPI(request_data);
    if (res.status === 200) {
      history.push(`/password/changed`);
    }
    return;
  };

  const handleTransitionToLoginClick = () => {
    history.push(`/login`);
  };

  // -- render part --
  return auth_state.is_logged_in ? (
    <Redirect to='/dashboards' />
  ) : (
    <LoginBackGround data-testid='LoginForgotPassword'>
      <LoginArea>
        {step === 0 ? (
          <>
            <LoginLogoArea step={step}>
              <LoginLogo />
            </LoginLogoArea>
            <TextArea>
              <LoginNormalText>ログインIDを入力してください</LoginNormalText>
              <LoginNormalText>パスワードのリセット方法をメールで送信します</LoginNormalText>
            </TextArea>
            <InputArea>
              <InputBoxWithIcon
                icon_src={id_svg}
                text='ログインID'
                handleChangeClick={(e: React.ChangeEvent<HTMLInputElement>) => setLoginId(e.currentTarget.value)}
                value={login_id}
                placeholder='ログインID'
              />
            </InputArea>
            <OptionArea>
              <FunctionalText text='ログイン画面に戻る' onClick={handleTransitionToLoginClick} />
            </OptionArea>
            <ButtonArea step={step}>
              <RoundedButton
                text='パスワードをリセット'
                onClick={() => handleSubmit({ login_id })}
                disabled={!isNotOnlySpace(login_id) ? true : false}
              />
            </ButtonArea>
          </>
        ) : (
          <>
            <LoginLogoArea step={step}>
              <LoginLogo />
            </LoginLogoArea>
            <TextArea>
              <LoginNormalText>認証コード・新しいパスワードを入力してください</LoginNormalText>
            </TextArea>
            <InputArea>
              <InputBox
                title='認証コード'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmationCode(e.currentTarget.value)}
                value={confirmation_code}
                placeholder='認証コード'
                style={{ marginBottom: styles.interval_margin }}
              />
              <InputBoxWithIcon
                icon_src={pw_svg}
                text='新しいパスワード'
                handleChangeClick={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.currentTarget.value)}
                value={password}
                placeholder='新しいパスワード'
                style={{ marginBottom: styles.interval_margin }}
                password
              />
              <InputBoxWithIcon
                icon_src={pw_svg}
                text='新しいパスワード(確認用)'
                handleChangeClick={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPasswordConfirmation(e.currentTarget.value)
                }
                value={password_confirmation}
                placeholder='新しいパスワード(確認用)'
                password
              />
            </InputArea>
            <ButtonArea step={step}>
              <RoundedButton
                text='パスワードの再設定'
                onClick={() =>
                  handleFinished(
                    {
                      password: password,
                      confirmation_code: confirmation_code,
                      login_id: login_id,
                    },
                    password_confirmation,
                  )
                }
                disabled={
                  !isNotOnlySpace(password) ||
                  !isNotOnlySpace(confirmation_code) ||
                  !isNotOnlySpace(password_confirmation) ||
                  password !== password_confirmation
                    ? true
                    : false
                }
              />
            </ButtonArea>
          </>
        )}
      </LoginArea>
      <AlertDialog />
    </LoginBackGround>
  );
};

// -- styled components --

const LoginLogoArea = styled(LoginBaseArea)<{
  step: number;
}>`
  height: ${(params) => (params.step === 0 ? '38%' : '33%')};
  justify-content: center;
  align-items: center;
`;

const TextArea = styled(LoginBaseArea)`
  height: auto;
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${styles.interval_margin};
`;

const InputArea = styled(LoginBaseArea)`
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${styles.interval_margin};
`;

const OptionArea = styled(LoginBaseArea)`
  height: auto;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: ${styles.interval_margin};
`;

const ButtonArea = styled(LoginBaseArea)<{
  step: number;
}>`
  justify-content: center;
  height: ${(params) => (params.step === 0 ? '20%' : '15%')};
`;

// -- finally export part --

export default LoginForgotPassword;
