import React from 'react';
import PfDialog from 'shared/components/atoms/PfDialog';
import PfHlsPlayer from 'shared/components/atoms/PfHlsPlayer';
import Spinner from 'shared/components/atoms/Spinner';
import { BottomArea } from 'shared/components/molecules/ContentsArea';
import M3u8Play from 'shared/components/molecules/M3u8Play';
import 'react-datepicker/dist/react-datepicker.css';
import styles from 'shared/styles/styles';
import styled from 'styled-components';
import {
  ChannelEvent,
  channelsIdEventNumberM3u8Url,
  channelsIdEventsChannelEventNumberGetAPI,
  getChannlesIdEventsNumberDownloadGetAPIUrl,
} from 'user/api/channelsEvents';
import { streamsStreamIdVideoM3U8GetAPIUrl } from 'user/api/streams';
import EventsIdDetailsFooter from './EventsIdDetailsFooter';
import RoundedAnchorButton from 'shared/components/atoms/RoundedAnchorButton';
import RoundedButton from 'shared/components/atoms/RoundedButton';

interface EventsIdDetailsDialogProps {
  is_open: boolean;
  onClose: (cancelled?: boolean) => void;
  channel_id: string;
  channel_event_number: number;
}
interface EventsIdDetailsDialogState {
  is_loaded: boolean;
  video_url?: string;
  event?: ChannelEvent;
  // コメントか確認済みをした場合にtrueとなる
  post_api_requested: boolean;
}
export default class EventsIdDetailsDialog extends React.PureComponent<
  EventsIdDetailsDialogProps,
  EventsIdDetailsDialogState
> {
  constructor(props: EventsIdDetailsDialogProps) {
    super(props);
    this.state = {
      is_loaded: false,
      post_api_requested: false,
    };
  }
  private handleSetIsLoaded = (b: boolean) => {
    this.setState({ is_loaded: b });
  };
  private handleVideoUrl = (happened_at: string) => {
    if (this.state.event) {
      const video_clip = this.state.event.video_clip;
      if (video_clip) {
        if (video_clip.clip_status === 'PROGRESS') {
          const start = Math.floor(new Date(happened_at).getTime() / 1000);
          this.setState({
            video_url: streamsStreamIdVideoM3U8GetAPIUrl({ stream_id: video_clip.stream_id, start: start }),
          });
        } else if (video_clip.clip_status === 'READY') {
          this.setState({
            video_url: channelsIdEventNumberM3u8Url(this.state.event.channel_id, this.state.event.channel_event_number),
          });
        }
      }
    }
  };
  componentDidMount() {
    // チャンネルイベントを取得
    channelsIdEventsChannelEventNumberGetAPI({
      channel_id: this.props.channel_id,
      channel_event_number: this.props.channel_event_number,
    }).then((res) => {
      if (res.status === 200) {
        this.setState({ event: res.data });
        this.handleVideoUrl(res.data.created_at);
      }
    });
  }
  render() {
    const video_clip = this.state.event?.video_clip;
    return (
      <PfDialog
        title='チャンネルイベント詳細'
        onClose={() => this.props.onClose(!this.state.post_api_requested)}
        isOpen={this.props.is_open}
      >
        {this.state.event ? (
          <>
            <TextRowArea>
              <ChannelEventNameText>{this.state.event.channel_event_name}</ChannelEventNameText>
              <div style={{ display: 'flex' }}>
                <div>発生日時</div>
                <CreatedAtText>{this.state.event.created_at}</CreatedAtText>
              </div>
            </TextRowArea>
            {video_clip && this.state.video_url && (
              <>
                <CenterArea>
                  <VideoArea>
                    {video_clip.clip_status === 'PROGRESS' && (
                      <M3u8Play
                        video_url={this.state.video_url}
                        selected_video_date={this.state.event.created_at}
                        is_loaded={this.state.is_loaded}
                        setIsLoaded={this.handleSetIsLoaded}
                        onTimeChange={async (time: string) => this.handleVideoUrl(time)}
                        width={750}
                        is_live={false}
                      />
                    )}
                    {video_clip.clip_status === 'READY' && (
                      <PfHlsPlayer
                        url={this.state.video_url}
                        width='auto'
                        height={370}
                        controls={true}
                        loop={false}
                        // clip_statusがREADYの時は動画の開始位置をイベント発生時刻にする
                        hlsConfig={{ startPosition: this.state.event?.video_clip?.periods_before ?? undefined }}
                      />
                    )}
                  </VideoArea>
                </CenterArea>
                {this.state.event.video_clip?.video_s3_key ? (
                  <RoundedAnchorButton
                    text='ダウンロード'
                    href={getChannlesIdEventsNumberDownloadGetAPIUrl({
                      channel_id: this.props.channel_id,
                      channel_event_number: this.props.channel_event_number,
                    })}
                    style={{ marginTop: 10 }}
                  />
                ) : (
                  // ダウンロードボタンの無効時はボタン要素で描画
                  <RoundedButton text='ダウンロード' style={{ marginTop: 10 }} stop_propagation disabled={true} />
                )}
              </>
            )}
            <BottomArea>
              <EventsIdDetailsFooter
                channel_id={this.state.event.channel_id}
                channel_event_number={this.state.event.channel_event_number}
                confirmed={this.state.event.confirm !== null}
                onAPIRequested={() => this.setState({ post_api_requested: true })}
              />
            </BottomArea>
          </>
        ) : (
          <Spinner />
        )}
      </PfDialog>
    );
  }
}

const TextRowArea = styled.div`
  display: flex;
  display-direction: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${styles.interval_margin};
`;

const ChannelEventNameText = styled.div``;

const CreatedAtText = styled.div`
  margin-left: ${styles.interval_margin};
`;

const CenterArea = styled.div`
  width: 100%;
  height: 83%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VideoArea = styled.div`
  width: auto;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
