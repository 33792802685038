import { CommonAPIRequestType, isCommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';
import { ISO8601 } from 'shared/models/ISO8601';
import { Query } from 'shared/models/Query';
import { StatusType } from 'shared/models/StatusType';
import { getClient } from 'user/api/base';

import sendAxios from 'shared/axios/sendAxios';
import { AxiosResponse } from 'axios';
import { DashboardType } from 'shared/models/DashboardType';
import { TableauDataType } from 'shared/models/TableauDataType';
import { addFormData } from 'shared/utils/converter';

/*** エンティティ ***/

export interface Dashboard {
  tenant_id: string;
  dashboard_id: string;
  dashboard_name: string;
  dashboard_type: DashboardType;
  tableau_data_type: TableauDataType | null;
  tableau_workbook_url: string | null;
  status: StatusType;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
  creator_user_name: string;
}

export interface DashboardWithPaging {
  items: Dashboard[];
  has_next: boolean;
  last_dashboard_id?: string;
}

const sample_dashboard_1: Dashboard = {
  tenant_id: 'string',
  dashboard_id: '0000001',
  dashboard_name: 'dashboard1',
  dashboard_type: 'usual',
  tableau_data_type: null,
  tableau_workbook_url: null,
  status: 'ACTIVE',
  created_at: '2020-04-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-05-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  creator_user_name: '長谷川',
};

const sample_dashboard_2: Dashboard = {
  tenant_id: 'string',
  dashboard_id: '0000002',
  dashboard_name: 'dashboard2',
  dashboard_type: 'tableau',
  tableau_data_type: 'count_data',
  tableau_workbook_url: 'https://prod-apnortheast-a.online.tableau.com/t/platformtableau/views/hase2/hase-dashboard1',
  status: 'ACTIVE',
  created_at: '2020-03-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-06-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  creator_user_name: '田中',
};

/*** Caching mechanism ***/

export class CachedDashboards {
  private searched = false;
  private cache: Dashboard[] = [];
  private params: RequestDashboardsGet;
  constructor(params: RequestDashboardsGet) {
    this.params = params;
  }
  async get() {
    if (!this.searched) {
      let esk: string | undefined = undefined;
      for (;;) {
        const res: AxiosResponse<DashboardWithPaging> = await dashboardsGetAPI({
          ...this.params,
          exclusive_start_dashboard_id: esk,
        });
        if (res.status === 200) {
          this.cache = [...this.cache, ...res.data.items];
          if (!res.data.has_next) {
            break;
          } else {
            esk = res.data.last_dashboard_id;
          }
        } else {
          break;
        }
      }
      this.searched = true;
    }
    return this.cache;
  }
}

/*** [GET] /api/dashboards ***/

export interface RequestDashboardsGet {
  exclusive_start_dashboard_id?: string;
}

export const dashboardsGetAPI = (params: RequestDashboardsGet) => {
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `/api/dashboards`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};
  for (const [key, value] of Object.entries(params)) {
    query[key] = value;
  }

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<DashboardWithPaging>(axios, path, query, form, method, {
    items: [sample_dashboard_1, sample_dashboard_2],
    has_next: false,
  });
};

/*** [POST] /api/dashboards ***/

export interface RequestDashboardsPost extends CommonAPIRequestType {
  dashboard_name: string;
  dashboard_type: DashboardType;
  tableau_data_type?: TableauDataType;
}

export const dashboardsPostAPI = (params: RequestDashboardsPost) => {
  // postはデフォルトでロードする
  const { dashboard_name, dashboard_type, tableau_data_type, disabled_load, disabled_error_message, ended_load } =
    toAPIRequestParams({
      ...params,
      api_send_type: params.api_send_type ?? 'changeableOneTransmission',
    });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/dashboards`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries({ dashboard_name, dashboard_type, tableau_data_type })) {
    addFormData({ key, value, form });
  }

  // 送信
  return sendAxios<Dashboard>(axios, path, query, form, method, sample_dashboard_1);
};

/*** [GET] /api/dashboards/{dashboard_id} ***/

export interface RequestDashboardsIdGet {
  dashboard_id: string;
}

export const dashboardsIdGetAPI = (params: RequestDashboardsIdGet) => {
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `/api/dashboards/${params.dashboard_id}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<Dashboard>(axios, path, query, form, method, sample_dashboard_2);
};

/*** [PUT] /api/dashboards/{dashboard_id} ***/

export interface RequestDashboardsIdPut extends CommonAPIRequestType {
  dashboard_id: string;
  dashboard_name: string;
}

export const dashboardsIdPutAPI = (params: RequestDashboardsIdPut) => {
  // putはデフォルトでロードする
  const { dashboard_id, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/dashboards/${dashboard_id}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'dashboard_id' || isCommonAPIRequestType(key)) {
      continue;
    }
    form.append(key, value);
  }

  // 送信
  return sendAxios<Dashboard>(axios, path, query, form, method, sample_dashboard_1);
};

/*** [DELETE] /api/dashboards/{dashboard_id} ***/

export interface RequestDashboardsIdDelete extends CommonAPIRequestType {
  dashboard_id: string;
}

export const dashboardsIdDeleteAPI = (params: RequestDashboardsIdDelete) => {
  const { dashboard_id, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'multipleTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, ended_load, disabled_error_message });

  // パス・メソッドを定義
  const path = `/api/dashboards/${dashboard_id}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<Dashboard>(axios, path, query, form, method, sample_dashboard_1);
};
