// -- basic library --
import React from 'react';
import Hls from 'hls.js';
import PfHlsPlayer from 'shared/components/atoms/PfHlsPlayer';
import { Content } from 'shared/components/molecules/ContentsArea';
import styled from 'styled-components';

// -- redux library --

// -- http connection library --

// -- external components --
import M3u8PlayBarDateArea from './M3u8PlayBarDateArea';

interface M3u8PlayProps {
  video_url: string;
  selected_video_date: string;
  is_loaded: boolean;
  setIsLoaded: (bol: boolean) => void;
  onTimeChange: (time: string) => Promise<void>;
  width?: number;
  height?: number;
  is_live: boolean;
}

interface M3u8PlayState {
  hls_object: Hls | undefined;
  video_ref: React.RefObject<HTMLVideoElement>;
}

export default class M3u8Play extends React.PureComponent<M3u8PlayProps, M3u8PlayState> {
  constructor(props: M3u8PlayProps) {
    super(props);
    this.state = {
      hls_object: undefined,
      video_ref: React.createRef(),
    };
  }
  componentDidMount() {
    this.props.setIsLoaded(false);
    this.loadHls(this.props.video_url, this.state.video_ref);
  }
  componentWillUnmount() {
    this.destroyHls(this.state.hls_object);
  }

  private destroyHls = (hls: Hls | undefined) => {
    if (hls === undefined) return;
    hls.stopLoad();
    hls.detachMedia();
    hls.destroy();
    this.setState({
      hls_object: hls,
    });
  };
  private loadHls = (url: string, video_ref: React.RefObject<HTMLVideoElement>) => {
    if (video_ref.current === null) {
      return;
    }
    const ref = video_ref.current;
    if (Hls.isSupported()) {
      if (this.state.hls_object) {
        this.destroyHls(this.state.hls_object);
      }
      const new_hls_object = new Hls();
      new_hls_object.loadSource(url);
      new_hls_object.attachMedia(ref);
      this.setState({ hls_object: new_hls_object });
    } else if (ref.canPlayType('application/vnd.apple.mpegurl')) {
      ref.src = url;
    }
  };
  render() {
    return (
      <Content>
        <WholeArea>
          <PfHlsPlayer
            playerRef={this.state.video_ref}
            key={this.props.selected_video_date}
            url={this.props.video_url}
            width={this.props.width || 'auto'}
            preload='auto'
            height={this.props.height ? this.props.height - 60 : 320} // 受け渡される高さのうち60pxは再生バーと日付に使われる
            // style={{ minWidth: styles.dialog_video_width }}
            autoplay={true}
            controls={true}
            loop={false}
            muted
            onCanPlay={() => {
              this.props.setIsLoaded(true);
            }}
            hlsConfig={{
              liveDurationInfinity: this.props.is_live,
            }}
          />
          <ControlPanelArea></ControlPanelArea>
          <M3u8PlayBarDateArea {...this.props} height={60} video_ref={this.state.video_ref} />
        </WholeArea>
      </Content>
    );
  }
}

// -- styled components --

const WholeArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ControlPanelArea = styled.div`
  width: 100%;
  height: 8%;
`;

// -- finally export part --
