// -- basic library --
import React, { useEffect, useState } from 'react';
import { dateToYMDHMS } from 'shared/utils/converter/date';
import { toISO8601 } from 'shared/models/ISO8601';
import styled from 'styled-components';

const displayVideoTime = (occurred_at: string, time: number, selecting_video_date?: string) => {
  if (occurred_at === '') {
    return null;
  }
  if (selecting_video_date) {
    return dateToYMDHMS(selecting_video_date);
  }
  const d = new Date(occurred_at);

  d.setSeconds(d.getSeconds() + Math.floor(time));
  return dateToYMDHMS(toISO8601(d));
};

// -- type declaration --
interface Params {
  selectedVideoDate: string;
  videoRef: React.RefObject<HTMLVideoElement>;
  selecting_video_date?: string;
}

// -- main component --
const M3u8Date: React.FC<Params> = (params) => {
  // -- local states --
  const [time, setTime] = useState<number>(0);

  //-- onload function --
  useEffect(() => {
    const countup = setInterval(() => {
      if (params.videoRef.current) {
        if (params.videoRef.current.onwaiting || params.videoRef.current.onabort) {
          setTime(0);
          return;
        }
        if (params.videoRef.current.currentTime !== 0) {
          setTime(params.videoRef.current.currentTime - 3600);
        }
      }
    }, 100);
    return () => {
      clearInterval(countup);
    };
  }, [time]); /* eslint-disable-line */
  useEffect(() => {
    setTime(0);
  }, [params.selectedVideoDate]); /* eslint-disable-line */
  // -- render part --
  return <DateForm>{displayVideoTime(params.selectedVideoDate, time, params.selecting_video_date)}</DateForm>;
};

// -- styled components --

const DateForm = styled.div`
  font-size: 1.7rem;
  display: flex;
  justify-content: center;
`;

// -- finally export part --

export default M3u8Date;
