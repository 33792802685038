import { AccumulationType } from 'shared/models/AccumulationType';
import { CommonAPIRequestType, isCommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';
import { DateString } from 'shared/models/DateString';
import { ISO8601 } from 'shared/models/ISO8601';
import { MetricGraphType } from 'shared/models/MetricGraphType';
import { Query } from 'shared/models/Query';
import { StatusType } from 'shared/models/StatusType';
import { TimeString } from 'shared/models/TimeString';
import { WidgetScaleType } from 'shared/models/WidgetScaleType';
import sendAxios from '../../shared/axios/sendAxios';
import { getClient } from './base';
import { MetricStatisticMethod } from 'shared/models/MetricStatisticMethod';
import { WidgetType } from 'shared/models/WidgetType';
import { WidgetPeriod } from 'shared/models/WidgetPeriod';

/*** エンティティ ***/

export interface WidgetGraphSetting {
  y_min?: number;
  y_max?: number;
}

export interface WidgetDateTimeSetting {
  start_date?: DateString | null;
  end_date?: DateString | null;
  start_time?: TimeString | null;
  end_time?: TimeString | null;
  scale?: WidgetScaleType | null;
}

export type WidgetSetting = WidgetGraphSetting & WidgetDateTimeSetting;

export interface Metric {
  name: string;
  graph_type?: MetricGraphType;
  stream_id: string;
  json_path: string;
  statistic_method: MetricStatisticMethod;
  accumulation?: AccumulationType;
  stack_id?: string;
  sum_up_id?: string;
}

export interface AreaCountSetting {
  name: string;
  area_name: string;
  count_target: string[];
  stack_id?: string;
}

export interface SUAreaCountSetting {
  area_name: string;
  count_target: string[];
  sum_up_id: string;
}

export interface SumUpGraphJsonSetting {
  json_method: string;
  jsonpath_group_id?: string;
  area_count_settings?: SUAreaCountSetting[];
  stream_id: string;
  metrics_length: number;
}
export interface SumUpGraphSettings {
  name: string[];
  json_settings: SumUpGraphJsonSetting[];
}

export interface DashboardWidget {
  tenant_id: string;
  dashboard_id: string;
  dashboard_widget_number: number;
  dashboard_widget_name: string;
  widget_type: WidgetType;
  widget_setting?: WidgetSetting;
  metrics?: Metric[];
  html?: string;
  html_script?: string;
  graph_type?: MetricGraphType;
  jsonpath_group_id?: string;
  player_stream_id?: string;
  od_stream_id?: string;
  od_age?: boolean;
  od_gender?: boolean;
  hm_stream_id?: string;
  hm_data_number_type?: string;
  gp_stream_id?: string;
  ac_stream_id?: string;
  area_count_settings?: AreaCountSetting[];
  sum_up_settings?: SumUpGraphSettings;
  order?: number;
  status: StatusType;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

export interface DashboardWidgetWithPaging {
  items: DashboardWidget[];
  has_next: boolean;
}

export interface DashboardWidgetMetric {
  datapoints: number[];
  metrics: { metric_name: string; statistic_method: MetricStatisticMethod; data: number[] }[];
  last_datapoint?: number;
}

export interface DashboardBusOdMetric {
  t: number;
  gps?: { lat: number; lon: number };
  gtfs?: { route_long_name: string; stop_name: string };
  objects: {
    label: string;
    gender?: { name: string };
    age?: { name: string };
    od: { state: string; od_id: string; in_t: number };
  }[];
}

export interface DashboardWidgetMetric2 {
  metrics: DashboardBusOdMetric[];
  last_data_number: number;
  has_next: boolean;
}

export interface DashboardHeatmapMetric {
  f: number | null;
  t: number | null;
  point: number[] | null;
}

export interface DashboardWidgetMetric3 {
  metrics: DashboardHeatmapMetric[];
  last_data_number: number;
  last_fragment_number: number | null;
  has_next: boolean;
  has_next_fragment: boolean;
}

const sample_dashboard_widget_1: DashboardWidget = {
  tenant_id: 'string',
  dashboard_id: 'string',
  dashboard_widget_number: 1,
  dashboard_widget_name: 'widget1',
  widget_type: 'GRAPH',
  widget_setting: {
    y_max: 200,
    y_min: -30,
    start_date: '2022-11-01' as DateString,
    end_date: '2022-11-03' as DateString,
    start_time: '00:00' as TimeString,
    end_time: '23:59' as TimeString,
    scale: 'DAY',
  },
  metrics: [
    {
      accumulation: 'HOUR',
      graph_type: 'AREA',
      json_path: '$[*]',
      name: 's',
      stack_id: '1',
      statistic_method: 'Maximum',
      stream_id: '00000001',
    },
    {
      accumulation: 'HOUR',
      graph_type: 'LINE',
      json_path: '$[*]',
      name: 't',
      stack_id: '1',
      statistic_method: 'Maximum',
      stream_id: '00000001',
    },
  ],
  player_stream_id: '000000001',
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
};

const sample_dashboard_widget_2: DashboardWidget = {
  tenant_id: 'string',
  dashboard_id: 'string',
  dashboard_widget_number: 2,
  dashboard_widget_name: 'widget2',
  widget_type: 'MAP',
  player_stream_id: '000000001',
  widget_setting: {
    start_date: null,
    end_date: null,
    start_time: null,
    end_time: null,
  },
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
};

const sample_dashboard_widget_metric_1: DashboardWidgetMetric = {
  datapoints: [1640098800, 1640102400, 1640106000, 1640109600, 1640113200, 1640116800, 1640120400],
  metrics: [
    {
      metric_name: 's',
      statistic_method: 'Count',
      data: [400, 300, 100, 200, 200, 0, 200],
    },
    {
      metric_name: 't',
      statistic_method: 'Count',
      data: [0, 0, 0, 1, 0, 2, 0],
    },
  ],
  last_datapoint: new Date().getTime(),
};

const sample_dashboard_widget_metric2_1: DashboardWidgetMetric2 = {
  metrics: [
    {
      t: 1640098800,
      gps: { lat: 122, lon: 24 },
      gtfs: { route_long_name: 'route1', stop_name: 'tokyo' },
      objects: [{ label: 'aaa', od: { state: 'state1', od_id: '00000001', in_t: 1640099000 } }],
    },

    {
      t: 1640102400,
      gps: { lat: 125, lon: 29 },
      gtfs: { route_long_name: 'route2', stop_name: 'shinjuku' },
      objects: [{ label: 'bbb', od: { state: 'state2', od_id: '00000002', in_t: 1640102500 } }],
    },
  ],
  last_data_number: 1640102400,
  has_next: false,
};
const sample_dashboard_widget_metric3_1: DashboardWidgetMetric3 = {
  metrics: [
    {
      t: 1640098800,
      f: 1640088800,
      point: [],
    },
    {
      t: 1640102400,
      f: 1640128800,
      point: [],
    },
  ],
  last_data_number: 1640102400,
  last_fragment_number: null,
  has_next: false,
  has_next_fragment: false,
};
/*** [GET] /api/dashboards/{dashboard_id}/widgets ***/

export interface RequestDashboardsIdWidgetsGet extends CommonAPIRequestType {
  dashboard_id: string;
  exclusive_start_dashboard_widget_number?: string;
  ordered?: 'True';
}

export const dashboardsIdWidgetsGetAPI = (params: RequestDashboardsIdWidgetsGet) => {
  const {
    dashboard_id,
    exclusive_start_dashboard_widget_number,
    ordered,
    disabled_load,
    disabled_error_message,
    ended_load,
  } = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/dashboards/${dashboard_id}/widgets`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    exclusive_start_dashboard_widget_number,
    ordered,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<DashboardWidgetWithPaging>(axios, path, query, form, method, {
    items: [sample_dashboard_widget_2, sample_dashboard_widget_1],
    has_next: false,
  });
};

/***
 * [POST] /api/dashboards/{dashboard_id}/widgets
 * [CHECK] 現在以下のパラメータはバックエンドに送信していない。(動作に問題はない)
 * これを送るべきか、いらないなら消すのか
 * jsonpath_group_id?: string;
 * graph_type?: string;
 * gp_stream_id?: string;
 *
 * ***/

export interface RequestDashboardsIdWidgetsPost extends CommonAPIRequestType {
  dashboard_id: string;
  dashboard_widget_name: string;
  widget_type: WidgetType;
  metrics?: Metric[];
  html?: string;
  html_script?: string;
  jsonpath_group_id?: string;
  graph_type?: string;
  player_stream_id?: string;
  od_stream_id?: string;
  od_age?: boolean;
  od_gender?: boolean;
  hm_stream_id?: string;
  gp_stream_id?: string;
  ac_stream_id?: string;
  area_count_settings?: AreaCountSetting[];
  sum_up_settings?: SumUpGraphSettings;
  widget_setting?: WidgetSetting;
}

export const dashboardsIdWidgetsPostAPI = (params: RequestDashboardsIdWidgetsPost) => {
  // postはデフォルトでロードする
  const { dashboard_id, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/dashboards/${dashboard_id}/widgets`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'dashboard_id' || key === 'metrics' || isCommonAPIRequestType(key)) {
      continue;
    }
    if (typeof value === 'object') {
      form.append(key, JSON.stringify(value));
      continue;
    }
    form.append(key, value);
  }
  // metricsだけ別処理(widget_typeに応じて送信するかしないか決定)
  // widget_typeが[GRAPH, LIVETEXT, AREACOUNT, SUMUPGRAPH]でかつmetricsが存在する時にのみmetricsを送信する
  if (
    (params.widget_type === 'GRAPH' ||
      params.widget_type === 'LIVETEXT' ||
      params.widget_type === 'AREACOUNT' ||
      params.widget_type === 'SUMUPGRAPH') &&
    params.metrics
  ) {
    const roop_number = params.metrics.length || 0;
    for (let i = 0; i < roop_number; i++) {
      for (const [key, value] of Object.entries(params.metrics[i])) {
        if (value !== undefined && value !== null) {
          form.append('metrics[' + i + '].' + key, value);
        }
      }
    }
  }
  // area_count_settingsだけ別処理(widget_typeに応じて送信するかしないか決定)
  // widget_typeが[AREACOUNT]でかつarea_count_settingsが存在する時にのみmetricsを送信する
  if (params.widget_type === 'AREACOUNT' && params.area_count_settings) {
    const roop_number = params.area_count_settings.length || 0;
    for (let i = 0; i < roop_number; i++) {
      for (const [key, value] of Object.entries(params.area_count_settings[i])) {
        if (value !== undefined && value !== null) {
          form.append('area_count_settings[' + i + '].' + key, value);
        }
      }
    }
  }
  // sum_up_settingsだけ別処理(widget_typeに応じて送信するかしないか決定)
  // widget_typeが[SUMUPGRAPH]でかつsum_up_settingsが存在する時にのみmetricsを送信する
  if (params.widget_type === 'SUMUPGRAPH' && params.sum_up_settings) {
    // sum_up_settings.name 配列の追加
    params.sum_up_settings.name.forEach((name, i) => {
      form.append(`sum_up_settings.name[${i}]`, name);
    });
    // sum_up_settings.json_settings 配列の処理
    params.sum_up_settings.json_settings.forEach((json_setting, j) => {
      for (const [key, value] of Object.entries(json_setting)) {
        // area_count_settings は別途処理するためここではスキップ
        if (key === 'area_count_settings') continue;

        // その他のプロパティをFormDataに追加
        if (typeof value === 'number' && value !== null) {
          form.append('sum_up_settings.json_settings[' + j + '].' + key, String(value));
        } else if (value !== undefined && value !== null) {
          form.append('sum_up_settings.json_settings[' + j + '].' + key, value);
        }
      }
      // area_count_settings 配列の処理
      if (json_setting.area_count_settings) {
        const roop_number = json_setting.area_count_settings.length || 0;
        for (let k = 0; k < roop_number; k++) {
          for (const [key, value] of Object.entries(json_setting.area_count_settings[k])) {
            if (value !== undefined && value !== null) {
              form.append('sum_up_settings.json_settings[' + j + '].area_count_settings[' + k + '].' + key, value);
            }
          }
        }
      }
    });
  }

  // 送信
  return sendAxios<DashboardWidget>(axios, path, query, form, method, sample_dashboard_widget_1);
};

/*** [GET] /api/dashboards/{dashboard_id}/widgets/{dashboard_widget_number} ***/

export interface RequestDashboardsIdWidgetsNumGet {
  dashboard_id: string;
  dashboard_widget_number: string;
}

export const dashboardsIdWidgetsNumGetAPI = (params: RequestDashboardsIdWidgetsNumGet) => {
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `/api/dashboards/${params.dashboard_id}/widgets/${params.dashboard_widget_number}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<DashboardWidget>(axios, path, query, form, method, sample_dashboard_widget_1);
};

/*** [PUT] /api/dashboards/{dashboard_id}/widgets/{dashboard_widget_number} ***/

export interface RequestDashboardsIdWidgetsNumPut extends CommonAPIRequestType {
  dashboard_id: string;
  dashboard_widget_number: string;
  dashboard_widget_name: string;
  widget_type: WidgetType;
  metrics?: Metric[];
  widget_setting?: WidgetSetting;
  order?: string;
  html?: string;
  html_script?: string;
  graph_type?: string;
  jsonpath_group_id?: string; // 送信はしないがmetricsの変換処理の際にjsonpath_group_idが必要
  player_stream_id?: string;
  od_stream_id?: string;
  od_age?: boolean;
  od_gender?: boolean;
  hm_stream_id?: string;
  gp_stream_id?: string; // 送信はしないがmetricsの変換処理の際にgp_stream_idが必要
  ac_stream_id?: string; // 送信はしないがmetricsの変換処理の際にac_stream_idが必要
  area_count_settings?: AreaCountSetting[];
  sum_up_settings?: SumUpGraphSettings;
}

export const dashboardsIdWidgetsNumPutAPI = (params: RequestDashboardsIdWidgetsNumPut) => {
  // putはデフォルトでロードする
  const { dashboard_id, dashboard_widget_number, disabled_load, disabled_error_message, ended_load } =
    toAPIRequestParams({
      ...params,
      api_send_type: params.api_send_type ?? 'changeableOneTransmission',
    });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/dashboards/${dashboard_id}/widgets/${dashboard_widget_number}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (
      key === 'dashboard_id' ||
      key === 'dashboard_widget_number' ||
      key === 'metrics' ||
      key === 'widget_type' ||
      isCommonAPIRequestType(key)
    ) {
      continue;
    }
    if (typeof value === 'object') {
      form.append(key, JSON.stringify(value));
      continue;
    }
    form.append(key, value);
  }
  // metricsだけ別処理(widget_typeに応じて送信するかしないか決定)
  // widget_typeが[GRAPH, LIVETEXT, AREACOUNT, SUMUPGRAPH]でかつmetricsが存在する時にのみmetricsを送信する
  if (
    (params.widget_type === 'GRAPH' ||
      params.widget_type === 'LIVETEXT' ||
      params.widget_type === 'AREACOUNT' ||
      params.widget_type === 'SUMUPGRAPH') &&
    params.metrics
  ) {
    const roop_number = params.metrics.length || 0;
    for (let i = 0; i < roop_number; i++) {
      for (const [key, value] of Object.entries(params.metrics[i])) {
        if (value !== undefined && value !== null) {
          form.append('metrics[' + i + '].' + key, value);
        }
      }
    }
  }
  // area_count_settingsだけ別処理(widget_typeに応じて送信するかしないか決定)
  // widget_typeが[AREACOUNT]でかつarea_count_settingsが存在する時にのみmetricsを送信する
  if (params.widget_type === 'AREACOUNT' && params.area_count_settings) {
    const roop_number = params.area_count_settings.length || 0;
    for (let i = 0; i < roop_number; i++) {
      for (const [key, value] of Object.entries(params.area_count_settings[i])) {
        if (value !== undefined && value !== null) {
          form.append('area_count_settings[' + i + '].' + key, value);
        }
      }
    }
  }
  // sum_up_settingsだけ別処理(widget_typeに応じて送信するかしないか決定)
  // widget_typeが[SUMUPGRAPH]でかつsum_up_settingsが存在する時にのみmetricsを送信する
  if (params.widget_type === 'SUMUPGRAPH' && params.sum_up_settings) {
    // sum_up_settings.name 配列の追加
    params.sum_up_settings.name.forEach((name, i) => {
      form.append(`sum_up_settings.name[${i}]`, name);
    });
    // sum_up_settings.json_settings 配列の処理
    params.sum_up_settings.json_settings.forEach((json_setting, j) => {
      for (const [key, value] of Object.entries(json_setting)) {
        // area_count_settings は別途処理するためここではスキップ
        if (key === 'area_count_settings') continue;

        // その他のプロパティをFormDataに追加
        if (typeof value === 'number' && value !== null) {
          form.append('sum_up_settings.json_settings[' + j + '].' + key, String(value));
        } else if (value !== undefined && value !== null) {
          form.append('sum_up_settings.json_settings[' + j + '].' + key, value);
        }
      }
      // area_count_settings 配列の処理
      if (json_setting.area_count_settings) {
        const roop_number = json_setting.area_count_settings.length || 0;
        for (let k = 0; k < roop_number; k++) {
          for (const [key, value] of Object.entries(json_setting.area_count_settings[k])) {
            if (value !== undefined && value !== null) {
              form.append('sum_up_settings.json_settings[' + j + '].area_count_settings[' + k + '].' + key, value);
            }
          }
        }
      }
    });
  }

  // 送信
  return sendAxios<DashboardWidget>(axios, path, query, form, method, sample_dashboard_widget_1);
};

/*** [DELETE] /api/dashboards/{dashboard_id}/widgets/{dashboard_widget_number} ***/

export interface RequestDashboardsIdWidgetsNumDelete extends CommonAPIRequestType {
  dashboard_id: string;
  dashboard_widget_number: string;
}

export const dashboardsIdWidgetsNumDeleteAPI = (params: RequestDashboardsIdWidgetsNumDelete) => {
  const { dashboard_id, dashboard_widget_number, disabled_load, disabled_error_message, ended_load } =
    toAPIRequestParams({
      ...params,
      // 本DELETE APIは単体で利用されることが多い
      api_send_type: params.api_send_type ?? 'changeableOneTransmission',
    });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/dashboards/${dashboard_id}/widgets/${dashboard_widget_number}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<DashboardWidget>(axios, path, query, form, method, sample_dashboard_widget_1);
};

/*** [GET] /api/dashboards/{dashboard_id}/widgets/{dashboard_widget_number}/image ***/

export interface RequestDashboardsIdWidgetsNumImageGet extends CommonAPIRequestType {
  dashboard_id: string;
  dashboard_widget_number: string | number;
}

export const DashboardsIdWidgetsNumImageGetAPI = (params: RequestDashboardsIdWidgetsNumImageGet) => {
  const { dashboard_id, dashboard_widget_number, disabled_load, disabled_error_message, ended_load } =
    toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ responseType: 'arraybuffer', disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/dashboards/${dashboard_id}/widgets/${dashboard_widget_number}/image`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<ArrayBuffer>(axios, path, query, form, method, new ArrayBuffer(6));
};

/*** [GET] /api/dashboards/{dashboard_id}/widgets/{dashboard_widget_number}/metrics ***/

export interface RequestDashboardsIdWidgetsNumMetricsGet extends CommonAPIRequestType {
  dashboard_id: string;
  dashboard_widget_number: string;
  stream_id: string;
  start: string;
  end: string;
  period: WidgetPeriod;
  start_time?: TimeString;
  end_time?: TimeString;
}

export const dashboardsIdWidgetsNumMetricsGetAPI = (params: RequestDashboardsIdWidgetsNumMetricsGet) => {
  const {
    dashboard_id,
    dashboard_widget_number,
    stream_id,
    start,
    end,
    period,
    start_time,
    end_time,
    disabled_load,
    disabled_error_message,
    ended_load,
  } = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/dashboards/${dashboard_id}/widgets/${dashboard_widget_number}/metrics`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    stream_id,
    start,
    end,
    period,
    start_time,
    end_time,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<DashboardWidgetMetric>(axios, path, query, form, method, sample_dashboard_widget_metric_1);
};

export interface RequestDashboardsIdWidgetsNumMetrics2Get extends CommonAPIRequestType {
  dashboard_id: string;
  dashboard_widget_number: string;
  start: string;
  end: string;
}

export const dashboardsIdWidgetsNumMetrics2GetAPI = (params: RequestDashboardsIdWidgetsNumMetrics2Get) => {
  const { dashboard_id, dashboard_widget_number, start, end, disabled_load, disabled_error_message, ended_load } =
    toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/dashboards/${dashboard_id}/widgets/${dashboard_widget_number}/metrics2`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    start,
    end,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<DashboardWidgetMetric2>(axios, path, query, form, method, sample_dashboard_widget_metric2_1);
};

export interface RequestDashboardsIdWidgetsNumMetrics3Get extends CommonAPIRequestType {
  dashboard_id: string;
  dashboard_widget_number: string;
  start: string;
  end: string;
  exclusive_start_fragment_number: string;
}

export const dashboardsIdWidgetsNumMetrics3GetAPI = (params: RequestDashboardsIdWidgetsNumMetrics3Get) => {
  const {
    dashboard_id,
    dashboard_widget_number,
    start,
    end,
    exclusive_start_fragment_number,
    disabled_load,
    disabled_error_message,
    ended_load,
  } = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/dashboards/${dashboard_id}/widgets/${dashboard_widget_number}/metrics3`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    start,
    end,
    exclusive_start_fragment_number,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<DashboardWidgetMetric3>(axios, path, query, form, method, sample_dashboard_widget_metric3_1);
};

/*** [GET] /api/dashboards/streams/detail/widgets/metrics ***/

export interface RequestDashboardsStreamsDetailWidMetGet extends CommonAPIRequestType {
  widget: DashboardWidget;
  stream_id: string;
  start: string;
  end: string;
  period: WidgetPeriod;
  start_time?: TimeString;
  end_time?: TimeString;
}

export const dashboardsStreamsDetailWidMetGetAPI = (params: RequestDashboardsStreamsDetailWidMetGet) => {
  const { stream_id, start, end, period, start_time, end_time, disabled_load, disabled_error_message, ended_load } =
    toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/dashboards/streams/detail/widgets/metrics`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    stream_id,
    start,
    end,
    period,
    start_time,
    end_time,
    widget: JSON.stringify(params.widget),
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // }

  // 送信
  return sendAxios<DashboardWidgetMetric>(axios, path, query, form, method, sample_dashboard_widget_metric_1);
};
