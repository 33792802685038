// -- basic library --
import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import InputBox from 'shared/components/atoms/InputBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Content, Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import StreamDataFileBox from 'user/components/molecules/StreamDataFileBox';
import styles from 'shared/styles/styles';
import { StreamDataFileType } from 'shared/models';
import { isNotOnlySpace } from 'shared/utils/is';
import delay from 'shared/utils/else/delay';
import { isSequence } from 'user/utils/streamFunctions';
import uploadMultiStreamDataFile from 'user/utils/uploadMultiStreamDataFunctions';
import { Stream, streamsIdGetAPI } from 'user/api/streams';
import { systemSelector } from 'user/redux/slices/systemSlice';
import { RequestStreamsIdDataUploadPost } from 'user/api/streamsData';
import { toStringBoolean } from 'shared/models/StringBoolean';
import RadioBox from 'shared/components/molecules/RadioBox';

interface Params {
  stream_id: string;
  onClose: (p: {
    isCanceled?: boolean;
    isCreated?: boolean;
    isSettingProcess?: boolean;
    stream_data_number?: string;
  }) => void;
  onCreateFinished: (s3_transfer_id: string) => void;
}
// -- main component --

const CreateDialog: React.FC<Params> = (params) => {
  // -- local state --
  const [stream, setStream] = useState<Stream | undefined>(undefined);
  const [stream_data_mime_type, setStreamDataMimeType] = useState<string>('*/*');

  // 入力用states
  const [stream_data_name, setStreamDataName] = useState<string>('');
  const [stream_data_files, setStreamDataFiles] = useState<StreamDataFileType[]>([]);
  const [framerate_type, setFramerateType] = useState<string>('BY_FILE');
  const [desired_fps, setDesiredFps] = useState<string>('10');

  // redux state
  const system_state = useSelector(systemSelector);

  // -- get datas --
  const getStreamsData = async (stream_id: string) => {
    const res = await streamsIdGetAPI({
      stream_id: stream_id,
    });
    if (res.status === 200) {
      setStream(res.data);
      if (res.data.data_type === 'IMAGE') {
        setStreamDataMimeType('image/*');
      } else if (res.data.data_type === 'VIDEO') {
        setStreamDataMimeType('video/*');
      }
    }
  };

  // -- handlers --
  const handleStreamDataNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStreamDataName(e.currentTarget.value);
  };

  const handleDesiredFpsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDesiredFps(e.currentTarget.value);
  };

  // varidation
  /**
   * 入力チェックを行い、エラーの場合はメッセージを含む例外をスローします。
   */
  const validate = () => {
    if (stream_data_files.length <= 0) {
      throw new Error('ストリームデータを選択してください');
    }
    for (let i = 0; i < stream_data_files.length; i++) {
      const sdf = stream_data_files[i];
      if (sdf.file.size > 5 * 1024 * 1024 * 1024) {
        // 5GBを超えるファイルは制限
        throw new Error(`${sdf.file_name}のサイズが5GBを超えています。\n*5GB以下のサイズのみアップロードできます`);
      }
    }
    if (framerate_type === 'BY_INPUT') {
      let v;
      try {
        v = parseInt(desired_fps);
      } catch (e) {
        throw new Error(`フレームレートの入力が正しくありません ${e}`);
      }
      if (v <= 0 || 60 < v) {
        throw new Error('フレームレートは1～60で指定してください');
      }
    }
  };

  // ストリームデータのアップロードが完了した後の関数
  const onUploadConfirm = (upload_files: StreamDataFileType[]) => {
    if (upload_files.length >= 2) {
      // 少し時間を開ける
      // 複数アップロードした後に、一覧を取得するが、少しの差で取得しきれない場合があるため
      delay(1).then(() => {
        params.onClose({
          isCreated: true,
          isCanceled: true,
        });
      });
    }
  };

  // s3_transfer_idを利用した変更を行う関数
  const onUploadS3Transfer = async (upload_files: StreamDataFileType[], s3_transfer_id: string) => {
    const len = upload_files.length;
    if (len === 1) {
      params.onCreateFinished(s3_transfer_id);
    }
  };

  const handleClickUploadFinish = async (stream: Stream) => {
    // 入力チェック
    try {
      validate();
    } catch (e) {
      if (e instanceof Error) {
        AlertDialog.show(e.message);
      }
      return;
    }

    const request_post_params: RequestStreamsIdDataUploadPost = {
      disabled_load: true,
      stream_id: params.stream_id,
      add_dimension: toStringBoolean(false),
    };
    if (isNotOnlySpace(stream_data_name)) {
      request_post_params.stream_data_name = stream_data_name;
    }
    if (framerate_type === 'BY_INPUT') {
      request_post_params.desired_fps = desired_fps;
    }
    // 上でバリデーションをかけているので、必ずストリームデータは1つ以上存在する。
    // SEQUENCEの時は、全てをアップロードし、それ以外では最初の1つだけをアップロードする。
    const upload_files = isSequence(stream) ? stream_data_files : stream_data_files.slice(0, 1);

    // API通信部分
    await uploadMultiStreamDataFile({
      onS3Transfer: async (s3_transfer_id) => onUploadS3Transfer(upload_files, s3_transfer_id),
      onConfirm: () => onUploadConfirm(upload_files),
      stream_datas: upload_files,
      request_params: request_post_params,
    });
  };

  // -- onload function --
  useEffect(() => {
    (async function () {
      // プロセス情報の取得
      await getStreamsData(params.stream_id);
    })();
  }, []); /* eslint-disable-line */

  // -- render part --
  return (
    <Content>
      {stream === undefined ? (
        <Spinner />
      ) : (
        <>
          <InputComponent text='ストリームデータ名'>
            <InputBox
              title='ストリームデータ名'
              onChange={handleStreamDataNameChange}
              value={stream_data_name}
              placeholder='入力してください'
            />
          </InputComponent>
          <InputComponent text={`ストリームデータ`} required>
            <StreamDataFileBox
              stream={stream}
              files={stream_data_files}
              setFiles={setStreamDataFiles}
              accept={stream_data_mime_type}
            />
          </InputComponent>
          <InputComponent text='フレームレート' required>
            <RadioBox
              datas={[
                { name: '動画ファイルのフレームレート', value: 'BY_FILE' },
                { name: 'フレームレートを指定する', value: 'BY_INPUT' },
              ]}
              selectedValue={framerate_type}
              handleChangeClick={setFramerateType}
            />
            {framerate_type === 'BY_INPUT' && (
              <InputBox
                title='フレームレート'
                type='number'
                onChange={handleDesiredFpsChange}
                value={desired_fps}
                placeholder='入力してください'
              />
            )}
          </InputComponent>
          <Footer>
            <RoundedButton
              text='キャンセル'
              onClick={() =>
                params.onClose({
                  isCanceled: true,
                })
              }
              is_white
              style={{
                marginRight: styles.interval_narrow_margin,
              }}
              disabled={system_state.loading.isActive}
            />
            <RoundedButton
              text_type='CREATE'
              onClick={() => handleClickUploadFinish(stream)}
              disabled={system_state.loading.isActive}
            />
          </Footer>
        </>
      )}
    </Content>
  );
};

// -- styled components --

// -- finally export part --

export default CreateDialog;
