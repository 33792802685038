import { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy } from 'shared/utils/converter';
import { BillingCollect, CachedBillingCollects, RequestBillingCollectsGet } from 'user/api/billingCollects';

/**
 * テナント毎の請求情報詳細一覧を取得するcustom hooks
 */
export const useBillingcollects = ({
  yearmonth,
  component_name,
  process_type,
}: RequestBillingCollectsGet) => {
  const [billing_collects, setBillingCollects] = useState<BillingCollect[] | undefined>(undefined);
  const billing_collects_record: Record<string, BillingCollect> = useMemo(() => {
    return billing_collects ? keyBy(billing_collects, (p) => p.component_id) : {};
  }, [billing_collects]);
  const loadbillingCollects = useCallback(
    async (request?: RequestBillingCollectsGet) => {
      const r = request ?? { yearmonth, component_name, process_type } ?? {};
      const new_billingCollects = await new CachedBillingCollects(r).get();
      setBillingCollects(new_billingCollects);
    },
    [ yearmonth, component_name, process_type],
  );

  useEffect(() => {
    loadbillingCollects();
  }, [loadbillingCollects]);

  return useMemo(() => {
    return {
      billing_collects,
      billing_collects_record,
      setBillingCollects,
      loadbillingCollects,
    };
  }, [billing_collects, billing_collects_record, setBillingCollects, loadbillingCollects]);
};
