// -- basic library --
import React, { useEffect, useRef, useState } from 'react';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { KonvaEventObject } from 'konva/types/Node';
import { Stage as StageRefType } from 'konva/types/Stage';
import { CircleConfig } from 'konva/types/shapes/Circle';
import { Circle, Group, Label, Text, Tag, Stage, Layer, Line, Image as ImageKonva } from 'react-konva';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import SelectableBlueprintButton from 'shared/components/atoms/SelectableBlueprintButton';
import { Content, FlexCenterDiv } from 'shared/components/molecules/ContentsArea';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import { divisePolygon, getPolygonPoints, multiplyPolygon } from 'shared/utils/else/polygonFunctions';
import styled from 'styled-components';
import SettingExpImpButton from '../../../user/components/molecules/SettingExpImpButton/SettingExpImpButton';
import { arr2DTo1D } from 'shared/utils/converter';
import { isBetweenRange, isNumberSquareArrayOfSize2 } from 'shared/utils/is';

// -- external functions --

// -- external types --
// import { Color } from "react-color";

/***
 * 射影変換エリアを描画するコンポネント
 * polygons: [[[x0,y0], [x1,y1]], [[x0,y0]]]のように指定
 * ***/
interface DrawTransformedPolygonParams {
  imgsrc: string;
  polygons: number[][][]; // // エリア, 点, xy
  onChange: (polygons: number[][][]) => void;
}

// 描画モード, 修正モード
type MODE = 'pen' | 'hand';

export const DrawTransformedPolygonObject: React.FC<DrawTransformedPolygonParams> = (params) => {
  // 描画中かどうか判定する
  const isDrawing = useRef(false);
  // konvaの最大親要素のref
  const stageRef: React.LegacyRef<StageRefType> = useRef(null);
  const parentRef: React.LegacyRef<HTMLDivElement> = useRef<HTMLDivElement>(null);
  // stageの一個上のコンポーネントのref
  const stageParentRef = useRef<HTMLDivElement>(null);

  // 画像の高さ
  const [height, setHeight] = useState<number>(580);
  // n角形のnの部分
  const polygon_number = 4;
  // モード切り替え
  const [mode, setMode] = useState<MODE>('pen');
  //   const [color, setColor] = useState<Color>('red')
  // 選択されている図形のindex
  const [selected_polygon_index, setSelectedPolygonIndex] = useState<number | null>(null);
  // 図形の頂点の丸を動かす際、どの丸(startから時計回りに, 0,1,2,...)を掴んでいるかを保持する。
  // nullの時は掴んでいない
  const [selected_vertex_index, setSelectedVertexIndex] = useState<number | null>(null);

  // konva objectがdragできるかどうか。先端の丸を動かしている時はdragできない
  //   const [draggable, setDraggable] = React.useState<boolean>(true)
  // dragを開始した時に、開始位置を格納しておく。(これで移動差分を取得し、座標を差分だけ移動させる)
  // const [drag_start, setDragStart] = React.useState<XYCoordinate | undefined>(undefined)

  // konvaのImageに格納するimgデータ
  const [img, setImg] = useState<CanvasImageSource | undefined>(undefined);
  // width / height
  const [img_ratio, setImgRario] = useState<number>(16 / 9);

  // 高さとレシオから横幅を出力する
  const getWidth = (height: number, img_ratio: number) => {
    return height * img_ratio;
  };

  // polygon_pointsのlengthの範囲内であればtrue, 範囲外であればfalse
  const checkRangePolygonIndex = (index: number) => {
    return isBetweenRange(index, 0, params.polygons.length);
  };

  // 図形を引ける最大数
  const checkPolygonsLength = (max_length = 1) => {
    if (params.polygons.length >= max_length) {
      return false;
    }
    return true;
  };

  // 図形の頂点が、指定の(indexの)polygonに存在するかどうか
  const checkVertexIndex = (vertex_index: number, index: number) => {
    const flag1 = checkRangePolygonIndex(index);
    const polygon: number[][] = params.polygons[index];
    const flag2 = isBetweenRange(vertex_index, 0, polygon.length - 1);
    return flag1 && flag2;
  };

  // indexを指定して。polygon_pointsを更新する
  const updatePolygonPointsWhereIndex = (pp: number[][], index: number) => {
    if (!checkRangePolygonIndex(index)) return;
    const new_polygon_points = [...params.polygons];
    new_polygon_points[index] = pp;
    params.onChange(new_polygon_points);
  };

  // 指定の頂点のpolygon_pointを変更する
  const changePolygonPoint = (p: { x: number; y: number; vertex_index: null | number; index: number }) => {
    // vertex_indexがnull or サイズオーバーの時はreturn
    if (p.vertex_index === null || !checkVertexIndex(p.vertex_index, p.index)) return;
    const new_polygon: number[][] = [...params.polygons[p.index]];
    new_polygon[p.vertex_index] = [p.x, p.y];
    updatePolygonPointsWhereIndex(new_polygon, p.index);
  };

  // マウスを押下した時の関数('pen'モード)
  const onMouseDownModePen = (evt: KonvaEventObject<MouseEvent>) => {
    if (!checkPolygonsLength()) return;
    isDrawing.current = true;
    const pos = evt.target.getStage()?.getPointerPosition();
    if (!pos) {
      return;
    }
    const x = pos.x;
    const y = pos.y;
    const new_polygongs: number[][][] = [...params.polygons];
    new_polygongs.push([[x / getWidth(height, img_ratio), y / height]]);
    params.onChange(new_polygongs);
  };

  // マウスを動かしている時の関数('pen'モード)
  const onMouseMoveModePen = (evt: KonvaEventObject<MouseEvent>) => {
    if (!checkPolygonsLength(6)) return;
    // 描画中でなければreturn
    // 四角形なので0,1,2,3点目で終了
    if (!isDrawing.current) {
      return;
    }
    const stage = evt.target.getStage();
    const pos = stage?.getPointerPosition();
    if (!pos) return;
    // 最後の要素のindex
    const index = params.polygons.length - 1;
    // 現在のpolygon('pen'モードの時は、必ず新しい図形を追加するので最後の要素がそれに当たる)
    let polygon: number[][] = [...params.polygons[index]];
    // 要素が1以上存在しないときはreturn ([x0, y0]はなくてはならない)
    if (polygon.length <= 0) return;

    const width = getWidth(height, img_ratio);
    const x = pos.x;
    const y = pos.y;
    // 回転を利用して残りの座標を決定しているため、割合座標での計算では値が狂う。
    // そのため、始点(polygon[0])の座標を通常の座標に戻してから、処理を行い、最後に座標を戻している
    polygon = getPolygonPoints(
      {
        start: {
          x: polygon[0][0] * width,
          y: polygon[0][1] * height,
        },
        end: {
          x: x,
          y: y,
        },
      },
      polygon_number,
    );
    const p = divisePolygon({
      polygon: polygon,
      width: width,
      height: height,
    });
    updatePolygonPointsWhereIndex(p, index);
  };

  // マウスを離した時の関数('pen'モード)
  const onMouseUpModePen = (_evt: KonvaEventObject<MouseEvent>) => {
    if (!checkPolygonsLength(6)) return;
    isDrawing.current = false;
  };

  // マウスを押下した時の関数('hand'モード)
  const onMouseDownModeHand = (evt: KonvaEventObject<MouseEvent>, index: number) => {
    // 描画開始
    isDrawing.current = true;
    // 座標が画像のサイズを超えないように
    const pos = evt.target.getStage()?.getPointerPosition();
    if (!pos) return;
    changePolygonPoint({
      x: pos.x / getWidth(height, img_ratio),
      y: pos.y / height,
      vertex_index: selected_vertex_index,
      index: index,
    });
  };

  // マウスを動かしている時の関数('hand'モード)
  const onMouseMoveModeHand = (evt: KonvaEventObject<MouseEvent>, index: number) => {
    if (!isDrawing.current) return;
    // 座標が画像のサイズを超えないように
    const pos = evt.target.getStage()?.getPointerPosition();
    const width = getWidth(height, img_ratio);
    if (!pos) return;
    changePolygonPoint({
      x: pos.x / width,
      y: pos.y / height,
      vertex_index: selected_vertex_index,
      index: index,
    });
  };

  // マウスを離した時の関数('hand'モード)
  const onMouseUpModeHand = (evt: KonvaEventObject<MouseEvent>, index: number) => {
    isDrawing.current = false;
    // no drawing - skipping
    // 座標が画像のサイズを超えないように
    const pos = evt.target.getStage()?.getPointerPosition();
    const width = getWidth(height, img_ratio);
    if (!pos) return;
    changePolygonPoint({
      x: pos.x / width,
      y: pos.y / height,
      vertex_index: selected_vertex_index,
      index: index,
    });
    // リサイズのポジションを初期に直す
    // これをやらないと、マウスをクリックするたびに線が移動してしまう。
    setSelectedVertexIndex(null);
  };

  // マウスを押下した時の関数
  const onMouseDown = (evt: KonvaEventObject<MouseEvent>) => {
    if (mode === 'pen') {
      onMouseDownModePen(evt);
    } else if (mode === 'hand') {
      // 選択されているpolygonが存在しない or 範囲外ならreturn
      if (selected_polygon_index === null || !checkRangePolygonIndex(selected_polygon_index)) return;
      onMouseDownModeHand(evt, selected_polygon_index);
    }
  };
  // マウスを動かしている時の関数
  const onMouseMove = (evt: KonvaEventObject<MouseEvent>) => {
    if (mode === 'pen') {
      onMouseMoveModePen(evt);
    } else if (mode === 'hand') {
      // 選択されているpolygonが存在しない or 範囲外ならreturn
      if (selected_polygon_index === null || !checkRangePolygonIndex(selected_polygon_index)) return;
      onMouseMoveModeHand(evt, selected_polygon_index);
    }
  };
  // マウスを離した時の関数
  const onMouseUp = (evt: KonvaEventObject<MouseEvent>) => {
    if (mode === 'pen') {
      onMouseUpModePen(evt);
    } else if (mode === 'hand') {
      // 選択されているpolygonが存在しない or 範囲外ならreturn
      if (selected_polygon_index === null || !checkRangePolygonIndex(selected_polygon_index)) return;
      onMouseUpModeHand(evt, selected_polygon_index);
    }
  };

  // モードを変更した時の関数
  // 選択されているindexはリセットする
  const changeMode = (nm: MODE) => {
    setMode(nm);
    setSelectedPolygonIndex(null);
    setSelectedVertexIndex(null);
  };

  // マウスが、図形上に侵入した時の関数
  const onMouseEnterPolygon = (evt: KonvaEventObject<DragEvent>) => {
    if (mode !== 'hand') return;
    const stage = evt.target.getStage();
    if (!stage) return;
    const content = stage.getContent();
    // 先端の丸にポインターが入っている時はスキップ
    if (content.style.cursor === 'nesw-resize') return;
    // オブジェクトにホバーするとcursorが変わる
    content.style.cursor = 'move';
  };
  // マウスが、図形上から離れた時の関数
  const onMouseOutPolygon = (evt: KonvaEventObject<DragEvent>) => {
    if (mode !== 'hand') return;
    const stage = evt.target.getStage();
    if (!stage) return;
    const content = stage.getContent();
    // オブジェクトのホバーが外れるとcursorが変わる
    content.style.cursor = 'default';
  };

  //図形をクリックした時の関数
  const onPolygonClick = (evt: KonvaEventObject<MouseEvent>, index: number) => {
    evt.evt.stopPropagation();
    setSelectedPolygonIndex(index);
  };

  //背景画像をクリックした時の関数
  const onImageClick = (_evt: KonvaEventObject<MouseEvent>) => {
    setSelectedPolygonIndex(null);
  };

  // 画像を描画する
  const drawImage = (imgsrc: string) => {
    const image = new Image();
    image.src = imgsrc;
    image.onload = () => {
      const ratio = image.width / image.height;
      // 横幅が足りない時は、heightを調節
      if (stageParentRef.current && stageParentRef.current.clientWidth) {
        if (height * ratio > stageParentRef.current.clientWidth) {
          setHeight(stageParentRef.current.clientWidth / ratio);
        }
      }
      setImg(image);
      setImgRario(image.width / image.height);
    };
  };

  // 図形を全て消去
  const onClickReset = () => {
    params.onChange([]);
  };

  // -- onload function --
  useEffect(() => {
    drawImage(params.imgsrc);
  }, [params.imgsrc]); /* eslint-disable-line */
  return (
    <Content ref={parentRef}>
      <TopArea
        style={{
          alignItems: 'center',
        }}
      >
        <FlexCenterDiv>
          <SelectableBlueprintButton
            icon={<Icon iconSize={16} icon={IconNames.EDIT} />}
            onClick={() => changeMode('pen')}
            selected={mode === 'pen'}
            title='線を描画するモード'
          />
          <SelectableBlueprintButton
            icon={<Icon iconSize={16} icon={IconNames.HAND} />}
            onClick={() => changeMode('hand')}
            selected={mode === 'hand'}
            title='線を操作するモード'
          />
        </FlexCenterDiv>
        <FlexCenterDiv align_center>
          <RoundedButton onClick={onClickReset} text='リセット' small is_margin_right />
          <SettingExpImpButton stayarea_crosslines={params.polygons} setStayareaCrosslines={params.onChange} />
        </FlexCenterDiv>
      </TopArea>
      <CenterArea ref={stageParentRef}>
        <Stage
          width={height * img_ratio}
          height={height}
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
          onMouseEnter={onMouseEnterPolygon}
          onMouseOut={onMouseOutPolygon}
          ref={stageRef}
        >
          {img && (
            <Layer>
              <ImageKonva image={img} width={height * img_ratio} height={height} onClick={onImageClick} />
              {params.polygons.map((polygon, index) => {
                return (
                  <PolygonGroup
                    key={index}
                    polygon={polygon}
                    index={index}
                    width={getWidth(height, img_ratio)}
                    height={height}
                    selected_polygon_index={selected_polygon_index}
                    selected_vertex_index={selected_vertex_index}
                    setSelectedVertexIndex={setSelectedVertexIndex}
                    mode={mode}
                    onClick={onPolygonClick}
                  />
                );
              })}
            </Layer>
          )}
        </Stage>
      </CenterArea>
    </Content>
  );
};

/*** 1つのpolygonに対する必要情報全て ***/
const PolygonGroup: React.FC<{
  polygon: number[][];
  index: number;
  mode: MODE;
  selected_polygon_index: number | null;
  selected_vertex_index: number | null;
  setSelectedVertexIndex: (selected_vertex_index: number | null) => void;
  onClick: (e: KonvaEventObject<MouseEvent>, index: number) => void;
  width: number;
  height: number;
}> = (params) => {
  // 絶対値の座標
  const multiplied_polygon = multiplyPolygon({
    polygon: params.polygon,
    width: params.width,
    height: params.height,
  });
  // 1次元の座標
  const polygon1D = arr2DTo1D(multiplied_polygon);
  return (
    <Group key={params.index} onClick={(e) => params.onClick(e, params.index)}>
      <Line points={polygon1D} stroke={colors.red} strokeWidth={5} closed={true} />
      <LabelText index={params.index} polygon={multiplied_polygon} />
      {params.selected_polygon_index === params.index && (
        <ResizePointCircles
          polygon_points={multiplied_polygon}
          mode={params.mode}
          selected_vertex_index={params.selected_vertex_index}
          setSelectedVertexIndex={params.setSelectedVertexIndex}
        />
      )}
    </Group>
  );
};

/*** 図形のラベル名を表示するコンポーネント ***/
const LabelText: React.FC<{
  polygon: number[][];
  index: number;
}> = (params) => {
  return (
    <>
      {params.polygon.length >= 1 && isNumberSquareArrayOfSize2(params.polygon) && (
        <Label fill={colors.white} x={params.polygon[0][0]} y={params.polygon[0][1]}>
          <Tag fill={colors.black} />
          <Text text='原点' fill={colors.light_green} fontSize={16} padding={5} />
        </Label>
      )}
    </>
  );
};

/***
 * 図形の先端の丸を表示するコンポーネント
 * 書く丸を動かすと、図形を変更できる
 * ***/
const ResizePointCircles: React.FC<{
  polygon_points: number[][];
  mode: 'hand' | 'pen';
  selected_vertex_index: number | null;
  setSelectedVertexIndex: (selected_verted_index: number | null) => void;
}> = (params) => {
  // マウスが、円上かに侵入した時の関数
  const onMouseEnterCircle = (evt: KonvaEventObject<DragEvent>) => {
    evt.evt.stopPropagation();
    if (params.mode !== 'hand') return;
    const stage = evt.target.getStage();
    if (!stage) return;
    const content = stage.getContent();
    // オブジェクトにホバーするとcursorが変わる
    content.style.cursor = 'nesw-resize';
  };
  // マウスが、円上を離れた時の関数
  const onMouseOutCircle = (evt: KonvaEventObject<DragEvent>) => {
    evt.evt.stopPropagation();
    if (params.mode !== 'hand') return;
    const stage = evt.target.getStage();
    if (!stage) return;
    const content = stage.getContent();
    // オブジェクトのホバーが外れるとcursorが変わる
    content.style.cursor = 'default';
  };

  const baseCircleConfig: CircleConfig = {
    radius: 6,
    fill: colors.light_green,
    stroke: colors.black,
    strokeWidth: 2,
    onMouseEnter: onMouseEnterCircle,
    onMouseOut: onMouseOutCircle,
  };

  return (
    <>
      {/* 先端の丸 */}
      {params.mode === 'hand' &&
        params.polygon_points.map((polygon, i) => {
          const x = polygon[0] || undefined;
          const y = polygon[1] || undefined;
          return (
            <Circle
              {...baseCircleConfig}
              key={`circle-${i}`}
              x={x}
              y={y}
              onMouseDown={() => params.setSelectedVertexIndex(i)}
            />
          );
        })}
    </>
  );
};

const TopArea = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
`;

const CenterArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${styles.interval_narrow_margin} 0px;
`;

export default DrawTransformedPolygonObject;
