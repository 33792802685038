import React, { useCallback, useState } from 'react';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { WidgetFixedDateSettingPanel } from 'shared/components/molecules/WidgetFixedDateSettingPanel';
import styles from 'shared/styles/styles';
import { DateString } from 'shared/models/DateString';
import { TimeString, toTimeString } from 'shared/models/TimeString';
import { WidgetScaleType } from 'shared/models/WidgetScaleType';
import { validateStartEndDate, validateStartEndTime, widgetPutValidator } from 'user/utils/validators/widgets';
import styled from 'styled-components';
import { dashboardsIdWidgetsNumPutAPI, DashboardWidget } from 'user/api/dashboardsWidgets';

export interface FixedDateScaleSettingDefaults {
  start_date?: DateString | null;
  end_date?: DateString | null;
  start_time?: TimeString | null;
  end_time?: TimeString | null;
  scale?: WidgetScaleType | null;
}

export type FixedDateScaleSettingDialogCloseProps = {
  start_date: DateString | null;
  end_date: DateString | null;
  start_time: TimeString;
  end_time: TimeString;
  scale: WidgetScaleType | null;
  is_canceled?: boolean;
} & FixedDateScaleSettingDefaults;

interface FixedDateScaleSettingDialogProps {
  isOpen?: boolean;
  // widgetが存在すれば、PUTを行える
  widget?: DashboardWidget;
  defaults?: FixedDateScaleSettingDefaults;
  hide_scale?: boolean;
  onClose: (params: FixedDateScaleSettingDialogCloseProps) => void;
}
/**
 * 日時と日時スケールを設定を行うダイアログです。
 */
export default function FixedDateScaleSettingDialog({
  widget,
  onClose,
  isOpen = true,
  defaults,
  hide_scale,
}: FixedDateScaleSettingDialogProps) {
  // widgetが存在すればPUTを行う
  const enabledSaveButton = widget !== undefined;
  const [start_date, setStartDate] = useState<DateString | null>(defaults?.start_date ?? null);
  const [end_date, setEndDate] = useState<DateString | null>(defaults?.end_date ?? null);
  const [start_time, setStartTime] = useState<TimeString>(defaults?.start_time ?? toTimeString('00:00'));
  const [end_time, setEndTime] = useState<TimeString>(defaults?.end_time ?? toTimeString('23:59'));
  const [scale, setScale] = useState<WidgetScaleType | null>(defaults?.scale ?? null);

  const requestWidgetSettingSave = useCallback(async () => {
    if (!widget) return;
    const { widget_setting } = widget;
    try {
      // validation後のrequestデータ取得
      const request = widgetPutValidator({
        ...widget,
        widget_setting: {
          y_min: typeof widget_setting?.y_min === 'number' ? String(widget_setting.y_min) : undefined,
          y_max: typeof widget_setting?.y_max === 'number' ? String(widget_setting.y_max) : undefined,
          start_time,
          end_time,
          start_date,
          end_date,
          // scaleは省略可
          scale: !hide_scale ? scale : undefined,
        },
      });
      await dashboardsIdWidgetsNumPutAPI(request);
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }
    }
  }, [start_time, end_time, start_date, end_date, scale, hide_scale, widget]);

  const onFinish = useCallback(async () => {
    try {
      // 開始日時, 終了日時のバリデーション
      validateStartEndDate({ start_date, end_date });
      // 開始時刻, 終了時刻のバリデーション
      validateStartEndTime({ start_time, end_time });
      if (enabledSaveButton) {
        await requestWidgetSettingSave();
      }
    } catch (e) {
      if (e instanceof Error) {
        AlertDialog.show(e.message);
      }
      return;
    }
    onClose({ is_canceled: false, start_date, end_date, start_time, end_time, scale: !hide_scale ? scale : null });
  }, [
    onClose,
    start_date,
    start_time,
    end_date,
    end_time,
    scale,
    requestWidgetSettingSave,
    hide_scale,
    enabledSaveButton,
  ]);

  const onCancelClose = useCallback(() => {
    onClose({ is_canceled: true, start_date, end_date, start_time, end_time, scale: !hide_scale ? scale : null });
  }, [start_date, end_date, start_time, end_time, scale, onClose, hide_scale]);
  return (
    <PfDialog title='日時固定設定' isOpen={isOpen} onClose={onCancelClose}>
      <WidgetFixedDateSettingPanel
        start_date={start_date}
        end_date={end_date}
        start_time={start_time}
        end_time={end_time}
        scale={scale}
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        setStartTime={setStartTime}
        setEndTime={setEndTime}
        setScale={setScale}
        hide_scale={hide_scale}
      />
      <div style={{ textAlign: 'center' }}>
        <RoundedButton text='キャンセル' is_white={true} onClick={onCancelClose} />
        <RoundedButton text='OK' onClick={onFinish} is_margin_left={true} is_margin_right={true} />
        {/* {widget && <CheckBoxWithText checked={checked} onClick={onCheckSwitch} text="設定を保存する" />} */}
      </div>
      {enabledSaveButton && (
        <SubText style={{ textAlign: 'center' }}>※設定した内容は保存されます。(リロードしても保持)</SubText>
      )}
    </PfDialog>
  );
}

const SubText = styled.p`
  text-align: center;
  margin-top: ${styles.interval_narrow_margin};
`;
