import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { Content, Title, TopArea } from 'shared/components/molecules/ContentsArea';
import { channelsIdProcessesChannelProcessNumberDeleteAPI } from 'user/api/channelsProcesses';
import {
  Device,
  deviceIdProcessinfoGetAPI,
  devicesIdGetAPI,
  devicesIdLinkChannelsPostAPI,
  ProcessinfoPaging,
} from 'user/api/device';
import { getStreamsIdDataNumberDownloadGetAPIUrl } from 'user/api/streamsData';
import FileCardDisplayingInferreddata from 'user/components/molecules/FileCardDisplayingInferreddata';
import CreateInferreddataDialog from 'user/pages/Devices/DetailPage/InferreddataPage/CreateInferreddataDialog';
import EditChannelProcessNameDialog from 'user/pages/Devices/DetailPage/InferreddataPage/EditChannelProcessNameDialog';
import InferreddataThumbnailPlayerDialog from 'user/pages/Devices/DetailPage/InferreddataPage/InferreddataThumbnailPlayerDialog';

type Params = RouteComponentProps<{ device_id: string }>;

const Inferreddatas: React.FC<Params> = (params) => {
  const [device, setDevice] = useState<Device | undefined>(undefined);
  const [processinfo, setProcessinfo] = useState<ProcessinfoPaging | undefined>(undefined);
  const [player, setPlayer] = useState<
    | {
        stream_id: string;
        stream_data_number: number;
        root_channel_process_pk: string;
      }
    | undefined
  >(undefined);
  const [edit_channel_process_name_props, setChannelProcessNameProps] = useState<
    | {
        channel_id: string;
        channel_process_number: string;
        channel_process_name: string;
      }
    | undefined
  >(undefined);
  const [is_inferreddata_create_dialog_open, setIsInferreddataCreateDialogOpen] = useState<boolean>(false);

  const loadProcessinfo = useCallback(async () => {
    const res = await deviceIdProcessinfoGetAPI({ device_id: params.match.params.device_id });
    if (res.status === 200) {
      setProcessinfo(res.data);
    }
  }, [params.match.params.device_id]);

  const getDevice = useCallback(async () => {
    const res = await devicesIdGetAPI(params.match.params.device_id);
    if (res.status === 200) {
      setDevice(res.data);
    }
  }, [params.match.params.device_id]);

  const handlePlayClick = (stream_id: string, root_channel_process_pk: string, stream_data_number?: number) => {
    if (typeof stream_data_number === 'number') {
      setPlayer({
        stream_id: stream_id,
        stream_data_number: stream_data_number,
        root_channel_process_pk: root_channel_process_pk,
      });
    }
  };
  const handlePlayerCloseClick = () => {
    setPlayer(undefined);
  };

  const handleChannelProcessNameDialogOpen = (
    channel_id: string,
    channel_process_number: number,
    channel_process_name: string,
  ) => {
    if (typeof channel_process_number === 'number') {
      setChannelProcessNameProps({
        channel_id: channel_id,
        channel_process_number: String(channel_process_number),
        channel_process_name: channel_process_name,
      });
    }
  };
  const handleChannelProcessNameDialogClose = async (canceled?: boolean) => {
    setChannelProcessNameProps(undefined);
    if (!canceled) {
      await loadProcessinfo();
    }
  };

  const handleDownloadMenuClick = (stream_id: string, stream_data_number?: number) => {
    if (typeof stream_data_number === 'number') {
      return getStreamsIdDataNumberDownloadGetAPIUrl({
        stream_id: stream_id,
        stream_data_number: stream_data_number,
      });
    } else {
      return undefined;
    }
  };

  const handleChannelProcessDeleteMenuClick = (channel_id: string, channel_process_number: number) => {
    ConfirmDialog.show('この推論動画を削除してもよろしいですか？', () =>
      deleteChannelProcess(channel_id, channel_process_number),
    );
  };
  const deleteChannelProcess = async (channel_id: string, channel_process_number: number) => {
    const res = await channelsIdProcessesChannelProcessNumberDeleteAPI({
      channel_id: channel_id,
      channel_process_number: String(channel_process_number),
      disabled_load: false,
      ended_load: true,
    });
    if (res.status === 200) {
      AlertDialog.show('推論動画を削除しました。', async () => await loadProcessinfo());
    }
  };

  // -- onload function --
  useEffect(() => {
    (async function () {
      await loadProcessinfo();
      await getDevice();
    })();
  }, [getDevice, loadProcessinfo]);

  // -- render part --
  return device && !device.channel_id ? (
    <TopArea>
      <Title text='' />
      <RoundedButton
        onClick={() => devicesIdLinkChannelsPostAPI({ device_id: params.match.params.device_id })}
        text='デバイスチャンネル紐づけ'
        disabled={device === undefined}
      />
    </TopArea>
  ) : (
    <Content style={{ display: 'flex', flexWrap: 'wrap' }}>
      <TopArea>
        <Title text='' />
        <RoundedButton
          onClick={() => setIsInferreddataCreateDialogOpen(true)}
          text='推論動画作成'
          disabled={device === undefined}
        />
      </TopArea>
      {processinfo?.items ? (
        processinfo.items.map((process, i) => {
          return (
            <FileCardDisplayingInferreddata
              key={i}
              stream_id={process.outputs[0].stream_id}
              stream_data_number={process.outputs[0].stream_data_number}
              stream_data_name={process.channel_process_name}
              handlePlayClick={() =>
                handlePlayClick(
                  process.outputs[0].stream_id,
                  process.root_channel_process_pk,
                  process.outputs[0].stream_data_number,
                )
              }
              handleDownloadMenuClick={() =>
                handleDownloadMenuClick(process.outputs[0].stream_id, process.outputs[0].stream_data_number)
              }
              handleChannelProcessDeleteMenuClick={() =>
                handleChannelProcessDeleteMenuClick(process.channel_id, process.channel_process_number)
              }
              handleChannelProcessNameDialogOpen={() =>
                handleChannelProcessNameDialogOpen(
                  process.channel_id,
                  process.channel_process_number,
                  process.channel_process_name,
                )
              }
            />
          );
        })
      ) : (
        <Spinner />
      )}
      {player && (
        <InferreddataThumbnailPlayerDialog
          device_id={params.match.params.device_id}
          stream_id={player.stream_id}
          stream_data_number={player.stream_data_number}
          root_channel_process_pk={player.root_channel_process_pk}
          isOpen={true}
          onCloseClick={handlePlayerCloseClick}
        />
      )}
      {edit_channel_process_name_props && (
        <EditChannelProcessNameDialog
          channel_id={edit_channel_process_name_props.channel_id}
          channel_process_number={edit_channel_process_name_props.channel_process_number}
          channel_process_name={edit_channel_process_name_props.channel_process_name}
          isOpen={edit_channel_process_name_props !== undefined}
          onClose={handleChannelProcessNameDialogClose}
        />
      )}
      {is_inferreddata_create_dialog_open && device && (
        <CreateInferreddataDialog
          device={device}
          isOpen={is_inferreddata_create_dialog_open}
          onClose={() => setIsInferreddataCreateDialogOpen(false)}
        />
      )}
    </Content>
  );
};

export default Inferreddatas;
