// -- basic library --
import React, { useEffect, useState } from 'react';
import Spinner from 'shared/components/atoms/Spinner';
import { Content } from 'shared/components/molecules/ContentsArea';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableHeaderType } from 'shared/components/molecules/Table/type';

type MetricsCSVType = {
  id: string;
  [key: string]: string;
};

// -- main component --
const MetricsCSV = () => {
  // -- redux preparations --

  // -- local states --
  const [table_bodies, setTableBodies] = useState<MetricsCSVType[] | undefined>(undefined);
  const [selected_bodies, setSelectedBodies] = useState<MetricsCSVType[]>([]);
  const [headers, setHeaders] = useState<TableHeaderType[] | undefined>(undefined);

  // -- functions --
  const getDatas = () => {
    const res = [
      {
        id: '0000001',
        itemA: 'Aaaaaa',
        itemB: 'Bbbbbb',
        itemC: 'Cccccc',
      },
      {
        id: '0000002',
        itemA: 'Xxxxxx',
        itemB: 'Yyyyyy',
        itemC: 'Zzzzzz',
      },
    ];
    const keys = [
      {
        key_name: 'itemA',
        display_name: '項目A',
      },
      {
        key_name: 'itemB',
        display_name: '項目B',
      },
      {
        key_name: 'itemC',
        display_name: '項目C',
      },
    ];
    setTableBodies(res);
    const r: TableHeaderType[] = [];
    keys.forEach((key) => {
      r.push({
        id: key.key_name,
        label: key.display_name,
        width: `calc(100% / ${keys.length})`,
      });
    });
    setHeaders(r);
  };

  const handleCheckClick = (bodies: MetricsCSVType[]) => {
    setSelectedBodies(bodies);
  };

  // -- handlers --

  // -- onload function --
  useEffect(() => {
    (async function () {
      await getDatas();
    })();
  }, []); /* eslint-disable-line */

  // -- render part --
  return (
    <Content>
      {table_bodies !== undefined && headers !== undefined ? (
        <BaseTable
          bodies={table_bodies}
          headers={headers}
          table_name='metricsCSV'
          selected_bodies={selected_bodies}
          handleCheckClick={handleCheckClick}
          id_abridgement
          checkbox_abridgement
        />
      ) : (
        <Spinner />
      )}
    </Content>
  );
};

// -- styled components --

// -- finally export part --

export default MetricsCSV;
