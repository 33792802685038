import React from 'react';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableHeaderType, TableBodyUrlType } from 'shared/components/molecules/Table/type';

interface Params {
  bodies: TableFileProcessesType[];
  selected_bodies: TableFileProcessesType[];
  handleCheckClick: (datas: TableFileProcessesType[]) => void;
  footer_option: {
    text: string;
    handleClick: () => void;
    disabled?: boolean;
  };
}

export type TableFileProcessesType = {
  id: string;
  folder_name: TableBodyUrlType;
};

// -- external functions --

// -- main component --
const FileProcessesTable: React.FC<Params> = (params) => {
  // テーブルの列の情報まとめたデータ
  const headers: TableHeaderType[] = [
    {
      id: 'folder_name',
      label: 'フォルダ名',
      width: 'auto',
      sortable: true,
      search_props: {
        type: 'name',
        default_display: true,
      },
    },
  ];

  // -- render part --
  return (
    <BaseTable
      bodies={params.bodies}
      headers={headers}
      table_name='file-processes'
      selected_bodies={params.selected_bodies}
      handleCheckClick={params.handleCheckClick}
      id_abridgement
      footer_option={params.footer_option}
    />
  );
};

// -- styled components --

// -- finally export part --
export default FileProcessesTable;
