import { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy } from 'shared/utils/converter';
import { CachedStreams, RequestStreamsGet, Stream } from 'user/api/streams';

/**
 * ストリーム一覧を取得するcustom hooks
 */
export const useStreams = ({
  device_id,
  data_types,
  data_number_types,
  with_output_streams,
  with_in_channel_streams,
  exclusive_start_stream_id,
}: RequestStreamsGet) => {
  const [streams, setStreams] = useState<Stream[] | undefined>(undefined);
  const streams_record: Record<string, Stream> = useMemo(() => {
    return streams ? keyBy(streams, (s) => s.stream_id) : {};
  }, [streams]);
  const loadStreams = useCallback(
    async (request?: RequestStreamsGet) => {
      const r =
        request ?? {
          device_id,
          data_types,
          data_number_types,
          with_output_streams,
          with_in_channel_streams,
          exclusive_start_stream_id,
        } ??
        {};
      const cachedStreams = new CachedStreams(r);
      const new_streams = await cachedStreams.get();
      setStreams(new_streams);
    },
    [device_id, data_types, data_number_types, with_output_streams, with_in_channel_streams, exclusive_start_stream_id],
  );

  useEffect(() => {
    loadStreams();
  }, [loadStreams]);

  return useMemo(() => {
    return {
      streams,
      streams_record,
      setStreams,
      loadStreams,
    };
  }, [streams, setStreams, streams_record, loadStreams]);
};
