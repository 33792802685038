import React from 'react';
import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import RoundedCard from 'shared/components/atoms/RoundedCard';
import { ProcessInfo, streamsIdDataNumberProcessinfoGetAPI } from 'user/api/streamsProcessInfo';
import StreamThumbnailImage from './StreamThumbnailImage';

/**
 * フォルダチャンネル/ファイル一覧で表示するストリームデータを表示するカード
 */
interface FileCardDisplayingInferreddataProps {
  stream_id: string;
  stream_data_number?: number;
  stream_data_name: string;
  handleChannelProcessDeleteMenuClick: () => void;
  handleDownloadMenuClick: () => string | undefined;
  handlePlayClick?: () => void;
  handleChannelProcessNameDialogOpen?: () => void;
}
interface FileCardDisplayingInferreddataState {
  is_open: boolean;
  is_process_result_open: boolean;
  process_infos?: ProcessInfo[];
  message: string | JSX.Element | string[];
  callback?: () => void;
}
export default class FileCardDisplayingInferreddata extends React.PureComponent<
  FileCardDisplayingInferreddataProps,
  FileCardDisplayingInferreddataState
> {
  constructor(props: FileCardDisplayingInferreddataProps) {
    super(props);
    this.state = {
      is_open: false,
      is_process_result_open: false,
      message: '',
      process_infos: undefined,
    };
  }
  private getStreamProcessinfo = () => {
    if (!this.props.stream_data_number) return;
    streamsIdDataNumberProcessinfoGetAPI({
      stream_id: this.props.stream_id,
      stream_data_number: this.props.stream_data_number,
    }).then((res) => {
      if (res.status === 200) {
        this.setState({
          process_infos: res.data.processes,
        });
      }
    });
  };
  componentDidMount() {
    this.getStreamProcessinfo();
  }
  componentDidUpdate(prevProps: FileCardDisplayingInferreddataProps) {
    // propsが変化したら、データを再ロードします
    if (this.props.stream_data_number !== prevProps.stream_data_number) {
      this.getStreamProcessinfo();
    }
  }
  onClose() {
    this.setState({ is_open: false, is_process_result_open: false });
    if (this.state.callback) {
      this.state.callback();
    }
  }
  render() {
    const cardStyle: React.CSSProperties = {
      width: 320,
      height: 320,
      padding: '20px',
      margin: '15px',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
    };
    return (
      <RoundedCard
        style={cardStyle}
        onClick={this.props.handlePlayClick}
        key={this.props.stream_data_number}
        menu={
          <Menu>
            <MenuItem text='詳細' onClick={this.props.handlePlayClick} />
            <MenuDivider />
            <MenuItem text='推論動画名の編集' onClick={this.props.handleChannelProcessNameDialogOpen} />
            <MenuDivider />
            <MenuItem text='ダウンロード' href={this.props.handleDownloadMenuClick()} />
            <MenuDivider />
            <MenuItem onClick={this.props.handleChannelProcessDeleteMenuClick} text='削除' />
          </Menu>
        }
      >
        <div style={{ height: 240, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {this.props.stream_data_number ? (
            <StreamThumbnailImage
              stream_id={this.props.stream_id}
              stream_data_number={this.props.stream_data_number}
              style={{ maxWidth: 280, maxHeight: 240 }}
            />
          ) : (
            <></>
          )}
        </div>
        <div style={{ flexGrow: 1 }}>{this.props.stream_data_name}</div>
      </RoundedCard>
    );
  }
}
