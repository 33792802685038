import React, { useState, useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Content, BottomArea } from 'shared/components/molecules/ContentsArea';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableHeaderType, TableBodyMultipleValueType, TableBodyUrlType } from 'shared/components/molecules/Table/type';
import ToggleButton from 'shared/components/molecules/ToggleButton';
import { streamsIdPutAPI } from 'user/api/streams';
import { systemSelector } from 'user/redux/slices/systemSlice';
import { useStreams } from 'user/hooks/useStreams/useStreams';
import { useChannel } from 'user/hooks/useChannel/useChannel';

// -- type declaration --

type Params = RouteComponentProps<{ channel_id: string }>;

type TableChannelOutputStreamType = {
  id: string;
  output_stream_name: TableBodyUrlType;
  data_number_type: string;
  data_type: string;
  toggle_public: TableBodyMultipleValueType<string>;
};

// テーブルの列の情報まとめたデータ
const headers: TableHeaderType[] = [
  {
    id: 'output_stream_name',
    label: '出力データ名',
    sortable: true,
    width: 'auto',
    search_props: {
      type: 'name',
      default_display: true,
    },
  },
  {
    id: 'data_number_type',
    label: 'データ番号の基準',
    sortable: true,
    width: '15%',
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'data_type',
    label: 'データの種類',
    sortable: true,
    width: '15%',
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'toggle_public',
    label: '可視性',
    width: '100px',
    search_props: {
      type: 'name',
    },
  },
];

// -- external functions --

// -- main component --
const ChannelsIdDetailOutputStreams: React.FC<Params> = (params) => {
  // -- redux preparations --
  const system_state = useSelector(systemSelector);

  // local_states
  const [selected_bodies, setSelectedBodies] = useState<TableChannelOutputStreamType[]>([]);
  const { streams_record, loadStreams } = useStreams({ with_output_streams: 'True', with_in_channel_streams: 'True' });
  const { channel } = useChannel({ channel_id: params.match.params.channel_id });
  const output_stream_ids = useMemo(() => {
    return channel?.output_stream_ids;
  }, [channel]);

  // テーブル関係の関数
  const handleClick = (bodies: TableChannelOutputStreamType[]) => {
    setSelectedBodies(bodies);
  };

  // 出力ストリームの一覧可視性の変更
  const handleItemPublicClick = useCallback(
    async (stream_id: string, next_public: boolean) => {
      const stream = streams_record[stream_id];
      if (!stream) return;
      const channel_id = params.match.params.channel_id;
      if (!next_public) {
        for (const v of stream.input_channel_processes) {
          if (!v.startsWith(channel_id + ':')) {
            AlertDialog.show('このデータは他のチャンネルから参照されているため、非公開にできません。');
            return;
          }
        }
        for (const v of stream.input_channel_event_conditions) {
          if (!v.startsWith(channel_id + ':')) {
            AlertDialog.show('このデータは他のチャンネルから参照されているため、非公開にできません。');
            return;
          }
        }
      }
      const res = await streamsIdPutAPI({
        stream_id: stream_id,
        listing_visibility: next_public ? 'OUTPUT' : 'IN_CHANNEL',
      });
      if (res.status === 200) {
        AlertDialog.show('可視性を変更しました');
        await loadStreams();
      }
    },
    [loadStreams, params.match.params.channel_id, streams_record],
  );

  // テーブルのデータ
  const table_bodies: TableChannelOutputStreamType[] | undefined = useMemo(() => {
    return output_stream_ids?.map((osid) => {
      const stream = streams_record[osid];
      return {
        id: osid,
        output_stream_name: {
          value: stream ? stream.stream_name : '',
          url: `/streams/${osid}/details`,
        },
        data_number_type: stream ? stream.data_number_type : '',
        data_type: stream ? stream.data_type : '',

        toggle_public: {
          value: stream?.listing_visibility ?? '',
          available_value: stream?.listing_visibility === 'OUTPUT' ? 'ON' : 'OFF',
          displayable_value: (
            <ToggleButton
              stop_propagation={true}
              onClick={() => handleItemPublicClick(osid, stream?.listing_visibility !== 'OUTPUT')}
              checked={stream?.listing_visibility === 'OUTPUT'}
            />
          ),
        },
      };
    });
  }, [streams_record, output_stream_ids, handleItemPublicClick]);

  // -- render part --
  return (
    <Content>
      {!system_state.loading.preloading && (
        <>
          <BottomArea>
            {table_bodies !== undefined ? (
              <BaseTable
                bodies={table_bodies}
                headers={headers}
                table_name='channelsOutputStreams'
                selected_bodies={selected_bodies}
                handleCheckClick={handleClick}
                id_abridgement
              />
            ) : (
              <Spinner />
            )}
          </BottomArea>
        </>
      )}
    </Content>
  );
};

// -- styled components --

// -- finally export part --
export default ChannelsIdDetailOutputStreams;
