import React from 'react';

/** タイムライン表示（現在は非対応のため null 返し） */
export default class TimelinePane extends React.PureComponent<React.HTMLAttributes<HTMLDivElement>> {
  render() {
    return (
      // <PaneBox {...this.props} paneTitle="Timeline">Body</PaneBox>
      null
    );
  }
}
