import { AxiosResponse } from 'axios';
import { StreamDataFileType } from 'shared/models';
import { CommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';
import { Query } from 'shared/models/Query';
import sendAxios from '../../shared/axios/sendAxios';
import { getClient } from './base';
import sendDownloadAxios from 'shared/axios/sendDownloadAxios';
import { StringBoolean } from 'shared/models/StringBoolean';
import { ISO8601 } from 'shared/models/ISO8601';
import { StatusType } from 'shared/models/StatusType';

/*** エンティティ ***/

export interface StreamData {
  tenant_id: string;
  stream_id: string;
  stream_data_number: number;
  stream_data_name: string;
  original_file_name?: string;
  status: StatusType;
  data_size: number;
  is_download_ready: boolean;
  is_ready_for_process: boolean;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

export interface StreamsDatasWithPaging {
  items: StreamData[];
  has_next: boolean;
}

const sample_stream_data_1: StreamData = {
  tenant_id: '00000001',
  stream_id: '00000001',
  stream_data_number: 1,
  stream_data_name: 'ストリーム1',
  original_file_name: 'テスト01',
  status: 'ACTIVE',
  data_size: 1701000000,
  is_download_ready: true,
  is_ready_for_process: true,
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: null,
};

const sample_stream_data_2: StreamData = {
  tenant_id: '00000001',
  stream_id: '00000002',
  stream_data_number: 2,
  stream_data_name: 'ストリーム2',
  original_file_name: 'テスト02',
  status: 'ACTIVE',
  data_size: 1701000000,
  is_download_ready: true,
  is_ready_for_process: true,
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: null,
};

export interface StreamDatasWithPaging {
  items: StreamData[];
  has_next: boolean;
  last_evaluated_stream_data_number?: number;
}

export interface StreamDataReplacementItems {
  camera_id: string[];
  line_no: string[];
}

const sample_stream_data_replacement_items_1: StreamDataReplacementItems = {
  camera_id: ['c0', 'c1'],
  line_no: ['LINE_0', 'LINE_1'],
};

/*** Caching mechanism ***/

export class CachedStreamDatas {
  private searched = false;
  private cache: StreamData[] = [];
  private params: RequestStreamsIdDataGet;
  constructor(params: RequestStreamsIdDataGet) {
    this.params = params;
  }
  async get() {
    if (!this.searched) {
      let esk: string | number | undefined = undefined;
      let has_next = true;
      while (has_next) {
        const res: AxiosResponse<StreamDatasWithPaging> = await streamsIdDataGetAPI({
          ...this.params,
          exclusive_start_key: esk,
        });
        has_next = false;
        if (res.status === 200) {
          this.cache = [...this.cache, ...res.data.items];
          has_next = res.data.has_next;
          esk = res.data.last_evaluated_stream_data_number;
        }
      }
      this.searched = true;
    }
    return this.cache;
  }
}

/*** [POST] /api/streams/{id}/data/upload ***/

export interface RequestStreamsIdDataUploadPost extends CommonAPIRequestType {
  stream_id: string;
  stream_data_name?: string;
  stream_data_number?: number | null;
  original_file_name?: string;
  multipart_upload?: 'True';
  add_dimension?: StringBoolean;
  desired_fps?: string;
}

export const streamsIdDataUploadPostAPI = (params: RequestStreamsIdDataUploadPost) => {
  const {
    stream_id,
    stream_data_name,
    stream_data_number,
    original_file_name,
    multipart_upload,
    add_dimension,
    disabled_load,
    disabled_error_message,
    ended_load,
    desired_fps,
  } = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/streams/${stream_id}/data/upload`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();

  for (const [key, value] of Object.entries({
    stream_data_name,
    stream_data_number,
    original_file_name,
    multipart_upload,
    add_dimension,
    desired_fps,
  })) {
    if (typeof value === 'string' || typeof value === 'number') form.append(key, String(value));
  }

  // 送信
  return sendAxios<{ s3_transfer_id: string; upload_url: string | null }>(axios, path, query, form, method, {
    s3_transfer_id: '000000001',
    upload_url: 'sample_url',
  });
};

/*** [PUT] /api/streams/{stream_id}/data/{stream_data_number}/upload ***/

export interface RequestStreamsIdDataNumUploadPut {
  stream_data: StreamDataFileType;
  upload_url: string;
}

export const streamsIdDataNumUploadPutAPI = async (params: RequestStreamsIdDataNumUploadPut) => {
  // クライアントを定義
  const axios = getClient({
    responseType: 'arraybuffer',
    header_options: { 'X-Amz-Acl': 'bucket-owner-full-control' },
  });

  // パス・メソッドを定義
  const path = params.upload_url;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query = {};

  // resultが他のapiのformの代わり
  let result: string | ArrayBuffer | null = '';

  // fileデータを読み込んでarraybufferに変換する処理
  const fileLoader = new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      result = reader.result;
      resolve(result);
    };
    try {
      reader.readAsArrayBuffer(params.stream_data.file);
    } catch {
      console.log('fileの読み込みに失敗');
    }
  });
  await fileLoader;

  // 送信
  return sendAxios<ArrayBuffer>(axios, path, query, result, method, new ArrayBuffer(8));
};

/*** [GET] /api/streams/{stream_id}/data ***/

export interface RequestStreamsIdDataGet extends CommonAPIRequestType {
  stream_id: string;
  limit?: number;
  status?: StatusType;
  exclusive_start_key?: string | number;
  scan_index_forward?: StringBoolean;
}

export const streamsIdDataGetAPI = (params: RequestStreamsIdDataGet) => {
  const {
    stream_id,
    limit,
    status,
    exclusive_start_key,
    scan_index_forward,
    disabled_load,
    disabled_error_message,
    ended_load,
  } = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/streams/${stream_id}/data`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    limit,
    status,
    exclusive_start_key,
    scan_index_forward,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<StreamDatasWithPaging>(axios, path, query, form, method, {
    items: new Array(100).fill(null).map((_, i) => {
      if (i % 2 === 0) {
        return { ...sample_stream_data_1, stream_data_number: i };
      } else {
        return { ...sample_stream_data_2, stream_data_number: i };
      }
    }),
    has_next: false,
    last_evaluated_stream_data_number: 2,
  });
};

/*** [GET] /api/streams/{stream_id}/data/{stream_data_number} ***/

export interface RequestStreamsIdDataNumberGet {
  stream_id: string;
  stream_data_number: number;
}

export const streamsIdDataNumberGetAPI = (params: RequestStreamsIdDataNumberGet) => {
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `/api/streams/${params.stream_id}/data/${params.stream_data_number}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<StreamData>(axios, path, query, form, method, sample_stream_data_1);
};

/*** [GET] /api/streams/{stream_id}/data/{stream_data_number} ***/

export interface RequestStreamsIdDataNumberFileGet {
  stream_id: string;
  stream_data_number: number;
}

export const streamsIdDataNumberFileGetAPI = (params: RequestStreamsIdDataNumberFileGet) => {
  // クライアントを定義
  const axios = getClient({ responseType: 'arraybuffer' });

  // パス・メソッドを定義
  const path = `/api/streams/${params.stream_id}/data/${params.stream_data_number}/file`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<ArrayBuffer>(axios, path, query, form, method, new ArrayBuffer(8));
};

/*** [GET] /api/streams/{stream_id}/data/{stream_data_number}/download ***/

export interface RequestStreamsIdDataNumberDownloadGet extends CommonAPIRequestType {
  stream_id: string;
  stream_data_number?: string | number;
  original_file_name?: string;
}
/** streamsIdDataNumberDownloadGetAPI のアクセスURLを生成します。 */
export function getStreamsIdDataNumberDownloadGetAPIUrl(params: RequestStreamsIdDataNumberDownloadGet) {
  let original_file_name = '';
  if (params.original_file_name) {
    original_file_name = encodeURIComponent(params.original_file_name);
  }
  return `/api/streams/${params.stream_id}/data/${params.stream_data_number}/download?original_file_name=${original_file_name}`;
}
export const streamsIdDataNumberDownloadGetAPI = (params: RequestStreamsIdDataNumberDownloadGet) => {
  const { disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'multipleTransmission',
  });
  // クライアントを定義
  const axios = getClient({ responseType: 'blob', disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = getStreamsIdDataNumberDownloadGetAPIUrl(params);
  // const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendDownloadAxios(axios, path, query, form, new ArrayBuffer(8));
};

/*** [GET] /api/streams/{stream_id}/data/{stream_data_number}/thumbnail ***/

export interface RequestStreamsIdDataNumberThumbnailGet {
  stream_id: string;
  stream_data_number: string;
  stream_type: string | undefined;
}

export const streamsIdDataNumberThumbnailGetAPI = (params: RequestStreamsIdDataNumberThumbnailGet) => {
  // クライアントを定義
  const axios = getClient({ responseType: 'arraybuffer' });

  // パス・メソッドを定義
  const path = `/api/streams/${params.stream_id}/data/${params.stream_data_number}/thumbnail`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<ArrayBuffer>(axios, path, query, form, method, new ArrayBuffer(8));
};

/*** [GET] /api/streams/{stream_id}/data/replacement/items ***/

export interface RequestStreamsIdDataReplacementItemsGet {
  stream_id: string;
}

export const streamsIdDataReplacementItemsGet = (params: RequestStreamsIdDataReplacementItemsGet) => {
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `/api/streams/${params.stream_id}/data/replacement/items`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<StreamDataReplacementItems>(
    axios,
    path,
    query,
    form,
    method,
    sample_stream_data_replacement_items_1,
  );
};

/** 動画再生のURL生成 */
export function streamsIdDataNumberM3u8Url(streamId: string, streamDataNumber: number) {
  return '/api/streams/' + streamId + '/data/' + streamDataNumber + '/play.m3u8';
}

/*** [PUT] /api/streams/{stream_id}/data/{stream_data_number} ***/

export interface RequestStreamsIdDataNumberPut {
  stream_id: string;
  stream_data_number: number;
  stream_data_name: string;
}

export const streamsIdDataNumberPutAPI = (params: RequestStreamsIdDataNumberPut) => {
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `/api/streams/${params.stream_id}/data/${params.stream_data_number}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'stream_id' || key === 'stream_data_number') {
      continue;
    }
    form.append(key, value);
  }

  // 送信
  return sendAxios<StreamData>(axios, path, query, form, method, sample_stream_data_1);
};

/*** [DELETE] /api/streams/{stream_id}/data/{stream_data_number} ***/

export interface RequestStreamsIdDataNumberDelete extends CommonAPIRequestType {
  stream_id: string;
  stream_data_number: number;
}

export const streamsIdDataNumberDeleteAPI = (params: RequestStreamsIdDataNumberDelete) => {
  const { stream_id, stream_data_number, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'multipleTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/streams/${stream_id}/data/${stream_data_number}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<StreamData>(axios, path, query, form, method, sample_stream_data_1);
};
