import { StreamDeviceGraph } from 'shared/models/StreamDeviceGraph';

/** formにdevice_graphsというkey: valueを開いて渡す。**/
export const addDeviceGraphsToFormData = (form: FormData, device_graphs?: StreamDeviceGraph[]): FormData => {
  if (device_graphs === undefined) return form;
  const new_form = form;
  // 空配列に更新したい時は、device_graphs: 'delete'をformに送信する。
  if (device_graphs.length === 0) {
    new_form.append('device_graphs', 'delete');
    return new_form;
  }
  for (let i = 0; i < device_graphs.length; i++) {
    const device_graph = device_graphs[i];
    for (const [key, value] of Object.entries(device_graph)) {
      if (value !== undefined && value !== null) {
        new_form.append('device_graphs[' + i + '].' + key, String(value));
      }
    }
  }
  return new_form;
};
