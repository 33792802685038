import React, { useEffect, useState } from 'react';
import history from 'shared/browserHistory';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { PageWholeArea, Title, TopArea, BottomArea } from 'shared/components/molecules/ContentsArea';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import { isNotSelected, isSelected } from 'shared/utils/is';
import { dateToYMD } from 'shared/utils/converter/date';
import { Channel } from 'user/api/channels';
import { channelsIdDeleteAPI, CachedChannels } from 'user/api/channels';
import {
  channelsIdEventConditionsChannelEventConditionNumberDeleteAPI,
  CachedChannelEventConditions,
} from 'user/api/channelsEventConditions';
import { channelsIdProcessesChannelProcessNumberDeleteAPI, CachedChannelProcesses } from 'user/api/channelsProcesses';
import ChannelThumbnailImage from 'user/components/molecules/ChannelThumbnailImage';
import { loadWrapperFunc } from 'user/utils/loadWrapperFunc';
import ChannelsCreateDialog from '../CreateDialog/ChannelsCreate';
import { TableChannelsType } from './ChannelsTable';
import ChannelsTable from './ChannelsTable';

// -- main component --
const Channels: React.FC = () => {
  // -- local states --
  const [table_bodies, setTableBodies] = useState<TableChannelsType[] | undefined>(undefined);
  const [selected_bodies, setSelectedBodies] = useState<TableChannelsType[]>([]);

  // 新規チャンネル追加用states
  const [open_dialog, setOpenDialog] = useState<boolean>(false);

  // -- handlers --

  const loadTableBodies = async () => {
    const table_datas: TableChannelsType[] = [];
    const channel_items: Channel[] = await new CachedChannels({ channel_type: 'STANDARD' }).get();
    for (let i = 0; i < channel_items.length; i++) {
      const table_data: TableChannelsType = {
        id: channel_items[i].channel_id,
        img_element: (
          <ChannelThumbnailImage
            channel_id={channel_items[i].channel_id}
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 70,
            }}
          />
        ),
        channel_name: {
          value: channel_items[i].channel_name,
          url: `/channels/${channel_items[i].channel_id}/overviews`,
        },
        created_at: dateToYMD(channel_items[i].created_at),
      };
      table_datas.push(table_data);
    }
    setTableBodies(table_datas);
  };

  const handleClick = (datas: TableChannelsType[]) => {
    setSelectedBodies(datas);
  };

  const handleCreateClick = () => {
    setOpenDialog(true);
  };

  const channnelAlarmDelete = async (channel_id: string) => {
    const channel_event_conditions = await new CachedChannelEventConditions({ channel_id }).get();

    // 1つのチャンネルのイベント条件データを番号後ろから削除
    for (let j = channel_event_conditions.length - 1; j >= 0; j--) {
      const res_event_condition_delete = await channelsIdEventConditionsChannelEventConditionNumberDeleteAPI({
        channel_id: channel_event_conditions[j].channel_id,
        channel_event_condition_number: channel_event_conditions[j].channel_event_condition_number,
      });
      if (res_event_condition_delete.status !== 200) return;
    }
  };

  const channnelProcessDelete = async (channel_id: string) => {
    const channel_processes = await new CachedChannelProcesses({ channel_id }).get();

    // 1つのチャンネルのプロセスデータを番号後ろから削除
    for (let j = channel_processes.length - 1; j >= 0; j--) {
      const res_process_delete = await channelsIdProcessesChannelProcessNumberDeleteAPI({
        channel_id: channel_processes[j].channel_id,
        channel_process_number: String(channel_processes[j].channel_process_number),
      });
      if (res_process_delete.status !== 200) return;
    }
  };

  const handleOKClick = async (selected_datas: TableChannelsType[]) => {
    const deletes = async () => {
      for (let i = 0; i < selected_datas.length; i++) {
        // 1つのチャンネルのプロセスデータを削除
        await channnelAlarmDelete(selected_datas[i].id);
        // 1つのチャンネルのイベント条件データを削除
        await channnelProcessDelete(selected_datas[i].id);

        // 1つのチャンネルのデータを削除
        const channel_delete = await channelsIdDeleteAPI({ channel_id: selected_datas[i].id });
        if (channel_delete.status !== 200) continue;
      }
      await loadTableBodies();
      AlertDialog.show('チャンネルの削除に成功しました');
      setSelectedBodies([]);
    };
    await loadWrapperFunc(deletes);
  };

  const handleCancelClick = () => {
    history.push('/channels');
  };

  const handleDeleteClick = () => {
    ConfirmDialog.show(
      <div style={{ color: colors.red }}>
        [確認]選択されているチャンネルを
        <br />
        チャンネルプロセス、チャンネルイベント条件を含めて
        <br />
        削除する必要があります。
        <br />
        これらを全て削除して本当によろしいですか?
      </div>,
      () => handleOKClick(selected_bodies),
      handleCancelClick,
      undefined,
    );
  };

  // チャンネル追加用関数
  const handleDialogCloseClick = async () => {
    setOpenDialog(false);
    await loadTableBodies();
  };

  // -- onload function --
  useEffect(() => {
    loadTableBodies();
  }, []);

  // -- render part --

  return (
    <PageWholeArea data-testid='Channel'>
      <TopArea>
        <Title text='チャンネル設定' />
        <RoundedButton
          onClick={handleCreateClick}
          text='チャンネル作成'
          style={{ marginRight: styles.interval_narrow_margin }}
          disabled={isSelected(selected_bodies.length)}
        />
      </TopArea>
      <BottomArea>
        {table_bodies !== undefined ? (
          <ChannelsTable
            bodies={table_bodies}
            selected_bodies={selected_bodies}
            handleClick={handleClick}
            footer_option={{
              text: '＞チェックしたチャンネルを削除する',
              handleClick: handleDeleteClick,
              disabled: isNotSelected(selected_bodies.length),
            }}
          />
        ) : (
          <Spinner />
        )}
        <PfDialog isOpen={open_dialog} onClose={handleDialogCloseClick} title='チャンネル作成'>
          <ChannelsCreateDialog handleDialogCloseClick={handleDialogCloseClick} />
        </PfDialog>
      </BottomArea>
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default Channels;
