// <input type="datetime-local"> をタイムスタンプ数値で入出力できるようにしたもの
// valueはストリームデータ番号をそのまま使用できます。

import React, { useCallback, useMemo } from 'react';
import { toISO8601 } from 'shared/models/ISO8601';
import { dateToTimeStamp } from 'shared/utils/converter/date';
import InputBox from './InputBox';

export interface DatetimeInputProps extends React.InputHTMLAttributes<HTMLInputElement> {}
export default function DatetimeInput({
  value: props_value,
  onChange: propsOnChange,
  ...remain_props
}: DatetimeInputProps) {
  // value値をUNIX時刻からISO8601形式に変換して返します。
  const value = useMemo(() => {
    if (props_value === undefined) return '';
    if (typeof props_value === 'string' || typeof props_value === 'number') {
      if (props_value === '' || props_value === 'undefined')
        // valueを設定している時にundefinedとなると<input/>がエラーを起こすためから文字列を返却する
        return '';
      return toISO8601(+props_value * 1000, 'NONE');
    }
    // valueを設定している時にundefinedとなると<input/>がエラーを起こすためから文字列を返却する
    return '';
  }, [props_value]);
  // onChange イベントは上書きされ、ISO8601形式からUNIX時刻に戻されます。
  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!propsOnChange) return;
      // 空文字列の時はそのまま返却
      const new_value = event.currentTarget.value === '' ? '' : String(dateToTimeStamp(event.currentTarget.value));
      event.currentTarget = {
        ...event.currentTarget,
        value: new_value,
      };
      propsOnChange(event);
    },
    [propsOnChange],
  );
  // type は継承できず、datetime-localに限定
  const new_props = { ...remain_props, type: 'datetime-local', value, onChange };

  return <InputBox {...new_props} />;
}
