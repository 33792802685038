import { ProcessOutputType } from 'shared/models/ProcessOutputType';
import { getStreamsIdDataNumberDownloadGetAPIUrl, streamsIdDataNumberDownloadGetAPI } from 'user/api/streamsData';
import {
  getStreamsIdPackagesNumberDownloadGetAPIUrl,
  streamsIdPackagesNumberDownloadGetAPI,
} from 'user/api/streamsPackage';

export interface getProcessOutputDLUrlProps {
  stream_id: string;
  output_type: ProcessOutputType;
  stream_package_number?: number;
  stream_data_number?: number;
  original_file_name?: string;
}
/** プロセスのアウトプットダウンロード用のurlを取得する関数**/
export const getProcessOutputDLUrl = (props: getProcessOutputDLUrlProps): string => {
  let url = '';
  if (
    props.output_type === 'PACKAGE' &&
    typeof props.stream_package_number === 'number' &&
    props.stream_package_number > 0
  ) {
    url = getStreamsIdPackagesNumberDownloadGetAPIUrl({
      stream_id: props.stream_id,
      stream_package_number: props.stream_package_number,
    });
  } else if (
    props.output_type === 'DATA' &&
    typeof props.stream_data_number === 'number' &&
    props.stream_data_number > 0
  ) {
    url = getStreamsIdDataNumberDownloadGetAPIUrl({
      stream_id: props.stream_id,
      stream_data_number: props.stream_data_number,
      original_file_name: props.original_file_name,
    });
  }
  return url;
};

export interface getProcessOutputDLTextProps {
  output_type: ProcessOutputType;
  stream_package_number?: number;
  stream_data_number?: number;
}
/** プロセスのアウトプットダウンロード用のテキストを取得する関数**/
export const getProcessOutputDLText = (props: getProcessOutputDLTextProps): string => {
  let text = '';
  if (
    props.output_type === 'PACKAGE' &&
    typeof props.stream_package_number === 'number' &&
    props.stream_package_number > 0
  ) {
    text = 'CSV';
  } else if (
    props.output_type === 'DATA' &&
    typeof props.stream_data_number === 'number' &&
    props.stream_data_number > 0
  ) {
    text = 'MP4';
  }
  return text;
};

export interface downloadProcessOutputProps {
  stream_id: string;
  output_type: ProcessOutputType;
  stream_package_number?: number;
  stream_data_number?: number;
  original_file_name?: string;
}
/** プロセスのアウトプットをダウンロードする関数**/
export const downloadProcessOutput = async (props: downloadProcessOutputProps): Promise<void> => {
  if (
    props.output_type === 'PACKAGE' &&
    typeof props.stream_package_number === 'number' &&
    props.stream_package_number > 0
  ) {
    await streamsIdPackagesNumberDownloadGetAPI({
      stream_id: props.stream_id,
      stream_package_number: props.stream_package_number,
    });
  } else if (
    props.output_type === 'DATA' &&
    typeof props.stream_data_number === 'number' &&
    props.stream_data_number > 0
  ) {
    await streamsIdDataNumberDownloadGetAPI({
      stream_id: props.stream_id,
      stream_data_number: props.stream_data_number,
      original_file_name: props.original_file_name,
    });
  }
};
