import { CommonAPIRequestType, isCommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';
import { ISO8601 } from 'shared/models/ISO8601';
import { ProcessOutputType } from 'shared/models/ProcessOutputType';
import { Query } from 'shared/models/Query';
import { SoracomEnvFreeParamType } from 'shared/models/Soracom';
import { StatusType } from 'shared/models/StatusType';

import sendAxios from 'shared/axios/sendAxios';
import { getClient } from './base';
import { SoracomStatus } from 'shared/models/SoracomStatus';
import { AxiosResponse } from 'axios';

/*** エンティティ ***/

export interface SoracomParameter {
  SORACOM_ENV_MODE: number;
  SORACOM_ENV_WAIT: number;
  SORACOM_ENV_CROP_TOP: number;
  SORACOM_ENV_CROP_BOTTOM: number;
  SORACOM_ENV_CROP_LEFT: number;
  SORACOM_ENV_CROP_RIGHT: number;
  SORACOM_ENV_UPLOAD_SIZE_W: number;
  SORACOM_ENV_UPLOAD_SIZE_H: number;
  SORACOM_ENV_URI: string;
  SORACOM_EXEC_PARAM: string;
  SORACOM_ENV_FREE_PARAM: SoracomEnvFreeParamType;
}

export interface Device {
  tenant_id: string;
  channel_id?: string;
  input_stream_id?: string;
  graph_stream_id?: string;
  stream_device_graph_id?: string;
  device_id: string;
  device_name: string;
  device_type: string;
  soracom_model_id: string;
  soracom_device_id: string;
  soracom_device_imsi: string;
  status: StatusType;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
  soracom_status: SoracomStatus | null;
  soracom_parameter: SoracomParameter | null;
}

export interface DevicesWithPaging {
  items: Device[];
  has_next: boolean;
}

export interface Processinfo {
  channel_id: string;
  channel_process_number: number;
  channel_process_name: string;
  root_channel_process_pk: string;
  processing_progress: number;
  outputs: ProcessinfoOutput[];
}

export interface ProcessinfoOutput {
  output_type: ProcessOutputType;
  data_type: string;
  stream_id: string;
  stream_data_number?: number;
  stream_package_number?: number;
  downloadable: boolean | null;
}

export interface ProcessinfoPaging {
  items: Processinfo[];
  has_next: boolean;
}

const sample_device_1: Device = {
  tenant_id: '00000001',
  device_id: '00000001',
  channel_id: '00000001',
  input_stream_id: '00000001',
  device_name: 'device_1',
  device_type: 'SORACOM',
  soracom_model_id: '00000001',
  soracom_device_id: 'd-samplesample',
  soracom_device_imsi: '112233445566778',
  graph_stream_id: '00000001',
  stream_device_graph_id: 'LINE0_LR_GENDER_CIRCLE',
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: null,
  soracom_status: {
    device_name: 'soracom_device_1',
    sim_is_online: true,
    is_powered_on: true,
    application_is_working: true,
    last_modified_time: 1577804400,
    application_name: 'sample_app_1',
    application_version: '1.0.0',
    application_deployed_at: '2020-01-01T00:00:00+09:00' as ISO8601,
    application_launched_at: '2020-01-01T00:00:00+09:00' as ISO8601,
    updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  },
  soracom_parameter: {
    SORACOM_ENV_MODE: 0,
    SORACOM_ENV_WAIT: 60,
    SORACOM_ENV_CROP_TOP: 100,
    SORACOM_ENV_CROP_BOTTOM: 100,
    SORACOM_ENV_CROP_LEFT: 30,
    SORACOM_ENV_CROP_RIGHT: 300,
    SORACOM_ENV_UPLOAD_SIZE_W: 400,
    SORACOM_ENV_UPLOAD_SIZE_H: 800,
    SORACOM_ENV_URI: '',
    SORACOM_EXEC_PARAM: '',
    SORACOM_ENV_FREE_PARAM: {
      CROSSLINE: [
        [
          [120, 100],
          [220, 10],
        ],
      ],
      ROTATE: 0,
    },
  },
};

const sample_processinfo1: Processinfo = {
  channel_id: '000000001',
  channel_process_number: 1,
  channel_process_name: 'プロセス1',
  root_channel_process_pk: '00000001:00000001:1',
  processing_progress: 100,
  outputs: [
    {
      output_type: 'DATA',
      data_type: 'VIDEO',
      stream_id: '000000001',
      stream_data_number: 1,
      downloadable: true,
    },
    {
      output_type: 'PACKAGE',
      data_type: 'METRIC',
      stream_id: '000000001',
      stream_package_number: 2,
      downloadable: false,
    },
  ],
};

const sample_processinfo2: Processinfo = {
  channel_id: '000000002',
  channel_process_number: 2,
  channel_process_name: 'プロセス2',
  root_channel_process_pk: '00000001:00000001:2',
  processing_progress: 80,
  outputs: [
    {
      output_type: 'DATA',
      data_type: 'VIDEO',
      stream_id: '000000001',
      stream_data_number: 3,
      downloadable: true,
    },
    {
      output_type: 'PACKAGE',
      data_type: 'METRIC',
      stream_id: '000000001',
      stream_package_number: 4,
      downloadable: null,
    },
  ],
};

/*** Caching mechanism ***/

export class CachedDevices {
  private searched = false;
  private cache: Device[] = [];
  private params: RequestDevicesGet;
  constructor(params: RequestDevicesGet) {
    this.params = params;
  }
  async get() {
    if (!this.searched) {
      const esk: string | undefined = undefined;
      const status = this.params.status ?? 'ACTIVE';
      const res: AxiosResponse<DevicesWithPaging> = await devicesGetAPI({
        status,
        exclusive_start_device_id: esk,
      });
      // ページング非対応
      if (res.status === 200) {
        this.cache = [...this.cache, ...res.data.items];
      }
      this.searched = true;
    }
    return this.cache;
  }
}

/*** [GET] /api/devices ***/

export interface RequestDevicesGet {
  status?: StatusType;
  exclusive_start_device_id?: string;
}

export const devicesGetAPI = (params: RequestDevicesGet) => {
  const { status, exclusive_start_device_id } = params;
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `/api/devices`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    status,
    exclusive_start_device_id,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<DevicesWithPaging>(axios, path, query, form, method, {
    items: new Array(5).fill(null).map((_, i) => {
      const v = JSON.parse(JSON.stringify(sample_device_1));
      v.device_name = `device_${i + 1}`;
      v.device_id = ('00000000' + String(i)).slice(-8);
      v.status = i % 3 === 0 ? 'DELETED' : 'ACTIVE';
      v.deleted_at = v.status === 'DELETED' ? '2020-01-01T00:00:00' : null;
      return v;
    }),
    has_next: false,
  });
};

/*** [GET] /api/devices/{id} ***/

export const devicesIdGetAPI = (id: string) => {
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `/api/devices/${id}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // }

  // 送信
  return sendAxios<Device>(axios, path, query, form, method, sample_device_1);
};

/*** [PUT] /api/devices/{id} ***/

export interface RequestDevicesIdPut extends CommonAPIRequestType {
  device_id: string;
  device_name?: string;
  graph_stream_id?: string;
  stream_device_graph_id?: string;
}

export const devicesIdPutAPI = (params: RequestDevicesIdPut) => {
  const { device_id, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/devices/${device_id}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'device_id' || isCommonAPIRequestType(key)) {
      continue;
    }
    form.append(key, value);
  }

  // 送信
  return sendAxios<Device>(axios, path, query, form, method, sample_device_1);
};

/*** [GET] /api/devices/{id}/now ***/

export const devicesIdNowGetAPI = (id: string) => {
  // クライアントを定義
  const axios = getClient({ responseType: 'arraybuffer' });

  // パス・メソッドを定義
  const path = `/api/devices/${id}/now`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<ArrayBuffer>(axios, path, query, form, method, new ArrayBuffer(6));
};

/*** [POST] /api/devices/{id}/settings ***/

export interface RequestDevicesIdSettingsPost extends CommonAPIRequestType {
  device_id: string;
  soracom_model_id: string;
  soracom_parameter: SoracomParameter;
}

export const devicesIdSettingsPostAPI = (params: RequestDevicesIdSettingsPost) => {
  const { device_id, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/devices/${device_id}/settings`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    let value_ = value;
    if (key === 'device_id' || isCommonAPIRequestType(key)) {
      continue;
    }
    if (typeof value === 'object') {
      value_ = JSON.stringify(value);
    }
    form.append(key, value_);
  }

  // 送信
  return sendAxios<Device>(axios, path, query, form, method, sample_device_1);
};

/*** [POST] /api/devices/{device_id}/processes ***/

export interface RequestDevicesIdProcessesPost extends CommonAPIRequestType {
  device_id: string;
  process_name: string;
  process_id: string;
  input_stream_ids: string[][];
  app_parameter?: { [key: string]: string };
  heartbeat?: { [key: string]: string };
  is_oneshot?: 'True';
  input_stream_data_number?: string | number;
  delay_seconds?: number;
  target_term_from?: number;
  target_term_to?: number;
}

export const devicesIdProcessesPostAPI = (params: RequestDevicesIdProcessesPost) => {
  const { device_id, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/devices/${device_id}/processes`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  form.append('process_name', params.process_name);
  form.append('process_id', params.process_id);
  for (let i = 0; i < params.input_stream_ids.length; i++) {
    for (let j = 0; j < params.input_stream_ids[i].length; j++) {
      form.append(`input_stream_id[${i}:${j}]`, params.input_stream_ids[i][j]);
    }
  }
  if (params.app_parameter) {
    for (const [key, value] of Object.entries(params.app_parameter)) {
      form.append('app_parameter.' + key, value);
    }
  }
  if (params.heartbeat) {
    for (const [key, value] of Object.entries(params.heartbeat)) {
      form.append('heartbeat.' + key, value);
    }
  }
  if (params.is_oneshot) {
    form.append('is_oneshot', params.is_oneshot);
  }
  if (params.input_stream_data_number) {
    form.append('input_stream_data_number', params.input_stream_data_number + '');
  }
  if (params.delay_seconds !== undefined) {
    form.append('delay_seconds', params.delay_seconds + '');
  }
  if (params.target_term_from !== undefined) {
    form.append('target_term_from', params.target_term_from + '');
  }
  if (params.target_term_to !== undefined) {
    form.append('target_term_to', params.target_term_to + '');
  }

  // 送信
  return sendAxios<Device>(axios, path, query, form, method, sample_device_1);
};

/*** [POST] /api/devices/{device_id}/linkchannels ***/

export interface RequestDevicesIdLinkChannelsPost extends CommonAPIRequestType {
  device_id: string;
}

export const devicesIdLinkChannelsPostAPI = (params: RequestDevicesIdLinkChannelsPost) => {
  const { device_id, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/devices/${device_id}/linkchannels`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<Device>(axios, path, query, form, method, sample_device_1);
};

/*** [POST] /api/devices/{id}/settings ***/
// soracomパラメーター反映API

export interface RequestSoracomParameterReflectionPost extends CommonAPIRequestType {
  device_id: string;
  app_parameter: Record<string, string | number | number[][][] | Record<string, string>>;
}

export const soracomParameterReflectionPostAPI = (params: RequestSoracomParameterReflectionPost) => {
  const { device_id, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/devices/${device_id}/inferreddatas`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    let value_ = value;
    if (key === 'device_id' || isCommonAPIRequestType(key)) {
      continue;
    }
    if (typeof value === 'object') {
      value_ = JSON.stringify(value);
    }
    form.append(key, value_);
  }

  // 送信
  return sendAxios<Device>(axios, path, query, form, method, sample_device_1);
};

/*** [GET] /api/streams/{stream_id}/data/{stream_data_number}/processinfo ***/

export interface RequestDeviceIdProcessinfoGet {
  device_id: string;
}

export const deviceIdProcessinfoGetAPI = (params: RequestDeviceIdProcessinfoGet) => {
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `/api/devices/${params.device_id}/processinfo`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<ProcessinfoPaging>(axios, path, query, form, method, {
    has_next: false,
    items: [sample_processinfo1, sample_processinfo2],
  });
};
