import { memo, useEffect, useState } from 'react';
import { EditModalButton } from './EditModel';
import { ICONFIG, IMJPEG_SERVER, IOD_MATCHER, ISCENARIO } from 'user/components/Config';
import {
  EdgeDevice,
  edgeDevicesKeyConfigGetAPI,
  edgeDevicesKeyConfigPutAPI,
  edgeDevicesKeyPutAPI,
  edgeDevicesKeyThumbnailGetAPI,
} from 'user/api/edgeDevices';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';

type Params = {
  edge_device: EdgeDevice;
};

const AiCamCard = memo(function AiCamCard({ edge_device }: Params) {
  const [config, setConfig] = useState<ICONFIG>({ SCENARIOS: [] });
  const [image, setImage] = useState<string | undefined>('');

  const onChange = (i: number, c?: ISCENARIO, od?: IOD_MATCHER, MJPEG_SERVER?: IMJPEG_SERVER, state?: boolean) => {
    if (i !== null && c && od && MJPEG_SERVER && state) {
      const cams = [...config.SCENARIOS];
      while (cams.length <= i) {
        cams.push({});
      }
      cams[i] = c;
      if (state) {
        save({
          ...config,
          SCENARIOS: cams,
          OD_MATCHER: od,
          MJPEG_SERVER: MJPEG_SERVER,
          CONFIG_CHANGE_TIME: Math.round(Date.now() / 1000),
        });
      } else {
        save({ ...config, SCENARIOS: cams, OD_MATCHER: od, MJPEG_SERVER: MJPEG_SERVER });
      }
    }
  };

  const save = async (config: ICONFIG) => {
    const res = await edgeDevicesKeyConfigPutAPI({
      edge_device_key: edge_device.edge_device_key,
      config: config,
      disabled_load: false,
    });
    if (res.status === 200) {
      const res_put = await edgeDevicesKeyPutAPI({
        edge_device_key: edge_device.edge_device_key,
        immediate_config_download_acquisition: 'True',
        disabled_load: false,
      });
      if (res_put.status == 200) {
        const res_config = await edgeDevicesKeyConfigGetAPI({
          edge_device_key: edge_device.edge_device_key,
          disabled_load: false,
        });
        const data = new TextDecoder().decode(res_config.data);
        const config = JSON.parse(data) as ICONFIG;
        setConfig(config);
        AlertDialog.show('エッジデバイスの設定が更新されました。');
      }
    } else {
      AlertDialog.show('エッジデバイスの設定更新に失敗しました。');
    }
  };

  // -- onload function --
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res_config = await edgeDevicesKeyConfigGetAPI({ edge_device_key: edge_device.edge_device_key });
        const data = new TextDecoder().decode(res_config.data);
        const config = JSON.parse(data) as ICONFIG;
        setConfig(config);
        const res_img = await edgeDevicesKeyThumbnailGetAPI({ edge_device_key: edge_device.edge_device_key });
        if (res_img.status === 200) {
          const blob = new Blob([res_img.data], { type: 'image/png' });
          const url = window.URL || window.webkitURL;
          await setImage(url.createObjectURL(blob));
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [edge_device.edge_device_key]);

  return (
    <>
      {config.SCENARIOS && config.SCENARIOS.length > 0 && image != null ? (
        <EditModalButton
          _id={0}
          value={config.SCENARIOS[0]}
          OD_MATCHER={config.OD_MATCHER || {}}
          MJPEG_SERVER={config.MJPEG_SERVER || {}}
          image_url={image}
          onClose={(scenario?: ISCENARIO, od?: IOD_MATCHER, MJPEG_SERVER?: IMJPEG_SERVER, state?: boolean) =>
            onChange(0, scenario, od, MJPEG_SERVER, state)
          }
        />
      ) : (
        <Spinner />
      )}
    </>
  );
});

// -- styled components --

// -- finally export part --
export default AiCamCard;
