// -- basic library --
import React, { useCallback, useEffect, useState } from 'react';

// -- redux library --

// -- http connection library --

// -- external components --
import { Spinner } from '@blueprintjs/core';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import { Content, Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';

// -- external functions --
import styles from 'shared/styles/styles';
import { Dashboard } from 'user/api/dashboards';
import { Role, rolesIdGetAPI } from 'user/api/roles';
import { Stream } from 'user/api/streams';
import PfDialog from 'shared/components/atoms/PfDialog';
import { keyBy } from 'shared/utils/converter';

// 指定された複数のdashboard_idより複数のDashboardを作る
const toSpecifiedDashboards = (dashboard_ids: string[], all_dashboards: Dashboard[]) => {
  const dashboards_record = keyBy(all_dashboards, (d) => d.dashboard_id);
  const specified_dashboards: Dashboard[] = [];
  dashboard_ids.forEach((did) => {
    if (did in dashboards_record) {
      specified_dashboards.push(dashboards_record[did]);
    }
  });
  return specified_dashboards;
};
// 指定された複数のstream_idより複数のStreamを作る
const toSpecifiedStreams = (stream_ids: string[], all_streams: Stream[]) => {
  const streams_record = keyBy(all_streams, (s) => s.stream_id);
  const specified_streams: Stream[] = [];
  stream_ids.forEach((sid) => {
    if (sid in streams_record) {
      specified_streams.push(streams_record[sid]);
    }
  });
  return specified_streams;
};

// -- types --
type Params = {
  role_id: string;
  handleDialogCloseClick: (canceled?: boolean) => void;
  handleEditDialogOpenClick: (role_id: string) => void;
  streams?: Stream[];
  dashboards?: Dashboard[];
  is_open: boolean;
};

// -- main component --

const DetailRoles: React.FC<Params> = ({
  role_id,
  streams,
  dashboards,
  is_open,
  handleDialogCloseClick,
  handleEditDialogOpenClick,
}) => {
  // -- local states --;
  const [role, setRole] = useState<Role>();

  const loadInitialData = useCallback(async () => {
    const res = await rolesIdGetAPI({ role_id });
    if (res.status === 200) {
      setRole(res.data);
    }
  }, [role_id]);

  // -- onload function --
  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  // -- render part --
  return (
    <PfDialog isOpen={is_open} onClose={() => handleDialogCloseClick(true)} title='ロール詳細'>
      <Content>
        {role && dashboards !== undefined && streams !== undefined ? (
          <>
            <InputComponent text='ロールID'>
              <div>{role.role_id}</div>
            </InputComponent>
            <InputComponent text='ロール名'>
              <div>{role.role_name}</div>
            </InputComponent>
            <InputComponent text='ダッシュボード'>
              {toSpecifiedDashboards(role.dashboard_ids, dashboards).map((dashboard, i) => {
                return <div key={i}>{dashboard.dashboard_name}</div>;
              })}
            </InputComponent>
            <InputComponent text='データ'>
              {toSpecifiedStreams(role.stream_ids, streams).map((stream, i) => {
                return <div key={i}>{stream.stream_name}</div>;
              })}
            </InputComponent>
            <Footer>
              <RoundedButton
                onClick={() => handleDialogCloseClick(true)}
                style={{ marginRight: styles.interval_narrow_margin }}
                text='キャンセル'
                is_white
              />
              <RoundedButton onClick={() => handleEditDialogOpenClick(role_id)} text='編集' />
            </Footer>
          </>
        ) : (
          <Spinner />
        )}
      </Content>
    </PfDialog>
  );
};

// -- styled components --

// -- finally export part --

export default DetailRoles;
