import React from 'react';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/types/Node';
import { Line as LineRefType } from 'konva/types/shapes/Line';
import { Rect as RectRefType } from 'konva/types/shapes/Rect';
import { Text as TextRefType } from 'konva/types/shapes/Text';
import {
  Image as KonvaImage,
  Layer as KonvaLayer,
  Line,
  Rect,
  Stage as KonvaStage,
  Text,
  Transformer,
} from 'react-konva';
import ObjectPropertyDialog from '../ObjectPropertyDialog';
import ObjectMenu from './ObjectMenu';
import PaneBox from './components/PaneBox';
import { Layer, LayerObject } from './types';

interface CanvasPaneProps extends React.HTMLAttributes<HTMLDivElement> {
  streamId?: string;
  streamDataNumber?: number;
  layers: Layer[];
  onImageSizeChange: (width: number, height: number) => void;
  onLayersChange: (layers: Layer[]) => void;
}
interface CanvasPaneState {
  stageWidth: number;
  stageHeight: number;
  stageScale: number;
  image?: Konva.Image;
  selectedObject?: LayerObject;
}
/** ビデオエディタのキャンバス部分です。 */
export default class CanvasPane extends React.PureComponent<CanvasPaneProps, CanvasPaneState> {
  container: HTMLDivElement | null | undefined;
  rand = new Date().getTime();
  constructor(props: CanvasPaneProps) {
    super(props);
    this.state = {
      stageWidth: 100,
      stageHeight: 100,
      stageScale: 1.0,
    };
  }
  componentDidMount() {
    this.checkSize();
    this.loadImage();
    // here we should add listener for "container" resize
    // take a look here https://developers.google.com/web/updates/2016/10/resizeobserver
    // for simplicity I will just listen window resize
    window.addEventListener('resize', this.checkSize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.checkSize);
  }
  private checkSize = () => {
    if (this.container) {
      const width = this.container.offsetWidth;
      const height = this.container.offsetHeight;
      this.setState({
        stageWidth: width,
        stageHeight: height,
        stageScale: this.calcScale(this.state.image, width, height),
      });
    }
  };
  private loadImage = () => {
    const url = this.props.streamDataNumber
      ? `/api/streams/${this.props.streamId}/data/${this.props.streamDataNumber}/thumbnail?stream_type=original&.rand=${this.rand}`
      : `/api/streams/${this.props.streamId}/thumbnail?.rand=${this.rand}`;
    Konva.Image.fromURL(url, (img: Konva.Image) => {
      this.setState({ image: img, stageScale: this.calcScale(img, this.state.stageWidth, this.state.stageHeight) });
      this.props.onImageSizeChange(img.width(), img.height());
    });
  };
  private calcScale = (img: Konva.Image | undefined, stageWidth: number, stageHeight: number) => {
    if (img === undefined) {
      return 1.0;
    } else {
      const scaleWidth = stageWidth / img.width();
      const scaleHeight = stageHeight / img.height();
      return Math.min(scaleWidth, scaleHeight, 1.0);
    }
  };
  render() {
    return (
      <PaneBox {...this.props}>
        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
          <div style={{ flexGrow: 1 }}>
            <div
              style={{ width: '100%', height: '100%' }}
              ref={(node) => {
                this.container = node;
              }}
            >
              <CanvasStage
                width={this.state.stageWidth}
                height={this.state.stageHeight}
                scale={this.state.stageScale}
                image={this.state.image}
                layers={this.props.layers}
                onLayersChange={this.props.onLayersChange}
              />
            </div>
          </div>
          <div style={{ color: '#c0c0c0' }}>
            scale: {this.state.stageScale} &nbsp; image: ({this.state.image?.width()} x {this.state.image?.height()})
            &nbsp; stage: ({this.state.stageWidth} x {this.state.stageHeight}) &nbsp;
          </div>
        </div>
      </PaneBox>
    );
  }
}

interface CanvasStageProps {
  width: number;
  height: number;
  scale: number;
  image?: Konva.Image;
  layers: Layer[];
  onLayersChange: (layers: Layer[]) => void;
}
interface CanvasStageState {
  selectedObject?: LayerObject;
  objectMenuProps?: { layer: Layer; layerObject: LayerObject; style: { top: number; left: number } };
  objectPropertyDialogProps?: { layer: Layer; layerObject: LayerObject };
}
/** キャンバスに描画される KonvaStage をコントロールします。 */
class CanvasStage extends React.PureComponent<CanvasStageProps, CanvasStageState> {
  private imageRef = React.createRef<Konva.Image>();
  constructor(props: CanvasStageProps) {
    super(props);
    this.state = {};
  }
  private handleMoved = (layer: Layer, obj: LayerObject, x: number, y: number, width: number, height: number) => {
    const imageWidth = this.props.image?.width() || 1;
    const imageHeight = this.props.image?.height() || 1;
    const newObject = {
      ...obj,
      bounds: {
        x1: x / imageWidth,
        y1: y / imageHeight,
        x2: (x + width) / imageWidth,
        y2: (y + height) / imageHeight,
      },
    };
    for (let i = 0; i < layer.objects.length; i++) {
      if (layer.objects[i] === obj) {
        layer.objects[i] = newObject;
      }
    }
    this.props.onLayersChange([...this.props.layers]);
  };
  private handleCheckDeselect = (evt: KonvaEventObject<MouseEvent>) => {
    if (evt.target === evt.target.getStage() || evt.target === this.imageRef.current) {
      this.setState({ selectedObject: undefined });
    }
    this.setState({ objectMenuProps: undefined });
  };
  private handleSelect = (e: KonvaEventObject<MouseEvent>, layer: Layer, obj: LayerObject) => {
    if (e.evt.button === 0) {
      this.setState({ selectedObject: obj, objectMenuProps: undefined });
    } else if (e.evt.button === 2) {
      this.setState({
        objectMenuProps: {
          layer: layer,
          layerObject: obj,
          style: {
            top: e.evt.y + 4,
            left: e.evt.x + 4,
          },
        },
      });
    }
  };
  private handleItemDeleteClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    layer: Layer,
    layerObject: LayerObject,
  ) => {
    const i = layer.objects.indexOf(layerObject);
    if (i !== -1) {
      layer.objects.splice(i, 1);
    }
    this.props.onLayersChange([...this.props.layers]);
    this.setState({ objectMenuProps: undefined });
  };
  private handleLayerObjectPropertyOpen = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    layer: Layer,
    layerObject: LayerObject,
  ) => {
    this.setState({
      objectMenuProps: undefined,
      objectPropertyDialogProps: { layer: layer, layerObject: layerObject },
    });
  };
  private handleLayerObjectPropertyCancelClose = () => {
    this.setState({ objectPropertyDialogProps: undefined });
  };
  private handleLayerObjectPropertyOkClose = () => {
    this.setState({ objectPropertyDialogProps: undefined });
    this.props.onLayersChange([...this.props.layers]);
  };
  render() {
    const imageWidth = this.props.image?.width() || 1;
    const imageHeight = this.props.image?.height() || 1;
    return (
      <>
        <KonvaStage
          width={this.props.width}
          height={this.props.height}
          scale={{ x: this.props.scale, y: this.props.scale }}
          onMouseDown={this.handleCheckDeselect}
          onContextMenu={(e) => e.evt.preventDefault()}
        >
          {/* 最下段にビデオ映像を描画 */}
          <KonvaLayer>
            {this.props.image && <KonvaImage ref={this.imageRef} image={this.props.image.image()} />}
          </KonvaLayer>
          {this.props.layers.map((layer, i) => {
            return (
              <KonvaLayer key={i}>
                {layer.objects.map((obj, j) => {
                  return (
                    <KonvaObject
                      key={j}
                      obj={obj}
                      imageWidth={imageWidth}
                      imageHeight={imageHeight}
                      isSelected={obj === this.state.selectedObject}
                      onSelect={(e) => this.handleSelect(e, layer, obj)}
                      onMoved={(x: number, y: number, width: number, height: number) =>
                        this.handleMoved(layer, obj, x, y, width, height)
                      }
                    />
                  );
                })}
              </KonvaLayer>
            );
          })}
        </KonvaStage>
        {this.state.objectMenuProps !== undefined && (
          <ObjectMenu
            {...this.state.objectMenuProps}
            style={{ position: 'absolute', ...this.state.objectMenuProps.style }}
            onLayerProperty={(e, layer, layerObject) => this.handleLayerObjectPropertyOpen(e, layer, layerObject)}
            onLayerDelete={(e, layer, layerObject) => this.handleItemDeleteClick(e, layer, layerObject)}
          />
        )}
        {this.state.objectPropertyDialogProps !== undefined && (
          <ObjectPropertyDialog
            {...this.state.objectPropertyDialogProps}
            onCancelClose={this.handleLayerObjectPropertyCancelClose}
            onOkClose={this.handleLayerObjectPropertyOkClose}
          />
        )}
      </>
    );
  }
}

const KonvaObject = (props: {
  obj: LayerObject;
  isSelected: boolean;
  imageWidth: number;
  imageHeight: number;
  onSelect: (e: KonvaEventObject<MouseEvent>) => void;
  onMoved: (x: number, y: number, width: number, height: number) => void;
}) => {
  const konvaRef: React.LegacyRef<RectRefType | TextRefType | LineRefType> = React.useRef(null);

  const trRef = React.useRef<Konva.Transformer>(null);
  React.useEffect(() => {
    if (props.isSelected) {
      // we need to attach transformer manually
      if (trRef.current && konvaRef.current) {
        trRef.current.nodes([konvaRef.current]);
        trRef.current.getLayer()?.batchDraw();
      }
    }
  }, [props.isSelected]);
  const handleDragEnd = (e: KonvaEventObject<DragEvent>) => {
    props.onMoved(e.target.x(), e.target.y(), e.target.width(), e.target.height());
  };
  const handleTransformEnd = (evt: Konva.KonvaEventObject<Event>) => {
    // transformer is changing scale of the node
    // and NOT its width or height
    // but in the store we have only width and height
    // to match the data better we will reset scale on transform end
    const node = evt.target;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    // we will reset it back
    node.scaleX(1);
    node.scaleY(1);
    props.onMoved(
      node.x(),
      node.y(),
      // set minimal value
      Math.max(5, node.width() * scaleX),
      Math.max(node.height() * scaleY),
    );
  };
  const baseProps: Konva.ShapeConfig = {
    draggable: true,
    x: props.obj.bounds.x1 * props.imageWidth,
    y: props.obj.bounds.y1 * props.imageHeight,
    onDragMove: () => {},
    onDragEnd: handleDragEnd,
    onClick: props.onSelect,
    onTransformEnd: handleTransformEnd,
  };
  const width = (props.obj.bounds.x2 - props.obj.bounds.x1) * props.imageWidth;
  const height = (props.obj.bounds.y2 - props.obj.bounds.y1) * props.imageHeight;
  let elm = undefined;
  if (props.obj.type === 'RECT') {
    elm = (
      <Rect
        {...baseProps}
        ref={konvaRef as React.LegacyRef<RectRefType>}
        width={width}
        height={height}
        fill={props.obj.fill_color || '#ffffff'}
        opacity={props.obj.opacity}
      />
    );
  } else if (props.obj.type === 'TEXT') {
    elm = (
      <Text
        {...baseProps}
        ref={konvaRef as React.LegacyRef<TextRefType>}
        width={width}
        height={height}
        text={props.obj.text || ''}
        fontSize={props.obj.font_size || 10}
        fontFamily='Noto Sans JP'
        fill={props.obj.fill_color || '#ffffff'}
        opacity={props.obj.opacity}
      />
    );
  } else if (props.obj.type === 'LINE') {
    elm = (
      <Line
        {...baseProps}
        ref={konvaRef as React.LegacyRef<LineRefType>}
        points={[0, 0, width, height]}
        stroke='black'
      />
    );
  } else {
    elm = <Rect />;
  }
  return (
    <>
      {elm}
      {props.isSelected && <Transformer rotateEnabled={false} ref={trRef} />}
    </>
  );
};
