import RoundedCard from 'shared/components/atoms/RoundedCard';
import styled from 'styled-components';

/**
 * ウィジェットなどを表示する際のRoundedCard
 * **/
const RoundedWidgetCard = styled(RoundedCard)<{
  large?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-conten: center;
  width: ${(p) => (p.large ? '800px' : '420px')};
  height: 430px;
  // max-height: ${(p) => (p.large ? '800px' : '430px')};
  padding: 10px;
  margin: 5px;
`;

export default RoundedWidgetCard;
