import React from 'react';
import Konva from 'konva';
import { Group as GroupRefType } from 'konva/types/Group';
import { KonvaEventObject } from 'konva/types/Node';
import { ArrowConfig } from 'konva/types/shapes/Arrow';
import { CircleConfig } from 'konva/types/shapes/Circle';
import { Arrow, Circle, Group, Label, Text, Tag } from 'react-konva';
import { colors } from 'shared/styles/colors';
import { Point } from 'shared/models';
import { is2DLen2Array, isSpecifiedArrLength } from 'shared/utils/is';
import { getVerticalCoordinate, multiplyPoint } from 'shared/utils/else/crosslineFunctions';
import { MODE, CROSSLINE_POSITION } from './index';

/*** 直線とそれに付随するKonva Object***/
interface Params {
  index: number;
  crossline: number[][];
  selected?: boolean;
  width: number;
  height: number;
  onClick: (e: KonvaEventObject<MouseEvent>) => void;
  tool: MODE;
  setCrosslineWhereIndex: (p: { crossline: number[][]; index: number }) => void;
  setResizePosition: (resize_position: CROSSLINE_POSITION) => void;
}

export const KonvaObject: React.FC<Params> = (params) => {
  // dragを開始した時に、開始位置を格納しておく。(これで移動差分を取得し、座標を差分だけ移動させる)
  const [drag_start, setDragStart] = React.useState<Point | undefined>(undefined);
  // konva objectがdragできるかどうか。先端の丸を動かしている時はdragできない
  const [draggable, setDraggable] = React.useState<boolean>(true);
  const shapeRef: React.LegacyRef<GroupRefType> = React.useRef(null);

  // ドラッグ(平行移動)を開始する時の関数
  const onDragStart = (e: KonvaEventObject<DragEvent>, invalid_object?: boolean) => {
    e.evt.stopPropagation();
    if (params.tool !== 'hand') return;
    // 仮にinvalid_objectがtrueならば、ドラッグを開始させない
    if (invalid_object) {
      setDragStart(undefined);
      return;
    }
    setDragStart({
      x: e.target.x(),
      y: e.target.y(),
    });
  };
  // ドラッグ(平行移動)を終了する時の関数
  const onDragEnd = (e: KonvaEventObject<DragEvent>, index: number) => {
    e.evt.stopPropagation();
    if (params.tool !== 'hand') return;
    if (!drag_start) return;
    let new_selected_line = [...params.crossline];
    if (!is2DLen2Array(new_selected_line)) return;
    // 移動開始位置
    const sx = drag_start?.x || 0;
    const sy = drag_start?.y || 0;
    // 移動した分の座標
    const dx = (e.target.x() - sx) / params.width;
    const dy = (e.target.y() - sy) / params.height;
    new_selected_line = [
      [params.crossline[0][0] + dx, params.crossline[0][1] + dy],
      [params.crossline[1][0] + dx, params.crossline[1][1] + dy],
    ];
    params.setCrosslineWhereIndex({
      crossline: new_selected_line,
      index: index,
    });
    setDragStart(undefined);
  };

  // マウスが、直線上に侵入した時の関数
  const onMouseEnterLine = (evt: KonvaEventObject<DragEvent>) => {
    if (params.tool !== 'hand') return;
    const stage = evt.target.getStage();
    if (!stage) return;
    const content = stage.getContent();
    // 先端の丸にポインターが入っている時はスキップ
    if (content.style.cursor === 'nesw-resize') return;
    // オブジェクトにホバーするとcursorが変わる
    content.style.cursor = 'move';
  };
  // マウスが、直線上から離れた時の関数
  const onMouseOutLine = (evt: KonvaEventObject<DragEvent>) => {
    if (params.tool !== 'hand') return;
    const stage = evt.target.getStage();
    if (!stage) return;
    const content = stage.getContent();
    // オブジェクトのホバーが外れるとcursorが変わる
    content.style.cursor = 'default';
  };

  // 本オブジェクトの中心点を取得する関数(線の開始位置と終了位置の中心とする)
  // 割合で返す
  const getCenterXY = (allow_undef?: boolean) => {
    let x = allow_undef ? undefined : 0;
    let y = allow_undef ? undefined : 0;
    if (is2DLen2Array(params.crossline)) {
      x = (params.crossline[0][0] + params.crossline[1][0]) / 2;
      y = (params.crossline[0][1] + params.crossline[1][1]) / 2;
    }
    return {
      x: x,
      y: y,
    };
  };

  // konva objectの各コンポーネントの共通情報
  const baseGroupConfig: Konva.ShapeConfig = {
    draggable: draggable,
    onMouseOut: onMouseOutLine,
    onMouseEnter: onMouseEnterLine,
    onClick: params.onClick,
    onDragStart: onDragStart,
    onDragEnd: (evt: KonvaEventObject<DragEvent>) => {
      onDragEnd(evt, params.index);
    },
  };

  // 本オブジェクトの中心点(割合座標)
  const center = getCenterXY(false) as Point;

  // toolがhandの時は、dragできるように。それ以外はdradをfalseに更新する
  React.useEffect(() => {
    setDraggable(params.tool === 'hand');
  }, [params.tool]);

  return (
    <Group
      {...baseGroupConfig}
      x={center.x ? center.x * params.width : center.x}
      y={center.y ? center.y * params.height : center.y}
      ref={shapeRef}
    >
      {/* 直線部分 */}
      <MainLine {...params} center={center} crossline={params.crossline} />
      {/* 直線に対する垂線 */}
      <VerticalLines {...params} center={center} crossline={params.crossline} />
      {/* LINEの番号テキスト */}
      <LineText {...params} center={center} crossline={params.crossline} index={params.index} />
      {/* リサイズができる先端の丸 */}
      <ResizePointCircles {...params} center={center} onDragStart={onDragStart} setDraggable={setDraggable} />
    </Group>
  );
};

/*** 直線のラベル名を表示するコンポーネント ***/
const LineText: React.FC<{
  crossline: number[][];
  center: Point;
  index: number;
  width: number;
  height: number;
}> = (params) => {
  // テキストの座標を取得する(絶対座標)
  const getTextXY = () => {
    let x = 0;
    let y = 0;
    if (!is2DLen2Array(params.crossline)) {
      return undefined;
    }
    // 矢印が右から左へと流れている時
    if (params.center.x > params.crossline[1][0]) {
      // 矢印が下から上へと流れている時
      if (params.center.y > params.crossline[1][1]) {
        // ↖︎の時は、テキストの座標を、矢印の先端の左上に配置
        x = (params.crossline[1][0] - params.center.x) * params.width - 30;
        y = (params.crossline[1][1] - params.center.y) * params.height - 30;
      } else {
        // 矢印が上から下へと流れている時
        // ↙︎の時は、テキストの座標を、矢印の先端の右下に配置
        x = (params.crossline[1][0] - params.center.x) * params.width + 5;
        y = (params.crossline[1][1] - params.center.y) * params.height + 15;
      }
    } else {
      // 矢印が左から右へと流れている時
      // 矢印が下から上へと流れている時
      if (params.center.y > params.crossline[1][1]) {
        // ↗︎の時は、テキストの座標を、矢印の先端の右下に配置
        x = (params.crossline[1][0] - params.center.x) * params.width + 15;
        y = (params.crossline[1][1] - params.center.y) * params.height + 15;
      } else {
        // ↘︎の時は、テキストの座標を、矢印の先端の右下に配置
        x = (params.crossline[1][0] - params.center.x) * params.width + 15;
        y = (params.crossline[1][1] - params.center.y) * params.height + 15;
      }
    }
    return {
      x: x,
      y: y,
    };
  };
  const textCoordinate = getTextXY();

  return (
    <>
      {textCoordinate && (
        <Label fill={colors.white} x={textCoordinate ? textCoordinate.x : 0} y={textCoordinate ? textCoordinate.y : 0}>
          <Tag fill={colors.black} />
          <Text text={`LINE_${params.index}`} fill={colors.light_green} fontSize={16} padding={5} />
        </Label>
      )}
    </>
  );
};

/*** 直線に対する垂線を表示するコンポーネント(LR, RL) ***/
const VerticalLines: React.FC<{
  crossline: number[][];
  center: Point;
  width: number;
  height: number;
}> = (params) => {
  /***垂線のpointsを取得する関数
  vertical1 RL(青色)
  vertical2 LR(黄色)***/
  const getVerticalLinePoints = (center: Point, key: 'vertical1' | 'vertical2') => {
    let points: number[] = [];
    if (!is2DLen2Array(params.crossline)) return points;

    // 割合で、座標を計算すると狂う(∵回転を利用して垂線の座標を出力しているので)ので、絶対座標に戻して利用する
    const verticalCoordinate = getVerticalCoordinate({
      start: {
        x: params.crossline[0][0] * params.width,
        y: params.crossline[0][1] * params.height,
      },
      end: {
        x: params.crossline[1][0] * params.width,
        y: params.crossline[1][1] * params.height,
      },
    });
    if (key === 'vertical1') {
      points = [
        0,
        0,
        verticalCoordinate.x1 - center.x * params.width,
        verticalCoordinate.y1 - center.y * params.height,
      ];
    } else {
      points = [
        0,
        0,
        verticalCoordinate.x2 - center.x * params.width,
        verticalCoordinate.y2 - center.y * params.height,
      ];
    }
    return points;
  };

  const baseArrowConfig: ArrowConfig = {
    strokeWidth: 3,
    tension: 1,
    lineCap: 'round',
    points: [],
  };

  return (
    <>
      {/* RLの垂線 */}
      <Arrow
        {...baseArrowConfig}
        points={getVerticalLinePoints(params.center, 'vertical1')}
        stroke={colors.crosslineRLColor}
      />
      {/* LRの垂線 */}
      <Arrow
        {...baseArrowConfig}
        points={getVerticalLinePoints(params.center, 'vertical2')}
        stroke={colors.crosslineLRColor}
      />
    </>
  );
};

/*** メインの直線を表示するコンポーネント ***/
const MainLine: React.FC<{
  crossline: number[][];
  center: Point;
  width: number;
  height: number;
}> = (params) => {
  // 直線のpointsを取得する関数
  const getLinePoints = (center: Point) => {
    let points: number[] = [];
    // start と endのどちらも存在すれば4点描く
    if (is2DLen2Array(params.crossline)) {
      points = [
        params.crossline[0][0] - center.x,
        params.crossline[0][1] - center.y,
        params.crossline[1][0] - center.x,
        params.crossline[1][1] - center.y,
      ];
    } else if (params.crossline[0] && isSpecifiedArrLength(params.crossline[0], 2)) {
      // startしかない時は2点描く
      points = [params.crossline[0][0] - center.x, params.crossline[0][1] - center.y];
    }
    return points;
  };

  const baseArrowConfig: ArrowConfig = {
    strokeWidth: 3,
    tension: 1,
    lineCap: 'round',
    points: [],
    stroke: colors.red,
  };

  return (
    <>
      <Arrow
        {...baseArrowConfig}
        points={multiplyPoint({
          point: getLinePoints(params.center),
          width: params.width,
          height: params.height,
        })}
      />
    </>
  );
};

/*** メインの直線の先端の丸を表示するコンポーネント ***/
const ResizePointCircles: React.FC<{
  crossline: number[][];
  center: Point;
  tool: MODE;
  width: number;
  height: number;
  selected?: boolean;
  setResizePosition: (resize_position: 'start' | 'end' | '') => void;
  setDraggable: (bool: boolean) => void;
  onDragStart: (e: KonvaEventObject<DragEvent>, invalid_object?: boolean) => void;
}> = (params) => {
  // マウスが、円上かに侵入した時の関数
  const onMouseEnterCircle = (evt: KonvaEventObject<DragEvent>) => {
    evt.evt.stopPropagation();
    // 先端の丸にホバーした時はドラッグ禁止
    params.setDraggable(false);
    if (params.tool !== 'hand') return;
    const stage = evt.target.getStage();
    if (!stage) return;
    const content = stage.getContent();
    // オブジェクトにホバーするとcursorが変わる
    content.style.cursor = 'nesw-resize';
  };
  // マウスが、円上を離れた時の関数
  const onMouseOutCircle = (evt: KonvaEventObject<DragEvent>) => {
    evt.evt.stopPropagation();
    // 先端の丸のホバーが外れた時にはドラッグをできるように
    params.setDraggable(true);
    if (params.tool !== 'hand') return;
    const stage = evt.target.getStage();
    if (!stage) return;
    const content = stage.getContent();
    // オブジェクトのホバーが外れるとcursorが変わる
    content.style.cursor = 'default';
  };

  const baseCircleConfig: CircleConfig = {
    radius: 6,
    fill: colors.light_green,
    stroke: colors.black,
    strokeWidth: 2,
    onMouseEnter: onMouseEnterCircle,
    onMouseOut: onMouseOutCircle,
    onDragStart: (evt: KonvaEventObject<DragEvent>) => params.onDragStart(evt, true),
  };

  return (
    <>
      {/* 先端の丸 */}
      {params.tool === 'hand' && params.selected && (
        <Circle
          {...baseCircleConfig}
          x={
            params.crossline[0] && isSpecifiedArrLength(params.crossline[0], 2)
              ? (params.crossline[0][0] - params.center.x) * params.width
              : undefined
          }
          y={
            params.crossline[0] && isSpecifiedArrLength(params.crossline[0], 2)
              ? (params.crossline[0][1] - params.center.y) * params.height
              : undefined
          }
          onMouseDown={() => params.setResizePosition('start')}
        />
      )}
      {params.tool === 'hand' && params.selected && (
        <Circle
          {...baseCircleConfig}
          x={
            params.crossline[1] && isSpecifiedArrLength(params.crossline[1], 2)
              ? (params.crossline[1][0] - params.center.x) * params.width
              : undefined
          }
          y={
            params.crossline[1] && isSpecifiedArrLength(params.crossline[1], 2)
              ? (params.crossline[1][1] - params.center.y) * params.height
              : undefined
          }
          onMouseDown={() => params.setResizePosition('end')}
        />
      )}
    </>
  );
};

export default KonvaObject;
