// -- basic library --
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Content } from 'shared/components/molecules/ContentsArea';
import M3u8DateSelector from 'shared/components/molecules/M3u8DateSelector';
import M3u8Play from 'shared/components/molecules/M3u8Play';
import { colors } from 'shared/styles/colors';
import { toISO8601 } from 'shared/models/ISO8601';
import styled from 'styled-components';
import { Stream, streamsStreamIdVideoM3U8GetAPIUrl } from 'user/api/streams';
// -- types --
interface Params {
  stream: Stream;
  is_open_set_dialog: boolean;
}

// -- main component --
const StreamVideoPanel: React.FC<Params> = (params) => {
  // -- local states --
  const [is_loaded, setIsLoaded] = useState<boolean>(false);
  const [stream_url, setStreamUrl] = useState<string>('');
  const [selected_video_date, setSelectedVideoDate] = useState<string>('');

  const query = new URLSearchParams(useLocation().search);

  // -- handlers --

  const getStreamsStreamIdVideoM3U8 = async (
    stream_id: string,
    query: {
      start?: number;
      speed?: number;
    },
  ) => {
    const request_params = {
      stream_id: stream_id,
      ...query,
    };
    setStreamUrl(streamsStreamIdVideoM3U8GetAPIUrl(request_params));
  };

  const getM3u8 = async (inputs: { happened_at: string; speed?: number; stream_id?: string }) => {
    const speed = inputs.speed || 1;
    const stream_id = inputs.stream_id || params.stream.stream_id;
    const live_delay = params.stream.estimated_live_delay || 60; // デフォルト 60秒 の遅延
    const happened_day = new Date(inputs.happened_at);
    const now_day = new Date();
    let new_happened_day = new Date();
    if (now_day.getTime() - live_delay * 1000 <= happened_day.getTime()) {
      // In MilliSecs
      const start = Math.floor((now_day.getTime() - live_delay * 1000) / 1000);
      await getStreamsStreamIdVideoM3U8(stream_id, {
        start: start,
        speed: speed,
      });
      new_happened_day.setMinutes(new_happened_day.getMinutes() - live_delay / 60); // In Minites
    } else {
      new_happened_day = new Date(inputs.happened_at);
      const start = Math.floor(new_happened_day.getTime() / 1000);
      await getStreamsStreamIdVideoM3U8(stream_id, {
        start: start,
        speed: speed,
      });
    }
    setSelectedVideoDate(toISO8601(new_happened_day));
  };

  // -- onload function --
  useEffect(() => {
    // query string の tm にUNIX時刻を指定できる
    const tm = query.get('tm');
    const now = tm ? new Date(Number(tm) * 1000) : new Date();
    (async function () {
      // m3u8ファイルの取得
      getM3u8({ happened_at: toISO8601(now), stream_id: params.stream.stream_id });
    })();
  }, []); /* eslint-disable-line */
  // -- render part --

  return (
    <Content data-testid='EventsIdDetails' style={{ width: 750 }}>
      {params.is_open_set_dialog ? null : (
        <>
          <TopArea>
            <M3u8Play
              video_url={stream_url}
              selected_video_date={selected_video_date ? selected_video_date : toISO8601(new Date(), 'JST')}
              is_loaded={is_loaded}
              setIsLoaded={setIsLoaded}
              onTimeChange={async (time: string) =>
                await getM3u8({ happened_at: time, stream_id: params.stream.stream_id })
              }
              width={750}
              is_live={params.stream.data_number_type === 'TIMESTAMP'}
            />
          </TopArea>
          <FooterArea>
            <Border />
            <M3u8DateSelector
              selected_video_date={selected_video_date}
              is_loaded={is_loaded}
              getM3u8={getM3u8}
              display_button_dates_number={7}
            />
          </FooterArea>
        </>
      )}
    </Content>
  );
};

// -- styled components --

const TopArea = styled.div`
  width: 100%;
  height: calc(100% - 55px - 3px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FooterArea = styled.div`
  width: 100%;
  height: 55px;
`;

const Border = styled.div`
  width: 100%;
  height: auto;
  border-top: solid 2px ${colors.component_small_border_color};
`;

// -- finally export part --

export default StreamVideoPanel;
