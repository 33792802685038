import React from 'react';
import { colors } from 'shared/styles/colors';
import styled from 'styled-components';

interface CirclePanelParams {
  text: string;
  num: number;
  selected?: boolean;
  width: string;
  last?: boolean;
  first?: boolean;
}

class CirclePanel extends React.Component<CirclePanelParams> {
  render() {
    return (
      <WholeCirclePanelArea
        first={this.props.first}
        last={this.props.last}
        style={{
          width: this.props.width,
        }}
      >
        <CircleArea selected={this.props.selected}>{this.props.num}</CircleArea>
        <TextArea selected={this.props.selected}>{this.props.text}</TextArea>
      </WholeCirclePanelArea>
    );
  }
}

// 円の左側の線
const before_style = `
  content: "";
  width: calc(50% - 35px / 2);
  height: 7px;
  position: absolute;
  top: calc((35px - 7px) / 2);
  left: 0;
  background-color: ${colors.gray};
`;

// 円の右の線
const after_style = `
  content: "";
  width: calc(50% - 35px / 2);
  height: 7px;
  position: absolute;
  top: calc((35px - 7px) / 2);
  left: calc(50% + 35px / 2);
  background-color: ${colors.gray};
`;

const WholeCirclePanelArea = styled.div<{
  first?: boolean;
  last?: boolean;
}>`
  width: auto;
  height; 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  &::before {${(params) => (params.first ? '' : before_style)}}
  &::after {${(params) => (params.last ? '' : after_style)}}
`;

const CircleArea = styled.div<{
  selected?: boolean;
}>`
  height: 35px;
  width: 35px;
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
  background-color: ${(params) => (params.selected ? colors.component_main_color : colors.gray)};
  color: ${(params) => (params.selected ? colors.white : colors.main_font_color)};
`;

const TextArea = styled.div<{
  selected?: boolean;
}>`
  margin-top: 5px;
  font-size: 0.8em;
  color: ${(params) => (params.selected ? colors.component_main_color : colors.main_font_color)};
`;

interface StepPanelParams {
  texts: string[];
  selected_number: number;
}

/**
 * パネルが何ページ目にいるのかを知らせるコンポーネント(デバイス更新画面などで使用)
 */
export default class StepPanel extends React.Component<StepPanelParams & React.HTMLAttributes<HTMLDivElement>> {
  render() {
    const texts_len = this.props.texts.length;
    return (
      <WholeNumberSliderPanelArea {...this.props}>
        {this.props.texts.map((text, index) => (
          <CirclePanel
            text={text}
            selected={index + 1 === this.props.selected_number}
            num={index + 1}
            first={index === 0}
            last={index + 1 === texts_len}
            width={`calc(100% / ${texts_len})`}
            key={index}
          />
        ))}
      </WholeNumberSliderPanelArea>
    );
  }
}

// -- styled components --
const WholeNumberSliderPanelArea = styled.div`
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;
