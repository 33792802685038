// -- basic library --
import React, { useEffect, useState } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import history from 'shared/browserHistory';
import Spinner from 'shared/components/atoms/Spinner';
import { PageWholeArea, Title } from 'shared/components/molecules/ContentsArea';
import PageSwitchingTabs from 'shared/components/molecules/PageSwitchingTabs';
import styles from 'shared/styles/styles';
import { systemSelector } from 'user/redux/slices/systemSlice';
import MetricsCSV from './MetricsCSV';
import MetricsGraph from './MetricsGraph';

/**
 * 現在利用されていないページです
 */

const MetricsIdDetails: React.FC<
  RouteComponentProps<{
    metric_id: string;
    panel_id?: string;
  }>
> = (params) => {
  // -- get external datas --

  // -- redux preparations --
  const system_state = useSelector(systemSelector);

  // -- local states --
  const [metric, setMetric] = useState<
    | {
        metric_id: string;
        metric_name: string;
        channel_names: string[];
      }
    | undefined
  >(undefined);

  // -- handlers --

  const getMetric = (metric_id: string) => {
    setMetric({
      metric_id: metric_id,
      metric_name: 'メトリック１',
      channel_names: [],
    });
  };

  // -- functions --
  const handleTabsClick = (tab_id: string, metric_id: string) => {
    history.push(`/metrics/${metric_id}/${tab_id}`);
  };

  // -- onload function --
  useEffect(() => {
    getMetric(params.match.params.metric_id);
  }, []); /* eslint-disable-line */

  // -- render part --
  return (
    <PageWholeArea data-testid='MetricsIdDetail'>
      {system_state.loading.preloading || metric === undefined ? (
        <Spinner />
      ) : (
        <>
          <Title
            text={metric.metric_name}
            style={{
              marginTop: styles.interval_narrow_margin,
              marginBottom: styles.interval_margin,
            }}
          />

          <React.Fragment>
            <PageSwitchingTabs
              display_datas={[
                { id: 'graph', text: 'グラフ' },
                { id: 'CSV', text: 'CSV' },
              ]}
              handleClick={(id: string) => handleTabsClick(id, params.match.params.metric_id)}
              selected_id={params.match.params.panel_id}
              initial_selected_id='graph'
            />
            <Switch>
              <Route exact path='/metrics/:metric_id/graph' render={(props) => <MetricsGraph {...props} />} />
              <Route exact path='/metrics/:metric_id/CSV' render={() => <MetricsCSV />} />
            </Switch>
          </React.Fragment>
        </>
      )}
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default MetricsIdDetails;
