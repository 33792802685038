import { TableauAuthoringViz, TableauViz } from 'shared/components/atoms/TableauComponent';
import { PageWholeArea } from 'shared/components/molecules/ContentsArea';
import { Dashboard } from 'user/api/dashboards';
import DashboardTopAreaWrapper from '../components/DashboardTopAreaWrapper';
import { TableauRole } from 'shared/models/TableauRole';
import { useEffect, useState } from 'react';
import { tableauJwtGetAPI } from 'user/api/tableau';

interface TableauDashboardWidgetsProps {
  dashboard: Dashboard;
  is_admin: boolean;
  tableau_role: TableauRole;
}

export default function TableauDashboardWidgets({ dashboard, is_admin, tableau_role }: TableauDashboardWidgetsProps) {
  const valid = dashboard.dashboard_type === 'tableau' && dashboard.tableau_workbook_url;
  return (
    <PageWholeArea>
      <DashboardTopAreaWrapper dashboard={dashboard} is_admin={is_admin}></DashboardTopAreaWrapper>
      {valid && (
        <DynamicTableauViz tableau_role={tableau_role} content_url={dashboard.tableau_workbook_url as string} />
      )}
    </PageWholeArea>
  );
}

function DynamicTableauViz({ tableau_role, content_url }: { tableau_role: TableauRole; content_url: string }) {
  const [token, setToken] = useState<string | null>(null);
  const loadJwtToken = async () => {
    const res = await tableauJwtGetAPI();
    if (res.status === 200) {
      setToken(res.data.token);
    }
  };

  useEffect(() => {
    loadJwtToken();
  }, []);

  switch (tableau_role) {
    case 'Explorer':
      return <TableauAuthoringViz src={content_url} width='100%' token={token ?? undefined} />;
    case 'Viewer':
      return <TableauViz src={content_url} width='100%' token={token ?? undefined} />;
    default:
      return null;
  }
}
