// -- basic library --
import React from 'react';
import FileBox from 'shared/components/atoms/FileBox';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import DrawTransformedPolygonObject from 'shared/components/molecules/DrawTransformedPolygons';
import { StringifiedJSON } from 'shared/models';
import { isRangeLen2Array } from 'shared/utils/is';
import { checkPolygonXYWithinRange, checkPolygonIntersect } from 'shared/utils/else/polygonFunctions';
import { streamsIdDataGetAPI, streamsIdDataNumberThumbnailGetAPI } from 'user/api/streamsData';

interface TransformedAreaDialogProps {
  isOpen: boolean;
  title: string;
  value: string;
  streamId?: string;
  streamDataNumber?: number;
  onClose: (value?: string) => void;
}
interface TransformedAreaDoalogState {
  transformedarea: number[][][]; // エリア, 点, xy
  imgsrc: string;
  file?: string;
  width?: number;
  height?: number;
}
/** 射影変換エリア設定ダイアログ */
export default class TransformedAreaDialog extends React.PureComponent<
  TransformedAreaDialogProps,
  TransformedAreaDoalogState
> {
  constructor(props: TransformedAreaDialogProps) {
    super(props);
    this.state = {
      transformedarea: [],
      imgsrc: '',
    };
  }

  componentDidMount() {
    // パラメータにストリームデータ番号がある場合はそのサムネイルを取得する
    // 無い場合はストリームの最終のストリームデータを取得する（最新映像）
    if (this.props.streamId) {
      const streamId = this.props.streamId;
      if (this.props.streamDataNumber) {
        this.loadThumbnail(streamId, this.props.streamDataNumber);
      } else {
        streamsIdDataGetAPI({ stream_id: streamId, scan_index_forward: 'False' }).then((res) => {
          if (res.status === 200 && res.data.items.length !== 0) {
            this.loadThumbnail(streamId, res.data.items[0].stream_data_number);
          }
        });
      }
    }

    const app_parameter = parse(this.props.value);
    if (app_parameter.transformedArea) {
      this.setState({ transformedarea: app_parameter.transformedArea });
    }
    if (app_parameter.imgsrc) {
      this.setState({ imgsrc: app_parameter.imgsrc });
    }
    if (app_parameter.width) {
      this.setState({ width: app_parameter.width });
    }
    if (app_parameter.height) {
      this.setState({ height: app_parameter.height });
    }
    if (app_parameter.file) {
      const img = new Image();
      const file = app_parameter.file;
      const img_base64_content = file;
      img.src = 'data:image/png;base64,' + img_base64_content;
      this.setState({ imgsrc: img.src });
      this.setState({ file: file });
    }
  }

  // サムネイル画像の取得
  private loadThumbnail = (streamId: string, streamDataNumber: number) => {
    streamsIdDataNumberThumbnailGetAPI({
      stream_id: streamId,
      stream_data_number: String(streamDataNumber),
      stream_type: 'original',
    }).then((res) => {
      if (res.status === 200) {
        const blob = new Blob([res.data], { type: 'image/png' });
        const url = window.URL || window.webkitURL;
        this.setState({ imgsrc: url.createObjectURL(blob) });
      }
    });
  };

  // 画像アップロード
  private handleChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files !== null && e.currentTarget.files[0]) {
      const file = e.currentTarget.files[0];
      const img = new Image();
      // fileデータを読み込んでarraybufferに変換する処理
      const fileLoader = new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
          const result = reader.result;
          resolve(result);
        };
        try {
          reader.readAsBinaryString(file);
        } catch {
          console.log('fileの読み込みに失敗');
        }
      });
      const fileString = (await fileLoader) as string;
      const base64String = btoa(fileString);
      this.setState({ file: base64String });
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        this.setState({ width: width, height: height });
      };
      this.setState({ imgsrc: img.src });
    }
  };

  /**
   * 入力チェックを行い、エラーの場合はメッセージを含む例外をスローします。
   */
  private validate = () => {
    if (!this.state.transformedarea) {
      throw new Error(`射影変換エリアを描画してください`);
    }
    for (let i = 0; i < this.state.transformedarea.length; i++) {
      const transformedarea = this.state.transformedarea[i];
      if (!isRangeLen2Array(transformedarea, 4, 5)) {
        throw new Error(`$図形の形式が異なります。\n 図形の座標は、number[4 or 5][2]を満たす必要があります。`);
      }
      if (
        !checkPolygonXYWithinRange({
          polygon: transformedarea,
          size: {
            width: 1,
            height: 1,
          },
        })
      ) {
        throw new Error(`座標が画像サイズを超えています。`);
      }
      if (checkPolygonIntersect(transformedarea)) {
        throw new Error(`直線が交差している点があります。\n交わる点が存在してはいけません`);
      }
    }
  };
  // 射影変換検知の図形の設定
  private handleFinish = () => {
    // 入力チェック
    try {
      this.validate();
    } catch (e) {
      if (e instanceof Error) {
        AlertDialog.show(e.message);
      }
      return;
    }
    const joined_coordinates: {
      transformedArea: number[][][];
      // [TODO] ここでimgsrcがでかいとサイズ制限エラーになる
      imgsrc?: string;
      file?: string;
      width?: number;
      height?: number;
    } = {
      transformedArea: this.state.transformedarea,
    };
    if (!this.props.streamId) {
      joined_coordinates.imgsrc = this.state.imgsrc;
      joined_coordinates.file = this.state.file;
      joined_coordinates.width = this.state.width;
      joined_coordinates.height = this.state.height;
    }
    this.props.onClose(JSON.stringify(joined_coordinates));
  };
  render() {
    return (
      <PfDialog isOpen={this.props.isOpen} title={this.props.title} onClose={() => this.props.onClose()} large>
        {!this.props.streamId ? (
          <FileBox
            id='transformed-area-image-file'
            style={{ height: 55, marginBottom: 30 }}
            onChange={this.handleChangeFile}
            placeholder={"射影変換後のエリア画像を選択してください ('image/*')"}
            button_text='ファイルを選択'
            accept={'image/*'}
          />
        ) : (
          <></>
        )}
        {this.props.streamId || this.state.imgsrc ? (
          <DrawTransformedPolygonObject
            polygons={this.state.transformedarea}
            imgsrc={this.state.imgsrc}
            onChange={(polygons) => this.setState({ transformedarea: polygons })}
          />
        ) : (
          <></>
        )}
        <div style={{ textAlign: 'center' }}>
          <RoundedButton text='キャンセル' is_white={true} onClick={() => this.props.onClose()} is_margin_right />
          <RoundedButton text='OK' onClick={this.handleFinish} />
        </div>
      </PfDialog>
    );
  }
}
/** JSON文字列をパースします。異常値の場合は [] にします。 */
function parse(s: string | StringifiedJSON) {
  try {
    return JSON.parse(s);
  } catch (e) {
    return {};
  }
}

interface TransformedAreaButtonProps {
  value: string;
  title: string;
  streamId?: string;
  streamDataNumber?: number;
  onChange: (value: string) => void;
}
interface TransformedAreaButtonState {
  isOpen: boolean;
  text: string;
}
/** 射影変換エリア設定ダイアログを開くためのボタンです。 */
export class TransformedAreaButton extends React.PureComponent<TransformedAreaButtonProps, TransformedAreaButtonState> {
  constructor(props: TransformedAreaButtonProps) {
    super(props);
    if (this.props.title === 'TRANSFORMEDAREA1') {
      this.state = {
        isOpen: false,
        text: '射影変換対象エリア設定',
      };
    } else {
      this.state = {
        isOpen: false,
        text: '射影変換後エリア設定',
      };
    }
  }

  private handleOpen = () => {
    this.setState({ isOpen: true });
  };
  private handleClose = (value?: string) => {
    this.setState({ isOpen: false });
    if (value !== undefined) {
      this.props.onChange(value);
    }
  };
  render() {
    return (
      <>
        <RoundedButton text={this.state.text} onClick={this.handleOpen} />
        {this.state.isOpen && (
          <TransformedAreaDialog
            isOpen={true}
            title={this.state.text}
            value={this.props.value}
            streamId={this.props.streamId}
            streamDataNumber={this.props.streamDataNumber}
            onClose={this.handleClose}
          />
        )}
      </>
    );
  }
}
