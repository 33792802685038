import React from 'react';
import SelectBox from 'shared/components/atoms/SelectBox';
import { BottomArea, Title, TopArea, PageWholeArea } from 'shared/components/molecules/ContentsArea';
import TabButton from 'shared/components/molecules/TabButton';
import styles from 'shared/styles/styles';
import { CachedChannels, Channel } from 'user/api/channels';
import EventsIdDetailsDialog from '../DetailDialog';
import EventsPanel from './EventsPanel';
import EventsTable from './EventsTable';
import history from 'shared/browserHistory';
import { RouteComponentProps } from 'react-router-dom';

interface EventsProps
  extends RouteComponentProps<{
    channel_id: string;
  }> {}
interface EventsState {
  channels?: Channel[];
  channel_id: string;
  selected_tab_id: string;
  event_dialog_param?: { channel_id: string; channel_event_number: number };
}
/** イベント一覧ページ */
export default class Events extends React.PureComponent<EventsProps, EventsState> {
  private table_ref = React.createRef<EventsTable>();
  private historyUnlistenCallback?: { (): void; (): void };
  constructor(props: EventsProps) {
    super(props);
    // URLから初期パラメータを取得
    const query = new URLSearchParams(window.location.search);
    this.state = {
      channel_id: props.match.params.channel_id ?? 'all',
      selected_tab_id: query.get('tab_id') ?? 'grid',
      event_dialog_param: getEventDialogParam(query),
    };
  }
  componentDidMount() {
    // チャンネルリストの取得
    new CachedChannels({ channel_type: 'STANDARD', from_event_page: 'True' })
      .get()
      .then((res) => this.setState({ channels: res }));
    // アドレス欄のURLの書き換わりを監視するリスナーを登録
    this.historyUnlistenCallback = history.listen(this.onURLChanged);
  }
  componentWillUnmount() {
    if (this.historyUnlistenCallback) this.historyUnlistenCallback();
  }
  private onURLChanged = () => {
    const query = new URLSearchParams(window.location.search);
    const tab_id = query.get('tab_id');
    // tab_idが存在しない時は、初期状態に戻す
    if (tab_id === null) {
      this.setState({ channel_id: 'all', selected_tab_id: 'grid' });
    } else {
      this.setState({ selected_tab_id: tab_id });
    }
  };
  private handleChannelTabChange = (channel_id: string, tab_id: string) => {
    this.setState({ channel_id, selected_tab_id: tab_id });
    if (channel_id === 'all') {
      history.push(`/events?tab_id=${tab_id}`);
    } else {
      history.push(`/events/${channel_id}/?tab_id=${tab_id}`);
    }
  };
  private handleEventSelect = (channel_id: string, channel_event_number: number) => {
    this.setState({
      event_dialog_param: {
        channel_id,
        channel_event_number,
      },
    });
  };
  private handleDialogClose = (cancelled?: boolean) => {
    this.setState({ event_dialog_param: undefined });
    // キャンセルされた場合はリロードをしない
    if (cancelled) return;
    this.table_ref.current?.reload();
  };

  private handleTabClick = (tab_id: string) => {
    const { channel_id } = this.state;
    if (channel_id === 'all') {
      history.push(`/events?tab_id=${tab_id}`);
    } else {
      history.push(`/events/${channel_id}/?tab_id=${tab_id}`);
    }
  };

  render() {
    const { selected_tab_id } = this.state;
    return (
      <PageWholeArea data-testid='Events'>
        <TopArea>
          <Title text='アラート' />
          <SelectBox
            value={this.state.channel_id}
            default_text='null'
            datas={[
              { value: 'all', name: '全て' },
              ...(this.state.channels?.map((e) => {
                return {
                  value: e.channel_id,
                  name: e.channel_name,
                };
              }) || []),
            ]}
            onChange={(e) => this.handleChannelTabChange(e.currentTarget.value, selected_tab_id)}
            style={{ width: 300, marginRight: styles.interval_narrow_margin }}
          />
          <TabButton tabId={selected_tab_id} onChange={(tab_id: string) => this.handleTabClick(tab_id)} />
        </TopArea>
        <BottomArea>
          {selected_tab_id === 'table' ? (
            <EventsTable
              ref={this.table_ref}
              channels={this.state.channels}
              selected_channel_id={this.state.channel_id}
              onEventSelect={this.handleEventSelect}
            />
          ) : selected_tab_id === 'grid' ? (
            <EventsPanel onChannelTabChange={this.handleChannelTabChange} selected_data_value={this.state.channel_id} />
          ) : null}
        </BottomArea>
        {/* イベント詳細ダイアログ */}
        {this.state.event_dialog_param && (
          <EventsIdDetailsDialog
            onClose={this.handleDialogClose}
            is_open={true}
            channel_id={this.state.event_dialog_param.channel_id}
            channel_event_number={this.state.event_dialog_param.channel_event_number}
          />
        )}
      </PageWholeArea>
    );
  }
}

/** 可能ならURLからダイアログ用パラメータを生成します */
function getEventDialogParam(query: URLSearchParams) {
  const channel_id = query.get('channel_id');
  const channel_event_number = query.get('channel_event_number');
  try {
    if (channel_id && channel_event_number) {
      return { channel_id, channel_event_number: Number(channel_event_number) };
    }
  } catch (e) {
    console.log(e);
  }
  return undefined;
}
