import {
  DashboardBusOdMetric,
  dashboardsIdWidgetsNumMetrics2GetAPI,
  DashboardWidget,
} from 'user/api/dashboardsWidgets';
import { FixedDateScaleSettingDialogCloseProps } from 'user/dialogs/FixedDateScaleSettingDialog';
import { WidgetState } from 'user/pages/Dashboards/DetailPage/UsualDashboardWidgets/DashboardWidgetCard';
import { useCallback, useEffect, useMemo } from 'react';
import { dateTimeStringToFeatureDate } from 'shared/utils/converter';
import { DateString } from 'shared/models/DateString';
import { TimeString, toTimeString } from 'shared/models/TimeString';
import useBasicHooks from './useBasicHooks';
import { getDownloadUrlAsCsv } from 'shared/utils/get';
import { dateToMD } from 'shared/utils/converter/date';
import { WidgetBase2Type } from './DateRangeWidgetBase2';

export interface UseDateRangeWidgetBase2Params {
  widget: DashboardWidget;
  state: WidgetState;
  data?: DashboardBusOdMetric[];
  route_long_name?: string;
  widget_type: WidgetBase2Type;
  onInitMetrics(): void;
  onStateChanged(state: WidgetState): void;
  onMetricsChanged(metrics: DashboardBusOdMetric[]): void;
}
/**
 * DateRangeWidgetBase2で利用するhooks + 関数
 * - 未来のデータはプロットしないように制限しています。(see updateDataPart)
 */
export default function useDateRangeWidgetBase2({
  state,
  widget,
  data,
  route_long_name,
  widget_type,
  onMetricsChanged,
  onInitMetrics,
  onStateChanged,
}: UseDateRangeWidgetBase2Params) {
  const { date_dialog_open, onDateDialogOpen, onDateDialogClose } = useBasicHooks();
  // APIからデータをロードする
  const loadData = useCallback(
    async (params: { start: number; end: number }) => {
      /*
          表示期間に応じてグラフデータを取得します。
          時：分別で1時間
          日：分別で1日
          週：時別で7日
          月：日別で24ヶ月
      */
      const { start: initial_start, end } = params;
      let start = initial_start;
      // データ取得前にmetricsを初期化
      onInitMetrics();
      // データ取得ループ
      const data: DashboardBusOdMetric[] = [];
      for (;;) {
        const res = await dashboardsIdWidgetsNumMetrics2GetAPI({
          dashboard_id: widget.dashboard_id,
          dashboard_widget_number: String(widget.dashboard_widget_number),
          start: String(start),
          end: String(end),
        });
        if (res.status === 200) {
          for (const item of res.data.metrics) {
            data.push(item);
          }
          if (res.data.has_next) {
            start = res.data.last_data_number + 1;
          } else {
            break;
          }
        } else {
          break;
        }
      }
      onMetricsChanged(data);
    },
    [widget, onInitMetrics, onMetricsChanged],
  );
  const loadDataByDateTimeString = useCallback(
    async (params: {
      start_date: DateString | null;
      end_date: DateString | null;
      start_time: TimeString;
      end_time: TimeString;
    }) => {
      const { start_date, end_date, start_time, end_time } = params;
      const start = dateTimeStringToFeatureDate(start_date, start_time).getTime(false);
      const end = dateTimeStringToFeatureDate(end_date, end_time, true).getTime(true);
      await loadData({ start, end });
    },
    [loadData],
  );
  const handleDateUpdated = useCallback(
    async ({
      is_canceled,
      start_date,
      end_date,
      start_time = toTimeString('00:00'),
      end_time = toTimeString('23:59'),
    }: FixedDateScaleSettingDialogCloseProps) => {
      if (!is_canceled) {
        const newDefaults = { ...state };
        newDefaults.start_date = start_date;
        newDefaults.end_date = end_date;
        newDefaults.start_time = start_time;
        newDefaults.end_time = end_time;
        onStateChanged(newDefaults);
      }
      onDateDialogClose();
    },
    [onDateDialogClose, state, onStateChanged],
  );
  const convertDate = (unix: number) => {
    const dt = new Date(unix * 1000);
    return dateToMD(dt);
  };
  const csv_data_download_url = useMemo(() => {
    if (!data) return;
    // グラフの出力データを2次元配列に変換
    const csv_data = [];
    // 各データのバス停毎の人数カウント用
    const count = [];
    const all_label = [];
    // ヘッダー部分の格納
    const header = [''];
    let set_label: Set<string>;
    let set_stop_name: Set<string>;
    const label: string[] = [];
    const all_stop_name = [];
    if (widget_type === 'BUSOD1') {
      for (let i = 0; data.length > i; i++) {
        const count_data = {
          label: data[i].objects[0].od.state + '/' + data[i].objects[0].label,
          stop_name: data[i].gtfs?.stop_name as string,
          route_long_name: data[i].gtfs?.route_long_name,
        };
        count.push(count_data);
        all_label.push(count_data.label);
        // route_long_nameを選択した場合、route_long_nameで絞り込み
        if (route_long_name && count[i].route_long_name !== route_long_name) continue;
        all_stop_name.push(count_data.stop_name);
      }
      // stop_nameの重複削除し、headerに詰める
      set_stop_name = new Set(all_stop_name);
      for (const stop_name of set_stop_name) {
        header.push(stop_name);
      }
      // labelの重複削除
      set_label = new Set(all_label);
      for (const x of set_label) {
        label.push(x);
      }
      csv_data.push(header);
      // データ部分の格納
      // 行数分の繰り返し
      for (let j = 0; label.length > j; j++) {
        const res = [];
        // 列数分の繰り返し
        for (let k = 0; header.length > k; k++) {
          let count_res = 0;
          if (k === 0) {
            // 各行の先頭にはラベルを格納
            res.push(label[j]);
            continue;
          }
          for (let l = 0; count.length > l; l++) {
            if (count[l].label === label[j] && count[l].stop_name === header[k]) {
              count_res++;
            }
          }
          count_res === 0 ? res.push('') : res.push(count_res);
        }
        // 1行分のデータを格納したらpush
        csv_data.push(res);
      }
      // 2次元配列からダウンロード用URLを取得
      return getDownloadUrlAsCsv(csv_data);
    } else if (widget_type === 'BUSOD2') {
      const all_date = [];
      for (let i = 0; data.length > i; i++) {
        all_date.push(convertDate(data[i].t));
      }
      // dateの重複削除してheaderに詰める
      const set_date = new Set(all_date);
      for (const x of set_date) {
        header.push(x);
      }
      for (let j = 0; all_date.length > j; j++) {
        if (data[j].objects[0].od.state !== 'IN') continue;
        const count_data = {
          label: data[j].objects[0].label,
          date: convertDate(data[j].t),
          route_long_name: data[j].gtfs?.route_long_name,
        };
        // route_long_nameを選択した場合、route_long_nameで絞り込み
        if (route_long_name && count_data.route_long_name !== route_long_name) continue;
        all_label.push(count_data.label);
        count.push(count_data);
      }
      // labelの重複削除
      set_label = new Set(all_label);
      for (const x of set_label) {
        label.push(x);
      }
      csv_data.push(header);
      // データ部分の格納
      // 行数分の繰り返し
      for (let j = 0; label.length > j; j++) {
        const res = [];
        // 列数分の繰り返し
        for (let k = 0; header.length > k; k++) {
          let count_res = 0;
          if (k === 0) {
            // 各行の先頭にはラベルを格納
            res.push(label[j]);
            continue;
          }
          for (let l = 0; count.length > l; l++) {
            if (count[l].label === label[j] && count[l].date === header[k]) {
              count_res++;
            }
          }
          count_res === 0 ? res.push('') : res.push(count_res);
        }
        // 1行分のデータを格納したらpush
        csv_data.push(res);
      }
      // 2次元配列からダウンロード用URLを取得
      return getDownloadUrlAsCsv(csv_data);
    } else if (widget_type === 'BUSOD3') {
      const data_in = [];
      const data_out = [];
      for (let i = 0; data.length > i; i++) {
        const stop_name = data[i].gtfs?.stop_name;
        if (data[i].objects[0].od.state === 'IN') {
          const data_row = {
            od_id: data[i].objects[0].od.od_id,
            stop_name: data[i].gtfs?.stop_name,
            route_long_name: data[i].gtfs?.route_long_name,
          };
          data_in.push(data_row);
        }
        if (data[i].objects[0].od.state === 'OUT') {
          const data_row = {
            od_id: data[i].objects[0].od.od_id,
            stop_name: data[i].gtfs?.stop_name,
            route_long_name: data[i].gtfs?.route_long_name,
          };
          data_out.push(data_row);
        }
        // route_long_nameを選択した場合、route_long_nameで絞り込み
        if (route_long_name && data[i].gtfs?.route_long_name !== route_long_name) continue;
        all_stop_name.push(stop_name);
      }
      // stop_nameの重複削除し、headerとlabelに詰める
      set_stop_name = new Set(all_stop_name as string[]);
      for (const stop_name of set_stop_name) {
        header.push(stop_name);
        label.push(stop_name);
      }
      csv_data.push(header);
      // データ部分の格納
      // 行数分の繰り返し
      for (let j = 0; label.length > j; j++) {
        const res = [];
        // 列数分の繰り返し
        for (let k = 0; header.length > k; k++) {
          let count_res = 0;
          if (k === 0) {
            // 各行の先頭にはラベルを格納
            res[k] = label[j];
            continue;
          }
          for (let l = 0; data_in.length > l; l++) {
            if (data_in[l].stop_name !== label[j]) continue;
            for (let m = 0; data_out.length > m; m++) {
              if (data_out[m].stop_name === header[k] && data_in[l].od_id === data_out[m].od_id) {
                count_res++;
              }
            }
          }
          count_res === 0 ? res.push('') : res.push(count_res);
        }
        // 1行分のデータを格納したらpush
        csv_data.push(res);
      }
      // 2次元配列からダウンロード用URLを取得
      return getDownloadUrlAsCsv(csv_data);
    }
    return undefined;
  }, [data, widget_type, route_long_name]);

  // stateの変更に対してデータロードを動作
  useEffect(() => {
    loadDataByDateTimeString({
      start_date: state.start_date,
      end_date: state.end_date,
      start_time: state.start_time,
      end_time: state.end_time,
    });
  }, [state, loadDataByDateTimeString]);

  return useMemo(() => {
    return {
      date_dialog_open,
      csv_data_download_url,
      onDateDialogOpen,
      handleDateUpdated,
    };
  }, [date_dialog_open, csv_data_download_url, onDateDialogOpen, handleDateUpdated]);
}
