import { memo } from 'react';
import { MenuItem, Radio } from '@blueprintjs/core';

/**ラジオ型のmenu **/
export interface RadioMenuItemProps {
  checked: boolean;
  text: string;
  onClick: (b: boolean) => void;
}
export const RadioMenuItem = memo(function RadioMenuItem(props: RadioMenuItemProps) {
  return (
    <MenuItem
      text={
        <Radio
          label={props.text}
          checked={props.checked}
          onClick={() => props.onClick(!props.checked)}
          onChange={() => {}}
        />
      }
    />
  );
});

export default RadioMenuItem;
