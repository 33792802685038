import { memo } from 'react';
import { colors } from 'shared/styles/colors';
import styled from 'styled-components';

/*** 図形の各辺に対する凡例 ***/
const PolygonLineLegend = memo(function PolygonLineLegend() {
  return (
    <ManageLines>
      <Legend>図形の各辺の数字：LINE番号</Legend>
    </ManageLines>
  );
});

const ManageLines = styled.div`
  display: flex;
  padding: 3px;
`;

const Legend = styled.div`
  font-size: larger;
  font-weight: bold;
  color: ${colors.black};
`;

export default PolygonLineLegend;
