// -- basic library --
import React from 'react';

// -- external components --
import RadioBox from './RadioBox';

// -- type declaration --
interface Params {
  data_type: string;
  setDataType: (data_type: string) => void;
  hide_metrics?: boolean;
}

// -- main component --

/***
 * ストリームデータの種類を選択するラジオボックス
 *
 * ***/
const StreamDataTypeRadioBox: React.FC<Params> = (params) => {
  // -- preparations --
  const datas = [
    { name: '画像ファイル', value: 'IMAGE' },
    { name: '動画ファイル', value: 'VIDEO' },
    // { label: '音声ファイル', value: 'AUDIO' },
    // { label: 'PDFファイル', value: 'PDF' },
  ];
  if (!params.hide_metrics) {
    datas.push({ name: 'メトリクス', value: 'METRIC' });
  }

  // -- render part --
  return <RadioBox datas={datas} selectedValue={params.data_type} handleChangeClick={params.setDataType} />;
};

// -- styled components --

// -- finally export part --

export default StreamDataTypeRadioBox;
