import React from 'react';
import ContentBodyMat from 'shared/components/atoms/ContentBodyMat';
import { EditIcon } from 'shared/components/atoms/Icons';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import InputDialog from 'shared/components/molecules/InputDialog';
import { Stream, streamsIdPutAPI } from 'user/api/streams';

interface MetricEndpointPanelProps {
  stream: Stream;
  onStreamChange: () => void;
}
export default class MetricEndpointPanel extends React.PureComponent<MetricEndpointPanelProps> {
  onEdgeboxKeyEdit = () => {
    InputDialog.show('エッジボックスの識別キー', '', '', this.props.stream.edgebox_key || '', this.onChangeEdgeboxKey);
  };
  onChangeEdgeboxKey = (value: string | undefined, isCancel: boolean) => {
    if (!isCancel) {
      streamsIdPutAPI({
        stream_id: this.props.stream.stream_id,
        edgebox_key: value,
      }).then((res) => {
        if (res.status === 200) {
          this.props.onStreamChange();
        }
      });
    }
  };
  onClickEdgeboxKey = () => {
    if (this.props.stream.edgebox_key) {
      // 'None' を送ることで解除します
      this.onChangeEdgeboxKey('None', false);
    } else {
      const array = new Uint32Array(8);
      window.crypto.getRandomValues(array);
      let ek = '';
      for (let i = 0; i < array.length; i++) {
        const v = '00000000' + array[i].toString(16).toLowerCase();
        ek += v.substring(v.length - 8);
      }
      this.onChangeEdgeboxKey(ek, false);
    }
  };
  render() {
    return (
      <ContentBodyMat>
        <div>エッジボックスからのメトリクスの受信</div>
        <div>
          {this.props.stream.edgebox_key ? '公開中' : '非公開'}
          <RoundedButton text={this.props.stream.edgebox_key ? '非公開' : '公開'} onClick={this.onClickEdgeboxKey} />
        </div>
        {this.props.stream.edgebox_key !== null && (
          <div>
            <div>エッジボックスの識別キー</div>
            <div>
              {this.props.stream.edgebox_key} <EditIcon onClick={() => this.onEdgeboxKeyEdit()} />
            </div>
          </div>
        )}
      </ContentBodyMat>
    );
  }
}
