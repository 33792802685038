import React from 'react';
import styled from 'styled-components';
import { Stream, streamsStreamIdVideoM3U8GetAPIUrl, streamsIdGetAPI } from 'user/api/streams';
import { Content } from 'shared/components/molecules/ContentsArea';
import M3u8Play from 'shared/components/molecules/M3u8Play';
import { colors } from 'shared/styles/colors';
import DateRangeWidgetBase from './base/DateRangeWidgetBase';
import M3u8DateSelector from 'shared/components/molecules/M3u8DateSelector';
import { WidgetProps } from './getWidgetType';
import { toISO8601 } from 'shared/models/ISO8601';

/**
 * ビデオプレーヤーウィジェット
 */

interface VideoPlayerWidgetState {
  is_loaded: boolean;
  stream_url: string;
  stream: Stream | null;
  selected_video_date: string;
  parentRef: React.RefObject<HTMLDivElement>; // m3u8プレイヤーの横幅を親要素に合わせる為に親要素のrefを取得する必要がある
}

export default class VideoPlayerWidget extends React.PureComponent<WidgetProps, VideoPlayerWidgetState> {
  constructor(props: WidgetProps) {
    super(props);
    this.state = {
      is_loaded: false,
      stream_url: '',
      stream: null,
      selected_video_date: '',
      parentRef: React.createRef(),
    };
  }
  async componentDidMount() {
    if (!this.props.widget.player_stream_id) return;
    const res = await streamsIdGetAPI({ stream_id: this.props.widget.player_stream_id, disabled_user_type_check: 'True' });
    if (res.status !== 200) return;
    this.setState({ stream: res.data });
    // class componentでuseLocation()の使い方がわからないためwindow.locationでurlを取得
    const url = window.location;
    const query = new URLSearchParams(url.search);
    const tm = query.get('tm');
    const now = tm ? new Date(Number(tm) * 1000) : new Date();
    // m3u8情報の取得
    this.getM3u8({ happened_at: toISO8601(now) });
  }

  private getStreamsStreamIdVideoM3U8 = async (
    stream_id: string,
    query: {
      start?: number;
      speed?: number;
    },
  ) => {
    const request_params = {
      stream_id: stream_id,
      ...query,
    };
    this.setState({ stream_url: streamsStreamIdVideoM3U8GetAPIUrl(request_params) });
  };

  private getM3u8 = async (inputs: { happened_at: string; speed?: number }) => {
    // player_stream_idがなければビデオを取得使用がないためreturn
    if (!this.props.widget.player_stream_id) return;
    const speed = inputs.speed || 1;
    const stream_id = this.props.widget.player_stream_id;
    const live_delay = this.state.stream ? this.state.stream.estimated_live_delay || 60 : 60; // デフォルト 60秒 の遅延
    const happened_day = new Date(inputs.happened_at);
    const now_day = new Date();
    let new_happened_day = new Date();
    if (now_day.getTime() - live_delay * 1000 <= happened_day.getTime()) {
      // In MilliSecs
      const start = Math.floor((now_day.getTime() - live_delay * 1000) / 1000);
      await this.getStreamsStreamIdVideoM3U8(stream_id, {
        start: start,
        speed: speed,
      });
      new_happened_day.setMinutes(new_happened_day.getMinutes() - live_delay / 60); // In Minites
    } else {
      new_happened_day = new Date(inputs.happened_at);
      const start = Math.floor(new_happened_day.getTime() / 1000);
      await this.getStreamsStreamIdVideoM3U8(stream_id, {
        start: start,
        speed: speed,
      });
    }
    this.setState({
      selected_video_date: toISO8601(new_happened_day),
    });
  };

  private getM3u8PlaySize = () => {
    // 動画と再生バーと日付を表示するM3u8Playコンポーネントのサイズは最大でも、カードに従って400px 300pxと設定しておく
    const width = this.state.parentRef.current?.clientWidth ? this.state.parentRef.current?.clientWidth : 400;
    const height = this.state.parentRef.current?.clientHeight ? this.state.parentRef.current?.clientHeight : 300;
    return {
      width: width, //ひとまずは親要素の100%の横幅
      height: height - 55, //footerが55pxなのでそれ以外の高さは動画と再生バーと時間表示に使用する
    };
  };
  render() {
    return (
      <DateRangeWidgetBase
        widget={this.props.widget}
        state={this.props.state}
        hide_scale={true}
        onInitMetrics={() => {}}
        onStateChanged={this.props.onStateChanged}
        onMetricsChanged={() => {}}
        onScaleChanged={() => {}}
      >
        <Content style={{ width: '100%', height: '100%' }}>
          <TopArea ref={this.state.parentRef}>
            <M3u8Play
              width={this.getM3u8PlaySize().width}
              height={this.getM3u8PlaySize().height}
              video_url={this.state.stream_url}
              selected_video_date={
                this.state.selected_video_date ? this.state.selected_video_date : toISO8601(new Date())
              }
              is_loaded={this.state.is_loaded}
              setIsLoaded={(new_is_loaded: boolean) => this.setState({ is_loaded: new_is_loaded })}
              onTimeChange={async (time: string) => await this.getM3u8({ happened_at: time })}
              is_live={this.state.stream ? this.state.stream.data_number_type === 'TIMESTAMP' : false}
            />
          </TopArea>
          <FooterArea>
            <Border />
            <M3u8DateSelector
              selected_video_date={this.state.selected_video_date}
              is_loaded={this.state.is_loaded}
              getM3u8={this.getM3u8}
              display_button_dates_number={this.getM3u8PlaySize().width > 700 ? 7 : 4}
            />
          </FooterArea>
        </Content>
      </DateRangeWidgetBase>
    );
  }
}

// -- styled components --

const TopArea = styled.div`
  width: 100%;
  height: calc(100% - 55px - 3px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FooterArea = styled.div`
  width: 100%;
  height: 55px;
`;

const Border = styled.div`
  width: 100%;
  height: auto;
  border-top: solid 2px ${colors.component_small_border_color};
`;
