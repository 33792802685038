/**
 * SQSのように、コンポーネント間で共通して利用するboolean変数を提供
 * 関数などの、変数を監視できない場合に利用
 * **/
export class SQSBoolean {
  private flag = false;
  // flag変数を取得
  get getFlag() {
    return this.flag;
  }
  // flagを更新
  public updateFlag(flag: boolean) {
    this.flag = flag;
  }
}
