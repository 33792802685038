import { JsonpathGroup } from 'user/api/jsonpathGroups';
import React from 'react';
import { AppParamRowArea, AppParamRowText } from 'shared/components/atoms/ContentsArea';
import InputBox from 'shared/components/atoms/InputBox';
import MultiSelectBox from 'shared/components/atoms/MultiSelectBox';
import RequiredIcon from 'shared/components/atoms/RequiredIcon';
import SelectBox from 'shared/components/atoms/SelectBox';
import { ProcessAppParameter } from 'user/api/processes';
import { Stream } from 'user/api/streams';
import { CrossLine1DialogButton } from 'user/dialogs/CrossLine1Dialog';
import { CrossLine2Button } from 'user/dialogs/CrossLine2Dialog';
import { CsvRowsInputDialogButton } from 'user/dialogs/CsvRowsInputDialog';
import { InputJsonpathGroup } from 'user/dialogs/CsvRowsInputDialog/index';
import { SafieAuthButton } from 'user/dialogs/SafieAuthDialog';
import { StayAreaButton } from 'user/dialogs/StayAreaDialog';
import { StreamCropDialogButton } from 'user/dialogs/StreamCropDialog';
import { TransformedAreaButton } from 'user/dialogs/TransformedAreaDialog';
import { VideoEditorDialogButton } from 'user/dialogs/VideoEditorDialog';
import { DetectionClassesForm } from 'shared/components/molecules/DetectionClassesForm';

// -- type declaration --

interface Params {
  process_app_parameter: ProcessAppParameter;
  app_parameter: string;
  streams: Stream[];
  stream_data_number?: number;
  onChange: (appParameter: string) => void;
}

// -- main component --

const AppParameterInput: React.FC<Params> = (params) => {
  // MuiltiSelectの初期値
  const checkDefaultValue = (value: string) => {
    if (value === '') {
      return undefined;
    }
    return value.split(',');
  };

  // 複数選択項目が変わった時の関数
  const handleListItemsChange = (item: string) => {
    if (params.app_parameter === '') {
      return item;
    }
    const input_item_list = params.app_parameter.split(',');
    if (!input_item_list.includes(item)) {
      // 選択された要素を追加
      input_item_list.push(item);
    }
    return input_item_list.join(',');
  };

  // 複数選択項目を削除する時の関数
  const handleRemove = (index: number | undefined) => {
    const input_item_list = params.app_parameter.split(',');
    if (typeof index === 'number') {
      // 選択された要素を削除
      input_item_list.splice(index, 1);
      return input_item_list.join(',');
    } else {
      return '';
    }
  };

  const getStreamId = (data_type: string[]) => {
    if (data_type.length) {
      for (const s of params.streams) {
        if (data_type.indexOf(s.data_type) !== -1) {
          return s.stream_id;
        }
      }
    } else if (params.streams.length) {
      return params.streams[0].stream_id;
    }
    return undefined;
  };

  const handleJsonpathGroupChange = (jsonpath_group?: JsonpathGroup) => {
    params.onChange(jsonpath_group?.jsonpath_group_id ? jsonpath_group.jsonpath_group_id : '');
  };

  // -- render part --
  return (
    <AppParamRowArea>
      <AppParamRowText>
        {params.process_app_parameter.name}
        {params.process_app_parameter.required ? <RequiredIcon /> : null}
      </AppParamRowText>
      {params.process_app_parameter.input_type === 'TEXT' && (
        <InputBox
          title={params.process_app_parameter.description}
          placeholder='入力してください'
          value={params.app_parameter}
          onChange={(e) => params.onChange(e.currentTarget.value)}
        />
      )}
      {params.process_app_parameter.input_type === 'SELECT' && (
        <SelectBox
          value={params.app_parameter}
          onChange={(e) => params.onChange(e.currentTarget.value)}
          datas={params.process_app_parameter.list_items.map((s) => {
            return {
              name: s,
              value: s,
            };
          })}
          long={true}
        />
      )}
      {params.process_app_parameter.input_type === 'MULTISELECT' && (
        <MultiSelectBox
          selectedItems={checkDefaultValue(params.app_parameter)}
          items={params.process_app_parameter.list_items}
          onItemSelect={(item) => params.onChange(handleListItemsChange(item))}
          onRemove={(_item, index) => params.onChange(handleRemove(index))}
        />
      )}
      {params.process_app_parameter.input_type === 'CROSSLINE' && (
        <CrossLine1DialogButton
          value={params.app_parameter}
          streamId={getStreamId(['VIDEO', 'IMAGE'])}
          streamDataNumber={params.stream_data_number}
          onChange={params.onChange}
        />
      )}
      {params.process_app_parameter.input_type === 'CROSSLINE2' && (
        <CrossLine2Button
          value={params.app_parameter}
          streamId={getStreamId(['VIDEO', 'IMAGE'])}
          streamDataNumber={params.stream_data_number}
          onChange={params.onChange}
        />
      )}
      {params.process_app_parameter.input_type === 'CROP' && (
        <StreamCropDialogButton
          value={params.app_parameter}
          streamId={getStreamId(['VIDEO', 'IMAGE'])}
          streamDataNumber={params.stream_data_number}
          onChange={params.onChange}
        />
      )}
      {params.process_app_parameter.input_type === 'VIDEOEDITOR' && (
        <VideoEditorDialogButton
          value={params.app_parameter}
          streamId={getStreamId(['VIDEO', 'IMAGE'])}
          streamDataNumber={params.stream_data_number}
          onChange={params.onChange}
        />
      )}
      {params.process_app_parameter.input_type === 'CSVROWS' && (
        <CsvRowsInputDialogButton
          value={params.app_parameter}
          streamId={getStreamId(['METRIC'])}
          streamDataNumber={params.stream_data_number}
          required={params.process_app_parameter.required}
          onChange={params.onChange}
        />
      )}
      {params.process_app_parameter.input_type === 'JSONPATHGROUPS' && (
        <InputJsonpathGroup
          value={params.app_parameter}
          onChange={(e) => handleJsonpathGroupChange(e)}
          display={'PROCESS'}
        />
      )}
      {params.process_app_parameter.input_type === 'SAFIEAUTH' && (
        <SafieAuthButton value={params.app_parameter} onChange={params.onChange} />
      )}
      {params.process_app_parameter.input_type === 'STAYAREA' && (
        <StayAreaButton
          value={params.app_parameter}
          stream_id={getStreamId(['VIDEO', 'IMAGE'])}
          stream_data_number={params.stream_data_number}
          onChange={params.onChange}
        />
      )}
      {params.process_app_parameter.input_type === 'TRANSFORMEDAREA1' && (
        <TransformedAreaButton
          value={params.app_parameter}
          title='TRANSFORMEDAREA1'
          streamId={getStreamId(['VIDEO', 'IMAGE'])}
          streamDataNumber={params.stream_data_number}
          onChange={params.onChange}
        />
      )}
      {params.process_app_parameter.input_type === 'TRANSFORMEDAREA2' && (
        <TransformedAreaButton value={params.app_parameter} title='TRANSFORMEDAREA2' onChange={params.onChange} />
      )}
      {params.process_app_parameter.input_type === 'DETCLASS' && (
        <div>
          <DetectionClassesForm
            DET_CLASSES={toArray(params.app_parameter)}
            onChange={(value) => params.onChange(JSON.stringify(value))} />
        </div>
      )}
    </AppParamRowArea>
  );
};

// -- finally export part --
/** 文字列をJSON解析します。値が不正な場合は [] にします。 */
function toArray(s: string) {
  try {
    return JSON.parse(s);
  } catch (e) {
    return [];
  }
}

export default AppParameterInput;
