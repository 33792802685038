// -- basic library --
import React, { useState, useMemo, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import history from 'shared/browserHistory';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';

// -- external functions --
import AlertDialog from 'shared/components/molecules/AlertDialog';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { Content, TopArea, Title, BottomArea, ButtonElementsArea } from 'shared/components/molecules/ContentsArea';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableHeaderType, TableBodyMultipleValueType } from 'shared/components/molecules/Table/type';
import ToggleButton from 'shared/components/molecules/ToggleButton';
import styles, {
  table_cell_button_style,
  table_cell_toggle_button_style,
  table_cell_toggle_button_width,
} from 'shared/styles/styles';
import { isNotSelected, isSelected } from 'shared/utils/is';
import {
  ChannelEventCondition,
  channelsIdEventConditionsChannelEventConditionNumberDeleteAPI,
  channelsIdEventConditionsNumActivatePostAPI,
} from 'user/api/channelsEventConditions';
import MailNotificationDialog, { MailNotificationDialogPropsKey } from 'user/dialogs/MailNotificationDialog';
import { systemSelector } from 'user/redux/slices/systemSlice';
import { loadWrapperFunc } from 'user/utils/loadWrapperFunc';
import ChannelsIdDetailEventConditionsCreate from './CreateDialog';
import ChannelsIdDetailEventConditionsDetail from './DetailDialog';
import { useStreams } from 'user/hooks/useStreams/useStreams';
import { toStringBoolean } from 'shared/models/StringBoolean';
import { useChannelEventConditions } from 'user/hooks/useChannelEventConditions/useChannelEventConditions';
import { useChannel } from 'user/hooks/useChannel/useChannel';

// テーブルの列の情報まとめたデータ
const headers: TableHeaderType[] = [
  {
    id: 'channel_event_condition_name',
    label: 'チャンネルイベント条件名',
    sortable: true,
    width: 'auto',
    search_props: {
      type: 'name',
      default_display: true,
    },
  },
  {
    id: 'changeable_status',
    label: '状態',
    sortable: true,
    width: table_cell_toggle_button_width,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'button_element',
    label: '',
    useDoubleTableButtonStyles: true,
  },
];
// -- type declaration --

type Params = RouteComponentProps<{ channel_id: string }>;

export type TableChannelEventConditionType = {
  id: string;
  channel_event_condition_name: string;
  changeable_status: TableBodyMultipleValueType<string>;
  button_element: JSX.Element;
};

// -- external functions --

// -- main component --
const ChannelsIdDetailAlarm: React.FC<Params> = (params) => {
  // -- redux preparations --
  const system_state = useSelector(systemSelector);

  // local_states
  const [selected_bodies, setSelectedBodies] = useState<TableChannelEventConditionType[]>([]);
  const [open_create_dialog, setOpenCreateDialog] = useState<boolean>(false);
  const [open_detail_dialog, setOpenDetailDialog] = useState<boolean>(false);

  const { streams, streams_record } = useStreams({
    with_output_streams: 'True',
    with_in_channel_streams: 'True',
    data_types: 'METRIC',
  });
  const { channel, loadChannel } = useChannel({ channel_id: params.match.params.channel_id });
  const { channel_event_conditions, channel_event_conditions_record, loadChannelEventConditions } =
    useChannelEventConditions({
      channel_id: params.match.params.channel_id,
    });

  const [selected_channel_event_condition, setSelectedChannelEventCondition] = useState<ChannelEventCondition | null>(
    null,
  );
  const [mail_notification_dialog_param, setMailNotificationDialogParam] = useState<MailNotificationDialogPropsKey>();
  // 作成画面のステート

  // テーブル関係の関数
  const handleClick = (bodies: TableChannelEventConditionType[]) => {
    setSelectedBodies(bodies);
  };

  const handleCreateClick = () => {
    setOpenCreateDialog(true);
  };
  // テーブルの状態でトグルボタンを押した時の関数
  const onChangeStatus = useCallback(
    async (params: { channel_id: string; channel_event_condition_number: string; status: boolean }) => {
      // 今のstatusの反転したbooleanを送信する
      const is_active = toStringBoolean(!params.status);
      const res = await channelsIdEventConditionsNumActivatePostAPI({
        channel_id: params.channel_id,
        channel_event_condition_number: params.channel_event_condition_number,
        is_active: is_active,
      });
      if (res.status === 200) {
        AlertDialog.show('イベント条件の状態を切り替えました');
      }
      await loadChannelEventConditions({ channel_id: params.channel_id });
      await loadChannel(params.channel_id);
      setSelectedBodies([]);
    },
    [loadChannelEventConditions, loadChannel],
  );

  const handleDetailClick = useCallback(
    (channel_event_condition_number: string) => {
      const channel_event_condition = channel_event_conditions_record[channel_event_condition_number];
      if (!channel_event_condition) return;
      setSelectedChannelEventCondition(channel_event_condition);
      setOpenDetailDialog(true);
    },
    [channel_event_conditions_record],
  );

  const table_bodies = useMemo(() => {
    return channel_event_conditions?.map((item) => {
      return {
        id: item.channel_event_condition_number,
        channel_event_condition_name: item.channel_event_condition_name,
        changeable_status: {
          value: item.is_active ? 'ON' : 'OFF',
          available_value: item.is_active ? 'ON' : 'OFF',
          displayable_value: (
            <ToggleButton
              stop_propagation={true}
              onClick={() => {
                onChangeStatus({
                  channel_id: item.channel_id,
                  channel_event_condition_number: item.channel_event_condition_number,
                  status: item.is_active,
                });
              }}
              checked={item.is_active}
              style={table_cell_toggle_button_style}
            />
          ),
        },
        button_element: (
          <ButtonElementsArea>
            <RoundedButton
              text_type='DETAIL'
              onClick={() => handleDetailClick(item.channel_event_condition_number)}
              style={table_cell_button_style}
              stop_propagation
            />
            <RoundedButton
              text='通知設定'
              onClick={() =>
                setMailNotificationDialogParam({
                  channelId: item.channel_id,
                  channelEventCondition: item,
                  channelEventConditionNumber: Number(item.channel_event_condition_number),
                })
              }
              style={table_cell_button_style}
              stop_propagation
            />
          </ButtonElementsArea>
        ),
      };
    });
  }, [channel_event_conditions, onChangeStatus, handleDetailClick]);

  const handleOKClick = async (channel_id: string) => {
    const deletes = async () => {
      for (const selected_body of selected_bodies) {
        await channelsIdEventConditionsChannelEventConditionNumberDeleteAPI({
          channel_id: channel_id,
          channel_event_condition_number: selected_body.id,
        });
      }
      await loadChannelEventConditions({ channel_id });
      setSelectedBodies([]);
    };
    loadWrapperFunc(deletes);
  };

  const handleCancelClick = (channel_id: string) => {
    history.push(`/channels/${channel_id}/event-conditions`);
  };

  const handleDeleteClick = async (channel_id: string) => {
    ConfirmDialog.show(
      <div>
        [確認]選択されているイベント定義を削除します。
        <br />
        本当によろしいですか？
      </div>,
      async () => await handleOKClick(channel_id),
      () => handleCancelClick(channel_id),
      undefined,
    );
  };

  const handleCreateDialogCloseClick = async () => {
    setOpenCreateDialog(false);
    await loadChannelEventConditions({ channel_id: params.match.params.channel_id });
    await loadChannel(params.match.params.channel_id);
    setSelectedBodies([]);
  };

  const handleDetailDialogCloseClick = async () => {
    setOpenDetailDialog(false);
    await loadChannelEventConditions({ channel_id: params.match.params.channel_id });
    await loadChannel(params.match.params.channel_id);
    setSelectedBodies([]);
  };

  // -- render part --
  return (
    <Content>
      {!system_state.loading.preloading && channel ? (
        <>
          <TopArea>
            <Title text='' />
            <RoundedButton
              text='イベント定義作成'
              onClick={handleCreateClick}
              style={{ marginRight: styles.interval_narrow_margin }}
              disabled={isSelected(selected_bodies.length)}
            />
          </TopArea>
          <BottomArea>
            {table_bodies !== undefined ? (
              <BaseTable
                bodies={table_bodies}
                headers={headers}
                table_name='channelsEventConditions'
                selected_bodies={selected_bodies}
                handleCheckClick={handleClick}
                id_abridgement
                footer_option={{
                  text: '＞チェックした項目を削除する',
                  handleClick: async () => await handleDeleteClick(params.match.params.channel_id),
                  disabled: isNotSelected(selected_bodies.length),
                }}
              />
            ) : (
              <Spinner />
            )}
            <PfDialog isOpen={open_create_dialog} onClose={handleCreateDialogCloseClick} title='イベント定義作成'>
              <ChannelsIdDetailEventConditionsCreate
                streams={streams}
                streams_record={streams_record}
                handleCloseClick={handleCreateDialogCloseClick}
                channel_id={params.match.params.channel_id}
                {...params}
              />
            </PfDialog>
            {selected_channel_event_condition === null ? null : (
              <PfDialog isOpen={open_detail_dialog} onClose={handleDetailDialogCloseClick} title='イベント定義詳細'>
                <ChannelsIdDetailEventConditionsDetail
                  streams={streams}
                  streams_record={streams_record}
                  handleCloseClick={handleDetailDialogCloseClick}
                  channel_event_condition={selected_channel_event_condition}
                  {...params}
                />
              </PfDialog>
            )}
          </BottomArea>
        </>
      ) : (
        <Spinner />
      )}
      {mail_notification_dialog_param && (
        <MailNotificationDialog
          isOpen={true}
          onClose={() => setMailNotificationDialogParam(undefined)}
          channelId={mail_notification_dialog_param.channelId}
          channelEventCondition={mail_notification_dialog_param.channelEventCondition}
          channelEventConditionNumber={mail_notification_dialog_param.channelEventConditionNumber}
        />
      )}
    </Content>
  );
};

// -- finally export part --
export default ChannelsIdDetailAlarm;
