import React, { useState, useEffect, useRef, useCallback } from 'react';
import { PolarGraphData } from 'shared/components/molecules/PfGraph';
import RoundedWidgetCard from 'shared/components/molecules/RoundedGraphCard';
import { WidgetScaleType } from 'shared/models/WidgetScaleType';
import FeatureDatetime from 'shared/models/FeatureDatetime';
import getPinnedStatus from 'user/utils/getPinnedStatus';
import { getWidgetRequestInfos } from 'shared/utils/get';
import mergePolarGraphs from 'shared/utils/else/mergePolarGraphs';
import { Device } from 'user/api/device';
import { Stream } from 'user/api/streams';
import { DatapointItem, StreamsGraphsDataGetResponse, streamsIdGraphsDatGetAPI } from 'user/api/streamsGraphs';
import MetricResultDialog from 'user/pages/Devices/DetailPage/CameraAnalysisResultPage/MetricResultDialog';
import CameraAnalysisResultPart from './CameraAnalysisResultPart';
import { StreamDeviceGraph } from 'shared/models/StreamDeviceGraph';

interface CameraAnalysisResultWrapperProps {
  stream_graph: StreamDeviceGraph;
  stream: Stream;
  device: Device;
  start?: FeatureDatetime;
  end?: FeatureDatetime;
  scale?: WidgetScaleType;
  hide_scale?: boolean;
  auto_reload_interval: number; // 自動リロードの間隔 -1は無効
  onStreamGraphDelete?: () => void;
  onPinStreamGraph?: (stream_device_graph_id?: string, stream_id?: string) => void;
}

// -- main component --
/** 1つのグラフを包む部分。ここにstateを格納することで、拡大とカードでデータを共有できる**/
const CameraAnalysisResultWrapper: React.FC<CameraAnalysisResultWrapperProps> = (params) => {
  // timer用のref
  const interval_ref: React.MutableRefObject<ReturnType<typeof setInterval> | undefined> = useRef();
  // -- local states --
  const [graph_data, setGraphData] = useState<DatapointItem[] | PolarGraphData[] | undefined>(undefined);
  const [start, setStart] = React.useState<FeatureDatetime>(params.start || new FeatureDatetime('today'));
  const [end, setEnd] = React.useState<FeatureDatetime>(params.end || new FeatureDatetime('today'));
  const [scale, setScale] = React.useState<WidgetScaleType>(params.scale || 'HOUR');
  const [is_expanding_dialog_oepn, setIsExpandingDialogOpen] = useState<boolean>(false);
  // -- functions --

  /** グラフのデータを取得し、読み込む関数**/
  const loadGraphData = async (p: { scale?: WidgetScaleType; start?: FeatureDatetime; end?: FeatureDatetime }) => {
    // 初期化
    setGraphData(undefined);
    const sc = p.scale || scale;
    const st = p.start || start;
    const en = p.end || end;
    const request_infos = getWidgetRequestInfos(st.getTime(false), en.getTime(true), sc);
    let new_graph_data: StreamsGraphsDataGetResponse['items'] = [];
    for (const ri of request_infos) {
      const res = await streamsIdGraphsDatGetAPI({
        stream_device_graph_id: params.stream_graph.id,
        stream_id: params.stream.stream_id,
        period: ri.period,
        start: ri.start + '',
        end: ri.end + '',
      });
      if (res.status === 200) {
        if (params.stream_graph.graph_type === 'BAR') {
          new_graph_data = [...(new_graph_data as DatapointItem[]), ...(res.data.items as DatapointItem[])];
        } else if (params.stream_graph.graph_type === 'CIRCLE') {
          new_graph_data = mergePolarGraphs(new_graph_data as PolarGraphData[], res.data.items as PolarGraphData[]);
        } else if (params.stream_graph.graph_type === 'TABLE') {
          new_graph_data = [...(new_graph_data as DatapointItem[]), ...(res.data.items as DatapointItem[])];
        }
      }
    }
    setGraphData(new_graph_data);
  };

  // scaleを変更する関数
  const onScaleChange = (scale: WidgetScaleType) => {
    // TABLEの場合は変更できない
    if (params.stream_graph.graph_type === 'TABLE') return;
    setScale(scale);
  };

  const onIsExpandingDialogOpen = () => {
    setIsExpandingDialogOpen(true);
  };

  const onIsExpandingDialogClose = () => {
    setIsExpandingDialogOpen(false);
  };

  // timerをリセット
  const teardownTimer = useCallback(() => {
    if (interval_ref.current !== undefined) {
      clearInterval(interval_ref.current);
      interval_ref.current = undefined;
    }
  }, []);

  // -- onload function --
  useEffect(() => {
    const newstart = params.start || start;
    const newend = params.end || end;
    const newscale = params.scale || scale;
    setStart(newstart);
    setEnd(newend);
    setScale(newscale);
    (async function () {
      await loadGraphData({
        scale: newscale,
        start: newstart,
        end: newend,
      });
    })();
  }, [params.start, params.end, params.scale, params.stream_graph]); /* eslint-disable-line */

  // 自動リロード関連
  useEffect(() => {
    // 事前に動いていた場合はクリアー
    teardownTimer();
    // タイマーのセットアップ
    if (params.auto_reload_interval !== -1) {
      const newinterval = setInterval(async () => {
        await loadGraphData({});
      }, params.auto_reload_interval);
      interval_ref.current = newinterval;
    }
    // unmount時は、timerをリセット
    return () => {
      teardownTimer();
    };
  }, [params.auto_reload_interval]); /* eslint-disable-line */

  return (
    <RoundedWidgetCard
      large={params.stream_graph.graph_type === 'TABLE'}
      pinned={getPinnedStatus({
        device: params.device,
        stream_id: params.stream.stream_id,
        stream_graph: params.stream_graph,
      })}
    >
      <CameraAnalysisResultPart
        {...params}
        onIsExpandingDialogOpen={onIsExpandingDialogOpen}
        start={start}
        end={end}
        scale={scale}
        loadGraphData={loadGraphData}
        setEnd={setEnd}
        setStart={setStart}
        setScale={onScaleChange}
        graph_data={graph_data}
      />
      {is_expanding_dialog_oepn && (
        <MetricResultDialog
          stream={params.stream}
          device={params.device}
          stream_graph={params.stream_graph}
          isOpen={is_expanding_dialog_oepn}
          onClose={onIsExpandingDialogClose}
          start={start}
          end={end}
          scale={scale}
          loadGraphData={loadGraphData}
          setEnd={setEnd}
          setStart={setStart}
          setScale={onScaleChange}
          graph_data={graph_data}
        />
      )}
    </RoundedWidgetCard>
  );
};

// -- finally export part --

export default CameraAnalysisResultWrapper;
