import { Route, Router, Switch } from 'react-router-dom';

import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/select/lib/css/blueprint-select.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';
import './App.css';

import { useSelector } from 'react-redux';
import browserHistory from 'shared/browserHistory';
import PfDocument from 'user/components/molecules/PfDocument';
import Accounts from './pages/Accounts/TopPage';
import AndroidDevices from './pages/Devices/AndroidDevices/TopPage';
import EdgeDevices from './pages/Devices/EdgeDevices/TopPage';
import EdgeDeviceDetail from './pages/Devices/EdgeDevices/DetailPage';
import Billings from './pages/Billings/TopPage';
import BillingsFileProcessDetailPage from './pages/Billings/FileProcessDetailPage';
import Channels from './pages/Channels/TopPage';
import ChannelsDetail from './pages/Channels/DetailPage';
import Dashboards from './pages/Dashboards/TopPage';
import Devices from './pages/Devices/TopPage';
import DevicesDetail from './pages/Devices/DetailPage';
import Events from './pages/Events/TopPage';
import FileProcesses from './pages/FileProcesses/TopPage';
import FileProcessesIdDetail from './pages/FileProcesses/DetailPage';
import InitialPasswordChange from './pages/InitialPasswordChange/TopPage';
import Login from './pages/Login/TopPage';
import LoginChangedPassword from './pages/LoginChangedPassword/TopPage';
import LoginForgotPassword from './pages/LoginForgotPassword/TopPage';
import Metrics from './pages/Metrics/TopPage';
import MetricsIdDetails from './pages/Metrics/DetailPage';
import Roles from './pages/Roles/TopPage';
import Streams from './pages/Streams/TopPage';
import StreamsDetail from './pages/Streams/DetailPage';
import StreamsPackagesFavorite from './pages/StreamsPackagesFavorite/TopPage';
import Users from './pages/Users/TopPage';
import { authSelector } from './redux/slices/authSlice';
import DashboardsWidgets from './pages/Dashboards/DetailPage';
import { useEffect } from 'react';
import Notifications from './pages/Notifications/TopPage';

function App() {
  const auth_state = useSelector(authSelector);
  const { is_logged_in } = auth_state;
  // Redirectコンポーネントがうまく動作しないので、browserHistoryを監視してリダイレクトする
  useEffect(() => {
    if (browserHistory.location.pathname === '/') {
      browserHistory.push(is_logged_in ? '/notifications' : '/login');
    }
  }, [is_logged_in]);
  return (
    <Router history={browserHistory}>
      <Switch>
        <Route exact path='/login' component={Login} />
        <Route exact path='/password/forgot' component={LoginForgotPassword} />
        <Route exact path='/password/changed' component={LoginChangedPassword} />
        <Route exact path='/initialPasswordChange' component={InitialPasswordChange} />
        <PfDocument>
          <Switch>
            <Route exact path='/dashboards' component={Dashboards} />
            <Route exact path='/dashboards/:dashboard_id/:panel_id?' render={(p) => <DashboardsWidgets {...p} />} />
            <Route exact path='/notifications' component={Notifications} />
            <Route exact path='/streams' component={Streams} />
            <Route exact path='/streams/:stream_id/:panel_id?' component={StreamsDetail} />
            <Route exact path='/streams-packages-favorite' component={StreamsPackagesFavorite} />
            <Route exact path='/events/:channel_id?' component={Events} />
            <Route exact path='/accounts' component={Accounts} />
            <Route exact path='/metrics' component={Metrics} />
            <Route exact path='/metrics/:metric_id/:panel_id?' component={MetricsIdDetails} />
            {auth_state.is_admin && (
              <Switch>
                <Route exact path='/android/devices' component={AndroidDevices} />
                <Route exact path='/billings/:yearmonth?' component={Billings} />
                <Route
                  exact
                  path='/billings/:yearmonth/:component_name/:process_type/fileprocess/detail'
                  component={BillingsFileProcessDetailPage}
                />
                <Route exact path='/channels' component={Channels} />
                <Route exact path='/channels/:channel_id/:panel_id?' component={ChannelsDetail} />
                <Route exact path='/edge/devices' component={EdgeDevices} />
                <Route exact path='/edge/devices/:edge_device_key/:panel_id?' component={EdgeDeviceDetail} />
                <Route exact path='/devices' component={Devices} />
                <Route exact path='/devices/:device_id/:panel_id?' component={DevicesDetail} />
                <Route exact path='/users' component={Users} />
                <Route exact path='/roles' component={Roles} />
                <Route exact path='/file-processes' component={FileProcesses} />
                <Route exact path='/file-processes/:channel_id/:panel_id?' component={FileProcessesIdDetail} />
              </Switch>
            )}
          </Switch>
        </PfDocument>
      </Switch>
    </Router>
  );
}

export default App;
