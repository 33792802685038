export type StatisticMethod =
  | 'Key'
  | 'FirstValue'
  | 'LastValue'
  | 'Minimum'
  | 'Maximum'
  | 'Sum'
  | 'Average'
  | 'Count'
  | 'Mode';

const statistic_method_record = {
  Key: 0,
  FirstValue: 0,
  LastValue: 0,
  Minimum: 0,
  Maximum: 0,
  Sum: 0,
  Average: 0,
  Count: 0,
  Mode: 0,
} as const;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isStatisticMethod = (data: any): data is StatisticMethod =>
  typeof data === 'string' && data in statistic_method_record;
