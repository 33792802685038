import React from 'react';
import { TextArea } from '@blueprintjs/core';
import InputComponent from 'shared/components/molecules/InputComponent';

export interface LiveTextSettingPaneValues {
  html?: string;
  htmlScript?: string;
}
interface LiveTextSettingPaneProps {
  values: LiveTextSettingPaneValues;
  onChange: (p: LiveTextSettingPaneValues) => void;
}
interface LiveTextSettingPaneState {}
export default class LiveTextSettingPane extends React.PureComponent<
  LiveTextSettingPaneProps,
  LiveTextSettingPaneState
> {
  constructor(props: LiveTextSettingPaneProps) {
    super(props);
    this.state = {};
  }
  private handleHtmlChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const v = this.props.values;
    v.html = e.currentTarget.value;
    this.props.onChange(v);
  };
  private handleHtmlScriptChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const v = this.props.values;
    v.htmlScript = e.currentTarget.value;
    this.props.onChange(v);
  };

  render() {
    return (
      <>
        <InputComponent text='HTML' required>
          <TextArea defaultValue={this.props.values.html} onChange={this.handleHtmlChange} fill={true}></TextArea>
        </InputComponent>
        <InputComponent text='HTMLスクリプト' required>
          <TextArea
            defaultValue={this.props.values.htmlScript}
            onChange={this.handleHtmlScriptChange}
            fill={true}
          ></TextArea>
        </InputComponent>
      </>
    );
  }
}
