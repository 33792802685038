import { useCallback, useEffect, useMemo, useState } from 'react';
import { ProcessCategoryType } from 'shared/models/ProcessCategoryType';
import { keyBy } from 'shared/utils/converter';
import { getAllProcesses, Process } from 'user/api/processes';

/**
 * プロセス一覧を取得するcustom hooks
 */
export const useProcesses = (category?: ProcessCategoryType, visibility?: 'all') => {
  const [processes, setProcesses] = useState<Process[] | undefined>(undefined);
  const processes_record: Record<string, Process> = useMemo(() => {
    return processes ? keyBy(processes, (p) => p.process_id) : {};
  }, [processes]);
  const loadProcesses = useCallback(
    async (request_category?: ProcessCategoryType, request_visibility?: 'all') => {
      const new_processes = await getAllProcesses(request_category ?? category, request_visibility ?? visibility);
      setProcesses(new_processes);
    },
    [category, visibility],
  );

  useEffect(() => {
    loadProcesses();
  }, [loadProcesses]);

  return useMemo(() => {
    return {
      processes,
      processes_record,
      setProcesses,
      loadProcesses,
    };
  }, [processes, processes_record, setProcesses, loadProcesses]);
};
