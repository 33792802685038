import React from 'react';
import { MenuProps, Menu, MenuItem } from '@blueprintjs/core';
import { Layer, LayerObject } from './types';

interface ObjectMenuProps extends MenuProps {
  layer: Layer;
  layerObject: LayerObject;
  onLayerProperty: (e: React.MouseEvent<HTMLElement, MouseEvent>, layer: Layer, layerObject: LayerObject) => void;
  onLayerDelete: (e: React.MouseEvent<HTMLElement, MouseEvent>, layer: Layer, layerObject: LayerObject) => void;
}
export default class ObjectMenu extends React.PureComponent<ObjectMenuProps> {
  render() {
    return (
      <Menu {...this.props}>
        <MenuItem
          text='設定...'
          onClick={(e) => this.props.onLayerProperty(e, this.props.layer, this.props.layerObject)}
        />
        <MenuItem text='削除' onClick={(e) => this.props.onLayerDelete(e, this.props.layer, this.props.layerObject)} />
      </Menu>
    );
  }
}
