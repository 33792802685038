import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PfBoldText from 'shared/components/atoms/PfBoldText';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import AppParameterInput from 'user/components/molecules/AppParameterInput';
import { Footer, SubTextBasicProcess } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import { ProcessFlowPanels } from 'shared/components/molecules/ProcessPanels';
import { RequestAppParameter } from 'shared/models/RequestAppParameter';
import checkAppParameterRequired from 'user/utils/checkAppParameterRequired';
import { isNotOnlySpace } from 'shared/utils/is';

import { Channel, channelsIdGetAPI } from 'user/api/channels';
import { channelsIdProcessflowPostAPI, ProcessflowStep } from 'user/api/channelsProcesses';
import { Process, ProcessAppParameter } from 'user/api/processes';
import { CachedProcessFlowInfos, CachedProcessFlows, ProcessFlow, ProcessFlowInfo } from 'user/api/processflows';
import { Stream, CachedStreams } from 'user/api/streams';
import ChannelProcessHeartbeatPart from 'user/dialogs/CreateChannelProcessDialog/ChannelProcessHeartbeatPart';
import StreamSelectionPart from 'user/dialogs/CreateChannelProcessDialog/StreamSelectionPart';
import { systemSelector } from 'user/redux/slices/systemSlice';

// -- types --

type Params = {
  channel_id: string;
  stream: Stream;
  stream_data_number: string | number;
  cachedStreams: CachedStreams;
  cachedProcessFlows: CachedProcessFlows;
  cachedProcessflowInfos: CachedProcessFlowInfos;
  onClose: (p?: { isCanceled?: boolean }) => void;
  inferreddata_name: string;
};

interface StepInfo {
  input_stream_ids: string[][];
  app_parameter: Record<string, string>; // API送信する時用のアップパラメーター
  heartbeat: Record<string, string>;
  process_app_parameter: ProcessAppParameter[]; //画面に表示する用のアップパラメーター
  process: Process;
}

// -- main component --
const ProcessFlowPanel: React.FC<Params> = (params) => {
  // -- redux preparations --
  const system_state = useSelector(systemSelector);

  // -- local states --
  const [channel, setChannel] = useState<Channel | undefined>(undefined);
  const [streams, setStreams] = useState<Stream[] | undefined>(undefined);

  // -- 入力用states --
  const [selectedProcessflowInfo, setSelectedProcessflowInfo] = useState<ProcessFlowInfo | undefined>(undefined);
  // 各プロセスごとに必要な情報をまとめた変数
  const [stepsinfos, setStepsinfos] = useState<StepInfo[]>([]);

  // -- processflows state--
  const [processflows, setProcessflows] = useState<ProcessFlow[] | undefined>(undefined);

  // -- get external datas --

  const getStreams = () => {
    params.cachedStreams.get().then((data) => {
      // streamDataの親streamだけは選択できるようにする。with_in_channel_streams: 'False'としているため、この操作がないと、選択できなくなる
      setStreams([params.stream, ...data]);
    });
  };

  const getProcessflows = () => {
    params.cachedProcessFlows.get().then((data) => {
      setProcessflows(data);
    });
  };

  const getChannelData = async (channel_id: string) => {
    const res = await channelsIdGetAPI({ channel_id: channel_id });
    if (res.status === 200) {
      setChannel(res.data);
    }
  };

  // validate
  /**
   * 入力チェックを行い、エラーの場合はメッセージを含む例外をスローします。
   */
  const validate = () => {
    if (!selectedProcessflowInfo) {
      throw new Error(`プロセスフローを選択してください`);
    }
    if (stepsinfos[0] && !stepsinfos[0].process.can_be_oneshot) {
      throw new Error(
        `${stepsinfos[0].process.process_name}のプロセスはワンショットに対応していない為、選択できません`,
      );
    }
    for (let i = 0; i < stepsinfos.length; i++) {
      if (!isStreamSelected(i)) {
        throw new Error(`${stepsinfos[i].process.process_name}のデータを選択してください`);
      }
      if (!checkAppParameterRequired(stepsinfos[i].app_parameter, stepsinfos[i].process_app_parameter)) {
        throw new Error(`${stepsinfos[i].process.process_name}のプロセスの詳細条件を正しく入力してください`);
      }
    }
  };

  // -- handlers --

  // プロセスが変わった時の関数
  const handleProcessChange = async (processflow: ProcessFlow) => {
    const pfi = await params.cachedProcessflowInfos.get({ process_flow_id: processflow.process_flow_id });
    if (!pfi) {
      AlertDialog.show('指定のプロセスフローを取得できませんでした。');
      return;
    }
    let canbe_oneshot = true;
    // 最初のプロセスがoneshotに対応していないのならば、指定のプロセスフローは選択できない
    if (pfi.processes[0] && !pfi.processes[0].can_be_oneshot) {
      canbe_oneshot = false;
    }
    if (!canbe_oneshot) {
      AlertDialog.show(
        <div>
          指定のプロセスフローは
          <br />
          ワンショットに対応していない為
          <br />
          選択できません。
        </div>,
      );
      return;
    }
    const new_stepsinfos: StepInfo[] = [];
    // プロセス毎に、必要な情報をまとめる
    pfi.processes.forEach((pr) => {
      const stepsinfo: StepInfo = {
        input_stream_ids: [],
        app_parameter: {},
        heartbeat: {},
        process_app_parameter: [],
        process: pr,
      };
      // 入力データ(ストリーム)が必要な場合、1つ目の入力streamは、streamDataの親フォルダ(親stream)を指定する
      if (pr.input_stream_constraints.length > 0) {
        stepsinfo.input_stream_ids.push([params.stream.stream_id]);
      }
      const row_app_parameter: RequestAppParameter = {};
      pr.app_parameters.forEach((ap) => {
        row_app_parameter[ap.key] = ap.default_value;
      });
      stepsinfo.app_parameter = row_app_parameter;
      stepsinfo.process_app_parameter = pr.app_parameters;
      new_stepsinfos.push(stepsinfo);
    });
    setSelectedProcessflowInfo(pfi);
    setStepsinfos(new_stepsinfos);
  };

  const handleStreamSelectionChange = (p: { value: string[][]; step: number }) => {
    // ステップインフォの長さを超えてしまっている。
    if (p.step >= stepsinfos.length || p.step < 0) {
      return;
    }
    const new_stepsinfos = [...stepsinfos];
    new_stepsinfos[p.step].input_stream_ids = p.value;
    setStepsinfos(new_stepsinfos);
  };

  //app_parameterがどれかでも変わった時の関数
  const handleSetAppParameter = (p: { key: string; value: string; step: number }) => {
    // ステップインフォの長さを超えてしまっている。
    if (p.step >= stepsinfos.length || p.step < 0) {
      return;
    }
    const new_app_parameter = { ...stepsinfos[p.step].app_parameter };
    new_app_parameter[p.key] = p.value;
    const new_stepsinfos = [...stepsinfos];
    new_stepsinfos[p.step].app_parameter = new_app_parameter;
    setStepsinfos(new_stepsinfos);
  };

  const handleSetHeartbeat = (p: { value: Record<string, string>; step: number }) => {
    // ステップインフォの長さを超えてしまっている。
    if (p.step >= stepsinfos.length || p.step < 0) {
      return;
    }
    const new_stepsinfos = [...stepsinfos];
    new_stepsinfos[p.step].heartbeat = p.value;
    setStepsinfos(new_stepsinfos);
  };

  const handleFinishClick = async () => {
    // 入力チェック
    try {
      validate();
    } catch (e) {
      if (e instanceof Error) {
        AlertDialog.show(e.message);
      }
      return;
    }

    const new_stepsinfos = [...stepsinfos];
    // app_parameterにおいて、必須でない and 入力がスペースのみの場合は送らないようにデータ整形
    for (let i = 0; i < stepsinfos.length; i++) {
      const stepinfo = stepsinfos[i];
      const new_app_parameter: Record<string, string> = {};
      for (let j = 0; j < stepinfo.process_app_parameter.length; j++) {
        const pap = stepinfo.process_app_parameter[j];
        const key = pap.key;
        // 存在しない or スペースならスキップ
        if (!stepinfo.app_parameter[key] || !isNotOnlySpace(stepinfo.app_parameter[key])) continue;
        // すでにバリデーション済みなので存在するデータは全て送る
        new_app_parameter[key] = stepinfo.app_parameter[key];
      }
      new_stepsinfos[i].app_parameter = new_app_parameter;
    }
    const steps_for_post: ProcessflowStep[] = stepsinfos.map((si) => {
      return {
        app_parameter: si.app_parameter,
        input_stream_ids: si.input_stream_ids,
        heartbeat: si.heartbeat,
      };
    });
    const res = await channelsIdProcessflowPostAPI({
      channel_id: params.channel_id,
      channel_process_name: params.inferreddata_name,
      process_flow_id: selectedProcessflowInfo?.process_flow_id || '',
      is_oneshot: 'True',
      steps: steps_for_post,
      input_stream_data_number: [params.stream_data_number],
    });

    // resの正常を持って完了と判定する
    if (res.status === 200) {
      params.onClose();
      AlertDialog.show('新規チャンネルプロセスの追加に成功しました');
    }
  };

  const isStreamSelected = (step: number) => {
    // ステップインフォの長さを超えてしまっている。
    if (step >= stepsinfos.length || step < 0) {
      return false;
    }
    const input_stream_ids = stepsinfos[step].input_stream_ids;
    // ストリームが選択されていない
    if (input_stream_ids.length !== selectedProcessflowInfo?.processes[step].input_stream_constraints.length) {
      return false;
    }
    for (const v of input_stream_ids) {
      for (const w of v) {
        if (w === '') return false;
      }
    }
    return true;
  };
  // -- onload function --
  useEffect(() => {
    (async function () {
      getStreams();
      getProcessflows();
      await getChannelData(params.channel_id);
    })();
  }, []); /* eslint-disable-line */

  // -- render part --
  return (
    <>
      {system_state.loading.preloading ||
      processflows === undefined ||
      channel === undefined ||
      streams === undefined ? (
        <Spinner />
      ) : (
        <>
          <InputComponent text='プロセスフロー' required>
            <SubTextBasicProcess>プロセスフローを選択してください</SubTextBasicProcess>
            <ProcessFlowPanels
              processflows={processflows}
              selected_id={selectedProcessflowInfo?.process_flow_id || ''}
              onClick={handleProcessChange}
            />
          </InputComponent>
          {stepsinfos
            ? stepsinfos.map((si, i) => {
                // 全ての入力データ(ストリーム)を結合（無理やり感がある）
                const all_input_stream_ids = stepsinfos.map((si) => si.input_stream_ids.flat()).flat();
                return (
                  <div key={i}>
                    <PfBoldText>{si.process.process_name}</PfBoldText>
                    <StreamSelectionPart
                      process={si.process}
                      streams={streams}
                      selected_stream_ids={si.input_stream_ids}
                      fixed_first_input_stream={true}
                      onChange={(v) =>
                        handleStreamSelectionChange({
                          value: v,
                          step: i,
                        })
                      }
                    />
                    {si.process_app_parameter.length > 0 && (
                      <InputComponent text='プロセスの詳細条件' required>
                        {si.process_app_parameter.map((spap, j) => {
                          return (
                            <AppParameterInput
                              key={`${i}_${j}`}
                              process_app_parameter={spap}
                              app_parameter={String(si.app_parameter[spap.key])}
                              streams={
                                // 入力input_streamがない場合はundefined
                                all_input_stream_ids.length <= 0
                                  ? []
                                  : streams.filter((v) => all_input_stream_ids.flat().indexOf(v.stream_id) !== -1)
                              }
                              stream_data_number={Number(params.stream_data_number)}
                              onChange={(app_parameter_value) =>
                                handleSetAppParameter({
                                  key: spap.key,
                                  value: app_parameter_value,
                                  step: i,
                                })
                              }
                            />
                          );
                        })}
                      </InputComponent>
                    )}
                    <ChannelProcessHeartbeatPart
                      key={si.process.process_id}
                      process={si.process}
                      value={si.heartbeat}
                      onChange={(v) => handleSetHeartbeat({ value: v, step: i })}
                    />
                  </div>
                );
              })
            : null}
          <Footer>
            <RoundedButton onClick={handleFinishClick} text='登録' />
          </Footer>
        </>
      )}
    </>
  );
};

// -- styled components --

// -- finally export part --

export default ProcessFlowPanel;
