import { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy } from 'shared/utils/converter';
import { RequestStreamsGet, Stream, streamsGetAPI } from 'user/api/streams';

/**
 * ページングを伴うストリーム一覧を取得するcustom hooks
 */
export const useStreamsWithPaging = ({
  limit,
  with_output_streams,
  with_in_channel_streams,
  device_id,
  data_types,
  data_number_types,
}: RequestStreamsGet) => {
  const [streams, setStreams] = useState<Stream[] | undefined>(undefined);
  const [next_meta_data, setNextMetaData] = useState<{
    has_next: boolean;
    exclusive_start_stream_id: string;
  }>({
    has_next: false,
    exclusive_start_stream_id: '',
  });
  const streams_record: Record<string, Stream> = useMemo(() => {
    return streams ? keyBy(streams, (s) => s.stream_id) : {};
  }, [streams]);
  const loadStreams = useCallback(
    async (request?: RequestStreamsGet) => {
      const r: RequestStreamsGet = {
        device_id: request?.device_id ?? device_id,
        data_types: request?.data_types ?? data_types,
        data_number_types: request?.data_number_types ?? data_number_types,
        with_output_streams: request?.with_output_streams ?? with_output_streams,
        with_in_channel_streams: request?.with_in_channel_streams ?? with_in_channel_streams,
        exclusive_start_stream_id: request?.exclusive_start_stream_id,
        limit: request?.limit ?? limit,
      };
      const res = await streamsGetAPI({ ...r });
      if (res.status === 200) {
        setStreams(res.data.items);
        setNextMetaData({
          has_next: res.data.has_next,
          exclusive_start_stream_id: res.data.last_evaluated_stream_id ?? '',
        });
      }
    },
    [limit, with_output_streams, with_in_channel_streams, device_id, data_types, data_number_types],
  );

  const addStreams = useCallback(
    async (request?: RequestStreamsGet) => {
      const { has_next, exclusive_start_stream_id } = next_meta_data;
      if (!has_next) return;
      const r: RequestStreamsGet = {
        device_id: request?.device_id ?? device_id,
        data_types: request?.data_types ?? data_types,
        data_number_types: request?.data_number_types ?? data_number_types,
        with_output_streams: request?.with_output_streams ?? with_output_streams,
        with_in_channel_streams: request?.with_in_channel_streams ?? with_in_channel_streams,
        exclusive_start_stream_id: request?.with_in_channel_streams ?? exclusive_start_stream_id,
        limit: request?.limit ?? limit,
      };
      const res = await streamsGetAPI({
        ...r,
      });
      if (res.status !== 200) return;

      let new_streams = [];
      if (streams === undefined) {
        new_streams = [...res.data.items];
      } else {
        new_streams = [...streams, ...res.data.items];
      }
      setNextMetaData({
        has_next: res.data.has_next,
        exclusive_start_stream_id: res.data.last_evaluated_stream_id ?? '',
      });
      setStreams(new_streams);
    },
    [
      streams,
      next_meta_data,
      limit,
      with_output_streams,
      with_in_channel_streams,
      device_id,
      data_types,
      data_number_types,
    ],
  );

  useEffect(() => {
    loadStreams();
  }, [loadStreams]);

  return useMemo(() => {
    return {
      streams,
      streams_record,
      next_meta_data,
      setStreams,
      loadStreams,
      addStreams,
    };
  }, [streams, setStreams, streams_record, next_meta_data, loadStreams, addStreams]);
};
