import { DashboardWidget } from 'user/api/dashboardsWidgets';
import { WidgetState } from 'user/pages/Dashboards/DetailPage/UsualDashboardWidgets/DashboardWidgetCard';
import { useCallback, useMemo, useState } from 'react';

export interface UseBasicHooksParams {
  widget: DashboardWidget;
  defaults: WidgetState;
}
/**
 * DateRangeWidgetBase系で利用する基本的なuseState
 */
export default function useBasicHooks() {
  const [date_dialog_open, setDateDialogOpen] = useState<boolean>(false);
  const onDateDialogOpen = useCallback(() => {
    setDateDialogOpen(true);
  }, [setDateDialogOpen]);
  const onDateDialogClose = useCallback(() => {
    setDateDialogOpen(false);
  }, [setDateDialogOpen]);
  return useMemo(() => {
    return {
      date_dialog_open,
      onDateDialogOpen,
      onDateDialogClose,
    };
  }, [date_dialog_open, onDateDialogOpen, onDateDialogClose]);
}
