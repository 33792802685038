import { useCallback, useEffect, useMemo, useState } from 'react';
import { Channel, channelsIdGetAPI, RequestChannelsIdGet } from 'user/api/channels';

/**
 * チャンネルを1つ取得するcustom hooks
 */
export const useChannel = ({ channel_id }: RequestChannelsIdGet) => {
  const [channel, setChannel] = useState<Channel | undefined>(undefined);
  const loadChannel = useCallback(
    async (request_channel_id?: string) => {
      const res = await channelsIdGetAPI({ channel_id: request_channel_id ?? channel_id });
      if (res.status === 200) {
        setChannel(res.data);
      }
    },
    [channel_id],
  );

  useEffect(() => {
    loadChannel();
  }, [loadChannel]);

  return useMemo(() => {
    return {
      channel,
      setChannel,
      loadChannel,
    };
  }, [channel, setChannel, loadChannel]);
};
