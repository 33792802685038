// -- basic library --
import React, { useCallback, useState } from 'react';

// -- external components --
import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import PfMenu from 'shared/components/atoms/PfMenu';
import { TrimedImage } from 'shared/models';
import { dateToNumeric } from 'shared/utils/converter/date';
import { getDownloadUrlAsJson } from 'shared/utils/get';
import ImportSettingFileDialog from 'user/dialogs/ImportSettingFileDialog';

// -- external functions --
import { SettingExpImpValue } from './types';

// -- external types --

// ここに新たに、変更したい要素があれば追加する
export interface SettingExpImpButtonProps {
  // CROSSLINEの検知ライン
  crosslines?: number[][][];
  setCrosslines?(crosslines: number[][][]): void;
  // STAYAREAの検知ライン
  stayarea_crosslines?: number[][][];
  setStayareaCrosslines?(stayarea_crosslines: number[][][]): void;
  /**
   * crop用のデータ
   * 元々trimedImageで登録していたため古いjsonがtrimedImageで保存されている
   * 破壊的変更となってしまうので、lowerCamelCaseにしている
   * */
  trimedImage?: TrimedImage;
  setTrimedImage?(trimedImage: TrimedImage): void;
  hide_import?: boolean;
  hide_export?: boolean;
}

// パラメータから受け取ったデータをjsonにして返却
const mergeData = (params: {
  crosslines?: number[][][];
  trimedImage?: TrimedImage;
  stayarea_crosslines?: number[][][];
}) => {
  const { crosslines, trimedImage, stayarea_crosslines } = params;
  // どれもない時はundefined
  if (!crosslines && !trimedImage && !stayarea_crosslines) {
    return undefined;
  }
  const value: SettingExpImpValue = {};
  if (crosslines) {
    value.crosslines = crosslines;
  }
  if (trimedImage) {
    value.trimedImage = {
      cropArea: trimedImage.cropArea,
      rotate: trimedImage.rotate,
    };
  }
  if (stayarea_crosslines) {
    value.stayarea_crosslines = stayarea_crosslines;
  }
  return value;
};

// どのデータのインポートができるのか明示するテキスト
const getImportMessage = (params: {
  setCrosslines?(crosslines: number[][][]): void;
  setTrimedImage?(trimedImage: TrimedImage): void;
  setStayareaCrosslines?(stayarea_crosslines: number[][][]): void;
}) => {
  const { setCrosslines, setTrimedImage, setStayareaCrosslines } = params;
  let text = '[';
  if (setCrosslines) {
    text += '検知ライン,';
  }
  if (setTrimedImage) {
    text += 'クロップ,';
  }
  if (setStayareaCrosslines) {
    text += '滞留検知ライン,';
  }
  text += ']';
  return text;
};

// どのデータをエクスポートできるのか明示するテキスト
const getExportMessage = (params: {
  crosslines?: number[][][];
  trimedImage?: TrimedImage;
  stayarea_crosslines?: number[][][];
}) => {
  const { crosslines, trimedImage, stayarea_crosslines } = params;
  let text = '[';
  if (crosslines) {
    text += '検知ライン,';
  }
  if (trimedImage) {
    text += 'クロップ,';
  }
  if (stayarea_crosslines) {
    text += '滞留検知ライン,';
  }
  text += ']';
  return text;
};

// ダウンロード名を返却
const downloadName = (): string => {
  const dt = new Date();
  return `${dateToNumeric(dt)}_setting`;
};

/***
 * 横3点ボタンの中身
 * 設定jsonをエクスポート/インポートできる
 * ***/
const SettingExpImpButton: React.FC<SettingExpImpButtonProps> = ({
  hide_import,
  hide_export,
  crosslines,
  setCrosslines,
  stayarea_crosslines,
  setStayareaCrosslines,
  trimedImage,
  setTrimedImage,
}) => {
  // ファイルを入力するダイアログのopen
  const [is_open, setIsOpen] = useState<boolean>(false);

  const closeDialog = useCallback(
    (value?: SettingExpImpValue) => {
      if (value) {
        // 検知ラインを入力するコンポーネントに渡すデータ
        if (value.crosslines) {
          setCrosslines?.(value.crosslines);
        }
        // トリミング
        if (value.trimedImage && trimedImage) {
          const newTrimedImage = {
            ...trimedImage,
            ...value.trimedImage,
          };
          setTrimedImage?.(newTrimedImage);
        }
        // 対流検知ラインを入力するコンポーネントに渡すデータ
        if (value.stayarea_crosslines) {
          setStayareaCrosslines?.(value.stayarea_crosslines);
        }
      }
      setIsOpen(false);
    },
    [setCrosslines, setTrimedImage, setStayareaCrosslines, trimedImage, setIsOpen],
  );

  const openDialog = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  return (
    <>
      <PfMenu
        icon={IconNames.MORE}
        menu={
          <Menu>
            {!hide_export && (
              <MenuItem
                href={getDownloadUrlAsJson(
                  mergeData({
                    trimedImage,
                    crosslines,
                    stayarea_crosslines,
                  }),
                )}
                text={`既存の設定をエクスポート${getExportMessage({
                  trimedImage,
                  crosslines,
                  stayarea_crosslines,
                })}`}
                download={downloadName()}
                disabled={!crosslines && !trimedImage && !stayarea_crosslines}
              />
            )}
            {!hide_import && !hide_export && <MenuDivider />}
            {!hide_import && (
              <MenuItem
                onClick={openDialog}
                text={`既存の設定をインポート${getImportMessage({
                  setCrosslines,
                  setTrimedImage,
                  setStayareaCrosslines,
                })}`}
              />
            )}
          </Menu>
        }
      />
      {is_open && (
        <ImportSettingFileDialog
          isOpen={is_open}
          onClose={closeDialog}
          use_values={{
            // 更新関数が存在していれば、そのvalueのimportを有効化
            crosslines: !!setCrosslines,
            trimedImage: !!setTrimedImage,
            stayarea_crosslines: !!setStayareaCrosslines,
          }}
        />
      )}
    </>
  );
};

export default SettingExpImpButton;
