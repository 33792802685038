// ストリームに関する関数を記載する

import { Stream } from 'user/api/streams';
import { StreamDataNumberType } from 'shared/models/StreamDataNumberType';

/**
 * ストリームのデータ番号の基準が指定の値の時はtrue
 * **/
export const isSpecifiedStreamDataNumberType = (
  stream: Stream | null | undefined,
  specified_stream_data_number_type: StreamDataNumberType,
) => {
  if (!stream) {
    return false;
  }
  return stream.data_number_type === specified_stream_data_number_type;
};

/**
 * ストリームのデータ番号の基準がSEQUENCEの時はtrue
 * **/
export const isSequence = (stream: Stream | null | undefined) => {
  return isSpecifiedStreamDataNumberType(stream, 'SEQUENCE');
};
