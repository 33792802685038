import { AxiosResponse } from 'axios';
import { AcquisitionStatus } from 'shared/models/AcquisitionStatus';
import { CommonAPIRequestType, isCommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';
import { ISO8601 } from 'shared/models/ISO8601';
import { Query } from 'shared/models/Query';
import { StatusType } from 'shared/models/StatusType';
import sendAxios from 'shared/axios/sendAxios';
import { getClient } from './base';

/*** エンティティ ***/

export interface EdgeDeviceMoviedata {
  tenant_id: string;
  edge_device_key: string;
  device_movie_number: number;
  data_acquisition_at: ISO8601;
  recording_time: number;
  stream_data_name: string;
  stream_id: string;
  stream_data_number: number;
  acquisition_status: AcquisitionStatus;
  status: StatusType;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601;
}

export interface EdgeDeviceMoviedatasWithPaging {
  items: EdgeDeviceMoviedata[];
  has_next: boolean;
  last_evaluated_device_movie_number?: number;
}

const sample_device_movie_data_1: EdgeDeviceMoviedata = {
  tenant_id: '00000001',
  edge_device_key: '00000001',
  device_movie_number: 1,
  data_acquisition_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  recording_time: 10,
  stream_data_name: 'stream_1',
  stream_id: '00000001',
  stream_data_number: 1,
  acquisition_status: 'PLANNED',
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
};

/*** Caching mechanism ***/

export class CachedEdgeDevicesMoviedatas {
  private searched = false;
  private cache: EdgeDeviceMoviedata[] = [];
  private params: RequestEdgeDevicesMoviedatasGet;
  constructor(params: RequestEdgeDevicesMoviedatasGet) {
    this.params = params;
  }
  async get() {
    if (!this.searched) {
      let esk: string | number | undefined = undefined;
      for (;;) {
        const res: AxiosResponse<EdgeDeviceMoviedatasWithPaging> = await edgeDevicesMoviedatasGetAPI({
          ...this.params,
          exclusive_start_device_movie_number: esk,
        });
        if (res.status === 200) {
          this.cache = [...this.cache, ...res.data.items];
          if (!res.data.has_next) {
            break;
          } else {
            esk = res.data.last_evaluated_device_movie_number;
          }
        } else {
          break;
        }
      }
      this.searched = true;
    }
    return this.cache;
  }
}

/*** [GET] /api/edge/devicesMoviedatas ***/

export interface RequestEdgeDevicesMoviedatasGet {
  edge_device_key: string;
  exclusive_start_device_movie_number?: string | number;
}

export const edgeDevicesMoviedatasGetAPI = (params: RequestEdgeDevicesMoviedatasGet) => {
  const { edge_device_key, exclusive_start_device_movie_number } = params;
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `/api/edge/devices/${edge_device_key}/moviedatas`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    exclusive_start_device_movie_number,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<EdgeDeviceMoviedatasWithPaging>(axios, path, query, form, method, {
    items: [sample_device_movie_data_1],
    has_next: false,
  });
};

/*** [POST] /api/edge/devicesMoviedatas ***/

export interface RequestEdgeDevicesMoviedatasPost extends CommonAPIRequestType {
  edge_device_key: string;
  data_acquisition_at: string;
  recording_time: number;
  stream_id?: string;
  stream_data_name: string;
  use_edge_device_stream_id?: 'True'; // 指定のstream_idではなく、デバイスに紐づくstream_idを指定する場合は'True'
}

export const edgeDevicesMoviedataPostAPI = (params: RequestEdgeDevicesMoviedatasPost) => {
  const { edge_device_key, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/edge/devices/${edge_device_key}/moviedatas`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'edge_device_key' || isCommonAPIRequestType(key)) continue;
    form.append(key, value + '');
  }

  // 送信
  return sendAxios<EdgeDeviceMoviedata>(axios, path, query, form, method, sample_device_movie_data_1);
};

/*** [GET] /api/edge/devices/{edge_device_key}/moviedatas/{device_movie_number} ***/

export interface RequestEdgeDevicesKeyMoviedatasNumGet extends CommonAPIRequestType {
  edge_device_key: string;
  device_movie_number: number | string;
}

export const edgeDevicesKeyMoviedatasNumGetAPI = (params: RequestEdgeDevicesKeyMoviedatasNumGet) => {
  const { edge_device_key, device_movie_number, disabled_load, disabled_error_message, ended_load } =
    toAPIRequestParams({
      ...params,
      api_send_type: params.api_send_type ?? 'multipleTransmission',
    });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/edge/devices/${edge_device_key}/moviedatas/${device_movie_number}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<EdgeDeviceMoviedatasWithPaging>(axios, path, query, form, method, {
    items: [sample_device_movie_data_1],
    has_next: false,
  });
};

/*** [PUT] /api/edge/devices/{edge_device_key}/moviedatas/{device_movie_number} ***/

export interface RequestEdgeDevicesKeyMoviedatasNumPut extends CommonAPIRequestType {
  edge_device_key: string;
  device_movie_number: number | string;
}

export const edgeDevicesKeyMoviedatasNumPutAPI = (params: RequestEdgeDevicesKeyMoviedatasNumPut) => {
  const { edge_device_key, device_movie_number, disabled_load, disabled_error_message, ended_load } =
    toAPIRequestParams({
      ...params,
      api_send_type: params.api_send_type ?? 'multipleTransmission',
    });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/edge/devices/${edge_device_key}/moviedatas/${device_movie_number}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<EdgeDeviceMoviedata>(axios, path, query, form, method, sample_device_movie_data_1);
};

/*** [DELETE] /api/edge/devices/{edge_device_key}/moviedatas/{device_movie_number} ***/

export interface RequestEdgeDevicesKeyMoviedatasNumDelete extends CommonAPIRequestType {
  edge_device_key: string;
  device_movie_number: number | string;
}

export const edgeDevicesKeyMoviedatasNumDeleteAPI = (params: RequestEdgeDevicesKeyMoviedatasNumDelete) => {
  const { edge_device_key, device_movie_number, disabled_load, disabled_error_message, ended_load } =
    toAPIRequestParams({
      ...params,
      api_send_type: params.api_send_type ?? 'multipleTransmission',
    });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/edge/devices/${edge_device_key}/moviedatas/${device_movie_number}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<EdgeDeviceMoviedata>(axios, path, query, form, method, sample_device_movie_data_1);
};
