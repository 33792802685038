// -- basic library --
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import SelectBox, { SelectData } from 'shared/components/atoms/SelectBox';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Content, Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import { keyBy } from 'shared/utils/converter';
import { isNotOnlySpace } from 'shared/utils/is';
import { Channel, channelsIdInputStreamsPostAPI } from 'user/api/channels';
import { Stream } from 'user/api/streams';
import { systemSelector } from 'user/redux/slices/systemSlice';

type Params = {
  onClose: () => void;
  channel: Channel;
  streams?: Stream[];
};

// -- main component --
const Create: React.FC<Params> = ({ channel, onClose, streams }) => {
  // -- redux preparations --
  const system_state = useSelector(systemSelector);

  // -- local states --
  const [stream_id, setStreamId] = useState<string>('');

  const stream_id_names = useMemo(() => {
    // 指定のチャンネルに既に登録されているstream_idは選べないようにするため情報が必要
    const input_output_stream_id_record = keyBy(
      [...channel.output_stream_ids, ...channel.input_stream_ids],
      (id) => id,
    );

    const new_stream_id_names: SelectData[] = [];
    streams?.forEach((stream) => {
      // 指定のチャンネルに既に存在するstream_idならスキップ
      if (stream.stream_id in input_output_stream_id_record) {
        return;
      }
      new_stream_id_names.push({
        name: stream.stream_name,
        value: stream.stream_id,
      });
    });
    return new_stream_id_names;
  }, [channel, streams]);

  const handleFinishClick = async () => {
    if (!isNotOnlySpace(stream_id)) {
      AlertDialog.show('入力データは必須です');
      return;
    }
    const res = await channelsIdInputStreamsPostAPI({
      channel_id: channel.channel_id,
      stream_id: stream_id,
    });
    if (res.status === 200) {
      AlertDialog.show('入力データの追加に成功しました');
      onClose();
    }
  };

  // -- render part --
  return (
    <Content>
      {system_state.loading.preloading || stream_id_names === undefined ? (
        <Spinner />
      ) : (
        <>
          <InputComponent text='入力データ' required>
            <SelectBox
              value={stream_id}
              onChange={(e) => setStreamId(e.currentTarget.value)}
              datas={stream_id_names}
              long={true}
            />
          </InputComponent>

          <Footer>
            <RoundedButton onClick={handleFinishClick} text_type='CREATE' />
          </Footer>
        </>
      )}
    </Content>
  );
};

// -- finally export part --

export default Create;
