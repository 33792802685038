// -- basic library --
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

// -- external components --
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { KonvaEventObject } from 'konva/types/Node';
import { Stage as StageRefType } from 'konva/types/Stage';
import { CircleConfig } from 'konva/types/shapes/Circle';
import { Circle, Group, Label, Text, Tag, Stage, Layer, Line, Image as ImageKonva } from 'react-konva';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import BlueprintButton from 'shared/components/atoms/SelectableBlueprintButton';
import { Content, FlexCenterDiv } from 'shared/components/molecules/ContentsArea';
import RadioBox from 'shared/components/molecules/RadioBox';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import { isBetweenRange, isNumberSquareArrayOfSize2 } from 'shared/utils/is';
import { arr2DTo1D, trimedImageToSize } from 'shared/utils/converter';
import { divisePolygon, getPolygonPoints, multiplyPolygon } from 'shared/utils/else/polygonFunctions';
import { TrimedImage } from 'shared/models';
import styled from 'styled-components';
import SettingExpImpButton from 'user/components/molecules/SettingExpImpButton/SettingExpImpButton';
import { average } from 'shared/utils/get';
import PolygonLineLegend from './PolygonLineLegend';

/***
 * 図形を描画するコンポネント
 * polygons: [[[x0,y0], [x1,y1]], [[x0,y0]]]のように指定
 * ***/
interface DrawPolygonParams {
  imgsrc: string;
  polygons: number[][][]; // // エリア, 点, xy
  trimed_image?: TrimedImage;
  onChange: (polygons: number[][][]) => void;
  setting_exp_imp_button_props?: {
    hide_import?: boolean;
    hide_export?: boolean;
  };
}

// 描画モード, 修正モード
type MODE = 'pen' | 'hand';

export const DrawPolygonObject: React.FC<DrawPolygonParams> = ({
  imgsrc,
  polygons,
  trimed_image,
  onChange,
  setting_exp_imp_button_props,
}) => {
  // 描画中かどうか判定する
  const isDrawing = useRef(false);
  // konvaの最大親要素のref
  const stageRef: React.LegacyRef<StageRefType> = useRef(null);
  const parentRef: React.LegacyRef<HTMLDivElement> = useRef<HTMLDivElement>(null);
  // stageの一個上のコンポーネントのref
  const stageParentRef = useRef<HTMLDivElement>(null);

  // 画像の高さ
  const [height, setHeight] = useState<number>(580);
  // n角形のnの部分
  const [polygon_number, setPolygonNumber] = useState(4);
  // モード切り替え
  const [mode, setMode] = useState<MODE>('pen');
  //   const [color, setColor] = useState<Color>('red')
  // 選択されている図形のindex
  const [selected_polygon_index, setSelectedPolygonIndex] = useState<number | null>(null);
  // 図形の頂点の丸を動かす際、どの丸(startから時計回りに, 0,1,2,...)を掴んでいるかを保持する。
  // nullの時は掴んでいない
  const [selected_vertex_index, setSelectedVertexIndex] = useState<number | null>(null);

  // konvaのImageに格納するimgデータ
  const [img, setImg] = useState<CanvasImageSource | undefined>(undefined);
  // width / height
  const [img_ratio, setImgRario] = useState<number>(16 / 9);

  // 高さとレシオから横幅を出力する
  const getWidth = (height: number, img_ratio: number) => {
    return height * img_ratio;
  };

  // polygon_pointsのlengthの範囲内であればtrue, 範囲外であればfalse
  const checkRangePolygonIndex = (index: number) => {
    return isBetweenRange(index, 0, polygons.length);
  };

  // 図形を引ける最大数
  const checkPolygonsLength = (max_length = 5) => {
    if (polygons.length >= max_length) {
      return false;
    }
    return true;
  };

  // 図形の頂点が、指定の(indexの)polygonに存在するかどうか
  const checkVertexIndex = (vertex_index: number, index: number) => {
    const flag1 = checkRangePolygonIndex(index);
    const polygon: number[][] = polygons[index];
    const flag2 = isBetweenRange(vertex_index, 0, polygon.length - 1);
    return flag1 && flag2;
  };

  // indexを指定して。polygon_pointsを更新する
  const updatePolygonPointsWhereIndex = (pp: number[][], index: number) => {
    if (!checkRangePolygonIndex(index)) return;
    const new_polygon_points = [...polygons];
    new_polygon_points[index] = pp;
    onChange(new_polygon_points);
  };

  // 指定の頂点のpolygon_pointを変更する
  const changePolygonPoint = (p: { x: number; y: number; vertex_index: null | number; index: number }) => {
    // vertex_indexがnull or サイズオーバーの時はreturn
    if (p.vertex_index === null || !checkVertexIndex(p.vertex_index, p.index)) return;
    const new_polygon: number[][] = [...polygons[p.index]];
    new_polygon[p.vertex_index] = [p.x, p.y];
    updatePolygonPointsWhereIndex(new_polygon, p.index);
  };

  // マウスを押下した時の関数('pen'モード)
  const onMouseDownModePen = (evt: KonvaEventObject<MouseEvent>) => {
    if (!checkPolygonsLength()) return;
    isDrawing.current = true;
    const pos = evt.target.getStage()?.getPointerPosition();
    if (!pos) {
      return;
    }
    const x = pos.x;
    const y = pos.y;
    const new_polygongs: number[][][] = [...polygons];
    new_polygongs.push([[x / getWidth(height, img_ratio), y / height]]);
    onChange(new_polygongs);
  };

  // マウスを動かしている時の関数('pen'モード)
  const onMouseMoveModePen = (evt: KonvaEventObject<MouseEvent>) => {
    if (!checkPolygonsLength(6)) return;
    // 描画中でなければreturn
    // 四角形なので0,1,2,3点目で終了
    if (!isDrawing.current) {
      return;
    }
    const stage = evt.target.getStage();
    const pos = stage?.getPointerPosition();
    if (!pos) return;
    // 最後の要素のindex
    const index = polygons.length - 1;
    // 現在のpolygon('pen'モードの時は、必ず新しい図形を追加するので最後の要素がそれに当たる)
    let polygon: number[][] = [...polygons[index]];
    // 要素が1以上存在しないときはreturn ([x0, y0]はなくてはならない)
    if (polygon.length <= 0) return;

    const width = getWidth(height, img_ratio);
    const x = pos.x;
    const y = pos.y;
    // 回転を利用して残りの座標を決定しているため、割合座標での計算では値が狂う。
    // そのため、始点(polygon[0])の座標を絶対座標に戻してから処理を行い、最後に割合座標に戻している
    polygon = getPolygonPoints(
      {
        start: {
          x: polygon[0][0] * width,
          y: polygon[0][1] * height,
        },
        end: {
          x: x,
          y: y,
        },
      },
      polygon_number,
    );
    const p = divisePolygon({
      polygon: polygon,
      width: width,
      height: height,
    });
    updatePolygonPointsWhereIndex(p, index);
  };

  // マウスを離した時の関数('pen'モード)
  const onMouseUpModePen = (_evt: KonvaEventObject<MouseEvent>) => {
    if (!checkPolygonsLength(6)) return;
    isDrawing.current = false;
  };

  // マウスを押下した時の関数('hand'モード)
  const onMouseDownModeHand = (evt: KonvaEventObject<MouseEvent>, index: number) => {
    // 描画開始
    isDrawing.current = true;
    // 座標が画像のサイズを超えないように
    const pos = evt.target.getStage()?.getPointerPosition();
    if (!pos) return;
    changePolygonPoint({
      x: pos.x / getWidth(height, img_ratio),
      y: pos.y / height,
      vertex_index: selected_vertex_index,
      index: index,
    });
  };

  // マウスを動かしている時の関数('hand'モード)
  const onMouseMoveModeHand = (evt: KonvaEventObject<MouseEvent>, index: number) => {
    if (!isDrawing.current) return;
    // 座標が画像のサイズを超えないように
    const pos = evt.target.getStage()?.getPointerPosition();
    const width = getWidth(height, img_ratio);
    if (!pos) return;
    changePolygonPoint({
      x: pos.x / width,
      y: pos.y / height,
      vertex_index: selected_vertex_index,
      index: index,
    });
  };

  // マウスを離した時の関数('hand'モード)
  const onMouseUpModeHand = (evt: KonvaEventObject<MouseEvent>, index: number) => {
    isDrawing.current = false;
    // no drawing - skipping
    // 座標が画像のサイズを超えないように
    const pos = evt.target.getStage()?.getPointerPosition();
    const width = getWidth(height, img_ratio);
    if (!pos) return;
    changePolygonPoint({
      x: pos.x / width,
      y: pos.y / height,
      vertex_index: selected_vertex_index,
      index: index,
    });
    // リサイズのポジションを初期に直す
    // これをやらないと、マウスをクリックするたびに線が移動してしまう。
    setSelectedVertexIndex(null);
  };

  // マウスを押下した時の関数
  const onMouseDown = (evt: KonvaEventObject<MouseEvent>) => {
    if (mode === 'pen') {
      onMouseDownModePen(evt);
    } else if (mode === 'hand') {
      // 選択されているpolygonが存在しない or 範囲外ならreturn
      if (selected_polygon_index === null || !checkRangePolygonIndex(selected_polygon_index)) return;
      onMouseDownModeHand(evt, selected_polygon_index);
    }
  };
  // マウスを動かしている時の関数
  const onMouseMove = (evt: KonvaEventObject<MouseEvent>) => {
    if (mode === 'pen') {
      onMouseMoveModePen(evt);
    } else if (mode === 'hand') {
      // 選択されているpolygonが存在しない or 範囲外ならreturn
      if (selected_polygon_index === null || !checkRangePolygonIndex(selected_polygon_index)) return;
      onMouseMoveModeHand(evt, selected_polygon_index);
    }
  };
  // マウスを離した時の関数
  const onMouseUp = (evt: KonvaEventObject<MouseEvent>) => {
    if (mode === 'pen') {
      onMouseUpModePen(evt);
    } else if (mode === 'hand') {
      // 選択されているpolygonが存在しない or 範囲外ならreturn
      if (selected_polygon_index === null || !checkRangePolygonIndex(selected_polygon_index)) return;
      onMouseUpModeHand(evt, selected_polygon_index);
    }
  };

  // モードを変更した時の関数
  // 選択されているindexはリセットする
  const changeMode = (nm: MODE) => {
    setMode(nm);
    setSelectedPolygonIndex(null);
    setSelectedVertexIndex(null);
  };

  // マウスが、図形上に侵入した時の関数
  const onMouseEnterPolygon = (evt: KonvaEventObject<DragEvent>) => {
    if (mode !== 'hand') return;
    const stage = evt.target.getStage();
    if (!stage) return;
    const content = stage.getContent();
    // 先端の丸にポインターが入っている時はスキップ
    if (content.style.cursor === 'nesw-resize') return;
    // オブジェクトにホバーするとcursorが変わる
    content.style.cursor = 'move';
  };
  // マウスが、図形上から離れた時の関数
  const onMouseOutPolygon = (evt: KonvaEventObject<DragEvent>) => {
    if (mode !== 'hand') return;
    const stage = evt.target.getStage();
    if (!stage) return;
    const content = stage.getContent();
    // オブジェクトのホバーが外れるとcursorが変わる
    content.style.cursor = 'default';
  };

  //図形をクリックした時の関数
  const onPolygonClick = (evt: KonvaEventObject<MouseEvent>, index: number) => {
    evt.evt.stopPropagation();
    setSelectedPolygonIndex(index);
  };

  //背景画像をクリックした時の関数
  const onImageClick = (_evt: KonvaEventObject<MouseEvent>) => {
    setSelectedPolygonIndex(null);
  };

  // 画像を描画する
  const drawImage = useCallback(() => {
    const image = new Image();
    image.src = trimed_image?.url || imgsrc;
    image.onload = () => {
      let new_img_ratio = img_ratio;
      let new_height = height;
      if (trimed_image) {
        const size = trimedImageToSize(trimed_image);
        new_img_ratio = size.width / size.height;
        image.width = size.width;
        image.height = size.height;
        new_height = size.height;
      } else if (stageParentRef.current && stageParentRef.current.clientWidth) {
        new_img_ratio = image.width / image.height;
        // 横幅が足りない時は、heightを調節
        if (height * new_img_ratio > stageParentRef.current.clientWidth) {
          setHeight(stageParentRef.current.clientWidth / new_img_ratio);
        }
      }

      setImg(image);
      setImgRario(new_img_ratio);
      setHeight(new_height);
    };
  }, [imgsrc, trimed_image, setImg, setImgRario, setHeight, stageParentRef, height, img_ratio]);

  // 図形を全て消去
  const onClickReset = () => {
    onChange([]);
  };

  // 選択されている図形を消去
  const onClickDelete = (selected_index: number | null) => {
    if (selected_index === null) return;
    const new_polygons = polygons.filter((polygon, i) => i !== selected_index);
    onChange(new_polygons);
    setSelectedPolygonIndex(null);
  };

  // 一番最後に書かれた図形を消去
  const onClickBack = () => {
    const new_polygons = [...polygons];
    new_polygons.pop();
    onChange(new_polygons);
  };

  // -- onload function --
  useEffect(() => {
    drawImage();
  }, [drawImage]);

  return (
    <Content ref={parentRef}>
      <TopArea
        style={{
          alignItems: 'center',
        }}
      >
        <FlexCenterDiv>
          <BlueprintButton
            icon={<Icon iconSize={16} icon={IconNames.EDIT} />}
            onClick={() => changeMode('pen')}
            selected={mode === 'pen'}
            title='線を描画するモード'
          />
          <BlueprintButton
            icon={<Icon iconSize={16} icon={IconNames.HAND} />}
            onClick={() => changeMode('hand')}
            selected={mode === 'hand'}
            title='線を操作するモード'
          />
        </FlexCenterDiv>
        {/* <SelectColorButtonBox 
            color={color}
            onChangeComplete={(c) => setColor(c.rgb)}
        /> */}
        <RadioBox
          selectedValue={polygon_number + ''}
          handleChangeClick={(value) => setPolygonNumber(Number(value))}
          datas={[
            {
              name: '四角形',
              value: '4',
            },
            {
              name: '五角形',
              value: '5',
            },
          ]}
          style={{ display: 'flex', alignItems: 'center' }}
        />
        <FlexCenterDiv align_center>
          <RoundedButton
            onClick={() => onClickDelete(selected_polygon_index)}
            text='削除'
            small
            is_margin_right
            disabled={selected_polygon_index === null}
          />
          <RoundedButton onClick={onClickBack} text='最新を消す' small is_margin_right />
          <RoundedButton onClick={onClickReset} text='リセット' small is_margin_right />
          <SettingExpImpButton
            stayarea_crosslines={polygons}
            setStayareaCrosslines={onChange}
            trimedImage={trimed_image}
            hide_export={setting_exp_imp_button_props?.hide_export}
            hide_import={setting_exp_imp_button_props?.hide_import}
          />
        </FlexCenterDiv>
      </TopArea>
      <SecondTopArea>
        <PolygonLineLegend />
      </SecondTopArea>
      <CenterArea ref={stageParentRef}>
        <Stage
          width={height * img_ratio}
          height={height}
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
          // onDragStart={(e) => onDragStart(e, selected_polygon_index)}
          // onDragEnd={(e) => onDragEnd(e, selected_polygon_index)}
          onMouseEnter={onMouseEnterPolygon}
          onMouseOut={onMouseOutPolygon}
          ref={stageRef}
        >
          {img && (
            <Layer>
              <ImageKonva image={img} width={height * img_ratio} height={height} onClick={onImageClick} />
              {polygons.map((polygon, index) => {
                return (
                  <PolygonGroup
                    key={index}
                    polygon={polygon}
                    index={index}
                    width={getWidth(height, img_ratio)}
                    height={height}
                    selected_polygon_index={selected_polygon_index}
                    selected_vertex_index={selected_vertex_index}
                    setSelectedVertexIndex={setSelectedVertexIndex}
                    mode={mode}
                    onClick={onPolygonClick}
                  />
                );
              })}
            </Layer>
          )}
        </Stage>
      </CenterArea>
    </Content>
  );
};

/*** 1つのpolygonに対する必要情報全て ***/
const PolygonGroup: React.FC<{
  polygon: number[][];
  index: number;
  mode: MODE;
  selected_polygon_index: number | null;
  selected_vertex_index: number | null;
  setSelectedVertexIndex: (selected_vertex_index: number | null) => void;
  onClick: (e: KonvaEventObject<MouseEvent>, index: number) => void;
  width: number;
  height: number;
}> = ({
  polygon,
  index,
  selected_polygon_index,
  selected_vertex_index,
  setSelectedVertexIndex,
  onClick,
  width,
  height,
  mode,
}) => {
  // 絶対値の座標
  const multiplied_polygon = multiplyPolygon({
    polygon: polygon,
    width: width,
    height: height,
  });
  // 1次元の座標
  const polygon1D = arr2DTo1D(multiplied_polygon);
  return (
    <Group key={index} onClick={(e) => onClick(e, index)}>
      <Line points={polygon1D} stroke={colors.red} strokeWidth={5} closed={true} />
      <LabelText index={index} polygon={multiplied_polygon} />
      <LineLabelText polygon={multiplied_polygon} />
      {selected_polygon_index === index && (
        <ResizePointCircles
          polygon_points={multiplied_polygon}
          mode={mode}
          selected_vertex_index={selected_vertex_index}
          setSelectedVertexIndex={setSelectedVertexIndex}
        />
      )}
    </Group>
  );
};

/*** 図形のラベル名を表示するコンポーネント ***/
const LabelText: React.FC<{
  polygon: number[][];
  index: number;
}> = ({ polygon, index }) => {
  return (
    <>
      {polygon.length >= 1 && isNumberSquareArrayOfSize2(polygon) && (
        <Label fill={colors.white} x={polygon[0][0]} y={polygon[0][1]}>
          <Tag fill={colors.black} />
          <Text text={`AREA${index}`} fill={colors.light_green} fontSize={16} padding={5} />
        </Label>
      )}
    </>
  );
};

/*** 図形のライン番号のラベル名を表示するコンポーネント ***/
const LineLabelText: React.FC<{
  polygon: number[][];
}> = ({ polygon }) => {
  // ラベルを表示させるそれぞれのラインの座標
  const lines_points = useMemo(() => {
    const centers: number[][] = [];
    if (polygon.length <= 1) return null;
    const polygon_last_index = polygon.length - 1;
    // 原点から反時計回りでLINE番号を連番
    for (let i = 0; i < polygon.length; i++) {
      if (i === polygon_last_index) break;
      centers.push([average(polygon[i][0], polygon[i + 1][0]), average(polygon[i][1], polygon[i + 1][1])]);
    }
    // 最後のLINE番号
    centers.push([
      average(polygon[0][0], polygon[polygon_last_index][0]),
      average(polygon[0][1], polygon[polygon_last_index][1]),
    ]);
    return centers;
  }, [polygon]);

  return (
    <>
      {lines_points &&
        lines_points.map((pol, i) => {
          return (
            <Label fill={colors.white} x={pol[0]} y={pol[1]} key={i}>
              <Tag fill={colors.black} />
              <Text text={`${i}`} fill={colors.light_green} fontSize={20} padding={5} />
            </Label>
          );
        })}
    </>
  );
};

/***
 * 図形の先端の丸を表示するコンポーネント
 * 書く丸を動かすと、図形を変更できる
 * ***/
const ResizePointCircles: React.FC<{
  polygon_points: number[][];
  mode: 'hand' | 'pen';
  selected_vertex_index: number | null;
  setSelectedVertexIndex: (selected_verted_index: number | null) => void;
}> = ({ polygon_points, mode, setSelectedVertexIndex }) => {
  // マウスが、円上かに侵入した時の関数
  const onMouseEnterCircle = (evt: KonvaEventObject<DragEvent>) => {
    evt.evt.stopPropagation();
    if (mode !== 'hand') return;
    const stage = evt.target.getStage();
    if (!stage) return;
    const content = stage.getContent();
    // オブジェクトにホバーするとcursorが変わる
    content.style.cursor = 'nesw-resize';
  };
  // マウスが、円上を離れた時の関数
  const onMouseOutCircle = (evt: KonvaEventObject<DragEvent>) => {
    evt.evt.stopPropagation();
    if (mode !== 'hand') return;
    const stage = evt.target.getStage();
    if (!stage) return;
    const content = stage.getContent();
    // オブジェクトのホバーが外れるとcursorが変わる
    content.style.cursor = 'default';
  };

  const baseCircleConfig: CircleConfig = {
    radius: 6,
    fill: colors.light_green,
    stroke: colors.black,
    strokeWidth: 2,
    onMouseEnter: onMouseEnterCircle,
    onMouseOut: onMouseOutCircle,
  };

  return (
    <>
      {/* 先端の丸 */}
      {mode === 'hand' &&
        polygon_points.map((polygon, i) => {
          const x = polygon[0] || undefined;
          const y = polygon[1] || undefined;
          return (
            <Circle
              {...baseCircleConfig}
              key={`circle-${i}`}
              x={x}
              y={y}
              onMouseDown={() => setSelectedVertexIndex(i)}
            />
          );
        })}
    </>
  );
};

const SecondTopArea = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
`;

const TopArea = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
`;

const CenterArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${styles.interval_narrow_margin} 0px;
`;

export default DrawPolygonObject;
