import { AreaCountSetting, Metric } from 'user/api/dashboardsWidgets';
import { Stream } from 'user/api/streams';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import SelectBox from 'shared/components/atoms/SelectBox';
import InputComponent from 'shared/components/molecules/InputComponent';
import React from 'react';
import { graph_types, MetricGraphType } from 'shared/models/MetricGraphType';
import styles from 'shared/styles/styles';
import { FooterArea, Table, TableArea, Tbody, Td, Th, Thead, Tr, WholeArea } from './StyledComponent';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import InputBox from 'shared/components/atoms/InputBox';
import { MenuItem, Select } from '@material-ui/core';
import { area_name, count_target } from 'shared/utils/constants';
import Spinner from 'shared/components/atoms/Spinner';

export interface AreaCountSettingPanelValues {
  ac_stream_id?: string;
  graph_type?: MetricGraphType;
}
interface AreaCountSettingPanelProps {
  metricStreams: Stream[];
  onCreate: (p: boolean) => void;
  onSettingChange: (p: AreaCountSettingPanelValues) => void;
  onMetricChange: (p: Metric[]) => void;
  values: AreaCountSettingPanelValues;
  metrics: Metric[];
  area_count_settings: AreaCountSetting[];
  onAreaCountSettingsChange: (p: AreaCountSetting[]) => void;
}
interface AreaCountSettingPanelState {}

export default class AreaCountSettingPanel extends React.PureComponent<
  AreaCountSettingPanelProps,
  AreaCountSettingPanelState
> {
  constructor(props: AreaCountSettingPanelProps) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    // Createの場合は初期化
    if (this.props.metrics.length == 1 && !this.props.metrics[0].json_path) {
      this.props.onMetricChange([]);
      this.props.onAreaCountSettingsChange([]);
    }
    this.InputCheck();
  }
  private InputCheck = () => {
    return this.props.values.ac_stream_id && this.props.values.graph_type;
  };
  private handleStreamIdChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (this.props.metrics.length >= 1) {
      const metrics = this.props.metrics.slice();
      const new_metrics: Metric[] = [];
      metrics.map((metric: Metric) => {
        const new_metric = { ...metric, stream_id: e.currentTarget.value };
        new_metrics.push(new_metric);
      });
      this.props.onMetricChange(new_metrics);
    }
    this.props.onSettingChange({ ...this.props.values, ac_stream_id: e.currentTarget.value });
    this.InputCheck();
  };
  private handleGraphTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (this.props.metrics.length >= 1) {
      const metrics = this.props.metrics.slice();
      const new_metrics: Metric[] = [];
      metrics.map((metric: Metric) => {
        const new_metric = { ...metric, graph_type: e.currentTarget.value as MetricGraphType };
        new_metrics.push(new_metric);
      });
      this.props.onMetricChange(new_metrics);
    }
    this.props.onSettingChange({ ...this.props.values, graph_type: e.currentTarget.value as MetricGraphType });
    this.InputCheck();
  };
  new_metrics: Metric[] = [];
  new_area_count_settings: AreaCountSetting[] = [];
  private handleChange = (value: string, index: number, key: string) => {
    const new_metrics = this.props.metrics.slice();
    const new_area_count_settings = this.props.area_count_settings.slice();
    if (key === 'name') {
      new_area_count_settings[index].name = value;
      new_metrics[index].name = value;
    }
    if (key === 'area_name') {
      const area_name = value;
      new_area_count_settings[index].area_name = value;
      const count_target = new_area_count_settings[index].count_target.join(', ');
      const json_path = '$.`areacount(' + area_name + ', ' + count_target + ')`';
      new_metrics[index].json_path = json_path;
    }
    if (key === 'stack_id') {
      new_area_count_settings[index].stack_id = value;
      new_metrics[index].stack_id = value;
    }
    this.props.onMetricChange(new_metrics);
    this.props.onAreaCountSettingsChange(new_area_count_settings);
  };
  private handleAddRow = () => {
    const new_metrics = this.props.metrics.slice();
    const new_area_count_settings = this.props.area_count_settings.slice();
    if (new_metrics.length >= 100) {
      AlertDialog.show('最大で100行までです。');
      return;
    }
    new_metrics.push({
      name: '',
      stream_id: this.props.values.ac_stream_id || '',
      json_path: '',
      statistic_method: 'Sum',
      accumulation: 'NONE',
      stack_id: '',
      graph_type: this.props.values.graph_type,
    });
    this.props.onMetricChange(new_metrics);
    new_area_count_settings.push({
      name: '',
      area_name: '',
      count_target: [],
      stack_id: '',
    });
    this.props.onAreaCountSettingsChange(new_area_count_settings);
  };
  private handleDeleteRow = () => {
    const new_metrics = this.props.metrics.slice();
    const new_area_count_settings = this.props.area_count_settings.slice();
    if (new_metrics.length <= 1 || this.new_area_count_settings.length) {
      AlertDialog.show('少なくとも1つ以上の入力が必要です');
      return;
    }
    new_metrics.pop();
    new_area_count_settings.pop();
    this.props.onMetricChange(new_metrics);
    this.props.onAreaCountSettingsChange(new_area_count_settings);
  };

  private handleCountTargetChange = (e: React.ChangeEvent<{ value: unknown }>, index: number) => {
    const selected_values = e.target.value as string[];
    const new_metrics = this.props.metrics.slice();
    const new_area_count_settings = this.props.area_count_settings.slice();

    const updated_count_target = selected_values.map((value) => {
      const found_item = count_target.find((item) => item.value === value);
      return found_item ? found_item.value : '';
    });

    new_area_count_settings[index].count_target = updated_count_target;
    this.props.onAreaCountSettingsChange(new_area_count_settings);

    const area_name = new_area_count_settings[index].area_name;
    const new_count_target = updated_count_target.join(', ');
    const json_path = '$.`areacount(' + area_name + ', ' + new_count_target + ')`';
    new_metrics[index].json_path = json_path;
    this.props.onMetricChange(new_metrics);
  };

  render() {
    return (
      <>
        <InputComponent text='データ' required>
          <SelectBox
            onChange={(e) => this.handleStreamIdChange(e)}
            value={this.props.values.ac_stream_id || ''}
            datas={this.props.metricStreams.map((stream) => {
              return { name: stream.stream_name, value: stream.stream_id };
            })}
            long
          />
        </InputComponent>
        <InputComponent text='グラフタイプ' required>
          <SelectBox
            value={this.props.values.graph_type || ''}
            datas={graph_types.map((d) => {
              return {
                value: d,
                name: d,
              };
            })}
            onChange={(e) => this.handleGraphTypeChange(e)}
            long
          />
        </InputComponent>
        {this.props.values.ac_stream_id && this.props.values.graph_type && (
          <>
            {count_target ? (
              <WholeArea>
                {/* テーブル */}
                <TableArea>
                  <Table>
                    <Thead>
                      <tr>
                        {/* テーブルヘッダー */}
                        <Th style={{ width: 215 }}>表示名</Th>
                        <Th style={{ width: 100 }}>エリア名</Th>
                        <Th style={{ width: 120 }}>カウント対象</Th>
                        <Th style={{ width: 70 }}>スタック</Th>
                      </tr>
                    </Thead>

                    {/* テーブルボディー */}
                    <Tbody>
                      {/* ボディーデータをソートした後に、一ページ分のデータに切り分ける */}
                      {this.props.area_count_settings.map((body, index1) => {
                        return (
                          <Tr key={index1}>
                            <Td
                              key={`name_${index1}`}
                              last_row={index1 + 1 === this.props.metrics.length ? true : false}
                            >
                              <InputBox
                                value={body.name}
                                onChange={(e) => this.handleChange(e.currentTarget.value, index1, 'name')}
                              />
                            </Td>
                            <Td
                              key={`area_name_${index1}`}
                              last_row={index1 + 1 === this.props.metrics.length ? true : false}
                            >
                              <SelectBox
                                value={body.area_name}
                                onChange={(e) => this.handleChange(e.currentTarget.value, index1, 'area_name')}
                                datas={area_name.map((d) => {
                                  return {
                                    value: d,
                                    name: d,
                                  };
                                })}
                                style={{ width: '100%' }}
                              />
                            </Td>
                            <Td
                              key={`count_target_${index1}`}
                              last_row={index1 + 1 === this.props.metrics.length ? true : false}
                            >
                              <Select
                                multiple
                                value={body.count_target}
                                onChange={(e) => this.handleCountTargetChange(e, index1)}
                                style={{ width: '215px' }}
                              >
                                {count_target.map((item) => (
                                  <MenuItem key={item.name} value={item.value}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Td>
                            <Td
                              key={`stack_id_${index1}`}
                              last_row={index1 + 1 === this.props.metrics.length ? true : false}
                            >
                              <SelectBox
                                value={body.stack_id || ''}
                                datas={['1', '2', '3', '4', '5', '6', '7', '8', '9'].map((d) => {
                                  return {
                                    value: d,
                                    name: d,
                                  };
                                })}
                                onChange={(e) => this.handleChange(e.currentTarget.value, index1, 'stack_id')}
                                long
                              />
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableArea>

                {/* フッター */}
                <FooterArea>
                  <RoundedButton
                    text='削除'
                    onClick={this.handleDeleteRow}
                    style={{
                      width: styles.super_small_button_width,
                      height: styles.super_small_button_height,
                      marginRight: styles.interval_narrow_margin,
                    }}
                    is_white
                  />
                  <RoundedButton
                    text='追加'
                    onClick={this.handleAddRow}
                    style={{
                      width: styles.super_small_button_width,
                      height: styles.super_small_button_height,
                    }}
                  />
                </FooterArea>
              </WholeArea>
            ) : (
              <Spinner />
            )}
          </>
        )}
      </>
    );
  }
}
