import { AxiosResponse } from 'axios';
import { CommonAPIRequestType, isCommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';
import { ListingVisibility } from 'shared/models/ListingVisibility';
import { Query } from 'shared/models/Query';
import { StatusType } from 'shared/models/StatusType';
import { StreamDataNumberType } from 'shared/models/StreamDataNumberType';
import { StreamDataType } from 'shared/models/StreamDataType';
import { StreamDeviceGraph } from 'shared/models/StreamDeviceGraph';
import { addDeviceGraphsToFormData } from 'user/utils/addFormData';

import sendAxios from 'shared/axios/sendAxios';
import { getClient } from './base';
import { StringBoolean } from 'shared/models/StringBoolean';

/*** エンティティ ***/

export interface Stream {
  tenant_id: string;
  stream_id: string;
  stream_name: string;
  status: StatusType;
  category: string;
  data_number_type: StreamDataNumberType;
  data_type: StreamDataType;
  data_store_period: number;
  total_data_count: number;
  total_data_size: number;
  metrics_jsonpath_templates?: { name: string; jsonpath: string }[];
  detail_jsonpath_group_id: string | null;
  auto_csv_type?: string | null;
  type_changed_at?: number | null;
  person_age_type: number | null;
  car_type: number | null;
  device_id: string;
  device_data_category: string;
  device_graphs?: StreamDeviceGraph[];
  input_channel_event_conditions: string[];
  input_channel_processes: string[];
  jsonpath_group_id: string[];
  output_channel_process: string | null;
  streaming_endpoint: string | null;
  is_published: boolean;
  publish_id: string | null;
  edgebox_key: string | null;
  estimated_live_delay: number | null;
  listing_visibility: ListingVisibility;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

export interface JsonpathGetResponse {
  jsonpaths: string[];
}

const sample_jsonpaths = [
  '$[*].a',
  '$[*].b',
  '$[*].c',
  '$[*].d',
  '$[*].e',
  '$[*].f',
  '$[*].g',
  '$[*].h',
  '$[*].i',
  '$[*].j',
  '$[*].k',
];

export interface StreamsWithPaging {
  items: Stream[];
  has_next: boolean;
  last_evaluated_stream_id?: string;
}

const sample_stream_1: Stream = {
  tenant_id: '00000001',
  stream_id: '00000001',
  stream_name: 'ストリーム1',
  status: 'ACTIVE',
  category: 'COUNTER',
  data_number_type: 'USER_SPECIFIC',
  data_type: 'METRIC',
  data_store_period: 0,
  total_data_count: 3,
  total_data_size: 1701000000,
  detail_jsonpath_group_id: null,
  person_age_type: null,
  car_type: null,
  device_id: '00000001',
  device_data_category: 'count_data',
  device_graphs: [
    {
      id: 'LINE0_LR_GENDER_CIRCLE',
      graph_type: 'CIRCLE',
      line_number: 0,
      direction: 'LR',
      target: 'GENDER',
    },
    {
      id: 'LINE0_RL_GENERATION_CIRCLE',
      graph_type: 'CIRCLE',
      line_number: 0,
      direction: 'RL',
      target: 'GENERATION',
    },
    {
      id: 'LINE1_LR_TOTAL_BAR',
      graph_type: 'BAR',
      line_number: 0,
      direction: 'LR',
      target: 'TOTAL',
    },
  ],
  input_channel_event_conditions: [],
  input_channel_processes: [],
  jsonpath_group_id: [],
  output_channel_process: null,
  streaming_endpoint: null,
  edgebox_key: null,
  is_published: false,
  publish_id: null,
  estimated_live_delay: null,
  listing_visibility: 'INPUT',
  created_at: '2020-01-01T00:00:00+09:00',
  updated_at: '2020-01-01T00:00:00+09:00',
  deleted_at: null,
};

const sample_stream_2: Stream = {
  tenant_id: '00000001',
  stream_id: '00000002',
  stream_name: 'ストリーム2',
  status: 'ACTIVE',
  category: 'COUNTERBOX',
  data_number_type: 'SEQUENCE',
  data_type: 'VIDEO',
  data_store_period: 0,
  total_data_count: 3,
  total_data_size: 1701000000,
  detail_jsonpath_group_id: null,
  person_age_type: null,
  car_type: null,
  device_id: '00000001',
  device_data_category: 'string',
  device_graphs: [
    {
      id: 'LINE0_LR_GENDER_CIRCLE',
      graph_type: 'CIRCLE',
      line_number: 0,
      direction: 'LR',
      target: 'GENDER',
    },
    {
      id: 'LINE0_RL_GENERATION_CIRCLE',
      graph_type: 'CIRCLE',
      line_number: 0,
      direction: 'RL',
      target: 'GENERATION',
    },
    {
      id: 'LINE1_LR_TOTAL_BAR',
      graph_type: 'BAR',
      line_number: 0,
      direction: 'LR',
      target: 'TOTAL',
    },
  ],
  input_channel_event_conditions: ['00000001', '00000002'],
  input_channel_processes: ['00000001', '00000002'],
  jsonpath_group_id: ['00000001', '00000002'],
  output_channel_process: '00000001',
  streaming_endpoint: null,
  edgebox_key: null,
  is_published: false,
  publish_id: null,
  estimated_live_delay: null,
  listing_visibility: 'INPUT',
  created_at: '2020-01-01T00:00:00+09:00',
  updated_at: '2020-01-01T00:00:00+09:00',
  deleted_at: null,
};

const sample_stream_3: Stream = {
  tenant_id: '00000001',
  stream_id: '00000003',
  stream_name: 'ストリーム3',
  status: 'ACTIVE',
  category: '',
  data_number_type: 'TIMESTAMP',
  data_type: 'METRIC',
  data_store_period: 60,
  total_data_count: 3,
  total_data_size: 1701000000,
  detail_jsonpath_group_id: null,
  person_age_type: null,
  car_type: null,
  device_id: '00000001',
  device_data_category: 'string',
  device_graphs: [
    {
      id: 'LINE0_LR_GENDER_CIRCLE',
      graph_type: 'CIRCLE',
      line_number: 0,
      direction: 'LR',
      target: 'GENDER',
    },
    {
      id: 'LINE0_RL_GENERATION_CIRCLE',
      graph_type: 'CIRCLE',
      line_number: 0,
      direction: 'RL',
      target: 'GENERATION',
    },
    {
      id: 'LINE1_LR_TOTAL_BAR',
      graph_type: 'BAR',
      line_number: 0,
      direction: 'LR',
      target: 'TOTAL',
    },
  ],
  input_channel_event_conditions: ['00000001', '00000002'],
  input_channel_processes: ['00000001', '00000002'],
  jsonpath_group_id: ['00000001', '00000002'],
  output_channel_process: '00000001',
  streaming_endpoint: null,
  edgebox_key: null,
  is_published: false,
  publish_id: null,
  estimated_live_delay: null,
  listing_visibility: 'INPUT',
  created_at: '2020-01-01T00:00:00+09:00',
  updated_at: '2020-01-01T00:00:00+09:00',
  deleted_at: null,
};

const sample_stream_4: Stream = {
  tenant_id: '00000001',
  stream_id: '00000004',
  stream_name: 'ストリーム4',
  status: 'ACTIVE',
  category: 'COUNTER',
  data_number_type: 'TIMESTAMP',
  data_type: 'VIDEO',
  data_store_period: 0,
  total_data_count: 3,
  total_data_size: 1701000000,
  detail_jsonpath_group_id: null,
  person_age_type: null,
  car_type: null,
  device_id: '00000001',
  device_data_category: 'string',
  input_channel_event_conditions: ['00000001', '00000002'],
  input_channel_processes: ['00000001', '00000002'],
  jsonpath_group_id: ['00000001', '00000002'],
  output_channel_process: '00000001',
  streaming_endpoint: null,
  edgebox_key: null,
  is_published: false,
  publish_id: null,
  estimated_live_delay: null,
  listing_visibility: 'INPUT',
  created_at: '2020-01-01T00:00:00+09:00',
  updated_at: '2020-01-01T00:00:00+09:00',
  deleted_at: null,
};

export interface MetricDataBasic {
  ts: number;
  ts_second: number;
}

/** system_dataのMetricData**/
export interface MetricSystemData extends MetricDataBasic {
  ct: string; // 数値文字列 '83.0'
  fps: number;
  it: number;
  n: string;
}

/** count_dataのMetricData**/
export interface MetricCountData extends MetricDataBasic {
  c0: Record<string, number>; // {LINE0_LR_female: 0}のようなオブジェクト
}

export interface StreamMetricDataPoint {
  datapoint_number: number;
  metric_data: (MetricSystemData | MetricCountData)[][];
}

export interface AppParamResponse {
  cropArea: Record<string, string>;
  crossLine: number[][][];
  cropRotate: number;
  width: number;
  height: number;
  detectionIgnoreRatioW: number;
  detectionIgnoreRatioH: number;
  detectionIgnoreMinRatioW: number;
  detectionIgnoreMinRatioH: number;
  confidenceThreshold: number;
}

const sample_app_param_1: AppParamResponse = {
  cropArea: { left: '0', top: '0', right: '1', bottom: '1' },
  crossLine: [
    [
      [10, 20],
      [30, 40],
    ],
  ],
  cropRotate: 0,
  width: 1280,
  height: 720,
  detectionIgnoreRatioH: 0.75,
  detectionIgnoreRatioW: 0.75,
  detectionIgnoreMinRatioH: 0,
  detectionIgnoreMinRatioW: 0,
  confidenceThreshold: 0.2,
};

/*** Caching mechanism ***/

export class CachedStreams {
  private searched = false;
  private cache: Stream[] = [];
  private params: RequestStreamsGet;
  constructor(params: RequestStreamsGet) {
    this.params = params;
  }
  async get() {
    if (!this.searched) {
      let esk: string | undefined = undefined;
      for (;;) {
        const res: AxiosResponse<StreamsWithPaging> = await streamsGetAPI({
          ...this.params,
          exclusive_start_stream_id: esk,
        });
        if (res.status === 200) {
          this.cache = [...this.cache, ...res.data.items];
          if (!res.data.has_next) {
            break;
          } else {
            esk = res.data.last_evaluated_stream_id;
          }
        } else {
          break;
        }
      }
      this.searched = true;
    }
    return this.cache;
  }
}

/*** [GET] /api/streams ***/

export interface RequestStreamsGet extends CommonAPIRequestType {
  device_id?: string; // デバイスの実機の出力データを保存しているstreamを取得する
  data_types?: string; // 複数をカンマで区切って指定できる
  data_number_types?: string; // 複数をカンマで区切って指定できる
  with_output_streams?: 'True';
  with_in_channel_streams?: 'True';
  exclusive_start_stream_id?: string;
  disabled_user_type_check?: StringBoolean;
  limit?: number;
}

export const streamsGetAPI = (params: RequestStreamsGet) => {
  const {
    device_id,
    data_types,
    data_number_types,
    with_output_streams,
    with_in_channel_streams,
    exclusive_start_stream_id,
    limit,
    disabled_load,
    disabled_error_message,
    ended_load,
    disabled_user_type_check,
  } = toAPIRequestParams(params);

  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/streams`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    device_id,
    data_types,
    data_number_types,
    with_output_streams,
    with_in_channel_streams,
    exclusive_start_stream_id,
    disabled_user_type_check,
    limit,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<StreamsWithPaging>(axios, path, query, form, method, {
    // items: Array(110).fill(null).map((_, i) => {
    //   let d = {...sample_stream_1}
    //   d.stream_id = String(i)
    //   d.stream_name = `ストリーム${i}`
    //   return d
    // }),
    items: [sample_stream_1, sample_stream_2, sample_stream_3, sample_stream_4],
    has_next: false,
  });
};

/*** [POST] /api/streams ***/

export interface RequestStreamsPost extends CommonAPIRequestType {
  stream_name: string;
  data_number_type?: string; // "TIMESTAMP" | "SEQUENCE" | "USER_SPECIFIC"
  data_type?: string; // "IMAGE" | "VIDEO" | "AUDIO" | "METRIC" | "PDF"
  create_rt_stream?: 'RTMP' | 'RTSP' | 'Safie';
  category?: 'COUNTER' | 'COUNTERBOX' | '';
  fps?: string;
  kbps?: string;
  width?: string;
  height?: string;
  data_store_period?: string;
  safie_info?: string;
  detail_jsonpath_group_id?: string;
}

export const streamsPostAPI = (params: RequestStreamsPost) => {
  const { disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/streams`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (isCommonAPIRequestType(key)) continue;
    form.append(key, value);
  }

  // 送信
  return sendAxios<Stream>(axios, path, query, form, method, sample_stream_1);
};

/*** [GET] /api/streams/{id} ***/

export interface RequestStreamsIdGet extends CommonAPIRequestType {
  stream_id: string;
  disabled_user_type_check?: StringBoolean;
}

export const streamsIdGetAPI = (params: RequestStreamsIdGet) => {
  const { stream_id, disabled_load, disabled_error_message, ended_load, disabled_user_type_check } =
    toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/streams/${stream_id}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = { disabled_user_type_check };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<Stream>(axios, path, query, form, method, sample_stream_3);
};

/*** [PUT] /api/streams/{id} ***/

export interface RequestStreamsIdPut extends CommonAPIRequestType {
  stream_id: string;
  stream_name?: string;
  category?: string;
  data_store_period?: string;
  edgebox_key?: string;
  is_published?: 'True' | 'False';
  listing_visibility?: 'OUTPUT' | 'IN_CHANNEL';
  device_graphs?: StreamDeviceGraph[];
  detail_jsonpath_group_id?: string;
  auto_csv_type?: string;
  type_changed_at?: number;
  person_age_type?: number;
  car_type?: number;
}

export const streamsIdPutAPI = (params: RequestStreamsIdPut) => {
  const { stream_id, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/streams/${stream_id}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  let form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'device_graphs' || isCommonAPIRequestType(key)) continue;
    form.append(key, value);
  }
  // device_graphsは別で、変換処理
  form = addDeviceGraphsToFormData(form, params.device_graphs);

  // 送信
  return sendAxios<Stream>(axios, path, query, form, method, sample_stream_1);
};

/*** [DELETE] /api/streams/{id} ***/

export interface RequestStreamsIdDelete extends CommonAPIRequestType {
  stream_id: string;
}

export const streamsIdDeleteAPI = (params: RequestStreamsIdDelete) => {
  const { stream_id, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'multipleTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/streams/${stream_id}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<Stream>(axios, path, query, form, method, sample_stream_1);
};

/*** [GET] /api/streams/{id}/jsonpath ***/

export interface RequestStreamsIdJsonpathGet {
  stream_id: string;
}

export const streamsIdJsonpathGetAPI = (params: RequestStreamsIdJsonpathGet) => {
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `/api/streams/${params.stream_id}/jsonpath`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<JsonpathGetResponse>(axios, path, query, form, method, { jsonpaths: sample_jsonpaths });
};

/*** [GET] /api/streams/{id}/video.m3u8 ***/

export interface RequestStreamsStreamIdVideoM3U8Get extends CommonAPIRequestType {
  stream_id: string;
  start?: number;
  speed?: number;
}

export const streamsStreamIdVideoM3U8GetAPI = (params: RequestStreamsStreamIdVideoM3U8Get) => {
  const { stream_id, start, speed, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ responseType: 'arraybuffer', disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/streams/${stream_id}/video.m3u8`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    start,
    now: Math.floor(new Date().getTime() / 1000),
    speed,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<ArrayBuffer>(axios, path, query, form, method, new ArrayBuffer(6));
};
/** URLのみ生成する */
export const streamsStreamIdVideoM3U8GetAPIUrl = (params: RequestStreamsStreamIdVideoM3U8Get) => {
  const { stream_id, speed, start } = params;
  const now = Math.floor(new Date().getTime() / 1000);
  let path = `/api/streams/${stream_id}/video.m3u8?`;
  if (start) path += `start=${start}&`;
  path += `now=${now}&`;
  if (speed) path += `speed=${speed}&`;
  return path;
};

/*** [GET] /api/streams/processinfo/{root_channel_process_pk} ***/

export interface RequestStreamsProcessinfoAppparamGet {
  root_channel_process_pk: string;
}

export const streamsProcessinfoAppparamGetAPI = (params: RequestStreamsProcessinfoAppparamGet) => {
  // クライアントを定義
  const axios = getClient({});

  const path = `/api/streams/processinfo/${params.root_channel_process_pk}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<{ items: AppParamResponse; has_next: boolean }>(axios, path, query, form, method, {
    has_next: false,
    items: sample_app_param_1,
  });
};
