import SelectBox from 'shared/components/atoms/SelectBox';
import { PageWholeArea } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import styled from 'styled-components';
import { IVIDEO_INPUT } from 'user/components/Config';

interface VideoInputTabProps {
  _id: number;
  VIDEO_INPUT: IVIDEO_INPUT;
  onChange: (value: IVIDEO_INPUT) => void;
}
export function VideoInputTab(params: VideoInputTabProps) {
  const rotate_list = [
    { name: '回転なし', value: 0 },
    { name: '90度', value: 1 },
    { name: '180度', value: 2 },
    { name: '270度', value: 3 },
  ];
  const handleOnChange = (value: IVIDEO_INPUT) => {
    params.onChange({ ...params.VIDEO_INPUT, ...value });
  };
  return (
    <PageWholeArea data-testid='AISetting_CameraID'>
      <CustomInputComponent text='カメラID' required>
        <div>{params.VIDEO_INPUT.CAMERA_ID}</div>
      </CustomInputComponent>
      <CustomInputComponent text='回転' required>
        <SelectBox
          onChange={(e) => handleOnChange({ ROTATE: Number(e.currentTarget.value) as 0 | 1 | 2 | 3 })}
          value={params.VIDEO_INPUT.ROTATE !== undefined ? String(params.VIDEO_INPUT.ROTATE) : '0'}
          datas={rotate_list.map((d) => ({
            name: d.name,
            value: String(d.value),
          }))}
          long
        />
      </CustomInputComponent>
    </PageWholeArea>
  );
}

// -- styled components --
const CustomInputComponent = styled(InputComponent)`
  width: 300px;
`;
