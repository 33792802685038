import InputBox from 'shared/components/atoms/InputBox';
import InputNumberBox from 'shared/components/atoms/InputNumberBox';
import { PageWholeArea, Title } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import ToggleButton from 'shared/components/molecules/ToggleButton';
import { table_cell_toggle_button_style } from 'shared/styles/styles';
import styled from 'styled-components';
import { ICROSSLINE, ILINE_COUNTER } from 'user/components/Config';
import { LineEditModalButton } from 'user/components/LineEditModal';
import { TargetPointEditModalButton } from 'user/components/TargetPointEditModal';

interface LineCounterTabProps {
  LINE_COUNTER: ILINE_COUNTER;
  image_url?: string;
  onChange: (value?: ILINE_COUNTER) => void;
}
export function LineCounterTab(params: LineCounterTabProps) {
  const invokeOnChange = (value: ILINE_COUNTER) => {
    if ((value.CROSSLINES || []).length === 0) {
      params.onChange(undefined);
    } else {
      params.onChange(value);
    }
  };
  const handleOnChange = (value: ILINE_COUNTER) => {
    invokeOnChange({ ...params.LINE_COUNTER, ...value });
  };
  const handleOnCrosslineChange = (index: number, value: ICROSSLINE) => {
    const crosslines = params.LINE_COUNTER.CROSSLINES || [];
    if (crosslines.length > index) {
      crosslines[index] = value;
      invokeOnChange({ ...params.LINE_COUNTER, CROSSLINES: crosslines });
    }
  };

  return (
    <PageWholeArea data-testid='AISetting_Crosslines'>
      <Title text='検知ライン設定' />
      <div style={{ margin: '20px 0px' }}>
        <LineEditModalButton
          value={params.LINE_COUNTER.CROSSLINES || []}
          onChange={(value) => handleOnChange({ CROSSLINES: value })}
          image_url={params.image_url}
        />
      </div>
      <Title text='ライン識別' />
      {params.LINE_COUNTER.CROSSLINES &&
        params.LINE_COUNTER.CROSSLINES.map((cl, index) => {
          return (
            <>
              <InputComponent text={`検知ライン(${index}) `}>
                <CustomInputComponent text={`ライン名： `}>
                  {/* JSON.stringify(cl.name)だと"LINE*"と表示されるため成型 */}
                  <div>{JSON.stringify(cl.name).slice(1, -1)}</div>
                </CustomInputComponent>
                <CustomInputComponent text={`ライン座標：`}>
                  <div>{JSON.stringify(cl.co)}</div>
                </CustomInputComponent>
                <CustomInputComponent text={`入退場識別：`}>
                  <InputBox
                    placeholder={cl.od_direction ?? ''}
                    value={cl.od_direction ?? ''}
                    onChange={(e) => handleOnCrosslineChange(index, { ...cl, od_direction: e.currentTarget.value })}
                  />
                </CustomInputComponent>
              </InputComponent>
              <InputComponent text={'対象点：'}>
                <div style={{ margin: '20px 0px' }}>
                  <TargetPointEditModalButton
                    value={cl.target_point ?? [0.5, 0.5]}
                    onChange={(value) => handleOnCrosslineChange(index, { ...cl, target_point: value })}
                  />
                </div>
                <div>{JSON.stringify(cl.target_point ?? [0.5, 0.5])}</div>
              </InputComponent>
              サムネイル出力：
              <div style={{ margin: '20px 0px' }}>
                <ToggleButton
                  stop_propagation={true}
                  onClick={() => handleOnCrosslineChange(index, { ...cl, CUT_THUMBNAIL: !cl.CUT_THUMBNAIL ?? false })}
                  checked={cl.CUT_THUMBNAIL ?? false}
                  style={table_cell_toggle_button_style}
                />
              </div>
            </>
          );
        })}
      <CustomInputComponent text='デバイス上の保存期間（分）'>
        <div style={{ margin: '20px 0px' }}>
          <InputNumberBox
            value={params.LINE_COUNTER.PASSER_MMIN ?? 0}
            onChange={(value) => handleOnChange({ PASSER_MMIN: value })}
            min={60}
            max={1080}
          />
        </div>
        <div>※60～1080の数値で入力してください</div>
      </CustomInputComponent>
    </PageWholeArea>
  );
}

// -- styled components --
const CustomInputComponent = styled(InputComponent)`
  width: 300px;
`;
