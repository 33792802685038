import React from 'react';

import { DeleteIcon } from 'shared/components/atoms/Icons';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import { FlexCenterDiv, TopButtonArea } from 'shared/components/molecules/ContentsArea';
import { PolarGraphData } from 'shared/components/molecules/PfGraph';
import { WidgetScaler } from 'shared/components/molecules/WidgetScaler';
import styles from 'shared/styles/styles';
import { WidgetScaleType } from 'shared/models/WidgetScaleType';
import FeatureDatetime from 'shared/models/FeatureDatetime';
import { getDateLabel } from 'shared/utils/get';
import getPinnedStatus from 'user/utils/getPinnedStatus';
import { getStreamGraphTitle } from 'shared/models/StreamDeviceGraph';
import { Device } from 'user/api/device';
import { Stream } from 'user/api/streams';
import { DatapointItem } from 'user/api/streamsGraphs';
import DatetimeRangeDialog from 'user/dialogs/DatetimeRangeDialog';
import MetricResultGraphCard from './GraphPart';
import { StreamDeviceGraph } from 'shared/models/StreamDeviceGraph';
import { streamDeviceGraphTypeToText } from 'shared/models/StreamDeviceGraphType';

export interface MetricResultCardProps {
  stream_graph: StreamDeviceGraph;
  stream: Stream;
  device: Device;
  hide_scale?: boolean;
  onStreamGraphDelete?: () => void;
  onPinStreamGraph?: (stream_device_graph_id?: string, stream_id?: string) => void;
  onIsExpandingDialogOpen?: () => void;
  start: FeatureDatetime;
  end: FeatureDatetime;
  scale: WidgetScaleType;
  setStart: (start: FeatureDatetime) => void;
  setEnd: (end: FeatureDatetime) => void;
  setScale: (scale: WidgetScaleType) => void;
  loadGraphData: (p: { scale?: WidgetScaleType; start?: FeatureDatetime; end?: FeatureDatetime }) => Promise<void>;
  graph_data?: DatapointItem[] | PolarGraphData[];
}

// -- main component --

/** 1つのグラフの情報の中身**/
const CameraAnalysisResultPart: React.FC<MetricResultCardProps> = (params) => {
  // -- local states --
  const [select_date_dialog, setSelectDateDialog] = React.useState<boolean>(false);
  // -- functions --

  // // scaleを変更する関数
  const onScaleChanged = async (rangeType: WidgetScaleType) => {
    params.setScale(rangeType);
    await params.loadGraphData({
      scale: rangeType,
    });
  };

  // 日付を選択するダイアログを開く関数
  const onSelectDateDialogOpen = () => {
    setSelectDateDialog(true);
  };

  // 日付を選択するダイアログを閉じる際の関数
  const onSelectDateDialogClose = async (isCanceled: boolean, pstart?: FeatureDatetime, pend?: FeatureDatetime) => {
    setSelectDateDialog(false);
    if (isCanceled || !pstart || !pend) return;
    params.setStart(pstart);
    params.setEnd(pend);
    await params.loadGraphData({
      start: pstart,
      end: pend,
    });
  };

  // ピンを自身のstream_graphのidに指定
  const onPinStreamGraph = () => {
    if (params.onPinStreamGraph) {
      params.onPinStreamGraph(params.stream_graph.id, params.stream.stream_id);
    }
  };
  // ピンを空文字列に設定し、解除する
  const onRemovePinStreamGraph = () => {
    if (params.onPinStreamGraph) {
      params.onPinStreamGraph('', '');
    }
  };

  // ピン留めされているかのstatusを取得する関数
  const pinned_status = () => {
    return getPinnedStatus({
      device: params.device,
      stream_id: params.stream.stream_id,
      stream_graph: params.stream_graph,
    });
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <FlexCenterDiv align_center style={{ width: '100%', height: 20 }}>
        <FlexCenterDiv
          align_center
          style={{ width: '100%', height: '100%', marginLeft: styles.interval_x_narrow_margin }}
        >
          {getStreamGraphTitle(params.stream_graph)}
        </FlexCenterDiv>
        {params.onPinStreamGraph !== undefined && (
          <RoundedButton
            text={pinned_status() ? 'ピン解除' : 'ピンづけ'}
            onClick={pinned_status() ? onRemovePinStreamGraph : onPinStreamGraph}
            style={{ width: 120, height: '100%', marginRight: styles.interval_x_narrow_margin, fontSize: '0.8em' }}
          />
        )}
        {params.onIsExpandingDialogOpen !== undefined && (
          <RoundedButton
            text='拡大'
            onClick={params.onIsExpandingDialogOpen}
            style={{ width: 70, height: '100%', marginRight: styles.interval_x_narrow_margin, fontSize: '0.8em' }}
          />
        )}
        <RoundedButton
          text='リロード'
          onClick={() => params.loadGraphData({})}
          style={{ width: 120, height: '100%', marginRight: styles.interval_x_narrow_margin, fontSize: '0.8em' }}
        />
        {params.onStreamGraphDelete !== undefined && <DeleteIcon onClick={params.onStreamGraphDelete} />}
      </FlexCenterDiv>
      <TopButtonArea>
        <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={onSelectDateDialogOpen}>
          {getDateLabel(params.start, params.end)}
        </span>
        {params.hide_scale !== true && <WidgetScaler value={params.scale} onChange={onScaleChanged} />}
      </TopButtonArea>
      <div style={{ width: '100%', height: 'calc(100% - 20px - 36px - 2 * 2px)' }}>
        <MetricResultGraphCard
          type={streamDeviceGraphTypeToText(params.stream_graph.graph_type)}
          graph_data={params.graph_data}
        />
      </div>
      {select_date_dialog && (
        <DatetimeRangeDialog
          start={params.start}
          end={params.end}
          onClose={onSelectDateDialogClose}
          is_open={select_date_dialog}
        />
      )}
    </div>
  );
};

// -- finally export part --

export default CameraAnalysisResultPart;
