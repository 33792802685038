import { AxiosResponse } from 'axios';
import { ISO8601 } from 'shared/models/ISO8601';
import { Query } from 'shared/models/Query';
import sendAxios from '../../shared/axios/sendAxios';
import { getClient } from './base';
import { StreamPackage } from './streamsPackage';

/*** エンティティ ***/
/*** お気に入り登録された全ストリームパッケージを取得する***/

const sample_stream_1: StreamPackage = {
  tenant_id: 'string',
  stream_id: '00000001',
  stream_package_number: 111,
  line_count: undefined,
  stream_package_name: 'ストリームパッケージ1',
  stream_data_number_from: 1,
  stream_data_number_to: 5,
  package_type: 'CSV',
  export_filename: 'string',
  export_csv_encode: 'UTF-8',
  export_csv_stream_data_number: 'None',
  jsonpath_group_id: '00000001',
  export_csv_statistics: 'NONE',
  export_csv_time_unit: 'MINUTE',
  csv_rows: [
    {
      header_name: 'string',
      json_path: 'string',
      cell_format: 'STRING',
      cell_format_args: 'string',
      fill: true,
    },
  ],
  fps: 'string',
  width: 'string',
  height: 'string',
  package_size: 99999,
  packaging_progress: 70,
  packaging_done: true,
  favorite: true,
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
};

const sample_stream_2: StreamPackage = {
  tenant_id: 'string',
  stream_id: '00000002',
  stream_package_number: 222,
  line_count: undefined,
  stream_package_name: 'ストリームパッケージ2',
  stream_data_number_from: 19024604,
  stream_data_number_to: 19024670,
  package_type: 'MP4',
  export_filename: 'string',
  export_csv_encode: 'string',
  export_csv_stream_data_number: 'None',
  jsonpath_group_id: '00000002',
  export_csv_statistics: 'NONE',
  export_csv_time_unit: 'HOUR',
  csv_rows: [
    {
      header_name: 'string',
      json_path: 'string',
      cell_format: 'STRING',
      cell_format_args: 'string',
      fill: false,
    },
  ],
  fps: 'string',
  width: 'string',
  height: 'string',
  package_size: 7088,
  packaging_progress: 100,
  packaging_done: true,
  favorite: false,
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
};

const sample_stream_3: StreamPackage = {
  tenant_id: 'string',
  stream_id: '00000003',
  stream_package_number: 333,
  line_count: undefined,
  stream_package_name: 'ストリームパッケージ3',
  stream_data_number_from: 19024604,
  stream_data_number_to: 19024670,
  package_type: 'CSV',
  export_filename: 'string',
  export_csv_encode: 'string',
  export_csv_stream_data_number: 'None',
  jsonpath_group_id: '00000003',
  export_csv_statistics: 'NONE',
  export_csv_time_unit: 'DAY',
  csv_rows: [
    {
      header_name: 'string',
      json_path: 'string',
      cell_format: 'STRING',
      cell_format_args: 'string',
      fill: false,
    },
  ],
  fps: 'string',
  width: 'string',
  height: 'string',
  package_size: 7088,
  packaging_progress: 100,
  packaging_done: true,
  favorite: false,
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
};

export interface StreamPackagesFavoriteWithPaging {
  items: StreamPackage[];
  has_next: boolean;
}

/*** [GET] /api/streamPackagesFavorite ***/

export interface RequestStreamPackagesFavoriteGet {
  limit?: number;
  status?: string;
  exclusive_start_key?: string;
}

export const streamPackagesFavoriteGetAPI = (params: RequestStreamPackagesFavoriteGet) => {
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `/api/stream-packages-favorite`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};
  for (const [key, value] of Object.entries(params)) {
    query[key] = value;
  }

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<StreamPackagesFavoriteWithPaging>(axios, path, query, form, method, {
    items: [sample_stream_1, sample_stream_2, sample_stream_3],
    has_next: false,
  });
};

export class CachedStreamPackagesFavorite {
  private searched = false;
  private cache: StreamPackage[] = [];
  private params: RequestStreamPackagesFavoriteGet;
  constructor(params: RequestStreamPackagesFavoriteGet) {
    this.params = params;
  }
  async get() {
    if (!this.searched) {
      let esk: string | undefined = undefined;
      for (;;) {
        const res: AxiosResponse<StreamPackagesFavoriteWithPaging> = await streamPackagesFavoriteGetAPI({
          ...this.params,
          exclusive_start_key: esk,
        });
        if (res.status === 200) {
          this.cache = [...this.cache, ...res.data.items];
          if (!res.data.has_next || res.data.items.length === 0) {
            break;
          } else {
            esk = String(res.data.items[res.data.items.length - 1].stream_package_number);
          }
        } else {
          break;
        }
      }
      this.searched = true;
    }
    return this.cache;
  }
}
