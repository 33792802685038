import CheckBoxWithText from "./CheckBoxWithText";

type DetectionClassesFormProps = {
  DET_CLASSES: number[];
  onChange: (DET_CLASSES: number[]) => void;
};

enum DetectionClasses {
  Person = 0,
  Bicycle = 1,
  Car = 2,
  Motorcycle = 3,
  MidCar = 4,
  Bus = 5,
  Train = 6,
  Truck = 7,
  SmallTruck = 8,
  Taxi = 9,
  FireVehicle = 10,
  PoliceVehicle = 11,
  AmbulanceVehicle = 12,
  SpecialVehicle = 13,
  SmallMobility = 14,
  Skateboard = 15,
  Wheelchair = 16,
  Stroller = 17,
}

/** AI設定用の検知クラス選択フォームです。 */
export function DetectionClassesForm(props: DetectionClassesFormProps) {
  const isChecked = (ids: number[]) => {
    const selectedIds = props.DET_CLASSES.filter((id) => ids.includes(id));
    return selectedIds.length === ids.length ? true : selectedIds.length === 0 ? false : null;
  };

  const handleChange = (ids: number[], checked: boolean) => {
    const selectedSet = new Set(props.DET_CLASSES);
    for (const id of ids) {
      checked ? selectedSet.add(id) : selectedSet.delete(id);
    }
    props.onChange(Array.from(selectedSet));
  };

  const handleSingleChange = (id: number, checked: boolean) => {
    handleChange([id], checked);
  };

  return (
    <>
      <div style={{ margin: '10px 0px' }}>
        <CheckBoxWithText
          text='人'
          checked={isChecked([DetectionClasses.Person]) || false}
          onClick={() => handleSingleChange(DetectionClasses.Person, !isChecked([DetectionClasses.Person]))}
        />
      </div>
      <div style={{ margin: '10px 0px' }}>
        <CheckBoxWithText
          text='車両'
          checked={
            isChecked([
              DetectionClasses.Bicycle,
              DetectionClasses.Car,
              DetectionClasses.Motorcycle,
              DetectionClasses.MidCar,
              DetectionClasses.Bus,
              DetectionClasses.Truck,
              DetectionClasses.SmallTruck,
              DetectionClasses.Taxi,
              DetectionClasses.FireVehicle,
              DetectionClasses.PoliceVehicle,
              DetectionClasses.AmbulanceVehicle,
              DetectionClasses.SpecialVehicle,
            ]) || false
          }
          onClick={() =>
            handleChange(
              [
                DetectionClasses.Bicycle,
                DetectionClasses.Car,
                DetectionClasses.Motorcycle,
                DetectionClasses.MidCar,
                DetectionClasses.Bus,
                DetectionClasses.Truck,
                DetectionClasses.SmallTruck,
                DetectionClasses.Taxi,
                DetectionClasses.FireVehicle,
                DetectionClasses.PoliceVehicle,
                DetectionClasses.AmbulanceVehicle,
                DetectionClasses.SpecialVehicle,
              ],
              !isChecked([
                DetectionClasses.Bicycle,
                DetectionClasses.Car,
                DetectionClasses.Motorcycle,
                DetectionClasses.MidCar,
                DetectionClasses.Bus,
                DetectionClasses.Truck,
                DetectionClasses.SmallTruck,
                DetectionClasses.Taxi,
                DetectionClasses.FireVehicle,
                DetectionClasses.PoliceVehicle,
                DetectionClasses.AmbulanceVehicle,
                DetectionClasses.SpecialVehicle,
              ]),
            )
          }
        />
      </div>
      <div style={{ border: 'solid 1px gray', marginLeft: '1rem' }}>
        <CheckBoxWithText
          text='自転車'
          checked={isChecked([DetectionClasses.Bicycle]) || false}
          onClick={() => handleSingleChange(DetectionClasses.Bicycle, !isChecked([DetectionClasses.Bicycle]))}
          style={{ margin: '10px' }}
        />
        <CheckBoxWithText
          text='乗用車'
          checked={isChecked([DetectionClasses.Car]) || false}
          onClick={() => handleSingleChange(DetectionClasses.Car, !isChecked([DetectionClasses.Car]))}
          style={{ margin: '10px' }}
        />
        <CheckBoxWithText
          text='自動二輪'
          checked={isChecked([DetectionClasses.Motorcycle]) || false}
          onClick={() => handleSingleChange(DetectionClasses.Motorcycle, !isChecked([DetectionClasses.Motorcycle]))}
          style={{ margin: '10px' }}
        />
        <CheckBoxWithText
          text='中型乗用車'
          checked={isChecked([DetectionClasses.MidCar]) || false}
          onClick={() => handleSingleChange(DetectionClasses.MidCar, !isChecked([DetectionClasses.MidCar]))}
          style={{ margin: '10px' }}
        />
        <CheckBoxWithText
          text='バス'
          checked={isChecked([DetectionClasses.Bus]) || false}
          onClick={() => handleSingleChange(DetectionClasses.Bus, !isChecked([DetectionClasses.Bus]))}
          style={{ margin: '10px' }}
        />
        <CheckBoxWithText
          text='トラック'
          checked={isChecked([DetectionClasses.Truck]) || false}
          onClick={() => handleSingleChange(DetectionClasses.Truck, !isChecked([DetectionClasses.Truck]))}
          style={{ margin: '10px' }}
        />
        <CheckBoxWithText
          text='軽トラック'
          checked={isChecked([DetectionClasses.SmallTruck]) || false}
          onClick={() => handleSingleChange(DetectionClasses.SmallTruck, !isChecked([DetectionClasses.SmallTruck]))}
          style={{ margin: '10px' }}
        />
        <CheckBoxWithText
          text='タクシー'
          checked={isChecked([DetectionClasses.Taxi]) || false}
          onClick={() => handleSingleChange(DetectionClasses.Taxi, !isChecked([DetectionClasses.Taxi]))}
          style={{ margin: '10px' }}
        />
        <CheckBoxWithText
          text='消防車両'
          checked={isChecked([DetectionClasses.FireVehicle]) || false}
          onClick={() => handleSingleChange(DetectionClasses.FireVehicle, !isChecked([DetectionClasses.FireVehicle]))}
          style={{ margin: '10px' }}
        />
        <CheckBoxWithText
          text='警備車両'
          checked={isChecked([DetectionClasses.PoliceVehicle]) || false}
          onClick={() =>
            handleSingleChange(DetectionClasses.PoliceVehicle, !isChecked([DetectionClasses.PoliceVehicle]))
          }
          style={{ margin: '10px' }}
        />
        <CheckBoxWithText
          text='救急車両'
          checked={isChecked([DetectionClasses.AmbulanceVehicle]) || false}
          onClick={() =>
            handleSingleChange(DetectionClasses.AmbulanceVehicle, !isChecked([DetectionClasses.AmbulanceVehicle]))
          }
          style={{ margin: '10px' }}
        />
        <CheckBoxWithText
          text='特殊自動車'
          checked={isChecked([DetectionClasses.SpecialVehicle]) || false}
          onClick={() =>
            handleSingleChange(DetectionClasses.SpecialVehicle, !isChecked([DetectionClasses.SpecialVehicle]))
          }
          style={{ margin: '10px' }}
        />
      </div>
      <div style={{ margin: '10px 0px' }}>
        <CheckBoxWithText
          text='その他'
          checked={
            isChecked([
              DetectionClasses.Train,
              DetectionClasses.SmallMobility,
              DetectionClasses.Skateboard,
              DetectionClasses.Wheelchair,
              DetectionClasses.Stroller,
            ]) || false
          }
          onClick={() =>
            handleChange(
              [
                DetectionClasses.Train,
                DetectionClasses.SmallMobility,
                DetectionClasses.Skateboard,
                DetectionClasses.Wheelchair,
                DetectionClasses.Stroller,
              ],
              !isChecked([
                DetectionClasses.Train,
                DetectionClasses.SmallMobility,
                DetectionClasses.Skateboard,
                DetectionClasses.Wheelchair,
                DetectionClasses.Stroller,
              ]),
            )
          }
        />
      </div>
      <div style={{ border: 'solid 1px gray', marginLeft: '1rem' }}>
        <CheckBoxWithText
          text='電車'
          checked={isChecked([DetectionClasses.Train]) || false}
          onClick={() => handleSingleChange(DetectionClasses.Train, !isChecked([DetectionClasses.Train]))}
          style={{ margin: '10px' }}
        />
        <CheckBoxWithText
          text='小型モビリティ'
          checked={isChecked([DetectionClasses.SmallMobility]) || false}
          onClick={() =>
            handleSingleChange(DetectionClasses.SmallMobility, !isChecked([DetectionClasses.SmallMobility]))
          }
          style={{ margin: '10px' }}
        />
        <CheckBoxWithText
          text='スケートボード'
          checked={isChecked([DetectionClasses.Skateboard]) || false}
          onClick={() => handleSingleChange(DetectionClasses.Skateboard, !isChecked([DetectionClasses.Skateboard]))}
          style={{ margin: '10px' }}
        />
        <CheckBoxWithText
          text='車椅子'
          checked={isChecked([DetectionClasses.Wheelchair]) || false}
          onClick={() => handleSingleChange(DetectionClasses.Wheelchair, !isChecked([DetectionClasses.Wheelchair]))}
          style={{ margin: '10px' }}
        />
        <CheckBoxWithText
          text='ベビーカー'
          checked={isChecked([DetectionClasses.Stroller]) || false}
          onClick={() => handleSingleChange(DetectionClasses.Stroller, !isChecked([DetectionClasses.Stroller]))}
          style={{ margin: '10px' }}
        />
      </div>
    </>
  );
}
