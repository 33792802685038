// -- basic library --
import React from 'react';
import history from 'shared/browserHistory';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { BottomArea, Title, TopArea, PageWholeArea } from 'shared/components/molecules/ContentsArea';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableHeaderType, TableBodyMultipleValueType } from 'shared/components/molecules/Table/type';
import styles, { table_cell_button_style } from 'shared/styles/styles';
import { isNotSelected } from 'shared/utils/is';
import { CachedChannels, Channel } from 'user/api/channels';
import { streamsGetAPI } from 'user/api/streams';

// -- type declaration --

// メトリクステーブルのtype
type TableMetricType = {
  id: string;
  metric_name: string;
  channel_names: TableBodyMultipleValueType<string[]>;
  detail_element: JSX.Element;
};

// テーブルの列の情報まとめたデータ
const headers: TableHeaderType[] = [
  {
    id: 'metric_name',
    label: 'メトリクス名',
    width: '280px',
  },
  {
    id: 'channel_names',
    label: 'チャンネル名',
    width: 'auto',
  },
  {
    id: 'detail_element',
    label: '',
    useTableButtonStyles: true,
  },
];

interface MetricsState {
  channels: Channel[]; // チャンネルリスト
  table_bodies: TableMetricType[] | undefined;
  selected_bodies: TableMetricType[];
}
/**
 * 現在利用されていないページです
 */
export default class Metrics extends React.PureComponent<object, MetricsState> {
  constructor(props: object) {
    super(props);
    this.state = {
      channels: [],
      table_bodies: undefined,
      selected_bodies: [],
    };
  }
  componentDidMount() {
    new CachedChannels({ channel_type: 'STANDARD' }).get().then((res) => {
      this.setState({ channels: res });
      this.getStreams();
    });
  }
  getChannelOf = (channelId: string) => {
    for (const c of this.state.channels) {
      if (c.channel_id === channelId) {
        return c;
      }
    }
    return null;
  };
  getStreams = () => {
    streamsGetAPI({ data_types: 'METRIC' }).then((res) => {
      if (res.status === 200) {
        const return_table_datas: TableMetricType[] = [];
        for (const o of res.data.items) {
          // 入力チャンネルIDのセット(重複を除く)
          const channel_id_set = new Set<string>();
          for (const cp of o.input_channel_processes) {
            channel_id_set.add(cp.split(':')[0]);
          }
          // チャンネル名の突合
          const channel_names: string[] = [];
          for (const channel_id of Array.from(channel_id_set)) {
            const channel = this.getChannelOf(channel_id);
            if (channel !== null) {
              channel_names.push(channel.channel_name);
            } else {
              channel_names.push(channel_id);
            }
          }
          const return_top_table_data: TableMetricType = {
            id: o.stream_id,
            metric_name: o.stream_name,
            channel_names: {
              value: channel_names,
              available_value: channel_names.join(),
              displayable_value: (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: '100%',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                  title={channel_names.join()}
                >
                  {channel_names.map((v, index) => (
                    <div key={index} style={{ marginRight: styles.interval_narrow_margin }}>
                      {v}
                    </div>
                  ))}
                </div>
              ),
            },
            detail_element: (
              <RoundedButton
                text='グラフ表示'
                onClick={() => this.handleGraphDisplayClick(o.stream_id)}
                style={table_cell_button_style}
                stop_propagation
              />
            ),
          };
          return_table_datas.push(return_top_table_data);
        }
        this.setState({ table_bodies: return_table_datas });
      }
    });
  };
  handleCheckClick = (datas: TableMetricType[]) => {
    this.setState({ selected_bodies: datas });
  };
  handleGraphDisplayClick = (metric_id: string) => {
    history.push(`/metrics/${metric_id}/graph`);
  };
  handleDeleteClick = () => {
    AlertDialog.show('この機能は未実装です');
  };
  render() {
    return (
      <PageWholeArea data-testid='Metrics'>
        <TopArea>
          <Title text='メトリクス' />
        </TopArea>
        <BottomArea>
          {this.state.table_bodies === undefined ? (
            <Spinner />
          ) : (
            <BaseTable
              bodies={this.state.table_bodies}
              headers={headers}
              table_name='metrics'
              selected_bodies={this.state.selected_bodies}
              handleCheckClick={this.handleCheckClick}
              footer_option={{
                text: '＞チェックしたメトリクスを削除する',
                handleClick: this.handleDeleteClick,
                disabled: isNotSelected(this.state.selected_bodies.length),
              }}
            />
          )}
        </BottomArea>
      </PageWholeArea>
    );
  }
}
