import { Rect } from 'shared/models/Rect';

/**
 * app_parameter.STAYAREAの中身の型
 * バックエンドから返却されるデータ
 * バックエンドのデータ構造の都合上、lowerCamelCase記法になっている。
 * (フロントエンドのコーディング規約から外れている)
 * */
export interface StayAreaType {
  crossLine: number[][][]; // エリア, 点, xy
  cropArea?: Rect;
  width?: number;
  height?: number;
  cropRotate?: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isStayArea = (data: any): data is StayAreaType => {
  return Array.isArray(data.crossLine);
};

export const empty_stay_area: StayAreaType = {
  crossLine: [],
  cropArea: undefined,
  width: undefined,
  height: undefined,
  cropRotate: undefined,
};
