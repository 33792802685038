import React, { useEffect, useMemo, useState } from 'react';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import SelectBox from 'shared/components/atoms/SelectBox';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import CheckBoxWithText from 'shared/components/molecules/CheckBoxWithText';
import InputComponent from 'shared/components/molecules/InputComponent';
import validateCsvRows from 'user/utils/validateCsvRows';
import { JsonpathGroup, jsonpathGroupGetAPI } from 'user/api/jsonpathGroups';
import { CsvRow } from 'shared/models/CsvRow';
import CSVTable from 'user/pages/Streams/DetailPage/StreamDataDownloadPackagesPanel/CreateStreamDataDownloadPackagesDialog/CSVTable';
import { jsonpathGroupCategoryToText } from 'shared/models/JsonpathGroupCategory';
import { keyBy } from 'shared/utils/converter';
import Spinner from 'shared/components/atoms/Spinner';

interface CsvRowsInputDialogProps {
  is_open: boolean;
  value: string;
  stream_id?: string;
  stream_data_number?: number;
  required?: boolean;
  onClose: (value?: string) => void;
}
interface CsvRowsInputDoalogState {
  csv_rows: CsvRow[];
  export_csv_statistics?: string;
}
/** アプリパラメータでCSV行定義を設定するダイアログです */
export default class CsvRowsInputDialog extends React.PureComponent<CsvRowsInputDialogProps, CsvRowsInputDoalogState> {
  constructor(props: CsvRowsInputDialogProps) {
    super(props);
    let o = [];
    let export_csv_statistics = 'NONE';
    try {
      o = JSON.parse(this.props.value);
      if (!Array.isArray(o)) {
        export_csv_statistics = o['export_csv_statistics'];
        o = o['csv_rows'];
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
    this.state = {
      csv_rows: o,
      export_csv_statistics: export_csv_statistics,
    };
  }
  handleExportCsvStatistics = (value: string) => {
    this.setState({ export_csv_statistics: value });
  };
  handleCsvTableChange = (csv_rows: CsvRow[]) => {
    this.setState({ csv_rows: csv_rows });
  };
  /**
   * 入力チェックを行い、エラーの場合はメッセージを含む例外をスローします。
   */
  validate = () => {
    // csv_rowsのバリデーションでエラーを返す
    validateCsvRows(this.state.csv_rows, !this.props.required);
  };
  handleOKClick = () => {
    try {
      this.validate();
    } catch (e) {
      if (e instanceof Error) {
        AlertDialog.show(e.message);
      }
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const csv_info: Record<string, any> = {
      csv_rows: this.state.csv_rows,
      export_csv_statistics: this.state.export_csv_statistics,
    };
    this.props.onClose(JSON.stringify(csv_info));
  };
  render() {
    return (
      <PfDialog
        isOpen={this.props.is_open}
        title='CSV行定義'
        onClose={() => this.props.onClose()}
        contentProps={{ style: { display: 'flex', flexDirection: 'column' } }}
        large
      >
        <InputComponent text='集計'>
          <CheckBoxWithText
            text='集計する'
            checked={this.state.export_csv_statistics !== 'NONE'}
            onClick={() => this.handleExportCsvStatistics(this.state.export_csv_statistics !== 'NONE' ? 'NONE' : 'KEY')}
          />
        </InputComponent>
        <InputComponent text='CSV定義' required>
          <CSVTable
            bodies={this.state.csv_rows}
            stream_id={this.props.stream_id}
            showStatistics={this.state.export_csv_statistics === 'KEY'}
            setCsvRows={this.handleCsvTableChange}
            table_area_props={{
              style: {
                maxHeight: 'auto',
                height: 'auto',
              },
            }}
          />
        </InputComponent>
        <div style={{ textAlign: 'center' }}>
          <RoundedButton
            text='キャンセル'
            is_white={true}
            is_margin_right={true}
            onClick={() => this.props.onClose()}
          />
          <RoundedButton text='OK' onClick={this.handleOKClick} />
        </div>
      </PfDialog>
    );
  }
}

interface CsvRowsInputDialogButtonProps {
  value: string;
  streamId?: string;
  streamDataNumber?: number;
  required?: boolean;
  onChange: (value: string) => void;
}
interface CsvRowsInputDialogButtonState {
  isOpen: boolean;
}
/** ビデオエディタ設定ダイアログを開くためのボタンです。 */
export class CsvRowsInputDialogButton extends React.PureComponent<
  CsvRowsInputDialogButtonProps,
  CsvRowsInputDialogButtonState
> {
  constructor(props: CsvRowsInputDialogButtonProps) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  private handleOpen = () => {
    this.setState({ isOpen: true });
  };
  private handleClose = (value?: string) => {
    this.setState({ isOpen: false });
    if (value !== undefined) {
      this.props.onChange(value);
    }
  };
  render() {
    return (
      <>
        <RoundedButton text='CSV定義' onClick={this.handleOpen} />
        {this.state.isOpen && <CsvRowsInputDialog {...this.props} is_open={true} onClose={this.handleClose} />}
      </>
    );
  }
}

/** JSONパスの入力データ(ストリーム)の入力欄です */
interface CsvRowsInputDialogSelectButtonProps {
  value: string;
  display?: string;
  category?: string;
  onChange: (value: JsonpathGroup | undefined) => void;
}
export function InputJsonpathGroup(props: CsvRowsInputDialogSelectButtonProps) {
  const [jsonpath_groups, setJsonpathGroups] = useState<JsonpathGroup[]>();
  const jsonpath_groups_record: Record<string, JsonpathGroup> | undefined = useMemo(() => {
    if (jsonpath_groups) {
      return keyBy(jsonpath_groups, (jg) => jg.jsonpath_group_id);
    }
  }, [jsonpath_groups]);
  const getJsonpathGroups = async () => {
    let items: JsonpathGroup[] = [];
    setJsonpathGroups(undefined);
    let has_next = true;
    while (has_next) {
      const res = await jsonpathGroupGetAPI({
        display: props.display,
        category: props.category,
      });
      has_next = false;
      if (res.status === 200) {
        items = items.concat(res.data.items);
        has_next = res.data.has_next;
      }
    }
    setJsonpathGroups(items);
  };
  // 選択を変更したときのイベントハンドラ
  const handleChange = (value: string) => {
    // jsonpath_groupは辞書検索しているので見つからない場合はundefinedを想定する
    const jsonpath_group = jsonpath_groups_record?.[value];
    props.onChange(jsonpath_group);
  };

  // -- onload function --
  useEffect(() => {
    // プロセス情報の取得
    getJsonpathGroups();
  }, [props.display, props.category]); /* eslint-disable-line */
  return (
    <>
      {jsonpath_groups ? (
        <SelectBox
          data-testid='input-jsonpath-groups'
          style={{ width: 500 }}
          datas={jsonpath_groups.map((e) => {
            return {
              name: e.jsonpath_group_name + ' [' + jsonpathGroupCategoryToText(e.category) + ']',
              value: e.jsonpath_group_id,
            };
          })}
          value={props.value}
          onChange={(e) => handleChange(e.currentTarget.value)}
        />
      ) : (
        <Spinner />
      )}
    </>
  );
}
