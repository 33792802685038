// -- basic library --
import React from 'react';
import { Menu, MenuItem } from '@blueprintjs/core';
import {
  IItemListRendererProps,
  IItemRendererProps,
  renderFilteredItems,
  Suggest,
  SuggestProps,
} from '@blueprintjs/select';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import styled from 'styled-components';
import './SuggestBox.css';

// -- type declaration --

interface Params {
  items: string[] | undefined;
  value: string;
  inputItems: (item: string) => string;
  onItemSelect: (item: string, event?: React.SyntheticEvent<HTMLElement>) => void;
  onQueryChange: ((query: string, event?: React.ChangeEvent<HTMLInputElement> | undefined) => void) | undefined;
  'data-testid'?: string;
}

// -- main component --
/**
 * 入力時などでsuggest boxを表示するコンポーネント
 */
const SuggestBox: React.FC<Params> = (params) => {
  // -- preparations --

  const default_item_renderer = (item: string, itemProps: IItemRendererProps) => {
    return <MenuItem onClick={itemProps.handleClick} key={item} text={item} />;
  };
  // suggest boxの枠組み
  const item_list_renderer = (item: IItemListRendererProps<string>) => {
    const menuContent = renderFilteredItems(item);
    return (
      <ParentSuggestBox>
        <Menu style={{ maxHeight: 130 }}>{menuContent}</Menu>
      </ParentSuggestBox>
    );
  };

  // -- render part --
  return (
    <CustomSuggest
      items={params.items ?? []}
      itemRenderer={default_item_renderer}
      selectedItem={params.value}
      query={params.value}
      onItemSelect={params.onItemSelect}
      onQueryChange={params.onQueryChange}
      inputValueRenderer={params.inputItems}
      createNewItemPosition={'first'}
      itemListRenderer={item_list_renderer}
      fill={true}
      popoverProps={{ minimal: true }} /*矢印を消す*/
      data-testid={params['data-testid']}
    />
  );
};

const CustomSuggest = styled((props: SuggestProps<string> & { 'data-testid'?: string }) => <Suggest {...props} />)`
  .bp3-input {
    width: 100%;
    height: ${styles.input_component_height};
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid ${colors.component_small_border_color};
    border-radius: ${styles.border_radius};
    outline: none;
    font-family: inherit;
    color: ${colors.main_font_color};
    &:focus {
      border-color: ${colors.component_main_color};
    }
    &:disabled {
      opacity: 1;
      background-color: ${colors.disabled_background_color};
      cursor: not-allowed;
    }
    &:placeholder {
      color: ${colors.placeholder_color};
    }
  }
`;

const ParentSuggestBox = styled.div`
  top: 37px; // 1行分開ける
  position: absolute;
  box-shadow: 0 0 0 1px rgb(16 22 26 / 10%), 0 2px 4px rgb(16 22 26 / 20%), 0 8px 24px rgb(16 22 26 / 20%);
  border-radius: 3px;
`;

// -- finally export part --

export default SuggestBox;
