import { SizeWH, Point } from 'shared/models/index';
import { isBetweenRange, is2DLen2Array } from '../is';
import { scaleChange } from '../converter';

export const getVerticalCoordinate = (p: { start: Point; end: Point; angle?: number; distance?: number }) => {
  const distance = p.distance || 30;
  const angle = p.angle || 90;
  const center: Point = {
    x: (p.start.x + p.end.x) / 2,
    y: (p.start.y + p.end.y) / 2,
  };
  const x1_xc = p.start.x - center.x;
  const y1_yc = p.start.y - center.y;
  const x2_xc = p.end.x - center.x;
  const y2_yc = p.end.y - center.y;
  const pi = Math.PI;
  const scaledXY1 = scaleChange(
    {
      x: x1_xc * Math.cos((angle * pi) / 180) - y1_yc * Math.sin((angle * pi) / 180) + center.x,
      y: x1_xc * Math.sin((angle * pi) / 180) + y1_yc * Math.cos((angle * pi) / 180) + center.y,
    },
    center,
    distance,
  );
  const scaledXY2 = scaleChange(
    {
      x: x2_xc * Math.cos((angle * pi) / 180) - y2_yc * Math.sin((angle * pi) / 180) + center.x,
      y: x2_xc * Math.sin((angle * pi) / 180) + y2_yc * Math.cos((angle * pi) / 180) + center.y,
    },
    center,
    distance,
  );
  const rotatedCoordinate: {
    xc: number;
    yc: number;
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  } = {
    xc: center.x,
    yc: center.y,
    x1: scaledXY1.x,
    y1: scaledXY1.y,
    x2: scaledXY2.x,
    y2: scaledXY2.y,
  };
  return rotatedCoordinate;
};

// 座標が画像の範囲を超えないように調節する関数
export const modifyCoordinate = (coor: number, min: number, max: number) => {
  if (coor < min) {
    return min;
  }
  if (coor > max) {
    return max;
  }
  return coor;
};
/*** 検知ラインの番号を表示する座標を取得する関数***/
export const getNameCoordinate = (crossline: number[][]) => {
  if (!is2DLen2Array(crossline)) {
    return undefined;
  }
  // 垂線の延長線上の座標を指定するために、dustabceを42(>30)としている
  const vc = getVerticalCoordinate({
    start: {
      x: crossline[0][0],
      y: crossline[0][1],
    },
    end: {
      x: crossline[1][0],
      y: crossline[1][1],
    },
    distance: 45,
  });
  return {
    x: vc.x1 - vc.xc,
    y: vc.y1 - vc.yc,
  };
};

/*** 範囲内を超えていない場合はtrue, それ以外がfalse***/
export const checkXYWithinRange = (p: { crossline: number[][]; size: SizeWH }) => {
  if (!is2DLen2Array(p.crossline)) {
    return false;
  }
  const start = p.crossline[0];
  const end = p.crossline[1];
  // startのx座標判定
  if (start[0] > p.size.width || start[0] < 0) {
    return false;
  }
  // endのx座標判定
  if (end[0] > p.size.width || end[0] < 0) {
    return false;
  }
  // startのy座標判定
  if (start[1] > p.size.height || start[1] < 0) {
    return false;
  }
  // endのy座標判定
  if (end[1] > p.size.height || end[1] < 0) {
    return false;
  }
  return true;
};

// polygon_pointsのlengthの範囲内であればtrue, 範囲外であればfalse
export const checkRangeCrosslineIndex = (p: { index: number; crosslines: number[][][] }) => {
  return isBetweenRange(p.index, 0, p.crosslines.length);
};

// 線をを引ける最大数
export const checkCrosslinesLength = (p: { crosslines: number[][][]; max_length?: number }) => {
  const max_length = p.max_length || 5;
  if (p.crosslines.length > max_length) {
    return false;
  }
  return true;
};

/*** 割合で保持しているcrosslineに画像の大きさをかける ***/
export const multiplyCrossline = (p: { crossline: number[][]; width: number; height: number }) => {
  const new_crossline = p.crossline.map((pol) => {
    return pol.map((po, j) => {
      // p[0] -> x座標, p[1] -> y座標
      return j % 2 === 0 ? po * p.width : po * p.height;
    });
  });
  return new_crossline;
};
/*** 割合で保持しているpolygonに画像の大きさを割る ***/
export const diviseCrossline = (p: { crossline: number[][]; width: number; height: number }) => {
  const new_crossline = p.crossline.map((pol) => {
    return pol.map((po, j) => {
      // p[0] -> x座標, p[1] -> y座標
      return j % 2 === 0 ? po / p.width : po / p.height;
    });
  });
  return new_crossline;
};

/*** 割合で保持している1次元のリストに画像の大きさをかける ***/
export const multiplyPoint = (p: { point: number[]; width: number; height: number }): number[] => {
  const new_point = p.point.map((po, i) => {
    return i % 2 === 0 ? po * p.width : po * p.height;
  });
  return new_point;
};
