import { Metric } from 'user/api/dashboardsWidgets';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import JsonpathInput from 'user/components/molecules/JsonpathInput';
import SelectBox from 'shared/components/atoms/SelectBox';
import React, { useState } from 'react';

import { MetricGraphType, toMetricGraphType } from 'shared/models/MetricGraphType';
import styles from 'shared/styles/styles';
import CheckBoxWithText from 'shared/components/molecules/CheckBoxWithText';
// import { MetricStatisticMethod } from 'shared/models/MetricStatisticMethod';
// import { AccumulationTypes, convertAccumulationType } from 'shared/models/AccumulationType';
import { Thead, Th, Tbody, Tr, Td, WholeArea, Table, TableArea, FooterArea } from './StyledComponent';
import { Stream } from 'user/api/streams';
import InputBox from 'shared/components/atoms/InputBox';

interface SumUpGraphCountSettingTableProps {
  index: number;
  metrics: Metric[];
  streams: Stream[];
  stream_id: string;
  graph_type: MetricGraphType;
  name: string[];
  onChange: (value: Metric[]) => void;
}
const SumUpGraphCountSettingTable: React.FC<SumUpGraphCountSettingTableProps> = (props) => {
  const [stream_display, setStreamDisplay] = useState<boolean>(false);
  const handleChange = (value: string, index: number, key: string) => {
    const new_metrics = props.metrics.slice();
    if (key === 'json_path') {
      new_metrics[index].json_path = value;
    }
    if (key === 'stream_id') {
      new_metrics[index].stream_id = value;
    }
    // 現状accumulation,stack_idを使用しない想定なのでコメントアウト
    // if (key === 'accumulation') {
    //   new_metrics[index].accumulation = convertAccumulationType(value);
    // }
    // if (key === 'stack_id') {
    //   new_metrics[index].stack_id = value;
    // }
    if (key === 'sum_up_id') {
      new_metrics[index].sum_up_id = value;
    }
    if (key === 'graph_type') {
      new_metrics[index].graph_type = toMetricGraphType(value);
    }
    props.onChange(new_metrics);
  };
  // 現状statick_methodを使用しない想定なのでコメントアウト
  // const handleChangeStatisticMethod = (value: MetricStatisticMethod, index: number) => {
  //   const new_metrics = props.metrics.slice();
  //   new_metrics[index].statistic_method = value;
  //   props.onChange(new_metrics);
  // };
  const handleAddRow = () => {
    const new_metrics = props.metrics.slice();
    if (new_metrics.length >= 100) {
      AlertDialog.show('メトリクスは最大で100行までです。');
      return;
    }
    new_metrics.push({
      name: props.index + '-' + new_metrics.length,
      stream_id: props.stream_id ? props.stream_id : '',
      json_path: '',
      statistic_method: 'Sum',
      accumulation: 'NONE',
      stack_id: '1',
      sum_up_id: '1',
      graph_type: props.graph_type,
    });
    props.onChange(new_metrics);
  };
  const handleDeleteRow = () => {
    const new_metrics = props.metrics.slice();
    if (new_metrics.length <= 1) {
      AlertDialog.show('メトリクスは少なくとも1つ以上の入力が必要です');
      return;
    }
    new_metrics.pop();
    props.onChange(new_metrics);
  };
  const handleStreamDisplayCheck = () => {
    setStreamDisplay(stream_display ? false : true);
  };
  // const statistic_methods = [
  //   { name: '最大値', value: 'Maximum' },
  //   { name: '最小値', value: 'Minimum' },
  //   { name: '合計値', value: 'Sum' },
  //   { name: '平均値', value: 'Average' },
  //   { name: '値の数', value: 'Count' },
  // ];

  return (
    <WholeArea>
      {/* テーブル */}
      <TableArea>
        <Table>
          <Thead>
            <tr>
              {/* テーブルヘッダー */}
              <Th style={{ width: 215 }}>表示名</Th>
              <Th style={{ width: 70 }}>合算ID</Th>
              <Th style={{ width: 215 }}>JSONパス</Th>
              {stream_display && <Th style={{ width: 445 }}>対象データ</Th>}
              {/* <Th style={{ width: 100 }}>集計方法</Th>
              <Th style={{ width: 100 }}>累積期間</Th> */}
              {/* <Th style={{ width: 70 }}>スタック</Th> */}
            </tr>
          </Thead>

          {/* テーブルボディー */}
          <Tbody>
            {/* ボディーデータをソートした後に、一ページ分のデータに切り分ける */}
            {props.metrics.map((body, index) => {
              return (
                <Tr key={index}>
                  <Td key={`name_${index}`} last_row={index + 1 === props.metrics.length ? true : false}>
                    <InputBox
                      value={props.name[Number(props.metrics[index].sum_up_id) - 1] || ''}
                      onChange={() => {}}
                    />
                  </Td>
                  <Td key={`sum_up_id_${index}`} last_row={index + 1 === props.metrics.length ? true : false}>
                    <SelectBox
                      value={body.sum_up_id as string}
                      datas={Array.from({ length: props.name.length }, (_, i) => {
                        return {
                          value: (i + 1).toString(),
                          name: (i + 1).toString(),
                        };
                      })}
                      onChange={(e) => handleChange(e.currentTarget.value, index, 'sum_up_id')}
                      long
                    />
                  </Td>
                  <Td key={`json_path_${index}`} last_row={index + 1 === props.metrics.length ? true : false}>
                    <JsonpathInput
                      value={body.json_path}
                      onJsonpathChanged={(item: string) => handleChange(item, index, 'json_path')}
                      placeholder='$から始めてください'
                      stream_id={body.stream_id}
                    />
                  </Td>
                  {stream_display ? (
                    <Td key={`stream_id_${index}`} last_row={index + 1 === props.metrics.length ? true : false}>
                      <SelectBox
                        value={body.stream_id || ''}
                        datas={props.streams.map((s) => {
                          return { name: s.stream_name, value: s.stream_id };
                        })}
                        onChange={(e) => handleChange(e.currentTarget.value, index, 'stream_id')}
                        long
                      />
                    </Td>
                  ) : null}
                  {/* <Td
                    key={`statistic_method_${index}`}
                    last_row={index + 1 === props.metrics.length ? true : false}
                    last_child
                  >
                    <SelectBox
                      value={body.statistic_method}
                      datas={statistic_methods}
                      onChange={(e) =>
                        handleChangeStatisticMethod(e.currentTarget.value as MetricStatisticMethod, index)
                      }
                      long
                    />
                  </Td>
                  <Td key={`accumulation_${index}`} last_row={index + 1 === props.metrics.length ? true : false}>
                    <SelectBox
                      value={body.accumulation || ''}
                      datas={AccumulationTypes.map((d) => {
                        return {
                          value: d,
                          name: d,
                        };
                      })}
                      onChange={(e) => handleChange(e.currentTarget.value, index, 'accumulation')}
                      long
                    />
                  </Td> */}
                  {/* <Td key={`stack_id_${index}`} last_row={index + 1 === props.metrics.length ? true : false}>
                        <SelectBox
                          value={body.stack_id || ''}
                          default_text=''
                          default_text_value=''
                          datas={['1', '2', '3', '4', '5', '6', '7', '8', '9'].map((d) => {
                            return {
                              value: d,
                              name: d,
                            };
                          })}
                          onChange={(e) => handleChange(e.currentTarget.value, index, 'stack_id')}
                          long
                        />
                      </Td> */}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableArea>
      <CheckBoxWithText text='対象データを表示する' checked={stream_display} onClick={handleStreamDisplayCheck} />

      {/* フッター */}
      <FooterArea>
        <RoundedButton
          text='削除'
          onClick={handleDeleteRow}
          style={{
            width: styles.super_small_button_width,
            height: styles.super_small_button_height,
            marginRight: styles.interval_narrow_margin,
          }}
          is_white
        />
        <RoundedButton
          text='追加'
          onClick={handleAddRow}
          style={{
            width: styles.super_small_button_width,
            height: styles.super_small_button_height,
          }}
        />
      </FooterArea>
    </WholeArea>
  );
};

export default SumUpGraphCountSettingTable;
