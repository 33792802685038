// -- basic library --
import React, { useState } from 'react';
import GrayCube from 'shared/components/atoms/GrayCube';
import { Content } from 'shared/components/molecules/ContentsArea';
import M3u8Date from 'shared/components/molecules/M3u8Date';
import M3u8PlayBar from 'shared/components/molecules/M3u8PlayBar';
import styles from 'shared/styles/styles';
import styled from 'styled-components';

interface Params {
  selected_video_date: string;
  is_loaded: boolean;
  onTimeChange: (time: string) => Promise<void>;
  width?: number;
  height?: number;
  video_ref: React.RefObject<HTMLVideoElement>;
}

// -- main component --
const M3u8PlayBarDateArea: React.FC<Params> = (params) => {
  // -- local states --
  // -- reference preparations --

  const [selecting_video_date, setSelectingVideoDate] = useState<string | undefined>(undefined);
  const [type, setType] = useState<string>('24H');

  // -- handlers --

  // -- render part --
  return (
    <Content>
      <PlayBarArea>
        <M3u8PlayBar
          width={params.width || 800}
          height={params.height || 60}
          type={type}
          selected_video_time={params.selected_video_date}
          draggable={params.is_loaded}
          onTimeChange={params.onTimeChange}
          video_ref={params.video_ref}
          setSelectingVideoDate={setSelectingVideoDate}
        />
      </PlayBarArea>
      <M3u8DateArea>
        <ButtonArea>
          <GrayCube
            text='1H'
            onClick={() => setType('1H')}
            style={{
              marginRight: styles.interval_narrow_margin,
            }}
            selected={type === '1H'}
          />
          <GrayCube text='24H' onClick={() => setType('24H')} selected={type === '24H'} />
        </ButtonArea>
        <M3u8Date
          selectedVideoDate={params.selected_video_date}
          videoRef={params.video_ref}
          selecting_video_date={selecting_video_date}
        />
      </M3u8DateArea>
    </Content>
  );
};

// -- styled components --

const PlayBarArea = styled.div`
  width: auto;
  height: auto;
`;

const M3u8DateArea = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
`;

const ButtonArea = styled.div`
  display: flex;
  width: calc(100% / 3);
`;

// -- finally export part --

export default M3u8PlayBarDateArea;
