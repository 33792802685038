// -- basic library --
import React from 'react';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableHeaderType, TableBodyUrlType } from 'shared/components/molecules/Table/type';

// テーブルの列の情報まとめたデータ
const headers: TableHeaderType[] = [
  {
    id: 'img_element',
    label: '',
    width: `150px`,
  },
  {
    id: 'channel_name',
    label: 'チャンネル名',
    width: `auto`,
    sortable: true,
    search_props: {
      type: 'name',
      default_display: true,
    },
  },
  {
    id: 'created_at',
    label: '登録日',
    width: '15%',
    search_props: {
      type: 'datetime',
    },
  },
];

// -- type declaration --

interface Params {
  bodies: TableChannelsType[];
  selected_bodies: TableChannelsType[];
  handleClick: (datas: TableChannelsType[]) => void;
  footer_option: {
    text: string;
    handleClick: () => void;
    disabled?: boolean;
  };
}

export type TableChannelsType = {
  id: string;
  img_element: JSX.Element;
  channel_name: TableBodyUrlType;
  created_at: string;
};

// -- external functions --

// -- main component --
const ChannelsTable: React.FC<Params> = (params) => {
  // -- render part --
  return (
    <BaseTable
      bodies={params.bodies}
      headers={headers}
      table_name='channels'
      selected_bodies={params.selected_bodies}
      handleCheckClick={params.handleClick}
      id_abridgement={true}
      bodies_per_page={5}
      footer_option={params.footer_option}
      disabled_padding_lefts={{ img_element: true }} // img_element列のpadding-leftを無効化
    />
  );
};

// -- styled components --

// -- finally export part --
export default ChannelsTable;
