import { AxiosResponse } from 'axios';
import { CommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';
import { ProcessFlowCategoryType } from 'shared/models/ProcessFlowCategoryType';
import { Query } from 'shared/models/Query';
import sendAxios from '../../shared/axios/sendAxios';
import { getClient } from './base';
import { Process } from './processes';

/*** エンティティ ***/

export interface ProcessFlow {
  process_flow_id: string;
  process_flow_name: string;
  steps: {
    step_id: number;
    process_id: string;
    input_streams: string[];
  }[];
}

export interface ProcessFlowsWithPaging {
  items: ProcessFlow[];
  has_next: boolean;
}

const sample_processflow1: ProcessFlow = {
  process_flow_id: '00000001',
  process_flow_name: 'プロセスフロー1',
  steps: [],
};
const sample_processflow2: ProcessFlow = {
  process_flow_id: '00000002',
  process_flow_name: 'プロセスフロー2',
  steps: [],
};
const sample_processflow3: ProcessFlow = {
  process_flow_id: '00000003',
  process_flow_name: 'プロセスフロー3',
  steps: [],
};

/*** Caching mechanism ***/

export class CachedProcessFlows {
  private searched = false;
  private cache: ProcessFlow[] = [];
  private params: RequestProcessFlowsGet;
  constructor(params: RequestProcessFlowsGet) {
    this.params = params;
  }
  async get() {
    if (!this.searched) {
      let has_next = true;
      let exclusive_start_key = '';
      while (has_next) {
        const res: AxiosResponse<ProcessFlowsWithPaging> = await processflowsGetAPI({
          exclusive_start_process_flow_id: exclusive_start_key,
          ...this.params,
        });
        has_next = false;
        if (res.status === 200) {
          this.cache = [...this.cache, ...res.data.items];
          has_next = res.data.has_next;
          exclusive_start_key = res.data.items
            .map((d) => String(d.process_flow_id))
            .reduce((a, b) => (a > b ? a : b), '');
        }
      }
      this.searched = true;
    }
    return this.cache;
  }
}

/*** [GET] /api/processflows ***/

export interface RequestProcessFlowsGet extends CommonAPIRequestType {
  exclusive_start_process_flow_id?: string;
  category?: ProcessFlowCategoryType;
}
export const processflowsGetAPI = (params: RequestProcessFlowsGet) => {
  const { exclusive_start_process_flow_id, category, disabled_load, disabled_error_message, ended_load } =
    toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/processflows`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    exclusive_start_process_flow_id,
    category,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // }

  // 送信
  return sendAxios<ProcessFlowsWithPaging>(axios, path, query, form, method, {
    items: [sample_processflow1, sample_processflow2, sample_processflow3],
    has_next: false,
  });
};

export interface ProcessFlowInfo {
  process_flow_id: string;
  process_flow_name: string;
  processes: Process[];
}

const sample_processflow_info1: ProcessFlowInfo = {
  process_flow_id: '00000001',
  process_flow_name: 'プロセスフロー1',
  processes: [
    {
      process_id: '00000001',
      process_name: 'string',
      tenant_id: '00000001',
      status: 'ACTIVE',
      input_stream_count: 1,
      input_stream_constraints: [
        {
          data_type: 'VIDEO', // 複数カンマ区切り
          data_number_type: 'TIMESTAMP',
          max_repeats: 0,
        },
      ],
      output_stream_count: 0,
      app_parameters: [
        {
          key: 'jsonpath_group',
          name: 'jsonpath_group',
          description: 'jsonpath_group',
          default_value: '',
          input_type: 'JSONPATHGROUPS',
          list_items: [],
          required: false,
          validate_regax: '',
        },
      ],
      heartbeats: [
        {
          key: 'string',
          name: 'string',
          description: 'string',
          default_schedule: 'string',
          required: true,
        },
      ],
      can_be_oneshot: true,
    },
  ],
};

/*** Caching mechanism ***/

export class CachedProcessFlowInfos {
  private cache: Record<string, ProcessFlowInfo> = {};
  async get(params: RequestProcessFlowsIdGet) {
    // すでに存在していれば、そのデータを返す
    if (this.cache[params.process_flow_id]) {
      return this.cache[params.process_flow_id];
    }
    // 指定のデータがなければ取得する。
    const res = await processflowsIdGetAPI({ process_flow_id: params.process_flow_id });
    if (res.status === 200) {
      //cacheにデータを格納
      this.cache[params.process_flow_id] = res.data;
      // 返却
      return res.data;
    }
  }
}

/*** [GET] /api/processflows/{process_flow_id} ***/
export interface RequestProcessFlowsIdGet {
  process_flow_id: string;
}
/**
 * チャンネルに入力データ(ストリーム)を追加する
 * @param process_flow_id プロセスフローID
 * @returns プロセスフロー情報
 */
export const processflowsIdGetAPI = (params: RequestProcessFlowsIdGet) => {
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `/api/processflows/${params.process_flow_id}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // }

  // 送信
  return sendAxios<ProcessFlowInfo>(axios, path, query, form, method, sample_processflow_info1);
};
