// -- basic library --
import React, { useCallback, useEffect, useState } from 'react';
import PfBoldText from 'shared/components/atoms/PfBoldText';
import PfDialog, { PfDialogProps } from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import RadioBox from 'shared/components/molecules/RadioBox';
import ToggleButton from 'shared/components/molecules/ToggleButton';
import { colors } from 'shared/styles/colors';
import { isNotOnlySpace } from 'shared/utils/is';
import { dateToYMDHMS } from 'shared/utils/converter/date';
import styled from 'styled-components';
import { JsonpathGroup, jsonpathGroupsIdGetAPI } from 'user/api/jsonpathGroups';
import { CachedStreamPackagesFavorite } from 'user/api/streamPackagesFavorite';
import { Stream } from 'user/api/streams';
import { StreamPackage, streamsIdPackagesNumberGetAPI } from 'user/api/streamsPackage';
import CSVTable from './CSVTable';
import { useStreams } from 'user/hooks/useStreams/useStreams';
import { StringBoolean } from 'shared/models/StringBoolean';
import { CsvRow } from 'shared/models/CsvRow';
import { exportCsvTimeUnitToName } from 'shared/models/ExportCsvTimeUnit';
import { exportExportCsvStatisticsToName } from 'shared/models/ExportCsvStatistics';
import getDlpkgLabel from 'user/utils/getDlpkgLabel';

/*** お気に入りのストリームパッケージを選択できる詳細表示ダイアログ***/
interface StreamPackagePreviewDialogParams extends PfDialogProps {
  stream: Stream;
  isOpen: boolean;
  onClose: () => void;
  onCopyCreate: (stream_id: string, stream_package_number: string) => void;
  is_hide_copy_button?: boolean;
  style?: React.CSSProperties;
}

// -- main component --
const StreamPackagePreviewDialog: React.FC<StreamPackagePreviewDialogParams> = ({
  stream,
  isOpen,
  onClose,
  onCopyCreate,
  is_hide_copy_button,
  style,
}) => {
  // -- local states --

  const [stream_package, setStreamPackage] = useState<StreamPackage | undefined>(undefined); // 選択されたstream_package
  const [stream_id, setStreamId] = useState<string>(''); // 選択されたstream_id
  const [stream_package_number, setStreamPackageNumber] = useState<string>(''); // 選択されたstream_package_number
  const [is_display_detail, setIsDisplayDetail] = useState<boolean>(true); // デフォルトでは表示する
  const [stream_packages, setStreamPackages] = useState<StreamPackage[] | undefined>(undefined); // ストリームパッケージ一覧
  const { streams_record } = useStreams({ with_output_streams: 'True', with_in_channel_streams: 'True' });
  const [jsonpath_group, setJsonpathGroup] = useState<JsonpathGroup | undefined>(undefined); //JSONパスグループ名表示用
  const [csv_rows, setCsvRows] = useState<CsvRow[]>([
    {
      header_name: '',
      json_path: '',
      cell_format: '',
      cell_format_args: '',
      statistic_method: 'Key',
      fill: 'False',
    },
  ]);

  // ストリームパッケージを得る関数
  const getStreamPackage = async (stream_package_number: string, stream_id?: string) => {
    // stream_packages内で検索
    let stream_package = stream_packages?.find(
      (sp) => String(sp.stream_package_number) === stream_package_number && sp.stream_id === stream_id,
    );
    //なければ個別に呼び出す
    if (!stream_package && stream_id) {
      const res = await streamsIdPackagesNumberGetAPI({
        stream_id: stream_id,
        stream_package_number: stream_package_number,
      });
      if (res.status === 200) {
        stream_package = res.data;
      }
    }
    //それでもなければreturn
    if (!stream_package) return;
    setStreamPackage(stream_package);
    setStreamPackageNumber(stream_package_number);
    setStreamId(stream_package.stream_id);

    //JSONパスグループの取得
    if (stream_package.jsonpath_group_id) {
      const jsonpath_group = await jsonpathGroupsIdGetAPI({ jsonpath_group_id: stream_package.jsonpath_group_id });
      if (jsonpath_group.status === 200) {
        setJsonpathGroup(jsonpath_group.data);
        if (stream_package.csv_rows.length === 0) {
          setCsvRows(jsonpath_group.data.csv_rows);
        }
      }
    } else {
      setJsonpathGroup(undefined);
      setCsvRows([]);
    }
    if (stream_package.csv_rows.length > 0) {
      const new_csv_rows = stream_package.csv_rows.map((r) => {
        return {
          header_name: r.header_name,
          json_path: r.json_path,
          cell_format: r.cell_format,
          cell_format_args: r.cell_format_args === null ? '' : r.cell_format_args,
          statistic_method: r.statistic_method || 'Key',
          fill: r.fill ? 'True' : ('False' as StringBoolean),
        };
      });
      setCsvRows(new_csv_rows);
    }
  };

  const getRadioBoxLabel = (stream_id: string, stream_package_name: string) => {
    let value = stream_package_name;
    if (!(stream_id in streams_record)) return value;
    value = `${stream_package_name}[${streams_record[stream_id].stream_name}]`;
    return value;
  };

  const handleFinishClick = () => {
    if (!isNotOnlySpace(stream_package_number)) {
      AlertDialog.show('ストリームパッケージを選択してください');
      return;
    }
    onCopyCreate(stream_id, stream_package_number);
    onClose();
  };

  const load = useCallback(async () => {
    // ストリームパッケージ(お気に入り)一覧取得
    const streamPackagesFavorite = await new CachedStreamPackagesFavorite({}).get();
    const newStreamPackagesFavorite: StreamPackage[] = [];
    // 自身のdata_typeによって選べるお気に入りストリームパッケージをフィルタリングする
    for (const spf of streamPackagesFavorite) {
      const st = streams_record[spf.stream_id];
      const data_type = st?.data_type;
      // VIDEOかIMAGEならどちらかであればよい
      if (stream.data_type === 'VIDEO' || stream.data_type === 'IMAGE') {
        if (data_type === 'VIDEO' || data_type === 'IMAGE') {
          newStreamPackagesFavorite.push(spf);
        }
      } else {
        //それ以外なら合致していれば良い
        if (data_type === stream.data_type) {
          newStreamPackagesFavorite.push(spf);
        }
      }
    }
    setStreamPackages(newStreamPackagesFavorite);
  }, [streams_record, stream.data_type]);

  // -- onload function --
  useEffect(() => {
    (async function () {
      await load();
    })();
  }, [load]);

  // -- handlers --
  // -- render part --
  return (
    <PfDialog onClose={onClose} isOpen={isOpen} title={'お気に入り' + getDlpkgLabel(stream) + '選択'} large style={style}>
      <StyledTop>
        <div style={{ marginRight: '5px' }}>詳細を表示する</div>
        <ToggleButton checked={is_display_detail} onClick={() => setIsDisplayDetail(!is_display_detail)} />
      </StyledTop>
      <StyledContent>
        <StyledLeft>
          <PfBoldText style={{ marginBottom: 15 }}>
            ダウロードパッケージ選択欄
            <br />
            ※パッケージ名[ストリーム名]
          </PfBoldText>
          {stream_packages ? (
            <RadioBox
              column
              child_style={{ marginTop: 15 }}
              handleChangeClick={async (value, sub_value) => await getStreamPackage(String(value), sub_value)}
              selectedValue={stream_package_number}
              selectedSubValue={stream_id}
              datas={
                stream_packages
                  ? stream_packages.map((stream_package) => {
                      return {
                        name: getRadioBoxLabel(stream_package.stream_id, stream_package.stream_package_name),
                        value: String(stream_package.stream_package_number),
                        sub_value: stream_package.stream_id,
                      };
                    })
                  : []
              }
            ></RadioBox>
          ) : (
            <Spinner />
          )}
        </StyledLeft>
        <StyledRight>
          <PfBoldText style={{ marginBottom: 15 }}>{getDlpkgLabel(stream)}詳細</PfBoldText>
          {stream && stream_package && is_display_detail ? (
            <>
              <InputComponent text='お気に入り'>
                <div>{stream_package.favorite ? '登録済み' : '未登録'}</div>
              </InputComponent>
              <InputComponent text='パッケージ名'>
                <div>{stream_package.stream_package_name}</div>
              </InputComponent>
              <InputComponent text='対象データ'>
                {stream.data_number_type === 'TIMESTAMP' ? (
                  <div>
                    {dateToYMDHMS(stream_package.stream_data_number_from * 1000)}~
                    {dateToYMDHMS(stream_package.stream_data_number_to * 1000)}
                  </div>
                ) : (
                  <div>
                    {stream_package.stream_data_number_from}~{stream_package.stream_data_number_to}
                  </div>
                )}
              </InputComponent>
              <InputComponent text='出力ファイル名'>
                <div>{stream_package.export_filename}</div>
              </InputComponent>

              {stream_package.package_type === 'CSV' ? (
                <>
                  <PfBoldText>CSV</PfBoldText>
                  <InputComponent text='CSVエンコード'>
                    <div>{stream_package.export_csv_encode}</div>
                  </InputComponent>
                  <InputComponent text='JSONパスグループ'>{jsonpath_group?.jsonpath_group_name}</InputComponent>
                  <InputComponent text='集計'>
                    {exportExportCsvStatisticsToName(stream_package.export_csv_statistics)}
                  </InputComponent>
                  {stream_package.export_csv_statistics === 'TIMESTAMP' ? (
                    <InputComponent text='集計する時間単位'>
                      {exportCsvTimeUnitToName(stream_package.export_csv_time_unit)}
                    </InputComponent>
                  ) : (
                    <InputComponent text='A列にタイムスタンプを出力'>
                      {stream_package.export_csv_stream_data_number === 'None' ? '出力しない' : '出力する'}
                    </InputComponent>
                  )}
                  <InputComponent text='CSV定義'>
                    <CSVTable
                      bodies={csv_rows.map((r) => {
                        return {
                          header_name: r.header_name,
                          json_path: r.json_path,
                          cell_format: r.cell_format,
                          cell_format_args: r.cell_format_args === null ? '' : r.cell_format_args,
                          statistic_method: r.statistic_method,
                          fill: r.fill ? 'True' : 'False',
                        };
                      })}
                      showStatistics={stream_package.export_csv_statistics === 'KEY'}
                    />
                  </InputComponent>
                </>
              ) : null}
              {stream_package.package_type === 'AUTO_CSV' ? (
                <>
                  <PfBoldText>CSV</PfBoldText>
                  <InputComponent text='CSVエンコード'>
                    <div>{stream_package.export_csv_encode}</div>
                  </InputComponent>
                  <InputComponent text='CSV形式'>
                    <div>{stream_package.export_auto_csv_type}</div>
                  </InputComponent>
                </>
              ) : null}
              {stream_package.package_type === 'MP4' ? (
                <>
                  <PfBoldText>動画</PfBoldText>
                  <InputComponent text='FPS'>
                    <div>{stream_package.fps}</div>
                  </InputComponent>
                  <InputComponent text='解像度'>
                    <div>
                      幅 {stream_package.width} × 高さ {stream_package.height}
                    </div>
                  </InputComponent>
                </>
              ) : null}
            </>
          ) : null}
        </StyledRight>
      </StyledContent>
      <StyledBottom>
        {!is_hide_copy_button && (
          <Footer>
            <RoundedButton text='同じ条件で新規作成' onClick={handleFinishClick} />
          </Footer>
        )}
      </StyledBottom>
    </PfDialog>
  );
};

// -- styled components --

const StyledContent = styled.div`
  display: flex;
  height: calc(100% - 25px - 45px);
  overflow-y: hidden;
`;

const StyledTop = styled.div`
  height: 25px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledBottom = styled.div`
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLeft = styled.div`
  width: 350px;
  max-height: 100%;
  overflow-y: scroll;
  border-right: 1px solid ${colors.main_font_color};
`;

const StyledRight = styled.div`
  width: calc(100% - 350px);
  max-height: 100%;
  overflow-y: scroll;
  margin-left: 30px;
`;

// -- finally export part --

export default StreamPackagePreviewDialog;
