import React from 'react';
import history from 'shared/browserHistory';
import FunctionalText from 'shared/components/atoms/FunctionalText';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import RoundedCard from 'shared/components/atoms/RoundedCard';
import Spinner from 'shared/components/atoms/Spinner';
import { Content } from 'shared/components/molecules/ContentsArea';
import StateText from 'shared/components/molecules/StateText';
import styles from 'shared/styles/styles';
import styled from 'styled-components';
import { CachedChannels, Channel, channelsIdGetAPI } from 'user/api/channels';
import ChannelThumbnailImage from 'user/components/molecules/ChannelThumbnailImage';

// -- http connection library --

// -- external components --

// パネル１枚
interface EventPanelProps {
  channel: Channel;
  selected_data_value: string;
  onChannelTabChange: (channel_id: string, tab_id: string) => void;
}

export class EventPanel extends React.PureComponent<EventPanelProps> {
  private handleChannelNameClick = (channel_id: string) => {
    history.push(`/channels/${channel_id}/overviews`);
  };

  private handleEventHistoryClick = (channel_id: string) => {
    this.props.onChannelTabChange(channel_id, 'table');
  };
  render() {
    const cardStyle: React.CSSProperties = {
      width: 340,
      height: 360,
      padding: '10px 20px',
      margin: '15px',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
    };
    return (
      <RoundedCard style={cardStyle} badge_number={this.props.channel.unconfirm_event_count}>
        <StateText state={this.props.channel.status === 'ACTIVE' ? 'ACTIVE' : 'PAUSE'} />
        <FunctionalTextArea>
          <FunctionalText
            text={this.props.channel.channel_name}
            onClick={() => this.handleChannelNameClick(this.props.channel.channel_id)}
            style={{
              textAlign: 'left',
              fontSize: styles.normal_text_size,
              maxWidth: '100%',
              marginTop: 2,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          />
        </FunctionalTextArea>

        <div style={{ height: 260, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ChannelThumbnailImage
            channel_id={this.props.channel.channel_id}
            event_number={this.props.channel.unconfirm_event_count}
            from_event_page={true}
            style={{ maxWidth: 280, maxHeight: 240 }}
          />
        </div>
        <RoundedButton
          text='アラート履歴'
          onClick={() => this.handleEventHistoryClick(this.props.channel.channel_id)}
          style={{
            width: '100%',
          }}
        />
      </RoundedCard>
    );
  }
}

interface EventsPanelProps {
  selected_data_value: string;
  onChannelTabChange: (channel_id: string, tab_id: string) => void;
}
interface EventsPanelState {
  channels?: Channel[];
}
// パネルを複数枚表示させる
export default class EventsPanel extends React.PureComponent<EventsPanelProps, EventsPanelState> {
  constructor(props: EventsPanelProps) {
    super(props);
    this.state = {
      channels: undefined,
    };
  }
  async componentDidMount() {
    if (this.props.selected_data_value !== 'all') {
      await this.getChannel(this.props.selected_data_value);
    } else {
      await this.getChannels();
    }
  }
  async componentDidUpdate(prevProps: EventsPanelProps) {
    // 典型的な使い方(props を比較することを忘れないでください)
    if (this.props.selected_data_value !== prevProps.selected_data_value) {
      if (this.props.selected_data_value !== 'all') {
        await this.getChannel(this.props.selected_data_value);
      } else {
        await this.getChannels();
      }
    }
  }
  private getChannels = async () => {
    const channel_items: Channel[] = await new CachedChannels({
      channel_type: 'STANDARD',
      from_event_page: 'True',
    }).get();
    this.setState({
      channels: channel_items,
    });
  };

  private getChannel = async (channel_id: string) => {
    const res = await channelsIdGetAPI({ channel_id: channel_id, from_event_page: 'True' });
    if (res.status === 200) {
      this.setState({
        channels: [res.data],
      });
    }
  };

  render() {
    return (
      <Content style={{ display: 'flex', flexWrap: 'wrap' }}>
        {this.state.channels ? (
          this.state.channels.map((ch, index) => {
            return <EventPanel channel={ch} key={index} {...this.props} />;
          })
        ) : (
          <Spinner />
        )}
      </Content>
    );
  }
}

const FunctionalTextArea = styled.div`
  display: inline-block;
  text-align: left;
`;
