// クロップビデオの設定用ダイアログ

import React from 'react';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import CropPanel from './CropPanel';

interface StreamCropDialogProps {
  isOpen: boolean;
  value: string;
  streamId?: string;
  streamDataNumber?: number;
  onClose: (value?: string) => void;
}
interface StreamCropDialogState {}
export default class StreamCropDialog extends React.PureComponent<StreamCropDialogProps, StreamCropDialogState> {
  private panelRef = React.createRef<CropPanel>();
  constructor(props: StreamCropDialogProps) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <PfDialog
        isOpen={this.props.isOpen}
        title='クロップビデオ設定'
        onClose={() => this.props.onClose()}
        contentProps={{ style: { display: 'flex', flexDirection: 'column' } }}
        large
      >
        <CropPanel
          ref={this.panelRef}
          streamId={this.props.streamId}
          streamDataNumber={this.props.streamDataNumber}
          style={{ width: '100%', flexGrow: 1 }}
          value={this.props.value}
        />
        <div style={{ textAlign: 'center' }}>
          <RoundedButton text='キャンセル' is_white={true} onClick={() => this.props.onClose()} />
          <RoundedButton
            text='OK'
            onClick={() => this.props.onClose(JSON.stringify(this.panelRef.current?.state.crops))}
          />
        </div>
      </PfDialog>
    );
  }
}

interface StreamCropDialogButtonProps {
  value: string;
  streamId?: string;
  streamDataNumber?: number;
  onChange: (value: string) => void;
}
interface StreamCropDialogButtonState {
  isOpen: boolean;
}
/** クロップビデオ設定ダイアログを開くためのボタンです。 */
export class StreamCropDialogButton extends React.PureComponent<
  StreamCropDialogButtonProps,
  StreamCropDialogButtonState
> {
  constructor(props: StreamCropDialogButtonProps) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  private handleOpen = () => {
    this.setState({ isOpen: true });
  };
  private handleClose = (value?: string) => {
    this.setState({ isOpen: false });
    if (value !== undefined) {
      this.props.onChange(value);
    }
  };
  render() {
    return (
      <>
        <RoundedButton
          text='クロップビデオ設定'
          onClick={this.handleOpen}
          disabled={(this.props.streamId || '') === ''}
        />
        {this.state.isOpen && this.props.streamId && (
          <StreamCropDialog
            isOpen={true}
            value={this.props.value}
            streamId={this.props.streamId}
            streamDataNumber={this.props.streamDataNumber}
            onClose={this.handleClose}
          />
        )}
      </>
    );
  }
}
