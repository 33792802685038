import { AreaCountSetting } from 'user/api/dashboardsWidgets';
import { isNotOnlySpace } from 'shared/utils/is';

const checkAreaCountSettings = (widget_type: string, area_count_settings: AreaCountSetting[]) => {
  if (widget_type !== 'AREACOUNT') return true;
  // データの長さにおけるヴァリデーション
  if (area_count_settings.length < 1 || area_count_settings.length > 200) {
    return false;
  }
  //  エリアカウント設定の中身のヴァリデーション
  for (let i = 0; i < area_count_settings.length; i++) {
    if (
      !isNotOnlySpace(area_count_settings[i].name) ||
      !isNotOnlySpace(area_count_settings[i].area_name) ||
      area_count_settings[i].count_target.length === 0
    )
      return false;
  }

  return true;
};

export default checkAreaCountSettings;
