// -- basic library --
import React, { useEffect, useState } from 'react';

// -- external components --
import { RouteComponentProps } from 'react-router-dom';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import SelectBox from 'shared/components/atoms/SelectBox';
import Spinner from 'shared/components/atoms/Spinner';
import AutoReloadButton from 'shared/components/molecules/AutoReloadButton';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { Content, FlexCenterDiv, TopArea } from 'shared/components/molecules/ContentsArea';
import { WidgetScaler } from 'shared/components/molecules/WidgetScaler';

// -- http connection library --

// -- external types --

// -- external functions --
import { WidgetScaleType } from 'shared/models/WidgetScaleType';
import FeatureDatetime from 'shared/models/FeatureDatetime';
import convertStreamsToSelectDatas from 'user/utils/convertStreamsToSeledtDatas';
import { getDateLabel } from 'shared/utils/get';
import { Device, devicesIdGetAPI, devicesIdPutAPI } from 'user/api/device';
import { CachedStreams, Stream, streamsIdPutAPI } from 'user/api/streams';
import DatetimeRangeDialog from 'user/dialogs/DatetimeRangeDialog';
import DetailDeviceStreamGraphsDialog from 'user/pages/Devices/DetailPage/CameraAnalysisResultPage/DetailDeviceStreamGraphsDialog';
import CameraAnalysisResultWrapper from './CameraAnalysisResultWrapper';
import { StreamDeviceGraph } from 'shared/models/StreamDeviceGraph';

// -- type declaration --

type Params = RouteComponentProps<{ device_id: string }>;

// -- external functions --

// -- main component --
const CameraAnalysisResult: React.FC<Params> = (params) => {
  // -- local states --
  const [device, setDevice] = useState<Device | undefined>(undefined);
  const [streams, setStreams] = useState<Stream[]>([]);
  const [selected_stream, setSelectedStream] = useState<Stream | undefined>(undefined);
  const [start, setStart] = React.useState<FeatureDatetime>(new FeatureDatetime('today'));
  const [end, setEnd] = React.useState<FeatureDatetime>(new FeatureDatetime('today'));
  const [scale, setScale] = React.useState<WidgetScaleType>('HOUR');
  const [select_date_dialog, setSelectDateDialog] = React.useState<boolean>(false);

  const [auto_reload_interval, setAutoReloadInterval] = useState<number>(60000);
  const [enable_auto_reload, setEnableAutoReload] = useState<boolean>(false);

  // 作成用states
  const [graph_dialog_props, setGraphDialogProps] = useState<
    | {
        stream: Stream;
      }
    | undefined
  >(undefined);

  // -- functions --
  // デバイスを読み込む
  const loadDevice = async () => {
    const res = await devicesIdGetAPI(params.match.params.device_id);
    if (res.status === 200) {
      setDevice(res.data);
    }
  };

  // 選択されているstreamが変わった時の関数
  const onChangeStream = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const stream = streams.find((st) => st.stream_id === e.currentTarget.value);
    if (stream) {
      setSelectedStream(stream);
    }
  };

  // stream一覧を取得する関数
  const getOutputStream = async () => {
    const cs = await new CachedStreams({ device_id: params.match.params.device_id }).get();
    if (selected_stream) {
      const new_selected_stream = cs.find((c) => c.stream_id === selected_stream.stream_id);
      setSelectedStream(new_selected_stream);
    }
    setStreams(cs);
  };

  // streamの指定のdevice_graphをトップ固定にする。
  const onPinStreamGraph = async (stream_device_graph_id?: string, stream_id?: string) => {
    const res = await devicesIdPutAPI({
      device_id: params.match.params.device_id,
      stream_device_graph_id: stream_device_graph_id,
      graph_stream_id: stream_id,
    });
    if (res.status === 200) {
      setDevice(res.data);
    }
  };

  // streamの指定のdevice_graphを削除(非表示)する関数
  const onDeleteStreamGraph = async (stream_graph: StreamDeviceGraph, stream?: Stream) => {
    if (!stream) return;
    let new_stream_graphs: StreamDeviceGraph[] = [];
    if (stream.device_graphs) {
      new_stream_graphs = [...stream.device_graphs];
      // 引数で渡されたstream_graphを配列から排除する
      new_stream_graphs = new_stream_graphs.filter((nsg) => nsg.id !== stream_graph.id);
    }
    const res = await streamsIdPutAPI({
      stream_id: stream.stream_id,
      device_graphs: new_stream_graphs,
    });
    if (res.status === 200) {
      await getOutputStream();
    }
  };

  // device_graphを削除(非表示)する関数を確認付きでおこなう
  const confirmDeleteStreamGraph = (stream_graph: StreamDeviceGraph, stream?: Stream) => {
    ConfirmDialog.show(
      <div>
        こちらのグラフを非表示にします。
        <br />
        よろしいですか?
      </div>,
      async () => await onDeleteStreamGraph(stream_graph, stream),
      () => {},
    );
  };

  // グラフを選択するダイアログを開く
  const onGraphDialogOpen = () => {
    if (!selected_stream) return;
    setGraphDialogProps({
      stream: selected_stream,
    });
  };

  // グラフを選択するダイアログを閉じる
  const onGraphDialogClose = async (isCanceled?: boolean) => {
    setGraphDialogProps(undefined);
    if (isCanceled) return;
    await getOutputStream();
  };

  // scaleを変更する関数
  const onScaleChanged = (rangeType: WidgetScaleType) => {
    setScale(rangeType);
  };

  // 日付を選択するダイアログを開く関数
  const onSelectDateDialogOpen = () => {
    setSelectDateDialog(true);
  };

  // 日付を選択するダイアログを閉じる際の関数
  const onSelectDateDialogClose = async (isCanceled: boolean, start?: FeatureDatetime, end?: FeatureDatetime) => {
    setSelectDateDialog(false);
    if (isCanceled || !start || !end) return;
    setStart(start);
    setEnd(end);
  };

  // auto reloadのintervalを変更する関数(自動的にenableとする)
  const onAutoReloadIntervalChange = (ari: number) => {
    setAutoReloadInterval(ari);
    setEnableAutoReload(true);
  };

  // -- onload function --
  useEffect(() => {
    (async function () {
      await loadDevice();
      await getOutputStream();
    })();
  }, []); /* eslint-disable-line */

  // -- render part --
  return (
    <Content>
      <TopArea>
        <SelectBox
          value={selected_stream?.stream_id || ''}
          datas={convertStreamsToSelectDatas(streams)}
          onChange={onChangeStream}
        />
        <FlexCenterDiv style={{ width: '100%', justifyContent: 'flex-end' }} align_center={true}>
          <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={onSelectDateDialogOpen}>
            {getDateLabel(start, end)}
          </span>
          <WidgetScaler value={scale} onChange={onScaleChanged} />
          <AutoReloadButton
            auto_reload_interval={auto_reload_interval}
            enable_auto_reload={enable_auto_reload}
            onAutoReloadChange={setEnableAutoReload}
            onAutoReloadIntervalChange={onAutoReloadIntervalChange}
          />
          <RoundedButton
            text='グラフ選択'
            onClick={onGraphDialogOpen}
            disabled={selected_stream === undefined}
            is_margin_left={true}
          />
        </FlexCenterDiv>
      </TopArea>
      {device ? (
        <FlexCenterDiv style={{ flexWrap: 'wrap', width: '100%' }}>
          {selected_stream?.device_graphs &&
            selected_stream.device_graphs.map((dg, i) => {
              return (
                <CameraAnalysisResultWrapper
                  {...params}
                  device={device}
                  start={start}
                  end={end}
                  scale={dg.graph_type === 'TABLE' ? 'HOUR' : scale}
                  hide_scale={dg.graph_type === 'TABLE'}
                  stream_graph={dg}
                  key={i}
                  auto_reload_interval={enable_auto_reload ? auto_reload_interval : -1}
                  stream={selected_stream}
                  onStreamGraphDelete={() => confirmDeleteStreamGraph(dg, selected_stream)}
                  onPinStreamGraph={onPinStreamGraph}
                />
              );
            })}
        </FlexCenterDiv>
      ) : (
        <Spinner />
      )}
      {graph_dialog_props && (
        <DetailDeviceStreamGraphsDialog
          stream={graph_dialog_props.stream}
          isOpen={graph_dialog_props !== undefined}
          onClose={onGraphDialogClose}
        />
      )}
      {select_date_dialog && (
        <DatetimeRangeDialog start={start} end={end} onClose={onSelectDateDialogClose} is_open={select_date_dialog} />
      )}
    </Content>
  );
};

// -- styled components --

// -- finally export part --
export default CameraAnalysisResult;
