// -- basic library --
import React, { useState } from 'react';
import RoundedAnchorButton from 'shared/components/atoms/RoundedAnchorButton';
// -- redux library --

// -- http connection library --
import CSVTable from './CSVTable';
import { PageWholeArea, Title } from 'shared/components/molecules/ContentsArea';
import SelectBox from 'shared/components/atoms/SelectBox';
import { getBillingSummariesDataDownloadGetAPIUrl } from 'user/api/billingSummary';
import { dateFormat, firstYearmonth, yearmonthList } from 'shared/utils/else/billingYearMonth';
import styled from 'styled-components';
import styles from 'shared/styles/styles';
import { RouteComponentProps } from 'react-router-dom';

// -- external functions --
type Params = RouteComponentProps<{
  yearmonth?: string;
}>;

// -- main component --
const BillingCsv: React.FC<Params> = (params) => {
  const [yearmonth, setYearmonth] = useState<string>( params.match.params.yearmonth || firstYearmonth);
  const yearmonth_list: string[] = yearmonthList();

  // -- render part --
  return (
    <PageWholeArea data-testid='Billings'>
      <TopArea>
        <Title text='請求照会画面' />
        <SelectBox
          onChange={(e) => setYearmonth(e.currentTarget.value)}
          value={yearmonth}
          datas={yearmonth_list.map((e) => {
            return {
              name: dateFormat(e),
              value: e,
            };
          })}
        />
      </TopArea>
      <CSVTable yearmonth={yearmonth} />
      <BottomArea
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <RoundedAnchorButton
          text='CSVダウンロード'
          href={getBillingSummariesDataDownloadGetAPIUrl({
            yearmonth: yearmonth,
          })}
        />
      </BottomArea>
    </PageWholeArea>
  );
};

// -- styled components --
const TopArea = styled.div`
  width: 50%;
  height: 10%;
  display: flex;
  align-items: center;
  margin-bottom: ${styles.interval_margin};
`;

const BottomArea = styled.div`
  width: 50%;
  height: auto;
  display: block;
`;

// -- finally export part --

export default BillingCsv;
