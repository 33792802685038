import { DashboardWidgetMetric } from 'user/api/dashboardsWidgets';
import React from 'react';
import DateRangeWidgetBase from './base/DateRangeWidgetBase';
import { WidgetProps } from './getWidgetType';

/**
 * ライブテキストウィジェット
 */
interface LiveTextWidgetState {
  metric?: DashboardWidgetMetric;
}
export default class LiveTextWidget extends React.PureComponent<WidgetProps, LiveTextWidgetState> {
  private elementRef = React.createRef<HTMLDivElement>();
  constructor(props: WidgetProps) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.runScript();
  }
  componentDidUpdate() {
    this.runScript();
  }
  private runScript = () => {
    try {
      // eslint-disable-next-line no-new-func
      const func = Function('dom', 'metric', this.props.widget.html_script || '');
      func(this.elementRef.current, this.state.metric);
    } catch (e) {
      console.log(e);
    }
  };
  private handleMetricsChanged = (metric: DashboardWidgetMetric) => {
    this.setState({ metric });
  };

  private onInitMetrics = () => {
    this.setState({ metric: undefined });
  };

  render() {
    return (
      <DateRangeWidgetBase
        widget={this.props.widget}
        state={this.props.state}
        hide_scale={true}
        onInitMetrics={this.onInitMetrics}
        onStateChanged={this.props.onStateChanged}
        onMetricsChanged={this.handleMetricsChanged}
        onScaleChanged={() => {}}
      >
        <div
          ref={this.elementRef}
          style={{ width: '100%', height: '100%' }}
          dangerouslySetInnerHTML={{ __html: this.props.widget.html || '' }}
        />
      </DateRangeWidgetBase>
    );
  }
}
