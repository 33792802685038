import React, { useState } from 'react';
import { IAREA } from './Config';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import { Footer } from 'shared/components/molecules/ContentsArea';
import DrawPolygonObject from 'shared/components/molecules/DrawPolygons';
import PfDialog from 'shared/components/atoms/PfDialog';
import styles from 'shared/styles/styles';

interface AreaEditModalProps {
  value: IAREA[];
  image_url?: string;
  onClose: (value?: IAREA[]) => void;
}
export function AreaEditModal(params: AreaEditModalProps) {
  const [stayareas, setStayareas] = useState<number[][][]>(params.value.map((cl) => cl.co));
  const handleSave = () => {
    params.onClose(
      stayareas.map((d, i) => {
        return { name: `AREA${i}`, co: d };
      }),
    );
  };
  const handleAreasChange = (stayareas: number[][][]) => {
    setStayareas(stayareas);
  };
  return (
    <>
      <PfDialog
        isOpen={params.image_url != undefined}
        title='滞留エリア設定'
        onClose={() => params.onClose()}
        contentProps={{ style: { display: 'flex', flexDirection: 'column' } }}
        large
      >
        {params.image_url && (
          <DrawPolygonObject imgsrc={params.image_url} polygons={stayareas} onChange={handleAreasChange} />
        )}
        <Footer
          style={{
            marginTop: styles.interval_narrow_margin,
          }}
        >
          <RoundedButton
            text='キャンセル'
            onClick={() => params.onClose()}
            is_white={true}
            is_margin_right={true}
            style={{
              width: styles.small_button_width,
            }}
          />
          <RoundedButton
            text='保存'
            onClick={handleSave}
            style={{
              width: styles.small_button_width,
            }}
          />
        </Footer>
      </PfDialog>
    </>
  );
}

interface AreaEditModalButtonProps {
  value: IAREA[];
  image_url?: string;
  onChange: (value: IAREA[]) => void;
}
export function AreaEditModalButton(params: AreaEditModalButtonProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = (value?: IAREA[]) => {
    setIsOpen(false);
    if (value !== undefined) {
      params.onChange(value);
    }
  };
  return (
    <>
      <RoundedButton text='設定' onClick={handleOpen} />
      {isOpen && params.image_url && (
        <AreaEditModal value={params.value} image_url={params.image_url} onClose={handleClose} />
      )}
    </>
  );
}
