import React from 'react';
import L, { LatLng } from 'leaflet';
import { MapContainer, TileLayer, /*Marker, */ Popup, Polyline, CircleMarker } from 'react-leaflet';
import styled from 'styled-components';
import 'leaflet/dist/leaflet.css';
// iconの画像を読み込む為にpropsを上書きする必要あり(leafletこういうところ修正してほしいと感じている)
// https://www.ipride.co.jp/blog/3425
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
/* eslint-disable */
delete (L.Icon.Default.prototype as any)._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});
/* eslint-disable */

export interface StreetMapData {
  date: string;
  lat: number;
  lng: number;
}

interface StreetMapProps {
  street_map_datas: StreetMapData[];
  center?: LatLng;
  start_text?: string;
  goal_text?: string;
}

interface StreetMapState {
  latlng: LatLng[];
}
/**
 * マップ描画ウィジェットです。
 */
export default class StreetMap extends React.PureComponent<StreetMapProps, StreetMapState> {
  constructor(props: StreetMapProps) {
    super(props);
    this.state = {
      latlng: [],
    };
  }

  componentDidMount() {
    this.setLatLng();
  }
  componentDidUpdate(prevProps: StreetMapProps) {
    if (this.props.street_map_datas !== prevProps.street_map_datas) {
      this.setLatLng();
    }
  }

  private setLatLng = () => {
    const new_latlng = this.props.street_map_datas.map((street_map_data) => {
      return new LatLng(street_map_data.lat, street_map_data.lng);
    });
    this.setState({ latlng: new_latlng });
  };

  render() {
    // defaultは東京駅
    const center = this.props.center || new LatLng(35.68055479159759, 139.767971314588);
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <CustomMapContainer center={center} zoom={13}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />
          {/* <Marker position={center}>
            <Popup>Tokyo Station</Popup>
          </Marker> */}
          {this.state.latlng.length ? (
            <CircleMarker center={this.state.latlng[0]} pathOptions={{ color: 'black' }} radius={5}>
              {this.props.start_text ? <Popup>this.props.start_text</Popup> : null}
            </CircleMarker>
          ) : null}
          {this.state.latlng.length > 1 ? (
            <CircleMarker
              center={this.state.latlng[this.state.latlng.length - 1]}
              pathOptions={{ color: 'black' }}
              radius={5}
            >
              {this.props.goal_text ? <Popup>this.props.goal_text</Popup> : null}
            </CircleMarker>
          ) : null}
          <Polyline pathOptions={{ color: 'rgb(49,104,203)' }} positions={this.state.latlng} />
        </CustomMapContainer>
      </div>
    );
  }
}

const CustomMapContainer = styled(MapContainer)`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

// 東京駅からIntelligence Designまでのルート(サンプルデータ)
// const polyline = [
// new LatLng(35.68055479159759, 139.767971314588),
// new LatLng(35.678449579678606, 139.7623276825056),
// new LatLng(35.678658740965886, 139.76061106889736),
// new LatLng(35.67524237128977, 139.75958110073242),
// new LatLng(35.67747348642241, 139.7534871224232),
// new LatLng(35.677194600442, 139.74988223384588),
// new LatLng(35.672662566662645, 139.74584819186654),
// new LatLng(35.67189558163886, 139.74395991689747),
// new LatLng(35.666735672646254, 139.73838092267073),
// new LatLng(35.66534104539517, 139.73640681702125),
// new LatLng(35.66164516535883, 139.7296261932687),
// new LatLng(35.659832029585615, 139.72456218312442),
// new LatLng(35.658367543713766, 139.71005679813484),
// new LatLng(35.6582280674681, 139.70430614254724),
// new LatLng(35.658088590978856, 139.70301868234105),
// new LatLng(35.663318792607726, 139.70216037553695),
// new LatLng(35.66464372263374, 139.70233203689776),
// new LatLng(35.66778162709661, 139.7049069573101),
// new LatLng(35.66800977501836, 139.7047084874227),
// new LatLng(35.667450507896255, 139.7043294789055),
// new LatLng(35.667573044286264, 139.70402781906526),
// new LatLng(35.667393952575864, 139.70386925427746),
// ]
