// -- basic library --
import React, { useEffect, useState } from 'react';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import CheckBoxWithText from 'shared/components/molecules/CheckBoxWithText';
import { Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import styles from 'shared/styles/styles';
import { getStreamGraphTitle, StreamDeviceGraph } from 'shared/models/StreamDeviceGraph';
import { isBetweenRange } from 'shared/utils/is';
import styled from 'styled-components';
import { Stream, streamsIdPutAPI } from 'user/api/streams';
import { streamsIdGraphsCandidatesGetAPI } from 'user/api/streamsGraphs';

// -- types --
interface DetailDeviceStreamGraphsDialogProps {
  isOpen: boolean;
  onClose: (isCanceled: boolean) => void;
  stream: Stream;
}

interface CheckboxGraphType extends StreamDeviceGraph {
  checked: boolean;
}

// -- main component --
const DetailDeviceStreamGraphsDialog: React.FC<DetailDeviceStreamGraphsDialogProps> = (params) => {
  // -- local states --
  // const [stream, setStream] = useState<Stream | undefined>(undefined)
  const [checkbox_graphs, setCheckboxGraphs] = useState<CheckboxGraphType[] | undefined>(undefined);

  // チェックボックスを更新
  const updateCheckboxGraphs = (index: number, checked: boolean) => {
    if (checkbox_graphs === undefined) return;
    const new_checkbox_graph = [...checkbox_graphs];
    if (isBetweenRange(index, 0, checkbox_graphs.length - 1)) {
      new_checkbox_graph[index].checked = checked;
    }
    setCheckboxGraphs(new_checkbox_graph);
  };

  // グラフの候補を取得して、ロードする。
  // この時、すでにチェック済みのデータはチェックにする
  const loadCheckboxGraphs = async (stream_id: string) => {
    const res = await streamsIdGraphsCandidatesGetAPI({
      stream_id: stream_id,
    });
    if (res.status !== 200) {
      setCheckboxGraphs([]);
      return;
    }

    const new_checkbox_graphs: CheckboxGraphType[] = res.data.graph_candidates.map((dgc) => {
      const checked: boolean = params.stream.device_graphs
        ? !!params.stream.device_graphs.find((sdg) => sdg.id === dgc.id)
        : false;
      return {
        checked: checked,
        ...dgc,
      };
    });
    setCheckboxGraphs(new_checkbox_graphs);
  };

  const handleFinishClick = async () => {
    if (checkbox_graphs === undefined) return;
    const checked_graphs = checkbox_graphs.filter((cg) => cg.checked);
    const new_device_graphs: StreamDeviceGraph[] = checked_graphs.map((cg) => {
      // checkedを除外したオブジェクトを返却する
      const { checked: _checked, ...par } = cg;
      return par;
    });
    const res = await streamsIdPutAPI({
      stream_id: params.stream.stream_id,
      device_graphs: new_device_graphs,
    });
    if (res.status === 200) {
      AlertDialog.show('データの更新に成功しました');
      params.onClose(false);
    }
  };

  // -- onload function --
  useEffect(() => {
    (async function () {
      // プロセス情報の取得
      await loadCheckboxGraphs(params.stream.stream_id);
    })();
  }, []); /* eslint-disable-line */

  // -- render part --
  return (
    <PfDialog isOpen={params.isOpen} title='グラフ選択' onClose={() => params.onClose(true)}>
      <InputComponent text='表示するグラフを選択'>
        <CheckboxesWrap>
          {checkbox_graphs !== undefined ? (
            checkbox_graphs.map((cg, index) => {
              return (
                <CheckBoxWithText
                  text={getStreamGraphTitle({ ...cg })}
                  checked={cg.checked}
                  onClick={() => updateCheckboxGraphs(index, !cg.checked)}
                  key={`${cg.id}-${index}`}
                  style={{
                    margin: `${styles.interval_narrow_margin} 0px`,
                  }}
                />
              );
            })
          ) : (
            <Spinner />
          )}
        </CheckboxesWrap>
      </InputComponent>
      <Footer>
        <RoundedButton
          onClick={handleFinishClick}
          text_type='UPDATE'
          disabled={!checkbox_graphs || checkbox_graphs.length === 0}
        />
      </Footer>
    </PfDialog>
  );
};

// -- finally export part --

export default DetailDeviceStreamGraphsDialog;

const CheckboxesWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
