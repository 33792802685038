import { memo } from 'react';
import { colors } from 'shared/styles/colors';
import styled from 'styled-components';

/*** 直線に対する垂線の凡例 ***/
const CrossLineLegend = memo(function CrossLineLegend() {
  return (
    <ManageLines>
      <Legend color={colors.crosslineLRColor} style={{ marginRight: 30 }}>
        黄色の垂線: LR(左から右)
      </Legend>
      <Legend color={colors.crosslineRLColor}>青色の垂線: RL(右から左)</Legend>
    </ManageLines>
  );
});

const ManageLines = styled.div`
  display: flex;
  padding: 3px;
`;

const Legend = styled.div<{
  color: string;
}>`
  font-size: larger;
  font-weight: bold;
  color: ${(p) => p.color};
`;

export default CrossLineLegend;
