import { CommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';
import { ProcessAppParameterInputType } from 'shared/models/ProcessAppParameterInputType';
import { ProcessCategoryType } from 'shared/models/ProcessCategoryType';
import { Query } from 'shared/models/Query';
import sendAxios from '../../shared/axios/sendAxios';
import { getClient } from './base';

/*** エンティティ ***/
export interface ProcessAppParameter {
  key: string;
  name: string;
  description: string;
  default_value: string;
  input_type: ProcessAppParameterInputType;
  list_items: string[];
  required: boolean;
  validate_regax: string;
}
export interface ProcessHeartbeat {
  key: string;
  name: string;
  description: string;
  default_schedule: string;
  required: boolean;
}

export interface Process {
  tenant_id: string;
  process_id: string;
  process_name: string;
  status: string;
  input_stream_count: number;
  input_stream_constraints: {
    data_type: string; // 複数カンマ区切り
    data_number_type: string;
    max_repeats: number;
  }[];
  output_stream_count: number;
  app_parameters: ProcessAppParameter[];
  heartbeats: ProcessHeartbeat[];
  can_be_oneshot: boolean;
}

const sample_process1: Process = {
  tenant_id: '001',
  process_id: '001',
  process_name: '001',
  status: 'ACTIVE',
  input_stream_count: 1,
  input_stream_constraints: [
    {
      data_type: 'VIDEO,IMAGE', // 複数カンマ区切り
      data_number_type: 'TIMESTAMP,SEQUENCE,USER_SPECIFIC',
      max_repeats: 0,
    },
  ],
  output_stream_count: 0,
  app_parameters: [
    {
      key: 'STAYAREA',
      name: 'STAYAREA',
      description: 'STAYAREA',
      default_value: '',
      input_type: 'STAYAREA',
      list_items: [],
      required: false,
      validate_regax: '',
    },
  ],
  heartbeats: [],
  can_be_oneshot: true,
};

const sample_process2: Process = {
  tenant_id: '001',
  process_id: '002',
  process_name: '002',
  status: 'ACTIVE',
  input_stream_count: 1,
  input_stream_constraints: [
    {
      data_type: 'VIDEO,IMAGE', // 複数カンマ区切り
      data_number_type: 'TIMESTAMP,SEQUENCE,USER_SPECIFIC',
      max_repeats: 0,
    },
  ],
  output_stream_count: 0,
  app_parameters: [
    {
      key: 'CROSSLINE',
      name: 'CROSSLINE',
      description: 'CROSSLINE',
      default_value: '',
      input_type: 'CROSSLINE',
      list_items: [],
      required: false,
      validate_regax: '',
    },
  ],
  heartbeats: [],
  can_be_oneshot: true,
};

const sample_proces3: Process = {
  tenant_id: '001',
  process_id: '003',
  process_name: '003',
  status: 'ACTIVE',
  input_stream_count: 1,
  input_stream_constraints: [
    {
      data_type: 'VIDEO,IMAGE', // 複数カンマ区切り
      data_number_type: 'TIMESTAMP,SEQUENCE',
      max_repeats: 0,
    },
  ],
  output_stream_count: 0,
  app_parameters: [
    {
      key: 'CROSSLINE2',
      name: 'CROSSLINE2',
      description: 'CROSSLINE2',
      default_value: '',
      input_type: 'CROSSLINE2',
      list_items: [],
      required: false,
      validate_regax: '',
    },
  ],
  heartbeats: [],
  can_be_oneshot: true,
};

const sample_proces4: Process = {
  tenant_id: '001',
  process_id: '004',
  process_name: '004',
  status: 'ACTIVE',
  input_stream_count: 0,
  input_stream_constraints: [],
  output_stream_count: 0,
  app_parameters: [
    {
      key: 'CSVROWS',
      name: 'CSVROWS',
      description: 'CSVROWS',
      default_value: '',
      input_type: 'CSVROWS',
      list_items: [],
      required: false,
      validate_regax: '',
    },
  ],
  heartbeats: [],
  can_be_oneshot: true,
};

export interface ProcessesWithPaging {
  items: Process[];
  has_next: boolean;
}

/*** Caching mechanism ***/

interface CachedProcessesProps {
  can_be_oneshot?: boolean; // ワンショットプロセスとなるかどうか
  first_input_stream_data_type?: string; // 最初の入力データ(ストリーム)のデータ種別
  include_input_stream_data_type?: string; // 含まれる入力データ(ストリーム)のデータ種別
  first_input_stream_data_number_type?: string; // 最初の入力データ(ストリーム)のデータ番号の基準
  category?: ProcessCategoryType;
  visibility?: 'all';
}
export class CachedProcesses {
  private searched = false;
  private cache: Process[] = [];
  private params: CachedProcessesProps;
  constructor(params: CachedProcessesProps) {
    this.params = params;
  }
  async get() {
    if (!this.searched) {
      let processes = await getAllProcesses(this.params.category, this.params.visibility);
      if (this.params.can_be_oneshot !== undefined) {
        processes = processes.filter((p) => p.can_be_oneshot === this.params.can_be_oneshot);
      }
      if (this.params.first_input_stream_data_type !== undefined) {
        const first_input_stream_data_type = this.params.first_input_stream_data_type;
        processes = processes.filter(
          (p) =>
            p.input_stream_constraints.length !== 0 &&
            p.input_stream_constraints[0].data_type.indexOf(first_input_stream_data_type) !== -1,
        );
      }
      if (this.params.include_input_stream_data_type !== undefined) {
        const include_input_stream_data_type = this.params.include_input_stream_data_type;
        processes = processes.filter(
          (p) =>
            p.input_stream_constraints.filter((c) => c.data_type.indexOf(include_input_stream_data_type) !== -1)
              .length !== 0,
        );
      }
      if (this.params.first_input_stream_data_number_type !== undefined) {
        const first_input_stream_data_number_type = this.params.first_input_stream_data_number_type;
        processes = processes.filter(
          (p) =>
            p.input_stream_constraints.length !== 0 &&
            p.input_stream_constraints[0].data_number_type.indexOf(first_input_stream_data_number_type) !== -1,
        );
      }
      this.cache = processes;
      this.searched = true;
    }
    return this.cache;
  }
}

/*** [GET] /api/processes ***/

export interface RequestProcessesGet extends CommonAPIRequestType {
  status?: string;
  exclusive_start_process_id?: string;
  exclusive_start_owned_process_id?: string;
  category?: ProcessCategoryType;
  visibility?: 'all';
}

export const processesGetAPI = (params: RequestProcessesGet) => {
  const {
    status,
    exclusive_start_owned_process_id,
    exclusive_start_process_id,
    category,
    visibility,
    disabled_error_message,
    disabled_load,
    ended_load,
  } = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_error_message, disabled_load, ended_load });

  // パス・メソッドを定義
  const path = `/api/processes`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    status,
    exclusive_start_owned_process_id,
    exclusive_start_process_id,
    category,
    visibility,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<ProcessesWithPaging>(axios, path, query, form, method, {
    items: [sample_process1, sample_process2, sample_proces3, sample_proces4],
    has_next: false,
  });
};

/** 読み取り可能な全プロセス情報を取得します。 */
export async function getAllProcesses(category?: ProcessCategoryType, visibility?: 'all') {
  // [TODO] APIを必要に応じた回数だけ叩く
  // 暫定仕様で、可能な限りデータを取得している
  let process_items: Process[] = [];
  let has_next = true;
  let exclusive_start_key = '';
  let exclusive_start_owned_key = '';
  while (has_next) {
    const res = await processesGetAPI({
      status: 'ACTIVE',
      exclusive_start_process_id: exclusive_start_key,
      exclusive_start_owned_process_id: exclusive_start_owned_key,
      category: category,
      visibility: visibility,
    });
    has_next = false;
    if (res.status === 200) {
      process_items = process_items.concat(res.data.items);
      has_next = res.data.has_next;
      exclusive_start_key = res.data.items
        .filter((d) => d.tenant_id === 'all')
        .map((d) => d.process_id)
        .reduce((a, b) => (a > b ? a : b), '');
      exclusive_start_owned_key = res.data.items
        .filter((d) => d.tenant_id !== 'all')
        .map((d) => d.process_id)
        .reduce((a, b) => (a > b ? a : b), '');
    }
  }
  return process_items;
}
