import React from 'react';
import FunctionalText from 'shared/components/atoms/FunctionalText';
import InputBox from 'shared/components/atoms/InputBox';
import { MailAdress } from 'shared/models/MailAdress';
import styles from 'shared/styles/styles';

interface MultiMailAddressInputProps<T> {
  mail_addresses: MailAdress<T>[];
  onChange: (mail_addresses: MailAdress<T>[]) => void;
}
/** メールアドレス（複数追加可能）入力 */
export default class MultiMailAddressInput<T> extends React.PureComponent<MultiMailAddressInputProps<T>> {
  private handleMailAddressesChange = (value: string, index: number) => {
    const new_mail_addresses = [...this.props.mail_addresses];
    if (new_mail_addresses.length > index) {
      new_mail_addresses[index] = { ...new_mail_addresses[index], mail_address: value };
    }
    this.props.onChange(new_mail_addresses);
  };
  private handleAddMailAddress = () => {
    const new_mail_addresses: MailAdress<T>[] = [...this.props.mail_addresses, { mail_address: '' }] as MailAdress<T>[];
    this.props.onChange(new_mail_addresses);
  };
  render() {
    return (
      <>
        {this.props.mail_addresses.map((m, index) => (
          <InputBox
            title='メールアドレス'
            placeholder='メールアドレス'
            value={m.mail_address}
            onChange={(e) => this.handleMailAddressesChange(e.currentTarget.value, index)}
            key={index}
            style={{
              marginBottom: styles.interval_narrow_margin,
            }}
          />
        ))}
        <FunctionalText
          onClick={this.handleAddMailAddress}
          text='+メールアドレスを追加'
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        />
      </>
    );
  }
}
