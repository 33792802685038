import { SelectData } from 'shared/components/atoms/SelectBox';

/** CSV作成時の時間単位 */
export type ExportCsvTimeUnit = 'MINUTE' | 'HOUR' | 'DAY' | 'MONTH';
export const ExportCsvTimeUnites: ExportCsvTimeUnit[] = ['MINUTE', 'HOUR', 'DAY', 'MONTH'];
export function convertExportCsvTimeUnit(s?: string, undef?: boolean) {
  for (const v of ExportCsvTimeUnites) if (v === s) return s;
  return undef ? undefined : ExportCsvTimeUnites[0];
}

export const exportCsvTimeUnitToName = (time_unit: ExportCsvTimeUnit) => {
  let name = '';
  switch (time_unit) {
    case 'MINUTE':
      name = '1分';
      break;
    case 'HOUR':
      name = '1時間';
      break;
    case 'DAY':
      name = '1日';
      break;
    case 'MONTH':
      name = '1ヶ月';
      break;
  }
  return name;
};

export const convertExportCsvTimeUnitToSelectDatas = () => {
  const select_datas: SelectData[] = ExportCsvTimeUnites.map((ctu) => {
    return {
      name: exportCsvTimeUnitToName(ctu),
      value: ctu,
    };
  });
  return select_datas;
};
