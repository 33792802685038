import React from 'react';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import SelectBox from 'shared/components/atoms/SelectBox';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import { CachedChannels, Channel, channelsIdGetAPI } from 'user/api/channels';
import { channelsIdProcessesPostAPI } from 'user/api/channelsProcesses';
import { CachedProcesses } from 'user/api/processes';
import { Stream } from 'user/api/streams';
import { StreamData } from 'user/api/streamsData';
import InputChannelProcessForm, { ChannelProcessValue } from './InputChannelProcessForm';

interface CreateOneshotChannelProcessDialogProps {
  stream: Stream;
  stream_data: StreamData;
  channel_id?: string;
  isOpen: boolean;
  onClose: (canceled?: boolean) => void;
}
interface CreateOneshotChannelProcessDialogState {
  channels: Channel[];
  channel_id: string;
  channel_process_value: ChannelProcessValue;
  is_channel_process_value_valid: boolean;
}
/**
 * ストリームからワンショットのチャンネルプロセスを登録するダイアログです。
 */
export default class CreateOneshotChannelProcessDialog extends React.PureComponent<
  CreateOneshotChannelProcessDialogProps,
  CreateOneshotChannelProcessDialogState
> {
  constructor(props: CreateOneshotChannelProcessDialogProps) {
    super(props);
    // const selected_stream_ids: string[][] = [];
    // selected_stream_ids.push([this.props.streamData.stream_id])
    this.state = {
      channels: [],
      channel_id: '',
      channel_process_value: {
        channel_process_name: '',
        process_id: '',
        selected_stream_ids: [],
        app_parameter: {},
        heartbeat: {},
      },
      is_channel_process_value_valid: false,
    };
  }
  componentDidMount() {
    this.loadChannels();
  }
  /** チャンネルをロードします。 */
  private async loadChannels() {
    const channels: Channel[] = [];
    if (this.props.channel_id) {
      // channel_idが指定されれば、それのみを格納
      const res = await channelsIdGetAPI({ channel_id: this.props.channel_id });
      if (res.status === 200) {
        channels.push(res.data);
      }
      this.setState({
        channel_id: this.props.channel_id,
      });
    } else if (this.props.stream.listing_visibility === 'IN_CHANNEL' && this.props.stream.output_channel_process) {
      // ストリームがチャンネル内部ストリームの場合は、そのチャンネルに限定される
      const c = this.props.stream.output_channel_process.split(':');
      const res = await channelsIdGetAPI({ channel_id: c[0] });
      if (res.status === 200) {
        channels.push(res.data);
      }
      this.setState({ channel_id: c[0] });
    } else {
      channels.push(...(await new CachedChannels({ channel_type: 'STANDARD' }).get()));
    }
    this.setState({ channels: channels });
    // 対象チャンネルが1つしかない場合はそれを選択する
    if (channels.length === 1) {
      this.setState({ channel_id: channels[0].channel_id });
    }
  }
  private handleFinishClick = async () => {
    const res = await channelsIdProcessesPostAPI({
      channel_id: this.state.channel_id,
      channel_process_name: this.state.channel_process_value.channel_process_name,
      process_id: this.state.channel_process_value.process_id,
      input_stream_ids: this.state.channel_process_value.selected_stream_ids,
      app_parameter: this.state.channel_process_value.app_parameter,
      heartbeat: this.state.channel_process_value.heartbeat,
      is_oneshot: 'True',
      input_stream_data_number: String(this.props.stream_data.stream_data_number),
    });
    if (res.status === 200) {
      this.props.onClose();
      AlertDialog.show('新規チャンネルプロセスの追加に成功しました');
    }
  };
  private isFinishDisabled = () => {
    return this.state.channel_id === '' || !this.state.is_channel_process_value_valid;
  };
  private handleInputChannelProcessFormChange = (v: ChannelProcessValue, isValid: boolean) => {
    this.setState({ channel_process_value: v, is_channel_process_value_valid: isValid });
  };
  render() {
    return (
      <PfDialog title='チャンネルプロセス作成' isOpen={this.props.isOpen} onClose={() => this.props.onClose(true)}>
        <InputComponent text='チャンネル' required>
          <SelectBox
            onChange={(e) => this.setState({ channel_id: e.currentTarget.value })}
            value={this.state.channel_id}
            default_text={this.state.channels.length === 1 ? 'null' : undefined}
            datas={this.state.channels.map((e) => {
              return { name: e.channel_name, value: e.channel_id };
            })}
          />
        </InputComponent>
        <InputChannelProcessForm
          stream_id={this.props.stream.stream_id}
          processes={
            new CachedProcesses({
              can_be_oneshot: true,
              include_input_stream_data_type: this.props.stream.data_type,
              first_input_stream_data_number_type: this.props.stream.data_number_type,
            })
          }
          value={this.state.channel_process_value}
          fixed_matched_input_stream={true}
          stream_data_number={this.props.stream_data.stream_data_number}
          onChange={this.handleInputChannelProcessFormChange}
        />
        <Footer>
          <RoundedButton text_type='CREATE' onClick={this.handleFinishClick} disabled={this.isFinishDisabled()} />
        </Footer>
      </PfDialog>
    );
  }
}
