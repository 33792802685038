import { Stream } from 'user/api/streams';

/** ストリームデータの種類がビデオまたはイメージかどうかを判定します。 */
export default function getDlpkgLabel(stream: Stream) {
  if (stream.data_type === 'METRIC') {
    return '集計ファイル';
  } else if (stream.data_type === 'VIDEO') {
    return 'ビデオファイル';
  } else if (stream.data_type === 'IMAGE') {
    return '画像ファイル';
  } else {
    return 'ダウンロードパッケージ';
  }
}
