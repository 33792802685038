import React from 'react';
import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import FunctionalText from 'shared/components/atoms/FunctionalText';
import { DeleteIcon, InformationIcon } from 'shared/components/atoms/Icons';
import PfProgressBar from 'shared/components/atoms/PfProgressBar';
import RoundedCard from 'shared/components/atoms/RoundedCard';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import { ProcessOutputType } from 'shared/models/ProcessOutputType';
import { getMinProcessingProgress } from 'user/utils/fileCardDisplayingProcessFunctions';
import {
  getProgressTextFromProcessInfos,
  getOutputResultDisplayName,
} from 'user/utils/fileCardDisplayingProcessFunctions';
import styled from 'styled-components';
import { Stream } from 'user/api/streams';
import { StreamData } from 'user/api/streamsData';
import StreamThumbnailImage from '../../../../components/molecules/StreamThumbnailImage';
import Popover from 'shared/components/atoms/Popover';
import { ProcessInfo, ProcessInfoOutput, streamsIdDataNumberProcessinfoGetAPI } from 'user/api/streamsProcessInfo';

/**
 * フォルダチャンネル/ファイル一覧で表示するストリームデータを表示するカード
 */
interface FileCardDisplayingProcessProps {
  stream: Stream;
  stream_data: StreamData;
  handleStreamDataDeleteMenuClick: (processinfos?: ProcessInfo[]) => void;
  handleApplyChannelProcessMenuClick: () => void;
  handleResultDialogOpen: (p: {
    stream_package_number?: number;
    stream_id?: string;
    stream_data_number?: number;
    original_file_name?: string;
    output_type: ProcessOutputType;
    process_infos?: ProcessInfo[];
    output: ProcessInfoOutput;
    process_info: ProcessInfo;
  }) => void;
  handlePlayClick?: () => void;
  handleEditStreamDataDialogOpen?: () => void;
  handleProcessInfoClick: (p: { channel_id: string; channel_process_number: string | number }) => void;
  handleProcessDeleteClick: (p: { channel_id: string; channel_process_number: string | number }) => void;
}
interface FileCardDisplayingProcessState {
  is_open: boolean;
  is_process_result_open: boolean;
  process_infos?: ProcessInfo[];
  message: string | JSX.Element | string[];
  callback?: () => void;
}
export default class FileCardDisplayingProcess extends React.PureComponent<
  FileCardDisplayingProcessProps,
  FileCardDisplayingProcessState
> {
  constructor(props: FileCardDisplayingProcessProps) {
    super(props);
    this.state = {
      is_open: false,
      is_process_result_open: false,
      message: '',
      process_infos: undefined,
    };
  }
  private getStreamProcessinfo = () => {
    streamsIdDataNumberProcessinfoGetAPI({
      stream_id: this.props.stream.stream_id,
      stream_data_number: this.props.stream_data.stream_data_number,
    }).then((res) => {
      if (res.status === 200) {
        /*** テスト用のデータ(現状実際のAPIからはデータは返ってこない為) ***/
        // this.setState({ processes: [
        //   {
        //     channel_id: '11',
        //     channel_process_name: 'aiueo',
        //     channel_process_number: 2,
        //     stream_id: '000000000015',
        //     stream_package_number: undefined,
        //     stream_data_number: 1,
        //   }
        // ] })
        this.setState({
          process_infos: res.data.processes,
        });
      }
    });
  };
  componentDidMount() {
    this.getStreamProcessinfo();
  }
  componentDidUpdate(prevProps: FileCardDisplayingProcessProps) {
    // propsが変化したら、データを再ロードします
    if (this.props.stream_data !== prevProps.stream_data) {
      this.getStreamProcessinfo();
    }
  }
  onClose() {
    this.setState({ is_open: false, is_process_result_open: false });
    if (this.state.callback) {
      this.state.callback();
    }
  }
  render() {
    const card_style: React.CSSProperties = {
      width: 320,
      height: 360,
      padding: '20px',
      margin: '15px',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
    };
    return (
      <RoundedCard
        style={card_style}
        onClick={this.props.handlePlayClick}
        key={this.props.stream_data.stream_data_number}
        menu={
          <Menu>
            <MenuItem text='データ詳細' onClick={this.props.handleEditStreamDataDialogOpen} />
            <MenuDivider />
            <MenuItem
              text='チャンネルプロセス適用'
              onClick={this.props.handleApplyChannelProcessMenuClick}
              disabled={!this.props.stream_data.is_ready_for_process}
            />
            <MenuDivider />
            <MenuItem
              onClick={() => this.props.handleStreamDataDeleteMenuClick(this.state.process_infos)}
              text='データ削除'
            />
          </Menu>
        }
      >
        <div
          style={{
            height: 240,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <StreamThumbnailImage
            stream_id={this.props.stream.stream_id}
            stream_data_number={this.props.stream_data.stream_data_number}
            style={{ maxWidth: 280, maxHeight: 240 }}
          />
        </div>
        <div style={{ flexGrow: 1 }}>
          {this.props.stream_data.stream_data_number}
          <br />
          {this.props.stream_data.stream_data_name}
          <br />
          {this.props.stream_data.original_file_name}
        </div>
        <ProcessWrap
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Popover
            content={
              <div style={{ width: 300, minHeight: 50, maxHeight: 300, padding: 8, overflow: 'auto' }}>
                {this.state.process_infos
                  ? this.state.process_infos.map((process, i) => {
                      return (
                        <ProcessInformation
                          is_last={this.state.process_infos ? i === this.state.process_infos.length - 1 : true}
                          key={`${i}_${process.channel_process_name}`}
                        >
                          <PIBasics>
                            <ProcessInformationCategory>{process.channel_process_name}</ProcessInformationCategory>
                            <InformationIcon
                              iconSize={16}
                              onClick={() =>
                                this.props.handleProcessInfoClick({
                                  channel_id: process.channel_id,
                                  channel_process_number: process.channel_process_number,
                                })
                              }
                              style={{ marginRight: 10 }}
                            />
                            <DeleteIcon
                              iconSize={16}
                              onClick={() =>
                                this.props.handleProcessDeleteClick({
                                  channel_id: process.channel_id,
                                  channel_process_number: process.channel_process_number,
                                })
                              }
                            />
                          </PIBasics>
                          <PIOutput>
                            {process.outputs.map((output, j) => {
                              return (
                                <PIOutputStream
                                  key={`${j}_${output.stream_data_number}_${output.stream_package_number}`}
                                  onClick={() =>
                                    this.props.handleResultDialogOpen({
                                      stream_package_number: output.stream_package_number,
                                      stream_id: output.stream_id,
                                      stream_data_number: output.stream_data_number,
                                      original_file_name: this.props.stream_data.original_file_name,
                                      output_type: output.output_type,
                                      process_infos: this.state.process_infos,
                                      output: output,
                                      process_info: process,
                                    })
                                  }
                                  style={{ height: 'auto' }}
                                >
                                  {getOutputResultDisplayName({
                                    stream_data_number: output.stream_data_number,
                                    stream_package_number: output.stream_package_number,
                                    output_type: output.output_type,
                                    output_name: output.output_name,
                                    index: j,
                                  })}
                                </PIOutputStream>
                              );
                            })}
                          </PIOutput>
                        </ProcessInformation>
                      );
                    })
                  : null}
              </div>
            }
            minimal={true}
            placement='bottom'
          >
            <DisplayProcessProgress process_infos={this.state.process_infos} />
          </Popover>
        </ProcessWrap>
      </RoundedCard>
    );
  }
}

// -- styled components --

const ProcessWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 3px;
  border-top: 1px solid ${colors.component_small_border_color};
`;
const ProcessInformation = styled.span<{
  is_last?: boolean;
}>`
  width: 100%;
  height: auto;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: ${(params) => (params.is_last ? 'unset' : `1px dashed ${colors.component_small_border_color}`)};
`;

const PIBasics = styled.div`
  width: 100%;
  min-height: 40px;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const PIOutput = styled.ul`
  width: 100%;
  margin: 0px;
`;

const ProcessInformationCategory = styled.div`
  font-size: larger;
  font-weight: bold;
  margin-right: auto;
`;

const PIOutputStream = styled.li`
  margin-bottom: 5px;
  height: 15px;
  cursor: pointer;
  &:hover {
    color: ${colors.component_main_color};
  }
`;

/**
 * 進捗率の情報を表示するコンポーネント
 */
interface DisplayProcessProgressProps {
  process_infos?: ProcessInfo[];
}
class DisplayProcessProgress extends React.PureComponent<DisplayProcessProgressProps> {
  render() {
    // process_infosの中で最小の進捗率
    const min_processing_progress: number = getMinProcessingProgress(this.props.process_infos);
    return (
      <ProcessProgressWrap>
        {this.props.process_infos && this.props.process_infos.length > 0 && (
          <>
            <FunctionalText
              text={getProgressTextFromProcessInfos(min_processing_progress)}
              style={{
                textDecoration: 'none',
                fontSize: styles.normal_text_size,
                marginRight: 15,
              }}
            />
            {min_processing_progress < 100 && (
              <PfProgressBar value={min_processing_progress / 100} animate={false} stripes={false} />
            )}
          </>
        )}
      </ProcessProgressWrap>
    );
  }
}

const ProcessProgressWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
