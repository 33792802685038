export type ChannelEventConEvaluateCondition = {
  operator: ChannelEventConEvaluateConditionOperator;
  values: [number] | [number, number];
};

export type ChannelEventConEvaluateConditionOperator =
  | 'Equal'
  | 'LessThan'
  | 'LessThanOrEqual'
  | 'GreaterThanOrEqual'
  | 'GreaterThan'
  | 'Between';

export const isChannelEventConEvaluateConditionOperator = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
): data is ChannelEventConEvaluateConditionOperator =>
  data === 'Equal' ||
  data === 'LessThan' ||
  data === 'LessThanOrEqual' ||
  data === 'GreaterThanOrEqual' ||
  data === 'GreaterThan' ||
  data === 'Between';
