import { useCallback, useEffect, useMemo, useState } from 'react';
import { RequestStreamsIdGet, Stream, streamsIdGetAPI } from 'user/api/streams';

/**
 * ストリームを取得するcustom hooks
 */
export const useStream = ({ stream_id }: RequestStreamsIdGet) => {
  const [stream, setStream] = useState<Stream | undefined>(undefined);
  const loadStream = useCallback(
    async (request_stream_id?: string) => {
      const res = await streamsIdGetAPI({ stream_id: request_stream_id ?? stream_id });
      if (res.status === 200) {
        setStream(res.data);
      }
    },
    [stream_id],
  );

  useEffect(() => {
    loadStream();
  }, [loadStream]);

  return useMemo(() => {
    return {
      stream,
      setStream,
      loadStream,
    };
  }, [stream, setStream, loadStream]);
};
