// -- basic library --
import React, { useEffect, useState } from 'react';
import InputBox from 'shared/components/atoms/InputBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import SelectBox from 'shared/components/atoms/SelectBox';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Content, Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import JudgeTimingsInput from 'shared/components/molecules/JudgeTimingsInput';
import ToggleButton from 'shared/components/molecules/ToggleButton';
import styles from 'shared/styles/styles';
import { ExecTypeType } from 'shared/models/ExecTypeType';
import { ObservationModeType } from 'shared/models/ObservationModeType';
import { isValidEmailFormat } from 'shared/utils/is';
import styled from 'styled-components';
import {
  channelsIdEventConditionsPostAPI,
  RequestChannelsIdEventConditionsPost,
} from 'user/api/channelsEventConditions';
import { channelsIdEventConditionsNumActionsPostAPI } from 'user/api/channelsEventConditionsActions';
import { CachedStreams, Stream } from 'user/api/streams';
import MailNotificationField, {
  MailNotificationFieldProps,
} from 'user/dialogs/MailNotificationDialog/MailNotificationField';
import ClipVideoField from '../ClipVideoField';
import { MailAdress } from 'shared/models/MailAdress';
import { channelsEventConditionsPostValidatorPostValidator } from 'user/utils/validators/channels';
import {
  ChannelEventConditionTypeType,
  convertChannelEventConditionTypeType,
} from 'shared/models/ChannelEventConditionType';
import GeneralConditionPane from './GeneralConditionPane';
import UsecaseConditionPane from './UsecaseConditionPane';
import { ChannelEventConditionUsecaseTypeType } from 'shared/models/ChannelEventConditionUsecaseType';

type Params = {
  channel_id: string;
  handleCloseClick: () => void;
  streams_record: Record<string, Stream>;
  streams?: Stream[];
};

// -- main component --
const Create: React.FC<Params> = (params) => {
  // -- local states --
  const [is_timestamp, setIsTimestamp] = useState<boolean>(false);
  // 入力用states
  const [channel_event_condition_name, setChannelEventConditionName] = useState<string>('');
  const [condition_type, setConditionType] = useState<ChannelEventConditionTypeType | undefined>('GENERAL');
  const [exec_type, setExecType] = useState<ExecTypeType | undefined>('ADD');
  const [input_stream_id, setInputStreamId] = useState<string>('');
  const [observation_mode, setObservationMode] = useState<ObservationModeType | undefined>('TO_ALARM');
  const [evaluation_period, setEvaluationPeriod] = useState<number>(1);
  const [aggregation_period, setAggregationPeriod] = useState<number>(1);
  const [statistic_method, setStatisticMethod] = useState<string>('');
  const [evaluate_condition_operator, setEvaluateConditionOperator] = useState<string>('');
  const [evaluate_condition_value1, setEvaluateConditionValue1] = useState<number>(NaN);
  const [evaluate_condition_value2, setEvaluateConditionValue2] = useState<number>(NaN); // number
  const [json_path, setJsonPath] = useState<string>('');
  const [alarm_datapoints, setAlarmDatapoints] = useState<number>(NaN); // number
  const [alert_enabled, setAlertEnabled] = useState<boolean>(false);
  const [alert_mail_addresses, setAlertMailAddresses] = useState<MailAdress[]>([{ mail_address: '' }]);
  const [alert_mail_message, setAlertMailMessage] = useState<string>('');
  const [soracom_device_reboot, setSoracomDeviceReboot] = useState<boolean>(false);
  const [stream_id, setStreamId] = useState<string | null>(null);
  const [mail_stream_id, setMailStreamId] = useState<string | null | undefined>(undefined);
  const [periods_before, setPeriodsBefore] = useState<number>(NaN); // number
  const [periods_after, setPeriodsAfter] = useState<number>(NaN); // number
  const [judge_timings, setJudgeTimings] = useState<number[][]>([[0, 2359]]);
  const [usecase_type, setUsecaseType] = useState<ChannelEventConditionUsecaseTypeType | undefined>('STAY_ALERT');
  const [stayareas, setStayareas] = useState<string>('');
  const [staying_time, setStayingTime] = useState<number | undefined>();
  const [area_routing, setAreaRouting] = useState<string>('{"in":[],"out":[]}');
  const [cachedStreams] = useState(new CachedStreams({ data_types: 'IMAGE,VIDEO', with_output_streams: 'True' }));
  const [streams, setStreams] = useState<Stream[]>([]);

  const handleStreamChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    let stream: undefined | Stream = undefined;
    if (e.currentTarget.value in params.streams_record) {
      stream = params.streams_record[e.currentTarget.value];
    }

    if (stream === undefined) {
      setIsTimestamp(false);
      setEvaluationPeriod(1);
      setAggregationPeriod(1);
      setInputStreamId(e.currentTarget.value);
      return;
    }
    if (stream.data_number_type === 'TIMESTAMP') {
      setIsTimestamp(true);
    } else {
      setIsTimestamp(false);
      setEvaluationPeriod(1);
      setAggregationPeriod(1);
    }
    setInputStreamId(stream.stream_id);
  };

  const handleClipVideoChange = (
    enabled: boolean,
    streamId: string | null,
    periodsBefore: number,
    periodsAfter: number,
  ) => {
    setStreamId(enabled ? streamId : null);
    setPeriodsBefore(enabled ? +periodsBefore : NaN);
    setPeriodsAfter(enabled ? +periodsAfter : NaN);
  };

  const onMailInfoChange: MailNotificationFieldProps['onChange'] = ({
    mail_addresses,
    mail_message,
    enabled,
    stream_id,
  }) => {
    if (typeof enabled === 'boolean') {
      setAlertEnabled(enabled);
    }
    if (mail_addresses instanceof Array) {
      setAlertMailAddresses(mail_addresses);
    }
    if (typeof mail_message === 'string') {
      setAlertMailMessage(mail_message);
    }
    setMailStreamId(stream_id);
  };

  const handleFinishClick = async () => {
    const { channel_id } = params;
    try {
      // バリデーションをし、requestデータを取得
      const request: RequestChannelsIdEventConditionsPost = channelsEventConditionsPostValidatorPostValidator({
        channel_id,
        channel_event_condition_name,
        condition_type: condition_type || 'GENERAL',
        input_stream_id,
        evaluation_period,
        aggregation_period,
        json_path,
        statistic_method,
        evaluate_condition_operator,
        evaluate_condition_value1,
        evaluate_condition_value2,
        alarm_datapoints,
        is_timestamp,
        alert_mail_addresses,
        mail_stream_id,
        stream_id,
        periods_after,
        periods_before,
        exec_type,
        observation_mode,
        judge_timings,
        usecase_type,
        stayareas,
        staying_time,
        area_routing,
      });

      // --------- チャンネルイベント条件の登録 ---------
      const res = await channelsIdEventConditionsPostAPI(request);
      if (res.status !== 200) {
        AlertDialog.show('チャンネルイベント条件の作成に失敗しました');
        return;
      }
      // --------- イベントアクション（クリップビデオ） ------
      if (stream_id !== null) {
        channelsIdEventConditionsNumActionsPostAPI({
          channel_id: channel_id,
          channel_event_condition_number: res.data.channel_event_condition_number,
          channel_event_condition_action_name: 'ClipVideo',
          action_type: 'ClipVideo',
          stream_id: stream_id,
          periods_before: periods_before ? periods_before + '' : undefined,
          periods_after: periods_after ? periods_after + '' : undefined,
        });
      }
      // --------- イベントアクション（メールアドレス） ------
      if (alert_enabled) {
        for (const mail_address of alert_mail_addresses) {
          const _mail_stream_id = mail_stream_id || undefined;
          // メールアドレスのフォーマットが正しいもののみを送信する
          if (mail_address.mail_address.length && isValidEmailFormat(mail_address.mail_address)) {
            channelsIdEventConditionsNumActionsPostAPI({
              channel_id: channel_id,
              channel_event_condition_number: res.data.channel_event_condition_number,
              channel_event_condition_action_name: 'Email',
              action_type: 'Email',
              ...(_mail_stream_id ? { stream_id: _mail_stream_id } : {}),
              mail_address: mail_address.mail_address,
              mail_message: alert_mail_message,
            });
          }
        }
      }
      // --------- イベントアクション（ソラコムデバイス再起動） ------
      if ((condition_type || 'GENERAL') == 'GENERAL' && soracom_device_reboot) {
        channelsIdEventConditionsNumActionsPostAPI({
          channel_id: channel_id,
          channel_event_condition_number: res.data.channel_event_condition_number,
          channel_event_condition_action_name: 'SoracomDeviceReboot',
          action_type: 'SoracomDeviceReboot',
        });
      }
      AlertDialog.show('チャンネルイベント条件の作成に成功しました');
      params.handleCloseClick();
    } catch (e) {
      if (e instanceof Error) {
        AlertDialog.show(e.message);
      }
      return;
    }
  };

  useEffect(() => {
    cachedStreams.get().then((values) => {
      setStreams(values);
    });
  }, [cachedStreams]);

  // -- render part --
  return (
    <Content>
      <TopArea>
        <InputComponent text='イベント定義名' required>
          <InputBox
            title='イベント定義名'
            placeholder='入力してください'
            value={channel_event_condition_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setChannelEventConditionName(e.currentTarget.value)}
          />
        </InputComponent>
        <InputComponent text='入力データ' required>
          <SelectBox
            value={input_stream_id}
            onChange={handleStreamChange}
            datas={
              params.streams?.map((d) => {
                return {
                  name: d.stream_name,
                  value: d.stream_id,
                };
              }) ?? []
            }
          />
        </InputComponent>
        <InputComponent text='イベント条件の種類' required>
          <SelectBox
            datas={[
              {
                name: '汎用イベント',
                value: 'GENERAL',
              },
              {
                name: 'ユースケースイベント',
                value: 'USECASE',
              },
            ]}
            value={condition_type || 'GENERAL'}
            onChange={(e) => setConditionType(convertChannelEventConditionTypeType(e.currentTarget.value))}
            default_text='null'
          />
        </InputComponent>
        {condition_type === 'GENERAL' && (
          <GeneralConditionPane
            mode='Create'
            streams={params.streams}
            input_stream_id={input_stream_id}
            is_timestamp={is_timestamp}
            evaluation_period={evaluation_period}
            onEvaluationPeriodChange={setEvaluationPeriod}
            aggregation_period={aggregation_period}
            onAggregationPeriodChange={setAggregationPeriod}
            json_path={json_path}
            onJsonPathChange={setJsonPath}
            statistic_method={statistic_method}
            onStatisticMethodChange={setStatisticMethod}
            evaluate_condition_operator={evaluate_condition_operator}
            onEvaluateConditionOperatorChange={setEvaluateConditionOperator}
            evaluate_condition_value1={evaluate_condition_value1}
            evaluate_condition_value2={evaluate_condition_value2}
            onEvaluateConditionValue1Change={setEvaluateConditionValue1}
            onEvaluateConditionValue2Change={setEvaluateConditionValue2}
            alarm_datapoints={alarm_datapoints}
            onAlarmDatapointsChange={setAlarmDatapoints}
            observation_mode={observation_mode}
            onObservationModeChange={setObservationMode}
            exec_type={exec_type}
            onExecTypeChange={setExecType}
          />
        )}
        {condition_type === 'USECASE' && (
          <UsecaseConditionPane
            streams={params.streams}
            usecase_type={usecase_type}
            onUsecaseTypeChange={setUsecaseType}
            stayareas={stayareas}
            onStayareasChange={setStayareas}
            staying_time={staying_time}
            onStayingTimeChange={setStayingTime}
            area_routing={area_routing}
            onAreaRoutingChange={setAreaRouting}
          />
        )}
        <InputComponent text='イベントの判定を行う時間帯'>
          <JudgeTimingsInput value={judge_timings} onChange={(value) => setJudgeTimings(value)} />
        </InputComponent>
        {/* 通知設定 */}
        <MailNotificationField
          enabled={alert_enabled}
          mail_addresses={alert_mail_addresses}
          mail_message={alert_mail_message}
          show_stream_id={condition_type === 'USECASE'}
          stream_id={mail_stream_id}
          streams={streams}
          onChange={onMailInfoChange}
        />
        {(condition_type || 'GENERAL') == 'GENERAL' && (
          <InputComponent text='自動再起動設定'>
            <ToggleButton
              checked={soracom_device_reboot}
              onClick={() => setSoracomDeviceReboot(!soracom_device_reboot)}
              style={{
                marginBottom: styles.interval_narrow_margin,
              }}
            />
          </InputComponent>
        )}
        {/* クリップビデオ */}
        <ClipVideoField
          enabled={stream_id !== null}
          stream_id={stream_id}
          periods_before={periods_before}
          periods_after={periods_after}
          onChange={handleClipVideoChange}
        />
      </TopArea>
      <Footer>
        <RoundedButton onClick={handleFinishClick} text_type='CREATE' style={{ width: styles.small_button_width }} />
      </Footer>
    </Content>
  );
};

// -- styled components --
const TopArea = styled.div``;

// -- finally export part --

export default Create;
