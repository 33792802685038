import { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy } from 'shared/utils/converter';
import { CachedNotificationsCheck, RequestNotificationsCheckGet, NotificationCheck } from 'user/api/notificationsCheck';

/**
 * お知らせチェック一覧を取得するcustom hooks
 */
export const useNotificationsCheck = ({ status, exclusive_start_notification_id }: RequestNotificationsCheckGet) => {
  const [notifications_check, setNotificationsCheck] = useState<NotificationCheck[] | undefined>(undefined);
  const notifications_check_record: Record<string, NotificationCheck> = useMemo(() => {
    return notifications_check ? keyBy(notifications_check, (r) => r.notification_id) : {};
  }, [notifications_check]);
  const loadNotificationsCheck = useCallback(
    async (request?: RequestNotificationsCheckGet) => {
      const r = {
        status: request?.status ?? status,
        exclusive_start_notification_id: request?.exclusive_start_notification_id ?? exclusive_start_notification_id,
      };
      const new_notifications_check = await new CachedNotificationsCheck(r).get();
      setNotificationsCheck(new_notifications_check);
    },
    [status, exclusive_start_notification_id],
  );

  useEffect(() => {
    loadNotificationsCheck();
  }, [loadNotificationsCheck]);

  return useMemo(() => {
    return {
      notifications_check,
      notifications_check_record,
      setNotificationsCheck,
      loadNotificationsCheck,
    };
  }, [notifications_check, notifications_check_record, setNotificationsCheck, loadNotificationsCheck]);
};
