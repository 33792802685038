import React from 'react';
import { InputGroupProps } from '@blueprintjs/core';
import InputBox from 'shared/components/atoms/InputBox';
import SuggestBox from 'shared/components/atoms/SuggestBox';
import { Stream, streamsIdGetAPI, streamsIdJsonpathGetAPI } from 'user/api/streams';

// JSONパスを入力するためのテキストボックス
interface JsonpathInputProps {
  stream_id?: string;
  stream?: Stream;
  value: string;
  onJsonpathChanged: (item: string) => void;
  'data-testid'?: string;
}
interface JsonpathInputState {
  stream?: Stream;
  jsonpath?: string[];
}
export default class JsonpathInput extends React.PureComponent<
  JsonpathInputProps & InputGroupProps,
  JsonpathInputState
> {
  private inputRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
  constructor(props: JsonpathInputProps) {
    super(props);
    this.state = {
      stream: props.stream,
    };
  }
  componentDidMount() {
    if (this.state.stream === undefined && this.props.stream_id) this.updateStream();
  }
  componentDidUpdate(prevProps: JsonpathInputProps) {
    if (this.props.stream_id !== prevProps.stream_id) {
      this.updateStream();
    }
  }
  private updateStream() {
    if (this.props.stream_id) {
      streamsIdGetAPI({ stream_id: this.props.stream_id }).then((res) => {
        if (res.status === 200) this.setState({ stream: res.data });
      });
      streamsIdJsonpathGetAPI({ stream_id: this.props.stream_id }).then((res) => {
        if (res.status === 200) {
          this.setState({ jsonpath: res.data.jsonpaths });
        }
      });
    }
  }
  private setJsonpath = (item: string) => {
    this.props.onJsonpathChanged(item);
  };
  render() {
    const { onJsonpathChanged, ...remain_props } = this.props;
    return (
      <>
        {this.state.stream ? (
          <SuggestBox
            items={this.state.jsonpath || []}
            inputItems={(item) => item}
            onItemSelect={(item) => this.setJsonpath(item)}
            onQueryChange={(item) => this.setJsonpath(item)}
            value={this.props.value}
            data-testid={remain_props['data-testid']}
          />
        ) : (
          <InputBox
            {...remain_props}
            inputRef={this.inputRef}
            onChange={(e) => onJsonpathChanged(e.currentTarget.value)}
          />
        )}
      </>
    );
  }
}
