import { ProcessInfo } from 'user/api/streamsProcessInfo';
import { ProcessOutputType } from 'shared/models/ProcessOutputType';

// 2つを比較して最小の数を得る
export const aryMin = (a: number, b: number) => {
  return Math.min(a, b);
};

// プロセスフローのプロセスの中で最小の進捗率を取得する
export const getMinProcessingProgress = (process_infos?: ProcessInfo[]): number => {
  let proccesing_progress_number = 0;
  if (!process_infos || process_infos.length === 0) {
    return proccesing_progress_number;
  } else if (process_infos.length === 1) {
    proccesing_progress_number = process_infos[0].processing_progress;
  } else {
    const processing_progresses: number[] = process_infos.map((pro) => pro.processing_progress);
    proccesing_progress_number = processing_progresses.reduce(aryMin);
  }
  return proccesing_progress_number;
};

export const getProgressTextFromProcessInfos = (processing_progress?: number) => {
  const pp = processing_progress || 0;
  let return_text = `進捗率 ${pp}%`;
  if (pp >= 100) {
    return_text = 'プロセス結果を表示 | ▼';
  }
  return return_text;
};

export const getOutputResultDisplayName = (p: {
  stream_data_number?: number;
  stream_package_number?: number;
  output_type: ProcessOutputType;
  output_name?: string;
  index: number;
}) => {
  // 出力名設定
  let output_name = `出力データ(${p.index + 1})`;
  if (p.output_name) {
    output_name = p.output_name;
  }
  // csv出力(パッケージ判定)
  if (p.output_type === 'PACKAGE' && typeof p.stream_package_number === 'number') {
    return `${output_name} [CSV]`;
  }
  // mp4出力(データ判定)
  if (p.output_type === 'DATA' && typeof p.stream_data_number === 'number') {
    return `${output_name} [MP4]`;
  }
  return '';
};
