import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import InputBox from 'shared/components/atoms/InputBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import SelectBox from 'shared/components/atoms/SelectBox';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { Content, Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import RadioBox from 'shared/components/molecules/RadioBox';
import styles from 'shared/styles/styles';
import { isUserType } from 'shared/models/UserType';
import { isNotOnlySpace, isMailAddressOptionally, isValidEmailFormat } from 'shared/utils/is';
import { Role } from 'user/api/roles';
import { usersIdPutAPI, RequestUsersIdPut, usersIdGetAPI } from 'user/api/users';
import { systemSelector } from 'user/redux/slices/systemSlice';
import { preloadGroup } from 'user/utils/preloadGroup';
import PfDialog from 'shared/components/atoms/PfDialog';

// -- types --
type Params = {
  user_id: string;
  handleDialogCloseClick: () => void;
  roles?: Role[];
  is_open: boolean;
};

// -- main component --

const UsersDetailDialog: React.FC<Params> = ({ user_id, roles, handleDialogCloseClick, is_open }) => {
  // -- get external datas --

  // -- redux preparations --
  const system_state = useSelector(systemSelector);

  // -- local states --;
  const [login_id, setLoginId] = useState<string>('');
  const [user_name, setUserName] = useState<string>('');
  const [mail_address, setMailAddress] = useState<string | null>(null);
  const [user_type, setUserType] = useState<string>('');
  const [selected_role, setSelectedRole] = useState<string>('');
  const [is_display_component, setIsDisplayComponent] = useState<boolean>(false);

  // -- handlers --
  const getInitialData = async (user_id: string) => {
    const res = await usersIdGetAPI({ user_id: user_id });
    if (res.status === 200) {
      setUserName(res.data.user_name);
      setMailAddress(res.data.mail_address);
      setUserType(res.data.user_type);
      setSelectedRole(res.data.role_id);
      setLoginId(res.data.login_id);
      setIsDisplayComponent(
        res.data.user_name !== undefined &&
          res.data.mail_address !== undefined &&
          res.data.user_type !== undefined &&
          res.data.login_id !== undefined,
      );
    }
  };

  const handleUserNameChangeClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(e.currentTarget.value);
  };

  const handleMailAddressChangeClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value === '') {
      setMailAddress(null);
      return;
    }
    setMailAddress(e.currentTarget.value);
  };

  const handleFinish = async (inputs: {
    user_id: string;
    user_name: string;
    mail_address: string | null;
    user_type: string;
    role: string | undefined;
  }) => {
    if (!isNotOnlySpace(inputs.user_name)) {
      AlertDialog.show('入力欄に空欄があります');
      return;
    }
    if (!isMailAddressOptionally(inputs.mail_address)) {
      AlertDialog.show(
        <div>
          メールアドレスは空白のみの時省略します。
          <br />
          入力する場合は正しいフォーマットで送信してください
        </div>,
      );
      return;
    }
    if (!isUserType(inputs.user_type)) {
      AlertDialog.show('ユーザータイプを選択してください');
      return;
    }
    const users_id_put_data: RequestUsersIdPut = {
      user_id: inputs.user_id,
      user_name: inputs.user_name,
      user_type: inputs.user_type,
      role_id: inputs.role,
    };
    if (
      inputs.mail_address !== null &&
      isNotOnlySpace(inputs.mail_address) &&
      isValidEmailFormat(inputs.mail_address)
    ) {
      users_id_put_data.mail_address = inputs.mail_address;
    }
    ConfirmDialog.show(
      <div>
        [注意]全てのユーザが一般になると閲覧のみしかできなくなります。
        <br />
        更新してよろしいですか？
      </div>,
      () => update(users_id_put_data),
      undefined,
      undefined,
    );
  };

  const update = async (users_id_put_data: RequestUsersIdPut) => {
    const res = await usersIdPutAPI(users_id_put_data);
    if (res.status === 200) {
      AlertDialog.show('ユーザー情報の更新に成功しました');
      handleDialogCloseClick();
    }
  };

  // -- onload function --
  useEffect(() => {
    preloadGroup(getInitialData(user_id));
  }, [user_id]);

  // -- render part --
  return (
    <PfDialog isOpen={is_open} onClose={handleDialogCloseClick} title='ユーザー詳細'>
      <Content>
        {!system_state.loading.preloading && is_display_component && (
          <>
            <InputComponent text='ログインID'>
              <InputBox
                title='ログインID'
                placeholder=''
                value={login_id}
                onChange={(e) => setLoginId(e.currentTarget.value)}
                disabled={true}
              />
            </InputComponent>
            <InputComponent text='名前' required>
              <InputBox
                title='名前'
                placeholder='入力してください(必須)'
                value={user_name}
                onChange={handleUserNameChangeClick}
              />
            </InputComponent>
            <InputComponent text='メールアドレス'>
              <InputBox
                title='メールアドレス'
                placeholder='省略可'
                value={mail_address === null ? '' : mail_address}
                onChange={handleMailAddressChangeClick}
              />
            </InputComponent>
            <InputComponent text='ユーザタイプ'>
              <RadioBox
                datas={[
                  { name: '管理者', value: 'ADMIN' },
                  { name: '一般', value: 'GENERAL' },
                ]}
                selectedValue={user_type}
                handleChangeClick={(value) => setUserType(value)}
              />
            </InputComponent>
            {user_type === 'GENERAL' && roles && (
              <InputComponent text='ロール'>
                <SelectBox
                  onChange={(e) => setSelectedRole(e.currentTarget.value)}
                  value={selected_role}
                  datas={roles.map((role) => {
                    return {
                      name: role.role_name,
                      value: role.role_id,
                    };
                  })}
                />
              </InputComponent>
            )}
            <Footer>
              <RoundedButton
                onClick={handleDialogCloseClick}
                style={{ marginRight: styles.interval_narrow_margin }}
                text='キャンセル'
                is_white
              />

              <RoundedButton
                onClick={() =>
                  handleFinish({
                    user_id: user_id,
                    user_name: user_name,
                    mail_address: mail_address,
                    user_type: user_type,
                    role: selected_role,
                  })
                }
                disabled={Boolean(!isNotOnlySpace(user_name) || !isMailAddressOptionally(mail_address))}
                text_type='UPDATE'
              />
            </Footer>
          </>
        )}
      </Content>
    </PfDialog>
  );
};

// -- styled components --

// -- finally export part --

export default UsersDetailDialog;
