// polygon操作に関する関数たち

import { isNumberSquareArrayOfSize2 } from '../is';
import { scaleChange } from '../converter';
import { SizeWH, Point, StartEndPoint } from 'shared/models';

export const getPolygonPoints = (p: StartEndPoint, polygon_number = 4): number[][] => {
  // 返却する座標の配列
  // [[x1, y1], [x2, x2], ...]
  let polygon_points: number[][] = [];
  // 正n角形をn等分した時の角度
  const angle = 360 / polygon_number;
  // 中点
  const center = getCenter(p, polygon_number);
  if (polygon_number === 4) {
    /**
     * ４角形の時は長方形を許す
     * 始点を中心に反時計回りに座標をプロット
     * */
    polygon_points = [
      [p.start.x, p.start.y],
      [p.start.x, p.end.y],
      [p.end.x, p.end.y],
      [p.end.x, p.start.y],
    ];
  } else if (polygon_number % 2 === 0) {
    /**
     * 正n角形(n: 偶数)の時
     * angleをi倍して回転した座標を格納する、
     * 始点を中心に反時計回りに座標を保持するため逆順に回す
     */
    polygon_points = [[p.start.x, p.start.y]];
    // 始点以外の座標n-1点をプロット
    for (let i = polygon_number - 1; i > 0; i--) {
      const rotated_coordinate = getRotatedCoordinate({
        coordinate: p.start,
        center: center,
        angle: angle * i,
      });
      // 座標格納
      polygon_points.push([rotated_coordinate.x, rotated_coordinate.y]);
    }
  } else {
    /**
     * 正n角形(n: 奇数)の時
     * angleをi倍して回転した座標を格納する、
     * 始点を中心に反時計回りに座標を保持するため逆順に回す
     */
    polygon_points = [[p.start.x, p.start.y]];
    // 始点以外の座標n-1点をプロット
    for (let i = polygon_number - 1; i > 0; i--) {
      const rotated_coordinate = getRotatedCoordinate({
        coordinate: p.start,
        center: center,
        angle: angle * i,
      });
      // 座標格納
      polygon_points.push([rotated_coordinate.x, rotated_coordinate.y]);
    }
  }
  return polygon_points;
};

/***
 * n角形の中点を取得する
 * ***/
export const getCenter = (p: StartEndPoint, polygon_number = 4): Point => {
  if (polygon_number % 2 === 0) {
    return {
      x: (p.start.x + p.end.x) / 2,
      y: (p.start.y + p.end.y) / 2,
    };
  } else {
    const angle = 360 / polygon_number;
    // 正n角形をn等分したそれぞれの三角形を、2等分して(中点からの垂線で二等分)、その三角形の中点から遠い方の角度
    const theta = convertNumberToRad(180 - 90 - angle / 2);
    // 始点終点の直線の長さ
    const len = Math.sqrt((p.end.x - p.start.x) ** 2 + (p.end.y - p.start.y) ** 2);
    // 正n角形の半径
    const radius: number = len / (1 + Math.sin(theta));
    const center: Point = scaleChange(p.end, p.start, radius);
    return center;
  }
};

export const getRotatedCoordinate = (p: { coordinate: Point; center: Point; angle: number }) => {
  // 中点から見た始点(中点から見た始点の相対座標)
  const relative_coordinate: Point = {
    x: p.coordinate.x - p.center.x,
    y: p.coordinate.y - p.center.y,
  };
  const rotated_coordinate: Point = {
    x:
      relative_coordinate.x * Math.cos(convertNumberToRad(p.angle)) -
      relative_coordinate.y * Math.sin(convertNumberToRad(p.angle)) +
      p.center.x,
    y:
      relative_coordinate.x * Math.sin(convertNumberToRad(p.angle)) +
      relative_coordinate.y * Math.cos(convertNumberToRad(p.angle)) +
      p.center.y,
  };
  return rotated_coordinate;
};

/*** 数値を角度に変換する関数***/
export const convertNumberToRad = (num: number) => {
  const pi = Math.PI;
  return (num * pi) / 180;
};

/*** 割合で保持しているpolygonに画像の大きさをかける ***/
export const multiplyPolygon = (p: { polygon: number[][]; width: number; height: number }) => {
  const new_polygon = p.polygon.map((pol) => {
    return pol.map((po, j) => {
      // p[0] -> x座標, p[1] -> y座標
      return j % 2 === 0 ? po * p.width : po * p.height;
    });
  });
  return new_polygon;
};
/*** 割合で保持しているpolygonに画像の大きさを割る ***/
export const divisePolygon = (p: { polygon: number[][]; width: number; height: number }) => {
  const new_polygon = p.polygon.map((pol) => {
    return pol.map((po, j) => {
      // p[0] -> x座標, p[1] -> y座標
      return j % 2 === 0 ? po / p.width : po / p.height;
    });
  });
  return new_polygon;
};

/*** 範囲内を超えていない場合はtrue, それ以外がfalse***/
export const checkPolygonXYWithinRange = (p: { polygon: number[][]; size: SizeWH }) => {
  // 適切な配列の形をしていない
  if (!isNumberSquareArrayOfSize2(p.polygon)) {
    return false;
  }
  let flag = true;
  for (let i = 0; i < p.polygon.length; i++) {
    const pos = p.polygon[i];
    // x座標の範囲
    if (pos[0] < 0 || pos[0] > p.size.width) {
      flag = false;
      break;
    }
    // y座標の範囲
    if (pos[1] < 0 || pos[1] > p.size.height) {
      flag = false;
      break;
    }
  }
  return flag;
};

/***
 * 図形の座標が交差している場合はtrue, それ以外がfalseとなる関数
 *
 * ***/
export const checkPolygonIntersect = (polygon: number[][]) => {
  // 座標が3以下では交わることはない(∵どの辺も必ず選ばれている頂点を含む線分だから)
  const len = polygon.length;
  if (len <= 3) return false;

  // polygon内の全ての配列要素の長さが2の時はtrue, それ以外はfalse
  // falseの時はそもそもpolygonが間違っている
  if (!isNumberSquareArrayOfSize2(polygon)) return true;

  // ここで交差判定を線分ごとに行う
  for (let i = 0; i < len; i++) {
    const xy = polygon[i];
    // 選択中の頂点の次の頂点(時計回りに1つ次)
    const next_i = (i + 1) % len;
    const xy_next = polygon[next_i];
    const line1 = {
      start: {
        x: xy[0],
        y: xy[1],
      },
      end: {
        x: xy_next[0],
        y: xy_next[1],
      },
    };
    // i以降の頂点において、線分を評価する
    // i以前の評価は、既にされている(nCn-iの評価で十分)
    for (let j = i + 1; j < len; j++) {
      const xy_pat = polygon[j];
      const next_j = (j + 1) % len;
      const next_xy_pat = polygon[next_j];
      // line1内の座標が含まれている時は、交わることはないので評価をスキップする
      if ([j, next_j].includes(i) || [j, next_j].includes(next_i)) continue;
      const line2 = {
        start: {
          x: xy_pat[0],
          y: xy_pat[1],
        },
        end: {
          x: next_xy_pat[0],
          y: next_xy_pat[1],
        },
      };
      const intersect = checkIntersect(line1, line2);
      if (intersect) return true;
    }
  }
  // ここまでの評価をクリアしていれば交差するものがないのでfalse
  return false;
};

/***
 * 2つの線分が交差する場合はtrue, それ以外がfalseとなる
 * ***/
export const checkIntersect = (line1: StartEndPoint, line2: StartEndPoint): boolean => {
  const tl1s =
    (line2.start.x - line2.end.x) * (line1.start.y - line2.start.y) +
    (line2.start.y - line2.end.y) * (line2.start.x - line1.start.x);
  const tl1e =
    (line2.start.x - line2.end.x) * (line1.end.y - line2.start.y) +
    (line2.start.y - line2.end.y) * (line2.start.x - line1.end.x);
  const tl2s =
    (line1.start.x - line1.end.x) * (line2.start.y - line1.start.y) +
    (line1.start.y - line1.end.y) * (line1.start.x - line2.start.x);
  const tl2e =
    (line1.start.x - line1.end.x) * (line2.end.y - line1.start.y) +
    (line1.start.y - line1.end.y) * (line1.start.x - line2.end.x);
  return tl2s * tl2e < 0 && tl1s * tl1e < 0;
};
