// -- basic library --
import React, { useState, useEffect, useCallback } from 'react';

// -- redux library --

// -- http connection library --
import Spinner from 'shared/components/atoms/Spinner';
import {
  getStreamsIdPackagesNumberDownloadGetAPIUrl,
  StreamPackage,
  streamsIdPackagesNumberGetAPI,
} from 'user/api/streamsPackage';

// -- external components --
import CSVTable from './CSVTable';
import { ProcessInfo, ProcessInfoOutput } from 'user/api/streamsProcessInfo';
import { ResultTopArea } from './ResultTopArea';

interface CSVResultBodyProps {
  stream_id: string;
  stream_package_number: number;
  process_output: ProcessInfoOutput;
  setProcessOutput(process_output: ProcessInfoOutput): void;
  process_info: ProcessInfo;
  process_infos?: ProcessInfo[];
}

// -- main component --

const CSVResultBody: React.FC<CSVResultBodyProps> = (props) => {
  // -- local states --
  const [stream_package, setStreamPackage] = useState<StreamPackage | undefined>(undefined);
  const is_csv = stream_package?.package_type === 'CSV' || stream_package?.package_type === 'AUTO_CSV';

  const loadStreampackage = useCallback(() => {
    streamsIdPackagesNumberGetAPI({
      stream_id: props.stream_id,
      stream_package_number: String(props.stream_package_number),
    }).then((res) => {
      if (res.status === 200) {
        setStreamPackage(res.data);
      }
    });
  }, [props.stream_package_number, props.stream_id]);

  useEffect(() => {
    // 別のCSVファイル選択時の画面表示切替のため、
    // propsが変更された場合一度stream_packageをundefinedにする
    setStreamPackage(undefined);
    loadStreampackage();
  }, [loadStreampackage, props]); /* eslint-disable-line */

  // -- render part --
  return stream_package ? (
    <>
      <ResultTopArea
        button_text='CSVでダウンロード'
        button_disabled={!is_csv}
        top_text={stream_package?.line_count ? `CSVデータ行数: ${stream_package?.line_count}` : ''}
        download_url={getStreamsIdPackagesNumberDownloadGetAPIUrl({
          stream_id: props.stream_id,
          stream_package_number: props.stream_package_number,
        })}
      />
      {is_csv && <CSVTable {...props} />}
    </>
  ) : (
    <Spinner />
  );
};

// -- finally export part --

export default CSVResultBody;
