import { dateToYMD } from 'shared/utils/converter/date';
import { Branded } from '.';

/**
 * 文字列の日付型
 * yyyy-mm-dd
 */
export type DateString = Branded<string, 'DateString'>;

/**
 *
 * @param str: string
 * @return boolean: yyyy-mm-ddの形式ならばtrue
 */
export const isDateString = (str?: string | null): str is DateString => {
  if (typeof str !== 'string') return false;
  const correctPattern = new RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}$');
  const match = str.match(correctPattern);
  return match !== null;
};

/**
 * strがDateStringでなければ今日のデータが格納
 * @param str: string
 * @return DateString
 */
export const toDateString = (str: string): DateString => {
  if (isDateString(str)) return str;
  const today = new Date();
  return dateToYMD(today, '-') as DateString;
};

export const dateStringYear = (dateString: DateString) => {
  const ymd = dateString.split('-');
  return Number(ymd[0]);
};

export const dateStringMonth = (dateString: DateString) => {
  const ymd = dateString.split('-');
  return Number(ymd[1]);
};

export const dateStringDay = (dateString: DateString) => {
  const ymd = dateString.split('-');
  return Number(ymd[2]);
};

export const dateStringToDateClass = (dateString: DateString): Date => {
  const year = dateStringYear(dateString);
  const month = dateStringMonth(dateString);
  const day = dateStringDay(dateString);
  const dt = new Date(year, month, day);
  return dt;
};

export const dateStringToSecond = (dateString: DateString): number => {
  const dt = dateStringToDateClass(dateString);
  return ~~(dt.getTime() / 1000);
};
