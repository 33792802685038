// -- basic library --
import React, { useEffect, useRef, useState } from 'react';

import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { KonvaEventObject } from 'konva/types/Node';
import { Stage as StageRefType } from 'konva/types/Stage';
import { Stage, Layer, Image as ImageKonva } from 'react-konva';
import RoundedButton from 'shared/components/atoms/RoundedButton';

import SelectableBlueprintButton from 'shared/components/atoms/SelectableBlueprintButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import styles from 'shared/styles/styles';

import { TrimedImage } from 'shared/models';
import { trimedImageToSize } from 'shared/utils/converter';
import { getWidth } from 'shared/utils/get';
import styled from 'styled-components';
import SettingExpImpButton from 'user/components/molecules/SettingExpImpButton/SettingExpImpButton';
import { Content, FlexCenterDiv } from '../ContentsArea';
import CrossLineLegend from './CrossLineLegend';
import { KonvaObject } from './KonvaObject';
import { checkCrosslinesLength, checkRangeCrosslineIndex } from 'shared/utils/else/crosslineFunctions';

// -- type declaration --
export interface DrawCrossLinePanelParams {
  imgsrc: string;
  crosslines: number[][][]; // 線番号,始点終点,xy(割合で保持)
  onChange: (crosslines: number[][][]) => void;
  trimed_image?: TrimedImage;
  onTrimedImageChange?: (trimed_image: TrimedImage) => void;
  hideImport?: boolean;
  hideExport?: boolean;
}

export type MODE = 'pen' | 'hand';
export type CROSSLINE_POSITION = 'start' | 'end' | '';

// -- main component --

const DrawDrawCrosslines: React.FC<DrawCrossLinePanelParams> = (params) => {
  // -- reference preparations --
  const isDrawing = useRef(false);
  const stageRef: React.LegacyRef<StageRefType> = useRef(null);
  // stageの一個上のコンポーネントのref
  const stageParentRef = useRef<HTMLDivElement>(null);

  // -- local states --
  const [tool, setTool] = React.useState<MODE>('pen');
  // 直線の情報を格納する
  const [img, setImg] = useState<CanvasImageSource | undefined>(undefined);
  // 直線の先端の丸を動かす際、どちらの丸(start or end)を掴んでいるかを保持する
  const [resize_position, setResizePosition] = useState<CROSSLINE_POSITION>('');
  // 選択されている線のindex(ない時はnull)
  const [selected_crossline_index, setSelectedCroslineIndex] = useState<number | null>(null);

  // 画像を描画する高さ
  // 横幅が足りない時は、高さを縮める
  const [height, setHeight] = useState<number>(580);
  // width / height
  const [img_ratio, setImgRario] = useState<number>(16 / 9);

  // toolが変化した時の関数
  const changeTool = (to: MODE) => {
    setTool(to);
    setSelectedCroslineIndex(null);
    setResizePosition('');
  };

  // 指定のlineを更新する
  const setCrosslineWhereIndex = (p: { crossline: number[][]; index: number }) => {
    if (
      !checkRangeCrosslineIndex({
        index: p.index,
        crosslines: params.crosslines,
      })
    ) {
      AlertDialog.show(`${p.index}がlinesの長さを超えています`);
      return;
    }
    const new_lines = [...params.crosslines];
    new_lines[p.index] = p.crossline;
    params.onChange(new_lines);
  };

  /** 選択されているcrosslineの頂点を変更する **/
  const changeCrosslinePosition = (p: {
    x: number;
    y: number;
    resize_position: CROSSLINE_POSITION;
    index: number | null;
    evt: KonvaEventObject<MouseEvent>;
  }) => {
    if (resize_position.length <= 0) return;
    // 選択中の線のindexがない時、indexが範囲外の時はreturn
    if (
      selected_crossline_index === null ||
      !checkRangeCrosslineIndex({
        index: selected_crossline_index,
        crosslines: params.crosslines,
      })
    )
      return;
    // 選択されている線
    const new_selected_line = params.crosslines[selected_crossline_index];
    // 座標が画像のサイズを超えないように
    if (resize_position === 'start') {
      new_selected_line[0] = [p.x, p.y];
    } else if (resize_position === 'end') {
      new_selected_line[1] = [p.x, p.y];
    }
    // crosslineを更新
    setCrosslineWhereIndex({
      crossline: new_selected_line,
      index: selected_crossline_index,
    });
  };

  // tool === 'pen'モードでのマウスダウン
  const addLineOnMouseDown = (evt: KonvaEventObject<MouseEvent>) => {
    isDrawing.current = true;
    if (
      !checkCrosslinesLength({
        crosslines: params.crosslines,
        max_length: 3,
      })
    ) {
      isDrawing.current = false;
      return;
    }
    const pos = evt.target.getStage()?.getPointerPosition();
    if (!pos) {
      return;
    }
    const width = getWidth({
      height: height,
      ratio: img_ratio,
    });
    // 座標が画像のサイズを超えないように
    const x = pos.x / width;
    const y = pos.y / height;
    const new_lines: number[][][] = [...params.crosslines, [[x, y]]];
    params.onChange(new_lines);
  };

  // tool === 'hand'モードでのマウスダウン
  const handLineOnMouseDown = (evt: KonvaEventObject<MouseEvent>, resize_position: CROSSLINE_POSITION) => {
    // 座標が画像のサイズを超えないように
    const pos = evt.target.getStage()?.getPointerPosition();
    if (!pos) return;
    const width = getWidth({
      height: height,
      ratio: img_ratio,
    });
    const x = pos.x / width;
    const y = pos.y / height;
    changeCrosslinePosition({
      x: x,
      y: y,
      resize_position: resize_position,
      index: selected_crossline_index,
      evt: evt,
    });
    // 描画開始
    isDrawing.current = true;
  };

  // マウスを押下した時の関数
  const onMouseDown = (evt: KonvaEventObject<MouseEvent>) => {
    if (tool === 'pen') {
      addLineOnMouseDown(evt);
    } else if (tool === 'hand') {
      handLineOnMouseDown(evt, resize_position);
    }
  };

  // tool === 'pen'モードでのマウスムーブ
  const addLineOnMouseMove = (evt: KonvaEventObject<MouseEvent>) => {
    if (!isDrawing.current) {
      return;
    }
    const stage = evt.target.getStage();
    const pos = stage?.getPointerPosition();
    if (!pos) return;
    const width = getWidth({
      height: height,
      ratio: img_ratio,
    });
    // 座標が画像のサイズを超えないように
    const x = pos.x / width;
    const y = pos.y / height;
    const length = params.crosslines.length;
    let new_crossline: number[][] = params.crosslines[length - 1];
    new_crossline = [new_crossline[0], [x, y]];
    setCrosslineWhereIndex({
      index: length - 1,
      crossline: new_crossline,
    });
  };

  // tool === 'hand'モードでのマウスムーブ
  const handLineOnMouseMove = (evt: KonvaEventObject<MouseEvent>, resize_position: CROSSLINE_POSITION) => {
    if (!isDrawing.current) {
      return;
    }
    const pos = evt.target.getStage()?.getPointerPosition();
    if (!pos) return;
    const width = getWidth({
      height: height,
      ratio: img_ratio,
    });
    const x = pos.x / width;
    const y = pos.y / height;
    changeCrosslinePosition({
      x: x,
      y: y,
      resize_position: resize_position,
      index: selected_crossline_index,
      evt: evt,
    });
  };

  // マウスが移動している時の関数
  const onMouseMove = (evt: KonvaEventObject<MouseEvent>) => {
    if (tool === 'pen') {
      addLineOnMouseMove(evt);
    } else if (tool === 'hand') {
      handLineOnMouseMove(evt, resize_position);
    }
  };

  // tool === 'pen'モードでのマウスアップ(押下の終了時)
  const addLineOnMouseUp = () => {
    if (!isDrawing.current) {
      return;
    }
    isDrawing.current = false;
  };

  // tool === 'hand'モードでのマウスアップ(押下の終了時)
  const handLineOnMouseUp = (evt: KonvaEventObject<MouseEvent>, resize_position: CROSSLINE_POSITION) => {
    if (!isDrawing.current) {
      return;
    }
    const pos = evt.target.getStage()?.getPointerPosition();
    if (!pos) return;
    const width = getWidth({
      height: height,
      ratio: img_ratio,
    });
    const x = pos.x / width;
    const y = pos.y / height;
    changeCrosslinePosition({
      x: x,
      y: y,
      resize_position: resize_position,
      index: selected_crossline_index,
      evt: evt,
    });
    // リサイズのポジションを初期に直す
    // これをやらないと、マウスをクリックするたびに線が移動してしまう。
    isDrawing.current = false;
    setResizePosition('');
  };

  // マウスアップ(押下の終了時)
  const onMouseUp = (evt: KonvaEventObject<MouseEvent>) => {
    if (tool === 'pen') {
      addLineOnMouseUp();
    } else if (tool === 'hand') {
      handLineOnMouseUp(evt, resize_position);
    }
  };

  // image全体をクリックした時の関数
  const onImageClick = () => {
    setSelectedCroslineIndex(null);
    setResizePosition('');
  };

  // crosslineをクリックした時の関数
  const onCrosslineClick = (index: number) => {
    if (tool !== 'hand') return;
    setSelectedCroslineIndex(index);
  };

  // マウスが、直線上に侵入した時の関数
  const onMouseEnterCrossLine = (evt: KonvaEventObject<DragEvent>) => {
    if (tool !== 'hand') return;
    const stage = evt.target.getStage();
    if (!stage) return;
    const content = stage.getContent();
    // 先端の丸にポインターが入っている時はスキップ
    if (content.style.cursor === 'nesw-resize') return;
    // オブジェクトにホバーするとcursorが変わる
    content.style.cursor = 'move';
  };
  // マウスが、直線上から離れた時の関数
  const onMouseOutCrossLine = (evt: KonvaEventObject<DragEvent>) => {
    if (tool !== 'hand') return;
    const stage = evt.target.getStage();
    if (!stage) return;
    const content = stage.getContent();
    // オブジェクトのホバーが外れるとcursorが変わる
    content.style.cursor = 'default';
  };

  // 画像を描画する
  const drawImage = (imgsrc: string, trimed_image?: TrimedImage) => {
    const image = new Image();
    image.src = params.trimed_image?.url || imgsrc;
    image.onload = () => {
      let new_img_ratio = img_ratio;
      let new_height = height;
      // trimed_imageが存在するときの処理
      if (trimed_image) {
        const size = trimedImageToSize(trimed_image);
        new_img_ratio = size.width / size.height;
        image.width = size.width;
        image.height = size.height;
        // 高さを更新しておく
        new_height = size.height;
      } else {
        // trimed_imageが存在しない時の処理
        new_img_ratio = image.width / image.height;
        // 横幅が足りない時は、heightを調節
        if (stageParentRef.current && stageParentRef.current.clientWidth) {
          if (height * new_img_ratio > stageParentRef.current.clientWidth) {
            new_height = stageParentRef.current.clientWidth / new_img_ratio;
          }
        }
      }
      setImg(image);
      setImgRario(new_img_ratio);
      setHeight(new_height);
    };
  };

  // 直線を全て消去
  const onClickReset = () => {
    params.onChange([]);
  };

  // 選択されている直線を消去
  const onClickDelete = (selected_index: number | null) => {
    if (selected_index === null) return;
    const new_crosslines = params.crosslines.filter((crossline, i) => i !== selected_index);
    params.onChange(new_crosslines);
    setSelectedCroslineIndex(null);
  };

  // 一番最後に書かれた直線を消去
  const onClickBack = () => {
    const new_crosslines = [...params.crosslines];
    new_crosslines.pop();
    params.onChange(new_crosslines);
  };

  // -- onload function --
  useEffect(() => {
    drawImage(params.imgsrc, params.trimed_image);
  }, [params.imgsrc]); /* eslint-disable-line */

  // -- render part --
  return (
    <Content>
      <TopArea>
        <FlexCenterDiv align_center>
          <SelectableBlueprintButton
            icon={<Icon iconSize={16} icon={IconNames.EDIT} />}
            onClick={() => changeTool('pen')}
            selected={tool === 'pen'}
            title='線を描画するモード'
          />
          <SelectableBlueprintButton
            icon={<Icon iconSize={16} icon={IconNames.HAND} />}
            onClick={() => changeTool('hand')}
            selected={tool === 'hand'}
            title='線を操作するモード'
          />
        </FlexCenterDiv>
        <FlexCenterDiv align_center>
          <RoundedButton
            text='削除'
            onClick={() => onClickDelete(selected_crossline_index)}
            small={true}
            is_margin_right={true}
            disabled={selected_crossline_index === null}
          />
          <RoundedButton text={`最新を消す`} onClick={onClickBack} small={true} is_margin_right={true} />
          <RoundedButton text='リセット' onClick={onClickReset} small={true} is_margin_right={true} />
          <SettingExpImpButton
            crosslines={params.crosslines}
            setCrosslines={params.onChange}
            trimedImage={params.trimed_image}
            hide_import={params.hideImport}
            hide_export={params.hideExport}
            // setTrimedImage={params.setTrimedImage} // とりあえず、今の段階ではここからクロップ座標の変更はできなくする
          />
        </FlexCenterDiv>
      </TopArea>
      <SecondTopArea>
        <CrossLineLegend />
      </SecondTopArea>
      <CenterArea ref={stageParentRef}>
        <Stage
          width={height * img_ratio}
          height={height}
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
          onMouseEnter={onMouseEnterCrossLine}
          onMouseOut={onMouseOutCrossLine}
          ref={stageRef}
        >
          {img && (
            <Layer>
              <ImageKonva image={img} onClick={onImageClick} width={height * img_ratio} height={height} />
              {params.crosslines.map((crossline, i) => {
                const width = getWidth({
                  height: height,
                  ratio: img_ratio,
                });
                return (
                  <KonvaObject
                    index={i}
                    key={i}
                    crossline={crossline}
                    tool={tool}
                    width={width}
                    height={height}
                    selected={i === selected_crossline_index}
                    onClick={() => onCrosslineClick(i)}
                    setCrosslineWhereIndex={setCrosslineWhereIndex}
                    setResizePosition={setResizePosition}
                  />
                );
              })}
            </Layer>
          )}
        </Stage>
      </CenterArea>
    </Content>
  );
};
// -- styled components --

const SecondTopArea = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
`;

const TopArea = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CenterArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${styles.interval_narrow_margin} 0px;
`;

// -- finally export part --

export default DrawDrawCrosslines;
