// -- basic library --
import React, { useEffect, useState } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import history from 'shared/browserHistory';
import Spinner from 'shared/components/atoms/Spinner';
import { PageWholeArea, Title, Content } from 'shared/components/molecules/ContentsArea';
import PageSwitchingTabs from 'shared/components/molecules/PageSwitchingTabs';
import styles from 'shared/styles/styles';
import { Device } from 'user/api/device';
import { devicesIdGetAPI } from 'user/api/device';
import { systemSelector } from 'user/redux/slices/systemSlice';
import { preloadGroup } from 'user/utils/preloadGroup';
import CameraAnalysisResult from './CameraAnalysisResultPage';
import DevicesBasicInformation from './OverviewsPage/OverviewsPage';
import Inferreddatas from './InferreddataPage/Inferreddatas';
import RecordedVideos from './RecordedVideosPage';

// -- main component --

const DevicesDetail: React.FC<
  RouteComponentProps<{
    device_id: string;
    panel_id?: string;
  }>
> = (params) => {
  const system_state = useSelector(systemSelector);

  // -- local states --
  const [device, setDevice] = useState<Device | undefined>(undefined);

  // -- handlers --

  const loadDevice = async (id: string) => {
    const res = await devicesIdGetAPI(id);
    if (res.status === 200) {
      setDevice(res.data);
    }
  };

  // タブを切り替える時に使う関数
  const handleTabsClick = (tab_id: string, device_id: string) => {
    history.push(`/devices/${device_id}/${tab_id}`);
  };

  // -- onload function --
  useEffect(() => {
    preloadGroup(loadDevice(params.match.params.device_id));
  }, [params.match.params.device_id]);

  // -- render part --
  return (
    <PageWholeArea data-testid='DeviceDetail'>
      {system_state.loading.preloading || device === undefined ? (
        <Spinner />
      ) : (
        <Content>
          <Title
            text={device.device_name}
            style={{
              marginTop: styles.interval_narrow_margin,
              marginBottom: styles.interval_margin,
            }}
          />
          <React.Fragment>
            <PageSwitchingTabs
              display_datas={[
                {
                  text: 'デバイス概要',
                  id: 'overviews',
                },
                {
                  text: '録画動画一覧',
                  id: 'recorded-movies',
                },
                {
                  text: '推論動画一覧',
                  id: 'inferred_datas',
                },
                {
                  text: 'カメラ実機解析結果',
                  id: 'camera-analysis-result',
                },
              ]}
              handleClick={(id: string) => handleTabsClick(id, params.match.params.device_id)}
              selected_id={params.match.params.panel_id}
              initial_selected_id='overviews'
            />
            <Switch>
              <Route
                exact
                path='/devices/:device_id/overviews'
                render={() => <DevicesBasicInformation device={device} />}
              />
              <Route
                exact
                path='/devices/:device_id/recorded-movies'
                render={() => <RecordedVideos device={device} />}
              />
              <Route exact path='/devices/:device_id/inferred_datas' render={(props) => <Inferreddatas {...props} />} />
              <Route
                exact
                path='/devices/:device_id/camera-analysis-result'
                render={(props) => <CameraAnalysisResult {...props} />}
              />
            </Switch>
          </React.Fragment>
        </Content>
      )}
    </PageWholeArea>
  );
};

// -- finally export part --

export default DevicesDetail;
