import React, { useState } from 'react';
import { Footer } from 'shared/components/molecules/ContentsArea';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import PageSwitchingTabs from 'shared/components/molecules/PageSwitchingTabs';
import {
  IDETECTION,
  ILINE_COUNTER,
  IMJPEG_SERVER,
  IOD_MATCHER,
  ISCENARIO,
  ISTAY_COUNTER,
  IVIDEO_INPUT,
} from 'user/components/Config';
import { VideoInputTab } from './tabs/VideoInputTab';
import { DetectionTab } from './tabs/DetectionTab';
import { LineCounterTab } from './tabs/LineCounterTab';
import { StayCounterTab } from './tabs/StayCounterTab';
import PfDialog from 'shared/components/atoms/PfDialog';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { colors } from 'shared/styles/colors';

interface EditModalProps {
  isOpen: boolean;
  _id: number;
  value: ISCENARIO;
  OD_MATCHER: IOD_MATCHER;
  MJPEG_SERVER: IMJPEG_SERVER;
  image_url: string;
  onClose: (value?: ISCENARIO, od?: IOD_MATCHER, MJPEG_SERVER?: IMJPEG_SERVER, state?: boolean) => void;
}
export function EditModal(params: EditModalProps) {
  const [VIDEO_INPUT, setVIDEO_INPUT] = useState<IVIDEO_INPUT>(params.value.VIDEO_INPUT || {});
  const [DETECTION, setDETECTION] = useState<IDETECTION>(params.value.DETECTION || {});
  const [LINE_COUNTER, setLINE_COUNTER] = useState<ILINE_COUNTER>(params.value.LINE_COUNTER || {});
  const [STAY_COUNTER, setSTAY_COUNTER] = useState<ISTAY_COUNTER>(params.value.STAY_COUNTER || {});
  const [selectedTab, setSelectedTab] = useState<string>('videoinput');
  const handleSave = () => {
    // デバイス上の保存期間（分）が60～1080の間でなければ正しい値に直す。(undefinedなら初期値の60にする)
    setLINE_COUNTER((prevLineCounter) => ({
      ...prevLineCounter,
      PASSER_MMIN: prevLineCounter?.PASSER_MMIN ? Math.max(60, Math.min(1080, prevLineCounter.PASSER_MMIN)) : 60,
    }));
    // 検知モデル閾値が0～1の間でなければ正しい値に直す。(undefinedなら初期値の0.4にする)
    setDETECTION((prevDetection) => ({
      ...prevDetection,
      CONFIDENCE_THRESHOLD: prevDetection?.CONFIDENCE_THRESHOLD
        ? Math.max(0, Math.min(1, prevDetection.CONFIDENCE_THRESHOLD))
        : 0.4,
    }));
    params.onClose(
      {
        ...params.value,
        VIDEO_INPUT: VIDEO_INPUT,
        DETECTION: DETECTION,
        ATTRIBUTE: params.value.ATTRIBUTE,
        FEATURE: params.value.FEATURE,
        TRACKER: params.value.TRACKER,
        LINE_COUNTER: LINE_COUNTER,
        STAY_COUNTER: STAY_COUNTER,
        OD_MATCHING: params.value.OD_MATCHING,
        METRIC_OUTPUT: params.value.METRIC_OUTPUT,
        VIDEO_OUTPUT: params.value.VIDEO_OUTPUT,
        MJPEG_SERVER_OUTPUT: params.value.MJPEG_SERVER_OUTPUT,
        REPEAT: params.value.REPEAT,
      },
      { ...params.OD_MATCHER },
      { ...params.MJPEG_SERVER },
      true,
    );
  };

  const handleTabsClick = (id: string) => {
    setSelectedTab(id);
  };

  const handleCancelClick = () => {
    params.onClose();
  };

  let tabComponent;
  switch (selectedTab) {
    case 'videoinput':
      tabComponent = (
        <VideoInputTab
          _id={params._id}
          VIDEO_INPUT={VIDEO_INPUT}
          onChange={(value: React.SetStateAction<IVIDEO_INPUT>) => setVIDEO_INPUT(value)}
        />
      );
      break;
    case 'detection':
      tabComponent = <DetectionTab DETECTION={DETECTION} onChange={(value) => setDETECTION(value)} />;
      break;
    case 'lineCounter':
      tabComponent = (
        <LineCounterTab
          LINE_COUNTER={LINE_COUNTER || {}}
          image_url={params.image_url}
          onChange={(value) => setLINE_COUNTER(value || {})}
        />
      );
      break;
    case 'stayCounter':
      tabComponent = (
        <StayCounterTab
          STAY_COUNTER={STAY_COUNTER || {}}
          image_url={params.image_url}
          onChange={(value) => setSTAY_COUNTER(value || {})}
        />
      );
      break;
    default:
      tabComponent = null;
  }
  return (
    <PfDialog
      isOpen={params.isOpen}
      title='AI設定'
      onClose={() => params.onClose()}
      contentProps={{ style: { display: 'flex', flexDirection: 'column' } }}
    >
      <PageSwitchingTabs
        display_datas={[
          { id: 'videoinput', text: '映像入力' },
          { id: 'detection', text: 'AI検知' },
          { id: 'lineCounter', text: 'ラインカウント' },
          { id: 'stayCounter', text: '滞留カウント' },
        ]}
        handleClick={(id: string) => handleTabsClick(id)}
        selected_id={selectedTab}
        initial_selected_id={selectedTab}
      />
      {tabComponent}
      <Footer>
        <RoundedButton text='キャンセル' onClick={() => handleCancelClick()} is_margin_right is_white />
        <RoundedButton text='保存' onClick={handleSave} />
      </Footer>
    </PfDialog>
  );
}

interface EditModalButtonProps {
  _id: number;
  value: ISCENARIO;
  OD_MATCHER: IOD_MATCHER;
  MJPEG_SERVER: IMJPEG_SERVER;
  image_url: string;
  onClose: (value?: ISCENARIO, od?: IOD_MATCHER, MJPEG_SERVER?: IMJPEG_SERVER, state?: boolean) => void;
}

/** AI設定ダイアログを開くためのボタンです。 */
export function EditModalButton(params: EditModalButtonProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = (value?: ISCENARIO, od?: IOD_MATCHER, MJPEG_SERVER?: IMJPEG_SERVER, state?: boolean) => {
    if (value && od && MJPEG_SERVER && state) {
      handleSave(value, od, MJPEG_SERVER, state);
    } else {
      setIsOpen(false);
    }
  };
  const handleSave = (value: ISCENARIO, od: IOD_MATCHER, MJPEG_SERVER: IMJPEG_SERVER, state: boolean) => {
    ConfirmDialog.show(
      <div style={{ color: colors.red }}>
        [確認]AI設定を変更した場合、元の設定に戻せません。
        <br />
        本当によろしいですか?
      </div>,
      () => save(value, od, MJPEG_SERVER, state),
      undefined,
      undefined,
    );
  };
  const save = (value: ISCENARIO, od: IOD_MATCHER, MJPEG_SERVER: IMJPEG_SERVER, state: boolean) => {
    setIsOpen(false);
    params.onClose(value, od, MJPEG_SERVER, state);
  };
  return (
    <>
      <RoundedButton text='設定' onClick={handleOpen} />
      {isOpen && params.image_url && (
        <EditModal
          isOpen={isOpen}
          _id={0}
          value={params.value}
          OD_MATCHER={params.OD_MATCHER || {}}
          MJPEG_SERVER={params.MJPEG_SERVER || {}}
          image_url={params.image_url}
          onClose={(scenario?: ISCENARIO, od?: IOD_MATCHER, MJPEG_SERVER?: IMJPEG_SERVER, state?: boolean) =>
            handleClose(scenario, od, MJPEG_SERVER, state)
          }
        />
      )}
    </>
  );
}
