// -- basic library --
import React from 'react';
import InputNumberBox from 'shared/components/atoms/InputNumberBox';
import styles from 'shared/styles/styles';
import styled from 'styled-components';

// -- external datas --

// -- type declaration --
interface Params {
  handleChangeClick1: (value: number) => void;
  handleChangeClick2: (value: number) => void;
  value1: number;
  value2: number;
  value2_disabled?: boolean;
  interval_text: string;
  test_id?: string;
  placeholders?: string[];
  steps?: number[];
  mins?: (number | string | undefined)[];
  maxes?: (number | string | undefined)[];
  disabled?: boolean;
  value_texts?: string[];
  style?: React.CSSProperties;
}

// -- main component --

const InputDoubleNumberBox: React.FC<Params> = (params) => {
  // -- render part --
  return (
    <WholeArea>
      {params.value_texts ? (
        <TextArea>
          <ValueText>{params.value_texts[0] ? params.value_texts[0] : ''}</ValueText>
          <IntervalText></IntervalText>
          <ValueText>{params.value_texts[1] ? params.value_texts[1] : ''}</ValueText>
        </TextArea>
      ) : null}

      <InputArea>
        <InputNumberBox
          placeholder={params.placeholders ? params.placeholders[0] : ''}
          min={params.mins ? params.mins[0] : undefined}
          max={params.maxes ? params.maxes[0] : undefined}
          step={params.steps ? params.steps[0] : undefined}
          onChange={params.handleChangeClick1}
          value={params.value1}
          style={{ width: 'calc((100% - 40px) / 2)' }}
        />
        {params.value2_disabled ? null : (
          <>
            <IntervalText>{params.interval_text}</IntervalText>
            <InputNumberBox
              placeholder={params.placeholders ? params.placeholders[1] : ''}
              min={params.mins ? params.mins[1] : undefined}
              max={params.maxes ? params.maxes[1] : undefined}
              step={params.steps ? params.steps[1] : undefined}
              onChange={params.handleChangeClick2}
              value={params.value2}
              style={{ width: 'calc((100% - 40px) / 2)' }}
            />
          </>
        )}
      </InputArea>
    </WholeArea>
  );
};

// -- styled components --

const WholeArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TextArea = styled.div`
  width: 100%;
  height: 12px;
  margin-bottom: 3px;
  display: flex;
`;

const InputArea = styled.div`
  width: 100%;
  height: ${styles.input_component_height};
  display: flex;
  align-items: center;
`;

const IntervalText = styled.div`
  width: auto;
  white-space: nowrap;
  text-align: center;
  margin: 20px 10px;
  font-size: ${styles.small_text_size};
  font-weight: ${styles.font_weight};
`;

const ValueText = styled.div`
  width: calc((100% - 40px) / 2);
  white-space: nowrap;
  font-size: 0.75em;
`;

// -- finally export part --

export default InputDoubleNumberBox;
