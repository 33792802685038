import React, { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import OverWrappedLoader from 'shared/components/atoms/OverWrappedLoader';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import InputDialog from 'shared/components/molecules/InputDialog';
import PfFooter from 'shared/components/molecules/PfFooter';
import ProgressPercentDialog from 'shared/components/molecules/ProgressPercentDialog';
import ProgressPercentNumberDialog from 'shared/components/molecules/ProgressPercentNumberDialog';
import FileProcessesPercentNumberDialog from 'shared/components/molecules/FileProcessesPercentNumberDialog';
import styles from 'shared/styles/styles';
import styled from 'styled-components';
import PfUserHeader from 'user/components/molecules/PfUserHeader';
import { authSelector } from 'user/redux/slices/authSlice';
import { systemSelector } from 'user/redux/slices/systemSlice';
import { PfUserSideMenu } from './PfUserSideMenu';

interface PfDocumentProps {
  children?: ReactNode;
}
/**
 * ページ全体
 */
const PfDocument: React.FC<PfDocumentProps> = (props) => {
  // -- redux preparations --
  const auth_state = useSelector(authSelector);
  const system_state = useSelector(systemSelector);

  // -- render part --
  return auth_state.is_logged_in ? (
    <StyledDocument className='base' isloading={system_state.loading.isActive}>
      <PfUserSideMenu />
      <Body>
        <PfUserHeader />
        <Content>{props.children}</Content>
      </Body>
      <PfFooter />

      {system_state.loading.isActive && <OverWrappedLoader />}
      <ProgressPercentDialog />
      <AlertDialog />
      <ConfirmDialog />
      <InputDialog />
      <ProgressPercentNumberDialog />
      <FileProcessesPercentNumberDialog />
    </StyledDocument>
  ) : (
    <ConvRedirect to='/login' />
  );
};

const ConvRedirect: React.FC<{ to: string }> = (params) => {
  const url = window.location.href;
  const to = params.to + '?redirect=' + encodeURIComponent(url);
  return <Redirect to={to} />;
};

// -- styled components --
const StyledDocument = styled.div<{
  isloading: boolean;
}>`
  display: flex;
  position: relative;
  opacity: ${(params) => (params.isloading ? 0.6 : 1.0)};
  pointer-events: ${(params) => (params.isloading ? 'none' : 'all')};
  height: 100vh;
  width: 100vw;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - ${styles.sidemenu_width});
`;

const Content = styled.div`
  width: 100%;
  height: calc(100% - ${styles.topmenu_height} - ${styles.footer_height});
  padding-top: 1px;
  overflow: hidden;
`;

// -- finally export part --

export default PfDocument;
