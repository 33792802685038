import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import InputBox from 'shared/components/atoms/InputBox';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Content, Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import SelectBoxDataStorePeriod from 'shared/components/molecules/SelectBoxDataStorePeriod';
import RadioBox from 'shared/components/molecules/RadioBox';
import { isNotOnlySpace } from 'shared/utils/is';

import { Stream, streamsIdPutAPI, RequestStreamsIdPut } from 'user/api/streams';
import { isTimeStamp } from 'shared/models/StreamDataNumberType';
import { authSelector } from 'user/redux/slices/authSlice';
import {
  DataStorePeriodType,
  getDataStorePeriod,
  isValidDataStorePeriod,
  secondsToDataStorePeriod,
  translateDataStorePeriodToJp,
} from 'shared/models/DataStorePeriod';
import { InputJsonpathGroup } from 'user/dialogs/CsvRowsInputDialog';
import DatetimeInput from 'shared/components/atoms/DatetimeInput';
// -- type declaration --
type Param = {
  isOpen: boolean;
  stream: Stream;
  onCancelClose: () => void;
  onOkClose: () => void;
};

// -- main component --

const DetailStreamDialog: React.FC<Param> = (params) => {
  const auth_state = useSelector(authSelector);

  // -- local states --
  const [stream_name, setStreamName] = useState<string>(params.stream.stream_name || '');
  const [data_store_period, setDataStorePeriod] = useState<DataStorePeriodType>(
    secondsToDataStorePeriod(params.stream.data_store_period),
  );
  const change_period = params.stream.streaming_endpoint === null;
  const [category, setCategory] = useState<string>(params.stream.category);
  const [detail_jsonpath_group_id, setDetailJsonpathGroupId] = useState<string>(
    params.stream.detail_jsonpath_group_id || '',
  );
  const [auto_csv_type, setAutoCsvType] = useState<string>(params.stream.auto_csv_type || 'NONE');
  const [type_changed_at, setTypeChangedAt] = useState<number | undefined>(params.stream.type_changed_at || undefined);
  const [person_age_type, setPersonAgeType] = useState<number>(params.stream.person_age_type || 5);
  const [car_type, setCarType] = useState<number>(params.stream.car_type || 19);

  // -- checkers --

  const handleTypeChangedAtChange = (value: string | number) => {
    if (typeof value === 'string') {
      const new_value = value.length > 0 ? Number(value) : NaN;
      setTypeChangedAt(new_value);
    } else {
      setTypeChangedAt(value);
    }
  };

  // varidation
  /**
   * 入力チェックを行い、エラーの場合はメッセージを含む例外をスローします。
   */
  const validate = () => {
    if (!isNotOnlySpace(stream_name)) {
      throw new Error('データ名を入力してください');
    }
    if (isTimeStamp(params.stream.data_number_type) && !isValidDataStorePeriod(data_store_period)) {
      throw new Error('データ保存期間は1 ~ 9999の整数でなければいけません');
    }
  };

  const validateB = () => {
    try {
      validate();
    } catch (e) {
      return false;
    }
    return true;
  };

  // -- handlers --
  const handleFinishClick = async (inputs: {
    stream_id: string;
    stream_name: string;
    category: string;
    data_store_period: DataStorePeriodType;
    detail_jsonpath_group_id: string;
    auto_csv_type: string;
    type_changed_at: number | undefined;
    person_age_type: number;
    car_type: number;
  }) => {
    // 入力チェック
    try {
      validate();
    } catch (e) {
      if (e instanceof Error) {
        AlertDialog.show(e.message);
      }
      return;
    }

    const request_bodies: RequestStreamsIdPut = {
      stream_id: inputs.stream_id,
      stream_name: inputs.stream_name,
      category: inputs.category,
      detail_jsonpath_group_id: inputs.detail_jsonpath_group_id,
      auto_csv_type: inputs.auto_csv_type,
      type_changed_at: inputs.type_changed_at,
      person_age_type: inputs.person_age_type,
      car_type: inputs.car_type,
    };

    if (isTimeStamp(params.stream.data_number_type) && params.stream.data_type !== 'METRIC') {
      // TIMESTAMPの時はバリデーション後にdata_store_periodを格納
      request_bodies.data_store_period = String(
        getDataStorePeriod(data_store_period.unit, data_store_period.data_store_period),
      );
    }
    const res = await streamsIdPutAPI(request_bodies);
    if (res.status === 200) {
      AlertDialog.show('データの更新に成功しました');
      params.onOkClose();
    }
  };

  // -- render part --
  return (
    <PfDialog isOpen={params.isOpen} title='データ詳細' onClose={params.onCancelClose}>
      <Content>
        {params.stream !== undefined ? (
          <>
            <InputComponent text='データ名' required>
              <InputBox
                title='データ名'
                placeholder='入力してください'
                value={stream_name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setStreamName(e.currentTarget.value)}
                disabled={!auth_state.is_admin}
              />
            </InputComponent>
            <InputComponent text='送信元'>
              <RadioBox
                id='category'
                datas={[
                  { name: 'counter', value: 'COUNTER' },
                  { name: 'counterBox', value: 'COUNTERBOX' },
                  { name: '指定なし', value: '' },
                ]}
                selectedValue={category}
                handleChangeClick={(v) => setCategory(v)}
              />
            </InputComponent>
            {params.stream.data_number_type === 'TIMESTAMP' && params.stream.data_type === 'METRIC' && (
              <InputComponent text='詳細画面のJSONパスグループ'>
                <InputJsonpathGroup
                  value={detail_jsonpath_group_id}
                  onChange={(e) => setDetailJsonpathGroupId(e?.jsonpath_group_id || '')}
                  display={'DASHBOARD'}
                  category={category}
                />
              </InputComponent>
            )}
            <InputComponent text='CSV形式' required>
              <RadioBox
                datas={[
                  { name: '人カウント', value: 'LINECOUNT_PERSON' },
                  { name: '車カウント', value: 'LINECOUNT_CAR' },
                  { name: '人＋車カウント', value: 'LINECOUNT_PERSON_CAR' },
                  { name: '滞留', value: 'STAYCOUNT' },
                  { name: 'その他', value: 'NONE' },
                ]}
                selectedValue={auto_csv_type}
                handleChangeClick={setAutoCsvType}
                data-testid='auto_csv_type' />
            </InputComponent>
            <InputComponent text='人年代区分'>
              <RadioBox
                id='person_age_type'
                datas={[
                  { name: '3区分', value: '3' },
                  { name: '5区分', value: '5' },
                ]}
                selectedValue={String(person_age_type)}
                handleChangeClick={(v) => setPersonAgeType(Number(v))}
              />
            </InputComponent>
            <InputComponent text='車属性区分'>
              <RadioBox
                id='car_type'
                datas={[
                  { name: '7区分', value: '7' },
                  // 2023/08/27現在 外部公開上は「17区分」のため、内部値とラベルに差異があります
                  // see https://idea.cloud.redmine.jp/issues/11989#note-6
                  { name: '17区分', value: '19' },
                ]}
                selectedValue={String(car_type)}
                handleChangeClick={(v) => setCarType(Number(v))}
              />
            </InputComponent>
            <InputComponent text='属性区分の切替日'>
              <DatetimeInput
                id='type_changed_at'
                type='datetime-local'
                value={Number.isNaN(type_changed_at) ? '' : type_changed_at}
                onChange={e => handleTypeChangedAtChange(e.currentTarget.value)}
                step='1'
              />
            </InputComponent>
            <InputComponent text='データ番号の基準'>
              <div>{params.stream.data_number_type}</div>
            </InputComponent>
            <InputComponent text='データの種類'>
              <div>{params.stream.data_type}</div>
            </InputComponent>
            {/* TIMESTAMPの時のみ表示*/}
            {isTimeStamp(params.stream.data_number_type) && params.stream.data_type !== 'METRIC' && change_period ? (
              <InputComponent text='データ保存期間' required>
                <SelectBoxDataStorePeriod
                  data_store_period={data_store_period}
                  setDataStorePeriod={setDataStorePeriod}
                />
              </InputComponent>
            ) : (
              <InputComponent text='データ保存期間'>
                <div>{translateDataStorePeriodToJp(data_store_period)}</div>
              </InputComponent>
            )}
            <Footer>
              {auth_state.is_admin && (
                <RoundedButton
                  onClick={() =>
                    handleFinishClick({
                      stream_id: params.stream.stream_id,
                      stream_name: stream_name,
                      category: category,
                      data_store_period: data_store_period,
                      detail_jsonpath_group_id: detail_jsonpath_group_id,
                      auto_csv_type: auto_csv_type,
                      type_changed_at: type_changed_at,
                      person_age_type: person_age_type,
                      car_type: car_type,
                    })
                  }
                  text_type='UPDATE'
                  disabled={!validateB()}
                />
              )}
            </Footer>
          </>
        ) : (
          <Spinner />
        )}
      </Content>
    </PfDialog>
  );
};

// -- styled components --

// -- finally export part --

export default DetailStreamDialog;
