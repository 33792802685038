import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from '@blueprintjs/core';
import InputBox from 'shared/components/atoms/InputBox';
import InputNumberBox from 'shared/components/atoms/InputNumberBox';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import RadioBox from 'shared/components/molecules/RadioBox';
import SelectBoxDataStorePeriod from 'shared/components/molecules/SelectBoxDataStorePeriod';
import StreamDataTypeRadioBox from 'shared/components/molecules/StreamDataTypeRadioBox';
import { isTimeStamp } from 'shared/models/StreamDataNumberType';

import { isNotOnlySpace, isValidNumber, isBetweenRange } from 'shared/utils/is';
import { DataStorePeriodType, getDataStorePeriod, isValidDataStorePeriod } from 'shared/models/DataStorePeriod';
import { CachedProcesses, Process } from 'user/api/processes';
import { RequestStreamsPost, streamsPostAPI } from 'user/api/streams';
import { SafieAuthButton } from 'user/dialogs/SafieAuthDialog';
import SelectBox from 'shared/components/atoms/SelectBox';
import { streaming_reception_billing_options } from 'shared/utils/constants';
import { InputJsonpathGroup } from 'user/dialogs/CsvRowsInputDialog';

/**
 * Safie受信プロセス
 * （この変数はグローバルなので、ダイアログを何度も表示しても、初回しかAPIはコールされません）
 */
const global_safie_process = new CachedProcesses({ category: 'safie', visibility: 'all' });

interface Params {
  isOpen: boolean;
  onClose: (canceled?: boolean) => void;
}
/**
 * ストリーム登録ダイアログです。
 *
 * このダイアログで通常のストリームの他、RTMP/RTSP受信ストリームの登録ができます。
 */
const CreateStreamDialog: React.FC<Params> = (params) => {
  const [stream_name, setStreamName] = useState<string>('');
  const [creation_type, setCreationType] = useState<'normal' | 'rt' | 'safie'>('normal');
  const [data_number_type, setDataNumberType] = useState<string>('');
  const [data_type, setDataType] = useState<string>('');
  const [create_rt_stream, setCreateRtStream] = useState<'RTMP' | 'RTSP'>('RTMP');
  const [category, setCategory] = useState<'COUNTERBOX' | ''>('COUNTERBOX');
  const [fps, setFps] = useState<number>(5);
  const [kbps, setKbps] = useState<number>(200);
  const [width, setWidth] = useState<number>(720);
  const [height, setHeight] = useState<number>(-1);
  const [data_store_period, setDataStorePeriod] = useState<DataStorePeriodType>({
    data_store_period: -1,
    unit: 'UNSPECIFIED',
  });
  const [safie_process, setSafieProcess] = useState<Process>();
  const [safie_info, setSafieInfo] = useState<string>('');
  const [detail_jsonpath_group_id, setDetailJsonpathGroupId] = useState<string>('');

  const handleDataTypeChangeClick = (value: string) => {
    setDataType(value);
  };

  /**
   * 入力チェックを行い、エラーの場合はメッセージを含む例外をスローします。
   */
  const validate = () => {
    if (!isNotOnlySpace(stream_name)) {
      throw new Error('データ名を入力してください');
    }
    if (creation_type === 'normal') {
      if (!data_number_type.length) {
        throw new Error('データ番号の基準を選択してください');
      }
      if (!data_type.length) {
        throw new Error('データの種類を選択してください');
      }
    } else if (creation_type === 'safie') {
      if (safie_info === '') {
        throw new Error('Safie認証を行ってください');
      }
      // JSON文字列を解析して必要なパラメータの入力済みを確認
      try {
        const o = JSON.parse(safie_info);
        if (!o.code || !o.device_id) {
          throw new Error('Safie認証を行ってください');
        }
      } catch (e) {
        throw new Error('Safie認証を行ってください');
      }
    } else {
      if (!isValidNumber({ num: fps }) || !isBetweenRange(fps, 1, 60)) {
        throw new Error('フレームレート(fps)は 1 ~ 60 の範囲で指定してください');
      }
      if (!isValidNumber({ num: kbps }) || !isBetweenRange(kbps, 1, 4096)) {
        throw new Error('ビットレート(kbps)は 1 ~ 4096 の範囲で指定してください');
      }
      if (!isValidNumber({ num: width }) || !isBetweenRange(width, 1, 7680)) {
        throw new Error('映像の幅(width)は 1 ~ 7680 の範囲で指定してください');
      }
      if (height !== -1 && (!isValidNumber({ num: height }) || !isBetweenRange(height, 1, 4320))) {
        throw new Error('映像の高さ(height)は -1(アスペクト比を維持)か、 1 ~ 4320 の範囲で指定してください');
      }
    }
    if (isTimeStamp(data_number_type) && !isValidDataStorePeriod(data_store_period)) {
      throw new Error('データ保存期間は1 ~ 9999の整数でなければいけません');
    }
    if (creation_type === 'rt' && data_store_period.data_store_period === -1) {
      throw new Error('データ保存期間を選択してください');
    }
  };

  const validateB = () => {
    try {
      validate();
    } catch (e) {
      return false;
    }
    return true;
  };

  const handleFinish = () => {
    // 入力チェック
    try {
      validate();
    } catch (e) {
      if (e instanceof Error) {
        AlertDialog.show(e.message);
      }
      return;
    }
    let request_bodies: RequestStreamsPost = {
      stream_name,
    };
    // TIMESTAMPの時またはストリーミング受信の時、data_store_periodを送る
    if (isTimeStamp(data_number_type) || creation_type === 'rt') {
      request_bodies.data_store_period = String(
        getDataStorePeriod(data_store_period.unit, data_store_period.data_store_period),
      );
    }
    if (creation_type === 'normal') {
      request_bodies = {
        ...request_bodies,
        data_number_type: data_number_type,
        data_type: data_type,
      };
      if (data_number_type === 'TIMESTAMP' && data_type === 'METRIC' && detail_jsonpath_group_id) {
        request_bodies.detail_jsonpath_group_id = detail_jsonpath_group_id;
      }
      streamsPostAPI(request_bodies).then((res) => {
        if (res.status === 200) {
          AlertDialog.show('新規データの追加に成功しました', () => {
            params.onClose();
          });
        }
      });
    } else if (creation_type === 'safie') {
      request_bodies = {
        ...request_bodies,
        create_rt_stream: 'Safie',
        safie_info: safie_info,
      };
      streamsPostAPI(request_bodies).then((res) => {
        if (res.status === 200) {
          AlertDialog.show('新規データの追加に成功しました', () => {
            params.onClose();
          });
        }
      });
    } else {
      request_bodies = {
        ...request_bodies,
        create_rt_stream: create_rt_stream,
        category: category,
        fps: String(fps),
        kbps: String(kbps),
        width: String(width),
        height: String(height),
      };
      streamsPostAPI(request_bodies).then((res) => {
        if (res.status === 200) {
          AlertDialog.show('新規データの追加に成功しました', () => {
            params.onClose();
          });
        }
      });
    }
  };

  // Safieプロセスの取得
  useEffect(() => {
    global_safie_process.get().then((res) => {
      if (res.length !== 0) {
        const p = res[0];
        try {
          setSafieInfo(p.app_parameters[0].default_value);
          setSafieProcess(p);
          // eslint-disable-next-line no-empty
        } catch (e) {}
      }
    });
  }, []);

  return (
    <PfDialog isOpen={params.isOpen} onClose={() => params.onClose(true)} title='入力データ作成'>
      <InputComponent text='データ名' required>
        <InputBox
          title='データ名'
          placeholder='入力してください'
          value={stream_name}
          onChange={(e) => setStreamName(e.currentTarget.value)}
        />
      </InputComponent>
      <Tabs
        id='CreationType'
        onChange={(n) => setCreationType(n === 'rt' ? 'rt' : n === 'safie' ? 'safie' : 'normal')}
        selectedTabId={creation_type}
      >
        <Tab
          id='normal'
          title='データアップロード'
          panel={
            <>
              <InputComponent text='データ番号の基準' required>
                <RadioBox
                  datas={[
                    { name: 'タイムスタンプ', value: 'TIMESTAMP' },
                    { name: '連続した番号', value: 'SEQUENCE' },
                    { name: 'ユーザー指定', value: 'USER_SPECIFIC' },
                  ]}
                  selectedValue={data_number_type}
                  handleChangeClick={setDataNumberType}
                />
              </InputComponent>
              <InputComponent text='データの種類' required>
                <StreamDataTypeRadioBox data_type={data_type} setDataType={handleDataTypeChangeClick} />
              </InputComponent>
              <InputComponent text='送信元' required>
                <RadioBox
                  id='category'
                  datas={[
                    { name: 'counterBox', value: 'COUNTERBOX' },
                    { name: '指定なし', value: '' },
                  ]}
                  selectedValue={category}
                  handleChangeClick={(v) => setCategory(v === 'COUNTERBOX' ? 'COUNTERBOX' : '')}
                />
              </InputComponent>
              {data_number_type === 'TIMESTAMP' && data_type === 'METRIC' && (
                <InputComponent text='詳細画面のJSONパスグループ'>
                  <InputJsonpathGroup
                    value={detail_jsonpath_group_id}
                    onChange={(e) => setDetailJsonpathGroupId(e?.jsonpath_group_id || '')}
                    display={'DASHBOARD'}
                    category={category}
                  />
                </InputComponent>
              )}
            </>
          }
        />
        <Tab
          id='rt'
          title='ストリーミング受信'
          panel={
            <>
              <InputComponent text='プロトコル' required>
                <RadioBox
                  id='protocol'
                  datas={[
                    { name: 'RTMP', value: 'RTMP' },
                    { name: 'RTSP', value: 'RTSP' },
                  ]}
                  selectedValue={create_rt_stream}
                  handleChangeClick={(v) => setCreateRtStream(v === 'RTMP' ? 'RTMP' : 'RTSP')}
                />
              </InputComponent>
              <InputComponent text='フレームレート(fps)' required>
                <InputNumberBox value={fps} onChange={setFps} />
              </InputComponent>
              <InputComponent text='ビットレート(kbps)' required>
                <InputNumberBox value={kbps} onChange={setKbps} />
              </InputComponent>
              <InputComponent text='映像の幅(width)' required>
                <InputNumberBox value={width} onChange={setWidth} />
              </InputComponent>
              <InputComponent text='映像の高さ(height)' required>
                <InputNumberBox value={height} onChange={setHeight} />
              </InputComponent>
              <InputComponent text='データ保存期間' required>
                <SelectBox
                  onChange={(e) =>
                    setDataStorePeriod({ data_store_period: Number(e.currentTarget.value), unit: 'DAY' })
                  }
                  value={String(data_store_period.data_store_period)}
                  datas={streaming_reception_billing_options}
                />
              </InputComponent>
            </>
          }
        />
        {safie_process && (
          <Tab
            id='safie'
            title='Safieからの取り込み'
            panel={
              <>
                <InputComponent text='Safie認証' required>
                  <SafieAuthButton value={safie_info} onChange={(value) => setSafieInfo(value)} />
                </InputComponent>
              </>
            }
          />
        )}
      </Tabs>
      {/* TIMESTAMPの時かつデータアップロード時のみ表示*/}
      {isTimeStamp(data_number_type) && creation_type === 'normal' && (
        <InputComponent text='データ保存期間' required>
          <SelectBoxDataStorePeriod data_store_period={data_store_period} setDataStorePeriod={setDataStorePeriod} />
        </InputComponent>
      )}
      <Footer>
        <RoundedButton onClick={() => handleFinish()} text_type='CREATE' disabled={!validateB()} />
      </Footer>
    </PfDialog>
  );
};

export default CreateStreamDialog;
