import { memo } from 'react';
import { Content } from 'shared/components/molecules/ContentsArea';
import { Device } from 'user/api/device';
import DevicesGridCard from './DevicesGridCard';

export interface DevicesGridProps {
  devices: Device[];
}

// -- main component --
const DevicesGrid = memo(function DevicesGrid(props: DevicesGridProps) {
  // -- render part --
  return (
    <Content style={{ display: 'flex', flexWrap: 'wrap' }}>
      {props.devices.map((device, i) => {
        return <DevicesGridCard device={device} key={i} />;
      })}
    </Content>
  );
});

// -- finally export part --

export default DevicesGrid;
