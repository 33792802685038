// -- basic library --
import React, { useEffect, useState } from 'react';
import InputBox from 'shared/components/atoms/InputBox';
import PfBoldText from 'shared/components/atoms/PfBoldText';
import PfDialog, { PfDialogProps } from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import CheckBoxWithText from 'shared/components/molecules/CheckBoxWithText';
import { Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import { toStringBoolean } from 'shared/models/StringBoolean';
import { isNotOnlySpace } from 'shared/utils/is';
import { dateToYMDHMS } from 'shared/utils/converter/date';
import { jsonpathGroupsIdGetAPI } from 'user/api/jsonpathGroups';
import { Stream } from 'user/api/streams';
import {
  streamsIdPackagesNumberGetAPI,
  StreamPackage,
  streamsIdPackagesNumPutAPI,
  RequestStreamsIdPackagesNumberPut,
} from 'user/api/streamsPackage';

// -- external datas --

// -- external functions --
import CSVTable from './CSVTable';
import { JsonpathGroup } from 'user/api/jsonpathGroups';
import { exportExportCsvStatisticsToName } from 'shared/models/ExportCsvStatistics';
import { exportCsvTimeUnitToName } from 'shared/models/ExportCsvTimeUnit';
import getDlpkgLabel from 'user/utils/getDlpkgLabel';

/*** ストリームパッケージの詳細表示ダイアログ***/
interface Params extends PfDialogProps {
  stream: Stream;
  stream_package_number: string;
  stream_packages?: StreamPackage[];
  jsonpath_group_id?: string;
  isOpen: boolean;
  onClose: () => void;
  onCopyCreate: () => void;
  is_hide_copy_button?: boolean;
  style?: React.CSSProperties;
}

// -- main component --
const DetailStreamPackageDialog: React.FC<Params> = (params) => {
  // -- redux preparations --
  const [stream_package, setStreamPackage] = useState<StreamPackage | undefined>(undefined);
  const [stream_package_name, setStreamPackageName] = useState<string>('');
  const [favorite, setFavorite] = useState<boolean>(false);
  const [jsonpath_group, setJsonpathGroup] = useState<JsonpathGroup>();

  // ストリームパッケージを得る関数
  const getStreamPackage = async (stream_id: string, stream_package_number: string) => {
    // stream_packages内で探す
    let stream_package = params.stream_packages?.find(
      (sp) => String(sp.stream_package_number) === stream_package_number,
    );
    //なければ個別に呼び出す
    if (!stream_package) {
      const res = await streamsIdPackagesNumberGetAPI({
        stream_id: stream_id,
        stream_package_number: stream_package_number,
      });
      if (res.status === 200) {
        stream_package = res.data;
      }
    }
    setStreamPackage(stream_package);
    if (stream_package) {
      setStreamPackageName(stream_package.stream_package_name);
      setFavorite(stream_package.favorite);
      if (stream_package.jsonpath_group_id) {
        const jsonpath_group = await jsonpathGroupsIdGetAPI({ jsonpath_group_id: stream_package.jsonpath_group_id });
        if (jsonpath_group.status === 200) {
          setJsonpathGroup(jsonpath_group.data);
        }
      }
    }
  };

  const postStreamPackage = async (stream_id: string, stream_package_number: string) => {
    const request_bodies: RequestStreamsIdPackagesNumberPut = {
      stream_id: stream_id,
      stream_package_number: stream_package_number,
      stream_package_name: stream_package_name,
      favorite: toStringBoolean(favorite),
    };

    if (!isNotOnlySpace(stream_package_name)) {
      AlertDialog.show('ストリームパッケージ名は必須です');
      return;
    }
    const res = await streamsIdPackagesNumPutAPI(request_bodies);
    if (res.status === 200) {
      params.onClose();
      AlertDialog.show('ストリームパッケージの更新に成功しました。');
    }
  };

  // -- handlers --
  // -- onload function --
  useEffect(() => {
    (async function () {
      // プロセス情報の取得
      await getStreamPackage(params.stream.stream_id, params.stream_package_number);
    })();
  }, []); /* eslint-disable-line */

  // -- render part --
  return (
    <PfDialog {...params} title={getDlpkgLabel(params.stream) + '詳細'} large>
      {stream_package === undefined ? (
        <Spinner />
      ) : (
        <>
          <InputComponent text='お気に入り'>
            <CheckBoxWithText text='登録する' checked={favorite} onClick={() => setFavorite(!favorite)} />
          </InputComponent>
          <InputComponent text='ストリームパッケージ名' required>
            <InputBox
              value={stream_package_name}
              onChange={(e) => setStreamPackageName(e.currentTarget.value)}
              placeholder='入力してください(必須)'
            />
          </InputComponent>
          <InputComponent text='対象データ'>
            {params.stream.data_number_type === 'TIMESTAMP' ? (
              <div>
                {dateToYMDHMS(stream_package.stream_data_number_from * 1000)}~
                {dateToYMDHMS(stream_package.stream_data_number_to * 1000)}
              </div>
            ) : (
              <div>
                {stream_package.stream_data_number_from}~{stream_package.stream_data_number_to}
              </div>
            )}
          </InputComponent>
          <InputComponent text='出力ファイル名'>
            <div>{stream_package.export_filename}</div>
          </InputComponent>
          {stream_package.package_type === 'CSV' ? (
            <>
              <PfBoldText>CSV</PfBoldText>
              <InputComponent text='CSVエンコード'>
                <div>{stream_package.export_csv_encode}</div>
              </InputComponent>
              {stream_package.jsonpath_group_id ? (
                <InputComponent text='JSONパスグループ'>
                  {jsonpath_group ? <div>{jsonpath_group?.jsonpath_group_name}</div> : <Spinner />}
                </InputComponent>
              ) : null}
              <InputComponent text='集計'>
                {exportExportCsvStatisticsToName(stream_package.export_csv_statistics)}
              </InputComponent>
              {stream_package.export_csv_statistics === 'TIMESTAMP' ? (
                <InputComponent text='集計する時間単位'>
                  {exportCsvTimeUnitToName(stream_package.export_csv_time_unit)}
                </InputComponent>
              ) : (
                <InputComponent text='A列にタイムスタンプを出力'>
                  {stream_package.export_csv_stream_data_number === 'None' ? '出力しない' : '出力する'}
                </InputComponent>
              )}
              <InputComponent text='CSV定義'>
                {stream_package.csv_rows.length === 0 && jsonpath_group !== undefined ? (
                  <CSVTable
                    bodies={jsonpath_group.csv_rows.map((r) => {
                      return {
                        header_name: r.header_name,
                        json_path: r.json_path,
                        cell_format: r.cell_format,
                        cell_format_args: r.cell_format_args === null ? '' : r.cell_format_args,
                        statistic_method: r.statistic_method,
                        fill: r.fill ? 'True' : 'False',
                      };
                    })}
                    showStatistics={stream_package.export_csv_statistics === 'KEY'}
                  />
                ) : (
                  <CSVTable
                    bodies={stream_package.csv_rows.map((r) => {
                      return {
                        header_name: r.header_name,
                        json_path: r.json_path,
                        cell_format: r.cell_format,
                        cell_format_args: r.cell_format_args === null ? '' : r.cell_format_args,
                        statistic_method: r.statistic_method,
                        fill: r.fill ? 'True' : 'False',
                      };
                    })}
                    showStatistics={stream_package.export_csv_statistics === 'KEY'}
                  />
                )}
              </InputComponent>
            </>
          ) : null}
          {stream_package.package_type === 'AUTO_CSV' ? (
            <>
              <PfBoldText>CSV</PfBoldText>
              <InputComponent text='CSVエンコード'>
                <div>{stream_package.export_csv_encode}</div>
              </InputComponent>
              <InputComponent text='CSV形式'>
                <div>{stream_package.export_auto_csv_type}</div>
              </InputComponent>
              <InputComponent text='集計'>
                {exportExportCsvStatisticsToName(stream_package.export_csv_statistics)}
              </InputComponent>
              {stream_package.export_csv_statistics === 'TIMESTAMP' && (
                <InputComponent text='集計する時間単位'>
                  {exportCsvTimeUnitToName(stream_package.export_csv_time_unit)}
                </InputComponent>
              )}
            </>
          ) : null}
          {stream_package.package_type === 'MP4' ? (
            <>
              <PfBoldText>動画</PfBoldText>
              <InputComponent text='FPS'>
                <div>{stream_package.fps}</div>
              </InputComponent>
              <InputComponent text='解像度'>
                <div>
                  幅 {stream_package.width} × 高さ {stream_package.height}
                </div>
              </InputComponent>
            </>
          ) : null}
          <Footer>
            <RoundedButton
              text_type='UPDATE'
              onClick={() => postStreamPackage(params.stream.stream_id, params.stream_package_number)}
            />
            {!params.is_hide_copy_button && (
              <RoundedButton text='同じ条件で新規作成' onClick={params.onCopyCreate} is_margin_left />
            )}
          </Footer>
        </>
      )}
    </PfDialog>
  );
};

// -- finally export part --

export default DetailStreamPackageDialog;
