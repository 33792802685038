import { AxiosResponse } from 'axios';
import sendAxios from 'shared/axios/sendAxios';
import { DateString } from 'shared/models/DateString';
import { ISO8601 } from 'shared/models/ISO8601';
import { Query } from 'shared/models/Query';
import { StatusType } from 'shared/models/StatusType';
import { UserType } from 'shared/models/UserType';
import { getClient } from './base';
import { toAPIRequestParams } from 'shared/models/CommonAPIType';

/*** エンティティ ***/

export interface Notification {
  notification_id: string;
  show_date: DateString;
  show_type: UserType;
  title: string;
  content: string;
  status: string;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

export interface NotificationsWithPaging {
  items: Notification[];
  has_next: boolean;
  last_notification_id?: string;
}

const sample_notification_1: Notification = {
  notification_id: '00000001',
  show_date: '2020-01-01' as DateString,
  show_type: 'GENERAL',
  title: 'テストユーザー1',
  content: 'テストテキスト1',
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: null,
};

const sample_notification_2: Notification = {
  notification_id: '00000002',
  show_date: '2020-01-01' as DateString,
  show_type: 'ADMIN',
  title: 'テストユーザー2',
  content: 'テストテキスト2',
  status: 'DELETED',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
};

/*** Caching mechanism ***/

export class CachedNotifications {
  private searched = false;
  private cache: Notification[] = [];
  private params: RequestNotificationsGet;
  constructor(params: RequestNotificationsGet) {
    this.params = params;
  }
  async get() {
    if (!this.searched) {
      let esk: string | undefined = undefined;
      let has_next = true;
      while (has_next) {
        const res: AxiosResponse<NotificationsWithPaging> = await notificationsGetAPI({
          ...this.params,
          exclusive_start_notification_id: esk,
        });
        if (res.status === 200) {
          this.cache = [...this.cache, ...res.data.items];
          has_next = res.data.has_next;
          esk = res.data.last_notification_id;
        } else {
          has_next = true;
          break;
        }
      }
      this.searched = true;
    }
    return this.cache;
  }
}

/*** [GET] /api/notifications ***/
export interface RequestNotificationsGet {
  status?: StatusType;
  exclusive_start_notification_id?: string;
}

export const notificationsGetAPI = (params: RequestNotificationsGet) => {
  const { status, exclusive_start_notification_id, disabled_load, disabled_error_message, ended_load } =
    toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/notifications`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    status,
    exclusive_start_notification_id,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<NotificationsWithPaging>(axios, path, query, form, method, {
    items: [sample_notification_1, sample_notification_2],
    has_next: false,
  });
};

/*** [GET] /api/notifications/{notification_id} ***/

export interface RequestNotificationsIdGet {
  notification_id: string;
}

export const notificationsIdGetAPI = (params: RequestNotificationsIdGet) => {
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `/api/notifications/${params.notification_id}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<Notification>(axios, path, query, form, method, sample_notification_1);
};
