// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createMultipleAPIDatas = <T = any>(create_number: number, id_name: string, sample_data: T) => {
  const return_datas: T[] = [];
  for (let i = 0; i < create_number; i++) {
    const random_id = Math.random().toString(32).substring(2);
    const new_sample_data = { ...sample_data, [id_name]: random_id };
    return_datas[i] = new_sample_data;
  }
  return return_datas;
};
