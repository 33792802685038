// -- basic library --
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

// -- external components --
// import TabTimeButton from 'shared/components/molecules/TabTimeButton';
import RoundedCard from 'shared/components/atoms/RoundedCard';
import SelectBox from 'shared/components/atoms/SelectBox';
import { Content, TopArea } from 'shared/components/molecules/ContentsArea';
import { CartesianGraphData, SymbolType, CartesianGragh } from 'shared/components/molecules/PfGraph';

// -- main component --
const MetricsGraph: React.FC<
  RouteComponentProps<{
    metric_id: string;
  }>
> = () => {
  const [selected_graph_id, setSelectedGraphId] = useState<string>('');
  // const [range_type, setRangeType] = useState<RangeType>('HOUR')
  const onChangeSelectBox = (value: string) => {
    setSelectedGraphId(value);
  };

  //--------- ダミーデータ -----------
  const symbolTypes: SymbolType[] = [
    {
      dataKey: 'y1',
      name: 'データ１',
      type: 'LINE',
    },
    {
      dataKey: 'y2',
      name: 'データ2',
      type: 'BAR',
    },
  ];
  const now_time = new Date().getTime();
  const graphData: CartesianGraphData[] = [
    {
      datapoint: now_time,
      y1: 0,
      y2: 0,
    },
    {
      datapoint: now_time + 900000,
      y1: 1,
      y2: 1,
    },
    {
      datapoint: now_time + 900000 * 2,
      y1: 2,
      y2: 4,
    },
    {
      datapoint: now_time + 900000 * 3,
      y1: 3,
      y2: 9,
    },
  ];

  return (
    <Content>
      <TopArea>
        <div
          style={{
            width: '100%',
          }}
        >
          <SelectBox
            value={selected_graph_id}
            onChange={(e) => onChangeSelectBox(e.currentTarget.value)}
            default_text=''
            datas={[
              { name: 'グラフ1', value: '1' },
              { name: 'グラフ2', value: '2' },
            ]}
          />
        </div>
        {/* <TabTimeButton
              selected_id={range_type}
              handleClick={(selected_id) => {
                setRangeType(selected_id)
              }}
              long
            /> */}
      </TopArea>
      <RoundedCard
        style={{
          width: '100%',
          height: 'auto',
          boxShadow: 'none',
          borderRadius: '0px',
        }}
      >
        <CartesianGragh data={graphData} symbolTypes={symbolTypes} isTimestampTick={true} />
      </RoundedCard>
    </Content>
  );
};

// -- styled components --

// -- finally export part --

export default MetricsGraph;
