import { TableFileProcessesResultsType } from './FileProcessesResults';
import { isProcessOutputDownloadable } from 'user/utils/isProcessOutputResultDownlodable';

/**
 * プロセスのoutputでダウンロード可能なデータが存在する場合はtrue
 * **/
const hasProcessOutputDownloadableData = (bodies: TableFileProcessesResultsType[]) => {
  return bodies.filter((body) => isProcessOutputDownloadable(body.output.downloadable)).length > 0;
};

export default hasProcessOutputDownloadableData;
