import { AxiosResponse } from 'axios';
import { CommonAPIRequestType, isCommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';
import { ISO8601 } from 'shared/models/ISO8601';
import { Query } from 'shared/models/Query';
import sendAxios from '../../shared/axios/sendAxios';
import { getClient } from './base';

/*** エンティティ ***/

export interface AndroidDevice {
  tenant_id: string;
  android_device_id: string;
  android_device_name?: string;
  android_device_status?: string;
  android_device_appid?: string;
  android_device_os_ver?: string;
  android_device_app_ver?: string;
  android_device_updated_at?: ISO8601;
  created_at: ISO8601;
}

const sample_device_1: AndroidDevice = {
  tenant_id: '00000001',
  android_device_id: 'ad1',
  android_device_name: 'ad1',
  android_device_status: '?',
  android_device_appid: 'ai8vyb',
  android_device_os_ver: '0.0.0',
  android_device_app_ver: '0.0.0',
  android_device_updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
};

export interface AndroidDevicesWithPaging {
  items: AndroidDevice[];
  last_android_device_id?: string;
  has_next: boolean;
}

/*** Caching mechanism ***/

export class CachedAndroidDevices {
  private searched = false;
  private cache: AndroidDevice[] = [];
  async get() {
    if (!this.searched) {
      let esk: string | undefined = undefined;
      for (;;) {
        const res: AxiosResponse<AndroidDevicesWithPaging> = await androidDevicesGetAPI({
          exclusive_start_android_device_id: esk,
        });
        if (res.status === 200) {
          this.cache = [...this.cache, ...res.data.items];
          if (!res.data.last_android_device_id) {
            break;
          } else {
            esk = res.data.last_android_device_id;
          }
        } else {
          break;
        }
      }
      this.searched = true;
    }
    return this.cache;
  }
}

/*** [GET] /api/android/devices ***/
export interface RequestAndroidDevicesGet extends CommonAPIRequestType {
  exclusive_start_android_device_id?: string;
}

export const androidDevicesGetAPI = (params: RequestAndroidDevicesGet) => {
  const { exclusive_start_android_device_id, disabled_load, disabled_error_message, ended_load } =
    toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/android/devices`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    exclusive_start_android_device_id,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<AndroidDevicesWithPaging>(axios, path, query, form, method, {
    items: [sample_device_1],
    has_next: false,
  });
};

/*** [DELETE] /api/android/devices/{id} ***/
export interface RequestAndroidDevicesIdDelete extends CommonAPIRequestType {
  android_device_id: string;
}

export const androidDevicesIdDeleteAPI = (params: RequestAndroidDevicesIdDelete) => {
  const { android_device_id, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/android/devices/${android_device_id}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<AndroidDevice>(axios, path, query, form, method, sample_device_1);
};

/*** [POST] /api/android/devices/{id}/push ***/
export interface RequestAndroidDevicesIdPushPost extends CommonAPIRequestType {
  android_device_id: string;
  action: string;
}
export interface ResponseAndroidDevicesIdPushPost {
  success: number;
  failure: number;
  message: string;
}
/**
 * Androidデバイスにプッシュ通知を送ります。
 */
export const androidDevicesIdPushPostAPI = (params: RequestAndroidDevicesIdPushPost) => {
  const { android_device_id, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/android/devices/${android_device_id}/push`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'android_device_id' || isCommonAPIRequestType(key)) continue;
    form.append(key, value);
  }

  // 送信
  return sendAxios<ResponseAndroidDevicesIdPushPost>(axios, path, query, form, method, {
    success: 1,
    failure: 0,
    message: 'ok',
  });
};
