import { useCallback, useEffect, useMemo, useState } from 'react';
import { CachedDevices, Device, RequestDevicesGet } from 'user/api/device';

/**
 * デバイス一覧を取得するcustom hooks
 */
export const useDevices = ({ status }: RequestDevicesGet) => {
  const [devices, setDevices] = useState<Device[] | undefined>(undefined);
  const loadDevices = useCallback(async () => {
    const new_devices = await new CachedDevices({ status }).get();
    setDevices(new_devices);
  }, [status]);

  useEffect(() => {
    loadDevices();
  }, [loadDevices]);

  return useMemo(() => {
    return { devices, loadDevices };
  }, [devices, loadDevices]);
};
