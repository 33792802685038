import { isNotOnlySpace } from 'shared/utils/is';

import { DashboardType, isDashboardType } from 'shared/models/DashboardType';
import { RequestDashboardsPost } from 'user/api/dashboards';
import { TableauDataType, isTableauDataType } from 'shared/models/TableauDataType';

export interface DashboardPostValidatorProps {
  dashboard_name: string;
  dashboard_type: DashboardType;
  tableau_data_type: TableauDataType | '';
}
/**
 * Dashboardで[POST]データをバリデーションして返却
 */
export const dashboardPostValidator = (params: DashboardPostValidatorProps) => {
  const { dashboard_name, dashboard_type, tableau_data_type } = params;
  const use_tableau = dashboard_type === 'tableau';
  if (!isNotOnlySpace(dashboard_name)) {
    throw new Error('ダッシュボード名は必須です');
  }
  if (!isDashboardType(dashboard_type)) {
    throw new Error('ダッシュボードタイプは必須です');
  }
  if (use_tableau && !isTableauDataType(tableau_data_type)) {
    throw new Error('ダッシュボードタイプがTableauの場合は、「Tableauデータタイプ」は必須です');
  }
  const request: RequestDashboardsPost = {
    dashboard_name,
    dashboard_type,
    tableau_data_type: use_tableau ? (tableau_data_type as TableauDataType) : undefined,
  };
  return request;
};
