import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import styled from 'styled-components';

export const WholeArea = styled.div`
  width: 100%;
  height: 100%;
  display: block;
`;

export const TableArea = styled.div`
  max-width: 100%;
  width: 100%;
  height: calc(100% - 25px - 25px - ${styles.interval_narrow_margin} * 2);
  max-height: 200px;
  overflow-y: scroll;
  border-radius: ${styles.table_radius_px};
`;

export const FooterArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 25px;
  margin-top: ${styles.interval_narrow_margin};
`;

export const Table = styled.table`
  width: 100%;
  height: 100%;
  border-spacing: 0;
  border: 1px solid ${colors.component_small_border_color};
  border-collapse: separate;
  border-radius: ${styles.table_radius_px};
  table-layout: fixed;
  overflow: hidden;
`;

export const Thead = styled.thead`
  width: 100%;
  height: 45px;
  max-height: 45px;
  background-color: ${colors.gray};
`;

export const Tbody = styled.tbody`
  width: 100%;
  height: calc(100% - 45px);
  background-color: ${colors.white};
`;

export const Tr = styled.tr<{
  checked?: boolean;
}>`
  width: 100%;
  height: 35px;
  ${(params) => (params.checked ? `background-color: ${colors.table_checked_background_color};` : '')}
  &:hover {
    background-color: ${(params) =>
      params.checked ? colors.table_checked_background_color : colors.table_hover_background_color};
  }
`;

export const Th = styled.th<{
  first_child?: boolean;
  last_child?: boolean;
}>`
  vertical-align: middle;
  border-right: ${(params) => (params.last_child ? 'none' : `1px solid ${colors.component_small_border_color}`)};
  border-bottom: 1px solid ${colors.component_small_border_color};
  border-top-left-radius: ${(params) => (params.first_child ? `${styles.table_radius_px}` : '0px')};
  border-top-right-radius: ${(params) => (params.last_child ? `${styles.table_radius_px}` : '0px')};
`;

export const ThChildrensArea = styled.div`
  display: flex;
  align-items: center;
`;

export const Td = styled.td<{
  first_child?: boolean;
  last_row?: boolean;
  last_child?: boolean;
}>`
  vertical-align: middle;
  border-right: ${(params) => (params.last_child ? 'none' : `1px solid ${colors.component_small_border_color}`)};
  border-bottom: ${(params) => (params.last_row ? 'none' : `1px solid ${colors.component_small_border_color}`)};
  border-bottom-left-radius: ${(params) =>
    params.first_child && params.last_row ? `${styles.table_radius_px}` : '0px'};
  border-bottom-right-radius: ${(params) =>
    params.last_child && params.last_row ? `${styles.table_radius_px}` : '0px'};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
