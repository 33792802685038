import { PageWholeArea, Title } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import styled from 'styled-components';
import { AreaEditModalButton } from 'user/components/AreaEditModal';
import { IAREA, ISTAY_COUNTER } from 'user/components/Config';
import { TargetPointEditModalButton } from 'user/components/TargetPointEditModal';
import { getClockwizeLabel } from 'user/utils/isClockwise';

interface StayCounterTabProps {
  STAY_COUNTER: ISTAY_COUNTER;
  image_url?: string;
  onChange: (value?: ISTAY_COUNTER) => void;
}
export function StayCounterTab(params: StayCounterTabProps) {
  const invokeOnChange = (value: ISTAY_COUNTER) => {
    if ((value.AREAS || []).length === 0) {
      params.onChange(undefined);
    } else {
      params.onChange(value);
    }
  };
  const handleOnChange = (value: ISTAY_COUNTER) => {
    invokeOnChange({ ...params.STAY_COUNTER, ...value });
  };
  const handleOnAreaChange = (index: number, value: IAREA) => {
    const areas = params.STAY_COUNTER.AREAS || [];
    if (areas.length > index) {
      areas[index] = value;
      invokeOnChange({ ...params.STAY_COUNTER, AREAS: areas });
    }
  };
  return (
    <PageWholeArea data-testid='AISetting_CameraID'>
      <Title text='滞留エリア設定' />
      <div style={{ margin: '20px 0px' }}>
        <AreaEditModalButton
          value={params.STAY_COUNTER.AREAS || []}
          onChange={(value) => handleOnChange({ AREAS: value })}
          image_url={params.image_url}
        />
      </div>
      {params.STAY_COUNTER.AREAS &&
        params.STAY_COUNTER.AREAS.map((cl, index) => {
          <Title text='滞留エリア識別' />;
          return (
            <>
              <InputComponent text={`滞留エリア(${index}) `}>
                {/* JSON.stringify(cl.name)だと"滞留エリア(*)"と表示されるため成型 */}
                <TextArea color='gold'>{JSON.stringify(cl.name).slice(1, -1)}</TextArea>
                <TextArea>滞留エリア座標：</TextArea>
                <TextArea color='gold'>{getClockwizeLabel(cl.co)}</TextArea>
                <TextArea color='gold'>{JSON.stringify(cl.co)}</TextArea>
                <TextArea>対象点：</TextArea>
                <TextArea>
                  <TargetPointEditModalButton
                    value={cl.target_point ?? [0.5, 0.5]}
                    onChange={(value) => handleOnAreaChange(index, { ...cl, target_point: value })}
                  />
                </TextArea>
              </InputComponent>
            </>
          );
        })}
    </PageWholeArea>
  );
}

// -- styled components --
const TextArea = styled.div`
  margin: 10px 0px;
`;
