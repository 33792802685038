// -- basic library --
import { BillingCsv, billingSummariesYeCsvGetAPI } from 'user/api/billingSummary';
import React, { useState, useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Table, Tbody, Td, Th, Thead, Tr } from 'shared/components/atoms/PfTable';
import Spinner from 'shared/components/atoms/Spinner';
import { billingDisplaySet, billingClaimGet, billingBreakdownGet } from 'shared/utils/converter';
import styled from 'styled-components';
import styles from 'shared/styles/styles';
import { Title } from 'shared/components/molecules/ContentsArea';

interface Params {
  yearmonth: string;
}

// -- main component --
const CSVTable: React.FC<Params> = (params) => {
  // -- redux preparations --
  const ref = useRef<HTMLDivElement>(null);

  // -- local states --
  const [breakdown_csvs, setBreakdownCsvs] = useState<BillingCsv[]>([]);
  const [claim_csvs, setClaimCsvs] = useState<BillingCsv[]>([]);
  const [headers, setHeaders] = useState<string[] | undefined>(undefined);
  const [hasmore, setHasmore] = useState<boolean>(true);
  // テーブルのサイドを固定にするか
  const side_sticky = true;
  // テーブルのヘッドを固定にするか
  const head_sticky = true;

  // -- functions --
  const getBillingSummaryCsv = (yearmonth: string) => {
    billingSummariesYeCsvGetAPI({
      yearmonth: yearmonth,
      start_csv_index: 0,
      acquisitions: 50,
    }).then((res) => {
      if (res.status === 200) {
        const claim_csv = billingClaimGet(res.data.items);
        const claim_csv_data: BillingCsv[] = [];
        for (const d of claim_csv) {
          const csv = d.csv;
          // 単価と請求金額を直したデータをnew_csv(1行分のデータ)に設定
          const new_csv: BillingCsv = {
            csv_index: d.csv_index,
            csv: csv,
          };
          // 上記で設定したnew_csvをset用の関数new_csvsに詰める
          claim_csv_data[d.csv_index] = { ...new_csv };
        }
        setClaimCsvs(claim_csv_data);
        const breakdown_csv = billingBreakdownGet(res.data.items);
        // set用の関数new_csvs
        const new_csvs: BillingCsv[] = [];
        let new_headers = [res.data.headers[0], '', '', ''];
        // もし、headersが空配列ならば、データの0番目からヘッダー情報を得る。
        if (new_headers.length <= 0) {
          new_headers = res.data.items[0] ? Object.keys(res.data.items[0].csv) : [];
        }
        const header_data: BillingCsv = {
          csv_index: 0,
          csv: ['', '', '', res.data.headers[3]],
        };
        breakdown_csv[0] = { ...header_data };
        setHeaders(new_headers);
        // set用の関数new_csvs
        for (const d of breakdown_csv) {
          const csv = d.csv;
          const breakdown_data: string[] = billingDisplaySet(csv);
          // 単価と請求金額を直したデータをnew_csv(1行分のデータ)に設定
          const new_csv: BillingCsv = {
            csv_index: d.csv_index,
            csv: breakdown_data,
          };
          // 上記で設定したnew_csvをset用の関数new_csvsに詰める
          new_csvs[d.csv_index] = { ...new_csv };
        }
        // 次のデータがないのならばhasmoreをfalseにする
        if (!res.data.has_next) {
          setHasmore(false);
        }
        setBreakdownCsvs(new_csvs);
      }
    });
  };
  const loadCsvMore = () => {
    if (breakdown_csvs.length >= 1000 || !hasmore) {
      return;
    }
    billingSummariesYeCsvGetAPI({
      yearmonth: params.yearmonth,
      start_csv_index: breakdown_csvs[breakdown_csvs.length - 1]
        ? breakdown_csvs[breakdown_csvs.length - 1].csv_index + 1
        : 0,
      acquisitions: 50,
    }).then((res) => {
      if (res.status === 200) {
        const claim_csv = billingClaimGet(res.data.items);
        const claim_csv_data: BillingCsv[] = [];
        for (const d of claim_csv) {
          const csv = d.csv;
          // 単価と請求金額を直したデータをnew_csv(1行分のデータ)に設定
          const new_csv: BillingCsv = {
            csv_index: d.csv_index,
            csv: csv,
          };
          // 上記で設定したnew_csvをset用の関数new_csvsに詰める
          claim_csv_data[d.csv_index] = { ...new_csv };
        }
        setClaimCsvs(claim_csv_data);
        const breakdown_csv = billingBreakdownGet(res.data.items);
        // set用の関数new_csvs
        const new_csvs: BillingCsv[] = [];
        for (const d of breakdown_csv) {
          const csv = d.csv;
          const breakdown_data: string[] = billingDisplaySet(csv);
          // 単価と請求金額を直したデータをnew_csv(1行分のデータ)に設定
          const new_csv: BillingCsv = {
            csv_index: d.csv_index,
            csv: breakdown_data,
          };
          // 上記で設定したnew_csvをset用の関数new_csvsに詰める
          new_csvs[d.csv_index] = { ...new_csv };
        }
        // データの数が1000を超える or 次のデータがないのならば、これ以上データを取得しない
        if (new_csvs.length >= 1000 || !res.data.has_next) {
          setHasmore(false);
        }
        setBreakdownCsvs(new_csvs);
      }
    });
  };

  const titleToComponentName = (title: string) => {
    if (title === 'ファイル処理 (検知)') return 'DETECTION';
    if (title === 'ファイル処理 (カウント)') return 'COUNT';
    if (title === 'ファイル処理 (滞留)') return 'STAYING';
  };

  const fileProcessesUrl = (csv: string) => {
    return '/billings/' + params.yearmonth + '/FILE_PROCESS/' + titleToComponentName(csv) + '/fileprocess/detail';
  };

  useEffect(() => {
    getBillingSummaryCsv(params.yearmonth);
  }, [params.yearmonth]); /* eslint-disable-line */

  // -- render part --
  return (
    <>
      <ClWrapper>
        <Table sticky={head_sticky || side_sticky}>
          {claim_csvs.map((claim_csv: BillingCsv, i) => {
            return (
              <>
                {i === 0 ? (
                  <Thead>
                    {Object.keys(claim_csv.csv).map((key, j) => {
                      return (
                        <Td
                          key={`${claim_csv.csv[key]}_${i}_${j}`}
                          last_row={i === breakdown_csvs.length - 1}
                          style={{ textAlign: 'center' }}
                        >
                          {claim_csv.csv[key]}
                        </Td>
                      );
                    })}
                  </Thead>
                ) : (
                  <Tbody>
                    {Object.keys(claim_csv.csv).map((key, j) => {
                      return (
                        <Td
                          key={`${claim_csv.csv[key]}_${i}_${j}`}
                          last_row={i === breakdown_csvs.length - 1}
                          style={{ height: '35px', textAlign: 'center' }}
                        >
                          {claim_csv.csv[key]}
                        </Td>
                      );
                    })}
                  </Tbody>
                )}
              </>
            );
          })}
        </Table>
      </ClWrapper>
      <Title text='請求金額内訳' />
      <BrWrapper ref={ref}>
        {headers === undefined ? (
          <Spinner />
        ) : (
          <InfiniteScroll
            pageStart={0}
            loadMore={() => loadCsvMore()}
            hasMore={hasmore}
            loader={<Spinner />}
            getScrollParent={() => ref.current}
            useWindow={false}
          >
            <Table sticky={head_sticky || side_sticky}>
              <Thead>
                {headers.map((head, i) => {
                  return (
                    <>
                      {i === 0 ? (
                        <Th
                          head_sticky={head_sticky}
                          side_sticky={side_sticky}
                          key={`${head}_${i}`}
                          style={{ width: 130, textAlign: 'center' }}
                        >
                          {head}
                        </Th>
                      ) : (
                        <Th
                          head_sticky={head_sticky}
                          side_sticky={side_sticky}
                          key={`${head}_${i}`}
                          style={{ width: 50, textAlign: 'center' }}
                        >
                          {head}
                        </Th>
                      )}
                    </>
                  );
                })}
              </Thead>
              {breakdown_csvs.map((csv: BillingCsv, i) => {
                return (
                  <>
                    {csv.csv[0] === '月額基本料' ||
                    csv.csv[0] === '月額従量費用' ||
                    csv.csv[0] === '対象' ||
                    csv.csv[0] === '請求金額合計 (税別)' ||
                    csv.csv[0] === '消費税' ||
                    csv.csv[0] === '請求金額合計 (税込)' ? (
                      <Thead>
                        {Object.keys(csv.csv).map((key, j) => {
                          return (
                            <Th
                              head_sticky={head_sticky}
                              side_sticky={side_sticky}
                              key={`${csv}_${i}_${j}`}
                              style={{ textAlign: 'center' }}
                            >
                              {csv.csv[key]}
                            </Th>
                          );
                        })}
                      </Thead>
                    ) : (
                      <Tbody>
                        <Tr key={`tr_${csv}_${i}`}>
                          {Object.keys(csv.csv).map((key, j) => {
                            return (
                              <>
                                {csv.csv[key] === 'ファイル処理 (検知)' ||
                                csv.csv[key] === 'ファイル処理 (カウント)' ||
                                csv.csv[key] === 'ファイル処理 (滞留)' ? (
                                  <Td
                                    key={`${csv.csv[key]}_${i}_${j}`}
                                    last_row={i === breakdown_csvs.length - 1}
                                    style={{ textAlign: 'center' }}
                                  >
                                    <a href={fileProcessesUrl(csv.csv[key])}>{csv.csv[key]}</a>
                                  </Td>
                                ) : (
                                  <>
                                    {j === 0 ? (
                                      <Td
                                        key={`${csv.csv[key]}_${i}_${j}`}
                                        last_row={i === breakdown_csvs.length - 1}
                                        style={{ textAlign: 'center' }}
                                      >
                                        {csv.csv[key]}
                                      </Td>
                                    ) : (
                                      <Td
                                        key={`${csv.csv[key]}_${i}_${j}`}
                                        last_row={i === breakdown_csvs.length - 1}
                                        style={{ textAlign: 'right', paddingRight: '1%' }}
                                      >
                                        {csv.csv[key]}
                                      </Td>
                                    )}
                                  </>
                                )}
                              </>
                            );
                          })}
                        </Tr>
                      </Tbody>
                    )}
                  </>
                );
              })}
            </Table>
          </InfiniteScroll>
        )}
      </BrWrapper>
    </>
  );
};

// -- styled components --
const ClWrapper = styled.div`
  display: block;
  width: 40%;
  min-height: 15%;
  max-width: 100%;
  min-width: 260px;
  margin-bottom: ${styles.interval_narrow_margin};
`;
const BrWrapper = styled.div`
  display: block;
  width: 50%;
  max-width: 100%;
  min-width: 280px;
  max-height: 60%;
  overflow: scroll;
`;

// -- finally export part --

export default CSVTable;
