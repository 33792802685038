// 渡されたURLからファイルをダウンロードできるようにしたもの。
// 受け取る値はURL、出力ファイル名

import React from 'react';
import import_svg from 'assets/import.svg';

interface DownloadIconProps {
  url?: string;
  download_name?: string;
}
const DownloadIcon: React.FC<DownloadIconProps> = (params) => {
  return params.url && params.download_name ? (
    <div>
      <a href={params.url} download={params.download_name}>
        <img
          src={import_svg}
          alt={'ダウンロード'}
          style={{
            width: '16px',
            height: '16px',
          }}
        />
      </a>
    </div>
  ) : null;
};
export default DownloadIcon;
