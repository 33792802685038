import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import styled from 'styled-components';

export interface MultiStateToggleButtonProps<T> {
  value: T;
  items: { label: string; value: T }[];
  onChange: (value: T) => void;
  long?: boolean;
  is_margin_left?: boolean;
}
export default function MultiStateToggleButton<T>(props: MultiStateToggleButtonProps<T>) {
  return (
    <Toggles long={props.long} is_margin_left={props.is_margin_left}>
      {props.items.map((item, i) => {
        return (
          <Button selected={props.value === item.value} onClick={() => props.onChange(item.value)} key={i}>
            {item.label}
          </Button>
        );
      })}
    </Toggles>
  );
}

const Toggles = styled.div<{
  long?: boolean;
  is_margin_left?: boolean;
}>`
  width: ${(params) => (params.long ? '100px' : 'auto')};
  height: 36px;
  margin-left: ${(params) => (params.is_margin_left ? styles.interval_narrow_margin : '0px')};
  border-radius: 15px;
  border: 1px solid ${colors.component_small_border_color};
  display: flex;
  background-color: ${colors.white};
  overflow: hidden;
`;

const Button = styled.div<{
  selected?: boolean;
}>`
  width: calc(100% / 4);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: ${(params) => (params.selected ? colors.white : colors.main_font_color)};
  background-color: ${(params) => (params.selected ? colors.component_main_color : colors.white)};
`;
