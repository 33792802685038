// -- basic library --
import React, { useCallback, useEffect, useState } from 'react';
import PfBoldText from 'shared/components/atoms/PfBoldText';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import { AppParameter } from 'shared/models/AppParameter';
import { CsvRow } from 'shared/models/CsvRow';
import { HeartbeatParameter } from 'shared/models/HeartbeatParameter';
import styled from 'styled-components';
import { JsonpathGroup } from 'user/api/jsonpathGroups';
import { Process, ProcessAppParameter } from 'user/api/processes';
import { CachedProcessFlowInfos } from 'user/api/processflows';
import { Stream } from 'user/api/streams';
import { ChannelProcessByRoot, streamsProcessinfoByrootIdNumGetAPI } from 'user/api/streamsProcessInfo';
import CSVTable from 'user/dialogs/DetailStreamPackageDialog/CSVTable';

// -- functions --
const isSpecifiedProcess = (process_id: string) => (process: ChannelProcessByRoot) => process_id === process.process_id;
const isProcessAppParameterKey = (process_app_parameter_keys: Record<string, boolean>) => (key: string) =>
  key in process_app_parameter_keys;

const appParameterKeysRecord = (app_parameteres: ProcessAppParameter[]): Record<string, boolean> => {
  const keys_record: Record<string, boolean> = {};
  app_parameteres.forEach((ap) => (keys_record[ap.key] = true));
  return keys_record;
};

// -- types --

type Params = {
  stream: Stream;
  stream_data_number: string | number;
  channel_id: string;
  channel_process_number: string;
  isOpen?: boolean;
  onClose: () => void;
  onCopyCreate: () => void;
};

type FileProcessAppParameter = {
  csv_rows?: CsvRow[];
  jsonoath_groups?: Record<string, string>;
} & AppParameter;

interface FileProcessAppParam extends ProcessAppParameter {
  jsonpath_group_id?: string;
}

interface StepInfo {
  input_stream_ids: string[][];
  app_parameter: FileProcessAppParameter; // API送信する時用のアップパラメーター
  heartbeat: HeartbeatParameter;
  process_app_parameter: FileProcessAppParam[]; //画面に表示する用のアップパラメーター
  process: Process;
  jsonpath_group?: JsonpathGroup;
  csv_rows?: CsvRow[];
}

// -- main component --
const ProcessFlowInfoPanel: React.FC<Params> = (params) => {
  // -- local states --
  const [cachedProcessFlowInfos] = useState<CachedProcessFlowInfos>(new CachedProcessFlowInfos());

  // 各プロセスごとに必要な情報をまとめた変数
  const [stepsinfos, setStepsinfos] = useState<StepInfo[] | undefined>(undefined);

  // 設定されているプロセスフローの取得関数
  const getProcessFlowDetail = useCallback(async () => {
    const processinfos_res = await streamsProcessinfoByrootIdNumGetAPI({
      channel_id: params.channel_id,
      channel_process_number: params.channel_process_number,
    });
    if (!processinfos_res) {
      AlertDialog.show('指定のプロセスフローを取得できませんでした。');
      return;
    }

    const pfi = await cachedProcessFlowInfos.get({
      process_flow_id: processinfos_res.data.processes[0].process_flow_id,
    });
    if (!pfi) {
      AlertDialog.show('指定のプロセスフローを取得できませんでした。');
      return;
    }
    const new_stepsinfos: StepInfo[] = [];
    // プロセス毎に、必要な情報をまとめる
    pfi.processes.forEach((pr, i) => {
      const stepsinfo: StepInfo = {
        input_stream_ids: [],
        app_parameter: {},
        heartbeat: {},
        process_app_parameter: [],
        process: pr,
      };
      const row_app_parameter: FileProcessAppParameter = {};
      // process内のapp_parameterのkeyのレコード
      const app_parameter_key_record = appParameterKeysRecord(pr.app_parameters);
      const process_id = stepsinfo.process.process_id;
      // 条件に合うprocessinfosを抽出
      const channnel_processes = processinfos_res.data.processes.filter(isSpecifiedProcess(process_id));
      channnel_processes.forEach((channnel_process) => {
        Object.keys(channnel_process.app_parameter)
          // process内のapp_parameterでfilter
          .filter(isProcessAppParameterKey(app_parameter_key_record))
          .forEach((key) => {
            if (key === 'csv_rows') {
              const csv_rows = processinfos_res.data.processes[i].csv_rows;
              if (csv_rows) {
                stepsinfo[key] = csv_rows;
              }
            } else if (key === 'jsonpath_group') {
              stepsinfo[key] = processinfos_res.data.processes[i].jsonpath_group;
            } else {
              row_app_parameter[key] = channnel_process.app_parameter[key];
            }
          });
      });
      stepsinfo.app_parameter = row_app_parameter;
      stepsinfo.process_app_parameter = pr.app_parameters;
      new_stepsinfos.push(stepsinfo);
    });
    setStepsinfos(new_stepsinfos);
  }, [cachedProcessFlowInfos, params.channel_id, params.channel_process_number]);

  // -- onload function --
  useEffect(() => {
    (async function () {
      await getProcessFlowDetail();
    })();
  }, [getProcessFlowDetail]);

  // -- render part --
  return (
    <PfDialog
      title='プロセスフロー詳細'
      isOpen={params.isOpen === undefined ? true : params.isOpen}
      onClose={() => params.onClose()}
    >
      {stepsinfos ? (
        <>
          {stepsinfos.map((si, i) => {
            // 全ての入力データ(ストリーム)を結合（無理やり感がある）
            return (
              <div key={i}>
                <PfBoldText>{si.process.process_name + '[' + (i + 1) + ']'}</PfBoldText>
                {si.process_app_parameter.length > 0 && (
                  <InputComponent text=''>
                    {si.process_app_parameter.map((spap, j) => {
                      return (
                        <div key={j}>
                          {spap.key === 'csv_rows' ? (
                            si.csv_rows ? (
                              <CSVTable
                                bodies={si.csv_rows.map((r) => {
                                  return {
                                    header_name: r.header_name,
                                    json_path: r['json_path'],
                                    cell_format: r.cell_format,
                                    cell_format_args: r.cell_format_args === null ? '' : r.cell_format_args,
                                    statistic_method: r.statistic_method,
                                    fill: r.fill ? 'True' : 'False',
                                  };
                                })}
                                showStatistics={true}
                              />
                            ) : (
                              <InputComponent text={spap.name}></InputComponent>
                            )
                          ) : spap.key === 'CROSSLINE' ? null : spap.key === 'jsonpath_group' && si.jsonpath_group ? (
                            <ParamArea>
                              <ParamsTitle>{spap.name}</ParamsTitle>
                              <div>{si.jsonpath_group.jsonpath_group_name}</div>
                            </ParamArea>
                          ) : (
                            <ParamArea>
                              <ParamsTitle>{spap.name}</ParamsTitle>
                              <div>{si.app_parameter[spap.key]}</div>
                            </ParamArea>
                          )}
                        </div>
                      );
                    })}
                  </InputComponent>
                )}
              </div>
            );
          })}
          <Footer>
            <RoundedButton onClick={() => params.onClose()} text='キャンセル' is_white />
            <RoundedButton text='同じ条件で新規作成' onClick={() => params.onCopyCreate()} style={{ marginLeft: 12 }} />
          </Footer>
        </>
      ) : (
        <Spinner />
      )}
    </PfDialog>
  );
};

// -- styled components --

const ParamArea = styled.div`
  display: flex;
  margin-bottom: 2.5rem;
`;

const ParamsTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  width: 200px;
`;

// -- finally export part --

export default ProcessFlowInfoPanel;
