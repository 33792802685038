import { DashboardWidget } from 'user/api/dashboardsWidgets';
import PfDialog from 'shared/components/atoms/PfDialog';
import React from 'react';
import { WidgetState } from './DashboardWidgetCard';
import getWidgetComponents from 'user/widgets/getWidgetType';

interface DashboardWidgetDialogProps {
  widget: DashboardWidget;
  widgetKey: number; // ウィジェットを再構築するキー
  state: WidgetState;
  onStateChanged: (state: WidgetState) => void;
  onClose: () => void;
}
interface DashboardWidgetDialogState {}
/**
 * ウィジェットの拡大表示ダイアログです。
 * ・ウィジェット内容の表示
 */
export default class DashboardWidgetDialog extends React.PureComponent<
  DashboardWidgetDialogProps,
  DashboardWidgetDialogState
> {
  render() {
    const ih = Math.floor(window.innerHeight * 0.85);
    return (
      <PfDialog
        title={this.props.widget.dashboard_widget_name}
        style={{ height: ih + 'px' }}
        onClose={this.props.onClose}
        isOpen={true}
      >
        {React.createElement(getWidgetComponents(this.props.widget.widget_type), {
          key: this.props.widgetKey,
          widget: this.props.widget,
          state: this.props.state,
          onStateChanged: this.props.onStateChanged,
        })}
      </PfDialog>
    );
  }
}
