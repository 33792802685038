import { useEffect, useMemo, useState } from 'react';
import { CachedDashboards, Dashboard } from 'user/api/dashboards';

/**
 * ダッシュボード一覧を取得するcustom hooks
 */
export const useDashboards = () => {
  const [dashboards, setDashboards] = useState<Dashboard[] | undefined>(undefined);
  const loadDashboards = async () => {
    const cachedDashboards = new CachedDashboards({});
    const new_dashboards = await cachedDashboards.get();
    setDashboards(new_dashboards);
  };

  useEffect(() => {
    loadDashboards();
  }, []);

  return useMemo(() => {
    return { dashboards, setDashboards };
  }, [dashboards, setDashboards]);
};
