import React from 'react';
import { DashboardWidget, DashboardWidgetMetric } from 'user/api/dashboardsWidgets';
import { getDateScaleLabel } from 'shared/utils/get';
import { TopButtonArea } from 'shared/components/atoms/ContentsArea';
import { WidgetScaleType } from 'shared/models/WidgetScaleType';
import useStreamDetailWidgetBase from './useStreamDetailWidgetBase';
import { WidgetState } from 'user/pages/Dashboards/DetailPage/UsualDashboardWidgets/DashboardWidgetCard';
import { GraphArea } from './GraphArea';
import { CartesianGraphData, RangeType, SymbolType } from 'shared/components/molecules/PfGraph';
import DownloadIcon from 'shared/components/atoms/Icons/DownloadIcon';
import DateScaleSettingDialog from 'user/dialogs/DateScaleSettingDialog';

interface StreamDetailWidgetBaseProps {
  widget: DashboardWidget;
  state: WidgetState;
  // 範囲外の時刻のデータを0で埋めるかどうか
  fill_out_of_range_data_with_0?: boolean;
  // 時間単位を隠すかどうか
  hide_scale?: boolean;
  onInitMetrics: () => void;
  onStateChanged: (state: WidgetState) => void;
  onMetricsChanged: (metrics: DashboardWidgetMetric) => void;
  onScaleChanged: (scale: WidgetScaleType) => void;
  children?: React.ReactNode;
  // グラフに描画される線の属性
  symbol_types?: SymbolType[];
  // グラフに描画するデータ。datapointがX軸ラベル、keyにデータ
  data?: CartesianGraphData[];
  range_type?: RangeType;
}
/**
 * グラフ描画ウィジェットです。
 */
export default function StreamDetailWidgetBase({
  children,
  hide_scale = false,
  fill_out_of_range_data_with_0,
  widget,
  state,
  data,
  symbol_types,
  range_type,
  onMetricsChanged,
  onInitMetrics,
  onScaleChanged,
  onStateChanged,
}: StreamDetailWidgetBaseProps) {
  // 必要なhooksや関数を取得
  const { date_dialog_open, csv_data_download_url, onDateDialogOpen, handleDateUpdated } = useStreamDetailWidgetBase({
    state,
    widget,
    hide_scale,
    fill_out_of_range_data_with_0,
    data,
    symbol_types,
    range_type,
    onScaleChanged,
    onMetricsChanged,
    onInitMetrics,
    onStateChanged,
  });

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <TopButtonArea style={{ justifyContent: 'space-between' }}>
        <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={onDateDialogOpen}>
          {getDateScaleLabel({
            start_date: state.start_date,
            end_date: state.end_date,
            start_time: state.start_time,
            end_time: state.end_time,
            scale: !hide_scale ? state.scale : undefined,
            fixed: false,
          })}
        </span>
        <DownloadIcon
          url={csv_data_download_url}
          download_name={
            widget.dashboard_widget_name +
            getDateScaleLabel({
              start_date: state.start_date,
              end_date: state.end_date,
              start_time: state.start_time,
              end_time: state.end_time,
              scale: !hide_scale ? state.scale : undefined,
              fixed: false,
            })
          }
        />
      </TopButtonArea>
      <GraphArea>{children}</GraphArea>
      {date_dialog_open && (
        <DateScaleSettingDialog defaults={state} onClose={handleDateUpdated} widget={widget} hide_scale={hide_scale} />
      )}
    </div>
  );
}
