// -- basic library --
import React, { createRef, useState, useEffect, useCallback } from 'react';

// -- http connection library --
import PfHlsPlayer from 'shared/components/atoms/PfHlsPlayer';
import Spinner from 'shared/components/atoms/Spinner';
import { Stream, streamsIdGetAPI } from 'user/api/streams';
import { streamsIdDataNumberM3u8Url, getStreamsIdDataNumberDownloadGetAPIUrl } from 'user/api/streamsData';

// -- external components --
import { ProcessInfoOutput, ProcessInfo } from 'user/api/streamsProcessInfo';
import { ResultTopArea } from './ResultTopArea';

interface MP4ResultBodyProps {
  stream_id: string;
  stream_data_number: number;
  original_file_name?: string;
  process_output: ProcessInfoOutput;
  setProcessOutput(process_output: ProcessInfoOutput): void;
  process_info: ProcessInfo;
  process_infos?: ProcessInfo[];
}

// -- main component --

const MP4ResultBody: React.FC<MP4ResultBodyProps> = (props) => {
  // -- local states --
  const [stream, setStream] = useState<Stream | undefined>(undefined);
  const playerRef = createRef<HTMLVideoElement>();
  const is_mp4 = stream?.data_type === 'VIDEO' && stream?.data_number_type === 'SEQUENCE';

  const loadStream = useCallback(() => {
    streamsIdGetAPI({
      stream_id: props.stream_id,
    }).then((res) => {
      if (res.status === 200) {
        setStream(res.data);
      }
    });
  }, [props.stream_id]);

  useEffect(() => {
    loadStream();
  }, [loadStream]);

  // -- render part --
  return stream ? (
    <>
      <ResultTopArea
        button_text='MP4でダウンロード'
        button_disabled={!is_mp4}
        download_url={getStreamsIdDataNumberDownloadGetAPIUrl({
          stream_id: props.stream_id,
          stream_data_number: props.stream_data_number,
          original_file_name: props.original_file_name,
        })}
      />
      {is_mp4 && (
        <PfHlsPlayer
          playerRef={playerRef}
          url={streamsIdDataNumberM3u8Url(props.stream_id, props.stream_data_number)}
          controls={true}
          loop={false}
          muted
        />
      )}
    </>
  ) : (
    <Spinner />
  );
};

// -- styled components --

// -- finally export part --

export default MP4ResultBody;
