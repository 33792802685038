import { ProcessOutputType } from 'shared/models/ProcessOutputType';

export const checkIsOpenResultDialog = (p: {
  stream_data_number?: number;
  stream_package_number?: number;
  output_type: ProcessOutputType;
}) => {
  // csv出力(パッケージ判定)
  if (p.output_type === 'PACKAGE' && typeof p.stream_package_number === 'number') {
    return true;
  }
  // mp4出力(データ判定)
  if (p.output_type === 'DATA' && typeof p.stream_data_number === 'number') {
    return true;
  }
  return false;
};
