import React from 'react';
import SelectBox from 'shared/components/atoms/SelectBox';
import { isStreamOrFile, StreamOrFile } from 'shared/models/StreamOfFile';
import { CachedChannels, Channel } from 'user/api/channels';
import { CachedChannelProcesses, ChannelProcess } from 'user/api/channelsProcesses';
import { CachedStreams, Stream, streamsIdGetAPI } from 'user/api/streams';

interface HeatmapStreamSelectProps {
  value: string;
  onChange: (value: string) => void;
  cachedMetricStreams?: CachedStreams;
  cachedFileChannels?: CachedChannels;
}
interface HeatmapStreamSelectState {
  stream_or_file: StreamOrFile;
  metric_streams: Stream[];
  file_channels: Channel[];
  file_channel_processes: ChannelProcess[];
  channel_id: string;
  channel_process_number: string;
}
export default class HeatmapStreamSelect extends React.PureComponent<
  HeatmapStreamSelectProps,
  HeatmapStreamSelectState
> {
  constructor(props: HeatmapStreamSelectProps) {
    super(props);
    this.state = {
      stream_or_file: 'STREAM',
      metric_streams: [],
      file_channels: [],
      file_channel_processes: [],
      channel_id: '',
      channel_process_number: '',
    };
  }
  componentDidMount(): void {
    (this.props.cachedMetricStreams || new CachedStreams({ data_types: 'METRIC', with_output_streams: 'True' }))
      .get()
      .then((value) => this.setState({ metric_streams: value }));
    (this.props.cachedFileChannels || new CachedChannels({ channel_type: 'FILE' })).get().then((value) => {
      this.setState({ file_channels: value });
      this.recoverChannel(this.props.value, value);
    });
  }
  /** ストリームIDがファイル処理の場合にチャンネル選択に切り替える */
  private recoverChannel(streamId: string, channels: Channel[]) {
    // まだ何も選択されていない場合はスキップ
    if (streamId === '') {
      return;
    }
    // ストリームを取得
    streamsIdGetAPI({ stream_id: streamId }).then((ret) => {
      if (ret.status === 200 && ret.data.output_channel_process) {
        const channel_process = ret.data.output_channel_process.split(':');
        const channel = findChannel(channels, channel_process[0]);
        if (channel) {
          this.setState({
            stream_or_file: 'FILE',
            channel_id: channel_process[0],
            channel_process_number: channel_process[1],
          });
          this.handleChannelIdChange(channel_process[0]);
        }
      }
    });
  }
  private handleChannelIdChange = async (channelId: string) => {
    this.setState({ channel_id: channelId });
    const fileChannelProcesses = await new CachedChannelProcesses({
      channel_id: channelId,
      limit_process: 'PROJECTIVE_TRANSFORMATION',
    }).get();
    this.setState({ file_channel_processes: fileChannelProcesses });
  };
  private handleChannelProcessNumberChange = (channelProcessNumber: string) => {
    this.setState({ channel_process_number: channelProcessNumber });
    for (const channelProcess of this.state.file_channel_processes) {
      if (String(channelProcess.channel_process_number) === channelProcessNumber) {
        const output_stream_id = channelProcess.output_stream_ids[0];
        this.props.onChange(output_stream_id);
      }
    }
  };
  render() {
    return (
      <div style={{ display: 'flex' }}>
        <SelectBox
          value={this.state.stream_or_file}
          datas={[
            { name: 'データ', value: 'STREAM' },
            { name: 'ファイル処理', value: 'FILE' },
          ]}
          onChange={(e) => {
            const { value } = e.currentTarget;
            if (isStreamOrFile(value)) {
              this.setState({ stream_or_file: value });
            }
          }}
        />
        {this.state.stream_or_file === 'STREAM' ? this.renderStream() : this.renderFile()}
      </div>
    );
  }
  private renderStream() {
    return (
      <SelectBox
        value={this.props.value}
        datas={this.state.metric_streams.map((stream) => {
          return { name: stream.stream_name, value: stream.stream_id };
        })}
        onChange={(e) => this.props.onChange(e.currentTarget.value)}
        long
      />
    );
  }
  private renderFile() {
    return (
      <>
        <SelectBox
          value={this.state.channel_id}
          datas={this.state.file_channels.map((channel) => {
            return { name: channel.channel_name, value: channel.channel_id };
          })}
          onChange={(e) => this.handleChannelIdChange(e.currentTarget.value)}
          long
        />
        <SelectBox
          value={this.state.channel_process_number}
          datas={this.state.file_channel_processes.map((channelProcess) => {
            return { name: channelProcess.channel_process_name, value: String(channelProcess.channel_process_number) };
          })}
          onChange={(e) => this.handleChannelProcessNumberChange(e.currentTarget.value)}
          long
        />
      </>
    );
  }
}

function findChannel(channels: Channel[], channelId: string) {
  for (const channel of channels) {
    if (channel.channel_id === channelId) {
      return channel;
    }
  }
  return null;
}
