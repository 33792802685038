import { AxiosResponse } from 'axios';
import { CommonAPIRequestType, isCommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';
import { ISO8601 } from 'shared/models/ISO8601';
import { Query } from 'shared/models/Query';
import { StatusType } from 'shared/models/StatusType';
import sendAxios from '../../shared/axios/sendAxios';
import { getClient } from './base';

/*** エンティティ ***/

export interface Role {
  tenant_id: string;
  role_id: string;
  role_name: string;
  dashboard_ids: string[];
  stream_ids: string[];
  status: StatusType;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

export interface RolesWithPaging {
  items: Role[];
  has_next: boolean;
  last_role_id?: string;
}

const sample_role_1: Role = {
  tenant_id: '00000001',
  role_id: '00000001',
  role_name: 'ロールA',
  dashboard_ids: ['001', '002'],
  stream_ids: ['00000001', '00000003'],
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: null,
};

/*** Caching mechanism ***/

export class CachedRoles {
  private searched = false;
  private cache: Role[] = [];
  private params: RequestRolesGet;
  constructor(params: RequestRolesGet) {
    this.params = params;
  }
  async get() {
    if (!this.searched) {
      const status = this.params.status ?? 'ACTIVE';
      let esk: string | undefined = this.params.exclusive_start_role_id ?? '';
      for (;;) {
        const res: AxiosResponse<RolesWithPaging> = await rolesGetAPI({
          ...this.params,
          status,
          exclusive_start_role_id: esk,
        });
        if (res.status === 200) {
          this.cache = [...this.cache, ...res.data.items];
          if (!res.data.has_next) {
            break;
          } else {
            esk = res.data.last_role_id;
          }
        } else {
          break;
        }
      }
      this.searched = true;
    }
    return this.cache;
  }
}

/*** [GET] /api/roles ***/

export interface RequestRolesGet extends CommonAPIRequestType {
  status?: string;
  exclusive_start_role_id?: string;
}

export const rolesGetAPI = (params: RequestRolesGet) => {
  const { status, exclusive_start_role_id, disabled_load, disabled_error_message, ended_load } =
    toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/roles`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    status,
    exclusive_start_role_id,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<RolesWithPaging>(axios, path, query, form, method, {
    items: [sample_role_1],
    has_next: false,
  });
};

/*** [GET] /api/roles/{role_id} ***/

export interface RequestRolesIdGet {
  role_id: string;
}

export const rolesIdGetAPI = (params: RequestRolesIdGet) => {
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `/api/roles/${params.role_id}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<Role>(axios, path, query, form, method, sample_role_1);
};

/*** [POST] /api/roles ***/

export interface RequestRolesPost extends CommonAPIRequestType {
  role_name: string;
  dashboard_ids: string[];
  stream_ids: string[];
}

export const rolesPostAPI = (params: RequestRolesPost) => {
  const { disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/roles`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (isCommonAPIRequestType(key)) continue;
    if (key === 'role_name') {
      form.append(key, value);
    } else if (key === 'dashboard_ids') {
      const roop_number = params.dashboard_ids.length || 0;
      for (let i = 0; i < roop_number; i++) {
        form.append('dashboard_id[' + i + ']', params.dashboard_ids[i]);
      }
    } else if (key === 'stream_ids') {
      const roop_number = params.stream_ids.length || 0;
      for (let i = 0; i < roop_number; i++) {
        form.append('stream_id[' + i + ']', params.stream_ids[i]);
      }
    }
  }

  // 送信
  return sendAxios<Role>(axios, path, query, form, method, sample_role_1);
};

/*** [PUT] /api/roles/{role_id} ***/

export interface RequestRolesIdPut extends CommonAPIRequestType {
  role_id: string;
  role_name: string;
  dashboard_ids: string[];
  stream_ids: string[];
}

export const rolesIdPutAPI = (params: RequestRolesIdPut) => {
  const { role_id, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/roles/${role_id}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'role_id' || isCommonAPIRequestType(key)) continue;
    if (key === 'role_name') {
      form.append(key, value);
    } else if (key === 'dashboard_ids') {
      const roop_number = params.dashboard_ids.length || 0;
      for (let i = 0; i < roop_number; i++) {
        form.append('dashboard_id[' + i + ']', params.dashboard_ids[i]);
      }
    } else if (key === 'stream_ids') {
      const roop_number = params.stream_ids.length || 0;
      for (let i = 0; i < roop_number; i++) {
        form.append('stream_id[' + i + ']', params.stream_ids[i]);
      }
    }
  }

  // 送信
  return sendAxios<Role>(axios, path, query, form, method, sample_role_1);
};

/*** [DELETE] /api/roles/{role_id} ***/

export interface RequestRolesIdDelete extends CommonAPIRequestType {
  role_id: string;
}

export const rolesIdDeleteAPI = (params: RequestRolesIdDelete) => {
  const { role_id, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'multipleTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/roles/${role_id}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<Role>(axios, path, query, form, method, sample_role_1);
};
