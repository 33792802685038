import { useCallback, useMemo, useState } from 'react';

export default function useBoolean(defaults?: boolean) {
  const [bool, setBool] = useState<boolean>(defaults ?? false);
  const open = useCallback(() => setBool(true), []);
  const close = useCallback(() => setBool(false), []);
  const reverse = useCallback(() => setBool((current) => !current), []);

  return useMemo(() => {
    return { bool, setBool, open, close, reverse };
  }, [bool, setBool, open, close, reverse]);
}
