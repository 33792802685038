import React from 'react';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import styled from 'styled-components';
import PaneBox from './components/PaneBox';
import { Crop } from './types';

interface ToolPaneProps extends React.HTMLAttributes<HTMLDivElement> {
  imageSize: { width: number; height: number };
  onAddCrop: (crop: Crop) => void;
}
interface ToolPaneState {
  selected?: string;
}
export default class ToolPane extends React.PureComponent<ToolPaneProps, ToolPaneState> {
  constructor(props: ToolPaneProps) {
    super(props);
    this.state = {};
  }
  private calcInitBounds = () => {
    return {
      x1: 100 / this.props.imageSize.width,
      y1: 100 / this.props.imageSize.height,
      x2: 200 / this.props.imageSize.width,
      y2: 200 / this.props.imageSize.height,
    };
  };
  private handleAddCrop = () => {
    this.props.onAddCrop({ name: 'Crop', bounds: this.calcInitBounds() });
  };
  render() {
    return (
      <PaneBox {...this.props}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <ToolboxButton title='クロップ' onClick={this.handleAddCrop}>
            <Icon icon={IconNames.WIDGET} />
          </ToolboxButton>
        </div>
      </PaneBox>
    );
  }
}

const ToolboxButton = styled.div`
  width: 20px;
  height: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: #dfdfdf #808080 #808080 #dfdfdf;
  background-color: #c0c0c0;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
