// -- basic library --
import React from 'react';
import styles from 'shared/styles/styles';
import styled from 'styled-components';

// -- external datas --

// -- type declaration --
interface Params {
  value1: string;
  value2: string;
  interval_text: string;
}

// -- main component --

const DoubleText: React.FC<Params> = (params) => {
  // -- render part --
  return (
    <WholeArea>
      <InputArea>
        {params.value1}
        <IntervalText>{params.interval_text}</IntervalText>
        {params.value2}
      </InputArea>
    </WholeArea>
  );
};

// -- styled components --

const WholeArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const InputArea = styled.div`
  width: 100%;
  height: ${styles.input_component_height};
  display: flex;
  align-items: center;
`;

const IntervalText = styled.div`
  width: 20px;
  white-space: nowrap;
  text-align: center;
  margin: 20px 10px;
  font-size: ${styles.small_text_size};
  font-weight: ${styles.font_weight};
`;

// -- finally export part --

export default DoubleText;
