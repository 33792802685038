// -- http connection library --
import { useState } from 'react';
import CSVResultBody from './components/CSVResultBody';
import MP4ResultBody from './components/MP4ResultBody';
import { ProcessInfo, ProcessInfoOutput } from 'user/api/streamsProcessInfo';
import PfDialog from 'shared/components/atoms/PfDialog';
import styled from 'styled-components';
import { PIOutput, PIOutputStream } from '../StreamOutputComponent';
import { getOutputResultDisplayName } from 'user/utils/fileCardDisplayingProcessFunctions';
import { ProcessInfoSelector } from './components/ProcessInfoSelector';
import styles from 'shared/styles/styles';

interface ResultDialogProps {
  isOpen: boolean;
  onClose: (isCanceled?: boolean) => void;
  original_file_name?: string;
  process_infos?: ProcessInfo[];
  output: ProcessInfoOutput;
  process_info: ProcessInfo;
}

// -- main component --

function ResultDialog({ output, isOpen, onClose, process_info, process_infos, original_file_name }: ResultDialogProps) {
  const [selected_process_output, setSelectedProcessOutput] = useState<ProcessInfoOutput | null>(output);
  const [selected_process_info, setSelectedProcessInfo] = useState<ProcessInfo | null>(process_info);
  const is_csv =
    selected_process_output?.output_type === 'PACKAGE' &&
    typeof selected_process_output?.stream_package_number === 'number' &&
    selected_process_output.stream_package_number > 0;
  const is_mp4 =
    selected_process_output?.output_type === 'DATA' &&
    typeof selected_process_output?.stream_data_number === 'number' &&
    selected_process_output.stream_data_number > 0;

  // 選択されているprocess_outputかどうかを判定する関数
  const isOutputSelected = (output: ProcessInfoOutput) => {
    if (!selected_process_output) return false;
    if (selected_process_output.stream_id !== output.stream_id) return false;
    if (selected_process_output.output_type !== output.output_type) return false;

    if (selected_process_output.output_type === 'PACKAGE') {
      // stream_package_numberがnumberでかつ、一致している時だけtrue
      return (
        typeof selected_process_output?.stream_package_number === 'number' &&
        selected_process_output?.stream_package_number === output.stream_package_number
      );
    }
    if (selected_process_output?.output_type === 'DATA') {
      // stream_data_numberがnumberでかつ、一致している時だけtrue
      return (
        typeof selected_process_output?.stream_data_number === 'number' &&
        selected_process_output?.stream_data_number === output.stream_data_number
      );
    }
    // それ以外はfalse
    return false;
  };

  const onProcessInfoChange = (new_process_info: ProcessInfo | null) => {
    setSelectedProcessInfo(new_process_info);
    setSelectedProcessOutput(null);
  };

  const onProcessOutputClick = (output: ProcessInfoOutput) => {
    setSelectedProcessOutput(output);
  };

  return (
    <PfDialog large={true} isOpen={isOpen} onClose={() => onClose(true)} title='結果データの表示'>
      <Wrapper>
        <LeftBodyArea>
          <ProcessInfoSelector
            process_infos={process_infos}
            selected_process_info={selected_process_info}
            onChange={onProcessInfoChange}
          />
          <PIOutput>
            {selected_process_info?.outputs.map((output, j) => {
              return (
                <PIOutputStream
                  key={`${j}_${output.stream_data_number}_${output.stream_package_number}`}
                  onClick={() => onProcessOutputClick(output)}
                  style={{ height: 'auto', marginRight: 10 }}
                  selected={isOutputSelected(output)}
                >
                  {getOutputResultDisplayName({
                    stream_data_number: output.stream_data_number,
                    stream_package_number: output.stream_package_number,
                    output_type: output.output_type,
                    output_name: output.output_name,
                    index: j,
                  })}
                </PIOutputStream>
              );
            })}
          </PIOutput>
        </LeftBodyArea>
        <RightBodyArea>
          {is_csv ? (
            <CSVResultBody
              stream_id={selected_process_output.stream_id}
              // is_csv関数で判定済みのためnumberでassertion
              stream_package_number={selected_process_output.stream_package_number as number}
              process_output={selected_process_output}
              setProcessOutput={setSelectedProcessOutput}
              process_info={process_info}
              process_infos={process_infos}
            />
          ) : is_mp4 ? (
            <MP4ResultBody
              // is_mp4関数で判定済みのためnumberでassertion
              stream_data_number={selected_process_output.stream_data_number as number}
              original_file_name={original_file_name}
              stream_id={selected_process_output.stream_id}
              process_output={selected_process_output}
              setProcessOutput={setSelectedProcessOutput}
              process_info={process_info}
              process_infos={process_infos}
            />
          ) : (
            <></>
          )}
        </RightBodyArea>
      </Wrapper>
    </PfDialog>
  );
}

// -- styled components --
const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;

const LeftBodyArea = styled.div`
  width: 20%;
  margin-right: ${styles.interval_narrow_margin};
  display: flex;
  flex-direction: column;
  min-width: 200px;
  align-items: center;
`;

const RightBodyArea = styled.div`
  width: calc(100% - 20% - ${styles.interval_narrow_margin});
  display: flex;
  flex-direction: column;
`;

// -- finally export part --

export default ResultDialog;
