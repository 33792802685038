import { AxiosResponse } from 'axios';
import { ChannelType } from 'shared/models/ChannelType';
import { CommonAPIRequestType, isCommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';
import { FileStreamDataType } from 'shared/models/FileStreamDataType';
import { ISO8601 } from 'shared/models/ISO8601';
import { Query } from 'shared/models/Query';
import { StatusType } from 'shared/models/StatusType';
import sendAxios from '../../shared/axios/sendAxios';
import { getClient } from './base';

/*** エンティティ ***/

export interface Channel {
  tenant_id: string;
  channel_id: string;
  channel_name: string;
  status: StatusType;
  thumbnail_stream_id: string;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
  unconfirm_event_count: number;
  input_stream_ids: string[];
  output_stream_ids: string[];
}

export interface ChannelsWithPaging {
  items: Channel[];
  last_channel_id?: string;
  has_next: boolean;
}

const sample_channel_1: Channel = {
  tenant_id: 'qc38e0567ee111ea9ae30242ac110000',
  channel_id: '00000001',
  channel_name: 'チャンネル1',
  status: 'ACTIVE',
  thumbnail_stream_id: 'string1',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  unconfirm_event_count: 5,
  input_stream_ids: ['00000001', '00000002'],
  output_stream_ids: ['00000003'],
};

const sample_channel_2: Channel = {
  tenant_id: 'qc38e0567ee111ea9ae30242ac110000',
  channel_id: '00000002',
  channel_name: 'チャンネル2',
  status: 'ACTIVE',
  thumbnail_stream_id: 'string1',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  unconfirm_event_count: 0,
  input_stream_ids: ['00000001', '00000002'],
  output_stream_ids: ['00000003'],
};

const sample_channel_3: Channel = {
  tenant_id: 'qc38e0567ee111ea9ae30242ac110000',
  channel_id: '00000003',
  channel_name: 'チャンネル3',
  status: 'ACTIVE',
  thumbnail_stream_id: 'string1',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  unconfirm_event_count: 3,
  input_stream_ids: ['00000001', '00000002'],
  output_stream_ids: ['00000003'],
};

const sample_channel_4: Channel = {
  tenant_id: 'qc38e0567ee111ea9ae30242ac110000',
  channel_id: '00000004',
  channel_name: 'チャンネル4',
  status: 'ACTIVE',
  thumbnail_stream_id: 'string1',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  unconfirm_event_count: 200,
  input_stream_ids: ['00000003'],
  output_stream_ids: ['00000003'],
};

/*** Caching mechanism ***/

export class CachedChannels {
  private channel_type?: ChannelType;
  private from_event_page?: 'True' = undefined;
  private searched = false;
  private cache: Channel[] = [];
  constructor(params?: RequestChannelsGet) {
    if (params?.channel_type) {
      this.channel_type = params.channel_type;
    }
    if (params?.from_event_page) {
      this.from_event_page = params.from_event_page;
    }
  }
  async get() {
    if (!this.searched) {
      let esk: string | undefined = undefined;
      for (;;) {
        const res: AxiosResponse<ChannelsWithPaging> = await channelsGetAPI({
          exclusive_start_channel_id: esk,
          channel_type: this.channel_type,
          from_event_page: this.from_event_page,
        });
        if (res.status === 200) {
          this.cache = [...this.cache, ...res.data.items];
          if (!res.data.last_channel_id) {
            break;
          } else {
            esk = res.data.last_channel_id;
          }
        } else {
          break;
        }
      }
      this.searched = true;
    }
    return this.cache;
  }
}

/*** [GET] /api/channels ***/
export interface RequestChannelsGet extends CommonAPIRequestType {
  exclusive_start_channel_id?: string;
  channel_type?: ChannelType;
  from_event_page?: 'True';
}

export const channelsGetAPI = (params: RequestChannelsGet) => {
  const {
    exclusive_start_channel_id,
    channel_type,
    from_event_page,
    disabled_load,
    disabled_error_message,
    ended_load,
  } = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/channels`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    exclusive_start_channel_id,
    channel_type,
    from_event_page,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<ChannelsWithPaging>(axios, path, query, form, method, {
    items: [sample_channel_1, sample_channel_2, sample_channel_3, sample_channel_4],
    has_next: false,
  });
};

export interface RequestChannelsPost extends CommonAPIRequestType {
  channel_name: string;
  channel_type?: ChannelType;
  file_stream_data_type?: FileStreamDataType; // channel_typeがFILEの時に指定する
}

export const channelsPostAPI = (params: RequestChannelsPost) => {
  const { disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/channels`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (isCommonAPIRequestType(key)) {
      continue;
    }
    form.append(key, value);
  }

  // 送信
  return sendAxios<Channel>(axios, path, query, form, method, sample_channel_1);
};

export interface RequestChannelsIdGet extends CommonAPIRequestType {
  channel_id: string;
  from_event_page?: 'True';
}

export const channelsIdGetAPI = (params: RequestChannelsIdGet) => {
  const { channel_id, from_event_page, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/channels/${channel_id}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    from_event_page,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<Channel>(axios, path, query, form, method, sample_channel_1);
};

export interface RequestChannelsIdPut extends CommonAPIRequestType {
  channel_id: string;
  channel_name?: string;
  thumbnail_stream_id?: string;
}

export const channelsIdPutAPI = (params: RequestChannelsIdPut) => {
  const { channel_id, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/channels/${channel_id}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'channel_id' || isCommonAPIRequestType(key)) continue;
    form.append(key, value);
  }

  // 送信
  return sendAxios<Channel>(axios, path, query, form, method, sample_channel_1);
};

export interface RequestChannelsIdDelete extends CommonAPIRequestType {
  channel_id: string;
}

export const channelsIdDeleteAPI = (params: RequestChannelsIdDelete) => {
  const { channel_id, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'multipleTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/channels/${channel_id}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<Channel>(axios, path, query, form, method, sample_channel_1);
};

export interface RequestChannelsIdThumbnailGet extends CommonAPIRequestType {
  channel_id: string;
  h?: number;
  w?: number;
  from_event_page?: 'True';
}

export const channelsIdThumbnailGetAPI = (params: RequestChannelsIdThumbnailGet) => {
  const { h, w, from_event_page, channel_id, disabled_load, disabled_error_message, ended_load } =
    toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ responseType: 'arraybuffer', disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/channels/${channel_id}/thumbnail`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    h,
    w,
    from_event_page,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<ArrayBuffer>(axios, path, query, form, method, new ArrayBuffer(6));
};

/*** [POST] /api/channels/{id}/input-streams ***/
export interface RequestChannelsIdInputStreamsPost extends CommonAPIRequestType {
  channel_id: string;
  stream_id: string;
}

/**
 * チャンネルに入力データ(ストリーム)を追加する
 * @param channel_id チャンネルID
 * @returns チャンネル
 */
export const channelsIdInputStreamsPostAPI = (params: RequestChannelsIdInputStreamsPost) => {
  const { channel_id, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/channels/${channel_id}/input-streams`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'channel_id' || isCommonAPIRequestType(key)) continue;
    form.append(key, value);
  }

  // 送信
  return sendAxios<Channel>(axios, path, query, form, method, sample_channel_1);
};

/*** [DELETE] /api/channels/{channel_id}/input-streams/${stream_id} ***/
export interface RequestChannelsIdInputStreamsIdDelete extends CommonAPIRequestType {
  channel_id: string;
  stream_id: string;
}

/**
 * チャンネルに入力データ(ストリーム)を削除する
 * @param channel_id チャンネルID
 * @returns チャンネル
 */
export const channelsIdInputStreamsIdDeleteAPI = (params: RequestChannelsIdInputStreamsIdDelete) => {
  const { channel_id, stream_id, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'multipleTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/channels/${channel_id}/input-streams/${stream_id}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<Channel>(axios, path, query, form, method, sample_channel_1);
};
