import React from 'react';
import DatetimeInput from 'shared/components/atoms/DatetimeInput';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import RadioBox from 'shared/components/molecules/RadioBox';
import { colors } from 'shared/styles/colors';
import FeatureDatetime from 'shared/models/FeatureDatetime';
import styled from 'styled-components';

interface DatetimeRangeDialogProps {
  start: FeatureDatetime;
  end: FeatureDatetime;
  is_open?: boolean;
  onClose: (isCanceled: boolean, start?: FeatureDatetime, end?: FeatureDatetime) => void;
}
interface DatetimeRangeDialogState {
  start: FeatureDatetime;
  end: FeatureDatetime;
  selected_date: string; // 開始か終了の選択されている方を示す。選択されている方に日付変更ボタンが適応される
}
/**
 * 日時のレンジを設定するダイアログです。
 * start, end の許容値:
 *  - number は絶対日時(UNIX時刻)を表します
 *  - 'now' は現在のシステム日時を表します
 *  - 'today' は当日(startの場合は00:00:00、endの場合は23:59:59)
 *  - 'yesterday' は前日
 *  - 'sunday' ~ 'saturday' は前のまたは次の該当曜日
 *  - 'month'
 *  - 'year'
 *  - 'hour'
 *  - 'minute'
 *  - '*+N' '*-N' により演算ができます。Nには 's' 'm' 'h' 'D' 'M' 'Y' 'W' を付けることで秒分時日月年週を表します
 */
export default class DatetimeRangeDialog extends React.PureComponent<
  DatetimeRangeDialogProps,
  DatetimeRangeDialogState
> {
  constructor(props: DatetimeRangeDialogProps) {
    super(props);
    this.state = {
      start: props.start,
      end: props.end,
      selected_date: 'start',
    };
  }
  private handleQuickClick = (value: string) => {
    const fd = new FeatureDatetime(value);
    // 値のセット
    if (this.state.selected_date === 'start') {
      this.setState({ start: fd });
    } else if (this.state.selected_date === 'end') {
      this.setState({ end: fd });
    }
  };
  private handleAdjustClick = (value: string) => {
    if (this.state.selected_date === 'start') {
      const t = this.state.start.getValue();
      if (typeof t === 'number') {
        const r = value[0] === '+' ? 1 : -1;
        const dt = new Date(t * 1000);
        switch (value[2]) {
          case 's':
            this.setState({ start: new FeatureDatetime(t + 1 * r) });
            break;
          case 'm':
            this.setState({ start: new FeatureDatetime(t + 60 * r) });
            break;
          case 'h':
            this.setState({ start: new FeatureDatetime(t + 3600 * r) });
            break;
          case 'D':
            this.setState({ start: new FeatureDatetime(t + 86400 * r) });
            break;
          case 'M':
            dt.setMonth(dt.getMonth() + 1 * r);
            this.setState({ start: new FeatureDatetime(Math.floor(dt.getTime() / 1000)) });
            break;
        }
      } else {
        this.setState({ start: new FeatureDatetime(t + value) });
      }
    } else if (this.state.selected_date === 'end') {
      const t = this.state.end.getValue();
      if (typeof t === 'number') {
        const r = value[0] === '+' ? 1 : -1;
        const dt = new Date(t * 1000);
        switch (value[2]) {
          case 's':
            this.setState({ end: new FeatureDatetime(t + 1 * r) });
            break;
          case 'm':
            this.setState({ end: new FeatureDatetime(t + 60 * r) });
            break;
          case 'h':
            this.setState({ end: new FeatureDatetime(t + 3600 * r) });
            break;
          case 'D':
            this.setState({ end: new FeatureDatetime(t + 86400 * r) });
            break;
          case 'M':
            dt.setMonth(dt.getMonth() + 1 * r);
            this.setState({ end: new FeatureDatetime(Math.floor(dt.getTime() / 1000)) });
            break;
        }
      } else {
        this.setState({ end: new FeatureDatetime(t + value) });
      }
    }
  };

  private onFinish = () => {
    if (this.state.start.getTime(false) >= this.state.end.getTime(true)) {
      AlertDialog.show('[開始 < 終了]を満たしていません。');
      return;
    }
    this.props.onClose(false, this.state.start, this.state.end);
  };
  render() {
    return (
      <PfDialog title='データの期間' isOpen={!!this.props.is_open} onClose={() => this.props.onClose(true)}>
        <div style={{ display: 'flex', marginBottom: '5px' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <ColoredSpan is_selected={this.state.selected_date === 'start'}>開始</ColoredSpan>
            <DatetimeInput
              data-testid='start-date-input'
              style={{
                background: this.state.selected_date === 'start' ? colors.table_checked_background_color : 'none',
              }}
              value={this.state.start.getTime(false)}
              onChange={(e) => {
                /**
                 * [NOTICE]削除は無効化する
                 * valueがFeatureDatetime型で空文字列の対応が難しいのでとりあえず削除を無効化する
                 * */
                if (e.currentTarget.value === '') return;
                this.setState({ start: new FeatureDatetime(Number(e.currentTarget.value)) });
              }}
            />
            <ColoredSpan is_selected={this.state.selected_date === 'end'}>終了</ColoredSpan>
            <DatetimeInput
              data-testid='end-date-input'
              style={{
                background: this.state.selected_date === 'end' ? colors.table_checked_background_color : 'none',
              }}
              value={this.state.end.getTime(true)}
              onChange={(e) => {
                /**
                 * [NOTICE]削除は無効化する
                 * valueがFeatureDatetime型で空文字列の対応が難しいのでとりあえず削除を無効化する
                 * */
                if (e.currentTarget.value === '') return;
                this.setState({ end: new FeatureDatetime(Number(e.currentTarget.value)) });
              }}
            />
            <RadioBox
              id='datetime-range-dialog-radio-box'
              title='適応日時'
              datas={[
                {
                  name: '開始',
                  value: 'start',
                },
                {
                  name: '終了',
                  value: 'end',
                },
              ]}
              selectedValue={this.state.selected_date}
              handleChangeClick={(v) => this.setState({ selected_date: v })}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
            <div style={{ marginBottom: '3px' }}>日付</div>
            <div style={{ marginBottom: '3px' }}>
              <RoundedButton text='本日' useTableStyle={true} onClick={() => this.handleQuickClick('today')} />
              <RoundedButton text='昨日' useTableStyle={true} onClick={() => this.handleQuickClick('today-1D')} />
              <RoundedButton text='今月' useTableStyle={true} onClick={() => this.handleQuickClick('month')} />
              <RoundedButton text='今年' useTableStyle={true} onClick={() => this.handleQuickClick('year')} />
            </div>
            <div style={{ marginBottom: '3px' }}>曜日</div>
            <div style={{ marginBottom: '3px' }}>
              <RoundedButton text='日' useTableStyle={true} onClick={() => this.handleQuickClick('Sunday')} />
              <RoundedButton text='月' useTableStyle={true} onClick={() => this.handleQuickClick('Monday')} />
              <RoundedButton text='火' useTableStyle={true} onClick={() => this.handleQuickClick('Tuesday')} />
              <RoundedButton text='水' useTableStyle={true} onClick={() => this.handleQuickClick('Wednesday')} />
              <RoundedButton text='木' useTableStyle={true} onClick={() => this.handleQuickClick('Thursday')} />
              <RoundedButton text='金' useTableStyle={true} onClick={() => this.handleQuickClick('Friday')} />
              <RoundedButton text='土' useTableStyle={true} onClick={() => this.handleQuickClick('Saturday')} />
            </div>
            <div style={{ marginBottom: '3px' }}>現在の時刻</div>
            <div style={{ marginBottom: '3px' }}>
              <RoundedButton text='現在の時刻' useTableStyle={true} onClick={() => this.handleQuickClick('now')} />
              <RoundedButton text='同じ分' useTableStyle={true} onClick={() => this.handleQuickClick('minute')} />
              <RoundedButton text='同じ時間' useTableStyle={true} onClick={() => this.handleQuickClick('hour')} />
            </div>
            <div style={{ marginBottom: '3px' }}>日時の調整</div>
            <div style={{ marginBottom: '3px' }}>
              <RoundedButton text='+1月' useTableStyle={true} onClick={() => this.handleAdjustClick('+1M')} />
              <RoundedButton text='+1日' useTableStyle={true} onClick={() => this.handleAdjustClick('+1D')} />
              <RoundedButton text='+1時間' useTableStyle={true} onClick={() => this.handleAdjustClick('+1h')} />
              <RoundedButton text='+1分' useTableStyle={true} onClick={() => this.handleAdjustClick('+1m')} />
              <RoundedButton text='+1秒' useTableStyle={true} onClick={() => this.handleAdjustClick('+1s')} />
            </div>
            <div style={{ marginBottom: '3px' }}>
              <RoundedButton text='-1月' useTableStyle={true} onClick={() => this.handleAdjustClick('-1M')} />
              <RoundedButton text='-1日' useTableStyle={true} onClick={() => this.handleAdjustClick('-1D')} />
              <RoundedButton text='-1時間' useTableStyle={true} onClick={() => this.handleAdjustClick('-1h')} />
              <RoundedButton text='-1分' useTableStyle={true} onClick={() => this.handleAdjustClick('-1m')} />
              <RoundedButton text='-1秒' useTableStyle={true} onClick={() => this.handleAdjustClick('-1s')} />
            </div>
          </div>
        </div>
        <div style={{ textAlign: 'center' }}>
          <RoundedButton text='キャンセル' is_white={true} onClick={() => this.props.onClose(true)} />
          <RoundedButton text='OK' onClick={this.onFinish} is_margin_left />
        </div>
      </PfDialog>
    );
  }
}

const ColoredSpan = styled.span<{
  is_selected?: boolean;
}>`
  color: ${(param) => (param.is_selected ? colors.component_main_color : 'inherit')};
`;
