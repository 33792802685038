import { memo, useCallback, useEffect, useState } from 'react';

import RoundedAnchorButton from 'shared/components/atoms/RoundedAnchorButton';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { BottomArea, ButtonElementsArea, Content, Title, TopArea } from 'shared/components/molecules/ContentsArea';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableHeaderType, TableBodyUrlType } from 'shared/components/molecules/Table/type';
import { table_cell_button_style } from 'shared/styles/styles';
import { isNotSelected, isSelected } from 'shared/utils/is';
import { dateToYMDHMS } from 'shared/utils/converter/date';
import { Device } from 'user/api/device';
import { CachedDevicesMoviedatas, DeviceMoviedata, devicesIdMoviedatasNumDeleteAPI } from 'user/api/deviceMoviedatas';
import { CachedStreams } from 'user/api/streams';
import { getStreamsIdDataNumberDownloadGetAPIUrl } from 'user/api/streamsData';
import CreateMoviedataDialog from 'user/pages/Devices/DetailPage/RecordedVideosPage/CreateMoviedataDialog';
import DataThumbnailPlayerDialog from 'user/dialogs/DataThumbnailPlayerDialog';
import { loadWrapperFunc } from 'user/utils/loadWrapperFunc';

const acquisitionStatusToJP = (acquisition_status: string) => {
  switch (acquisition_status) {
    case 'PLANNED':
      return '録画予定';
    case 'PROCESSING':
      return '録画中';
    case 'DONE':
      return '完了';
    case 'FAILED':
      return '失敗';
    default:
      return '';
  }
};

// -- type declaration --

type Params = { device: Device };

// テーブルの列の情報まとめたデータ
const headers: TableHeaderType[] = [
  {
    id: 'data_acquisition_at',
    label: '録画日時',
    sortable: true,
    search_props: {
      type: 'datetime',
      default_display: true,
    },
  },
  {
    id: 'recording_time',
    label: '録画時間（分）',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'stream_name',
    label: 'データ名',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'stream_data_name',
    label: 'ストリームデータ名',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'acquisition_status',
    label: '状態',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'detail_element',
    label: '詳細',
    useDoubleTableButtonStyles: true,
  },
];

// BaseTableのbodyのtype
type TableMoviedatasType = {
  id: string;
  data_acquisition_at: string;
  recording_time: number;
  stream_name: TableBodyUrlType;
  stream_data_name: string;
  acquisition_status: string;
  detail_element: JSX.Element;
  stream_id: string;
  stream_data_number: number;
};

// -- external functions --

// -- main component --
const RecordedVideos = memo(function RecordedVideos({ device }: Params) {
  // -- local states --
  const [table_bodies, setTableBodies] = useState<TableMoviedatasType[] | undefined>(undefined);
  const [selected_bodies, setSelectedBodies] = useState<TableMoviedatasType[]>([]);
  const [cachedStreams] = useState<CachedStreams>(new CachedStreams({ with_in_channel_streams: 'True' }));

  // 作成用states
  const [open_create_dialog, setOpenCreateDialog] = useState<boolean>(false);

  // 再生用states
  const [data_thumnail_player_props, setDataThumnailPlayerProps] = useState<
    | {
        stream_id: string;
        stream_data_number: number;
      }
    | undefined
  >();

  // -- handlers --
  const handleCheckClick = (bodies: TableMoviedatasType[]) => {
    setSelectedBodies(bodies);
  };
  const handlePreviewDialogClose = useCallback(() => {
    setDataThumnailPlayerProps(undefined);
  }, []);
  // 再生ボタンを押下した時の関数
  const handlePreviewClick = useCallback((stream_id: string, stream_data_number: number) => {
    setDataThumnailPlayerProps({ stream_id: stream_id, stream_data_number: stream_data_number });
  }, []);

  const loadTableBodies = useCallback(async () => {
    const return_table_datas: TableMoviedatasType[] = [];
    const device_movie_datas: DeviceMoviedata[] = await new CachedDevicesMoviedatas({
      device_id: device.device_id,
    }).get();
    const streams = await cachedStreams.get();

    for (let i = 0; i < device_movie_datas.length; i++) {
      const stream = streams.find((s) => {
        return device_movie_datas[i].stream_id === s.stream_id;
      });
      const acquisition_status = acquisitionStatusToJP(device_movie_datas[i].acquisition_status);
      const return_table_data: TableMoviedatasType = {
        id: device_movie_datas[i].device_movie_number + '',
        data_acquisition_at: dateToYMDHMS(device_movie_datas[i].data_acquisition_at),
        recording_time: device_movie_datas[i].recording_time,
        stream_name: {
          value: stream?.stream_name || '',
          url: `/streams/${device_movie_datas[i].stream_id}/details`,
        },
        stream_data_name: device_movie_datas[i].stream_data_name,
        acquisition_status,
        stream_id: device_movie_datas[i].stream_id,
        stream_data_number: device_movie_datas[i].stream_data_number,
        detail_element: (
          <ButtonElementsArea>
            <RoundedButton
              onClick={() =>
                handlePreviewClick(device_movie_datas[i].stream_id, device_movie_datas[i].stream_data_number)
              }
              text='再生'
              style={table_cell_button_style}
              disabled={device_movie_datas[i].acquisition_status !== 'DONE'}
              stop_propagation
            />
            {device_movie_datas[i].acquisition_status === 'DONE' ? (
              <RoundedAnchorButton
                text='ダウンロード'
                href={getStreamsIdDataNumberDownloadGetAPIUrl({
                  stream_id: device_movie_datas[i].stream_id,
                  stream_data_number: device_movie_datas[i].stream_data_number,
                  original_file_name: device_movie_datas[i].stream_data_name,
                })}
                style={{
                  ...table_cell_button_style,
                  fontSize: '0.8em',
                }}
              />
            ) : (
              <RoundedButton
                text='ダウンロード'
                disabled={true}
                style={{
                  ...table_cell_button_style,
                  fontSize: '0.8em',
                }}
                stop_propagation
              />
            )}
          </ButtonElementsArea>
        ),
      };
      return_table_datas.push(return_table_data);
    }
    setTableBodies(return_table_datas);
  }, [cachedStreams, device, handlePreviewClick]);

  const handleCreateClick = useCallback(() => {
    setOpenCreateDialog(true);
  }, []);

  // 作成用画面の関数
  const handleCreateMoviedataDialogClose = useCallback(
    async (canceled?: boolean) => {
      setOpenCreateDialog(false);
      if (canceled) {
        return;
      }
      await loadTableBodies();
    },
    [loadTableBodies],
  );

  // 削除用の関数
  const handleDeleteClick = async (device_id: string) => {
    ConfirmDialog.show(
      <div>
        [確認]選択されているデバイス動画データを削除します。
        <br />
        本当によろしいですか？
      </div>,
      () => handleOKClick(device_id),
      undefined,
      undefined,
    );
  };

  const handleOKClick = useCallback(
    async (device_id: string) => {
      // 削除関数定義
      const deletes = async () => {
        for (const sd of selected_bodies) {
          await devicesIdMoviedatasNumDeleteAPI({
            device_id: device_id,
            device_movie_number: sd.id,
          });
        }
        await loadTableBodies();
        setSelectedBodies([]);
      };
      // 実行
      await loadWrapperFunc(deletes);
    },

    [selected_bodies, loadTableBodies],
  );

  // -- onload function --
  useEffect(() => {
    (async function () {
      await loadTableBodies();
    })();
  }, [loadTableBodies]);

  // -- render part --
  return (
    <Content>
      <TopArea>
        <Title text='' />
        <RoundedButton
          onClick={handleCreateClick}
          text='録画予約設定'
          disabled={isSelected(selected_bodies.length)}
          is_margin_right={true}
        />
      </TopArea>
      {device ? (
        <BottomArea>
          {table_bodies !== undefined ? (
            <BaseTable
              bodies={table_bodies}
              headers={headers}
              table_name='moviedatas'
              selected_bodies={selected_bodies}
              handleCheckClick={handleCheckClick}
              footer_option={{
                text: 'チェックした項目を削除する',
                handleClick: () => handleDeleteClick(device.device_id),
                disabled: isNotSelected(selected_bodies.length),
              }}
            />
          ) : (
            <Spinner />
          )}
          {open_create_dialog && (
            <CreateMoviedataDialog
              isOpen={open_create_dialog}
              onClose={handleCreateMoviedataDialogClose}
              device_id={device.device_id}
            />
          )}
          {data_thumnail_player_props !== undefined && (
            <DataThumbnailPlayerDialog
              isOpen={data_thumnail_player_props !== undefined}
              onCloseClick={handlePreviewDialogClose}
              stream_id={data_thumnail_player_props.stream_id}
              stream_data_number={data_thumnail_player_props.stream_data_number}
            />
          )}
        </BottomArea>
      ) : (
        <Spinner />
      )}
    </Content>
  );
});

// -- styled components --

// -- finally export part --
export default RecordedVideos;
