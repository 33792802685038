import equal from 'assets/equal.png';
import greaterThan from 'assets/greaterThan.png';
import GreaterThanOrEqual from 'assets/greaterThanOrEqual.png';
import lessThan from 'assets/lessThan.png';
import lessThanOrEqual from 'assets/lessThanOrEqual.png';
import InputNumberBox from "shared/components/atoms/InputNumberBox";
import SelectBox from "shared/components/atoms/SelectBox";
import InputComponent from "shared/components/molecules/InputComponent";
import InputDoubleNumberBox from "shared/components/molecules/InputDoubleNumberBox";
import SelectPanelArea from "shared/components/molecules/SelectPanelArea";
import { convertExecTypeType, ExecTypeType } from "shared/models/ExecTypeType";
import { convertObservationModeType, ObservationModeType } from "shared/models/ObservationModeType";
import { Stream } from "user/api/streams";
import JsonpathInput from 'user/components/molecules/JsonpathInput';

type Params = {
  mode: 'Create' | 'Edit';
  streams?: Stream[];
  input_stream_id: string;
  is_timestamp: boolean;
  evaluation_period: number;
  onEvaluationPeriodChange: ((value: number) => void) | undefined;
  aggregation_period: number;
  onAggregationPeriodChange: ((value: number) => void) | undefined;
  json_path: string;
  onJsonPathChange: (arg0: string) => void;
  statistic_method: string;
  onStatisticMethodChange: (arg0: string) => void;
  evaluate_condition_operator: string;
  onEvaluateConditionOperatorChange: (arg0: string) => void;
  evaluate_condition_value1: number;
  evaluate_condition_value2: number;
  onEvaluateConditionValue1Change: (value: number) => void;
  onEvaluateConditionValue2Change: (value: number) => void;
  alarm_datapoints: number;
  onAlarmDatapointsChange: ((value: number) => void) | undefined;
  observation_mode: ObservationModeType | undefined;
  onObservationModeChange: (arg0: ObservationModeType | undefined) => void;
  exec_type: ExecTypeType | undefined;
  onExecTypeChange: (arg0: ExecTypeType | undefined) => void;
};

/** 汎用イベントのみで入力するフィールド群です。 */
const GeneralConditionPane: React.FC<Params> = (params) => {
  return (<>
    {params.is_timestamp ? (
      <>
        <InputComponent text='イベント状態を決定するまでの評価期間(秒)' required>
          <InputNumberBox
            placeholder='例：30'
            value={params.evaluation_period}
            onChange={params.onEvaluationPeriodChange}
            min={1}
            max={3600}
          />
        </InputComponent>
        <InputComponent text='評価期間のデータポイントの集約間隔' required>
          <InputNumberBox
            placeholder='例：10'
            value={params.aggregation_period}
            onChange={params.onAggregationPeriodChange}
            min={1}
            max={3600}
          />
        </InputComponent>
      </>
    ) : null}
    <InputComponent text='JSONパス' required>
      <JsonpathInput
        placeholder='例：$.persons.men.count'
        value={params.json_path}
        onJsonpathChanged={(item: string) => params.onJsonPathChange(item)}
        stream_id={params.input_stream_id}
      />
    </InputComponent>
    <InputComponent text='集計方法' required>
      <SelectPanelArea
        display_datas={[
          {
            text: '最大値',
            id: 'Maximum',
          },
          {
            text: '最小値',
            id: 'Minimum',
          },
          {
            text: '平均値',
            id: 'Average',
          },
          {
            text: '合計値',
            id: 'Sum',
          },
          {
            text: 'データの個数',
            id: 'Count',
          },
        ]}
        selected_id={params.statistic_method}
        handleClick={(id: string) => params.onStatisticMethodChange(id)}
      />
    </InputComponent>
    <InputComponent text='評価符号' required>
      <SelectPanelArea
        display_datas={[
          {
            text: '等しい',
            id: 'Equal',
            img_src: equal,
          },
          {
            text: '以上',
            id: 'GreaterThanOrEqual',
            img_src: GreaterThanOrEqual,
          },
          {
            text: 'より大きい',
            id: 'GreaterThan',
            img_src: greaterThan,
          },
          {
            text: 'より小さい',
            id: 'LessThan',
            img_src: lessThan,
          },
          {
            text: '以下',
            id: 'LessThanOrEqual',
            img_src: lessThanOrEqual,
          },
          {
            text: '範囲',
            id: 'Between',
          },
        ]}
        selected_id={params.evaluate_condition_operator}
        handleClick={(id: string) => params.onEvaluateConditionOperatorChange(id)}
      />
    </InputComponent>
    <InputComponent text='評価しきい値' required>
      <InputDoubleNumberBox
        placeholders={['入力してください', '入力してください']}
        value1={params.evaluate_condition_value1}
        value2={params.evaluate_condition_value2}
        handleChangeClick1={params.onEvaluateConditionValue1Change}
        handleChangeClick2={params.onEvaluateConditionValue2Change}
        interval_text='〜'
        value2_disabled={params.evaluate_condition_operator !== 'Between'}
      />
    </InputComponent>
    <InputComponent text='アラート状態と判断するデータポイント数' required>
      <InputNumberBox placeholder='例：3' value={params.alarm_datapoints} onChange={params.onAlarmDatapointsChange} />
    </InputComponent>
    <InputComponent text='状態の評価方法' required>
      <SelectBox
        onChange={(e) => params.onObservationModeChange(convertObservationModeType(e.currentTarget.value, true))}
        value={params.observation_mode || ''}
        datas={[
          { name: 'アラート状態への遷移', value: 'TO_ALARM' },
          { name: 'アラート状態中', value: 'ON_ALARM' },
          { name: '全ての状態の遷移', value: 'ON_CHANGE' },
        ]}
        default_text='null'
      />
    </InputComponent>
    {params.mode === 'Create' &&
      <InputComponent text='トリガー条件' required>
        <SelectBox
          onChange={(e) => params.onExecTypeChange(convertExecTypeType(e.currentTarget.value, true))}
          value={params.exec_type || ''}
          datas={[
            { name: 'データの追加', value: 'ADD' },
            { name: '1分ごと', value: 'CRON' },
          ]}
          default_text='null'
        />
      </InputComponent>
    }
    {params.mode === 'Edit' &&
      <InputComponent text='トリガー条件'>
        {params.exec_type === 'ADD' ? 'データの追加' : '1分ごと'}
      </InputComponent>
    }
  </>);
}

export default GeneralConditionPane;
