import React from 'react';
import ContentBodyMat from 'shared/components/atoms/ContentBodyMat';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import { Content } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import styles from 'shared/styles/styles';
import { streamDataTypeToMedia, toFormatBytes } from 'shared/utils/converter';
import styled from 'styled-components';
import { Stream } from 'user/api/streams';
import DetailStreamDialog from 'user/pages/Streams/DetailPage/StreamDetailPanel/EditStreamDialog';
import DataThumbnailPanel from './DataThumbnailPanel';
import StreamVideoPanel from './StreamsVideoPanel';
import StreamMetricPanel from './StreamMetricPanel';

interface StreamDetailPanelProps {
  stream: Stream;
  onStreamChanged: () => void;
}
interface StreamDetailPanelState {
  is_stream_detail_set_dialog_open: boolean;
}
/**
 * データ詳細の表示パネル
 */
export default class StreamDetailPanel extends React.PureComponent<StreamDetailPanelProps, StreamDetailPanelState> {
  constructor(props: StreamDetailPanelProps) {
    super(props);
    this.state = {
      is_stream_detail_set_dialog_open: false,
    };
  }
  private handleSetDialogOpenClick = () => {
    this.setState({ is_stream_detail_set_dialog_open: true });
  };
  private handleStreamDetailSetDialogOkClose = () => {
    this.setState({ is_stream_detail_set_dialog_open: false });
    this.props.onStreamChanged();
  };
  private handleStreamDetailSetDialogCancelClose = () => {
    this.setState({ is_stream_detail_set_dialog_open: false });
  };
  private getStreamDataDisplayPanel = () => {
    const switch_type = streamDataTypeToMedia(this.props.stream.data_type, this.props.stream.data_number_type);
    switch (switch_type) {
      case 'video':
        return (
          <StreamVideoPanel
            stream={this.props.stream}
            is_open_set_dialog={this.state.is_stream_detail_set_dialog_open}
          />
        );
      case 'thumbnail':
        return <DataThumbnailPanel stream={this.props.stream} />;
      case 'metric':
        return <StreamMetricPanel stream={this.props.stream} />;
      default:
        return null;
    }
  };
  render() {
    return (
      <Content style={{ minWidth: `calc(240px + 750px + ${styles.interval_margin} + 30px * 2)` }}>
        <ContentBodyMat flexible={true}>
          <RoundedCardLeftArea>
            <TextArea>
              <InputComponent text='データ番号の基準'>
                <div>{this.props.stream.data_number_type}</div>
              </InputComponent>
              <InputComponent text='データ種類'>
                <div>{this.props.stream.data_type}</div>
              </InputComponent>
              <InputComponent text='データ容量'>
                <div>{toFormatBytes(this.props.stream.total_data_size, 2)}</div>
              </InputComponent>
            </TextArea>
            <RoundedButton
              text_type='DETAIL'
              onClick={this.handleSetDialogOpenClick}
              style={{ width: styles.small_button_width }}
            />
            {this.state.is_stream_detail_set_dialog_open && (
              <DetailStreamDialog
                isOpen={this.state.is_stream_detail_set_dialog_open}
                stream={this.props.stream}
                onCancelClose={this.handleStreamDetailSetDialogCancelClose}
                onOkClose={this.handleStreamDetailSetDialogOkClose}
              />
            )}
          </RoundedCardLeftArea>
          <RoundedCardRightArea>{this.getStreamDataDisplayPanel()}</RoundedCardRightArea>
        </ContentBodyMat>
      </Content>
    );
  }
}

// -- styled components --
const RoundedCardLeftArea = styled.div`
  width: 240px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: ${styles.interval_margin};
`;

const RoundedCardRightArea = styled.div`
  width: calc(100% - 240px - ${styles.interval_margin});
  height: auto;
  display: flex;
  flex-direction: column;
`;

const TextArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
