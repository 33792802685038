import React from 'react';
import { Spinner } from '@blueprintjs/core';
import { colors } from 'shared/styles/colors';
import styled from 'styled-components';
import { editortempPostAPI } from 'user/api/editortemp';
import CanvasPane from './CanvasPane';
import LayersPane from './LayersPane';
import LivePreviewPane from './LivePreviewPane';
import TimelinePane from './Timeline';
import ToolPane from './ToolPane';
import { Layer } from './types';

interface EditorPanelProps {
  streamId?: string;
  streamDataNumber?: number;
  style?: React.CSSProperties;
  value: string;
}
interface EditorPanelState {
  layers: Layer[];
  selectedLayer?: Layer;
  editorTempId?: string;
  imageSize: { width: number; height: number };
}
export default class EditorPanel extends React.PureComponent<EditorPanelProps, EditorPanelState> {
  constructor(props: EditorPanelProps) {
    super(props);
    this.state = {
      layers: props.value !== '' ? JSON.parse(props.value) : [{ name: 'Layer 1', objects: [] }],
      imageSize: { width: 1280, height: 720 },
    };
  }
  componentDidMount() {
    this.updateEditorTemp(this.state.layers);
  }
  private handleLayersChange = (layers: Layer[]) => {
    this.setState({
      layers: layers,
      selectedLayer:
        this.state.selectedLayer && layers.indexOf(this.state.selectedLayer) !== -1
          ? this.state.selectedLayer
          : undefined,
    });
    this.updateEditorTemp(layers);
  };
  private handleLayerSelectionChange = (layer: Layer) => {
    this.setState({ selectedLayer: layer });
  };
  private handleSelectedLayerChange = (layer: Layer) => {
    const newLayers: Layer[] = [];
    for (const o of this.state.layers) {
      newLayers.push(o !== this.state.selectedLayer ? o : layer);
    }
    this.setState({
      layers: newLayers,
      selectedLayer: layer,
    });
    this.updateEditorTemp(newLayers);
  };
  private handleImageSizeChange = (width: number, height: number) => {
    this.setState({ imageSize: { width: width, height: height } });
  };
  private updateEditorTemp = (layers: Layer[]) => {
    editortempPostAPI({
      ...(this.state.editorTempId ? { editor_temp_id: this.state.editorTempId } : {}),
      stream_id: this.props.streamId || '',
      editor_params: JSON.stringify(layers),
    }).then((res) => {
      if (res.status === 200) {
        if (res.data.editor_temp_id !== this.state.editorTempId) {
          this.setState({ editorTempId: res.data.editor_temp_id });
        }
      }
    });
  };
  render() {
    return (
      <EditPanelBase style={this.props.style}>
        {this.state.editorTempId ? (
          <>
            <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
              <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                <ToolPane
                  layer={this.state.selectedLayer}
                  imageSize={this.state.imageSize}
                  onLayerChange={this.handleSelectedLayerChange}
                />
                <CanvasPane
                  streamId={this.props.streamId}
                  streamDataNumber={this.props.streamDataNumber}
                  layers={this.state.layers}
                  onImageSizeChange={this.handleImageSizeChange}
                  onLayersChange={this.handleLayersChange}
                  style={{ flexGrow: 1 }}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <LivePreviewPane
                  streamId={this.props.streamId}
                  streamDataNumber={this.props.streamDataNumber}
                  editorTempId={this.state.editorTempId}
                />
                <LayersPane
                  style={{ flexGrow: 1 }}
                  layers={this.state.layers}
                  selectedLayer={this.state.selectedLayer}
                  onLayersChange={this.handleLayersChange}
                  onLayerSelectionChange={this.handleLayerSelectionChange}
                />
              </div>
            </div>
            <TimelinePane />
          </>
        ) : (
          <Spinner />
        )}
      </EditPanelBase>
    );
  }
}

const EditPanelBase = styled.div`
  background-color: ${colors.dark_gray};
  display: flex;
  flex-direction: column;
  use-select: none;
`;
