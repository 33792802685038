import React, { useState, useEffect, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import id_svg from 'assets/id.svg';
import pw_svg from 'assets/pw.svg';
import { useDispatch, useSelector } from 'react-redux';
import history from 'shared/browserHistory';
import FunctionalText from 'shared/components/atoms/FunctionalText';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import CheckBoxWithText from 'shared/components/molecules/CheckBoxWithText';
import { LoginArea, LoginBackGround, LoginLogo, LoginBaseArea } from 'shared/components/molecules/ContentsArea';
import InputBoxWithIcon from 'shared/components/molecules/InputBoxWithIcon';
import styles from 'shared/styles/styles';
import { isNotOnlySpace } from 'shared/utils/is';
import { isExistedPathname } from 'shared/utils/is';
import styled from 'styled-components';
import { accountsLoginPostAPI } from 'user/api/accounts';
import { authActions, authSelector } from 'user/redux/slices/authSlice';
import { getLoginId, getRememberMe } from 'shared/utils/else/localStorage';
import { notificationsCheckGetAPI } from 'user/api/notificationsCheck';
import { setNotificationSessionStorage } from 'shared/utils/else/sessionStorageFunctions';
import { dateToYMD } from 'shared/utils/converter/date';

// -- main component --

const Login = () => {
  // -- redux preparations --
  const dispatch = useDispatch();
  const auth_state = useSelector(authSelector);
  const remember_me_from_local_storage = getRememberMe();
  const login_id_from_local_storage = getLoginId();

  // -- local states --
  const [login_id, setLoginId] = useState('');
  const [password, setPassword] = useState('');
  const [remember_me, setRememberMe] = useState(false);

  /**
   * 遷移先のpathnameを取得する
   */
  const getTransitionPathname = useCallback(() => {
    let pathname = '/notifications';
    const { location } = window;

    const urlSearchParams = new URLSearchParams(location.search);

    // redirectが存在しない or 空文字列の場合は、初期値を加える
    const redirect = urlSearchParams.get('redirect') || `${location.protocol}//${window.location.host}/notifications`;
    try {
      const redirectUrl = new URL(redirect);
      if (isExistedPathname(redirectUrl.pathname)) {
        pathname = redirectUrl.pathname;
      }
    } catch (e) {
      console.log(`このエラーは無視してください。エラー内容：${e}`);
    }

    return pathname;
  }, []);

  // -- handlers --
  const handlePasswordForgotClick = () => {
    history.push(`/password/forgot`);
  };

  const handleLogin = async (request_data: { login_id: string; password: string }, checked: boolean) => {
    if (!request_data.login_id || !request_data.password) {
      AlertDialog.show('入力欄に空欄があります');
      return;
    }
    const res = await accountsLoginPostAPI(request_data);
    if (res.status === 200) {
      // 新着情報の設定
      const ns_res = await notificationsCheckGetAPI({ latest: 'True' });
      if (ns_res.status === 200) {
        if (ns_res.data.items.length !== 0 && ns_res.data.items[0].title && ns_res.data.items[0].show_date) {
          const maxLength = 60;
          const title = ns_res.data.items[0].title;
          const cut_title = title.length > maxLength ? `${title.slice(0, maxLength)}...` : title;
          const show_date = dateToYMD(ns_res.data.items[0].show_date);
          const display_title = `【新着情報】[${show_date}] ${cut_title}`;
          setNotificationSessionStorage(display_title);
        }
      }
      if (res.data.challenge_name === 'NEW_PASSWORD_REQUIRED') {
        dispatch(
          authActions.signInFirst({
            login_id: request_data.login_id,
            session: res.data.session,
            remember_me: checked,
            is_admin: res.data.is_admin,
            tableau_role: res.data.tableau_role,
          }),
        );
        AlertDialog.show(
          <div>
            通信に成功しました。
            <br />
            初期パスワードを変更するために
            <br />
            [初期パスワード変更画面]に遷移します。
          </div>,
        );
        history.push(`/initialPasswordChange`);
      } else if (res.data.authentication_result) {
        const pathname = getTransitionPathname();
        dispatch(
          authActions.signIn({
            access_key: res.data.authentication_result.access_token,
            refresh_token: res.data.authentication_result.refresh_token,
            session: res.data.session,
            login_id: request_data.login_id,
            remember_me: checked,
            is_admin: res.data.is_admin,
            tableau_role: res.data.tableau_role,
          }),
        );
        history.push(pathname);
      }
    }
  };

  // -- onload function --
  useEffect(() => {
    if (remember_me_from_local_storage && login_id_from_local_storage) {
      setLoginId(login_id_from_local_storage);
      setRememberMe(remember_me_from_local_storage);
    }
  }, [login_id_from_local_storage, remember_me_from_local_storage]);

  // -- render part --
  return auth_state.is_logged_in ? (
    <Redirect to='/notifications' />
  ) : (
    <LoginBackGround data-testid='Login'>
      <LoginArea>
        <LoginLogoArea>
          <LoginLogo />
        </LoginLogoArea>
        <InputArea>
          <InputBoxWithIcon
            icon_src={id_svg}
            text='ログインID'
            handleChangeClick={(e: React.ChangeEvent<HTMLInputElement>) => setLoginId(e.currentTarget.value)}
            value={login_id}
            placeholder='ログインID'
            style={{ marginBottom: styles.interval_margin }}
          />
          <InputBoxWithIcon
            icon_src={pw_svg}
            text='パスワード'
            handleChangeClick={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.currentTarget.value)}
            value={password}
            placeholder='パスワード'
            password
          />
        </InputArea>
        <OptionArea>
          <CheckBoxWithText
            id='keep_login_information'
            text='ログイン情報を維持する'
            checked={remember_me}
            onClick={() => setRememberMe(!remember_me)}
          />
          <FunctionalText text='パスワードを忘れた方はこちら' onClick={handlePasswordForgotClick} />
        </OptionArea>
        <ButtonArea>
          <RoundedButton
            text='ログイン'
            onClick={() => handleLogin({ login_id: login_id, password: password }, remember_me)}
            disabled={!isNotOnlySpace(login_id) || !isNotOnlySpace(password) ? true : false}
          />
        </ButtonArea>
      </LoginArea>
      <AlertDialog />
    </LoginBackGround>
  );
};

// -- styled components --

const LoginLogoArea = styled(LoginBaseArea)`
  height: 35%;
  justify-content: center;
  align-items: center;
`;

const InputArea = styled(LoginBaseArea)`
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${styles.interval_margin};
`;

const OptionArea = styled(LoginBaseArea)`
  height: auto;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${styles.interval_margin};
`;

const ButtonArea = styled(LoginBaseArea)`
  height: 20%;
  justify-content: center;
`;

// -- finally export part --

export default Login;
