import React from 'react';
import InputBox from 'shared/components/atoms/InputBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import SelectBox from 'shared/components/atoms/SelectBox';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import styles from 'shared/styles/styles';
import { AccumulationTypes, convertAccumulationType } from 'shared/models/AccumulationType';
import { toMetricGraphType, graph_types } from 'shared/models/MetricGraphType';
import { MetricStatisticMethod } from 'shared/models/MetricStatisticMethod';
import { Metric } from 'user/api/dashboardsWidgets';
import { Stream } from 'user/api/streams';
import JsonpathInput from 'user/components/molecules/JsonpathInput';
import { Thead, Th, Tbody, Tr, Td, WholeArea, Table, TableArea, ThChildrensArea, FooterArea } from './StyledComponent';

interface MetricsTableProps {
  values: Metric[];
  streams: Stream[];
  is_graph_type: boolean;
  onChange: (value: Metric[]) => void;
  is_live_text: boolean;
}
const MetricsTable: React.FC<MetricsTableProps> = (props) => {
  const handleChange = (value: string, index: number, key: string) => {
    const new_metrics = props.values.slice();
    if (key === 'name') {
      new_metrics[index].name = value;
    }
    if (key === 'json_path') {
      new_metrics[index].json_path = value;
    }
    if (key === 'graph_type') {
      new_metrics[index].graph_type = toMetricGraphType(value);
    }
    if (key === 'stream_id') {
      new_metrics[index].stream_id = value;
    }
    if (key === 'accumulation') {
      new_metrics[index].accumulation = convertAccumulationType(value);
    }
    if (key === 'stack_id') {
      new_metrics[index].stack_id = value;
    }
    props.onChange(new_metrics);
  };
  const handleChangeStatisticMethod = (value: MetricStatisticMethod, index: number) => {
    const new_metrics = props.values.slice();
    new_metrics[index].statistic_method = value;
    props.onChange(new_metrics);
  };
  const handleAddRow = () => {
    const new_metrics = props.values.slice();
    if (new_metrics.length >= 100) {
      AlertDialog.show('メトリクスは最大で100行までです。');
      return;
    }
    new_metrics.push({
      name: '',
      stream_id: '',
      json_path: '',
      statistic_method: '',
    });
    props.onChange(new_metrics);
  };
  const handleDeleteRow = () => {
    const new_metrics = props.values.slice();
    if (new_metrics.length <= 1) {
      AlertDialog.show('メトリクスは少なくとも1つ以上の入力が必要です');
      return;
    }
    new_metrics.pop();
    props.onChange(new_metrics);
  };
  const headers = props.is_graph_type
    ? ['メトリクス名', 'グラフのタイプ', '対象のデータ', 'JSONパス', '集計方法', '累積期間', 'スタック']
    : ['メトリクス名', '対象のデータ', 'JSONパス', '集計方法'];
  const statistic_methods = props.is_live_text
    ? [
        { name: 'なし', value: 'Raw' },
        { name: '最初の値', value: 'Top' },
        { name: '最大値', value: 'Maximum' },
        { name: '最小値', value: 'Minimum' },
        { name: '合計値', value: 'Sum' },
        { name: '平均値', value: 'Average' },
        { name: '値の数', value: 'Count' },
      ]
    : [
        { name: '最大値', value: 'Maximum' },
        { name: '最小値', value: 'Minimum' },
        { name: '合計値', value: 'Sum' },
        { name: '平均値', value: 'Average' },
        { name: '値の数', value: 'Count' },
      ];
  return (
    <WholeArea>
      {/* テーブル */}
      <TableArea>
        <Table>
          <Thead>
            <tr>
              {/* テーブルヘッダー */}
              {headers.map((header, index) => (
                <Th
                  key={index}
                  style={{
                    width: 'calc(100% / 5)',
                    paddingLeft: styles.interval_narrow_margin,
                  }}
                  last_child={index === 5 - 1 ? true : false}
                >
                  <ThChildrensArea>{header}</ThChildrensArea>
                </Th>
              ))}
            </tr>
          </Thead>

          {/* テーブルボディー */}
          <Tbody>
            {/* ボディーデータをソートした後に、一ページ分のデータに切り分ける */}
            {props.values.map((body, index1) => {
              return (
                <Tr key={index1}>
                  <Td key={`name_${index1}`} last_row={index1 + 1 === props.values.length ? true : false}>
                    <InputBox value={body.name} onChange={(e) => handleChange(e.currentTarget.value, index1, 'name')} />
                  </Td>
                  {props.is_graph_type && (
                    <Td key={`graph_type_${index1}`} last_row={index1 + 1 === props.values.length ? true : false}>
                      <SelectBox
                        value={body.graph_type || ''}
                        datas={graph_types.map((d) => {
                          return {
                            value: d,
                            name: d,
                          };
                        })}
                        onChange={(e) => handleChange(e.currentTarget.value, index1, 'graph_type')}
                        long
                      />
                    </Td>
                  )}
                  <Td key={`stream_id_${index1}`} last_row={index1 + 1 === props.values.length ? true : false}>
                    <SelectBox
                      value={body.stream_id}
                      datas={props.streams.map((s) => {
                        return { name: s.stream_name, value: s.stream_id };
                      })}
                      onChange={(e) => handleChange(e.currentTarget.value, index1, 'stream_id')}
                      long
                    />
                  </Td>
                  <Td key={`json_path_${index1}`} last_row={index1 + 1 === props.values.length ? true : false}>
                    <JsonpathInput
                      value={body.json_path}
                      onJsonpathChanged={(item: string) => handleChange(item, index1, 'json_path')}
                      placeholder='$から始めてください'
                      stream_id={body.stream_id}
                    />
                  </Td>
                  <Td
                    key={`statistic_method_${index1}`}
                    last_row={index1 + 1 === props.values.length ? true : false}
                    last_child
                  >
                    <SelectBox
                      value={body.statistic_method}
                      datas={statistic_methods}
                      onChange={(e) =>
                        handleChangeStatisticMethod(e.currentTarget.value as MetricStatisticMethod, index1)
                      }
                      long
                    />
                  </Td>
                  {props.is_graph_type && (
                    <Td key={`accumulation_${index1}`} last_row={index1 + 1 === props.values.length ? true : false}>
                      <SelectBox
                        value={body.accumulation || ''}
                        datas={AccumulationTypes.map((d) => {
                          return {
                            value: d,
                            name: d,
                          };
                        })}
                        onChange={(e) => handleChange(e.currentTarget.value, index1, 'accumulation')}
                        long
                      />
                    </Td>
                  )}
                  {props.is_graph_type && (
                    <Td key={`stack_id_${index1}`} last_row={index1 + 1 === props.values.length ? true : false}>
                      <SelectBox
                        value={body.stack_id || ''}
                        default_text=''
                        default_text_value=''
                        datas={['1', '2', '3', '4', '5', '6', '7', '8', '9'].map((d) => {
                          return {
                            value: d,
                            name: d,
                          };
                        })}
                        onChange={(e) => handleChange(e.currentTarget.value, index1, 'stack_id')}
                        long
                      />
                    </Td>
                  )}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableArea>

      {/* フッター */}
      <FooterArea>
        <RoundedButton
          text='削除'
          onClick={handleDeleteRow}
          style={{
            width: styles.super_small_button_width,
            height: styles.super_small_button_height,
            marginRight: styles.interval_narrow_margin,
          }}
          is_white
        />
        <RoundedButton
          text='追加'
          onClick={handleAddRow}
          style={{
            width: styles.super_small_button_width,
            height: styles.super_small_button_height,
          }}
        />
      </FooterArea>
    </WholeArea>
  );
};

// -- styled components --

export default MetricsTable;
