import React from 'react';
import InputBox from 'shared/components/atoms/InputBox';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import InputComponent from 'shared/components/molecules/InputComponent';
import { oauthStateIdGetAPI, oauthStatePostAPI } from 'user/api/oauth';

interface SafieAuthDialogProps {
  isOpen: boolean;
  value: string;
  onClose: (value?: string) => void;
}
interface SafieAuthDoalogState {
  client_id: string;
  code: string;
  state: string;
  device_id: string;
  redirect_uri: string;
}
/** アプリパラメータでCSV行定義を設定するダイアログです */
export default class SafieAuthDialog extends React.PureComponent<SafieAuthDialogProps, SafieAuthDoalogState> {
  private interval?: ReturnType<typeof setInterval>;
  constructor(props: SafieAuthDialogProps) {
    super(props);
    let o = {
      client_id: '',
      code: '',
      device_id: '',
      redirect_uri: '',
    };
    try {
      o = JSON.parse(this.props.value);
    } catch (e) {
      console.log(e);
    }
    this.state = {
      client_id: o.client_id || '',
      code: o.code || '',
      state: '', // state は毎回リセットします
      device_id: o.device_id || '',
      redirect_uri: o.redirect_uri || '',
    };
  }
  componentDidMount() {
    this.interval = setInterval(this.checkCode, 2000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  /** Oauth後のcodeが発行されているかチェックします。 */
  private checkCode = () => {
    if (this.state.state !== '' && this.state.code === '') {
      oauthStateIdGetAPI({ id: this.state.state }).then((res) => {
        if (res.status === 200 && res.data.is_authorized && res.data.code) {
          this.setState({ state: '', code: res.data.code });
        }
      });
    }
  };
  /** Safie認証画面を開くことができるか判定します。 */
  private canOpenSafie = () => {
    return this.state.client_id !== '' && this.state.code === '';
  };
  private handleSafieAuthOpen = () => {
    oauthStatePostAPI({}).then((res) => {
      if (res.status === 200) {
        const state = res.data.state;
        const redirect_uri = encodeURIComponent(this.state.redirect_uri);
        this.setState({ state: state });
        window.open(
          `https://openapi.safie.link/v1/auth/authorize?client_id=${this.state.client_id}&response_type=code&scope=safie-user/api&redirect_uri=${redirect_uri}&state=${state}`,
          '_blank',
        );
      }
    });
  };
  render() {
    return (
      <PfDialog
        isOpen={this.props.isOpen}
        title='Safie認証'
        onClose={() => this.props.onClose()}
        contentProps={{ style: { display: 'flex', flexDirection: 'column' } }}
        large
      >
        <InputComponent text='code' required>
          <p>
            このボタンを押して、Safie APIへのアクセスを許可してください。
            <br />
            アクセス許可が得られるとCodeが自動的に入力されます。
            <br />
            <RoundedButton
              text='Safie認証画面を開く'
              disabled={!this.canOpenSafie()}
              onClick={this.handleSafieAuthOpen}
            />
          </p>
          <InputBox value={this.state.code} onChange={(e) => this.setState({ code: e.currentTarget.value })} />
        </InputComponent>
        <InputComponent text='device_id' required>
          <p>デバイスのIDを入力してください。</p>
          <InputBox
            value={this.state.device_id}
            onChange={(e) => this.setState({ device_id: e.currentTarget.value })}
          />
        </InputComponent>
        <div style={{ textAlign: 'center' }}>
          <RoundedButton text='キャンセル' is_white={true} onClick={() => this.props.onClose()} />
          <RoundedButton text='OK' onClick={() => this.props.onClose(JSON.stringify(this.state))} />
        </div>
      </PfDialog>
    );
  }
}

interface SafieAuthButtonProps {
  value: string;
  onChange: (value: string) => void;
}
interface SafieAuthButtonState {
  isOpen: boolean;
}
/** ビデオエディタ設定ダイアログを開くためのボタンです。 */
export class SafieAuthButton extends React.PureComponent<SafieAuthButtonProps, SafieAuthButtonState> {
  constructor(props: SafieAuthButtonProps) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  private handleOpen = () => {
    this.setState({ isOpen: true });
  };
  private handleClose = (value?: string) => {
    this.setState({ isOpen: false });
    if (value !== undefined) {
      this.props.onChange(value);
    }
  };
  render() {
    return (
      <>
        <RoundedButton text='Safie認証' onClick={this.handleOpen} />
        {this.state.isOpen && <SafieAuthDialog isOpen={true} value={this.props.value} onClose={this.handleClose} />}
      </>
    );
  }
}
