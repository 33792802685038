import { CommonAPIRequestType, isCommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';
import { Query } from 'shared/models/Query';
import sendAxios from '../../shared/axios/sendAxios';
import { getClient } from './base';

/*** エンティティ ***/

export interface EditorTemp {
  editor_temp_id: string;
  stream_id: string;
  editor_params: string;
}

const sample_editor_temp1: EditorTemp = {
  editor_temp_id: '00000001',
  stream_id: '0000001',
  editor_params: 'params',
};

/*** [POST] /api/editortemp ***/

export interface RequestEditortempPost extends CommonAPIRequestType {
  editor_temp_id?: string;
  stream_id: string;
  editor_params: string;
}

export const editortempPostAPI = (params: RequestEditortempPost) => {
  // このPOST関数はeditorで複数回呼ばれる為、loadはdefaultのまま
  const { disabled_load, disabled_error_message, ended_load } = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/editortemp`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (isCommonAPIRequestType(key)) continue;
    form.append(key, value);
  }

  // 送信
  return sendAxios<EditorTemp>(axios, path, query, form, method, sample_editor_temp1);
};
