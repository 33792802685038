import React from 'react';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import SelectBox from 'shared/components/atoms/SelectBox';
import CheckBoxWithText from 'shared/components/molecules/CheckBoxWithText';
import InputComponent from 'shared/components/molecules/InputComponent';
import { CachedStreams, Stream } from 'user/api/streams';
import { Layer, RectProps } from '../EditorPanel/types';
import { RectForm } from '../ObjectPropertyDialog';

interface LayerPropertyDialogProps {
  streams: CachedStreams;
  layer: Layer; // 更新前のレイヤー
  onCancelClose: () => void;
  onOkClose: (prevLayer: Layer, newLayer: Layer) => void;
}
interface LayerPropertyDialogState {
  streams: Stream[];
  layer: Layer; // 更新中のレイヤー
}
export default class LayerPropertyDialog extends React.PureComponent<
  LayerPropertyDialogProps,
  LayerPropertyDialogState
> {
  constructor(props: LayerPropertyDialogProps) {
    super(props);
    this.state = {
      streams: [],
      layer: { ...props.layer },
    };
  }
  componentDidMount() {
    // ストリームの読み込み
    this.props.streams.get().then((res) => this.setState({ streams: res }));
  }
  private handleLinkStream = (b: boolean) => {
    const newLayer = { ...this.state.layer };
    if (b) {
      newLayer.link_stream_id = this.state.streams.length > 0 ? this.state.streams[0].stream_id : undefined;
    } else {
      newLayer.link_stream_id = undefined;
    }
    this.setState({ layer: newLayer });
  };
  private handleStreamChange = (stream_id: string) => {
    const newLayer = { ...this.state.layer };
    newLayer.link_stream_id = stream_id;
    this.setState({ layer: newLayer });
  };
  private handleOkClick = () => {
    this.props.onOkClose(this.props.layer, this.state.layer);
  };
  private getStreamOf = (streamId: string) => {
    for (const stream of this.state.streams) {
      if (stream.stream_id === streamId) {
        return stream;
      }
    }
    return null;
  };
  private handleRectPropsChanged = (rectProps: RectProps) => {
    this.setState({ layer: { ...this.state.layer, ...rectProps } });
  };
  render() {
    return (
      <PfDialog isOpen={true} title='レイヤー' onClose={this.props.onCancelClose}>
        <InputComponent text='データとリンク'>
          <CheckBoxWithText
            text='データを読み込む'
            checked={this.state.layer.link_stream_id !== undefined}
            onClick={() => this.handleLinkStream(this.state.layer.link_stream_id === undefined)}
          />
          {this.state.layer.link_stream_id !== undefined && (
            <SelectBox
              onChange={(e) => this.handleStreamChange(e.currentTarget.value)}
              value={this.state.layer.link_stream_id}
              datas={this.state.streams.map((s) => {
                return { name: s.stream_name, value: s.stream_id };
              })}
            />
          )}
        </InputComponent>
        {this.state.layer.link_stream_id !== undefined &&
          this.getStreamOf(this.state.layer.link_stream_id)?.data_type === 'METRIC' && (
            <RectForm rectProps={this.state.layer} onChanged={this.handleRectPropsChanged} />
          )}
        <div style={{ textAlign: 'center' }}>
          <RoundedButton text='キャンセル' is_white={true} onClick={() => this.props.onCancelClose()} />
          <RoundedButton text='OK' onClick={this.handleOkClick} />
        </div>
      </PfDialog>
    );
  }
}
