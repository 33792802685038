// 複数ファイル処理する

// -- http connection library --
import { SQSBoolean } from 'shared/components/atoms/SQS';

// -- external components --

// -- external functions --
import sumArray from '../../shared/utils/else/sum';
import { ProcessflowStep, channelsIdProcessflowPostAPI } from 'user/api/channelsProcesses';
import FileProcessesPercentNumberDialog from 'shared/components/molecules/FileProcessesPercentNumberDialog';

/**
 * 複数のファイル処理をする関数
 * **/
const fileProcessesMulti = async (p: {
  stream_datas: (number | string)[];
  channel_id: string;
  channel_process_name: string;
  process_flow_id: string | undefined;
  is_oneshot?: 'True';
  steps: ProcessflowStep[];
  onConfirm?: () => void; // ダイアログの確認ボタンを押した時の関数
}) => {
  // 目標ファイル処理数
  const goal_file_length = p.stream_datas.length;
  // ファイル処理が完了したファイル数
  let finished_number = 0;
  // 中止フラッグクラス。trueになったら後続を止める
  const abort = new SQSBoolean();

  //ファイル処理の進捗率をテーブルとして格納する
  // i番目の要素のファイル処理進捗率(0~1)
  const progress_table: number[] = new Array(goal_file_length).fill(0);
  // ファイル処理の進捗率を更新する関数
  const onChangeProgressBar = (inc: number, index: number) => {
    // 最大で1
    progress_table[index] = Math.min(inc, 1);
    // テーブルの合計/ファイル数
    const s = sumArray(progress_table) / goal_file_length;
    FileProcessesPercentNumberDialog.updateValue(s);
  };

  FileProcessesPercentNumberDialog.show(0, goal_file_length, {
    onAbort: () => abort.updateFlag(true),
    onConfirm: p.onConfirm,
  });
  // ファイル処理は直列に行う
  while (finished_number < p.stream_datas.length) {
    // 進捗ダイアログ表示の更新
    const stream_data = [];
    stream_data.push(p.stream_datas[finished_number]);
    const res = await channelsIdProcessflowPostAPI({
      channel_id: p.channel_id,
      channel_process_name: p.channel_process_name,
      process_flow_id: p.process_flow_id || '',
      is_oneshot: 'True',
      steps: p.steps,
      input_stream_data_number: stream_data,
      disabled_load: true,
    });
    if (res.status !== 200) {
      return res;
    }
    finished_number += 1;
    onChangeProgressBar(1, finished_number);
    FileProcessesPercentNumberDialog.updateFinishedNumber(finished_number);
    // ループ数及びresの正常を持って完了と判定する
    if (res.status === 200) {
      // もし、全てのファイル処理ができていたら、終了
      if (finished_number === goal_file_length) {
        return res;
      }
    }
  }
};

export default fileProcessesMulti;
