import { ProgressBar, RangeSlider } from '@blueprintjs/core';
import { DashboardHeatmapMetric, DashboardsIdWidgetsNumImageGetAPI } from 'user/api/dashboardsWidgets';
import InputBox from 'shared/components/atoms/InputBox';
import Spinner from 'shared/components/atoms/Spinner';
import HeatmapStage, { HeatmapXY } from 'shared/components/molecules/HeatmapStage';
import React from 'react';
import DateRangeWidgetBase3 from './base/DateRangeWidgetBase3';
import { WidgetProps } from './getWidgetType';

interface ITimedMetrics {
  [t: number]: DashboardHeatmapMetric[]
};
interface HeatmapWidgetState {
  timedMetrics: ITimedMetrics;
  filterdMetrics: HeatmapXY[],
  circleRadius: number;
  stageWidth: number;
  stageHeight: number;
  image?: HTMLImageElement;
  rangeMax: number;
  range: number[];
  isDrawing: boolean;
  drawingProgress: number;
}
export default class HeatmapWidget extends React.PureComponent<WidgetProps, HeatmapWidgetState> {
  container: HTMLDivElement | null | undefined;
  constructor(props: WidgetProps) {
    super(props);
    this.state = {
      timedMetrics: {},
      filterdMetrics: [],
      circleRadius: 20,
      stageWidth: 100,
      stageHeight: 100,
      rangeMax: 10,
      range: [0, 10],
      isDrawing: false,
      drawingProgress: 0,
    };
  }
  componentDidMount() {
    this.checkSize();
    // here we should add listener for "container" resize
    // take a look here https://developers.google.com/web/updates/2016/10/resizeobserver
    // for simplicity I will just listen window resize
    window.addEventListener('resize', this.checkSize);
    // Load image
    this.loadImage();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.checkSize);
  }
  private loadImage = () => {
    DashboardsIdWidgetsNumImageGetAPI({
      dashboard_id: this.props.widget.dashboard_id,
      dashboard_widget_number: this.props.widget.dashboard_widget_number,
    }).then((res) => {
      if (res.status === 200) {
        const image = new Image();
        const blob = new Blob([res.data], { type: 'image/png' });
        const url = window.URL || window.webkitURL;
        image.src = url.createObjectURL(blob);
        image.onload = () => {
          this.setState({ image: image });
        };
      }
    });
  };
  /** Konvaステージの表示倍率を計算します。
   * 背景画像がある場合はそれを100%として縮小します。
   * 拡大(100%超)はしません。
   */
  private calcStageScale = () => {
    const scaleX = this.state.image ? this.state.stageWidth / this.state.image.width : 1.0;
    const scaleY = this.state.image ? this.state.stageHeight / this.state.image.height : 1.0;
    return Math.min(scaleX, scaleY, 1.0);
  };
  private checkSize = () => {
    if (this.container) {
      const width = this.container.offsetWidth;
      const height = this.container.offsetHeight;
      this.setState({
        stageWidth: width,
        stageHeight: height,
      });
    }
  };
  private handleMetricChanged = (metric: DashboardHeatmapMetric[]) => {
    let max = 0;
    const timedMetrics: ITimedMetrics = {};
    for (const m of metric) {
      const t = m.t;
      if (t) {
        let metrics = timedMetrics[t];
        if (metrics === undefined) {
          metrics = timedMetrics[t] = [];
        }
        metrics.push(m);
        if (max < t) {
          max = t;
        }
      }
    }
    this.setState({ timedMetrics: timedMetrics, filterdMetrics: filterMetrics(timedMetrics, [0, max]), rangeMax: max, range: [0, max] });
  };
  private onInitMetrics = () => {
    this.setState({ timedMetrics: {} });
  };

  render() {
    return (
      <DateRangeWidgetBase3
        widget={this.props.widget}
        state={this.props.state}
        onInitMetrics={this.onInitMetrics}
        underAreaControl={this.renderUnderAreaControl()}
        underAreaControlDirection='column'
        onStateChanged={this.props.onStateChanged}
        onMetricsChanged={this.handleMetricChanged}
      >
        <div
          style={{ width: '100%', height: '100%' }}
          ref={(node) => {
            this.container = node;
          }}
        >
          {this.state.timedMetrics ? (
            <HeatmapStage
              width={this.state.stageWidth}
              height={this.state.stageHeight}
              scale={this.calcStageScale()}
              data={this.state.filterdMetrics}
              circleRadius={this.state.circleRadius}
              image={this.state.image}
              onFinishDraw={() => this.setState({isDrawing: false, drawingProgress: 100})}
              onUpdateDrawingProgress={(p) => this.setState({isDrawing: true, drawingProgress: p})}
            />
          ) : (
            <Spinner />
          )}
        </div>
      </DateRangeWidgetBase3>
    );
  }
  private renderUnderAreaControl() {
    return (
      <UnderAreaControl
        circleRadius={this.state.circleRadius}
        onCircleRadiusChange={(value) => this.setState({ circleRadius: value })}
        rangeMax={this.state.rangeMax}
        range={this.state.range}
        onRangeChange={(value) => this.setState({ range: value, filterdMetrics: filterMetrics(this.state.timedMetrics, value) })}
        isDrawing={this.state.isDrawing}
        drawingProgress={this.state.drawingProgress}
      />
    );
  }
}
function filterMetrics(timedMetrics: ITimedMetrics, range: number[]) {
  return Object.keys(timedMetrics)
    .map(key => Number.parseInt(key))
    .filter(key => range[0] <= key && key <= range[1])
    .sort((a, b) => a - b)
    .map((key) => {
      return timedMetrics[key];
    })
    .flat()
    .filter(m => m !== undefined)
    .map(m => {
      if (Array.isArray(m.point)) {
        return {x: m.point[0], y: m.point[1]};
      }
      return {x: 0, y: 0};
    });
}


interface UnderAreaControlProps {
  circleRadius: number;
  onCircleRadiusChange: (value: number) => void;
  rangeMax: number;
  range: number[];
  onRangeChange: (value: number[]) => void;
  isDrawing: boolean;
  drawingProgress: number;
}
interface UnderAreaControlState {
  range: number[];
}
class UnderAreaControl extends React.PureComponent<UnderAreaControlProps, UnderAreaControlState> {
  constructor(props: UnderAreaControlProps) {
    super(props);
    this.state = {
      range: props.range,
    };
  }
  componentDidUpdate(prevProps: Readonly<UnderAreaControlProps>): void {
    if (prevProps.range !== this.props.range) {
      this.setState({range: this.props.range});
    }
  }
  /** サークルサイズが不正にならないよう補正 */
  private convertCircleRadius(valueAsNumber: number) {
    try {
      const v = valueAsNumber;
      if (v <= 1) {
        return 1;
      } else if (100 <= v) {
        return 100;
      }
      return v;
    } catch (e) {
      return 20;
    }
  }
  render() {
    return (
      <>
        <div style={{width: "100%", display: "flex"}}>
          <InputBox
            type='number'
            value={this.props.circleRadius}
            style={{ width: '90px' }}
            onChange={(e) => this.props.onCircleRadiusChange(this.convertCircleRadius(e.currentTarget.valueAsNumber))}
          />
          <span style={{ width: '15px' }}></span>
          {this.props.rangeMax !== 0 ?
            <RangeSlider
              min={0}
              max={this.props.rangeMax}
              labelStepSize={this.props.rangeMax}
              value={[this.state.range[0], this.state.range[1]]}
              onChange={(value) => this.setState({ range: value })}
              onRelease={(value) => this.props.onRangeChange(value)} />
            : "データがありません"
          }
        </div>
        {this.props.isDrawing &&
          <div style={{width: "100%", display: "flex"}}>
            <ProgressBar value={this.props.drawingProgress / 100} />
          </div>
        }
      </>
    );
  }
}
