import { widgetScaleToText, WidgetScaleType } from 'shared/models/WidgetScaleType';
import MultiStateToggleButton from './MultiStateToggleButton';

const items: {
  label: string;
  value: NonNullable<WidgetScaleType>;
}[] = [
  { label: widgetScaleToText('MIN'), value: 'MIN' },
  { label: widgetScaleToText('HOUR'), value: 'HOUR' },
  { label: widgetScaleToText('DAY'), value: 'DAY' },
  { label: widgetScaleToText('MONTH'), value: 'MONTH' },
];

export interface WidgetScalerProps {
  value: WidgetScaleType | null;
  onChange(value: WidgetScaleType | null): void;
  long?: boolean;
  is_margin_left?: boolean;
}
export const WidgetScaler = (props: WidgetScalerProps) => {
  const { value, onChange, is_margin_left, long = true } = props;
  return (
    <MultiStateToggleButton
      value={value}
      onChange={onChange}
      long={long}
      is_margin_left={is_margin_left}
      items={items}
    />
  );
};
