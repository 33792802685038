import React from 'react';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import styled from 'styled-components';
import PaneBox from './components/PaneBox';
import { Layer } from './types';

interface ToolPaneProps extends React.HTMLAttributes<HTMLDivElement> {
  layer?: Layer;
  imageSize: { width: number; height: number };
  onLayerChange: (layer: Layer) => void;
}
interface ToolPaneState {
  selected?: string;
}
export default class ToolPane extends React.PureComponent<ToolPaneProps, ToolPaneState> {
  constructor(props: ToolPaneProps) {
    super(props);
    this.state = {};
  }
  private calcInitBounds = () => {
    return {
      x1: 100 / this.props.imageSize.width,
      y1: 100 / this.props.imageSize.height,
      x2: 200 / this.props.imageSize.width,
      y2: 200 / this.props.imageSize.height,
    };
  };
  private handleAddRectangle = () => {
    if (this.props.layer) {
      const newLayer = { ...this.props.layer };
      newLayer.objects.push({ name: 'Rectangle', type: 'RECT', bounds: this.calcInitBounds(), fill_color: '#ffffff' });
      this.props.onLayerChange(newLayer);
    }
  };
  private handleAddText = () => {
    if (this.props.layer) {
      const newLayer = { ...this.props.layer };
      newLayer.objects.push({ name: 'Text', type: 'TEXT', bounds: this.calcInitBounds(), text: 'Some text here.' });
      this.props.onLayerChange(newLayer);
    }
  };
  private handleAddLine = () => {
    if (this.props.layer) {
      const newLayer = { ...this.props.layer };
      newLayer.objects.push({ name: 'Line', type: 'LINE', bounds: this.calcInitBounds() });
      this.props.onLayerChange(newLayer);
    }
  };
  render() {
    return (
      <PaneBox {...this.props}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <ToolboxButton title='矩形' onClick={this.handleAddRectangle}>
            <Icon icon={IconNames.WIDGET} />
          </ToolboxButton>
          <ToolboxButton title='テキスト' onClick={this.handleAddText}>
            <Icon icon={IconNames.NEW_TEXT_BOX} />
          </ToolboxButton>
          <ToolboxButton title='ライン' onClick={this.handleAddLine}>
            <Icon icon={IconNames.SLASH} />
          </ToolboxButton>
        </div>
      </PaneBox>
    );
  }
}

const ToolboxButton = styled.div`
  width: 20px;
  height: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: #dfdfdf #808080 #808080 #dfdfdf;
  background-color: #c0c0c0;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
