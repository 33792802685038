import { DashboardWidget, Metric, WidgetSetting } from 'user/api/dashboardsWidgets';
import React, { useCallback, useEffect, useState } from 'react';
import getWidgetComponents from 'user/widgets/getWidgetType';
import { WidgetState } from 'user/pages/Dashboards/DetailPage/UsualDashboardWidgets/DashboardWidgetCard';
import { jsonpathGroupsIdGetAPI } from 'user/api/jsonpathGroups';
import { Stream } from 'user/api/streams';
import { MetricStatisticMethod } from 'shared/models/MetricStatisticMethod';
import SelectBox from 'shared/components/atoms/SelectBox';
import InputComponent from 'shared/components/molecules/InputComponent';
import { toISO8601 } from 'shared/models/ISO8601';
import { MetricGraphType, graph_types } from 'shared/models/MetricGraphType';
import { toTimeString } from 'shared/models/TimeString';
import { isRequiredFixedLabel } from 'shared/utils/is';
import { Spinner } from '@blueprintjs/core/lib/esm/components';
import styled from 'styled-components';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import styles from 'shared/styles/styles';
import history from 'shared/browserHistory';

// -- types --
interface Params {
  stream: Stream;
}

// -- main component --
const StreamMetricPanel: React.FC<Params> = (params) => {
  const [graph_type, setGraphType] = useState<MetricGraphType>('LINE');
  const [widget_state, setWidgetState] = useState<WidgetState>();
  const [widget, setwidget] = useState<DashboardWidget>();
  const loadWidget = useCallback(async () => {
    const widget_setting: WidgetSetting = {
      start_date: null,
      end_date: null,
      start_time: toTimeString('00:00'),
      end_time: toTimeString('23:59'),
      scale: 'HOUR',
    };
    const metrics: Metric[] = [];
    if (params.stream.detail_jsonpath_group_id) {
      const res = await jsonpathGroupsIdGetAPI({ jsonpath_group_id: params.stream.detail_jsonpath_group_id });
      if (res.status === 200) {
        const csv_rows = res.data['csv_rows'];
        for (let i = 0; i < Math.min(csv_rows.length, 20); i++) {
          const csv_row = csv_rows[i];
          const metric: Metric = {
            name: csv_row.header_name,
            graph_type: graph_type,
            stream_id: params.stream.stream_id,
            json_path: csv_row.json_path,
            statistic_method: csv_row.how_statistic_method as MetricStatisticMethod,
            accumulation: csv_row.accumulation,
            stack_id: csv_row.stack_id,
          };
          metrics.push(metric);
        }
      }
    }

    const fixed = false;
    if (!widget_state) {
      setWidgetState({
        start_date: null,
        end_date: null,
        start_time: toTimeString('00:00'),
        end_time: toTimeString('23:59'),
        scale: 'HOUR',
        fixed,
      });
    }
    setwidget({
      tenant_id: params.stream.tenant_id,
      dashboard_id: '',
      dashboard_widget_number: 0,
      dashboard_widget_name: params.stream.stream_name,
      widget_type: 'GRAPH',
      widget_setting: widget_setting,
      metrics: metrics,
      graph_type: graph_type as MetricGraphType,
      jsonpath_group_id: params.stream.detail_jsonpath_group_id ? params.stream.detail_jsonpath_group_id : '',
      od_age: false,
      od_gender: false,
      gp_stream_id: params.stream.stream_id,
      order: undefined,
      status: params.stream.status,
      created_at: toISO8601(params.stream.created_at),
      updated_at: toISO8601(params.stream.updated_at),
      deleted_at: params.stream.deleted_at ? toISO8601(params.stream.deleted_at) : null,
    });
  }, [graph_type, params.stream, widget]); /* eslint-disable-line */
  // -- onload function --
  useEffect(() => {
    loadWidget();
  }, [graph_type]); /* eslint-disable-line */
  /** ウィジェットの表示設定が変更されました。 */
  const onStateChanged = (widgetState: WidgetState) => {
    const { start_date, end_date, start_time, end_time } = widgetState;

    setWidgetState({
      ...widgetState,
      fixed: isRequiredFixedLabel({ start_date, end_date, start_time, end_time }),
    });
  };

  return (
    <>
      <TopArea>
        <InputComponent text='グラフタイプ' required>
          <SelectBox
            onChange={(e) => setGraphType(e.currentTarget.value as MetricGraphType)}
            value={graph_type}
            datas={graph_types.map((graph_type) => {
              return { name: graph_type, value: graph_type };
            })}
            long
          />
        </InputComponent>
      </TopArea>
      <GraphArea>
        {params.stream.detail_jsonpath_group_id ? (
          <>
            {widget_state && widget ? (
              <div style={{ width: '100%', height: '100%', flexGrow: 1 }}>
                {React.createElement(getWidgetComponents('STREAM'), {
                  key: 0,
                  widget: widget,
                  state: widget_state,
                  onStateChanged: onStateChanged,
                })}
              </div>
            ) : (
              <Spinner />
            )}
          </>
        ) : (
          <div>
            {'詳細ボタンにて開く画面から、「詳細画面のJSONパスグループ」を設定することによりグラフを表示できます。'}
          </div>
        )}
      </GraphArea>
      <FooterArea>
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
          <RoundedButton
            text='CSV出力'
            onClick={() =>
              history.push(`/streams/${params.stream.stream_id}/data_download_packages?is_create_open=true`)
            }
          />
        </div>
      </FooterArea>
    </>
  );
};

// -- styled components --

const TopArea = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GraphArea = styled.div`
  max-width: 750px;
  min-height: 500px;
  width: 100%;
  height: calc(100% - 70px - 25px);
`;

const FooterArea = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 25px;
  margin-top: ${styles.interval_narrow_margin};
`;

// -- finally export part --

export default StreamMetricPanel;
