import { Point } from 'shared/models';

function calculateCrossProduct(p1: Point, p2: Point, p3: Point): number {
  const dx1 = p2.x - p1.x;
  const dy1 = p2.y - p1.y;
  const dx2 = p3.x - p2.x;
  const dy2 = p3.y - p2.y;

  return dx1 * dy2 - dy1 * dx2;
}

function isClockwise(points: Point[]): boolean {
  let crossProductSum = 0;
  const pointCount = points.length;

  for (let i = 0; i < pointCount; i++) {
    const p1 = points[i];
    const p2 = points[(i + 1) % pointCount];
    const p3 = points[(i + 2) % pointCount];

    crossProductSum += calculateCrossProduct(p1, p2, p3);
  }

  return crossProductSum > 0;
}

export function getClockwizeLabel(co: number[][]) {
  try {
    const points = co.map((c) => {
      return { x: c[0], y: c[1] };
    });
    return isClockwise(points) ? '時計回り' : '反時計回り';
  } catch (e) {
    return '';
  }
}
