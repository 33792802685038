import { AxiosResponse } from 'axios';
import { CommonAPIRequestType, isCommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';
import { ISO8601 } from 'shared/models/ISO8601';
import { Query } from 'shared/models/Query';
import { StatusType } from 'shared/models/StatusType';
import { UserType } from 'shared/models/UserType';
import { createMultipleAPIDatas } from 'shared/utils/else';
import sendAxios from '../../shared/axios/sendAxios';
import { getClient } from './base';

/*** エンティティ ***/

export interface User {
  tenant_id: string;
  user_id: string;
  user_name: string;
  status: StatusType;
  login_id: string;
  mail_address: string;
  user_type: UserType;
  role_id: string;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

export interface UsersWithPaging {
  items: User[];
  has_next: boolean;
}

const sample_user_1: User = {
  tenant_id: '00000001',
  user_id: '00000001',
  user_name: 'テストユーザー1',
  status: 'ACTIVE',
  login_id: 'test_user_1',
  mail_address: 'test1@sample.com',
  user_type: 'ADMIN',
  role_id: '00000001',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: null,
};

/*** Caching mechanism ***/

export class CachedUsers {
  private searched = false;
  private cache: User[] = [];
  private params: RequestUsersGet;
  constructor(params: RequestUsersGet) {
    this.params = params;
  }
  async get() {
    if (!this.searched) {
      const esk: string | undefined = undefined;
      const status: StatusType | undefined = 'ACTIVE';
      for (;;) {
        const res: AxiosResponse<UsersWithPaging> = await usersGetAPI({
          ...this.params,
          status,
          exclusive_start_user_id: esk,
        });
        if (res.status === 200) {
          this.cache = [...this.cache, ...res.data.items];
          break;
          // バックエンドAPIがlast_evaluated_stream_data_numberを返してないのでコメントアウト
          // if (!res.data.has_next) {
          //   break;
          // }
          // else {
          //   esk = res.data.last_evaluated_stream_data_number;
          // }
        } else {
          break;
        }
      }
      this.searched = true;
    }
    return this.cache;
  }
}

/*** [GET] /api/users ***/

export interface RequestUsersGet {
  status?: StatusType;
  exclusive_start_user_id?: string;
}

export const usersGetAPI = (params: RequestUsersGet) => {
  const { status, exclusive_start_user_id, disabled_load, disabled_error_message, ended_load } =
    toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/users`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    status,
    exclusive_start_user_id,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<UsersWithPaging>(axios, path, query, form, method, {
    items: createMultipleAPIDatas(30, 'user_id', sample_user_1),
    has_next: false,
  });
};

/*** [GET] /api/users/{user_id} ***/

export interface RequestUsersIdGet {
  user_id: string;
}

export const usersIdGetAPI = (params: RequestUsersIdGet) => {
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `/api/users/${params.user_id}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<User>(axios, path, query, form, method, sample_user_1);
};

/*** [POST] /api/users ***/

export interface RequestUsersPost extends CommonAPIRequestType {
  user_name: string;
  mail_address?: string;
  login_id: string;
  user_type: UserType;
  role_id?: string;
}

export const usersPostAPI = (params: RequestUsersPost) => {
  const { disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/users`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (isCommonAPIRequestType(key)) continue;
    form.append(key, value);
  }

  // 送信
  return sendAxios<User>(axios, path, query, form, method, sample_user_1);
};

/*** [PUT] /api/users/{user_id} ***/

export interface RequestUsersIdPut extends CommonAPIRequestType {
  user_id: string;
  user_name: string;
  mail_address?: string;
  user_type: UserType;
  role_id?: string;
}

export const usersIdPutAPI = (params: RequestUsersIdPut) => {
  const { user_id, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/users/${user_id}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'user_id' || isCommonAPIRequestType(key)) continue;
    form.append(key, value);
  }

  // 送信
  return sendAxios<User>(axios, path, query, form, method, sample_user_1);
};

/*** [DELETE] /api/users/{user_id} ***/

export interface RequestUsersIdDelete extends CommonAPIRequestType {
  user_id: string;
}

export const usersIdDeleteAPI = (params: RequestUsersIdDelete) => {
  const { user_id, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'multipleTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/users/${user_id}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<User>(axios, path, query, form, method, sample_user_1);
};
