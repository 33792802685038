import { Dashboard } from 'user/api/dashboards';
import { ReactNode } from 'react';
import { EditIcon } from 'shared/components/atoms/Icons';
import { TopArea, Title } from 'shared/components/molecules/ContentsArea';
import useBoolean from 'shared/hooks/useBoolean/useBoolean';
import EditDashboardDialog from '../EditDialog';

export interface DashboardTopAreaWrapperProps {
  children?: ReactNode;
  dashboard: Dashboard;
  is_admin: boolean;
  dialogCallback?(): void;
}
export default function DashboardTopAreaWrapper({
  children,
  dashboard,
  is_admin,
  dialogCallback,
}: DashboardTopAreaWrapperProps) {
  const { bool: is_dialog_open, open, close } = useBoolean(false);
  const customClose = (is_canceled: boolean) => {
    close();
    if (is_canceled) return;
    dialogCallback?.();
  };
  return (
    <TopArea>
      <Title
        style={{ width: 'auto', whiteSpace: 'nowrap' }}
        text={dashboard.dashboard_name}
        icon={is_admin ? <EditIcon onClick={open} /> : undefined}
      />
      {children}
      {is_admin && <EditDashboardDialog isOpen={is_dialog_open} dashboard={dashboard} onClose={customClose} />}
    </TopArea>
  );
}
