import React from 'react';
import InputBox from 'shared/components/atoms/InputBox';
import AppParameterInput from 'user/components/molecules/AppParameterInput';
import { SubTextBasicProcess, SubTextDetails } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import ProcessPanels from 'shared/components/molecules/ProcessPanels';
import checkAppParameterRequired from 'user/utils/checkAppParameterRequired';
import { CachedProcesses, getAllProcesses, Process, ProcessAppParameter } from 'user/api/processes';
import { CachedStreams, Stream } from 'user/api/streams';
import ChannelProcessHeartbeatPart from 'user/dialogs/CreateChannelProcessDialog/ChannelProcessHeartbeatPart';
import StreamSelectionPart from 'user/dialogs/CreateChannelProcessDialog/StreamSelectionPart';
import { AppParameter } from 'shared/models/AppParameter';

export interface ChannelProcessValue {
  channel_process_name: string;
  process_id: string;
  selected_stream_ids: string[][];
  app_parameter: AppParameter;
  heartbeat: Record<string, string>;
}
interface InputChannelProcessFormProps {
  value: ChannelProcessValue;
  processes?: CachedProcesses;
  stream_id?: string; //親Streamのstream_id
  process_id?: string; // プロセスを固定する場合は指定する
  fixed_matched_input_stream?: boolean; // 最初のデータ種別が同一の入力データ(ストリーム)を固定する場合は指定する
  stream_data_number?: number; // ワンショットプロセスの場合のストリームデータ番号
  onChange: (value: ChannelProcessValue, isValid: boolean) => void;
}
interface InputChannelProcessFormState {
  processes: Process[];
  matched_input_stream_index?: number;
  selected_process: Process | undefined;
  streams: Stream[];
  process_app_parameters: ProcessAppParameter[];
}
/**
 * チャンネルプロセスの登録・変更の入力フォームです。
 * ※現在は、ストリームデータからチャンネルプロセスを登録するフローのみ対応
 * 未対応：チャンネル作成時のチャンネルプロセス同時登録、チャンネルプロセス画面での登録、チャンネルプロセス画面での更新
 */
export default class InputChannelProcessForm extends React.PureComponent<
  InputChannelProcessFormProps,
  InputChannelProcessFormState
> {
  constructor(props: InputChannelProcessFormProps) {
    super(props);
    this.state = {
      processes: [],
      selected_process: undefined,
      streams: [],
      process_app_parameters: [],
    };
  }
  componentDidMount() {
    this.loadProcesses();
    this.loadStreams();
  }
  private async loadProcesses() {
    const processes = this.props.processes ? await this.props.processes.get() : await getAllProcesses();
    if (this.props.process_id) {
      const process = processes.find((p) => p.process_id === this.props.process_id);
      this.setState({
        processes: processes,
        selected_process: process,
        process_app_parameters: process?.app_parameters || [],
      });
    } else {
      this.setState({ processes: processes });
    }
  }
  private async loadStreams() {
    this.setState({ streams: await new CachedStreams({ with_output_streams: 'True' }).get() });
  }
  /** プロセス選択を変更したときの、プロセスの初期化を行います。 */
  private handleProcessChange = (process: Process) => {
    // 入力データ(ストリーム)
    let new_selected_stream_ids: string[][] = [];
    // プロセスに応じた入力データ(ストリーム)数とIDとapp_parameterの初期値を設定
    const app_parameter: Record<string, string> = {};
    let app_parameters: ProcessAppParameter[] = [];
    if (process) {
      // input_stream_count = process.input_stream_count;
      app_parameters = process.app_parameters;
      for (let i = 0; i < process.app_parameters.length; i++) {
        const key = process.app_parameters[i].key;
        app_parameter[key] = process.app_parameters[i].default_value;
      }
    }
    this.setState({
      selected_process: process,
      matched_input_stream_index: undefined,
      process_app_parameters: app_parameters,
    });
    const newValue = {
      ...this.props.value,
      process_id: process.process_id,
      app_parameter: app_parameter,
      heartbeat: {},
    };
    // 入力データ(ストリーム)が必要ない場合はselected_stream_idsを空にする
    if (process.input_stream_constraints.length <= 0) {
      new_selected_stream_ids = [];
    } else if (this.props.stream_id) {
      // 入力データ(ストリーム)が必要 && 親ストリームのstream_idが存在する時
      const s = this.state.streams.find((s) => s.stream_id === this.props.stream_id)?.data_type;
      for (let i = 0; i < process.input_stream_constraints.length; i++) {
        const c = process.input_stream_constraints[i];
        if (c.data_type.indexOf(s || '') !== -1) {
          new_selected_stream_ids.push([this.props.stream_id]);
          this.setState({ matched_input_stream_index: i });
          break;
        } else {
          new_selected_stream_ids.push([]);
        }
      }
    }
    // 入力データ(ストリーム)データを更新
    newValue.selected_stream_ids = new_selected_stream_ids;
    this.props.onChange(newValue, this.isValid(newValue, app_parameters));
  };
  private handleSetAppParameter = (value: string, key: string) => {
    const app_parameter = { ...this.props.value.app_parameter };
    app_parameter[key] = value;
    const newValue = { ...this.props.value, app_parameter: app_parameter };
    this.props.onChange(newValue, this.isValid(newValue));
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleChangeAny = (value: Record<string, any>) => {
    const newValue = { ...this.props.value, ...value };
    this.props.onChange(newValue, this.isValid(newValue));
  };
  /** 設定値が全て正常に入力されているか判定します。 */
  private isValid = (value: ChannelProcessValue, process_app_parameters?: ProcessAppParameter[]) => {
    // process_app_parameters?が引数にあればそちらを、なければthis.stateから参照する
    const pap = process_app_parameters || this.state.process_app_parameters;
    // selectedProcessをthis.stateから参照しようとすると更新のタイミングが同関数内(handleProcessChange)なので間に合わない。
    // よって、value.process_idから拾ってくる
    const selectedProcess: Process | undefined = this.state.processes.find((p) => p.process_id === value.process_id);
    // プロセスに必要な入力データ(ストリーム)の数と、入力されているストリームの数を照らし合わせる。プロセスがない場合は強制でfalse
    const validation_stream_ids_length = selectedProcess
      ? value.selected_stream_ids.length === selectedProcess.input_stream_constraints.length
      : false;
    return (
      value.channel_process_name !== '' &&
      value.process_id !== '' &&
      validation_stream_ids_length &&
      checkAppParameterRequired(value.app_parameter, pap)
    );
  };
  render() {
    return (
      <>
        <InputComponent text='チャンネルプロセス名' required>
          <InputBox
            placeholder='入力してください'
            value={this.props.value.channel_process_name || ''}
            onChange={(e) => this.handleChangeAny({ channel_process_name: e.currentTarget.value })}
          />
        </InputComponent>
        {this.props.process_id === undefined && (
          <InputComponent text='プロセス' required>
            <SubTextBasicProcess>基本プロセスを選択してください</SubTextBasicProcess>
            <ProcessPanels
              processes={this.state.processes}
              selected_id={this.state.selected_process?.process_id || ''}
              onClick={this.handleProcessChange}
            />
          </InputComponent>
        )}
        {this.state.selected_process && (
          <StreamSelectionPart
            process={this.state.selected_process}
            streams={this.state.streams}
            selected_stream_ids={this.props.value.selected_stream_ids}
            fixed_input_stream_index={this.state.matched_input_stream_index}
            onChange={(v) => this.handleChangeAny({ selected_stream_ids: v })}
          />
        )}
        {this.state.process_app_parameters.length !== 0 && (
          <InputComponent text='プロセスの詳細条件' required>
            <SubTextDetails>詳細条件を設定できます</SubTextDetails>
            {this.state.process_app_parameters.map((d, index) => {
              return (
                <AppParameterInput
                  key={index}
                  process_app_parameter={d}
                  app_parameter={String(this.props.value.app_parameter[d.key])}
                  streams={this.state.streams.filter(
                    (v) => this.props.value.selected_stream_ids.flat().indexOf(v.stream_id) !== -1,
                  )}
                  stream_data_number={this.props.stream_data_number}
                  onChange={(app_parameter_value) => this.handleSetAppParameter(app_parameter_value, d.key)}
                />
              );
            })}
          </InputComponent>
        )}
        {this.state.selected_process && (
          <ChannelProcessHeartbeatPart
            process={this.state.selected_process}
            value={this.props.value.heartbeat}
            onChange={(v) => this.handleChangeAny({ heartbeat: v })}
          />
        )}
      </>
    );
  }
}
