import React, { useState } from 'react';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { Content } from 'shared/components/molecules/ContentsArea';
import { checkIsOpenResultDialog } from 'user/pages/FileProcesses/DetailPage/FileProcessesOverviews/utils';
import { Stream } from 'user/api/streams';
import { StreamData, streamsIdDataNumberDeleteAPI } from 'user/api/streamsData';
import { ProcessOutputType } from 'shared/models/ProcessOutputType';
import FileCardDisplayingProcess from 'user/pages/FileProcesses/DetailPage/FileProcessesOverviews/FileCardDisplayingProcess';
import CreateFileProcessIdDetailProcessDialog from 'user/pages/FileProcesses/DetailPage/FileProcessesOverviews/CreateFileProcessIdDetailProcessDialog';
import DataThumbnailPlayerDialog from 'user/dialogs/DataThumbnailPlayerDialog';
import DetailFileProcessIdCreateDialog from 'user/pages/FileProcesses/DetailPage/FileProcessesOverviews/DetailFileProcessIdCreateDialog';
import DetailFileProcessIdInfoDialog from 'user/pages/FileProcesses/DetailPage/FileProcessesOverviews/DetailFileProcessIdInfoDialog';
import DetailStreamDataDialog from 'user/dialogs/DetailStreamDataDialog';
import ResultDialog from 'user/pages/FileProcesses/DetailPage/FileProcessesOverviews/ResultDialog';
import { loadWrapperFunc } from 'user/utils/loadWrapperFunc';
import { ProcessInfo, ProcessInfoOutput, streamsProcessinfoByrootIdNumDeleteAPI } from 'user/api/streamsProcessInfo';

interface FileProcessesDataThumbnailPanelProps {
  channel_id: string;
  stream: Stream;
  count?: number;
  loadStreamDataList: () => Promise<void>;
  stream_data_list?: StreamData[];
}
const FileProcessesDataThumbnailPanel: React.FC<FileProcessesDataThumbnailPanelProps> = (params) => {
  const [player, setPlayer] = useState<{ stream_id: string; stream_data_number: number } | undefined>(undefined);
  const [result_dialog_props, setResultDialogProps] = useState<
    | {
        original_file_name?: string;
        process_infos?: ProcessInfo[];
        output: ProcessInfoOutput;
        process_info: ProcessInfo;
      }
    | undefined
  >(undefined);
  const [channel_process_dialog_props, setChannelProcessDialogProps] = useState<
    { stream: Stream; stream_data: StreamData } | undefined
  >(undefined);
  const [channel_process_flow_info_dialog_props, setChannelProcessInfoDialogProps] = useState<
    { stream: Stream; stream_data: StreamData; channel_id: string; channel_process_number: string } | undefined
  >(undefined);
  const [channel_process_flow_create_dialog_props, setChannelProcessCreateDialogProps] = useState<
    { stream: Stream; stream_data: StreamData; channel_id: string; channel_process_number: string } | undefined
  >(undefined);

  const [edit_stream_data_props, setEditStreamDataProps] = useState<
    { stream: Stream; stream_data: StreamData } | undefined
  >(undefined);

  const handlePlayClick = (stream_id: string, stream_data_number: number) => {
    setPlayer({
      stream_id: stream_id,
      stream_data_number: stream_data_number,
    });
  };
  const handlePlayerCloseClick = () => {
    setPlayer(undefined);
  };
  const handleEditStreamDataDialogOpen = (sd: StreamData) => {
    setEditStreamDataProps({
      stream: params.stream,
      stream_data: sd,
    });
  };
  const handleEditStreamDataDialogClose = async (canceled?: boolean) => {
    setEditStreamDataProps(undefined);
    if (!canceled) {
      await params.loadStreamDataList();
    }
  };
  const handleApplyChannelProcessMenuClick = (sd: StreamData) => {
    setChannelProcessDialogProps({
      stream: params.stream,
      stream_data: sd,
    });
  };

  const handleChannelProcessDialogClose = async (p?: { isCanceled?: boolean }) => {
    setChannelProcessDialogProps(undefined);
    if (p?.isCanceled) return;
    await params.loadStreamDataList();
  };
  const handleStreamDataDeleteMenuClick = (sd: StreamData, processinfos?: ProcessInfo[]) => {
    ConfirmDialog.show('プロセス情報も含めて削除します。\nこのストリームデータを削除してもよろしいですか？', () =>
      deleteStreamDataAndAllChannelProcess(sd, processinfos),
    );
  };

  // Information の関数を追加(API呼び出し)
  const handleProcessInfoClick = async (p: {
    sd: StreamData;
    channel_id: string;
    channel_process_number: string | number;
  }) => {
    // チャンネルプロセスを取得
    setChannelProcessInfoDialogProps({
      stream: params.stream,
      stream_data: p.sd,
      channel_id: p.channel_id,
      channel_process_number:
        typeof p.channel_process_number === 'number' ? String(p.channel_process_number) : p.channel_process_number,
    });
  };
  const handleChannelProcessInfoDialogClose = async () => {
    setChannelProcessInfoDialogProps(undefined);
    setChannelProcessCreateDialogProps(undefined);
  };

  // 同じ条件で新規作成ボタンをクリックしたとき
  const onCopyCreate = async () => {
    setChannelProcessCreateDialogProps(channel_process_flow_info_dialog_props);
    setChannelProcessInfoDialogProps(undefined);
  };

  // 指定のチャンネルプロセスを削除する。
  // プロセスフローの場合は1フローを削除する
  const handleProcessDeleteClick = (p: {
    sd: StreamData;
    channel_id: string;
    channel_process_number: string | number;
  }) => {
    ConfirmDialog.show('チャンネルプロセス(プロセスフローの場合は1フロー)を削除します。\nよろしいですか?', () =>
      deleteStreamChannelProcess(p),
    );
  };
  const handleResultDialogOpen = (p: {
    stream_id?: string;
    stream_package_number?: number;
    stream_data_number?: number;
    original_file_name?: string;
    output_type: ProcessOutputType;
    process_infos?: ProcessInfo[];
    output: ProcessInfoOutput;
    process_info: ProcessInfo;
  }) => {
    setResultDialogProps(p);
  };
  const handleResultDialogClose = () => {
    setResultDialogProps(undefined);
  };
  const deleteStreamDataAndAllChannelProcess = async (sd: StreamData, processinfos?: ProcessInfo[]) => {
    // 削除関数定義
    const deletes = async () => {
      // ストリームデータに紐づくプロセスを全て削除
      if (Array.isArray(processinfos)) {
        for (let i = 0; i < processinfos.length; i++) {
          const pi = processinfos[i];
          await streamsProcessinfoByrootIdNumDeleteAPI({
            channel_id: pi.channel_id,
            channel_process_number: String(pi.channel_process_number),
          });
        }
      }

      // ストリームデータを削除
      const stream_data_res = await streamsIdDataNumberDeleteAPI({
        stream_id: sd.stream_id,
        stream_data_number: sd.stream_data_number,
        api_send_type: 'multipleTransmission',
      });
      if (stream_data_res.status === 200) {
        AlertDialog.show('ストリームデータを削除しました。', async () => await params.loadStreamDataList());
      }
    };
    // 実行
    await loadWrapperFunc(deletes);
  };

  const deleteStreamChannelProcess = async (p: {
    sd: StreamData;
    channel_id: string;
    channel_process_number: string | number;
  }) => {
    // チャンネルプロセスを削除
    const processinfos_res = await streamsProcessinfoByrootIdNumDeleteAPI({
      channel_id: p.channel_id,
      channel_process_number:
        typeof p.channel_process_number === 'number' ? String(p.channel_process_number) : p.channel_process_number,
      api_send_type: 'changeableOneTransmission',
    });
    if (processinfos_res.status === 200) {
      AlertDialog.show('チャンネルプロセスを削除しました。', async () => await params.loadStreamDataList());
    }
  };

  // -- render part --
  const enable_on_click = params.stream.data_type === 'VIDEO';
  return (
    <Content style={{ display: 'flex', flexWrap: 'wrap' }}>
      {params.stream_data_list ? (
        params.stream_data_list.map((sd, i) => {
          return (
            <FileCardDisplayingProcess
              stream={params.stream}
              stream_data={sd}
              handlePlayClick={enable_on_click ? () => handlePlayClick(sd.stream_id, sd.stream_data_number) : undefined}
              handleApplyChannelProcessMenuClick={() => handleApplyChannelProcessMenuClick(sd)}
              handleStreamDataDeleteMenuClick={(process_infos) => handleStreamDataDeleteMenuClick(sd, process_infos)}
              handleProcessInfoClick={(p) => handleProcessInfoClick({ sd, ...p })}
              handleProcessDeleteClick={(p) => handleProcessDeleteClick({ sd, ...p })}
              handleResultDialogOpen={handleResultDialogOpen}
              handleEditStreamDataDialogOpen={() => handleEditStreamDataDialogOpen(sd)}
              key={`${sd.stream_id}_${sd.stream_data_number}_${i}`}
            />
          );
        })
      ) : (
        <Spinner />
      )}
      {player && (
        <DataThumbnailPlayerDialog
          isOpen={true}
          onCloseClick={handlePlayerCloseClick}
          stream_id={player.stream_id}
          stream_data_number={player.stream_data_number}
        />
      )}
      {edit_stream_data_props && (
        <DetailStreamDataDialog
          stream={edit_stream_data_props.stream}
          streamData={edit_stream_data_props.stream_data}
          isOpen={edit_stream_data_props !== undefined}
          onClose={handleEditStreamDataDialogClose}
        />
      )}
      {channel_process_dialog_props && (
        <CreateFileProcessIdDetailProcessDialog
          {...params}
          stream={channel_process_dialog_props.stream}
          stream_data_number={channel_process_dialog_props.stream_data.stream_data_number}
          isOpen={channel_process_dialog_props !== undefined}
          onClose={handleChannelProcessDialogClose}
        />
      )}
      {result_dialog_props &&
        typeof result_dialog_props.output.stream_id === 'string' &&
        checkIsOpenResultDialog({ ...result_dialog_props.output }) && (
          <ResultDialog
            isOpen={true}
            onClose={handleResultDialogClose}
            original_file_name={result_dialog_props.original_file_name}
            process_infos={result_dialog_props.process_infos}
            output={result_dialog_props.output}
            process_info={result_dialog_props.process_info}
          />
        )}
      {channel_process_flow_info_dialog_props && (
        <DetailFileProcessIdInfoDialog
          {...params}
          stream={channel_process_flow_info_dialog_props.stream}
          stream_data_number={channel_process_flow_info_dialog_props.stream_data.stream_data_number}
          channel_id={channel_process_flow_info_dialog_props.channel_id}
          channel_process_number={channel_process_flow_info_dialog_props.channel_process_number}
          isOpen={channel_process_flow_info_dialog_props !== undefined}
          onClose={handleChannelProcessInfoDialogClose}
          onCopyCreate={onCopyCreate}
        />
      )}
      {channel_process_flow_create_dialog_props && (
        <DetailFileProcessIdCreateDialog
          {...params}
          stream={channel_process_flow_create_dialog_props.stream}
          stream_data_number={channel_process_flow_create_dialog_props.stream_data.stream_data_number}
          channel_id={channel_process_flow_create_dialog_props.channel_id}
          channel_process_number={channel_process_flow_create_dialog_props.channel_process_number}
          isOpen={channel_process_flow_create_dialog_props !== undefined}
          onClose={handleChannelProcessInfoDialogClose}
        />
      )}
    </Content>
  );
};

export default FileProcessesDataThumbnailPanel;
