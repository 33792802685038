import React, { useState } from 'react';
import ContentBodyMat from 'shared/components/atoms/ContentBodyMat';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import { Content, Title, TopArea } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import styles from 'shared/styles/styles';
import { toFormatBytes } from 'shared/utils/converter';
import styled from 'styled-components';
import { Channel } from 'user/api/channels';
import { Stream } from 'user/api/streams';
import { StreamData } from 'user/api/streamsData';
import CreateFileProcessIdDetailProcessDialog from 'user/pages/FileProcesses/DetailPage/FileProcessesOverviews/CreateFileProcessIdDetailProcessDialog';
import CreateFileProcessesIdDetailFileDialog from 'user/pages/FileProcesses/DetailPage/FileProcessesOverviews/CreateFileProcessesIdDetailFileDialog';
import FileProcessesIDDetailSetDialog from 'user/pages/FileProcesses/DetailPage/FileProcessesOverviews/DetailFileProcessesDialog';
import FileProcessesDataThumbnailPanel from 'user/pages/FileProcesses/DetailPage/FileProcessesOverviews/FileProcessesDataThumbnailPanel';

interface FileProcessesOverviewsProps {
  channel: Channel;
  stream: Stream;
  onStreamChanged: () => void;
  count?: number;
  stream_data_list?: StreamData[];
  loadStreamDataList: () => Promise<void>;
  loadChannel: () => Promise<void>;
  loadStream: () => Promise<void>;
}
/**
 * フォルダチャンネルのファイル一覧で使用する詳細画面
 */
const FileProcessesOverviews: React.FC<FileProcessesOverviewsProps> = (params) => {
  return (
    <Content style={{ minWidth: `calc(240px + 750px + ${styles.interval_margin} + 30px * 2)` }}>
      <ContentBodyMat flexible={true}>
        <LeftComponent {...params} />
        <RightComponent {...params} />
      </ContentBodyMat>
    </Content>
  );
};

// 左側のコンポーネント
const LeftComponent = ({ stream, channel, onStreamChanged }: FileProcessesOverviewsProps) => {
  const [is_file_processes_id_detail_set_dialog_open, setIsFileProcessesIdDetailSetDialogOpen] =
    useState<boolean>(false);
  // 子コンポーネントに変更を伝える変数
  const handleSetDialogOpenClick = () => {
    setIsFileProcessesIdDetailSetDialogOpen(true);
  };
  const handleStreamDetailSetDialogOkClose = () => {
    setIsFileProcessesIdDetailSetDialogOpen(false);
    onStreamChanged();
  };
  const handleStreamDetailSetDialogCancelClose = () => {
    setIsFileProcessesIdDetailSetDialogOpen(false);
  };
  return (
    <RoundedCardLeftArea>
      <TextArea>
        <InputComponent text='データ容量'>
          <div>{toFormatBytes(stream.total_data_size, 2)}</div>
        </InputComponent>
      </TextArea>
      <RoundedButton text='詳細' onClick={handleSetDialogOpenClick} style={{ width: styles.small_button_width }} />
      {is_file_processes_id_detail_set_dialog_open && (
        <FileProcessesIDDetailSetDialog
          isOpen={is_file_processes_id_detail_set_dialog_open}
          channel={channel}
          onCancelClose={handleStreamDetailSetDialogCancelClose}
          onOkClose={handleStreamDetailSetDialogOkClose}
        />
      )}
    </RoundedCardLeftArea>
  );
};

// 右側のコンポーネント
const RightComponent = (params: FileProcessesOverviewsProps) => {
  const [is_file_create_dialog_open, setIsFileCreateDialogOpen] = useState<boolean>(false);
  const [is_process_set_dialog_open, setIsProcessSetDialogOpen] = useState<boolean>(false);
  const [selected_stream_data_number, setSelectedStreamDataNumber] = useState<string | number | undefined>(undefined);

  // -- ファイル作成ダイアログ用の関数
  const handleCreateFileProcessesIdDetailDialogClose = (p: {
    isCanceled?: boolean;
    isCreated?: boolean;
    isSettingProcess?: boolean;
    stream_data_number?: string | number;
  }) => {
    setIsFileCreateDialogOpen(false);
    if (p.isCreated) {
      params.loadStreamDataList();
    }
    if (p.isCanceled) return;
    if (p.isSettingProcess) {
      setIsProcessSetDialogOpen(true);
    }
    if (p.stream_data_number !== undefined) {
      setSelectedStreamDataNumber(p.stream_data_number);
    }
  };
  // -- プロセス作成用の関数
  const handleCreateFileProcessesIdDetailProcessDialogClose = (p?: { isCanceled?: boolean }) => {
    setIsProcessSetDialogOpen(false);
    if (p?.isCanceled) return;
    params.loadChannel();
    params.loadStream();
  };
  return (
    <RoundedCardRightArea>
      <TopArea>
        <Title text={''} />
        <RoundedButton onClick={() => setIsFileCreateDialogOpen(true)} text='ファイル作成' />
      </TopArea>
      <FileProcessesDataThumbnailPanel
        channel_id={params.channel.channel_id}
        stream={params.stream}
        count={params.count}
        stream_data_list={params.stream_data_list}
        loadStreamDataList={params.loadStreamDataList}
      />
      {is_file_create_dialog_open && (
        <CreateFileProcessesIdDetailFileDialog
          {...params}
          stream_id={params.stream.stream_id}
          isOpen={is_file_create_dialog_open}
          onClose={handleCreateFileProcessesIdDetailDialogClose}
        />
      )}

      {is_process_set_dialog_open && selected_stream_data_number !== undefined && (
        <CreateFileProcessIdDetailProcessDialog
          stream={params.stream}
          stream_data_number={selected_stream_data_number}
          channel_id={params.channel.channel_id}
          isOpen={is_process_set_dialog_open}
          onClose={handleCreateFileProcessesIdDetailProcessDialogClose}
        />
      )}
    </RoundedCardRightArea>
  );
};

// -- styled components --
const RoundedCardLeftArea = styled.div`
  width: 240px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: ${styles.interval_margin};
`;

const RoundedCardRightArea = styled.div`
  width: calc(100% - 240px - ${styles.interval_margin});
  height: auto;
  display: flex;
  flex-direction: column;
`;

const TextArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export default FileProcessesOverviews;
