import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import InputBox from 'shared/components/atoms/InputBox';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Content, Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import { Stream } from 'user/api/streams';
import { RequestStreamsIdDataNumberPut, StreamData } from 'user/api/streamsData';
import { streamsIdDataNumberPutAPI } from 'user/api/streamsData';
import { systemSelector } from 'user/redux/slices/systemSlice';

// -- types --

type Params = {
  stream: Stream;
  streamData: StreamData;
  isOpen?: boolean;
  onClose: (canceled?: boolean) => void;
};

// -- main component --
const DetailStreamDataDialog: React.FC<Params> = (params) => {
  // -- redux preparations --
  const system_state = useSelector(systemSelector);

  // -- local states --
  const [stream_data_name, setStreamDataName] = useState<string>(params.streamData.stream_data_name);

  const handleFinishClick = async () => {
    const request_bodies: RequestStreamsIdDataNumberPut = {
      stream_id: params.streamData.stream_id,
      stream_data_number: params.streamData.stream_data_number,
      stream_data_name: stream_data_name,
    };

    const res = await streamsIdDataNumberPutAPI(request_bodies);

    // resの正常を持って完了と判定する
    if (res.status === 200) {
      params.onClose();
      AlertDialog.show('ストリームデータの更新に成功しました');
    }
  };

  // -- render part --
  return (
    <PfDialog
      title={`ストリームデータ詳細`}
      isOpen={params.isOpen === undefined ? true : params.isOpen}
      onClose={() => params.onClose(true)}
    >
      <Content>
        {system_state.loading.preloading ? (
          <Spinner />
        ) : (
          <>
            <InputComponent text='ストリームデータ番号' required>
              {params.streamData.stream_data_number}
            </InputComponent>
            <InputComponent text='ストリームデータ名'>
              <InputBox
                placeholder='入力してください'
                value={stream_data_name}
                onChange={(e) => setStreamDataName(e.currentTarget.value)}
              />
            </InputComponent>
            <Footer>
              <RoundedButton onClick={() => params.onClose(true)} text='キャンセル' is_margin_right is_white />
              <RoundedButton onClick={handleFinishClick} text_type='UPDATE' />
            </Footer>
          </>
        )}
      </Content>
    </PfDialog>
  );
};

// -- styled components --

// -- finally export part --

export default DetailStreamDataDialog;
