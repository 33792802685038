import { Metric, SUAreaCountSetting, SumUpGraphSettings } from 'user/api/dashboardsWidgets';
import { JsonpathGroup } from 'user/api/jsonpathGroups';
import { Stream } from 'user/api/streams';
import SelectBox from 'shared/components/atoms/SelectBox';
import InputComponent from 'shared/components/molecules/InputComponent';
import { InputJsonpathGroup } from 'user/dialogs/CsvRowsInputDialog';
import React from 'react';
import { graph_types, MetricGraphType } from 'shared/models/MetricGraphType';
// import { MetricStatisticMethod } from 'shared/models/MetricStatisticMethod';
import RadioBox from 'shared/components/molecules/RadioBox';
import { AccordionContainer } from 'shared/components/molecules/AccordionContainer';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import InputBox from 'shared/components/atoms/InputBox';
import SumUpGraphCountSettingTable from './SumUpGraphCountSettingTable';
import SumUpGraphAreaCountSettingTable from './SumUpGraphAreaCountSettingTable';
import { FooterArea } from './StyledComponent';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import styles from 'shared/styles/styles';

export interface SumUpGraphSettingPanelValues {
  graph_type?: MetricGraphType;
}
interface SumUpGraphSettingPanelProps {
  sum_up_settings: SumUpGraphSettings;
  metricStreams: Stream[];
  metrics: Metric[];
  create_graph: boolean;
  onCreate: (p: boolean) => void;
  onSettingChange: (p: SumUpGraphSettingPanelValues) => void;
  onSumUpGraphSettingsChange: (p: SumUpGraphSettings) => void;
  onMetricChange: (p: Metric[]) => void;
  values: SumUpGraphSettingPanelValues;
}
interface SumUpGraphSettingPanelState {}
export default class SumUpGraphSettingPanel extends React.PureComponent<
  SumUpGraphSettingPanelProps,
  SumUpGraphSettingPanelState
> {
  constructor(props: SumUpGraphSettingPanelProps) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.loadMetricList();
    this.props.onMetricChange([]);
    this.InputCheck();
  }
  private InputCheck = () => {
    // グラフタイプは必須
    if (this.props.values.graph_type) {
      // sum_up_settings.json_settingsの各行をチェック
      this.props.sum_up_settings?.json_settings.map((json_setting) => {
        // stream_idは必須
        if (!json_setting.stream_id) return false;
        // LINE検知 の場合、jsonpath_group_idは必須
        if (json_setting.json_method == 'Count') {
          if (json_setting.jsonpath_group_id) return false;
        }
      });
    } else {
      return false;
    }
    return true;
  };
  // 各json_settingsのmetrics_lengthを元にmetricからmetric_listに分解
  loadMetricList = () => {
    const { metrics, sum_up_settings } = this.props;
    const metric_list: { [key: number]: Metric[] } = {};
    let i = 0;
    sum_up_settings.json_settings.forEach((json_setting, index) => {
      // metrics_length に基づいて metrics から Metric オブジェクトを選択
      const length = json_setting.metrics_length;
      metric_list[index] = metrics.slice(i, length);
      // 次の範囲の開始インデックスを更新
      i = length;
    });

    // metric_list をセット
    this.metric_list = metric_list;
  };
  metric_list: { [key: number]: Metric[] } = {};
  // metrics_lengthを更新する関数
  private updateMetricLength = () => {
    let metrics_length = 0;
    const new_sum_up_settings = this.props.sum_up_settings;
    for (let i = 0; i < 10; i++) {
      if (!this.metric_list[i]) break;
      metrics_length = metrics_length + this.metric_list[i].length;
      new_sum_up_settings.json_settings[i].metrics_length = metrics_length;
    }
    this.props.onSumUpGraphSettingsChange(new_sum_up_settings);
  };
  private handleChange = (value: string, index: number, key: string) => {
    const new_metrics: Metric[] = [];
    const sum_up_settings = this.props.sum_up_settings;
    if (key === 'name') {
      sum_up_settings.name[index] = value;
    }
    if (key === 'json_method') {
      sum_up_settings.json_settings[index].json_method = value;
      this.metric_list[index] = [];
      for (let i = 0; i < 10; i++) {
        if (this.metric_list[i]) {
          const new_metric_list: Metric[] = [];
          this.metric_list[i].map((metric) => {
            new_metrics.push(metric);
            new_metric_list.push(metric);
          });
          this.metric_list[i] = new_metric_list;
          // 更新されたmetric_listのlengthをjson_settingsのmetrics_lengthに格納
          sum_up_settings.json_settings[i].metrics_length = new_metrics.length - 1;
        }
      }
    }
    // 更新されたmetric_listのlengthをjson_settingsのmetrics_lengthに格納
    this.updateMetricLength();
    this.props.onMetricChange(new_metrics);
    this.props.onSumUpGraphSettingsChange(sum_up_settings);
  };
  private handleAreaCountSettingChange = (area_count_settings: SUAreaCountSetting[], su_index: number) => {
    const new_sum_up_settings = this.props.sum_up_settings;
    new_sum_up_settings.json_settings[su_index].area_count_settings = area_count_settings;
    this.props.onSumUpGraphSettingsChange(new_sum_up_settings);
    this.updateMetricLength();
    this.InputCheck();
  };
  private handleAddJsonSettingsRow = () => {
    const json_settings = [...this.props.sum_up_settings.json_settings];
    if (json_settings.length >= 10) {
      AlertDialog.show('合算して表示できるのは10個のデータまでです。');
      return;
    }
    json_settings.push({
      json_method: 'Count',
      jsonpath_group_id: '',
      area_count_settings: [],
      stream_id: '',
      metrics_length: this.props.metrics.length,
    });
    const new_sum_up_settings = { ...this.props.sum_up_settings, json_settings: json_settings };
    this.props.onSumUpGraphSettingsChange(new_sum_up_settings);
    this.InputCheck();
  };
  private handleDeleteJsonSettingsRow = () => {
    const json_settings = [...this.props.sum_up_settings.json_settings];
    if (json_settings.length <= 1) {
      AlertDialog.show('少なくとも1つ以上のデータが必要です');
      return;
    }
    const new_metrics = this.props.metrics.slice();
    const metric_index = this.props.sum_up_settings.json_settings.length - 1;
    json_settings.pop();
    const new_sum_up_settings = { ...this.props.sum_up_settings, json_settings: json_settings };
    // 対象のmetric_listを初期化してmetricを更新
    this.metric_list[metric_index] = [];
    for (let i = 0; i < 10; i++) {
      if (this.metric_list[i]) {
        this.metric_list[i].map((metric) => {
          new_metrics.push(metric);
          this.metric_list[i].push(metric);
        });
        // 更新されたmetric_listのlengthをjson_settingsのmetrics_lengthに格納
        new_sum_up_settings.json_settings[i].metrics_length = new_metrics.length;
      }
    }
    this.props.onSumUpGraphSettingsChange(new_sum_up_settings);
    this.props.onMetricChange(new_metrics);
    this.InputCheck();
  };
  private handleAddNameRow = () => {
    const name = [...this.props.sum_up_settings.name];
    if (name.length >= 10) {
      AlertDialog.show('合算して表示できるのは10個のデータまでです。');
      return;
    }
    name.push('');
    const new_sum_up_settings = { ...this.props.sum_up_settings, name: name };
    this.props.onSumUpGraphSettingsChange(new_sum_up_settings);
  };
  private handleDeleteNameRow = () => {
    const name = [...this.props.sum_up_settings.name];
    if (name.length <= 1) {
      AlertDialog.show('少なくとも1つ以上の表示名が必要です');
      return;
    }
    name.pop();
    const new_sum_up_settings = { ...this.props.sum_up_settings, name: name };
    this.props.onSumUpGraphSettingsChange(new_sum_up_settings);
  };
  private handleJsonpathGroupIdChange = (index: number, jsonpath_group?: JsonpathGroup) => {
    const sum_up_settings = this.props.sum_up_settings;
    sum_up_settings.json_settings[index].jsonpath_group_id = jsonpath_group?.jsonpath_group_id ?? '';
    const new_metrics: Metric[] = [];
    if (jsonpath_group) {
      const csv_rows = jsonpath_group['csv_rows'];
      for (let i = 0; i < csv_rows.length; i++) {
        const csv_row = csv_rows[i];
        const new_metric: Metric = {
          name: index + '-' + i,
          graph_type: this.props.values.graph_type,
          stream_id: this.props.sum_up_settings.json_settings[index].stream_id as string,
          json_path: csv_row.json_path,
          statistic_method: 'Sum',
          accumulation: 'NONE',
          stack_id: csv_row.stack_id,
          sum_up_id: '1',
        };
        new_metrics.push(new_metric);
      }
    }
    this.handleMetricChange(new_metrics, index);
    this.props.onSumUpGraphSettingsChange(sum_up_settings);
  };
  private handleStreamIdChange = async (e: React.ChangeEvent<HTMLSelectElement>, index: number) => {
    const new_metrics = this.props.metrics.slice();
    const sum_up_settings = this.props.sum_up_settings;
    sum_up_settings.json_settings[index].stream_id = e.currentTarget.value;
    this.props.onMetricChange(new_metrics);
    this.props.onSumUpGraphSettingsChange(sum_up_settings);
  };
  private handleGraphTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const v = this.props.values;
    v.graph_type = e.currentTarget.value as MetricGraphType;
    this.props.onSettingChange(v);
  };
  private handleMetricChange = (metrics: Metric[], index: number) => {
    this.metric_list[index] = metrics;
    const new_metrics: Metric[] = [];
    for (let i = 0; i < 10; i++) {
      if (this.metric_list[i]) {
        this.metric_list[i].map((metric) => {
          new_metrics.push(metric);
        });
      }
    }
    this.props.onMetricChange(new_metrics);
    this.updateMetricLength();
  };
  private getCategory = (stream_id: string | undefined) => {
    let category = '';
    if (this.props.metricStreams && stream_id) {
      for (const stream of this.props.metricStreams) {
        if (stream.stream_id == stream_id) {
          category = stream.category;
        }
      }
    }
    return category;
  };
  render() {
    return (
      <>
        <InputComponent text='グラフタイプ' required>
          <SelectBox
            value={this.props.values.graph_type || ''}
            datas={graph_types.map((d) => {
              return {
                value: d,
                name: d,
              };
            })}
            onChange={(e) => this.handleGraphTypeChange(e)}
            long
          />
        </InputComponent>
        <AccordionContainer title={`表示名`}>
          {this.props.sum_up_settings.name.map((name, index) => (
            <InputComponent text={`表示名${index + 1}`} required key={index}>
              <InputBox value={name} onChange={(e) => this.handleChange(e.currentTarget.value, index, 'name')} />
            </InputComponent>
          ))}
          <FooterArea>
            <RoundedButton
              text='表示名削除'
              onClick={this.handleDeleteNameRow}
              style={{
                width: styles.super_small_button_width,
                height: styles.super_small_button_height,
                marginRight: styles.interval_narrow_margin,
              }}
              is_white
            />
            <RoundedButton
              text='表示名追加'
              onClick={this.handleAddNameRow}
              style={{
                width: styles.super_small_button_width,
                height: styles.super_small_button_height,
              }}
            />
          </FooterArea>
        </AccordionContainer>
        {this.props.sum_up_settings.json_settings.map((json_setting, index) => (
          <AccordionContainer title={`合算対象データ${index + 1}`} key={index}>
            <InputComponent text='対象データ検知方法' required>
              <RadioBox
                datas={[
                  { name: 'LINE検知', value: 'Count' },
                  { name: '滞留検知', value: 'AreaCount' },
                ]}
                selectedValue={json_setting.json_method}
                handleChangeClick={(e) => this.handleChange(e, index, 'json_method')}
              />
            </InputComponent>
            <InputComponent text='データ' required>
              <SelectBox
                onChange={(e) => this.handleStreamIdChange(e, index)}
                value={json_setting.stream_id}
                datas={this.props.metricStreams.map((stream) => {
                  return { name: stream.stream_name, value: stream.stream_id };
                })}
                long
              />
            </InputComponent>
            {json_setting.stream_id ? (
              <>
                {json_setting.json_method == 'Count' ? (
                  <>
                    <InputComponent text='JSONパスグループ' required>
                      <InputJsonpathGroup
                        value={json_setting.jsonpath_group_id || ''}
                        onChange={(e) => this.handleJsonpathGroupIdChange(index, e)}
                        display={'DASHBOARD'}
                        category={this.getCategory(json_setting.stream_id)}
                      />
                    </InputComponent>
                    {json_setting.jsonpath_group_id && this.props.values.graph_type ? (
                      <SumUpGraphCountSettingTable
                        index={index}
                        metrics={this.metric_list[index]}
                        streams={this.props.metricStreams}
                        stream_id={json_setting.stream_id}
                        graph_type={this.props.values.graph_type}
                        name={this.props.sum_up_settings.name}
                        onChange={(e) => this.handleMetricChange(e, index)}
                      />
                    ) : null}
                  </>
                ) : (
                  <>
                    {this.props.values.graph_type ? (
                      <SumUpGraphAreaCountSettingTable
                        index={index}
                        values={{ stream_id: json_setting.stream_id, graph_type: this.props.values.graph_type }}
                        metrics={this.metric_list[index]}
                        area_count_settings={json_setting.area_count_settings || []}
                        metricStreams={this.props.metricStreams}
                        name={this.props.sum_up_settings.name}
                        onMetricChange={(e) => this.handleMetricChange(e, index)}
                        onAreaCountSettingsChange={(e) => this.handleAreaCountSettingChange(e, index)}
                      />
                    ) : null}
                  </>
                )}
              </>
            ) : null}
          </AccordionContainer>
        ))}
        <FooterArea>
          <RoundedButton
            text='データ削除'
            onClick={this.handleDeleteJsonSettingsRow}
            style={{
              width: styles.super_small_button_width,
              height: styles.super_small_button_height,
              marginRight: styles.interval_narrow_margin,
            }}
            is_white
          />
          <RoundedButton
            text='データ追加'
            onClick={this.handleAddJsonSettingsRow}
            style={{
              width: styles.super_small_button_width,
              height: styles.super_small_button_height,
            }}
          />
        </FooterArea>
      </>
    );
  }
}
