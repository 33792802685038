import React, { useEffect, useState } from 'react';
import history from 'shared/browserHistory';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { BottomArea, Title, TopArea, PageWholeArea } from 'shared/components/molecules/ContentsArea';
import PageSwitchingTabs from 'shared/components/molecules/PageSwitchingTabs';
import { getMessageField } from 'shared/utils/get';
import { preloadGroup } from 'user/utils/preloadGroup';
import {
  EdgeDevice,
  EdgeDeviceHeartbeatAt,
  edgeDevicesGetAPI,
  edgeDevicesHeartbeatAtGetAPI,
  edgeDevicesKeyDeleteAPI,
} from 'user/api/edgeDevices';
import EdgeDevicesTable from './EdgeDevicesTable';

/**
 * Counterboxデバイス一覧ページ
 */
const EdgeDevices: React.FC = () => {
  // -- local states --
  const [edge_devices, setEdgeDevices] = useState<EdgeDevice[] | undefined>(undefined);

  const getDatas = async () => {
    // [TODO] APIを必要に応じた回数だけ叩く
    // 暫定仕様で、可能な限りデータを取得している
    let items: EdgeDevice[] = [];
    let has_next = true;
    let exclusive_start_key = '';
    while (has_next) {
      const res = await edgeDevicesGetAPI({
        exclusive_start_edge_device_key: exclusive_start_key,
      });
      has_next = false;
      if (res.status === 200) {
        items = items.concat(res.data.items);
        has_next = res.data.has_next;
        exclusive_start_key = res.data.last_edge_device_key || '';
      }
    }
    setEdgeDevices(items);
  };

  const getHeartbeatData = async () => {
    let heartbeats: EdgeDeviceHeartbeatAt[] = [];
    let has_next = true;
    let exclusive_start_key = '';
    while (has_next) {
      const res = await edgeDevicesHeartbeatAtGetAPI({
        exclusive_start_edge_device_key: exclusive_start_key,
      });
      has_next = false;
      if (res.status === 200) {
        heartbeats = heartbeats.concat(res.data.items);
        has_next = res.data.has_next;
        exclusive_start_key = res.data.last_edge_device_key || '';
      }
    }

    setEdgeDevices((edge_devices) =>
      edge_devices?.map((edged_evice) => {
        const heartbeatData = heartbeats.find((h) => h.edge_device_key === edged_evice.edge_device_key);
        return heartbeatData ? { ...edged_evice, heartbeat_at: heartbeatData.heartbeat_at } : { ...edged_evice };
      }),
    );
  };

  const handleEdgeDeviceDelete = (edge_device: EdgeDevice) => {
    ConfirmDialog.show(
      `デバイス ${
        edge_device.heartbeat_edge_device_name || ''
      } を削除して良いですか？\n\nデバイスを削除した場合でも、アプリからのデバイストークンの更新が行われると再表示されます。`,
      () => deleteEdgeDevice(edge_device),
    );
  };

  /** Counterboxデバイスを削除するAPIをコールします。 */
  const deleteEdgeDevice = (edge_device: EdgeDevice) => {
    edgeDevicesKeyDeleteAPI({ edge_device_key: edge_device.edge_device_key }).then((res) => {
      if (res.status === 200) {
        AlertDialog.show('削除に成功しました。');
      } else {
        AlertDialog.show('削除に失敗しました。\n\n(' + getMessageField(res.data) + ')');
      }
      // データを再取得
      getDatas();
    });
  };
  // -- functions --
  const handleTabsClick = (id: string) => {
    switch (id) {
      case 'soracom':
        history.push(`/devices`);
        break;
      case 'counterBox':
        history.push(`/edge/devices`);
        break;
      case 'android':
        history.push(`/android/devices`);
        break;
    }
  };

  // -- onload function --
  useEffect(() => {
    preloadGroup(getDatas());
    const interval = setInterval(() => {
      getHeartbeatData();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  // -- render part --
  return (
    <PageWholeArea data-testid='EdgeDevices'>
      <TopArea>
        <Title text='Counterboxデバイス' />
      </TopArea>
      <PageSwitchingTabs
        display_datas={[
          { id: 'soracom', text: 'SORACOMデバイス' },
          { id: 'counterBox', text: 'Counterboxデバイス' },
          { id: 'android', text: 'Androidデバイス' },
        ]}
        handleClick={(id: string) => handleTabsClick(id)}
        selected_id='counterBox'
        initial_selected_id='counterBox'
      />
      {edge_devices === undefined ? (
        <Spinner />
      ) : (
        <BottomArea>
          <EdgeDevicesTable edge_devices={edge_devices} onDelete={handleEdgeDeviceDelete} />
        </BottomArea>
      )}
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default EdgeDevices;
