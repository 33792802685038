import React from 'react';
import { CheckBoxArea } from 'shared/components/atoms/PfTable';
import RoundedCard from 'shared/components/atoms/RoundedCard';
import CheckBox from 'shared/components/molecules/CheckBox';
import { Stream } from 'user/api/streams';
import { StreamData } from 'user/api/streamsData';
import StreamThumbnailImage from './StreamThumbnailImage';

/**
 * フォルダチャンネル/ファイル一覧で表示するストリームデータを表示するカード(複数ファイルの選択時)
 */
interface FileCardDisplayingProcessProps {
  stream: Stream;
  stream_data: StreamData;
  card_checkbox: boolean;
  handleCardClick: (stream_data_number: number | string) => void;
}
interface FileCardDisplayingProcessState {
  is_open: boolean;
  is_process_result_open: boolean;
  message: string | JSX.Element | string[];
  callback?: () => void;
}
export default class FileCardDisplayingProcess extends React.PureComponent<
  FileCardDisplayingProcessProps,
  FileCardDisplayingProcessState
> {
  constructor(props: FileCardDisplayingProcessProps) {
    super(props);
    this.state = {
      is_open: false,
      is_process_result_open: false,
      message: '',
    };
  }
  onClose() {
    this.setState({ is_open: false, is_process_result_open: false });
    if (this.state.callback) {
      this.state.callback();
    }
  }
  render() {
    const card_style: React.CSSProperties = {
      width: 320,
      height: 270,
      padding: 10,
      margin: 15,
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
    };
    return (
      <RoundedCard
        style={card_style}
        key={this.props.stream_data.stream_data_number}
        onClick={() => this.props.handleCardClick(this.props.stream_data.stream_data_number)}
      >
        <div
          style={{
            height: 170,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <StreamThumbnailImage
            stream_id={this.props.stream.stream_id}
            stream_data_number={this.props.stream_data.stream_data_number}
            style={{ maxWidth: 280, maxHeight: 240 }}
          />
        </div>
        <div style={{ flexGrow: 1 }}>
          {this.props.stream_data.stream_data_number}
          <br />
          {this.props.stream_data.stream_data_name}
          <br />
          {this.props.stream_data.original_file_name}
        </div>
        <CheckBoxArea style={{ justifyContent: 'center' }}>
          <CheckBox checked={this.props.card_checkbox} />
        </CheckBoxArea>
      </RoundedCard>
    );
  }
}
