import InputBox from 'shared/components/atoms/InputBox';
import InputComponent from 'shared/components/molecules/InputComponent';
import ToggleButton from 'shared/components/molecules/ToggleButton';
import { MailAdress } from 'shared/models/MailAdress';
import styles from 'shared/styles/styles';
import MultiMailAddressInput from './MultiMailAddressInput';
import SelectBox from 'shared/components/atoms/SelectBox';
import { Stream } from 'user/api/streams';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface MailNotificationFieldProps<T = Record<string, any>> {
  mail_addresses: MailAdress<T>[];
  mail_message: string;
  enabled: boolean;
  show_stream_id: boolean;
  stream_id?: string | null; // undefinedは指定なし、nullは無指定（エラー）
  streams: Stream[];
  onChange: (args: { mail_addresses?: MailAdress<T>[]; mail_message?: string; enabled?: boolean, stream_id?: string | null }) => void;
}
/** メール通知設定欄 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function MailNotificationField<T = Record<string, any>>({
  mail_addresses,
  mail_message,
  enabled,
  show_stream_id,
  stream_id,
  streams,
  onChange,
}: MailNotificationFieldProps<T>) {
  const onEnabledChange = () => {
    onChange({ stream_id, enabled: !enabled });
  };
  const onImageSendChange = () => {
    onChange({ stream_id: typeof stream_id === 'undefined' ? null : undefined });
  }
  return (
    <InputComponent text='通知設定'>
      <ToggleButton
        checked={enabled}
        onClick={onEnabledChange}
        style={{
          marginBottom: styles.interval_narrow_margin,
        }}
      />
      {enabled && (
        <>
          <MultiMailAddressInput
            mail_addresses={mail_addresses ?? []}
            onChange={(mail_addresses) => onChange({ stream_id, mail_addresses })}
          />
          <InputComponent text='メッセージの本文'>
            <InputBox value={mail_message} onChange={(e) => onChange({ stream_id, mail_message: e.currentTarget.value })} />
          </InputComponent>
          {show_stream_id &&
            <InputComponent text='画像の添付'>
              <ToggleButton
                checked={typeof stream_id !== 'undefined'}
                onClick={onImageSendChange}
                style={{
                  marginBottom: styles.interval_narrow_margin,
                }}
              />
              {typeof stream_id !== 'undefined' &&
                <SelectBox
                  onChange={(e) =>
                    onChange({ stream_id: e.currentTarget.value})
                  }
                  value={stream_id || ''}
                  datas={streams.map((e) => {
                    return { name: e.stream_name, value: e.stream_id };
                  })}
                />
              }
            </InputComponent>
          }
        </>
      )}
    </InputComponent>
  );
}
