import { ProcessAppParameter } from 'user/api/processes';
import { isNotOnlySpace } from 'shared/utils/is';

// app_parameterが正しく入力されているかのチェック関数
const checkAppParameterRequired = (
  app_parameter: { [key: string]: string },
  process_app_parameteres: ProcessAppParameter[],
) => {
  let flag = true;
  // app_parametersでrequiredの要素が入力されているかチェックする
  for (let i = 0; i < process_app_parameteres.length; i++) {
    // required = falseならスキップ
    if (!process_app_parameteres[i].required) continue;
    // この時点でrequired = trueが確定
    const key = process_app_parameteres[i].key;
    // app_parameterにkeyの要素が存在しない or checkSpaceの条件を満たさなければflag = falseとする
    if (!app_parameter[key] || !isNotOnlySpace(app_parameter[key])) {
      flag = false;
      // app_parameterの内1つでも条件を満たさない要素が存在したら全体としての返り値はfalseになるため、次回以降の条件チェックをするまでもなくfalse
      break;
    }
  }
  return flag;
};

export default checkAppParameterRequired;
