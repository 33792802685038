import { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy } from 'shared/utils/converter';
import { CachedStreamDatas, RequestStreamsIdDataGet, StreamData } from 'user/api/streamsData';

/**
 * ストリームデータ一覧を取得するcustom hooks
 */
export const useStreamDataItems = ({
  stream_id,
  limit,
  status,
  exclusive_start_key,
  scan_index_forward,
}: RequestStreamsIdDataGet) => {
  const [stream_data_items, setStreamDataItems] = useState<StreamData[] | undefined>(undefined);
  const stream_data_items_record: Record<string, StreamData> = useMemo(() => {
    return stream_data_items ? keyBy(stream_data_items, (s) => s.stream_id) : {};
  }, [stream_data_items]);
  const loadStreamDataItems = useCallback(
    async (request?: RequestStreamsIdDataGet) => {
      const r =
        request ?? {
          stream_id,
          limit,
          status,
          exclusive_start_key,
          scan_index_forward,
        } ??
        {};
      if (!r.stream_id) return;
      const new_streams = await new CachedStreamDatas(r).get();
      setStreamDataItems(new_streams);
    },
    [stream_id, limit, status, exclusive_start_key, scan_index_forward],
  );

  useEffect(() => {
    loadStreamDataItems();
  }, [loadStreamDataItems]);

  return useMemo(() => {
    return {
      stream_data_items,
      stream_data_items_record,
      setStreamDataItems,
      loadStreamDataItems,
    };
  }, [stream_data_items, setStreamDataItems, stream_data_items_record, loadStreamDataItems]);
};
