// -- basic library --
import React, { useEffect, useState } from 'react';

// -- redux library --
import { useSelector } from 'react-redux';
import InputBox from 'shared/components/atoms/InputBox';
import PfBoldText from 'shared/components/atoms/PfBoldText';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import AppParameterInput from 'user/components/molecules/AppParameterInput';
import { Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import checkAppParameterRequired from 'user/utils/checkAppParameterRequired';
import { isNotOnlySpace } from 'shared/utils/is';
import { Channel, channelsIdGetAPI } from 'user/api/channels';
import { channelsIdProcessflowPostAPI, ProcessflowStep } from 'user/api/channelsProcesses';
import { JsonpathGroup } from 'user/api/jsonpathGroups';
import { Process, ProcessAppParameter } from 'user/api/processes';
import { CachedProcessFlowInfos, ProcessFlowInfo } from 'user/api/processflows';
import { Stream, CachedStreams } from 'user/api/streams';
import { streamsProcessinfoByrootIdNumGetAPI } from 'user/api/streamsProcessInfo';
import ChannelProcessHeartbeatPart from 'user/dialogs/CreateChannelProcessDialog/ChannelProcessHeartbeatPart';
import StreamSelectionPart from 'user/dialogs/CreateChannelProcessDialog/StreamSelectionPart';
import SelectStreamDataDialog from 'user/dialogs/SelectStreamDataDialog';
import { systemSelector } from 'user/redux/slices/systemSlice';
import { AppParameter } from 'shared/models/AppParameter';
import { HeartbeatParameter } from 'shared/models/HeartbeatParameter';

// -- http connection library --

// -- external components --

// -- external functions --

// -- types --

type Params = {
  stream: Stream;
  stream_data_number: string | number;
  channel_id: string;
  channel_process_number: string;
  isOpen?: boolean;
  onClose: () => void;
};

interface StepInfo {
  input_stream_ids: string[][];
  app_parameter: AppParameter; // API送信する時用のアップパラメーター
  heartbeat: HeartbeatParameter;
  process_app_parameter: ProcessAppParameter[]; //画面に表示する用のアップパラメーター
  process: Process;
  jsonpath_group?: JsonpathGroup;
}

// -- main component --
const ProcessFlowPanel: React.FC<Params> = (params) => {
  // -- redux preparations --
  const system_state = useSelector(systemSelector);

  // -- local states --
  const [channel, setChannel] = useState<Channel | undefined>(undefined);
  const [channel_process_name, setChannelProcessName] = useState<string>('');
  const [streams, setStreams] = useState<Stream[] | undefined>(undefined);
  const [stream_datas, setStreamDatas] = useState<(number | string)[]>([params.stream_data_number]);
  const [is_stream_data_thumnail_dialog_open, setIsStreamDataThumnailDialogOpen] = useState<boolean>(false);
  const [selected_stream_data_list, setSelectedStreamDataList] = useState<(number | string)[]>([
    params.stream_data_number,
  ]);
  const [all_selected, setAllSelected] = useState<boolean>(false);

  // -- 入力用states --
  const [selectedProcessflowInfo, setSelectedProcessflowInfo] = useState<ProcessFlowInfo | undefined>(undefined);
  const [cachedProcessFlowInfos] = useState<CachedProcessFlowInfos>(new CachedProcessFlowInfos());
  const [cachedStreams] = useState<CachedStreams>(
    new CachedStreams({
      with_output_streams: 'True',
      data_types: 'VIDEO,IMAGE',
      data_number_types: 'SEQUENCE',
    }),
  );

  // -- get external datas --

  const getStreamsDatas = () => {
    cachedStreams.get().then((data) => {
      // streamDataの親streamだけは選択できるようにする。with_in_channel_streams: 'False'としているため、この操作がないと、選択できなくなる
      setStreams([params.stream, ...data]);
    });
  };
  // 各プロセスごとに必要な情報をまとめた変数
  const [stepsinfos, setStepsinfos] = useState<StepInfo[]>([]);

  // -- processflows state--

  // -- get external datas --
  const getChannelData = async (channel_id: string) => {
    const res = await channelsIdGetAPI({ channel_id: channel_id });
    if (res.status === 200) {
      setChannel(res.data);
    }
  };

  // validate
  /**
   * 入力チェックを行い、エラーの場合はメッセージを含む例外をスローします。
   */
  const validate = () => {
    if (!selectedProcessflowInfo) {
      throw new Error(`プロセスフローを選択してください`);
    }
    if (!channel_process_name) {
      throw new Error(`プロセスフロー名を入力してください`);
    }
    if (stepsinfos[0] && !stepsinfos[0].process.can_be_oneshot) {
      throw new Error(
        `${stepsinfos[0].process.process_name}のプロセスはワンショットに対応していない為、選択できません`,
      );
    }
    for (let i = 0; i < stepsinfos.length; i++) {
      if (!isStreamSelected(i)) {
        throw new Error(`${stepsinfos[i].process.process_name}のストリームを選択してください`);
      }
      if (!checkAppParameterRequired(stepsinfos[i].app_parameter, stepsinfos[i].process_app_parameter)) {
        throw new Error(`${stepsinfos[i].process.process_name}のプロセスの詳細条件を正しく入力してください`);
      }
    }
  };

  // -- handlers --
  // ストリーム選択ダイアログ
  const handleCreateSelectStreamDataDialogClose = async (canceled?: boolean) => {
    setIsStreamDataThumnailDialogOpen(false);
    if (canceled) {
      return;
    }
  };

  const handleSetStreamDatas = async (streamdatas: (number | string)[]) => {
    setStreamDatas(streamdatas);
  };

  // 設定されているプロセスフローの取得関数
  const getProcessFlowDetail = async () => {
    const processinfos_res = await streamsProcessinfoByrootIdNumGetAPI({
      channel_id: params.channel_id,
      channel_process_number: params.channel_process_number,
    });
    if (!processinfos_res) {
      AlertDialog.show('指定のプロセスフローを取得できませんでした。');
      return;
    }

    // チャンネルプロセス名の設定
    setChannelProcessName(processinfos_res.data.processes[0].channel_process_name);

    const pfi = await cachedProcessFlowInfos.get({
      process_flow_id: processinfos_res.data.processes[0].process_flow_id,
    });
    if (!pfi) {
      AlertDialog.show('指定のプロセスフローを取得できませんでした。');
      return;
    }
    let canbe_oneshot = true;
    // 最初のプロセスがoneshotに対応していないのならば、指定のプロセスフローは選択できない
    if (pfi.processes[0] && !pfi.processes[0].can_be_oneshot) {
      canbe_oneshot = false;
    }
    if (!canbe_oneshot) {
      AlertDialog.show(
        <div>
          指定のプロセスフローは
          <br />
          ワンショットに対応していない為
          <br />
          選択できません。
        </div>,
      );
      return;
    }
    const new_stepsinfos: StepInfo[] = [];
    // プロセス毎に、必要な情報をまとめる
    pfi.processes.forEach((pr, i) => {
      const stepsinfo: StepInfo = {
        input_stream_ids: [],
        app_parameter: {},
        heartbeat: {},
        process_app_parameter: [],
        process: pr,
      };
      // 入力データ(ストリーム)が必要な場合、1つ目の入力streamは、streamDataの親フォルダ(親stream)を指定する
      if (pr.input_stream_constraints.length > 0) {
        stepsinfo.input_stream_ids.push([params.stream.stream_id]);
      }
      const row_app_parameter: AppParameter = processinfos_res.data.processes[i].app_parameter;
      stepsinfo.app_parameter = row_app_parameter;
      stepsinfo.process_app_parameter = pr.app_parameters;
      new_stepsinfos.push(stepsinfo);
    });
    setSelectedProcessflowInfo(pfi);
    setStepsinfos(new_stepsinfos);
  };

  const handleStreamSelectionChange = (p: { value: string[][]; step: number }) => {
    // ステップインフォの長さを超えてしまっている。
    if (p.step >= stepsinfos.length || p.step < 0) {
      return;
    }
    const new_stepsinfos = [...stepsinfos];
    new_stepsinfos[p.step].input_stream_ids = p.value;
    setStepsinfos(new_stepsinfos);
  };

  //app_parameterがどれかでも変わった時の関数
  const handleSetAppParameter = (p: { key: string; value: string; step: number }) => {
    // ステップインフォの長さを超えてしまっている。
    if (p.step >= stepsinfos.length || p.step < 0) {
      return;
    }
    const new_app_parameter = { ...stepsinfos[p.step].app_parameter };
    new_app_parameter[p.key] = p.value;
    const new_stepsinfos = [...stepsinfos];
    new_stepsinfos[p.step].app_parameter = new_app_parameter;
    setStepsinfos(new_stepsinfos);
  };

  const handleSetHeartbeat = (p: { value: HeartbeatParameter; step: number }) => {
    // ステップインフォの長さを超えてしまっている。
    if (p.step >= stepsinfos.length || p.step < 0) {
      return;
    }
    const new_stepsinfos = [...stepsinfos];
    new_stepsinfos[p.step].heartbeat = p.value;
    setStepsinfos(new_stepsinfos);
  };

  const handleFinishClick = async () => {
    // 入力チェック
    try {
      validate();
    } catch (e) {
      if (e instanceof Error) {
        AlertDialog.show(e.message);
      }
      return;
    }

    const new_stepsinfos = [...stepsinfos];
    // app_parameterにおいて、必須でない and 入力がスペースのみの場合は送らないようにデータ整形
    for (let i = 0; i < stepsinfos.length; i++) {
      const stepinfo = stepsinfos[i];
      const new_app_parameter: AppParameter = {};
      for (let j = 0; j < stepinfo.process_app_parameter.length; j++) {
        const pap = stepinfo.process_app_parameter[j];
        const key = pap.key;
        // 存在しない or スペースならスキップ
        if (!stepinfo.app_parameter[key] || !isNotOnlySpace(stepinfo.app_parameter[key])) continue;
        // すでにバリデーション済みなので存在するデータは全て送る
        new_app_parameter[key] = stepinfo.app_parameter[key];
      }
      new_stepsinfos[i].app_parameter = new_app_parameter;
    }
    const steps_for_post: ProcessflowStep[] = stepsinfos.map((si) => {
      return {
        app_parameter: si.app_parameter,
        input_stream_ids: si.input_stream_ids,
        heartbeat: si.heartbeat,
      };
    });
    const res = await channelsIdProcessflowPostAPI({
      channel_id: params.channel_id,
      channel_process_name: channel_process_name,
      process_flow_id: selectedProcessflowInfo?.process_flow_id || '',
      is_oneshot: 'True',
      steps: steps_for_post,
      input_stream_data_number: stream_datas,
    });

    // resの正常を持って完了と判定する
    if (res.status === 200) {
      params.onClose();
      AlertDialog.show('新規チャンネルプロセスの追加に成功しました');
    }
  };

  const isStreamSelected = (step: number) => {
    // ステップインフォの長さを超えてしまっている。
    if (step >= stepsinfos.length || step < 0) {
      return false;
    }
    const input_stream_ids = stepsinfos[step].input_stream_ids;
    // ストリームが選択されていない
    if (input_stream_ids.length !== selectedProcessflowInfo?.processes[step].input_stream_constraints.length) {
      return false;
    }
    for (const v of input_stream_ids) {
      for (const w of v) {
        if (w === '') return false;
      }
    }
    return true;
  };
  // -- onload function --
  useEffect(() => {
    (async function () {
      getStreamsDatas();
      await getChannelData(params.channel_id);
      await getProcessFlowDetail();
    })();
  }, []); /* eslint-disable-line */

  // -- render part --
  return (
    <PfDialog
      title='プロセスフロー詳細'
      isOpen={params.isOpen === undefined ? true : params.isOpen}
      onClose={() => params.onClose()}
    >
      {system_state.loading.preloading || streams === undefined || channel === undefined ? (
        <Spinner />
      ) : (
        <>
          <InputComponent text='プロセスフロー名' required>
            <InputBox
              title='プロセスフロー名'
              placeholder='入力してください'
              value={channel_process_name}
              onChange={(e) => setChannelProcessName(e.currentTarget.value)}
            />
          </InputComponent>
          {stepsinfos
            ? stepsinfos.map((si, i) => {
                // 全ての入力データ(ストリーム)を結合（無理やり感がある）
                const all_input_stream_ids = stepsinfos.map((si) => si.input_stream_ids.flat()).flat();
                return (
                  <div key={i}>
                    <PfBoldText>{si.process.process_name}</PfBoldText>
                    <div style={{ display: 'flex' }}>
                      <StreamSelectionPart
                        process={si.process}
                        streams={streams}
                        selected_stream_ids={si.input_stream_ids}
                        fixed_first_input_stream={true}
                        onChange={(v) =>
                          handleStreamSelectionChange({
                            value: v,
                            step: i,
                          })
                        }
                      />
                      {si.process.input_stream_constraints.length !== 0 && (
                        <RoundedButton
                          text='複数ファイルの選択'
                          onClick={() => setIsStreamDataThumnailDialogOpen(true)}
                          style={{ marginTop: 25, marginLeft: 25 }}
                        />
                      )}
                    </div>
                    {si.process_app_parameter.length > 0 && (
                      <InputComponent text='プロセスの詳細条件' required>
                        {si.process_app_parameter.map((spap, j) => {
                          return (
                            <AppParameterInput
                              key={`${i}_${j}`}
                              process_app_parameter={spap}
                              app_parameter={String(si.app_parameter[spap.key])}
                              streams={
                                // 入力input_streamがない場合はundefined
                                all_input_stream_ids.length <= 0
                                  ? []
                                  : streams.filter((v) => all_input_stream_ids.flat().indexOf(v.stream_id) !== -1)
                              }
                              stream_data_number={Number(params.stream_data_number)}
                              onChange={(app_parameter_value) =>
                                handleSetAppParameter({
                                  key: spap.key,
                                  value: app_parameter_value,
                                  step: i,
                                })
                              }
                            />
                          );
                        })}
                      </InputComponent>
                    )}
                    <ChannelProcessHeartbeatPart
                      key={si.process.process_id}
                      process={si.process}
                      value={si.heartbeat}
                      onChange={(v) => handleSetHeartbeat({ value: v, step: i })}
                    />
                  </div>
                );
              })
            : null}
          {is_stream_data_thumnail_dialog_open && (
            <SelectStreamDataDialog
              stream={params.stream}
              stream_data_number={params.stream_data_number}
              selected_stream_data_list={selected_stream_data_list}
              handleSelectedStreamDataList={(new_stream_data_list) => setSelectedStreamDataList(new_stream_data_list)}
              all_selected={all_selected}
              handleSetAllSelected={(new_all_selected) => setAllSelected(new_all_selected)}
              handleSetStreamDatas={handleSetStreamDatas}
              isOpen={is_stream_data_thumnail_dialog_open}
              onClose={handleCreateSelectStreamDataDialogClose}
            />
          )}
          <Footer>
            <RoundedButton onClick={() => params.onClose()} text='キャンセル' is_white />
            <RoundedButton onClick={handleFinishClick} text_type='CREATE' style={{ marginLeft: 12 }} />
          </Footer>
        </>
      )}
    </PfDialog>
  );
};

// -- styled components --

// -- finally export part --

export default ProcessFlowPanel;
