import { ActionTypeType } from 'shared/models/ActionTypeType';
import { CommonAPIRequestType, isCommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';
import { ISO8601 } from 'shared/models/ISO8601';
import { Query } from 'shared/models/Query';
import { StatusType } from 'shared/models/StatusType';
import sendAxios from '../../shared/axios/sendAxios';
import { getClient } from './base';

/*** エンティティ ***/

export interface ChannelEventConditionsActions {
  tenant_id: string;
  channel_id: string;
  channel_event_condition_number: number;
  channel_event_condition_action_number: number;
  channel_event_condition_name: string;
  status: StatusType;
  is_active: boolean;
  action_type: ActionTypeType;
  mail_address: string;
  mail_message?: string;
  stream_id: string;
  periods_before: number;
  periods_after: number;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

export interface ChannelEventConditionsActionsWithPaging {
  items: ChannelEventConditionsActions[];
  has_next: boolean;
}

const sample_channel_event_condition_action_1: ChannelEventConditionsActions = {
  tenant_id: '00000001',
  channel_id: '00000001',
  channel_event_condition_number: 1,
  channel_event_condition_action_number: 0,
  channel_event_condition_name: 'channel_event_condition_1',
  status: 'ACTIVE',
  is_active: true,
  action_type: 'Email',
  mail_address: 'string',
  stream_id: 'string',
  periods_before: 0,
  periods_after: 0,
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
};

const sample_channel_event_condition_action_2: ChannelEventConditionsActions = {
  tenant_id: '00000001',
  channel_id: '00000001',
  channel_event_condition_number: 1,
  channel_event_condition_action_number: 1,
  channel_event_condition_name: 'channel_event_condition_2',
  status: 'ACTIVE',
  is_active: false,
  action_type: 'Email',
  mail_address: 'string',
  stream_id: 'string',
  periods_before: 0,
  periods_after: 0,
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
};

/*** Caching mechanism ***/

export class CachedChannelEventConditionActions {
  private searched = false;
  private cache: ChannelEventConditionsActions[] = [];
  private channel_id: string;
  private channel_event_condition_number: string | number;
  constructor(params: { channel_id: string; channel_event_condition_number: string | number }) {
    this.channel_id = params.channel_id;
    this.channel_event_condition_number = params.channel_event_condition_number;
  }
  async get() {
    if (!this.searched) {
      // ページング非対応
      const res = await channelsIdEventConditionsActionsGetAPI({
        channel_id: this.channel_id,
        channel_event_condition_number: this.channel_event_condition_number,
      });
      if (res.status === 200) {
        this.cache = res.data.items;
        this.searched = true;
      }
    }
    return this.cache;
  }
}

/*** [GET] /api/channels/{id}/event-conditions/{channel_event_condition_number}/actions ***/

/**
 * チャンネルに設定されたイベント定義のactionの一覧を返す
 * @param channel_id チャンネルID
 * @returns チャンネルに設定されたイベント定義のactionの一覧
 */

export const channelsIdEventConditionsActionsGetAPI = (params: {
  channel_id: string;
  channel_event_condition_number: string | number;
  exclusive_start_channel_event_condition_action_number?: string;
}) => {
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `/api/channels/${params.channel_id}/event-conditions/${params.channel_event_condition_number}/actions`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    exclusive_start_channel_event_condition_number: params.exclusive_start_channel_event_condition_action_number,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<ChannelEventConditionsActionsWithPaging>(axios, path, query, form, method, {
    items: [sample_channel_event_condition_action_1, sample_channel_event_condition_action_2],
    has_next: false,
  });
};

/*** [POST] /api/channels/{id}/event-conditions/{channel_event_condition_number}/actions ***/

export interface RequestChannelsIdEventConditionsNumActionsPost extends CommonAPIRequestType {
  channel_id: string;
  channel_event_condition_number: string | number;
  channel_event_condition_action_name: string;
  action_type?: ActionTypeType;
  mail_address?: string;
  mail_message?: string;
  stream_id?: string;
  periods_before?: string;
  periods_after?: string;
}

export const channelsIdEventConditionsNumActionsPostAPI = (params: RequestChannelsIdEventConditionsNumActionsPost) => {
  const { channel_id, channel_event_condition_number, disabled_load, disabled_error_message, ended_load } =
    toAPIRequestParams({
      ...params,
      api_send_type: params.api_send_type ?? 'changeableOneTransmission',
    });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/channels/${channel_id}/event-conditions/${channel_event_condition_number}/actions`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'channel_id' || key === 'channel_event_condition_number' || isCommonAPIRequestType(key)) {
      continue;
    }
    form.append(key, value);
  }

  // 送信
  return sendAxios<ChannelEventConditionsActions>(
    axios,
    path,
    query,
    form,
    method,
    sample_channel_event_condition_action_1,
  );
};

/*** [PUT] /api/channels/{id}/event-conditions/{channel_event_condition_number}/actions/{channel_event_condition_action_number} ***/

export interface RequestChannelsIdEventConditionsNumActionsPut extends CommonAPIRequestType {
  channel_id: string;
  channel_event_condition_number: string | number;
  channel_event_condition_action_number: string | number;
  channel_event_condition_action_name: string;
  mail_address?: string;
  mail_message?: string;
  stream_id?: string;
  periods_before?: string;
  periods_after?: string;
}

export const channelsIdEventConditionsNumActionsPutAPI = (params: RequestChannelsIdEventConditionsNumActionsPut) => {
  const {
    channel_id,
    channel_event_condition_number,
    channel_event_condition_action_number,
    disabled_load,
    disabled_error_message,
    ended_load,
  } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/channels/${channel_id}/event-conditions/${channel_event_condition_number}/actions/${channel_event_condition_action_number}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (
      key === 'channel_id' ||
      key === 'channel_event_condition_number' ||
      key === 'channel_event_condition_action_number' ||
      isCommonAPIRequestType(key)
    ) {
      continue;
    }
    form.append(key, value);
  }

  // 送信
  return sendAxios<ChannelEventConditionsActions>(
    axios,
    path,
    query,
    form,
    method,
    sample_channel_event_condition_action_1,
  );
};

/*** [DELETE] /api/channels/{id}/event-conditions/{channel_event_condition_number}/actions/{channel_event_condition_action_number} ***/

export interface RequestChannelsIdEventConditionsNumActionsDelete extends CommonAPIRequestType {
  channel_id: string;
  channel_event_condition_number: string | number;
  channel_event_condition_action_number: string | number;
}

export const channelsIdEventConditionsNumActionsDeleteAPI = (
  params: RequestChannelsIdEventConditionsNumActionsDelete,
) => {
  const {
    channel_id,
    channel_event_condition_number,
    channel_event_condition_action_number,
    disabled_load,
    disabled_error_message,
    ended_load,
  } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'multipleTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/channels/${channel_id}/event-conditions/${channel_event_condition_number}/actions/${channel_event_condition_action_number}`;
  const method = 'delete';

  // 送信
  return sendAxios<ChannelEventConditionsActions>(
    axios,
    path,
    {},
    null,
    method,
    sample_channel_event_condition_action_1,
  );
};
