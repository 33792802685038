import React from 'react';
import { IMenuProps, Menu, MenuItem } from '@blueprintjs/core';
import { Layer } from './types';

interface LayerMenuProps extends IMenuProps {
  layer: Layer;
  onLayerProperty: (e: React.MouseEvent<HTMLElement, MouseEvent>, layer: Layer) => void;
  onLayerDelete: (e: React.MouseEvent<HTMLElement, MouseEvent>, layer: Layer) => void;
}
export default class LayerMenu extends React.PureComponent<LayerMenuProps> {
  render() {
    return (
      <Menu {...this.props}>
        <MenuItem text='設定...' onClick={(e) => this.props.onLayerProperty(e, this.props.layer)} />
        <MenuItem text='削除' onClick={(e) => this.props.onLayerDelete(e, this.props.layer)} />
      </Menu>
    );
  }
}
