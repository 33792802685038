// ビデオエディタの設定用ダイアログ

import React from 'react';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import EditorPanel from './EditorPanel';

interface VideoEditorDialogProps {
  isOpen: boolean;
  value: string;
  streamId?: string;
  streamDataNumber?: number;
  onClose: (value?: string) => void;
}
interface VideoEditorDialogState {}
export default class VideoEditorDialog extends React.PureComponent<VideoEditorDialogProps, VideoEditorDialogState> {
  private panelRef = React.createRef<EditorPanel>();
  constructor(props: VideoEditorDialogProps) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <PfDialog
        isOpen={this.props.isOpen}
        title='ビデオエディタ'
        onClose={() => this.props.onClose()}
        contentProps={{ style: { display: 'flex', flexDirection: 'column' } }}
        large
      >
        <EditorPanel
          ref={this.panelRef}
          streamId={this.props.streamId}
          streamDataNumber={this.props.streamDataNumber}
          style={{ width: '100%', flexGrow: 1 }}
          value={this.props.value}
        />
        <div style={{ textAlign: 'center' }}>
          <RoundedButton text='キャンセル' is_white={true} onClick={() => this.props.onClose()} />
          <RoundedButton
            text='OK'
            onClick={() => this.props.onClose(JSON.stringify(this.panelRef.current?.state.layers))}
          />
        </div>
      </PfDialog>
    );
  }
}

interface VideoEditorDialogButtonProps {
  value: string;
  streamId?: string;
  streamDataNumber?: number;
  onChange: (value: string) => void;
}
interface VideoEditorDialogButtonState {
  isOpen: boolean;
}
/** ビデオエディタ設定ダイアログを開くためのボタンです。 */
export class VideoEditorDialogButton extends React.PureComponent<
  VideoEditorDialogButtonProps,
  VideoEditorDialogButtonState
> {
  constructor(props: VideoEditorDialogButtonProps) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  private handleOpen = () => {
    this.setState({ isOpen: true });
  };
  private handleClose = (value?: string) => {
    this.setState({ isOpen: false });
    if (value !== undefined) {
      this.props.onChange(value);
    }
  };
  render() {
    return (
      <>
        <RoundedButton
          text='ビデオエディタ設定'
          onClick={this.handleOpen}
          disabled={(this.props.streamId || '') === ''}
        />
        {this.state.isOpen && this.props.streamId && (
          <VideoEditorDialog
            isOpen={true}
            value={this.props.value}
            streamId={this.props.streamId}
            streamDataNumber={this.props.streamDataNumber}
            onClose={this.handleClose}
          />
        )}
      </>
    );
  }
}
