import { Grid } from '@material-ui/core';
import { KonvaEventObject } from 'konva/types/Node';
import React, { useState } from 'react';
import { Circle, Layer, Stage } from 'react-konva';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import { Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import { colors } from 'shared/styles/colors';

interface TargetPointEditModalProps {
  value: number[];
  isOpen: boolean;
  onClose: (value?: number[]) => void;
}
export function TargetPointEditModal(params: TargetPointEditModalProps) {
  const [editValue, setEditValue] = useState<number[]>(params.value);
  const handleSave = () => {
    params.onClose(editValue);
  };
  const handleChange = (x?: number, y?: number) => {
    const value = [...editValue];
    if (x !== undefined) {
      value[0] = normalize(x);
    }
    if (y !== undefined) {
      value[1] = normalize(y);
    }
    setEditValue(value);
  };
  const handleDragEnd = (e: KonvaEventObject<DragEvent>) => {
    handleChange(e.target.x() / 200, e.target.y() / 200);
  };
  return (
    <>
      <PfDialog
        isOpen={params.isOpen}
        title='対象点設定'
        onClose={() => params.onClose()}
        contentProps={{ style: { display: 'flex', flexDirection: 'column' } }}
      >
        <p>対象点はバウンディングボックスのうち検知ラインを判定する座標点を指定します。</p>
        <Grid>
          <Grid item xs={8}>
            <div
              style={{
                width: '200px',
                height: '200px',
                border: '1px solid black',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <Stage width={200} height={200}>
                <Layer>
                  <Circle
                    x={Math.round(editValue[0] * 200)}
                    y={Math.round(editValue[1] * 200)}
                    radius={10}
                    fill={colors.light_green}
                    stroke={colors.black}
                    strokeWidth={2}
                    draggable={true}
                    onDragEnd={(e) => handleDragEnd(e)}
                  />
                </Layer>
              </Stage>
            </div>
          </Grid>
        </Grid>
        <Grid>
          <Grid item xs={4}>
            <>
              <div>
                <InputComponent text='横'>
                  <NumberInputRange
                    value={Math.round(editValue[0] * 100)}
                    onIncrement={() => handleChange(editValue[0] + 0.01, undefined)}
                    onDecrement={() => handleChange(editValue[0] - 0.01, undefined)}
                    onChange={(value) => handleChange(Number.parseInt(String(value)) / 100, undefined)}
                    unit='%'
                  />
                </InputComponent>
                <InputComponent text='縦'>
                  <NumberInputRange
                    value={Math.round(editValue[0] * 100)}
                    onIncrement={() => handleChange(undefined, editValue[1] + 0.01)}
                    onDecrement={() => handleChange(undefined, editValue[1] - 0.01)}
                    onChange={(value) => handleChange(Number.parseInt(String(value)) / 100, undefined)}
                    unit='%'
                  />
                </InputComponent>
              </div>
            </>
          </Grid>
        </Grid>
        <Footer>
          <RoundedButton text='保存' onClick={handleSave} />
          <RoundedButton text='キャンセル' onClick={() => params.onClose()} />
        </Footer>
      </PfDialog>
    </>
  );
}

type NumberInputRangeProps = {
  value: number;
  onIncrement: () => void;
  onDecrement: () => void;
  onChange: (value: number) => void;
  unit: string;
};

function NumberInputRange(props: NumberInputRangeProps) {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (!isNaN(value)) {
      props.onChange(value);
    }
  };

  return (
    <div>
      <button onClick={props.onDecrement}>-</button>
      <input type='number' value={props.value.toString()} onChange={handleInputChange} />
      <button onClick={props.onIncrement}>+</button>
      {props.unit}
    </div>
  );
}

function normalize(value: number) {
  if (value < 0) {
    return 0;
  } else if (1 < value) {
    return 1;
  } else {
    return Math.round(value * 100) / 100;
  }
}

interface TargetPointEditModalButtonProps {
  value: number[];
  onChange: (value: number[]) => void;
}
/** 対象点設定ダイアログを開くためのボタンです。 */
export function TargetPointEditModalButton(params: TargetPointEditModalButtonProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = (value?: number[]) => {
    setIsOpen(false);
    if (value !== undefined) {
      params.onChange(value);
    }
  };
  return (
    <>
      <RoundedButton text='設定' onClick={handleOpen} />
      {isOpen && <TargetPointEditModal value={params.value} isOpen={isOpen} onClose={handleClose} />}
    </>
  );
}
