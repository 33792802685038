import React from 'react';
import { IMenuProps, Menu, MenuItem } from '@blueprintjs/core';
import { Crop } from './types';

interface ObjectMenuProps extends IMenuProps {
  crop: Crop;
  onCropDelete: (e: React.MouseEvent<HTMLElement, MouseEvent>, crop: Crop) => void;
}
export default class ObjectMenu extends React.PureComponent<ObjectMenuProps> {
  render() {
    return (
      <Menu {...this.props}>
        <MenuItem text='削除' onClick={(e) => this.props.onCropDelete(e, this.props.crop)} />
      </Menu>
    );
  }
}
