// -- basic library --
import React, { SyntheticEvent } from 'react';
import { MenuItem } from '@blueprintjs/core';
import { IItemRendererProps, MultiSelect } from '@blueprintjs/select';

// -- type declaration --

interface Params {
  items: string[];
  selectedItems: string[] | undefined;
  onItemSelect: (item: string, event?: SyntheticEvent<HTMLElement, Event> | undefined) => void;
  onRemove: (value: string, index: number | undefined) => void;
}

// -- main component --

const MultiSelectBox: React.FC<Params> = (params) => {
  // -- preparations --
  const default_item_renderer = (item: string, itemProps: IItemRendererProps) => {
    return <MenuItem onClick={itemProps.handleClick} key={item} text={item} />;
  };
  const default_tag_renderer = (item: string) => {
    return <div>{item}</div>;
  };

  // -- render part --
  return (
    <MultiSelect
      onItemSelect={params.onItemSelect}
      selectedItems={params.selectedItems}
      tagRenderer={default_tag_renderer}
      items={params.items}
      itemRenderer={default_item_renderer}
      placeholder='複数選択可能...'
      onRemove={params.onRemove}
    />
  );
};

// -- finally export part --

export default MultiSelectBox;
