import ReactHlsPlayer, { ReactHlsPlayerProps } from 'react-hls-player';
import { getAccessKey } from 'shared/utils/else/sessionStorageFunctions';

export default function PfHlsPlayer(props: ReactHlsPlayerProps) {
  return (
    <ReactHlsPlayer
      {...props}
      hlsConfig={{
        ...props.hlsConfig,
        // startPosition: 0,
        // fix buffer holes (max 10sec)
        nudgeOffset: 0.2,
        nudgeMaxRetry: 50,
        enableWorker: true,
        xhrSetup: (xhr: XMLHttpRequest) => {
          xhr.withCredentials = true; // send cookies
          xhr.setRequestHeader('x-api-key', getAccessKey());
        },
      }}
    />
  );
}
