// -- basic library --
import React, { useEffect, useState } from 'react';
import history from 'shared/browserHistory';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { PageWholeArea, Title, TopArea, BottomArea } from 'shared/components/molecules/ContentsArea';
import { colors } from 'shared/styles/colors';
import { isNotSelected, isSelected } from 'shared/utils/is';
import { channelsIdDeleteAPI, CachedChannels, Channel } from 'user/api/channels';
import {
  CachedChannelEventConditions,
  channelsIdEventConditionsChannelEventConditionNumberDeleteAPI,
} from 'user/api/channelsEventConditions';
import { CachedChannelProcesses, channelsIdProcessesChannelProcessNumberDeleteAPI } from 'user/api/channelsProcesses';
import CreateFileProcessesDialog from 'user/pages/FileProcesses/CreateDialog';
import FileProcessesTable from 'user/pages/FileProcesses/TopPage/FileProcessesTable';
import { TableFileProcessesType } from 'user/pages/FileProcesses/TopPage/FileProcessesTable';
import { loadWrapperFunc } from 'user/utils/loadWrapperFunc';
import { preloadGroup } from 'user/utils/preloadGroup';

// -- main component --
const FileProcesses: React.FC = () => {
  // -- local states --
  const [selected_bodies, setSelectedBodies] = useState<TableFileProcessesType[]>([]);
  const [table_bodies, setTableBodies] = useState<TableFileProcessesType[] | undefined>(undefined);

  // 作成画面用
  const [open_create_dialog, setOpenCreateDialog] = useState<boolean>(false);

  // -- handlers --
  // クリックした時の関数たち

  const handleCreateClick = () => {
    setOpenCreateDialog(true);
  };

  const channnelAlarmDelete = async (channel_id: string) => {
    const items = await new CachedChannelEventConditions({ channel_id }).get();

    // 1つのチャンネルのイベント条件データを番号後ろから削除
    for (let j = items.length - 1; j >= 0; j--) {
      const res_event_condition_delete = await channelsIdEventConditionsChannelEventConditionNumberDeleteAPI({
        channel_id: items[j].channel_id,
        channel_event_condition_number: items[j].channel_event_condition_number,
      });
      if (res_event_condition_delete.status !== 200) return;
    }
  };

  const channnelProcessDelete = async (channel_id: string) => {
    const items = await new CachedChannelProcesses({ channel_id }).get();

    // 1つのチャンネルのプロセスデータを番号後ろから削除
    for (let j = items.length - 1; j >= 0; j--) {
      const res_process_delete = await channelsIdProcessesChannelProcessNumberDeleteAPI({
        channel_id: items[j].channel_id,
        channel_process_number: String(items[j].channel_process_number),
      });
      if (res_process_delete.status !== 200) return;
    }
  };

  const handleOKClick = async (selected_bodies: TableFileProcessesType[]) => {
    // 削除関数定義
    const deletes = async () => {
      for (const selected_body of selected_bodies) {
        // 1つのチャンネルのプロセスデータを削除
        await channnelAlarmDelete(selected_body.id);
        // 1つのチャンネルのイベント条件データを削除
        await channnelProcessDelete(selected_body.id);

        // 1つのチャンネルのデータを削除
        const channel_delete = await channelsIdDeleteAPI({ channel_id: selected_body.id });
        if (channel_delete.status !== 200) continue;
      }
      await loadTableBodies();
      AlertDialog.show('フォルダの削除に成功しました');
      setSelectedBodies([]);
    };
    // 実行
    await loadWrapperFunc(deletes);
  };

  const handleDeleteClick = () => {
    ConfirmDialog.show(
      <div style={{ color: colors.red }}>
        [確認]選択されているフォルダを
        <br />
        チャンネルプロセス、チャンネルイベント条件を含めて
        <br />
        削除する必要があります。
        <br />
        これらを全て削除して本当によろしいですか?
      </div>,
      () => handleOKClick(selected_bodies),
      () => history.push('/file-processes'),
      undefined,
    );
  };
  // テーブルをクリックする関数
  const handleCheckClick = (bodies: TableFileProcessesType[]) => {
    setSelectedBodies(bodies);
  };

  const loadTableBodies = async () => {
    const channel_items: Channel[] = await new CachedChannels({ channel_type: 'FILE' }).get();

    const return_table_bodies: TableFileProcessesType[] = [];
    for (let i = 0; i < channel_items.length; i++) {
      const return_body: TableFileProcessesType = {
        id: channel_items[i].channel_id,
        folder_name: {
          value: channel_items[i].channel_name,
          url: `/file-processes/${channel_items[i].channel_id}/overviews`,
        },
      };

      return_table_bodies.push(return_body);
    }
    setTableBodies(return_table_bodies);
  };

  // 作成用関数たち

  const handleDialogCloseClick = async (isCanceled: boolean) => {
    setOpenCreateDialog(false);
    if (!isCanceled) {
      await loadTableBodies();
    }
  };

  // -- onload function --
  useEffect(() => {
    preloadGroup(loadTableBodies());
  }, []);

  // -- render part --
  return (
    <PageWholeArea data-testid='FileProcesses'>
      <TopArea>
        <Title text='フォルダ一覧' />
        <RoundedButton onClick={handleCreateClick} text='フォルダ作成' disabled={isSelected(selected_bodies.length)} />
      </TopArea>
      <BottomArea>
        {table_bodies !== undefined ? (
          <FileProcessesTable
            bodies={table_bodies}
            selected_bodies={selected_bodies}
            handleCheckClick={handleCheckClick}
            footer_option={{
              text: '＞チェックした項目を削除する',
              handleClick: handleDeleteClick,
              disabled: isNotSelected(selected_bodies.length),
            }}
          />
        ) : (
          <Spinner />
        )}
        {open_create_dialog && (
          <CreateFileProcessesDialog isOpen={open_create_dialog} onClose={handleDialogCloseClick} />
        )}
      </BottomArea>
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default FileProcesses;
