// -- basic library --
import { Spinner } from '@blueprintjs/core';
import React, { useState } from 'react';
import InputBox from 'shared/components/atoms/InputBox';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import CheckBoxWithText from 'shared/components/molecules/CheckBoxWithText';
import { Content, Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import styles from 'shared/styles/styles';
import { isNotOnlySpace } from 'shared/utils/is';
import styled from 'styled-components';
import { Dashboard } from 'user/api/dashboards';
import { rolesPostAPI } from 'user/api/roles';
import { Stream } from 'user/api/streams';

// -- types --
interface Params {
  is_open: boolean;
  handleDialogCloseClick: (canceled?: boolean) => void;
  dashboards?: Dashboard[];
  streams?: Stream[];
}
// -- main component --

function CreateRoles({ handleDialogCloseClick, dashboards, streams, is_open }: Params) {
  // -- local states --
  // 入力用states
  const [role_name, setRoleName] = useState<string>('');
  const [selected_dashboards, setSelectedDashboards] = useState<string[]>([]);
  const [selected_streams, setSelectedStreams] = useState<string[]>([]);

  // -- handlers --

  const handleDashboardCheckClick = async (dashboard_id: string) => {
    let new_selected_dashboards = [...selected_dashboards];
    if (selected_dashboards.includes(dashboard_id)) {
      new_selected_dashboards = selected_dashboards.filter((si) => si !== dashboard_id);
    } else {
      new_selected_dashboards.push(dashboard_id);
    }
    setSelectedDashboards(new_selected_dashboards);
  };

  const handleStreamCheckClick = async (stream_id: string) => {
    let new_selected_streams = [...selected_streams];
    if (selected_streams.includes(stream_id)) {
      new_selected_streams = selected_streams.filter((si) => si !== stream_id);
    } else {
      new_selected_streams.push(stream_id);
    }
    setSelectedStreams(new_selected_streams);
  };

  const handleFinish = async () => {
    if (!isNotOnlySpace(role_name)) {
      AlertDialog.show('ロール名は必須入力です');
      return;
    }
    const res = await rolesPostAPI({
      role_name: role_name,
      dashboard_ids: selected_dashboards,
      stream_ids: selected_streams,
    });
    if (res.status === 200) {
      AlertDialog.show('ロールの作成に成功しました');
      handleDialogCloseClick();
    }
  };

  // -- render part --
  return (
    <PfDialog isOpen={is_open} onClose={() => handleDialogCloseClick(true)} title='ロール作成'>
      <Content>
        <InputComponent text='ロール名' required>
          <InputBox
            title='ロール名'
            placeholder='入力してください(必須)'
            value={role_name}
            onChange={(e) => setRoleName(e.currentTarget.value)}
          />
        </InputComponent>
        <InputComponent text='ダッシュボード'>
          {dashboards ? (
            dashboards.length !== 0 && (
              <RoleCheckBoxArea>
                {dashboards.map((dashboard, i) => {
                  return (
                    <CheckBoxWithText
                      key={i}
                      style={{ marginBottom: 5 }}
                      text={dashboard.dashboard_name}
                      checked={selected_dashboards.includes(dashboard.dashboard_id)}
                      onClick={() => handleDashboardCheckClick(dashboard.dashboard_id)}
                    />
                  );
                })}
              </RoleCheckBoxArea>
            )
          ) : (
            <Spinner />
          )}
        </InputComponent>
        <InputComponent text='データ'>
          {streams ? (
            streams.length !== 0 && (
              <RoleCheckBoxArea>
                {streams.map((stream, i) => {
                  return (
                    <CheckBoxWithText
                      key={i}
                      style={{ marginBottom: 5 }}
                      text={stream.stream_name}
                      checked={selected_streams.includes(stream.stream_id)}
                      onClick={() => handleStreamCheckClick(stream.stream_id)}
                    />
                  );
                })}
              </RoleCheckBoxArea>
            )
          ) : (
            <Spinner />
          )}
        </InputComponent>
        <Footer>
          <RoundedButton
            onClick={() => handleDialogCloseClick(true)}
            style={{ marginRight: styles.interval_narrow_margin }}
            text='キャンセル'
            is_white={true}
          />
          <RoundedButton onClick={handleFinish} disabled={!isNotOnlySpace(role_name)} text_type='CREATE' />
        </Footer>
      </Content>
    </PfDialog>
  );
}

// -- styled components --

const RoleCheckBoxArea = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 6px;
  max-height: 200px;
  overflow: auto;
`;

// -- finally export part --

export default CreateRoles;
