import { AxiosResponse } from 'axios';
import sendAxios from 'shared/axios/sendAxios';
import sendDownloadAxios from 'shared/axios/sendDownloadAxios';
import { CommonAPIRequestType, isCommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';
import { Query } from 'shared/models/Query';
import { getClient } from './base';

// import createMultipleAPIDatas from 'utils/createMultipleAPIDatas';

/*** エンティティ ***/

export interface Billing {
  yearmonth: string;
  component_id: string;
  amount: number;
  unit_price: number;
  demand_price: number;
}

export interface BillingsWithPaging {
  items: Billing[];
  has_next: boolean;
}

export interface BillingCsv {
  csv_index: number; // 元のcsvのindexを表す。
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  csv: Record<string, any>; // さまざまな属性が存在する。({列: 値}の関係)
}

export interface BillingsCsvWithPaging {
  items: BillingCsv[];
  headers: string[];
  has_next: boolean;
}

const sample_billing_1: Billing = {
  yearmonth: '202302',
  component_id: '00000001:INITIAL',
  amount: 1,
  unit_price: 20000,
  demand_price: 20000,
};

const sample_billing_2: Billing = {
  yearmonth: '202302',
  component_id: '00000001:DASHBOARD:123456',
  amount: 3,
  unit_price: 300,
  demand_price: 900,
};

const sample_billing_3: Billing = {
  yearmonth: '202302',
  component_id: '00000001:EVENT_CONDITION:123456:1',
  amount: 4,
  unit_price: 100,
  demand_price: 400,
};

const sample_billing_4: Billing = {
  yearmonth: '202302',
  component_id: '00000001:CHANNEL_PROCESS:123456:1',
  amount: 2,
  unit_price: 50000,
  demand_price: 100000,
};

const sample_billing_5: Billing = {
  yearmonth: '202302',
  component_id: '00000001:FILE_PROCESS:123456:1',
  amount: 900 / 3600,
  unit_price: 1500,
  demand_price: 375,
};

const sample_billing_6: Billing = {
  yearmonth: '202302',
  component_id: '00000001:FILE_PROCESS:123456:1',
  amount: 0,
  unit_price: 3000,
  demand_price: 0,
};

const sample_billing_7: Billing = {
  yearmonth: '202302',
  component_id: '00000001:STREAM_STORAGE:123456789012',
  amount: 10,
  unit_price: 0,
  demand_price: 0,
};

const sample_billing_8: Billing = {
  yearmonth: '202302',
  component_id: '00000001:STREAM_STORAGE:123456789012',
  amount: 0,
  unit_price: 30000,
  demand_price: 0,
};

const sample_billing_9: Billing = {
  yearmonth: '202302',
  component_id: '00000001:STREAM_STORAGE:123456789012',
  amount: 1,
  unit_price: 50000,
  demand_price: 50000,
};

const sample_csv1: BillingCsv = {
  csv_index: 0,
  csv: {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
    E: 0,
    F: 0,
    G: 0,
    H: 0,
    I: 0,
    J: 0,
  },
};

/*** Caching mechanism ***/

export class CachedBillings {
  private searched = false;
  private cache: Billing[] = [];
  private params: RequestBillingsGet;
  constructor(params: RequestBillingsGet) {
    this.params = params;
  }
  async get() {
    if (!this.searched) {
      let esk: string | undefined = undefined;
      let has_next = true;
      while (has_next) {
        const res: AxiosResponse<BillingsWithPaging> = await billingSummariesYeGetAPI({
          ...this.params,
          exclusive_start_yearmonth: esk,
        });
        if (res.status === 200) {
          this.cache = [...this.cache, ...res.data.items];
          has_next = res.data.has_next;
          esk = res.data.items.map((d) => d.yearmonth).reduce((a, b) => (a > b ? a : b), '');
        } else {
          has_next = true;
          break;
        }
      }
      this.searched = true;
    }
    return this.cache;
  }
}

/*** [GET] /api/billings/{yearmonth} ***/
export interface RequestBillingsGet {
  yearmonth: string;
  exclusive_start_yearmonth?: string;
}

export const billingSummariesYeGetAPI = (params: RequestBillingsGet) => {
  const { yearmonth, exclusive_start_yearmonth, disabled_load, disabled_error_message, ended_load } =
    toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/billings/${yearmonth}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    yearmonth,
    exclusive_start_yearmonth,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<BillingsWithPaging>(axios, path, query, form, method, {
    items: [
      sample_billing_1,
      sample_billing_2,
      sample_billing_3,
      sample_billing_4,
      sample_billing_5,
      sample_billing_6,
      sample_billing_7,
      sample_billing_8,
      sample_billing_9,
    ],
    has_next: false,
  });
};

export interface RequestBillingSummariesDataDownloadGet extends CommonAPIRequestType {
  yearmonth: string;
  original_file_name?: string;
}
export function getBillingSummariesDataDownloadGetAPIUrl(params: RequestBillingSummariesDataDownloadGet) {
  return `/api/billing/summaries/${params.yearmonth}/download`;
}

export const billingSummaryDataYeDownloadGetAPI = (params: RequestBillingSummariesDataDownloadGet) => {
  const { disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'multipleTransmission',
  });
  // クライアントを定義
  const axios = getClient({ responseType: 'blob', disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = getBillingSummariesDataDownloadGetAPIUrl(params);

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendDownloadAxios(axios, path, query, form, new ArrayBuffer(8));
};

/*** [POST] /api/billings ***/

export interface RequestBillngsPost extends CommonAPIRequestType {
  yearmonth: string;
  component_id: string;
  process_type: string;
  amount: number;
  unit_price: number;
  demand_price: number;
}

export const billingsPostAPI = (params: RequestBillngsPost) => {
  const { disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/billings`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (isCommonAPIRequestType(key)) continue;
    form.append(key, value);
  }

  // 送信
  return sendAxios<Billing>(axios, path, query, form, method, sample_billing_1);
};

/*** [PUT] /api/billings/{yearmonth}/{component_id} ***/

export interface RequestBillingsIdPut extends CommonAPIRequestType {
  yearmonth: string;
  component_id: string;
  process_type: string;
  amount: number;
  unit_price: number;
  demand_price: number;
}

export const billingsIdPutAPI = (params: RequestBillingsIdPut) => {
  const { yearmonth, component_id, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/billings/${yearmonth}/${component_id}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'user_id' || isCommonAPIRequestType(key)) continue;
    form.append(key, value);
  }

  // 送信
  return sendAxios<Billing>(axios, path, query, form, method, sample_billing_1);
};

/*** [GET] /api/billings/{yearmonth}/{component_id} ***/

export interface RequestbillingsIdGet extends CommonAPIRequestType {
  yearmonth: string;
  component_id: string;
}

export const billingsIdGetAPI = (params: RequestbillingsIdGet) => {
  const { yearmonth, component_id, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/billings/${yearmonth}/${component_id}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<Billing>(axios, path, query, form, method, sample_billing_1);
};

export interface RequestBillingSummariesCsvGet extends CommonAPIRequestType {
  yearmonth: string;
  original_file_name?: string;
  start_csv_index?: number; // csvの取得を始めるindex
  acquisitions?: number; // csvを取得する件数
}

// /*** [GET] /api/billing/summaries/{yearmonth}/csv ***/
/*** バッチで作成されたCSVをJSON形式で取得できるAPI ***/

export const billingSummariesYeCsvGetAPI = (params: RequestBillingSummariesCsvGet) => {
  const { yearmonth, start_csv_index, acquisitions, disabled_load, disabled_error_message, ended_load } =
    toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/billing/summaries/${yearmonth}/csv`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    start_csv_index,
    acquisitions,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<BillingsCsvWithPaging>(axios, path, query, form, method, {
    items: Array(50)
      .fill(null)
      .map((_, i) => {
        const d = { ...sample_csv1 };
        d.csv_index = i;
        return d;
      }),
    headers: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'],
    has_next: false,
  });
};
