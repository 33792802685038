import { assertNever } from 'shared/utils/else/assertNever';

/** 時間単位 */
export type WidgetScaleType = 'MIN' | 'HOUR' | 'DAY' | 'MONTH';
export const widgetScaleTypes: WidgetScaleType[] = ['MIN', 'HOUR', 'DAY', 'MONTH'];
export const widgtScaleTypeRecord: Record<WidgetScaleType, number> = { MIN: 1, HOUR: 1, DAY: 1, MONTH: 1 };

export const isWidgetScaleType = (str?: string | null) => typeof str === 'string' && str in widgtScaleTypeRecord;

export const widgetScaleToText = (widgetScale?: WidgetScaleType | null) => {
  switch (widgetScale) {
    case 'MIN':
      return '分';
    case 'HOUR':
      return '時';
    case 'DAY':
      return '日';
    case 'MONTH':
      return '月';
    case undefined:
      return '';
    case null:
      return '';
    default:
      assertNever(widgetScale);
      return '';
  }
};

export function scaleToWidgetPeriod(scale: WidgetScaleType) {
  switch (scale) {
    case 'MIN':
      return 'min';
    case 'HOUR':
      return 'hour';
    case 'DAY':
      return 'day';
    case 'MONTH':
      return 'day';
    default:
      assertNever(scale);
      return '';
  }
}

/** dashboardsIdWidgetsNumMetricsGetAPI 専用のウィジェット期間の変換関数です */
export function scaleToWidgetPeriod2(scale: WidgetScaleType) {
  switch (scale) {
    case 'MIN':
      return 'min';
    case 'HOUR':
      return 'hour';
    case 'DAY':
      return 'hour';
    case 'MONTH':
      return 'hour';
    default:
      assertNever(scale);
      return '';
  }
}

export const scaleToStep = (scale: WidgetScaleType) => {
  switch (scale) {
    case 'MIN':
      return 86400;
    case 'HOUR':
      return 86400;
    case 'DAY':
      return 86400;
    case 'MONTH':
      return 86400;
    default:
      assertNever(scale);
      return 0;
  }
};
