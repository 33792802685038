// -- basic library --
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import history from 'shared/browserHistory';
import Spinner from 'shared/components/atoms/Spinner';
import { PageWholeArea, Title } from 'shared/components/molecules/ContentsArea';
import PageSwitchingTabs from 'shared/components/molecules/PageSwitchingTabs';
import styles from 'shared/styles/styles';
import { Stream } from 'user/api/streams';
import { authSelector } from 'user/redux/slices/authSlice';
import { systemSelector } from 'user/redux/slices/systemSlice';
import MetricEndpointPanel from './MetricEndpointPanel';
import StreamDataDownloadPackagesPanel from './StreamDataDownloadPackagesPanel';
import StreamDataDownloadPanel from './StreamDataDownloadPanel';
import StreamDetailPanel from './StreamDetailPanel';

// -- external functions --

// external types --
import StreamPublishPanel from './StreamPublishPanel';
import StreamingEndpointPanel from './StreamingEndpointPanel';
import StreamDataUploadPanel from './StreamsDataUploadPanel';
import { useStream } from 'user/hooks/useStream/useStream';
import { TopArea } from 'shared/components/atoms/ContentsArea';
import { GearPopover } from 'shared/components/molecules/GearPopover';

// -- external datas --

// -- main component --

const StreamsDetail: React.FC<
  RouteComponentProps<{
    stream_id: string;
    panel_id?: string;
  }>
> = (params) => {
  const auth_state = useSelector(authSelector);
  const system_state = useSelector(systemSelector);

  // -- local states --
  const { stream, loadStream } = useStream({ stream_id: params.match.params.stream_id });

  // -- handlers --

  const getPageSwitchingTabsDatas = (stream: Stream) => {
    const return_datas = [{ id: 'details', text: 'データ詳細' }];
    if (stream.data_type === 'METRIC') {
      return_datas.push({ id: 'data_download_packages', text: '集計ファイル' });
    }
    if (stream.data_type === 'VIDEO' || stream.data_type === 'IMAGE') {
      return_datas.push({ id: 'data_download_packages', text: 'ビデオファイル' });
    }
    return return_datas;
  };

  // -- functions --
  const handleTabsClick = (tab_id: string, stream_id: string) => {
    history.push(`/streams/${stream_id}/${tab_id}`);
  };

  const handleOnStreamChange = () => {
    loadStream();
  };

  const getGearMenuItems = (stream: Stream) => {
    const return_datas = [];
    if (stream.listing_visibility === 'INPUT' && auth_state.is_admin) {
      return_datas.push({ label: 'アップロード', onClick: () => handleTabsClick("data_upload", params.match.params.stream_id) });
    }
    if (stream.data_type === 'METRIC' || stream.data_type === 'VIDEO' || stream.data_type === 'IMAGE') {
      return_datas.push({ label: '個別データダウンロード', onClick: () => handleTabsClick("data_download", params.match.params.stream_id) });
    }
    if (stream.streaming_endpoint !== null) {
      return_datas.push({  label: 'エンドポイント', onClick: () => handleTabsClick("streaming_endpoint", params.match.params.stream_id) });
    } else if (stream.data_type === 'VIDEO' && stream.data_number_type === 'TIMESTAMP' && auth_state.is_admin) {
      return_datas.push({ label: 'エンドポイント', onClick: () => handleTabsClick("metric_endpoint", params.match.params.stream_id) });
    }
    if (stream.data_type === 'METRIC' && stream.data_number_type === 'TIMESTAMP' && auth_state.is_admin) {
      return_datas.push({ label: 'エンドポイント', onClick: () => handleTabsClick("metric_endpoint", params.match.params.stream_id) });
      return_datas.push({ label: '公開', onClick: () => handleTabsClick("publish", params.match.params.stream_id) });
    }
    return return_datas;
  }

  // -- render part --
  return (
    <PageWholeArea data-testid='StreamDetail'>
      <TopArea>
        <Title
          text={stream?.stream_name ?? ''}
          style={{
            marginTop: styles.interval_narrow_margin,
            marginBottom: styles.interval_margin,
          }}
        />
        <GearPopover
          menuItems={stream ? getGearMenuItems(stream): []}
          data-testid="stream-detail-gear-menu" />
      </TopArea>
      {!system_state.loading.preloading && stream ? (
        <React.Fragment>
          <PageSwitchingTabs
            display_datas={getPageSwitchingTabsDatas(stream)}
            handleClick={(id: string) => handleTabsClick(id, params.match.params.stream_id)}
            selected_id={params.match.params.panel_id}
            initial_selected_id='details'
          />
          <Switch>
            <Route
              exact
              path='/streams/:stream_id/details'
              render={() => <StreamDetailPanel stream={stream} onStreamChanged={loadStream} />}
            />
            {stream.listing_visibility === 'INPUT' && (
              <Route
                exact
                path='/streams/:stream_id/data_upload'
                render={() => <StreamDataUploadPanel stream={stream} />}
              />
            )}
            {stream.data_type === 'METRIC' || stream.data_type === 'VIDEO' || stream.data_type === 'IMAGE' ? (
              <Route
                exact
                path='/streams/:stream_id/data_download_packages'
                render={(props) => <StreamDataDownloadPackagesPanel {...props} stream={stream} />}
              />
            ) : null}
            {stream.data_type === 'METRIC' || stream.data_type === 'VIDEO' || stream.data_type === 'IMAGE' ? (
              <Route
                exact
                path='/streams/:stream_id/data_download'
                render={(props) => <StreamDataDownloadPanel {...props} stream={stream} />}
              />
            ) : null}
            <Route
              exact
              path='/streams/:stream_id/streaming_endpoint'
              render={(props) => (
                <StreamingEndpointPanel {...props} stream={stream} onStreamChange={handleOnStreamChange} />
              )}
            />
            {stream.data_type === 'METRIC' && stream.data_number_type === 'TIMESTAMP' && (
              <Route
                exact
                path='/streams/:stream_id/publish'
                render={(props) => (
                  <StreamPublishPanel {...props} stream={stream} onStreamChange={handleOnStreamChange} />
                )}
              />
            )}
            {(stream.data_type === 'METRIC' || stream.data_type == 'VIDEO') && stream.data_number_type === 'TIMESTAMP' && (
              <Route
                exact
                path='/streams/:stream_id/metric_endpoint'
                render={(props) => (
                  <MetricEndpointPanel {...props} stream={stream} onStreamChange={handleOnStreamChange} />
                )}
              />
            )}
          </Switch>
        </React.Fragment>
      ) : (
        <Spinner />
      )}
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default StreamsDetail;
