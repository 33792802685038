/** デバイスの出力ストリームにおける、グラフの表示ターゲット。デバイス実機解析画面などで使用 */
export type StreamDeviceGraphTargetType = 'GENDER' | 'GENERATION' | 'GENDERGENERATION' | 'TOTAL' | 'TABLE';
export const stream_device_graph_target_record = {
  GENDER: 0,
  GENERATION: 0,
  GENDERGENERATION: 0,
  TOTAL: 0,
  TABLE: 0,
} as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isStreamDeviceGraphTargetType = (data: any): data is StreamDeviceGraphTargetType =>
  typeof data === 'string' && data in stream_device_graph_target_record;

/** device_graph_typeを日本語表記に変換する関数**/
export const convertDeviceGraphTypesToName = (device_graph_type: string) => {
  let name = '';
  switch (device_graph_type) {
    case 'GENDER':
      name = '性別';
      break;
    case 'GENERATION':
      name = '世代別';
      break;
    case 'GENDERGENERATION':
      name = '性別&世代別';
      break;
    case 'TOTAL':
      name = '時間別総数';
      break;
    case 'TABLE':
      name = '詳細表示';
      break;
    default:
      name = '';
  }

  return name;
};
