// -- basic library --
import React, { useEffect, useState } from 'react';

// -- redux library --

// -- http connection library --
import { useSelector } from 'react-redux';

// -- external components --
import InputBox from 'shared/components/atoms/InputBox';
import InputNumberBox from 'shared/components/atoms/InputNumberBox';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Content, Footer, SubTextDetails } from 'shared/components/molecules/ContentsArea';
import DoubleText from 'shared/components/molecules/DoubleText';
import InputComponent from 'shared/components/molecules/InputComponent';

// -- external functions --
import checkAppParameterRequired from 'user/utils/checkAppParameterRequired';
import { isNotOnlySpace, isValidDelaySeconds } from 'shared/utils/is';
import { dateToYMDHMS } from 'shared/utils/converter/date';

// -- external types --
import { channelsIdProcessesNumGetAPI, ChannelProcess, channelsIdProcessesNumPutAPI } from 'user/api/channelsProcesses';
import { getAllProcesses, Process, ProcessAppParameter } from 'user/api/processes';
import AppParameterInput from 'user/components/molecules/AppParameterInput';
import { systemSelector } from 'user/redux/slices/systemSlice';
import ChannelProcessHeartbeatPart from '../../../../../dialogs/CreateChannelProcessDialog/ChannelProcessHeartbeatPart';
import { AppParameter } from 'shared/models/AppParameter';
import { HeartbeatParameter } from 'shared/models/HeartbeatParameter';
import { useStreams } from 'user/hooks/useStreams/useStreams';

// -- types --

type Params = {
  channel_id: string;
  channel_process_number: string;
  isOpen?: boolean;
  onClose: () => void;
};

// -- main component --
const DetailChannelProcessDialog: React.FC<Params> = (params) => {
  // -- redux preparations --
  const system_state = useSelector(systemSelector);

  // -- local states --
  const [channel_process, setChannelProcess] = useState<ChannelProcess | undefined>(undefined);
  const [process_id_names, setProcessIdNames] = useState<{ [process_id: string]: string } | undefined>(undefined);
  const [process_app_parameters, setProcessAppParameters] = useState<ProcessAppParameter[] | undefined>(undefined);
  const [process, setProcess] = useState<Process | undefined>();
  // -- 入力用states --
  const [channel_process_name, setChannelProcessName] = useState<string>('');
  const [app_parameter, setAppParameter] = useState<AppParameter>({});
  const [heartbeat, setHeartbeat] = useState<HeartbeatParameter>({});
  const [delay_seconds, setDelaySeconds] = useState<number>(0);
  const { streams, streams_record } = useStreams({ with_output_streams: 'True', with_in_channel_streams: 'True' });

  const getChannelProcess = async (channel_id: string, channel_process_number: string) => {
    const res = await channelsIdProcessesNumGetAPI({
      channel_id: channel_id,
      channel_process_number: channel_process_number,
    });
    if (res.status !== 200) return;
    const processes = await getAllProcesses();
    const return_process_id_names: { [process_id: string]: string } = {};
    // process_idをもってprocess_nameを追加する
    processes.forEach((process) => {
      return_process_id_names[process.process_id] = process.process_name;
    });
    setProcessIdNames(return_process_id_names);
    setChannelProcess(res.data);
    setAppParameter(res.data.app_parameter);
    setHeartbeat(res.data.heartbeat);
    setChannelProcessName(res.data.channel_process_name);
    const process = processes.find((process) => process.process_id === res.data.process_id);
    if (process) {
      setProcessAppParameters(process.app_parameters);
      setProcess(process);
    }
    setDelaySeconds(res.data.delay_seconds);
  };

  const handleChannelNameChangeClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChannelProcessName(e.currentTarget.value);
  };

  //app_parameterがどれかでも変わった時の関数
  const handleSetAppParameter = (value: string, key: string) => {
    const new_app_parameter = { ...app_parameter };
    new_app_parameter[key] = value;
    setAppParameter(new_app_parameter);
  };

  const handleFinishClick = async (
    channel_id: string,
    channel_process_number: string,
    app_parameters: ProcessAppParameter[],
  ) => {
    if (!isNotOnlySpace(channel_process_name)) {
      AlertDialog.show('チャンネルプロセス名は必須です');
      return;
    }
    if (!checkAppParameterRequired(app_parameter, app_parameters)) {
      AlertDialog.show('プロセスの詳細条件の入力が正しくありません');
      return;
    }
    if (!isValidDelaySeconds(delay_seconds)) {
      AlertDialog.show('配信の遅延を入力する場合は、0以上900以下の整数でなければなりません。※省略時は0となります。');
      return;
    }

    // app_parameterにおいて、必須でない and 入力がスペースのみの場合は送らないようにデータ整形
    const app_parameter_for_post: { [key: string]: string } = {};
    for (let i = 0; i < app_parameters.length; i++) {
      const key = app_parameters[i].key;
      // 存在しない or スペースならスキップ
      if (!app_parameter[key] || !isNotOnlySpace(app_parameter[key])) continue;
      // すでにバリデーション済みなので存在するデータは全て送る
      app_parameter_for_post[key] = app_parameter[key];
    }

    const res = await channelsIdProcessesNumPutAPI({
      channel_id: channel_id,
      channel_process_number: channel_process_number,
      channel_process_name: channel_process_name,
      app_parameter: app_parameter_for_post,
      heartbeat: heartbeat,
      delay_seconds: delay_seconds || 0,
    });

    // resの正常を持って完了と判定する
    if (res.status === 200) {
      params.onClose();
      AlertDialog.show('チャンネルプロセスの更新に成功しました');
    }
  };

  // -- onload function --
  useEffect(() => {
    (async function () {
      // await getStreamsDatas();
      // プロセス情報の取得
      await getChannelProcess(params.channel_id, params.channel_process_number);
    })();
  }, []); /* eslint-disable-line */

  // -- render part --
  return (
    <PfDialog
      title={`チャンネルプロセス詳細`}
      isOpen={params.isOpen === undefined ? true : params.isOpen}
      onClose={params.onClose}
    >
      <Content>
        {system_state.loading.preloading ||
        channel_process === undefined ||
        process_id_names === undefined ||
        process_app_parameters === undefined ? (
          <Spinner />
        ) : (
          <>
            <InputComponent text='チャンネルプロセス名' required>
              <InputBox
                title='チャンネルプロセス名'
                placeholder='入力してください'
                value={channel_process_name}
                onChange={handleChannelNameChangeClick}
              />
            </InputComponent>
            <InputComponent text='プロセス'>
              <div style={{ marginBottom: 35 }}>
                {process_id_names[channel_process.process_id] ? process_id_names[channel_process.process_id] : ''}
              </div>

              {process_app_parameters.length ? (
                <>
                  <SubTextDetails>詳細条件を設定できます</SubTextDetails>
                  {process_app_parameters.map((d, index) => {
                    return (
                      <AppParameterInput
                        key={index}
                        process_app_parameter={d}
                        app_parameter={String(app_parameter[d.key])}
                        streams={
                          streams?.filter((v) => channel_process.input_stream_ids.indexOf(v.stream_id) !== -1) ?? []
                        }
                        stream_data_number={channel_process.input_stream_data_number}
                        onChange={(app_parameter_value) => handleSetAppParameter(app_parameter_value, d.key)}
                      />
                    );
                  })}
                </>
              ) : null}
              {process && (
                <ChannelProcessHeartbeatPart process={process} value={heartbeat} onChange={(v) => setHeartbeat(v)} />
              )}
            </InputComponent>
            {channel_process.target_term_from && (
              <InputComponent text='対象データ'>
                <DoubleText
                  value1={dateToYMDHMS(channel_process.target_term_from * 1000)}
                  value2={channel_process.target_term_to ? dateToYMDHMS(channel_process.target_term_to * 1000) : ''}
                  interval_text='〜'
                />
              </InputComponent>
            )}
            {channel_process.input_stream_ids.map((input_stream_id, index) => {
              return (
                <div key={index}>
                  <InputComponent text={`入力データ(${index + 1})`}>
                    <div>{streams_record[input_stream_id]?.stream_name ?? ''}</div>
                  </InputComponent>
                </div>
              );
            })}
            {channel_process.output_stream_ids.map((output_stream_id, index) => {
              return (
                <div key={index}>
                  <InputComponent text={`出力データ(${index + 1})`}>
                    <div>{streams_record[output_stream_id]?.stream_name ?? ''}</div>
                  </InputComponent>
                </div>
              );
            })}
            <InputComponent text='配信の遅延'>
              <InputNumberBox value={delay_seconds} onChange={setDelaySeconds} min={0} max={900} />
            </InputComponent>
            <Footer>
              <RoundedButton
                onClick={() =>
                  handleFinishClick(params.channel_id, params.channel_process_number, process_app_parameters)
                }
                text_type='UPDATE'
              />
            </Footer>
          </>
        )}
      </Content>
    </PfDialog>
  );
};

// -- styled components --

// -- finally export part --

export default DetailChannelProcessDialog;
