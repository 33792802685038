// -- basic library --
import React, { useEffect, useState } from 'react';
import history from 'shared/browserHistory';
import DatetimeInput from 'shared/components/atoms/DatetimeInput';
import InputBox from 'shared/components/atoms/InputBox';
import InputNumberBox from 'shared/components/atoms/InputNumberBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Content } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import StreamDataFileBox from 'user/components/molecules/StreamDataFileBox';
import styles from 'shared/styles/styles';
import { isValidNumber } from 'shared/utils/is';
import { isNotOnlySpace } from 'shared/utils/is';
import { isSequence } from 'user/utils/streamFunctions';
import uploadMultiStreamDataFile from 'user/utils/uploadMultiStreamDataFunctions';
import styled from 'styled-components';
import { Stream } from 'user/api/streams';
import { RequestStreamsIdDataUploadPost } from 'user/api/streamsData';
import { toStringBoolean } from 'shared/models/StringBoolean';
import { streamDataTypeToMime } from 'shared/models/StreamDataType';
import { StreamDataFileType } from 'shared/models';

// -- type declaration --

type Param = { stream: Stream };
// -- main component --

const StreamUploadPanel: React.FC<Param> = ({ stream }) => {
  // -- local state --
  const [is_disabled_stream_data_number, setIsDisabledStreamDataNumber] = useState<boolean>(true);
  const [placeholder_stream_data_number, setPlaceholderStreamDataNumber] = useState<string>('Please input ...(必須)');
  const [stream_data_mime_type, setStreamDataMimeType] = useState<string>('*/*');

  // 入力用states
  const [stream_data_name, setStreamDataName] = useState<string>('');
  const [stream_data_number, setStreamDataNumber] = useState<number>(NaN); // number
  const [stream_data_files, setStreamDataFiles] = useState<StreamDataFileType[]>([]);

  // -- get datas --
  const loadState = (stream: Stream) => {
    if (stream.data_number_type === 'TIMESTAMP') {
      setIsDisabledStreamDataNumber(false);
      setPlaceholderStreamDataNumber('入力してください(任意)');
    } else if (stream.data_number_type === 'SEQUENCE') {
      setIsDisabledStreamDataNumber(true);
      setPlaceholderStreamDataNumber('(連続した番号を使用します)');
    } else if (stream.data_number_type === 'USER_SPECIFIC') {
      setIsDisabledStreamDataNumber(false);
      setPlaceholderStreamDataNumber('正の整数を入力してください(必須)');
    }
    setStreamDataMimeType(streamDataTypeToMime(stream.data_type));
  };

  // -- handlers --
  const handleStreamDataNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStreamDataName(e.currentTarget.value);
  };

  const handleStreamDataNumberChange = (value: string | number) => {
    if (typeof value === 'string') {
      const new_stream_data_number = value.length > 0 ? Number(value) : NaN;
      setStreamDataNumber(new_stream_data_number);
    } else {
      setStreamDataNumber(value);
    }
  };

  const handleClickCancel = () => {
    history.push(`/streams`);
  };

  // varidation
  /**
   * 入力チェックを行い、エラーの場合はメッセージを含む例外をスローします。
   */
  const validate = (stream: Stream) => {
    if (
      stream.data_number_type === 'USER_SPECIFIC' &&
      !isValidNumber({
        num: Number(stream_data_number),
        positive: true,
        integer: true,
      })
    ) {
      throw new Error('データ番号の基準がUSER_SPECIFICの場合はデータ番号は必須です\n*データ番号は正の整数');
    }
    if (stream_data_files.length <= 0) {
      throw new Error('ストリームデータを選択してください');
    }
    for (let i = 0; i < stream_data_files.length; i++) {
      const sdf = stream_data_files[i];
      if (sdf.file.size > 5 * 1024 * 1024 * 1024) {
        // 5GBを超えるファイルは制限
        throw new Error(`${sdf.file_name}のサイズが5GBを超えています。\n*5GB以下のサイズのみアップロードできます`);
      }
    }
  };

  const onFinish = async () => {
    // 入力statesの初期化
    setStreamDataName('');
    setStreamDataNumber(NaN);
    setStreamDataFiles([]);
    // 状態を更新
    loadState(stream);
  };

  const handleClickUploadFinish = async (
    stream: Stream,
    inputs: {
      stream_id: string;
      stream_data_number: number;
      stream_data_name: string;
      stream_data_files: StreamDataFileType[];
    },
  ) => {
    // 入力チェック
    try {
      validate(stream);
    } catch (e) {
      if (e instanceof Error) {
        AlertDialog.show(e.message);
      }
      return;
    }
    // 上でバリデーションをかけているので、必ずストリームデータは1つ以上存在する。
    // SEQUENCEの時は、全てをアップロードし、それ以外では最初の1つだけをアップロードする。
    const upload_files = isSequence(stream) ? inputs.stream_data_files : inputs.stream_data_files.slice(0, 1);
    const request_post_params: RequestStreamsIdDataUploadPost = {
      stream_id: inputs.stream_id,
      add_dimension: toStringBoolean(false),
    };
    if (!Number.isNaN(inputs.stream_data_number)) {
      request_post_params.stream_data_number = inputs.stream_data_number;
    }
    if (isNotOnlySpace(inputs.stream_data_name)) {
      request_post_params.stream_data_name = inputs.stream_data_name;
    }
    // API通信部分
    // streamDataのFileを複数アップロードする関数
    await uploadMultiStreamDataFile({
      onConfirm: onFinish,
      stream_datas: upload_files,
      request_params: request_post_params,
    });
  };

  // -- onload function --
  useEffect(() => {
    loadState(stream);
  }, [stream]);

  // -- render part --
  return (
    <Content>
      <Panel>
        <InputComponent text='ストリームデータ番号' required={stream.data_number_type === 'USER_SPECIFIC'}>
          {stream.data_number_type === 'TIMESTAMP' && (
            <DatetimeInput
              title='ストリームデータ番号'
              data-testid='stream-data-number-timestamp'
              onChange={(e) => handleStreamDataNumberChange(e.currentTarget.value)}
              placeholder={placeholder_stream_data_number}
              value={Number.isNaN(stream_data_number) ? '' : stream_data_number}
              disabled={is_disabled_stream_data_number}
              step='1'
            />
          )}
          {stream.data_number_type !== 'TIMESTAMP' && (
            <InputNumberBox
              data-testid='stream-data-number-not-timestamp'
              onChange={handleStreamDataNumberChange}
              placeholder={placeholder_stream_data_number}
              value={stream_data_number}
              disabled={is_disabled_stream_data_number}
            />
          )}
        </InputComponent>
        <InputComponent text='ストリームデータ名'>
          <InputBox
            title='ストリームデータ名'
            onChange={handleStreamDataNameChange}
            value={stream_data_name}
            placeholder='入力してください'
          />
        </InputComponent>
        <InputComponent text={`ストリームデータ`} required>
          <StreamDataFileBox
            stream={stream}
            files={stream_data_files}
            setFiles={setStreamDataFiles}
            accept={stream_data_mime_type}
          />
        </InputComponent>
        <ButtonArea>
          <RoundedButton
            text='キャンセル'
            onClick={handleClickCancel}
            is_white
            style={{
              marginRight: styles.interval_narrow_margin,
            }}
          />
          <RoundedButton
            text='アップロード'
            onClick={() =>
              handleClickUploadFinish(stream, {
                stream_id: stream.stream_id,
                stream_data_name: stream_data_name,
                stream_data_number: stream_data_number,
                stream_data_files: stream_data_files,
              })
            }
          />
        </ButtonArea>
      </Panel>
    </Content>
  );
};

// -- styled components --
const Panel = styled.div`
  width: 60%;
  display: block;
`;

const ButtonArea = styled.div`
  display: flex;
`;

// -- finally export part --

export default StreamUploadPanel;
