import { useState, memo, useCallback } from 'react';
import no_image from 'assets/no_image.png';
import ContentBodyMat from 'shared/components/atoms/ContentBodyMat';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import { Content } from 'shared/components/molecules/ContentsArea';
import styles from 'shared/styles/styles';
import { dateToYMDHMS } from 'shared/utils/converter/date';
import { toFormatBytes } from 'shared/utils/converter';
import styled from 'styled-components';
import { Device, devicesIdNowGetAPI } from 'user/api/device';

// -- type declaration --

type Params = {
  device?: Device;
};

// -- external functions --

// -- main component --
const DevicesOverviews = memo(function DevicesIdDetailOverviews({ device }: Params) {
  // -- render part --
  return (
    <Content>
      {device ? (
        <ContentBodyMat flexible={true}>
          <LeftComponent device={device} />
          <RightComponent device={device} />
        </ContentBodyMat>
      ) : (
        <Spinner />
      )}
    </Content>
  );
});

// 左側の要素
const LeftComponent = function LeftComponent({ device }: { device: Device }) {
  return (
    <RoundedCardLeftArea>
      <TextsArea>
        <TextArea>
          <div>デバイス名</div>
          <ValueText>{device.device_name}</ValueText>
        </TextArea>
        <TextArea>
          <div>デバイスID</div>
          <ValueText>{device.device_id}</ValueText>
        </TextArea>
        <TextArea>
          <div>起動日時</div>
          <ValueText>{dateToYMDHMS(device.created_at)}</ValueText>
        </TextArea>
        <TextArea>
          <div>アプリ名</div>
          <ValueText>{device.soracom_status?.application_name || ''}</ValueText>
        </TextArea>
        <TextArea>
          <div>デプロイ日時</div>
          <ValueText>
            {device.soracom_status?.application_deployed_at
              ? dateToYMDHMS(device.soracom_status?.application_deployed_at)
              : ''}
          </ValueText>
        </TextArea>
        <TextArea>
          <div>データ容量</div>
          <ValueText>{toFormatBytes(0, 2)}</ValueText>
        </TextArea>
      </TextsArea>
    </RoundedCardLeftArea>
  );
};

// 右側の要素
const RightComponent = function RightComponent({ device }: { device: Device }) {
  // local_states
  const [image, setImage] = useState<string | undefined>('');

  const getNowImage = useCallback(async () => {
    if (process.env.NODE_ENV !== 'production') {
      setImage('https://idea-security-demo-hls-tmp-hasegawa.s3-ap-northeast-1.amazonaws.com/video000.jpg');
      return;
    }
    if (!device) return;
    let return_thumbnail = '';
    const res = await devicesIdNowGetAPI(device.device_id);
    if (res.status === 200) {
      const blob = new Blob([res.data], { type: 'image/png' });
      const url = window.URL || window.webkitURL;
      return_thumbnail = url.createObjectURL(blob);
    }
    setImage(return_thumbnail);
  }, [device]);
  return (
    <RoundedCardRightArea>
      <RoundedButton
        text='カメラ画像取得'
        onClick={getNowImage}
        style={{
          width: 140,
          height: styles.super_small_button_height,
          marginBottom: 7,
        }}
      />
      <Img src={image || no_image} />
    </RoundedCardRightArea>
  );
};

// -- styled components --
const RoundedCardLeftArea = styled.div`
  width: 280px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: ${styles.interval_margin};
`;

const RoundedCardRightArea = styled.div`
  width: calc(100% - 280px - ${styles.interval_margin});
  height: auto;
  display: flex;
  flex-direction: column;
`;

const TextsArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const ValueText = styled.div`
  margin-top: 5px;
  font-weight: bold;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  max-width: 580px;
  max-height: 360px;
`;

// -- finally export part --
export default DevicesOverviews;
