import React from 'react';
import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { dashboardsIdWidgetsNumDeleteAPI, DashboardWidget } from 'user/api/dashboardsWidgets';
import { CachedStreams } from 'user/api/streams';
import { WidgetScaleType } from 'shared/models/WidgetScaleType';
import history from 'shared/browserHistory';
import { RouteComponentProps } from 'react-router';
import { store } from 'user/redux/store';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import RoundedWidgetCard from 'shared/components/molecules/RoundedGraphCard';
import { DateString } from 'shared/models/DateString';
import { isTimeString, TimeString, toTimeString } from 'shared/models/TimeString';
import DetailDashboardWidgetDialog from '../DetailDashboardWidgetDialog';
import DashboardWidgetDialog from './DashboardWidgetDialog';
import getWidgetComponents from 'user/widgets/getWidgetType';
import { isRequiredFixedLabel } from 'shared/utils/is';

type DashboardWidgetCardProps = RouteComponentProps<{
  dashboard_id: string;
  panel_id?: string;
}> & {
  widget: DashboardWidget;
  handleUpdateDlCsvList: (dlName: string, dlUrl: string) => void;
  onDeleted: () => void;
  onWidgetChanged: () => void; // ウィジェットの設定が変わったことの通知
  metricStreams: CachedStreams;
  playerStreams: CachedStreams;
  scale?: WidgetScaleType | null;
  start_date?: DateString | null;
  end_date?: DateString | null;
  start_time?: TimeString | null;
  end_time?: TimeString | null;
};

export interface WidgetState {
  scale: WidgetScaleType | null;
  start_date: DateString | null;
  end_date: DateString | null;
  start_time: TimeString;
  end_time: TimeString;
  fixed: boolean;
}
interface DashboardWidgetCardState {
  isSettingDialogOpen: boolean;
  isExpandDialogOpen: boolean;
  widgetKey: number; // ウィジェットを再構築するキー
  // widgetでのデータ期間などのデータはここで保管する
  widgetState: WidgetState;
  auth: boolean;
  count: number;
}
/**
 * ウィジェット表示カードです。
 * ・ウィジェットコンテンツの表示
 * ・ウィジェットの削除
 * ・ウィジェットの更新ダイアログの表示
 */
export default class DashboardWidgetCard extends React.PureComponent<
  DashboardWidgetCardProps,
  DashboardWidgetCardState
> {
  constructor(props: DashboardWidgetCardProps) {
    super(props);
    const state = store.getState();
    const is_admin = state.auth.is_admin;
    const { start_date, end_date, start_time, end_time, scale, widget } = props;

    // propsで渡されていない and DBから取得した値が存在する
    const fixed =
      start_time === undefined &&
      end_time === undefined &&
      start_time === undefined &&
      end_time === undefined &&
      isRequiredFixedLabel(widget.widget_setting);
    this.state = {
      isSettingDialogOpen: false,
      isExpandDialogOpen: false,
      widgetKey: 0,
      widgetState: {
        start_date: start_date ?? widget.widget_setting?.start_date ?? null,
        end_date: end_date ?? widget.widget_setting?.end_date ?? null,
        start_time: start_time ?? widget.widget_setting?.start_time ?? toTimeString('00:00'),
        end_time: end_time ?? widget.widget_setting?.end_time ?? toTimeString('23:59'),
        scale: scale ?? widget.widget_setting?.scale ?? 'HOUR',
        fixed,
      },
      auth: is_admin,
      count: 0,
    };
  }

  componentDidUpdate(prevProps: DashboardWidgetCardProps) {
    // 全部同じなら更新しない
    if (
      this.props.scale === prevProps.scale &&
      this.props.start_date === prevProps.start_date &&
      this.props.end_date === prevProps.end_date &&
      this.props.start_time === prevProps.start_time &&
      this.props.end_time === prevProps.end_time
    ) {
      return;
    }

    const new_widget_state = { ...this.state.widgetState };
    const count_for_updated = {
      start_date: false,
      end_date: false,
      start_time: false,
      end_time: false,
    };

    if (this.props.scale !== undefined && this.props.scale !== prevProps.scale) {
      new_widget_state.scale = this.props.scale;
    }
    if (this.props.start_date !== undefined && this.props.start_date !== prevProps.start_date) {
      new_widget_state.start_date = this.props.start_date;
      count_for_updated.start_date = true;
    }
    if (this.props.end_date !== undefined && this.props.end_date !== prevProps.end_date) {
      new_widget_state.end_date = this.props.end_date;
      count_for_updated.end_date = true;
    }
    if (isTimeString(this.props.start_time) && this.props.start_time !== prevProps.start_time) {
      new_widget_state.start_time = this.props.start_time;
      count_for_updated.start_time = true;
    }

    if (isTimeString(this.props.end_time) && this.props.end_time !== prevProps.end_time) {
      new_widget_state.end_time = this.props.end_time;
      count_for_updated.end_time = true;
    }
    // propsから日時の指定がある場合は固定ラベルをつけない
    if (
      (count_for_updated.start_date && count_for_updated.start_date) ||
      (count_for_updated.start_time && count_for_updated.end_time)
    ) {
      new_widget_state.fixed = false;
    }
    // newWidgetStateをそれぞれで更新すると古いデータが参照される場合がある。
    // よってまとめて更新する
    this.setState({ widgetState: new_widget_state });
  }

  private handleWidgetSettingClick = () => {
    this.setState({ isSettingDialogOpen: true });
  };
  /** 設定ダイアログを閉じてウィジェットを再描画します。 */
  private handleDashboardWidgetSetDialogClose = (isCanceled: boolean) => {
    this.setState({ isSettingDialogOpen: false });
    // tabによるurlを初期化する
    history.push(`/dashboards/${this.props.match.params.dashboard_id}`);
    if (!isCanceled) {
      this.setState({ widgetKey: this.state.widgetKey + 1 });
      this.props.onWidgetChanged();
    }
  };
  private handleExpandDialogOpen = () => {
    this.setState({ isExpandDialogOpen: true });
  };
  private handleExpandDialogClose = () => {
    this.setState({ isExpandDialogOpen: false });
    this.handleReloadWidget();
  };
  /** ウィジェットの再構築をします。 */
  handleReloadWidget = () => {
    this.setState({ widgetKey: this.state.widgetKey + 1 });
  };
  private handleWidgetDeleteClick = () => {
    ConfirmDialog.show(
      '[確認]\nこのウィジェットを削除します。\n本当によろしいですか？',
      () => this.deleteWidget(this.props.widget.dashboard_id, this.props.widget.dashboard_widget_number),
      undefined,
      undefined,
    );
  };
  /** ウィジェットの表示設定が変更されました。 */
  private handleWidgetStateChanged = (widgetState: WidgetState) => {
    const { start_date, end_date, start_time, end_time } = widgetState;

    this.setState({
      widgetState: {
        ...widgetState,
        fixed: isRequiredFixedLabel({ start_date, end_date, start_time, end_time }),
      },
    });
    this.handleReloadWidget();
    this.props.onWidgetChanged();
  };
  /** このウィジェットを削除します。 */
  private deleteWidget = (dashboard_id: string, dashboard_widget_number: number) => {
    dashboardsIdWidgetsNumDeleteAPI({
      dashboard_id,
      dashboard_widget_number: String(dashboard_widget_number),
    }).then((res) => {
      if (res.status === 200) {
        AlertDialog.show('ウィジェットの削除に成功しました');
        this.props.onDeleted();
      }
    });
  };
  render() {
    return (
      <RoundedWidgetCard
        menu={
          <Menu>
            {this.state.auth && <MenuItem text='詳細設定' onClick={this.handleWidgetSettingClick} />}
            <MenuDivider />
            {/* <MenuItem text='CSVダウンロード' disabled={true} />
            <MenuDivider /> */}
            <MenuItem text='グラフ拡大表示' onClick={this.handleExpandDialogOpen} />
            <MenuDivider />
            {this.state.auth && <MenuItem text='削除' onClick={this.handleWidgetDeleteClick} />}
          </Menu>
        }
      >
        <div className='DragHandle' style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            {this.props.widget.dashboard_widget_name}
          </div>
        </div>
        <div style={{ width: '100%', flexGrow: 1 }}>
          {React.createElement(getWidgetComponents(this.props.widget.widget_type), {
            key: this.state.widgetKey,
            widget: this.props.widget,
            state: this.state.widgetState,
            onStateChanged: (defaults) => this.handleWidgetStateChanged(defaults),
            handleUpdateDlCsvList: this.props.handleUpdateDlCsvList,
          })}
        </div>
        {this.state.isExpandDialogOpen && (
          <DashboardWidgetDialog
            widget={this.props.widget}
            widgetKey={this.state.widgetKey}
            state={this.state.widgetState}
            onStateChanged={this.handleWidgetStateChanged}
            onClose={this.handleExpandDialogClose}
          />
        )}
        {this.state.isSettingDialogOpen && (
          <DetailDashboardWidgetDialog
            {...this.props}
            widget={this.props.widget}
            isOpen={this.state.isSettingDialogOpen}
            onClose={this.handleDashboardWidgetSetDialogClose}
            metricStreams={this.props.metricStreams}
            playerStreams={this.props.playerStreams}
          />
        )}
      </RoundedWidgetCard>
    );
  }
}
