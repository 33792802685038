import { CommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';
import { CsvRow } from 'shared/models/CsvRow';
import { ExportCsvStatistics } from 'shared/models/ExportCsvStatistics';
import { ISO8601 } from 'shared/models/ISO8601';
import { JsonpathGroupCategory } from 'shared/models/JsonpathGroupCategory';
import { Query } from 'shared/models/Query';
import { StatusType } from 'shared/models/StatusType';
import sendAxios from '../../shared/axios/sendAxios';
import { getClient } from './base';

/*** エンティティ ***/

export interface JsonpathGroup {
  jsonpath_group_id: string;
  jsonpath_group_name: string;
  category: JsonpathGroupCategory;
  display: string[];
  export_csv_statistics: ExportCsvStatistics;
  csv_rows: CsvRow[];
  memo: string | null;
  status: StatusType;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

export interface Jsonpath {
  jsonpath_group_id: string;
  csv_rows: CsvRow;
  status: StatusType;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

export interface JsonpathGroupWithPaging {
  items: JsonpathGroup[];
  has_next: boolean;
}

export interface JsonpathCsvWithPaging {
  items: CsvRow[];
  headers: string[];
  has_next: boolean;
}

const sample_json_path_group_1: JsonpathGroup = {
  jsonpath_group_id: '00000001',
  jsonpath_group_name: 'テストJSONパス1',
  category: 'AI',
  display: ['DASHBOARD'],
  export_csv_statistics: 'NONE',
  csv_rows: [
    {
      header_name: 'header1',
      json_path: '$[*].a',
      cell_format: 'STRING',
      cell_format_args: '',
      fill: 'True',
    },
  ],
  memo: 'このJSONPパスグループはダッシュボードで使います',
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: null,
};

const sample_json_path_group_2: JsonpathGroup = {
  jsonpath_group_id: '00000002',
  jsonpath_group_name: 'JSONパス1',
  category: 'COUNTER',
  display: ['STREAM', 'PROCESS'],
  csv_rows: [
    {
      header_name: 'header2',
      json_path: '$[*].b',
      cell_format: 'SUBSTRING',
      cell_format_args: 'test',
      fill: 'True',
    },
  ],
  memo: null,
  export_csv_statistics: 'KEY',
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: null,
};

/*** [GET] /api/jsonpath ***/
export interface RequestJsonpathGroupGet extends CommonAPIRequestType {
  status?: StatusType;
  exclusive_start_jsonpath_group_id?: string;
  display?: string;
  category?: string;
}

export const jsonpathGroupGetAPI = (params: RequestJsonpathGroupGet) => {
  const {
    status,
    exclusive_start_jsonpath_group_id,
    display,
    category,
    disabled_load,
    disabled_error_message,
    ended_load,
  } = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({
    responseType: 'json',
    disabled_load,
    disabled_error_message,
    ended_load,
  });

  // パス・メソッドを定義
  const path = `/api/jsonpath/groups`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    status,
    exclusive_start_jsonpath_group_id,
    display,
    category,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<JsonpathGroupWithPaging>(axios, path, query, form, method, {
    items: [sample_json_path_group_1, sample_json_path_group_2],
    has_next: false,
  });
};

/*** [POST] /api/jsonpathGroups ***/

export interface RequestJsonpathGroupPost {
  jsonpath_group_name: string;
  export_csv_statistics: ExportCsvStatistics;
  csv_rows: CsvRow[];
}

export const jsonpathGroupsPostAPI = (params: RequestJsonpathGroupPost) => {
  // クライアントを定義
  const axios = getClient({ responseType: 'json' });

  // パス・メソッドを定義
  const path = `/api/jsonpath/groups`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'csv_rows') {
      const roop_number = params.csv_rows.length || 0;
      for (let i = 0; i < roop_number; i++) {
        form.append(`csv_rows[${i}]`, JSON.stringify(params.csv_rows[i]));
      }
    } else {
      form.append(key, value);
    }
  }

  // 送信
  return sendAxios<JsonpathGroup>(axios, path, query, form, method, sample_json_path_group_1);
};

/*** [GET] /api/jsonpathGroups/{jsonpath_group_id} ***/

export interface RequestJsonpathGroupIdGet {
  jsonpath_group_id: string;
}

export const jsonpathGroupsIdGetAPI = (params: RequestJsonpathGroupIdGet) => {
  // クライアントを定義
  // const axios = getClient({responseType: 'json'});
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `/api/jsonpath/groups/${params.jsonpath_group_id}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // }

  // 送信
  return sendAxios<JsonpathGroup>(axios, path, query, form, method, sample_json_path_group_1);
};

/*** [PUT] /api/jsonpath/{jsonpath_group_id} ***/

export interface RequestJsonpathGroupIdPut {
  jsonpath_group_id: string;
  jsonpath_group_name: string;
  export_csv_statistics: ExportCsvStatistics;
  csv_rows: CsvRow[];
}

export const jsonpathGroupsIdPutAPI = (params: RequestJsonpathGroupIdPut) => {
  // クライアントを定義
  const axios = getClient({ responseType: 'json' });

  // パス・メソッドを定義
  const path = `/api/jsonpath/groups/${params.jsonpath_group_id}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'csv_rows') {
      const roop_number = params.csv_rows.length || 0;
      for (let i = 0; i < roop_number; i++) {
        form.append(`csv_rows[${i}]`, JSON.stringify(params.csv_rows[i]));
      }
    } else {
      form.append(key, value);
    }
  }

  // 送信
  return sendAxios<JsonpathGroup>(axios, path, query, form, method, sample_json_path_group_1);
};

/*** [DELETE] /api/jsonpath/{jsonpath_group_id} ***/

export interface RequestJsonpathGroupIdDelete {
  jsonpath_group_id: string;
}

export const jsonpathGroupsIdDeleteAPI = (params: RequestJsonpathGroupIdDelete) => {
  // クライアントを定義
  const axios = getClient({ responseType: 'json' });

  // パス・メソッドを定義
  const path = `/api/jsonpath/groups/${params.jsonpath_group_id}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    form.append(key, value);
  }

  // 送信
  return sendAxios<JsonpathGroup>(axios, path, query, form, method, sample_json_path_group_1);
};
