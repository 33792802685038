import React, { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import history from 'shared/browserHistory';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { Content, TopArea, Title, BottomArea } from 'shared/components/molecules/ContentsArea';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableHeaderType, TableBodyUrlType } from 'shared/components/molecules/Table/type';
import styles from 'shared/styles/styles';
import { isSelectedAndActive, isSelected /*checkNoneZeroAndActive*/ } from 'shared/utils/is';
import { channelsIdInputStreamsIdDeleteAPI } from 'user/api/channels';
import { systemSelector } from 'user/redux/slices/systemSlice';
import { loadWrapperFunc } from 'user/utils/loadWrapperFunc';
import Create from './Create';
import { useStreams } from 'user/hooks/useStreams/useStreams';
import { RouteComponentProps } from 'react-router-dom';
import { useChannel } from 'user/hooks/useChannel/useChannel';

// -- type declaration --

type Params = RouteComponentProps<{ channel_id: string }>;

type TableChannelInputStreamType = {
  id: string;
  input_stream_name: TableBodyUrlType;
  data_number_type: string;
  data_type: string;
};

// テーブルの列の情報まとめたデータ
const headers: TableHeaderType[] = [
  {
    id: 'input_stream_name',
    label: '入力データ名',
    sortable: true,
    width: 'auto',
    search_props: {
      type: 'name',
      default_display: true,
    },
  },
  {
    id: 'data_number_type',
    label: 'データ番号の基準',
    sortable: true,
    width: '15%',
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'data_type',
    label: 'データの種類',
    sortable: true,
    width: '15%',
    search_props: {
      type: 'name',
    },
  },
];

// -- external functions --

// -- main component --
const ChannelsIdDetailInputStreams: React.FC<Params> = (params) => {
  // -- redux preparations --
  const system_state = useSelector(systemSelector);

  const [selected_bodies, setSelectedBodies] = useState<TableChannelInputStreamType[]>([]);
  const { streams, streams_record } = useStreams({ with_output_streams: 'True', with_in_channel_streams: 'True' });
  const { channel, loadChannel } = useChannel({ channel_id: params.match.params.channel_id });
  const intput_stream_ids = useMemo(() => {
    return channel?.input_stream_ids;
  }, [channel]);
  // 作成画面のステート
  const [is_create_open, setIsCreateOpen] = useState<boolean>(false);

  // テーブル関係の関数
  const handleClick = (bodies: TableChannelInputStreamType[]) => {
    setSelectedBodies(bodies);
  };

  // テーブルのデータを取得する関数
  const table_bodies: TableChannelInputStreamType[] | undefined = useMemo(() => {
    return intput_stream_ids?.map((isid) => {
      const stream = streams_record[isid];
      return {
        id: isid,
        input_stream_name: {
          value: stream ? stream.stream_name : '',
          url: `/streams/${isid}/details`,
        },
        data_number_type: stream ? stream.data_number_type : '',
        data_type: stream ? stream.data_type : '',
      };
    });
  }, [streams_record, intput_stream_ids]);

  const handleOKClick = useCallback(async () => {
    if (!channel) return;
    const { channel_id } = channel;
    const deletes = async () => {
      for (const selected_body of selected_bodies) {
        await channelsIdInputStreamsIdDeleteAPI({
          channel_id,
          stream_id: selected_body.id,
        });
      }
      await loadChannel();
      setSelectedBodies([]);
    };
    await loadWrapperFunc(deletes);
  }, [loadChannel, selected_bodies, channel]);

  const handleCancelClick = () => {
    if (!channel) return;
    const { channel_id } = channel;
    history.push(`/channels/${channel_id}/input-streams`);
  };

  const handleDeleteClick = () => {
    ConfirmDialog.show(
      <div>
        [確認]選択されているデータを削除します。
        <br />
        本当によろしいですか？
      </div>,
      handleOKClick,
      handleCancelClick,
      undefined,
    );
  };
  // 作成用画面の関数

  const onCreateOpen = () => {
    setIsCreateOpen(true);
  };

  const onCreateClose = async () => {
    setIsCreateOpen(false);
    await loadChannel();
  };

  // -- render part --
  return (
    <Content>
      {!system_state.loading.preloading && (
        <>
          <TopArea>
            <Title text='' />
            <RoundedButton
              text='入力データ作成'
              onClick={onCreateOpen}
              style={{ marginRight: styles.interval_narrow_margin }}
              disabled={isSelected(selected_bodies.length)}
            />
          </TopArea>
          <BottomArea>
            {table_bodies !== undefined ? (
              <BaseTable
                bodies={table_bodies}
                headers={headers}
                table_name='channelsInputStreams'
                selected_bodies={selected_bodies}
                handleCheckClick={handleClick}
                id_abridgement
                footer_option={{
                  text: '>チェックした入力データを削除する',
                  handleClick: handleDeleteClick,
                  disabled: !isSelectedAndActive(selected_bodies),
                }}
              />
            ) : (
              <Spinner />
            )}
            {channel && (
              <PfDialog isOpen={is_create_open} onClose={onCreateClose} title='入力データ作成'>
                <Create channel={channel} onClose={onCreateClose} streams={streams} />
              </PfDialog>
            )}
          </BottomArea>
        </>
      )}
    </Content>
  );
};

// -- styled components --

// -- finally export part --
export default ChannelsIdDetailInputStreams;
