import {
  dashboardsIdWidgetsNumMetricsGetAPI,
  DashboardWidget,
  DashboardWidgetMetric,
} from 'user/api/dashboardsWidgets';
import { scaleToWidgetPeriod2, WidgetScaleType } from 'shared/models/WidgetScaleType';
import { FixedDateScaleSettingDialogCloseProps } from 'user/dialogs/FixedDateScaleSettingDialog';
import { WidgetState } from 'user/pages/Dashboards/DetailPage/UsualDashboardWidgets/DashboardWidgetCard';
import { useCallback, useEffect, useMemo } from 'react';
import { dateTimeStringToFeatureDate } from 'shared/utils/converter';
import { DateString } from 'shared/models/DateString';
import { isTimeString, TimeString, toTimeString } from 'shared/models/TimeString';
import { getStreamIdSet } from 'user/utils/getStreamIdSet';
import useBasicHooks from './useBasicHooks';
import { convertScale } from 'user/utils/convertScale';
import { MetricStatisticMethod } from 'shared/models/MetricStatisticMethod';
import { CartesianGraphData, RangeType, SymbolType } from 'shared/components/molecules/PfGraph';
import { getDownloadUrlAsCsv } from 'shared/utils/get';
import { dateToYMDHM } from 'shared/utils/converter/date';

export interface UseDateRangeWidgetBaseParams {
  widget: DashboardWidget;
  state: WidgetState;
  hide_scale?: boolean;
  fill_out_of_range_data_with_0?: boolean;
  data?: CartesianGraphData[];
  symbol_types?: SymbolType[];
  range_type?: RangeType;
  onInitMetrics(): void;
  onStateChanged(state: WidgetState): void;
  onMetricsChanged(metrics: DashboardWidgetMetric): void;
  onScaleChanged(scale: WidgetScaleType | null): void;
}
/**
 * DateRangeWidgetBaseで利用するhooks + 関数
 * - 未来のデータはプロットしないように制限しています。(see updateDataPart)
 */
export default function useDateRangeWidgetBase({
  state,
  widget,
  fill_out_of_range_data_with_0,
  hide_scale,
  data,
  symbol_types,
  range_type,
  onScaleChanged,
  onMetricsChanged,
  onInitMetrics,
  onStateChanged,
}: UseDateRangeWidgetBaseParams) {
  const { date_dialog_open, onDateDialogOpen, onDateDialogClose } = useBasicHooks();

  const loadData = useCallback(
    async (params: {
      start: number;
      end: number;
      start_time: TimeString;
      end_time: TimeString;
      scale: WidgetScaleType;
    }) => {
      /*
        表示期間に応じてグラフデータを取得します。
        時：分別で1時間
        日：分別で1日
        週：時別で7日
        月：日別で24ヶ月
    */
      const { start: initial_start, end, start_time, end_time, scale } = params;
      // metricsがない場合やVIDEOPLAYERはreturn
      if (!widget.metrics) return;
      if (!widget.metrics.length) return;
      if (widget.widget_type === 'VIDEOPLAYER') return;
      // データ取得前に、metricsを初期化
      onInitMetrics();
      const start = initial_start;
      // データを取得して一時変数に展開
      const datapointsSet = new Set<number>();
      const metricNames = []; // オリジナルの順序を維持したメトリクス名
      const metricsMap: { [key: string]: { [datapoint: number]: number } } = {};
      const metricsSmMap: { [key: string]: MetricStatisticMethod } = {};
      for (const stream_id of getStreamIdSet(widget.metrics)) {
        let _start = start;
        while (_start < end) {
          const res = await dashboardsIdWidgetsNumMetricsGetAPI({
            dashboard_id: widget.dashboard_id,
            dashboard_widget_number: String(widget.dashboard_widget_number),
            stream_id: stream_id,
            start: String(_start),
            end: String(end),
            // 期間外のデータを0埋めする場合は、その期間を指定する
            start_time: fill_out_of_range_data_with_0 ? start_time : undefined,
            end_time: fill_out_of_range_data_with_0 ? end_time : undefined,
            // scaleを非表示にする場合は'hourで固定'
            period: !hide_scale ? scaleToWidgetPeriod2(scale) || 'hour' : 'hour',
          });
          if (res.status === 200) {
            res.data.datapoints.forEach((value) => datapointsSet.add(value));
            for (const m of res.data.metrics) {
              let values = metricsMap[m.metric_name];
              if (values === undefined) {
                metricNames.push(m.metric_name);
                metricsMap[m.metric_name] = values = {};
                metricsSmMap[m.metric_name] = m.statistic_method;
              }
              res.data.datapoints.forEach((dp, i) => {
                values[dp] = m.data[i];
              });
            }
            _start = res.data.last_datapoint || end;
          } else {
            break;
          }
        }
      }
      // 各ウィジェット用のメトリクスデータ構造に再編
      // (注意) metrics 配列はウィジェット定義のメトリクス名の順序と一致させること
      const datapoints = Array.from(datapointsSet).sort((a, b) => a - b);
      const data: DashboardWidgetMetric = {
        datapoints: datapoints,
        metrics: [],
      };
      for (const metric_name of metricNames) {
        const values = metricsMap[metric_name];
        const _data = datapoints.map((dp) => values[dp]);
        data.metrics.push({
          metric_name: metric_name,
          statistic_method: metricsSmMap[metric_name],
          data: _data,
        });
      }
      // 期間が「日」「月」の場合は取得データを変換する
      if (scale === 'DAY' || scale === 'MONTH') {
        convertScale(data, scale);
      }
      onMetricsChanged(data);
    },
    [widget, onInitMetrics, onMetricsChanged, fill_out_of_range_data_with_0, hide_scale],
  );
  const loadDataByDateTimeString = useCallback(
    async (params: {
      start_date: DateString | null;
      end_date: DateString | null;
      start_time: TimeString;
      end_time: TimeString;
      scale: WidgetScaleType;
    }) => {
      const { start_date, end_date, start_time, end_time, scale } = params;
      const start = dateTimeStringToFeatureDate(start_date, start_time).getTime(false);
      const end = dateTimeStringToFeatureDate(end_date, end_time, true).getTime(true);
      await loadData({ start, end, start_time, end_time, scale });
    },
    [loadData],
  );
  const handleDateUpdated = useCallback(
    async ({
      is_canceled,
      start_date,
      end_date,
      start_time = toTimeString('00:00'),
      end_time = toTimeString('23:59'),
      scale,
    }: FixedDateScaleSettingDialogCloseProps) => {
      if (!is_canceled) {
        const newDefaults = { ...state };
        newDefaults.start_date = start_date;
        newDefaults.end_date = end_date;
        if (isTimeString(start_time)) {
          newDefaults.start_time = start_time;
        }
        if (isTimeString(end_time)) {
          newDefaults.end_time = end_time;
        }

        // nullの場合もscaleに設定
        newDefaults.scale = scale;
        onScaleChanged(scale);
        onStateChanged(newDefaults);
      }
      onDateDialogClose();
    },
    [onDateDialogClose, state, onScaleChanged, onStateChanged],
  );
  const convertDate = (unix: number) => {
    const dt = new Date(unix * 1000);
    return dateToYMDHM(dt);
  };
  const csv_data_download_url = useMemo(() => {
    // グラフの出力データを2次元配列に変換
    if (!(data && symbol_types && range_type)) return;
    const csv_data = [];
    // ヘッダー部分の格納
    const header = [''];
    for (let i = 0; symbol_types.length > i; i++) {
      header.push(symbol_types[i].name);
    }
    csv_data.push(header);
    // データ部分の格納
    for (let j = 0; data.length > j; j++) {
      const datapoint = data[j].datapoint as number;
      const date = convertDate(datapoint);
      const res: (string | number)[] = [date];
      for (let k = 0; symbol_types.length > k; k++) {
        const data_name = symbol_types[k].dataKey;
        const res_data = data[j][data_name];
        res.push(res_data ? res_data : 0);
      }
      csv_data.push(res);
    }
    // 2次元配列からダウンロード用URLを取得
    return getDownloadUrlAsCsv(csv_data);
  }, [data, symbol_types, range_type]);
  // stateの変更に対してデータロードを動作
  useEffect(() => {
    loadDataByDateTimeString({
      start_date: state.start_date,
      end_date: state.end_date,
      start_time: state.start_time,
      end_time: state.end_time,
      scale: state.scale ?? 'HOUR',
    });
  }, [state, loadDataByDateTimeString]);

  return useMemo(() => {
    return {
      date_dialog_open,
      csv_data_download_url,
      onDateDialogOpen,
      handleDateUpdated,
    };
  }, [date_dialog_open, csv_data_download_url, onDateDialogOpen, handleDateUpdated]);
}
