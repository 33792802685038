// -- basic library --
import React from 'react';
import process_image from 'assets/process_panel_icon.png';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import { normal_process_texts, process_images } from 'shared/utils/constants';
import styled from 'styled-components';
import { Process } from 'user/api/processes';
import { ProcessFlow } from 'user/api/processflows';

// プロセスパネル１枚
export class ProcessPanel extends React.Component<{
  id: string;
  selected_id: string;
  img_src?: string;
  text: string;
  onClick: (id: string) => void;
}> {
  public render() {
    return (
      <WholeArea
        onClick={() => this.props.onClick(this.props.id)}
        selected={this.props.selected_id === this.props.id}
        key={this.props.id}
      >
        <Img src={this.props.img_src ? this.props.img_src : process_image} />
        <Text title={this.props.text}>{this.props.text}</Text>
      </WholeArea>
    );
  }
}
const WholeArea = styled.div<{
  selected?: boolean;
}>`
  width: 160px;
  min-width: 160px;
  height: 160px;
  border: ${(params) =>
    params.selected ? `4px solid ${colors.component_main_color}` : `1px solid ${colors.component_small_border_color}`};
  margin-right: ${styles.interval_margin};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Img = styled.img`
  width: 70px;
  height: auto;
`;

const Text = styled.div`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: ${styles.normal_text_size};
`;

/*** プロセスを設定するパネル***/

interface ProcessPanelsParams {
  processes: Process[];
  selected_id: string;
  onClick: (process: Process) => void;
  style?: React.CSSProperties;
}

export const ProcessPanels: React.FC<ProcessPanelsParams> = (params) => {
  // -- render part --
  return (
    <ProcessPanelWholeArea style={params.style}>
      {params.processes.map((d, i) => {
        const exist_number = normal_process_texts.indexOf(d.process_name);
        if (exist_number >= 0 && exist_number < normal_process_texts.length) {
          return (
            <ProcessPanel
              id={d.process_id}
              text={normal_process_texts[exist_number]}
              img_src={process_images[exist_number]}
              onClick={() => params.onClick(d)}
              selected_id={params.selected_id}
              key={i}
            />
          );
        }
        return (
          <ProcessPanel
            id={d.process_id}
            text={d.process_name}
            onClick={() => params.onClick(d)}
            selected_id={params.selected_id}
            key={i}
          />
        );
      })}
    </ProcessPanelWholeArea>
  );
};

/*** プロセスフローを設定するパネル***/

interface ProcessFlowPanelsParams {
  processflows: ProcessFlow[];
  selected_id: string;
  onClick: (processflow: ProcessFlow) => void;
  style?: React.CSSProperties;
}

export const ProcessFlowPanels: React.FC<ProcessFlowPanelsParams> = (params) => {
  // -- render part --
  return (
    <ProcessPanelWholeArea style={params.style}>
      {params.processflows.map((d, i) => {
        const exist_number = normal_process_texts.indexOf(d.process_flow_id);
        if (exist_number >= 0 && exist_number < normal_process_texts.length) {
          return (
            <ProcessPanel
              id={d.process_flow_id}
              text={normal_process_texts[exist_number]}
              img_src={process_images[exist_number]}
              onClick={() => params.onClick(d)}
              selected_id={params.selected_id}
              key={i}
            />
          );
        }
        return (
          <ProcessPanel
            id={d.process_flow_id}
            text={d.process_flow_name}
            onClick={() => params.onClick(d)}
            selected_id={params.selected_id}
            key={i}
          />
        );
      })}
    </ProcessPanelWholeArea>
  );
};

// -- styled components --

// プロセスパネルの全体領域
const ProcessPanelWholeArea = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: scroll;
  display: flex;
  flex-direction: flex;
`;

// -- finally export part --

export default ProcessPanels;
