// -- basic library --
import React, { useState } from 'react';

// -- https --

// -- external components --
import InputBox from 'shared/components/atoms/InputBox';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Content, Footer } from 'shared/components/molecules/ContentsArea';

// -- external functions --
import InputComponent from 'shared/components/molecules/InputComponent';
import { isNotOnlySpace } from 'shared/utils/is';
import { Channel, channelsIdPutAPI } from 'user/api/channels';

// -- external datas --

// -- type declaration --
type Param = {
  isOpen: boolean;
  channel: Channel;
  onCancelClose: () => void;
  onOkClose: () => void;
};

// -- main component --

const DetailFileProcessesDialog: React.FC<Param> = (params) => {
  // -- local states --
  const [folder_name, setFolderName] = useState<string>(params.channel.channel_name || '');

  // -- checkers --

  // -- get datas --

  // -- handlers --
  const handleFinishClick = async (inputs: { channel_id: string; folder_name: string }) => {
    if (!isNotOnlySpace(inputs.folder_name)) {
      AlertDialog.show('フォルダ名は必須です');
      return;
    }

    const res = await channelsIdPutAPI({
      channel_id: inputs.channel_id,
      channel_name: inputs.folder_name,
    });
    if (res.status === 200) {
      AlertDialog.show('フォルダ名の更新に成功しました');
      params.onOkClose();
    }
  };

  // -- render part --
  return (
    <PfDialog isOpen={params.isOpen} title='フォルダ詳細' onClose={params.onCancelClose}>
      <Content>
        {params.channel !== undefined ? (
          <>
            <InputComponent text='フォルダ名' required>
              <InputBox
                title='フォルダ名'
                placeholder='入力してください'
                value={folder_name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFolderName(e.currentTarget.value)}
              />
            </InputComponent>
            <InputComponent text='チャンネルID'>
              <div>{params.channel.channel_id}</div>
            </InputComponent>
            <InputComponent text='データID'>
              <div>{params.channel.input_stream_ids[0]}</div>
            </InputComponent>
            <Footer>
              <RoundedButton
                onClick={() =>
                  handleFinishClick({
                    channel_id: params.channel.channel_id,
                    folder_name: folder_name,
                  })
                }
                text_type='UPDATE'
              />
            </Footer>
          </>
        ) : (
          <Spinner />
        )}
      </Content>
    </PfDialog>
  );
};

// -- styled components --

// -- finally export part --

export default DetailFileProcessesDialog;
