// -- basic library --
import React, { useState } from 'react';

// -- redux library --

// -- http connection library --

// -- external components --

// -- external functions --
import PfDialog from 'shared/components/atoms/PfDialog';
import PageSwitchingTabs from 'shared/components/molecules/PageSwitchingTabs';
import { CachedProcessFlowInfos, CachedProcessFlows } from 'user/api/processflows';
import { CachedStreams, Stream } from 'user/api/streams';
import ProcessFlowPanel from './ProcessFlowPanel';

// -- types --

interface Params {
  channel_id: string;
  stream: Stream;
  stream_data_number: string | number;
  isOpen?: boolean;
  onClose: (p?: { isCanceled?: boolean }) => void;
}

// -- main component --
const CreateFileProcessIdDetailProcessDialog: React.FC<Params> = (params) => {
  // -- local states --
  const [tab_id, setTabId] = useState<string>('process-flow');
  // このページ配下で取得するデータは、ここで取得する。(なぜならば、タブを切り替えた時に、いちいち取得しなきゃいけなくなるから)
  const [cachedProcessFlowInfos] = useState<CachedProcessFlowInfos>(new CachedProcessFlowInfos());
  const [cachedStreams] = useState<CachedStreams>(
    new CachedStreams({
      with_output_streams: 'True',
      data_types: 'VIDEO,IMAGE',
      data_number_types: 'SEQUENCE',
    }),
  );
  const [cachedProcessFlows] = useState<CachedProcessFlows>(new CachedProcessFlows({}));

  const handleTabsClick = (tab_id: string) => {
    setTabId(tab_id);
  };

  const getDisplayDatas = () => {
    const display_datas = [
      {
        id: 'process-flow',
        text: 'プロセスフロー',
      },
    ];
    return display_datas;
  };

  // -- render part --
  return (
    <PfDialog
      title='プロセス作成'
      isOpen={params.isOpen === undefined ? true : params.isOpen}
      onClose={() =>
        params.onClose({
          isCanceled: true,
        })
      }
    >
      <div>
        <PageSwitchingTabs
          display_datas={getDisplayDatas()}
          handleClick={handleTabsClick}
          selected_id={tab_id}
          initial_selected_id='process-flow'
        />
        <ProcessFlowPanel
          {...params}
          cachedProcessflowInfos={cachedProcessFlowInfos}
          cachedStreams={cachedStreams}
          cachedProcessFlows={cachedProcessFlows}
        />
      </div>
    </PfDialog>
  );
};

// -- styled components --

// -- finally export part --

export default CreateFileProcessIdDetailProcessDialog;
