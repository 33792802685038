// -- basic library --
import React, { useMemo, useState } from 'react';
import Spinner from 'shared/components/atoms/Spinner';
import { PageWholeArea, Title, TopArea, BottomArea } from 'shared/components/molecules/ContentsArea';
import NotificationTable, { TableNotificationType } from './NotificationTable';
import DetailNotifications from '../DetailDialog/DetailNotificatinos';
import { dateToYMD } from 'shared/utils/converter/date';
import { useNotificationsCheck } from 'user/hooks/useNotificationsCheck/useNotificationsCheck';
import { colors } from 'shared/styles/colors';
import { checkToJP } from 'shared/models/Check';
import { useNotifications } from 'user/hooks/useNotifications/useNotifications';

// -- main component --
const Notifications: React.FC = () => {
  // -- local states --
  const [selected_bodies, setSelectedBodies] = useState<TableNotificationType[]>([]);
  const { notifications } = useNotifications({});
  const { notifications_check, loadNotificationsCheck } = useNotificationsCheck({});

  // 詳細用states
  const [open_detail_dialog, setOpenDetailDialog] = useState<boolean>(false);
  const [notification_id, setNotificationId] = useState<string>('');
  const [check, setCheck] = useState<boolean | undefined>();

  // お知らせ詳細画面に遷移する関数
  const handleDetailClick = (notification_id: string, check?: boolean) => {
    setNotificationId(notification_id);
    setCheck(check);
    setOpenDetailDialog(true);
  };

  // テーブルのチェックをする関数
  const handleCheckClick = (bodies: TableNotificationType[]) => {
    setSelectedBodies(bodies);
  };

  // テーブルデータを設定する関数
  const table_bodies = useMemo(() => {
    return notifications?.map((item) => {
      const check = notifications_check?.some((nc) => nc.notification_id === item.notification_id);
      const check_jp = checkToJP(check);
      return {
        id: item.notification_id,
        show_date: {
          value: dateToYMD(item.show_date),
          onClick: () => handleDetailClick(item.notification_id, check),
        },
        title: item.title,
        check: {
          value: check_jp,
          available_value: check_jp,
          displayable_value: <div style={{ color: check ? colors.main_font_color : colors.red }}>{check_jp}</div>,
        },
      };
    });
  }, [notifications, notifications_check]);

  // 詳細ダイアログを閉じる関数
  const handleDetailDialogCloseClick = async (canceled?: boolean) => {
    setOpenDetailDialog(false);
    if (canceled) return;
    await loadNotificationsCheck();
  };

  // -- render part --
  return (
    <PageWholeArea data-testid='Notification'>
      <TopArea>
        <Title text='お知らせ一覧' />
      </TopArea>
      <BottomArea>
        {table_bodies !== undefined ? (
          <NotificationTable
            bodies={table_bodies}
            selected_bodies={selected_bodies}
            handleCheckClick={handleCheckClick}
          />
        ) : (
          <Spinner />
        )}

        {open_detail_dialog && (
          <DetailNotifications
            notification_id={notification_id}
            check={check}
            is_open={open_detail_dialog}
            handleDialogCloseClick={handleDetailDialogCloseClick}
          />
        )}
      </BottomArea>
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default Notifications;
