export interface Rect {
  left: number;
  top: number;
  right: number;
  bottom: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isRect = (data: any): data is Rect =>
  data instanceof Object &&
  typeof data.left === 'number' &&
  typeof data.top === 'number' &&
  typeof data.right === 'number' &&
  typeof data.bottom === 'number';
