import { PolarGraphData } from 'shared/components/molecules/PfGraph';

/** 2つのPolarGraphData[]を、同じkeyを足し算して返却する**/
const mergePolarGraphs = (polar_graphs1: PolarGraphData[], polar_graphs2: PolarGraphData[]) => {
  const new_polar_graph_object: Record<string, number> = {};
  for (const pg1 of polar_graphs1) {
    const value = typeof pg1.value === 'number' ? pg1.value : 0;
    new_polar_graph_object[pg1.name] = value;
  }
  for (const pg2 of polar_graphs2) {
    const value: number = typeof pg2.value === 'number' ? pg2.value : 0;
    if (typeof new_polar_graph_object[pg2.name] !== 'number') {
      new_polar_graph_object[pg2.name] = 0;
    }
    new_polar_graph_object[pg2.name] += value;
  }
  const new_polar_graphs: PolarGraphData[] = [];
  for (const [key, value] of Object.entries(new_polar_graph_object)) {
    new_polar_graphs.push({
      name: key,
      value: value,
    });
  }
  return new_polar_graphs;
};

export default mergePolarGraphs;
