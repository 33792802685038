/** チャンネルプロセスのプロセス処理の状態 */
export type ProcessingStateType = 'PROVISIONING' | 'STARTED' | 'PROCESSING' | 'WAITING' | 'FINISHED';
export const ProcessingStateTypes: ProcessingStateType[] = [
  'PROVISIONING',
  'STARTED',
  'PROCESSING',
  'WAITING',
  'FINISHED',
];
export const ProcessingStateJpTypes: string[] = ['準備中', '開始済', '処理中', '待機中', '終了済'];
export function convertProcessingStateTypes(s?: string) {
  for (const v of ProcessingStateTypes) if (v === s) return s;
  return ProcessingStateTypes[0];
}
export function convertProcessingStateJpTypes(s?: string) {
  const i = ProcessingStateTypes.indexOf(s as ProcessingStateType);
  return i !== -1 ? ProcessingStateJpTypes[i] : null;
}
