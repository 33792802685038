// -- basic library --
import React, { useCallback, useEffect, useState } from 'react';
import history from 'shared/browserHistory';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { PageWholeArea, Title, TopArea, BottomArea } from 'shared/components/molecules/ContentsArea';
import { isNotSelected } from 'shared/utils/is';
import { CachedRoles, Role, rolesIdDeleteAPI } from 'user/api/roles';
import { loadWrapperFunc } from 'user/utils/loadWrapperFunc';
import CreateRoles from '../CreateRolesDialog/CreateRoles';
import DetailRoles from '../DetailRolesDialog/DetailRoles';
import EditRoles from '../EditRolesDialog/EditRoles';
import { TableRoleType } from './RolesTable';
import RolesTable from './RolesTable';
import { useDashboards } from 'user/hooks/useDashboards/useDashboards';
import { useStreams } from 'user/hooks/useStreams/useStreams';

// -- main component --
const Roles: React.FC = () => {
  // -- local states --
  const [table_bodies, setTableBodies] = useState<TableRoleType[] | undefined>(undefined);
  const [selected_bodies, setSelectedBodies] = useState<TableRoleType[]>([]);
  const { dashboards } = useDashboards();
  const { streams } = useStreams({ with_output_streams: 'True', with_in_channel_streams: 'True' });

  // 作成用states
  const [open_create_dialog, setOpenCreateDialog] = useState<boolean>(false);
  // 詳細用states
  const [open_detail_dialog, setOpenDetailDialog] = useState<boolean>(false);
  // 編集用states
  const [open_edit_dialog, setOpenEditDialog] = useState<boolean>(false);
  const [role_id, setRoleId] = useState<string>('');

  // ロールを作成するダイアログを出す
  const handleCreateClick = () => {
    setOpenCreateDialog(true);
  };

  // ロール詳細画面に遷移する関数
  const handleDetailClick = (role_id: string) => {
    setRoleId(role_id);
    setOpenDetailDialog(true);
  };

  // ロール編集画面に遷移する関数
  const handleEditDialogOpenClick = (role_id: string) => {
    setRoleId(role_id);
    setOpenDetailDialog(false);
    setOpenEditDialog(true);
  };

  const handleDeleteClick = async (selected_bodies: TableRoleType[]) => {
    ConfirmDialog.show(
      <div>
        [確認]選択されているロールを削除します。
        <br />
        本当によろしいですか？
      </div>,
      () => handleOKClick(selected_bodies),
      handleCancelClick,
      undefined,
    );
  };

  // ロールを削除する関数
  const handleOKClick = async (selected_datas: TableRoleType[]) => {
    // 削除関数定義
    const deletes = async () => {
      await Promise.all(
        selected_datas.map((sd) =>
          rolesIdDeleteAPI({
            role_id: sd.id,
          }),
        ),
      );
      await loadTableBodies();
      setSelectedBodies([]);
    };
    // 実行
    await loadWrapperFunc(deletes);
  };

  const handleCancelClick = () => {
    history.push('/roles');
  };

  // テーブルのチェックをする関数
  const handleCheckClick = (bodies: TableRoleType[]) => {
    setSelectedBodies(bodies);
  };

  // データを取得する関数
  const loadTableBodies = useCallback(async () => {
    // [TODO] APIを必要に応じた回数だけ叩く
    // 暫定仕様で、可能な限りデータを取得している
    const role_items: Role[] = await new CachedRoles({}).get();

    const return_table_bodies: TableRoleType[] = [];
    for (let i = 0; i < role_items.length; i++) {
      const return_table_body: TableRoleType = {
        id: role_items[i].role_id,
        role_id: {
          value: role_items[i].role_id,
          onClick: () => handleDetailClick(role_items[i].role_id),
        },
        role_name: role_items[i].role_name,
      };
      return_table_bodies.push(return_table_body);
    }
    setTableBodies(return_table_bodies);
  }, []);

  // 作成用関数
  // 作成ダイアログを閉じる関数
  const handleCreateDialogCloseClick = async (canceled?: boolean) => {
    setOpenCreateDialog(false);
    if (canceled) return;
    await loadTableBodies();
  };

  // 詳細用関数
  // 詳細ダイアログを閉じる関数
  const handleDetailDialogCloseClick = async (canceled?: boolean) => {
    setOpenDetailDialog(false);
    if (canceled) return;
    await loadTableBodies();
  };

  // 編集用関数
  // 編集ダイアログを閉じる関数
  const handleEditDialogCloseClick = async (canceled?: boolean) => {
    setOpenEditDialog(false);
    if (canceled) return;
    await loadTableBodies();
  };

  // -- onload function --
  useEffect(() => {
    loadTableBodies();
  }, [loadTableBodies]);

  // -- render part --
  return (
    <PageWholeArea data-testid='Role'>
      <TopArea>
        <Title text='ロール一覧' />
        <RoundedButton text='ロール作成' onClick={handleCreateClick} />
      </TopArea>
      <BottomArea>
        {table_bodies !== undefined ? (
          <RolesTable
            datas={table_bodies}
            selected_datas={selected_bodies}
            handleCheckClick={handleCheckClick}
            footer_option={{
              text: '＞チェックしたロールを削除する',
              handleClick: () => handleDeleteClick(selected_bodies),
              disabled: isNotSelected(selected_bodies.length),
            }}
          />
        ) : (
          <Spinner />
        )}
        {open_create_dialog && (
          <CreateRoles
            handleDialogCloseClick={handleCreateDialogCloseClick}
            dashboards={dashboards}
            streams={streams}
            is_open={open_create_dialog}
          />
        )}

        {open_detail_dialog && role_id && (
          <DetailRoles
            is_open={open_detail_dialog}
            handleDialogCloseClick={handleDetailDialogCloseClick}
            handleEditDialogOpenClick={(role_id) => handleEditDialogOpenClick(role_id)}
            role_id={role_id}
            dashboards={dashboards}
            streams={streams}
          />
        )}
        {open_edit_dialog && role_id && (
          <EditRoles
            is_open={open_edit_dialog}
            handleDialogCloseClick={handleEditDialogCloseClick}
            role_id={role_id}
            dashboards={dashboards}
            streams={streams}
          />
        )}
      </BottomArea>
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default Roles;
