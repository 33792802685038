import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import InputBox from 'shared/components/atoms/InputBox';
import InputNumberBox from 'shared/components/atoms/InputNumberBox';
import SelectBox from 'shared/components/atoms/SelectBox';
import { PageWholeArea } from 'shared/components/molecules/ContentsArea';
import { DetectionClassesForm } from 'shared/components/molecules/DetectionClassesForm';
import InputComponent from 'shared/components/molecules/InputComponent';
import styled from 'styled-components';
import { IDETECTION } from 'user/components/Config';

interface DetectionTabProps {
  DETECTION: IDETECTION;
  onChange: (value: IDETECTION) => void;
}
export function DetectionTab(params: DetectionTabProps) {
  const handleOnChange = (value: IDETECTION) => {
    params.onChange({ ...params.DETECTION, ...value });
  };
  const handleCorpOnChange = (value: string) => {
    setCorpValue(value);
    let crop = undefined;
    try {
      crop = JSON.parse(value).map(Number);
      if (!Array.isArray(crop) || crop.some(isNaN)) {
        throw new Error('Invalid input');
      }
    } catch (error) {
      crop = undefined;
    }
    if (crop) {
      handleOnChange({ CROP: crop });
    }
  };
  const [crop_value, setCorpValue] = useState<string>(JSON.stringify(params.DETECTION.CROP) ?? [0, 0, 1, 1]);
  return (
    <PageWholeArea data-testid='AISetting_Detection'>
      <InputComponent text='検知クラス' style={{ width: 750 }}>
        <DetectionClassesForm
          DET_CLASSES={params.DETECTION.DET_CLASSES || []}
          onChange={(DET_CLASSES) => handleOnChange({ DET_CLASSES: DET_CLASSES })}
        />
        <div style={{ margin: '10px 0px' }}>AI検知を行うクラスを選択します</div>
      </InputComponent>
      <CustomInputComponent text='クロップ座標'>
        <InputBox
          title='クロップ座標'
          value={String(crop_value)}
          onChange={(e) => {
            handleCorpOnChange(e.currentTarget.value);
          }}
        />
        <div>※[0,0,1,1] の形式で入力してください</div>
      </CustomInputComponent>
      <CustomInputComponent text='クロップ方式'>
        <SelectBox
          onChange={(e) => handleOnChange({ CROP_MODE: e.currentTarget.value })}
          value={params.DETECTION.CROP_MODE ?? 'TRIM'}
          datas={[
            { name: 'トリミング', value: 'TRIM' },
            { name: '領域外フィル', value: 'FILL' },
          ]}
          long
        />
        <div>クロップの処理方式を指定します。</div>
      </CustomInputComponent>
      <CustomInputComponent text='検知モデル閾値'>
        <InputNumberBox
          value={params.DETECTION.CONFIDENCE_THRESHOLD ?? 0}
          onChange={(value) => handleOnChange({ CONFIDENCE_THRESHOLD: Number(value) })}
          min={0}
          max={1}
        />
        <div>0~1の小数で入力してください</div>
      </CustomInputComponent>
      <InputComponent text='除外ボックス'>
        <Grid>
          <Grid item xs={4}>
            最小ボックスサイズ
          </Grid>
          <DetectionRatioGridItems
            ratio={params.DETECTION.MIN_DETECTION_RATIO}
            default={[0, 0]}
            onChange={(value) => handleOnChange({ MIN_DETECTION_RATIO: value })}
          />
          <Grid item xs={4}>
            最大ボックスサイズ
          </Grid>
          <DetectionRatioGridItems
            ratio={params.DETECTION.MAX_DETECTION_RATIO}
            default={[1, 1]}
            onChange={(value) => handleOnChange({ MAX_DETECTION_RATIO: value })}
          />
        </Grid>
        <div>
          これらの設定値を下回る、または上回るサイズの検知オブジェクトは返されません。レートは映像入力の入力解像度を基準とした比率で入力します。
        </div>
      </InputComponent>
    </PageWholeArea>
  );
}

type NumberInputRangeProps = {
  value: number;
  onIncrement: () => void;
  onDecrement: () => void;
  onChange: (value: number) => void;
  unit: string;
};

function NumberInputRange(props: NumberInputRangeProps) {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (!isNaN(value)) {
      props.onChange(value);
    }
  };

  return (
    <div>
      <button onClick={props.onDecrement}>-</button>
      <input type='number' value={props.value.toString()} onChange={handleInputChange} style={{ width: 50 }} />
      <button onClick={props.onIncrement}>+</button>
      {props.unit}
    </div>
  );
}

type DetectionRatioGridItemsProps = {
  ratio?: number[];
  default: number[];
  onChange: (value: number[]) => void;
};

function DetectionRatioGridItems(props: DetectionRatioGridItemsProps) {
  const [horizontal, setHorizontal] = useState(props.ratio ? props.ratio[0] : props.default[0]);
  const [vertical, setVertical] = useState(props.ratio ? props.ratio[1] : props.default[1]);

  const handleChange = (horizontal: number, vertical: number) => {
    props.onChange([normalize(horizontal), normalize(vertical)]);
  };

  const handleHorizontalChange = (value: number) => {
    setHorizontal(value);
    handleChange(value, vertical);
  };

  const handleVerticalChange = (value: number) => {
    setVertical(value);
    handleChange(horizontal, value);
  };

  return (
    <Grid>
      <Grid item xs={4}>
        <div>
          <InputComponent text='横'>
            <NumberInputRange
              value={Math.round(horizontal * 100)}
              onIncrement={() => handleHorizontalChange(horizontal + 0.01)}
              onDecrement={() => handleHorizontalChange(horizontal - 0.01)}
              onChange={(value) => handleHorizontalChange(Number(value) / 100)}
              unit='%'
            />
          </InputComponent>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div>
          <InputComponent text='縦'>
            <NumberInputRange
              value={Math.round(vertical * 100)}
              onIncrement={() => handleVerticalChange(vertical + 0.01)}
              onDecrement={() => handleVerticalChange(vertical - 0.01)}
              onChange={(value) => handleVerticalChange(Number(value) / 100)}
              unit='%'
            />
          </InputComponent>
        </div>
      </Grid>
    </Grid>
  );
}

function normalize(value: number) {
  if (value < 0) {
    return 0;
  } else if (1 < value) {
    return 1;
  } else {
    return Math.round(value * 100) / 100;
  }
}

// -- styled components --
const CustomInputComponent = styled(InputComponent)`
  width: 300px;
`;
