import React, { useEffect, useState } from 'react';
import history from 'shared/browserHistory';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { BottomArea, Title, TopArea, PageWholeArea } from 'shared/components/molecules/ContentsArea';
import PageSwitchingTabs from 'shared/components/molecules/PageSwitchingTabs';
import { getMessageField } from 'shared/utils/get';
import { AndroidDevice, androidDevicesGetAPI, androidDevicesIdDeleteAPI } from 'user/api/androidDevices';
import { preloadGroup } from 'user/utils/preloadGroup';
import AndroidDevicesTable from './AndroidDevicesTable';

/**
 * Androidデバイス一覧ページ
 */
const AndroidDevices: React.FC = () => {
  // -- local states --
  const [android_devices, setAndroidDevices] = useState<AndroidDevice[] | undefined>(undefined);

  const getDatas = async () => {
    // [TODO] APIを必要に応じた回数だけ叩く
    // 暫定仕様で、可能な限りデータを取得している
    let items: AndroidDevice[] = [];
    let has_next = true;
    let exclusive_start_key = '';
    while (has_next) {
      const res = await androidDevicesGetAPI({
        exclusive_start_android_device_id: exclusive_start_key,
      });
      has_next = false;
      if (res.status === 200) {
        items = items.concat(res.data.items);
        has_next = res.data.has_next;
        exclusive_start_key = res.data.items.map((d) => d.android_device_id).reduce((a, b) => (a > b ? a : b), '');
      }
    }
    setAndroidDevices(items);
  };

  const handleAndroidDeviceDelete = (android_device: AndroidDevice) => {
    ConfirmDialog.show(
      `デバイス ${
        android_device.android_device_name || ''
      } を削除して良いですか？\n\nデバイスを削除した場合でも、アプリからのデバイストークンの更新が行われると再表示されます。`,
      () => deleteAndroidDevice(android_device),
    );
  };

  /** Androidデバイスを削除するAPIをコールします。 */
  const deleteAndroidDevice = (android_device: AndroidDevice) => {
    androidDevicesIdDeleteAPI({ android_device_id: android_device.android_device_id }).then((res) => {
      if (res.status === 200) {
        AlertDialog.show('削除に成功しました。');
      } else {
        AlertDialog.show('削除に失敗しました。\n\n(' + getMessageField(res.data) + ')');
      }
      // データを再取得
      getDatas();
    });
  };
  // -- functions --
  const handleTabsClick = (id: string) => {
    switch (id) {
      case 'soracom':
        history.push(`/devices`);
        break;
      case 'counterBox':
        history.push(`/edge/devices`);
        break;
      case 'android':
        history.push(`/android/devices`);
        break;
    }
  };

  // -- onload function --
  useEffect(() => {
    preloadGroup(getDatas());
  }, []);

  // -- render part --
  return (
    <PageWholeArea data-testid='AndroidDevices'>
      <TopArea>
        <Title text='Androidデバイス' />
      </TopArea>
      <PageSwitchingTabs
        display_datas={[
          { id: 'soracom', text: 'SORACOMデバイス' },
          { id: 'counterBox', text: 'Counterboxデバイス' },
          { id: 'android', text: 'Androidデバイス' },
        ]}
        handleClick={(id: string) => handleTabsClick(id)}
        selected_id='android'
        initial_selected_id='android'
      />
      {android_devices === undefined ? (
        <Spinner />
      ) : (
        <BottomArea>
          <AndroidDevicesTable android_devices={android_devices} onDelete={handleAndroidDeviceDelete} />
        </BottomArea>
      )}
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default AndroidDevices;
