import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import history from 'shared/browserHistory';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import {
  LoginArea,
  LoginBackGround,
  LoginLogo,
  LoginBaseArea,
  LoginNormalText,
} from 'shared/components/molecules/ContentsArea';
import styled from 'styled-components';
import { authSelector } from 'user/redux/slices/authSlice';

// -- main component --

const LoginChangedPassword: React.FC = () => {
  const auth_state = useSelector(authSelector);

  // -- handlers --
  const handleTransitionToLoginClick = () => {
    history.push(`/login`);
  };

  // -- render part --
  return auth_state.is_logged_in ? (
    <Redirect to='/dashboards' />
  ) : (
    <LoginBackGround data-testid='PasswordChangeFinish'>
      <LoginArea>
        <LoginLogoArea>
          <LoginLogo />
        </LoginLogoArea>
        <TextArea>
          <LoginNormalText>パスワードが変更されました</LoginNormalText>
        </TextArea>
        <ButtonArea>
          <RoundedButton text='ログイン画面' onClick={handleTransitionToLoginClick} />
        </ButtonArea>
      </LoginArea>
      <AlertDialog />
    </LoginBackGround>
  );
};

// -- styled components --

const LoginLogoArea = styled(LoginBaseArea)`
  height: 48%;
  justify-content: center;
  align-items: center;
`;

const TextArea = styled(LoginBaseArea)`
  height: 8%;
  justify-content: center;
`;

const ButtonArea = styled(LoginBaseArea)`
  height: 44%;
  justify-content: center;
`;

// -- finally export part --

export default LoginChangedPassword;
