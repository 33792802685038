/** デバイスの出力ストリームにおける、グラフのタイプ。デバイス実機解析画面などで使用 */
export type StreamDeviceGraphType = 'BAR' | 'CIRCLE' | 'TABLE';
export const stream_device_graph_type_record = {
  BAR: 0,
  CIRCLE: 0,
  TABLE: 0,
} as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isStreamDeviceGraphType = (data: any): data is StreamDeviceGraphType =>
  typeof data === 'string' && data in stream_device_graph_type_record;

export const streamDeviceGraphTypeToText = (graph_type?: string) => {
  switch (graph_type) {
    case 'CIRCLE':
      return 'polar';
    case 'BAR':
      return 'cartesian';
    case 'TABLE':
      return 'table';
    default:
      return null;
  }
};
