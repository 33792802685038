import { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy } from 'shared/utils/converter';
import { CachedNotifications, RequestNotificationsGet, Notification } from 'user/api/notifications';

/**
 * お知らせ一覧を取得するcustom hooks
 */
export const useNotifications = ({ status, exclusive_start_notification_id }: RequestNotificationsGet) => {
  const [notifications, setNotifications] = useState<Notification[] | undefined>(undefined);
  const notifications_record: Record<string, Notification> = useMemo(() => {
    return notifications ? keyBy(notifications, (r) => r.notification_id) : {};
  }, [notifications]);
  const loadNotifications = useCallback(
    async (request?: RequestNotificationsGet) => {
      const r = {
        status: request?.status ?? status,
        exclusive_start_notification_id: request?.exclusive_start_notification_id ?? exclusive_start_notification_id,
      };
      const new_notifications = await new CachedNotifications(r).get();
      setNotifications(new_notifications);
    },
    [status, exclusive_start_notification_id],
  );

  useEffect(() => {
    loadNotifications();
  }, [loadNotifications]);

  return useMemo(() => {
    return {
      notifications,
      notifications_record,
      setNotifications,
      loadNotifications,
    };
  }, [notifications, notifications_record, setNotifications, loadNotifications]);
};
