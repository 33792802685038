// -- basic library --
import React, { useState, useEffect, useCallback } from 'react';
import InputBox from 'shared/components/atoms/InputBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Content } from 'shared/components/molecules/ContentsArea';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import { dateToYMDHMS } from 'shared/utils/converter/date';
import { isNotOnlySpace } from 'shared/utils/is';
import styled from 'styled-components';
import { channelsIdEventsChannelEventNumberConfirmPostAPI } from 'user/api/channelsEvents';
import {
  ChannelEventComment,
  channelsIdEventsNumCommentsGetAPI,
  channelsIdEventsNumCommentsPostAPI,
} from 'user/api/channelsEventsComents';

// -- types --
interface Params {
  channel_id: string;
  channel_event_number: number;
  confirmed: boolean;
  onAPIRequested(): void;
}

// -- main component --
const EventsIdDetailsFooter: React.FC<Params> = ({ channel_event_number, channel_id, onAPIRequested, confirmed }) => {
  // -- local states --
  // 既に存在するコメントたち
  const [channel_event_comments, setchannelEventComments] = useState<ChannelEventComment[] | undefined>(undefined);
  // 新しく入力して追加するコメント
  const [adding_comment, setAddingComment] = useState<string>('');

  // -- get datas --
  const getChannelEventComments = useCallback(async () => {
    // [TODO] APIを必要に応じた回数だけ叩く
    // 暫定仕様で、可能な限りデータを取得している
    let items: ChannelEventComment[] = [];
    let has_next = true;
    let start_key = '';
    while (has_next) {
      const res = await channelsIdEventsNumCommentsGetAPI({
        channel_id: channel_id,
        channel_event_number: channel_event_number,
        order_by: 'DESC',
        exclusive_start_channel_event_comment_number: start_key,
      });
      has_next = false;
      if (res.status === 200) {
        items = items.concat(res.data.items);
        has_next = res.data.has_next;
        start_key = res.data.items
          .map((d) => String(d.channel_event_coment_number))
          .reduce((a, b) => (a > b ? a : b), '');
      }
    }
    setchannelEventComments(items);
  }, [channel_id, channel_event_number]);

  // -- handlers --

  const handleConfirmButtonClick = useCallback(async () => {
    const res = await channelsIdEventsChannelEventNumberConfirmPostAPI({
      channel_id: channel_id,
      channel_event_number: channel_event_number,
      api_send_type: 'changeableOneTransmission',
    });
    if (res.status === 200) {
      onAPIRequested();
      AlertDialog.show('確認済みにしました');
    }
  }, [onAPIRequested, channel_id, channel_event_number]);

  const handleCommentButtonClick = useCallback(
    async (comment: string) => {
      if (!isNotOnlySpace(comment)) {
        AlertDialog.show('コメントを残す場合はコメントを入力してください');
        return;
      }
      const res = await channelsIdEventsNumCommentsPostAPI({
        channel_id,
        channel_event_number,
        comment: comment,
      });
      if (res.status === 200) {
        AlertDialog.show('コメントしました');
        setAddingComment('');
        onAPIRequested();
        await getChannelEventComments();
      }
    },
    [onAPIRequested, getChannelEventComments, channel_id, channel_event_number],
  );
  // -- onload function --
  useEffect(() => {
    (async function () {
      await getChannelEventComments();
    })();
  }, [getChannelEventComments]);

  // -- render part --

  return (
    <WholeArea>
      <Coment>コメント</Coment>
      {channel_event_comments === undefined ? (
        <Spinner />
      ) : (
        <TextArea>
          {channel_event_comments.map((d, index) => (
            <RowArea key={index}>
              <Text
                style={{
                  width: 170,
                }}
              >
                {dateToYMDHMS(d.created_at)}
              </Text>
              <Text
                style={{
                  width: 130,
                }}
              >
                {d.user_name}
              </Text>
              <Text>{d.comment}</Text>
            </RowArea>
          ))}
        </TextArea>
      )}
      <InputBox title='コメント' value={adding_comment} onChange={(e) => setAddingComment(e.currentTarget.value)} />
      <RoundedButton
        text='コメントを残す'
        onClick={() => handleCommentButtonClick(adding_comment)}
        style={{ marginTop: styles.interval_margin }}
      />
      <RoundedButton
        text='確認済み'
        onClick={() => handleConfirmButtonClick()}
        style={{ marginTop: styles.interval_margin }}
        disabled={confirmed}
      />
    </WholeArea>
  );
};

// -- styled components --
const WholeArea = styled(Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TextArea = styled.div`
  width: 100%;
  display: block;
  border: 1px solid ${colors.component_small_border_color};
  border-radius: ${styles.border_radius};
  height: 110px;
  overflow-y: scroll;
  margin-bottom: ${styles.interval_margin};
`;

const RowArea = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 5px;
`;

const Coment = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  margin-top: ${styles.interval_margin};
`;

const Text = styled.div`
  margin-right: ${styles.interval_narrow_margin};
`;

// -- finally export part --

export default EventsIdDetailsFooter;
