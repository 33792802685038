import React from 'react';
import { Image as KonvaImage, Layer, Stage } from 'react-konva';

/** ヒートマップ上に表示するデータポイントを表します。 */
export interface HeatmapXY {
  x: number;
  y: number;
}
interface HeatmapStageProps {
  data: HeatmapXY[];
  circleRadius: number;
  width: number;
  height: number;
  scale: number;
  image?: HTMLImageElement;
  onFinishDraw: () => void;
  onUpdateDrawingProgress: (p: number) => void;
}
interface HeatmapStageState {
  canvas: HTMLCanvasElement;
  refreshKey: number;
}
/** ヒートマップを表示するKonvaステージです。 */
export default class HeatmapStage extends React.PureComponent<HeatmapStageProps, HeatmapStageState> {
  constructor(props: HeatmapStageProps) {
    super(props);
    this.state = {
      canvas: document.createElement('canvas'),
      refreshKey: 0
    };
  }
  componentDidMount(): void {
    this.scheduleRedrawCircles();
  }
  componentDidUpdate(prevProps: HeatmapStageProps): void {
    if (prevProps.circleRadius !== this.props.circleRadius || prevProps.data !== this.props.data) {
      this.scheduleRedrawCircles();
    }
  }
  /** ヒートマップ描画タスクを開始します。 */
  private scheduleRedrawCircles() {
    const canvas = this.state.canvas;
    // キャンバスサイズの補正
    if (this.props.image) {
      canvas.width = this.props.image.width
      canvas.height = this.props.image.height
    }
    // 非同期での描画タスクの起動
    const ctx = canvas.getContext('2d');
    if (ctx) {
      setTimeout(() => {
        // 現在の描画内容をクリアします
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        this.redrawTask(ctx, this.props.circleRadius, this.props.data, 0)});
    }
  }
  /**
   * サイクリックに起動されタイムアウトするまでヒートマップを描画するタスクです。
   * タイムアウトすると、次のサイクルのタスクが起動されます。
   * （次のタスクが実行される前にReactの画面描画が割り込まれるため、アニメーション的なユーザー体験が実現されます）
   */
  private redrawTask(ctx: CanvasRenderingContext2D, circleRadius: number, data: HeatmapXY[], start: number) {
    // 描画サイクルの途中で対象データが変更された場合は処理をキャンセルします
    if (circleRadius !== this.props.circleRadius || data !== this.props.data) {
      return;
    }
    const timeout = Date.now() + 100; // 100ms
    let i = 0;
    for (i = start; i < data.length; i++) {
      // draw
      const value = data[i];
      ctx.fillStyle = 'rgba(255, 0, 0, 0.3)';
      ctx.beginPath();
      ctx.arc(value.x, value.y, circleRadius, 0, 2 * Math.PI);
      ctx.fill();
      // timeout check
      if (timeout < Date.now()) {
        const percent = Math.round(i / data.length * 100);
        this.props.onUpdateDrawingProgress(percent);
        setTimeout(() => this.redrawTask(ctx, circleRadius, data, i + 1));
        return;
      }
    }
    // 全ての描画が終わると、プログレスバーを非表示にし、レイヤーを強制描画します
    this.props.onFinishDraw();
    this.setState({refreshKey: this.state.refreshKey + 1});
  }
  render() {
    return (
      <Stage width={this.props.width} height={this.props.height} scale={{ x: this.props.scale, y: this.props.scale }}>
        {this.props.image && (
          <Layer>
            <KonvaImage image={this.props.image} />
          </Layer>
        )}
        <Layer>
          <KonvaImage key={this.state.refreshKey} image={this.state.canvas} />
        </Layer>
      </Stage>
    );
  }
}
