import { CommonAPIRequestType, isCommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';
import { Query } from 'shared/models/Query';
import sendAxios from '../../shared/axios/sendAxios';
import { getClient } from './base';

/*** エンティティ ***/
/** APIの戻り構造体です */
interface OauthState {
  state: string; // Oauth で使用される state
  is_authorized: boolean; // Oauth が完了したかどうか
  code?: string; // Oauth の結果に得られた code
}

const oauth_state1: OauthState = {
  state: 'bcargcba2b8',
  is_authorized: true,
  code: 'ao8rb aybv8',
};

/*** [POST] /api/oauth/state ***/
export interface RequestOauthStatePost extends CommonAPIRequestType {}

export const oauthStatePostAPI = (params: RequestOauthStatePost) => {
  const { disabled_load, disabled_error_message, ended_load } = toAPIRequestParams<RequestOauthStatePost>({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/oauth/state`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (isCommonAPIRequestType(key)) {
      continue;
    }
    form.append(key, value);
  }

  // 送信
  return sendAxios<OauthState>(axios, path, query, form, method, oauth_state1);
};

/*** [GET] /api/oauth/state/{id} ***/
export interface RequestOauthStateIdGet extends CommonAPIRequestType {
  id: string;
}
export const oauthStateIdGetAPI = (params: RequestOauthStateIdGet) => {
  const { id, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/oauth/state/${id}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<OauthState>(axios, path, query, form, method, oauth_state1);
};
