import { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy } from 'shared/utils/converter';
import { CachedRoles, RequestRolesGet, Role } from 'user/api/roles';

/**
 * ロール一覧を取得するcustom hooks
 */
export const useRoles = ({ status, exclusive_start_role_id }: RequestRolesGet) => {
  const [roles, setRoles] = useState<Role[] | undefined>(undefined);
  const roles_record: Record<string, Role> = useMemo(() => {
    return roles ? keyBy(roles, (r) => r.role_id) : {};
  }, [roles]);
  const loadRoles = useCallback(
    async (request?: RequestRolesGet) => {
      const r = request ?? { status, exclusive_start_role_id } ?? {};
      const new_roles = await new CachedRoles(r).get();
      setRoles(new_roles);
    },
    [status, exclusive_start_role_id],
  );

  useEffect(() => {
    loadRoles();
  }, [loadRoles]);

  return useMemo(() => {
    return {
      roles,
      roles_record,
      setRoles,
      loadRoles,
    };
  }, [roles, roles_record, setRoles, loadRoles]);
};
