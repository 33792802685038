import React from 'react';
import PfDialog from 'shared/components/atoms/PfDialog';
import PfHlsPlayer from 'shared/components/atoms/PfHlsPlayer';
import { streamsIdDataNumberM3u8Url } from 'user/api/streamsData';

interface DataThumbnailPlayerDialogProps {
  isOpen: boolean;
  onCloseClick: () => void;
  stream_id: string;
  stream_data_number: number;
}
interface DataThumbnailPlayerDialogStete {
  player_aborted: boolean;
}
export default class DataThumbnailPlayerDialog extends React.PureComponent<
  DataThumbnailPlayerDialogProps,
  DataThumbnailPlayerDialogStete
> {
  private playerRef = React.createRef<HTMLVideoElement>();
  constructor(props: DataThumbnailPlayerDialogProps) {
    super(props);
    this.state = {
      player_aborted: false,
    };
  }
  render() {
    const url = streamsIdDataNumberM3u8Url(this.props.stream_id, this.props.stream_data_number);
    return (
      <PfDialog isOpen={this.props.isOpen} onClose={this.props.onCloseClick} title='ビデオデータの再生'>
        {!this.state.player_aborted && (
          <PfHlsPlayer
            playerRef={this.playerRef}
            url={url}
            width='auto'
            height={370}
            controls={true}
            loop={false}
            muted
          />
        )}
      </PfDialog>
    );
  }
}
