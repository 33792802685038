import { Stream } from 'user/api/streams';
import { StreamPackage } from 'user/api/streamsPackage';

/**
 * プロセスのoutputのMp4がダウンロード可能かどうかを判断する関数
 * **/
export const isProcessOutputMp4Downloadable = (stream: Stream) => {
  return stream && stream.data_type === 'VIDEO' && stream.data_number_type === 'SEQUENCE';
};

/**
 * プロセスのoutputのMp4がダウンロード可能かどうかを判断する関数
 * **/
export const isProcessOutputCSVDownloadable = (stream_package: StreamPackage) => {
  return stream_package && stream_package.package_type === 'CSV';
};

/**
 * プロセスのoutputがダウンロード可能かどうかを判断する関数
 * **/
export const isProcessOutputDownloadable = (downloadable: boolean | null) => {
  // [TODO hase] 現状downloadableがnullの時はダウンロード可能にしているが、(この実装の前に作成されている推論結果に対してはnullとなる)
  // downloadableがnullでなく完全なbooleanになった時は、null判定は不要となる
  return typeof downloadable === 'boolean' ? downloadable : downloadable === null;
};
