// -- basic library --
import React, { useState, useEffect } from 'react';
import pw_off_svg from 'assets/pw_off.svg';
import pw_on_svg from 'assets/pw_on.svg';
import { colors } from 'shared/styles/colors';
import styled from 'styled-components';

// -- type declaration --
interface Params {
  handleChangeClick?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  text: string;
  value: string;
  icon_src: string;
  password?: boolean;
  test_id?: string;
  placeholder?: string;
  minLength?: number;
  disabled?: boolean;
  style?: React.CSSProperties;
}

// -- main component --

const InputBoxWithIcon: React.FC<Params> = (params) => {
  const [reveal, setReveal] = useState(false);
  // -- preparations --

  // -- onload function --
  useEffect(() => {
    setReveal(false);
  }, []); /* eslint-disable-line */

  // -- render part --
  return (
    <InputArea style={params.style}>
      <Input
        password={params.password}
        title={params.text}
        placeholder={params.placeholder}
        type={params.password && reveal === false ? 'password' : 'text'}
        onChange={params.handleChangeClick}
        value={params.value}
      />
      <IconLeft src={params.icon_src} />
      {params.password ? <IconRight src={reveal ? pw_on_svg : pw_off_svg} onClick={() => setReveal(!reveal)} /> : null}
    </InputArea>
  );
};

// -- styled components --

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
`;

const Input = styled.input<{
  password?: boolean;
}>`
  font-family: inherit;
  line-height: 30px;
  box-sizing: border-box;
  width: 100%;
  padding: 0.3em;
  transition: 0.3s;
  border: 1px solid ${colors.component_small_border_color};
  border-radius: 4px;
  outline: none;
  padding-left: 40px;
  padding-right: ${(params) => (params.password ? 40 : 0)}px;
  &:focus {
    border-color: ${colors.selected_border_color};
  }
  &::placeholder {
    color: ${colors.placeholder_color};
  }
`;

const IconLeft = styled.img`
  position: absolute;
  top: calc((40px - 24px) / 2);
  left: calc((40px - 24px) / 2);
  width: 24px;
  height: 24px;
`;

const IconRight = styled.img`
  position: absolute;
  top: calc((40px - 24px) / 2);
  right: calc((40px - 24px) / 2);
  width: 24px;
  height: 24px;
`;

// -- finally export part --

export default InputBoxWithIcon;
