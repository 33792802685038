import React from 'react';
import ContentBodyMat from 'shared/components/atoms/ContentBodyMat';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import { Stream, streamsIdPutAPI } from 'user/api/streams';

interface StreamPublishPanelProps {
  stream: Stream;
  onStreamChange: () => void;
}
export default class StreamPublishPanel extends React.PureComponent<StreamPublishPanelProps> {
  onChangeEdgeboxKey = () => {
    streamsIdPutAPI({
      stream_id: this.props.stream.stream_id,
      is_published: !this.props.stream.is_published ? 'True' : 'False',
    }).then((res) => {
      if (res.status === 200) {
        this.props.onStreamChange();
      }
    });
  };
  render() {
    return (
      <ContentBodyMat>
        <div>ストリームの公開</div>
        <div>
          {this.props.stream.is_published ? '公開中' : '非公開'}
          <RoundedButton text={this.props.stream.is_published ? '非公開' : '公開'} onClick={this.onChangeEdgeboxKey} />
        </div>
        {this.props.stream.is_published && (
          <div>
            <div>公開ID</div>
            <div>{this.props.stream.publish_id}</div>
          </div>
        )}
      </ContentBodyMat>
    );
  }
}
