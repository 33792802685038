import {
  DashboardHeatmapMetric,
  dashboardsIdWidgetsNumMetrics3GetAPI,
  DashboardWidget,
} from 'user/api/dashboardsWidgets';
import { FixedDateScaleSettingDialogCloseProps } from 'user/dialogs/FixedDateScaleSettingDialog';
import { WidgetState } from 'user/pages/Dashboards/DetailPage/UsualDashboardWidgets/DashboardWidgetCard';
import { useCallback, useEffect, useMemo } from 'react';
import { dateTimeStringToFeatureDate } from 'shared/utils/converter';
import FeatureDatetime from 'shared/models/FeatureDatetime';
import { DateString } from 'shared/models/DateString';
import { TimeString, toTimeString } from 'shared/models/TimeString';
import useBasicHooks from './useBasicHooks';

export interface UseDateRangeWidgetBase3Params {
  widget: DashboardWidget;
  state: WidgetState;
  onInitMetrics(): void;
  onStateChanged(state: WidgetState): void;
  onMetricsChanged(metrics: DashboardHeatmapMetric[]): void;
}
/**
 * DateRangeWidgetBase3で利用するhooks + 関数
 * - 未来のデータはプロットしないように制限しています。(see updateDataPart)
 */
export default function useDateRangeWidgetBase3({
  state,
  widget,
  onMetricsChanged,
  onInitMetrics,
  onStateChanged,
}: UseDateRangeWidgetBase3Params) {
  const { date_dialog_open, onDateDialogOpen, onDateDialogClose } = useBasicHooks();
  // APIからデータをロードする
  const loadData = useCallback(
    async (params: { start: number; end: number }) => {
      /*
        表示期間に応じてグラフデータを取得します。
        時：分別で1時間
        日：分別で1日
        週：時別で7日
        月：日別で24ヶ月
    */
      const { start: initial_start, end } = params;
      // データ取得前にmetricsを初期化
      onInitMetrics();
      // データ取得ループ
      const data: DashboardHeatmapMetric[] = [];
      let start = initial_start;
      let exclusive_start_fragment_number = '-1';
      for (;;) {
        const res = await dashboardsIdWidgetsNumMetrics3GetAPI({
          dashboard_id: widget.dashboard_id,
          dashboard_widget_number: String(widget.dashboard_widget_number),
          start: String(start),
          end: String(end),
          exclusive_start_fragment_number: exclusive_start_fragment_number,
        });
        if (res.status === 200) {
          for (const item of res.data.metrics) {
            data.push(item);
          }
          if (res.data.has_next_fragment) {
            exclusive_start_fragment_number = String(res.data.last_fragment_number);
            continue;
          } else if (res.data.has_next) {
            start = res.data.last_data_number + 1;
            continue;
          }
        }
        break;
      }
      onMetricsChanged(data);
    },
    [widget, onInitMetrics, onMetricsChanged],
  );
  const loadDataByDateTimeString = useCallback(
    async (params: {
      start_date: DateString | null;
      end_date: DateString | null;
      start_time: TimeString;
      end_time: TimeString;
    }) => {
      const { start_date, end_date, start_time, end_time } = params;
      const start = dateTimeStringToFeatureDate(start_date, start_time).getTime(false);
      const end = dateTimeStringToFeatureDate(end_date, end_time, true).getTime(true);
      await loadData({ start, end });
    },
    [loadData],
  );
  const handleDateUpdated = useCallback(
    async ({
      is_canceled,
      start_date,
      end_date,
      start_time = toTimeString('00:00'),
      end_time = toTimeString('23:59'),
    }: FixedDateScaleSettingDialogCloseProps) => {
      if (!is_canceled) {
        const newDefaults = { ...state };
        newDefaults.start_date = start_date;
        newDefaults.end_date = end_date;
        newDefaults.start_time = start_time;
        newDefaults.end_time = end_time;
        onStateChanged(newDefaults);
      }
      onDateDialogClose();
    },
    [onDateDialogClose, state, onStateChanged],
  );
  // stateの変更に対してデータロードを動作
  useEffect(() => {
    if (widget.hm_data_number_type === 'SEQUENCE' || widget.hm_data_number_type === 'USER_SPECIFIC') {
      loadData({ start: new FeatureDatetime(0).getTime(false), end: new FeatureDatetime(99999999).getTime(true) });
    } else {
      loadDataByDateTimeString({
        start_date: state.start_date,
        end_date: state.end_date,
        start_time: state.start_time,
        end_time: state.end_time,
      });
    }
  }, [state, loadDataByDateTimeString, widget.hm_data_number_type, loadData]);

  return useMemo(() => {
    return {
      date_dialog_open,
      onDateDialogOpen,
      handleDateUpdated,
    };
  }, [date_dialog_open, onDateDialogOpen, handleDateUpdated]);
}
