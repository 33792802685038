// -- basic library --
import React, { useCallback, useState } from 'react';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import { Content, Footer } from 'shared/components/molecules/ContentsArea';
import DrawCrosslines from 'shared/components/molecules/DrawCrosslines.tsx';
import StepPanel from 'shared/components/molecules/StepPanel';
import TrimPanel from 'shared/components/molecules/TrimPanel';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import { TrimedImage } from 'shared/models';
import styled from 'styled-components';

// -- type declaration --
interface Params {
  imgsrc: string;
  trimed_image?: TrimedImage;
  crosslines: number[][][];
  onCrosslinesChange: (crosslines: number[][][]) => void;
  onTrimedImageChange: (trimed_image: TrimedImage) => void;
  onPanelFinish: () => void;
  onClose: () => void;
}

// -- main component --
/**
 * CrossLine1Dialogの中に差し込むスッテップパネル
 * 画像トリミング -> 検知ライン描画を行う
 * **/
const CrossLineStepPanel: React.FC<Params> = (params) => {
  // -- local states --
  const [step, setStep] = useState<number>(1);
  const onTrimPanelFinish = useCallback(() => {
    setStep(2);
  }, [setStep]);

  // [TODO] TrimePanel内に書いてあるFooterのボタンを本コンポーネント移す。
  // reac-cropperのcrop属性に状態を変更できる関数を常時割り当てれば、
  // 可能であるが、処理が非常に重くなってしまう(おそらく変更する状態や処理の数が多いため)
  // そのため現状は、形の汚い状態のgetElm()になっている。
  const getElm = () => {
    switch (step) {
      case 1:
        return (
          <>
            {params.imgsrc.length > 0 ? (
              <TrimPanel
                {...params}
                onFinish={onTrimPanelFinish}
                onClose={params.onClose}
                onTrimedImageChange={params.onTrimedImageChange}
                setting_exp_imp_button_props={{
                  setCrosslines: params.onCrosslinesChange,
                  crosslines: params.crosslines,
                  hide_export: true,
                  hide_import: false,
                }}
              />
            ) : (
              <Spinner />
            )}
          </>
        );
      case 2:
        return (
          <>
            {params.trimed_image === undefined ? (
              <Spinner />
            ) : (
              <>
                <DrawCrosslines
                  {...params}
                  trimed_image={params.trimed_image}
                  onChange={params.onCrosslinesChange}
                  hideImport={true}
                />
                <Footer
                  style={{
                    marginTop: styles.interval_narrow_margin,
                  }}
                >
                  <RoundedButton
                    text='戻る'
                    onClick={() => setStep(1)}
                    is_white={true}
                    is_margin_right={true}
                    style={{
                      width: styles.small_button_width,
                    }}
                  />
                  <RoundedButton
                    text='完了'
                    onClick={params.onPanelFinish}
                    style={{
                      width: styles.small_button_width,
                    }}
                  />
                </Footer>
              </>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  // -- render part --
  return (
    <NContent>
      <StepPanel texts={['トリミング', '検知ライン引き']} selected_number={step} />
      <ScreenImageArea>{getElm()}</ScreenImageArea>
    </NContent>
  );
};

// -- styled components --

const ScreenImageArea = styled.div`
  width: 100%;
  margin-top: ${styles.interval_margin};
  display: block;
`;

const NContent = styled(Content)`
  // background-color: ${colors.dark_gray};
  display: flex;
  flex-direction: column;
  use-select: none;
`;

// -- finally export part --

export default CrossLineStepPanel;
