import { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy } from 'shared/utils/converter';
import {
  CachedChannelEventConditions,
  RequestChannelsIdEventConditionsGet,
  ChannelEventCondition,
} from 'user/api/channelsEventConditions';

/**
 * チャンネルイベント一覧を取得するcustom hooks
 */
export const useChannelEventConditions = ({
  channel_id,
  exclusive_start_channel_event_condition_number,
}: RequestChannelsIdEventConditionsGet) => {
  const [channel_event_conditions, setChannelEventConditions] = useState<ChannelEventCondition[] | undefined>(
    undefined,
  );
  const channel_event_conditions_record: Record<string, ChannelEventCondition> = useMemo(() => {
    return channel_event_conditions ? keyBy(channel_event_conditions, (c) => c.channel_event_condition_number) : {};
  }, [channel_event_conditions]);
  const loadChannelEventConditions = useCallback(
    async (request?: RequestChannelsIdEventConditionsGet) => {
      const r = request ?? { channel_id, exclusive_start_channel_event_condition_number } ?? {};
      const new_channel_event_conditions = await new CachedChannelEventConditions(r).get();
      setChannelEventConditions(new_channel_event_conditions);
    },
    [channel_id, exclusive_start_channel_event_condition_number],
  );

  useEffect(() => {
    loadChannelEventConditions();
  }, [loadChannelEventConditions]);

  return useMemo(() => {
    return {
      channel_event_conditions,
      channel_event_conditions_record,
      setChannelEventConditions,
      loadChannelEventConditions,
    };
  }, [
    channel_event_conditions,
    channel_event_conditions_record,
    setChannelEventConditions,
    loadChannelEventConditions,
  ]);
};
