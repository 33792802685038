// -- basic library --
import React, { useState, useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import history from 'shared/browserHistory';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { Content, BottomArea, Title, TopArea } from 'shared/components/molecules/ContentsArea';
import StreamText from 'shared/components/molecules/StreamText';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import {
  TableHeaderType,
  TableBodyMultipleValueType,
  TableBodyClickType,
} from 'shared/components/molecules/Table/type';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import { StreamUIType } from 'shared/models/StreamUI';
import { isSelected, isNotSelected } from 'shared/utils/is';
import { streamDataTypeToMedia } from 'shared/utils/converter';
import { channelsIdProcessesChannelProcessNumberDeleteAPI, ChannelProcess } from 'user/api/channelsProcesses';
import { convertProcessingStateJpTypes } from 'shared/models/ProcessingStateType';
import CreateChannelProcessDialog from 'user/dialogs/CreateChannelProcessDialog';
import { loadWrapperFunc } from 'user/utils/loadWrapperFunc';
import DetailChannelProcessDialog from './DetailChannelProcessDialog';
import { useProcesses } from 'user/hooks/useProcesses/useProcesses';
import { useStreams } from 'user/hooks/useStreams/useStreams';
import { useChannelProcesses } from 'user/hooks/useChannelProcesses/useChannelProcesses';

// -- type declaration --

type Params = RouteComponentProps<{ channel_id: string }>;

export type TableChannelProcessType = {
  id: string;
  channel_process_name: TableBodyClickType;
  process_name: string;
  input_streams: TableBodyMultipleValueType<StreamUIType[]>;
  output_streams: TableBodyMultipleValueType<StreamUIType[]>;
  process_id: string;
  processing_state: TableBodyMultipleValueType<string>;
};

const generateTableStreamsDisplayableValue = (table_streams: StreamUIType[]) => {
  return (
    <div>
      {table_streams.map((v, index) => {
        const { stream_name, stream_id, is_video, color = colors.component_main_color } = v;
        return (
          <div key={index}>
            <StreamText
              onClick={(event) => {
                history.push(`/streams/${stream_id}/details`);
                // 親から伝達されるイベントを止める
                event?.stopPropagation();
              }}
              text={stream_name}
              is_video={is_video}
              color={color}
            />
          </div>
        );
      })}
    </div>
  );
};

const getTableStreamsAvailableValue = (table_streams: StreamUIType[]) => {
  return table_streams.map((ts) => ts.stream_name).join(' ');
};

const getProcessingState = (item: ChannelProcess): TableChannelProcessType['processing_state'] => {
  const processing_status_jp = convertProcessingStateJpTypes(item.processing_state) ?? '不明';
  const processing_progress = `${item.processing_progress ?? 0}%`;
  const value = item.processing_state ?? '';
  const available_value = `${processing_status_jp} ${processing_progress}`;
  const displayable_value = (
    <>
      {processing_status_jp}
      <br />
      {processing_progress}
    </>
  );

  return {
    value,
    available_value,
    displayable_value,
  };
};

// テーブルの列の情報まとめたデータ
const headers: TableHeaderType[] = [
  {
    id: 'channel_process_name',
    label: 'チャンネルプロセス名',
    width: '180px',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'process_name',
    label: 'プロセス名',
    width: '15%',
    sortable: true,
    search_props: {
      type: 'name',
      default_display: true,
    },
  },
  {
    id: 'input_streams',
    label: '入力データ',
    width: '25%%',
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'output_streams',
    label: '出力データ',
    width: 'auto',
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'processing_state',
    label: '状態',
    width: '5%',
    search_props: {
      type: 'name',
    },
  },
];

// -- main component --
const ChannelsIdDetailsProcesses: React.FC<Params> = (params) => {
  // local_states
  const [selected_bodies, setSelectedBodies] = useState<TableChannelProcessType[]>([]);
  const { processes_record } = useProcesses();
  const { streams_record } = useStreams({ with_output_streams: 'True', with_in_channel_streams: 'True' });
  const { channel_processes, loadChannelProcesses } = useChannelProcesses({
    channel_id: params.match.params.channel_id,
  });
  const table_bodies: TableChannelProcessType[] | undefined = useMemo(() => {
    return channel_processes?.map((cp) => {
      const input_stream_ids_value = cp.input_stream_ids.map((stream_id) => {
        const stream = streams_record[stream_id];
        return stream
          ? {
              stream_id,
              stream_name: stream.stream_name,
              is_video: streamDataTypeToMedia(stream.data_type, stream.data_number_type) === 'video',
            }
          : { stream_id: stream_id, stream_name: '', is_video: false };
      });
      const output_stream_ids_value = cp.output_stream_ids.map((stream_id) => {
        const stream = streams_record[stream_id];
        return streams_record[stream_id]
          ? {
              stream_id,
              stream_name: stream.stream_name,
              is_video: streamDataTypeToMedia(stream.data_type, stream.data_number_type) === 'video',
            }
          : { stream_id: stream_id, stream_name: '', is_video: false };
      });
      return {
        id: String(cp.channel_process_number),
        channel_process_name: {
          value: cp.channel_process_name,
          onClick: () => handleDetailClick(String(cp.channel_process_number)),
        },
        process_id: cp.process_id,
        process_name: processes_record[cp.process_id]?.process_name,
        input_streams: {
          value: input_stream_ids_value,
          available_value: getTableStreamsAvailableValue(input_stream_ids_value),
          displayable_value: generateTableStreamsDisplayableValue(input_stream_ids_value),
        },
        output_streams: {
          value: output_stream_ids_value,
          available_value: getTableStreamsAvailableValue(output_stream_ids_value),
          displayable_value: generateTableStreamsDisplayableValue(output_stream_ids_value),
        },
        processing_state: getProcessingState(cp),
      };
    });
  }, [channel_processes, streams_record, processes_record]);

  // -- 作成用画面states --
  const [open_create_dialog, setOpenCreateDialog] = useState<boolean>(false);

  // -- 詳細(更新)用states --
  const [open_set_dialog, setOpenSetDialog] = useState<boolean>(false);
  const [channel_process_number, setChannelProcessNumber] = useState<string | undefined>(undefined);

  const handleCheckClick = (datas: TableChannelProcessType[]) => {
    setSelectedBodies(datas);
  };

  // 作成ボタンを押下した時の関数
  const handleCreateClick = () => {
    setOpenCreateDialog(true);
  };
  // 詳細ボタンを押下した時の関数
  const handleDetailClick = (channel_process_number: string) => {
    setChannelProcessNumber(channel_process_number);
    setOpenSetDialog(true);
  };

  const handleOKClick = useCallback(
    async (channel_id: string) => {
      // 削除関数定義
      const deletes = async () => {
        for (const selected_body of selected_bodies) {
          await channelsIdProcessesChannelProcessNumberDeleteAPI({
            channel_id: channel_id,
            channel_process_number: selected_body.id,
          });
        }
        await loadChannelProcesses({ channel_id });
        setSelectedBodies([]);
      };
      // 実行
      await loadWrapperFunc(deletes);
    },
    [selected_bodies, loadChannelProcesses],
  );

  const handleCancelClick = (channel_id: string) => {
    history.push(`/channels/${channel_id}/processes`);
  };

  const handleDeleteClick = async (channel_id: string) => {
    ConfirmDialog.show(
      <div>
        [確認]選択されているチャンネルプロセスを削除します。
        <br />
        本当によろしいですか？
      </div>,
      () => handleOKClick(channel_id),
      () => handleCancelClick(channel_id),
      undefined,
    );
  };

  // 作成画面用関数
  const handleCreateDialogCloseClick = async (channel_id: string) => {
    await loadChannelProcesses({ channel_id });
    setOpenCreateDialog(false);
  };

  // 詳細(更新)用関数
  const handleSetDialogCloseClick = async (channel_id: string) => {
    await loadChannelProcesses({ channel_id });
    setOpenSetDialog(false);
  };

  // -- render part --
  return (
    <Content>
      <TopArea>
        <Title text='' />
        <RoundedButton
          text='チャンネルプロセス作成'
          onClick={handleCreateClick}
          disabled={isSelected(selected_bodies.length)}
          style={{ marginRight: styles.interval_narrow_margin, width: 300 }}
        />
      </TopArea>
      <BottomArea>
        {table_bodies !== undefined ? (
          <BaseTable
            bodies={table_bodies}
            headers={headers}
            table_name='channelsProcesses'
            selected_bodies={selected_bodies}
            handleCheckClick={handleCheckClick}
            id_abridgement
            footer_option={{
              text: '＞チェックした項目を削除する',
              handleClick: () => handleDeleteClick(params.match.params.channel_id),
              disabled: isNotSelected(selected_bodies.length),
            }}
            bodies_per_page={5}
          />
        ) : (
          <Spinner />
        )}
        {open_create_dialog && (
          <CreateChannelProcessDialog
            channel_id={params.match.params.channel_id}
            onClose={() => handleCreateDialogCloseClick(params.match.params.channel_id)}
          />
        )}
        {open_set_dialog && channel_process_number !== undefined && (
          <DetailChannelProcessDialog
            channel_id={params.match.params.channel_id}
            channel_process_number={channel_process_number}
            onClose={() => handleSetDialogCloseClick(params.match.params.channel_id)}
          />
        )}
      </BottomArea>
    </Content>
  );
};

// -- styled components --

// -- finally export part --
export default ChannelsIdDetailsProcesses;
