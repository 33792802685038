import { Metric } from 'user/api/dashboardsWidgets';
import { JsonpathGroup, jsonpathGroupsIdGetAPI } from 'user/api/jsonpathGroups';
import { Stream } from 'user/api/streams';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import SelectBox from 'shared/components/atoms/SelectBox';
import InputComponent from 'shared/components/molecules/InputComponent';
import { InputJsonpathGroup } from 'user/dialogs/CsvRowsInputDialog';
import React from 'react';
import { graph_types, MetricGraphType } from 'shared/models/MetricGraphType';
import styles from 'shared/styles/styles';
import { MetricStatisticMethod } from 'shared/models/MetricStatisticMethod';

export interface GraphSettingPanelValues {
  jsonpath_group_id?: string;
  gp_stream_id?: string;
  graph_type?: MetricGraphType;
  category?: string;
}
interface GraphSettingPanelProps {
  metricStreams: Stream[];
  create_graph: boolean;
  onCreate: (p: boolean) => void;
  onSettingChange: (p: GraphSettingPanelValues) => void;
  onMetricChange: (p: Metric[]) => void;
  values: GraphSettingPanelValues;
}
interface GraphSettingPanelState {}
export default class GraphSettingPanel extends React.PureComponent<GraphSettingPanelProps, GraphSettingPanelState> {
  constructor(props: GraphSettingPanelProps) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.onMetricChange([]);
    this.InputCheck();
  }
  private InputCheck = () => {
    return this.props.values.jsonpath_group_id && this.props.values.gp_stream_id && this.props.values.graph_type;
  };
  private handleJsonpathGroupIdChange = (jsonpath_group?: JsonpathGroup) => {
    const v = this.props.values;
    v.jsonpath_group_id = jsonpath_group?.jsonpath_group_id ?? '';
    this.props.onSettingChange(v);
    this.InputCheck();
  };
  private handleStreamIdChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const v = this.props.values;
    v.gp_stream_id = e.currentTarget.value;
    this.props.onSettingChange(v);
    this.InputCheck();
  };
  private handleGraphTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const v = this.props.values;
    v.graph_type = e.currentTarget.value as MetricGraphType;
    this.props.onSettingChange(v);
    this.InputCheck();
  };
  private handleCreateClick = async () => {
    const new_metrics: Metric[] = [];
    if (this.props.values.jsonpath_group_id) {
      const res = await jsonpathGroupsIdGetAPI({ jsonpath_group_id: this.props.values.jsonpath_group_id });
      if (res.status == 200) {
        const jsonpath_group = res.data;
        const csv_rows = jsonpath_group['csv_rows'];
        for (let i = 0; i < csv_rows.length; i++) {
          const csv_row = csv_rows[i];
          const new_metric: Metric = {
            name: csv_row.header_name,
            graph_type: this.props.values.graph_type,
            stream_id: this.props.values.gp_stream_id as string,
            json_path: csv_row.json_path,
            statistic_method: csv_row.how_statistic_method as MetricStatisticMethod,
            accumulation: csv_row.accumulation,
            stack_id: csv_row.stack_id,
          };
          new_metrics.push(new_metric);
        }
        this.props.onMetricChange(new_metrics);
        this.props.onCreate(true);
      }
    }
  };
  render() {
    return (
      <>
        <InputComponent text='データ' required>
          <SelectBox
            onChange={(e) => (this.handleStreamIdChange ? this.handleStreamIdChange(e) : null)}
            value={this.props.values.gp_stream_id || ''}
            datas={this.props.metricStreams.map((stream) => {
              return { name: stream.stream_name, value: stream.stream_id };
            })}
            long
          />
        </InputComponent>
        <InputComponent text='グラフタイプ' required>
          <SelectBox
            value={this.props.values.graph_type || ''}
            datas={graph_types.map((d) => {
              return {
                value: d,
                name: d,
              };
            })}
            onChange={(e) => this.handleGraphTypeChange(e)}
            long
          />
        </InputComponent>
        {this.props.values.gp_stream_id ? (
          <InputComponent text='JSONパスグループ' required>
            <InputJsonpathGroup
              value={this.props.values.jsonpath_group_id || ''}
              onChange={(e) => this.handleJsonpathGroupIdChange(e)}
              display={'DASHBOARD'}
              category={this.props.values.category}
            />
          </InputComponent>
        ) : null}
        <RoundedButton
          disabled={!this.InputCheck()}
          onClick={() => this.handleCreateClick()}
          text='生成'
          style={{
            width: styles.super_small_button_width,
            height: styles.super_small_button_height,
            marginRight: styles.interval_narrow_margin,
          }}
        />
      </>
    );
  }
}
