import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import pw_svg from 'assets/pw.svg';
import { useDispatch, useSelector } from 'react-redux';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { LoginArea, LoginBackGround, LoginLogo, LoginBaseArea } from 'shared/components/molecules/ContentsArea';
import InputBoxWithIcon from 'shared/components/molecules/InputBoxWithIcon';
import styles from 'shared/styles/styles';
import { isNotOnlySpace } from 'shared/utils/is';
import styled from 'styled-components';
import { accountsLoginNewpasswordPostAPI } from 'user/api/accounts';
import { authActions, authSelector } from 'user/redux/slices/authSlice';
import history from 'shared/browserHistory';
import { getLoginId } from 'shared/utils/else/localStorage';

// -- main component --

const InitialPasswordChange: React.FC = () => {
  // -- redux preparations --
  const dispatch = useDispatch();
  const auth_state = useSelector(authSelector);
  const login_id = getLoginId();
  // -- local states --
  const [password, setPassword] = useState('');
  const [password_confirmation, setPasswordConfirmation] = useState('');

  // -- handlers --
  const handleFinished = async (
    request_data: {
      new_password: string;
      login_id: string;
      session: string;
    },
    password_confirmation: string,
  ) => {
    if (!request_data.new_password || !password_confirmation || !request_data.session || !request_data.login_id) {
      AlertDialog.show('入力欄に空欄があります');
      return;
    }

    if (password !== password_confirmation) {
      AlertDialog.show('新しいパスワードと新しいパスワード確認用が一致しません');
      return;
    }

    const res = await accountsLoginNewpasswordPostAPI(request_data);
    if (res.status === 200) {
      dispatch(
        authActions.signIn({
          access_key: res.data.authentication_result.access_token,
          session: res.data.session,
          login_id: request_data.login_id,
          is_admin: res.data.is_admin,
          tableau_role: res.data.tableau_role,
        }),
      );
      history.push('/dashboards');
    }
    return;
  };

  // -- render part --
  return auth_state.is_logged_in ? (
    <Redirect to='/dashboards' />
  ) : (
    <LoginBackGround>
      <LoginArea>
        <LoginLogoArea>
          <LoginLogo />
        </LoginLogoArea>
        <InputArea>
          <InputBoxWithIcon
            icon_src={pw_svg}
            text='新しいパスワード'
            handleChangeClick={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.currentTarget.value)}
            value={password}
            placeholder='新しいパスワード'
            style={{ marginBottom: styles.interval_margin }}
            password
          />
          <InputBoxWithIcon
            icon_src={pw_svg}
            text='新しいパスワード(確認用)'
            handleChangeClick={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPasswordConfirmation(e.currentTarget.value)
            }
            value={password_confirmation}
            placeholder='新しいパスワード(確認用)'
            password
          />
        </InputArea>
        <ButtonArea>
          <RoundedButton
            text='初期パスワードを変更'
            onClick={() =>
              handleFinished(
                {
                  new_password: password,
                  session: auth_state.session,
                  login_id,
                },
                password_confirmation,
              )
            }
            disabled={
              !isNotOnlySpace(password) || !isNotOnlySpace(password_confirmation) || password !== password_confirmation
                ? true
                : false
            }
            style={{ width: 260 }}
          />
        </ButtonArea>
      </LoginArea>
      <AlertDialog />
    </LoginBackGround>
  );
};

// -- styled components --

const LoginLogoArea = styled(LoginBaseArea)`
  height: 42%;
  justify-content: center;
  align-items: center;
`;

const InputArea = styled(LoginBaseArea)`
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: calc(2 * ${styles.interval_margin});
`;

const ButtonArea = styled(LoginBaseArea)`
  height: 25%;
  justify-content: center;
`;

// -- finally export part --

export default InitialPasswordChange;
