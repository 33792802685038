import React from 'react';
import { TimePicker } from '@blueprintjs/datetime';
import styled from 'styled-components';

interface JudgeTimingsInputProps {
  value: number[][];
  onChange: (value: number[][]) => void;
}
/** イベント条件設定の「イベントの処理時刻」を制御するフィールドです。
 * 仕様上は複数の 開始時刻～終了時刻 を定義できます。
 * （現時点で運用上は一つのレンジになります）
 */
export default class JudgeTimingsInput extends React.PureComponent<JudgeTimingsInputProps> {
  private handleChange(i: number, j: number, value: Date) {
    const new_value = [...this.props.value];
    while (new_value.length < i) {
      new_value.push([0, 2359]);
    }
    new_value[i][j] = fromDate(value);
    this.props.onChange(new_value);
  }
  render(): React.ReactNode {
    return (
      <div style={{ width: '100%' }}>
        {this.props.value.map((e, i) => {
          return (
            <div key={i} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <StyledTimePicker value={toDate(e[0])} onChange={(value) => this.handleChange(i, 0, value)} />
              &nbsp; ～ &nbsp;
              <StyledTimePicker value={toDate(e[1])} onChange={(value) => this.handleChange(i, 1, value)} />
            </div>
          );
        })}
      </div>
    );
  }
}

function fromDate(value: Date) {
  return value.getHours() * 100 + value.getMinutes();
}

function toDate(value: number) {
  const date = new Date();
  date.setHours(Math.floor(value / 100), value % 100);
  return date;
}

const StyledTimePicker = styled(TimePicker)`
  .bp3-timepicker-input-row {
    padding: 0px 16px;
    height: 35px;
    box-sizing: border-box;
    border: 1px solid #919191;
    border-radius: 4px;
    outline: none;
    font-family: inherit;
    color: #6a6a6a;
    box-shadow: none;
    -webkit-box-shadow: none;
  }
`;
