import { useCallback, useEffect, useMemo, useState } from 'react';
import { RequestStreamsIdDataReplacementItemsGet, streamsIdDataReplacementItemsGet } from 'user/api/streamsData';

/**
 * ストリームを取得するcustom hooks
 */
export const useStreamDataReplacementItems = ({ stream_id }: RequestStreamsIdDataReplacementItemsGet) => {
  const [camera_id, setCameraId] = useState<string[]>([]);
  const [line_no, setLineNo] = useState<string[]>([]);
  const loadStreamDataReplacementItems = useCallback(
    async (request_stream_id?: string) => {
      try {
        const res = await streamsIdDataReplacementItemsGet({ stream_id: request_stream_id ?? stream_id });
        if (res.status === 200) {
          setCameraId(res.data.camera_id.length > 0 ? res.data.camera_id.sort() : ['c0', 'c1', 'c2', 'c3']);
          setLineNo(res.data.line_no.length > 0 ? res.data.line_no.sort() : ['LINE0', 'LINE1', 'LINE2', 'LINE3']);
        }
      } catch (error) {
        console.error('Failed to load stream data replacement items', error);
      }
    },
    [stream_id],
  );

  useEffect(() => {
    loadStreamDataReplacementItems();
  }, [loadStreamDataReplacementItems]);

  return useMemo(() => {
    return {
      camera_id,
      line_no,
      loadStreamDataReplacementItems,
    };
  }, [camera_id, line_no, loadStreamDataReplacementItems]);
};
