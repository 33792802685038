import React from 'react';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import styled from 'styled-components';

interface RoundedAnchorButtonProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  text: string; // ボタンの文字
  stop_propagation?: boolean;
}
/**
 * 丸みのあるボタンの見た目でAタグを描画します。
 * text 属性がボタンの文字となり、href 属性でリンク先を指定します。
 */
export default function RoundedAnchorButton(props: RoundedAnchorButtonProps) {
  return (
    <Button
      {...props}
      onClick={(e) => {
        if (props.stop_propagation) {
          e.stopPropagation();
        }
      }}
    >
      {props.text}
    </Button>
  );
}

const Button = styled.a<{
  disabled?: boolean;
  is_white?: boolean;
  is_margin_right?: boolean;
  is_margin_left?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 240px;
  height: 35px;
  border-radius: 25px;
  margin-right: ${(params) => (params.is_margin_right ? styles.interval_narrow_margin : '0px')};
  margin-left: ${(params) => (params.is_margin_left ? styles.interval_narrow_margin : '0px')};
  background-color: ${(params) => (params.is_white ? colors.white : colors.component_main_color)};
  color: ${(params) => (params.is_white ? colors.component_main_color : colors.component_text_color)};
  border: ${(params) => (params.is_white ? `1px solid ${colors.component_main_color}` : 'none')};
  font-family: inherit;
  &:disabled {
    cursor: not-allowed;
    background-color: ${colors.disabled_background_color};
    color: ${colors.main_font_color};
  }
  &:hover {
    text-decoration: none;
    opacity: ${(params) => (params.disabled ? 1 : styles.opacity_hover)};
    cursor: ${(params) => (params.disabled ? 'not-allowed' : 'pointer')};
    color: ${(params) => (params.is_white ? colors.component_main_color : colors.component_text_color)};
  }
  font-weight: ${styles.font_weight};
`;
