import { systemActions } from 'user/redux/slices/systemSlice';
import { store } from 'user/redux/store';

/**
 * [ユーザー画面]処理の前後でloadingを走らせるラッパー関数
 */
export const loadWrapperFunc = async <T = void>(func: () => T) => {
  store.dispatch(systemActions.loading(true));
  await func();
  store.dispatch(systemActions.loading(false));
};

export default loadWrapperFunc;
