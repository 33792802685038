import React from 'react';
import { colors } from 'shared/styles/colors';
import styled from 'styled-components';
import CanvasPane from './CanvasPane';
import ToolPane from './ToolPane';
import { Crop } from './types';

interface CropPanelProps {
  streamId?: string;
  streamDataNumber?: number;
  style?: React.CSSProperties;
  value: string;
}
interface CropPanelState {
  crops: Crop[];
  imageSize: { width: number; height: number };
}
export default class CropPanel extends React.PureComponent<CropPanelProps, CropPanelState> {
  constructor(props: CropPanelProps) {
    super(props);
    this.state = {
      crops: props.value !== '' ? JSON.parse(props.value) : [],
      imageSize: { width: 1280, height: 720 },
    };
  }
  private handleAddCrop = (crop: Crop) => {
    this.setState({ crops: [...this.state.crops, crop] });
  };
  private handleImageSizeChange = (width: number, height: number) => {
    this.setState({ imageSize: { width: width, height: height } });
  };
  private handleCropsChange = (crops: Crop[]) => {
    this.setState({ crops: crops });
  };
  render() {
    return (
      <CropPanelBase style={this.props.style}>
        <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
          <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <ToolPane imageSize={this.state.imageSize} onAddCrop={this.handleAddCrop} />
            <CanvasPane
              streamId={this.props.streamId}
              streamDataNumber={this.props.streamDataNumber}
              crops={this.state.crops}
              onImageSizeChange={this.handleImageSizeChange}
              onCropsChange={this.handleCropsChange}
              style={{ flexGrow: 1 }}
            />
          </div>
        </div>
      </CropPanelBase>
    );
  }
}

const CropPanelBase = styled.div`
  background-color: ${colors.dark_gray};
  display: flex;
  flex-direction: column;
  use-select: none;
`;
