import React from 'react';
import { colors } from 'shared/styles/colors';
import styled from 'styled-components';

/**
 * ページの表示モードを切り替えるボタン
 */

interface TabButtonProps {
  tabId: string;
  onChange: (tabId: string) => void;
}
interface TabButtonState {}
export default class TabButton extends React.PureComponent<TabButtonProps, TabButtonState> {
  render() {
    return (
      <TabButtonArea>
        <ButtonArea
          selected={this.props.tabId === 'grid'}
          style={{
            borderRight: `1px solid ${colors.component_small_border_color}`,
          }}
          onClick={() => this.props.onChange('grid')}
          data-testid='grid-tab'
        >
          <svg
            id='レイヤー_1'
            data-name='レイヤー 1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 30 30'
            style={{
              fill: this.props.tabId === 'grid' ? colors.white : colors.main_font_color,
              stroke: this.props.tabId === 'grid' ? colors.white : colors.main_font_color,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2px',
              width: 25,
              height: 25,
            }}
          >
            <defs></defs>
            <rect className='cls-1' x='1' y='1' width='6' height='6' />
            <rect className='cls-1' x='12' y='1' width='6' height='6' />
            <rect className='cls-1' x='23' y='1' width='6' height='6' />
            <rect className='cls-1' x='1' y='12' width='6' height='6' />
            <rect className='cls-1' x='12' y='12' width='6' height='6' />
            <rect className='cls-1' x='23' y='12' width='6' height='6' />
            <rect className='cls-1' x='1' y='23' width='6' height='6' />
            <rect className='cls-1' x='12' y='23' width='6' height='6' />
            <rect className='cls-1' x='23' y='23' width='6' height='6' />
          </svg>
        </ButtonArea>
        <ButtonArea
          selected={this.props.tabId === 'table'}
          onClick={() => this.props.onChange('table')}
          data-testid='table-tab'
        >
          <svg
            id='レイヤー_1'
            data-name='レイヤー 1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 31 29'
            style={{
              fill: this.props.tabId === 'table' ? colors.white : colors.main_font_color,
              stroke: this.props.tabId === 'table' ? colors.white : colors.main_font_color,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2px',
              width: 25,
              height: 25,
            }}
          >
            <defs></defs>
            <circle className='cls-1' cx='2.5' cy='2.5' r='2.5' />
            <circle className='cls-1' cx='2.5' cy='10.5' r='2.5' />
            <circle className='cls-1' cx='2.5' cy='18.5' r='2.5' />
            <circle className='cls-1' cx='2.5' cy='26.5' r='2.5' />
            <line className='cls-2' x1='9.13' y1='2.36' x2='30' y2='2' />
            <line className='cls-2' x1='9.13' y1='10.36' x2='30' y2='10' />
            <line className='cls-2' x1='9.13' y1='18.36' x2='30' y2='18' />
            <line className='cls-2' x1='9.13' y1='26.36' x2='30' y2='26' />
          </svg>
        </ButtonArea>
      </TabButtonArea>
    );
  }
}

// -- styled components --

const TabButtonArea = styled.div`
  width: 160px;
  height: 40px;
  border-radius: 15px;
  border: 1px solid ${colors.component_small_border_color};
  display: flex;
  background-color: ${colors.white};
  overflow: hidden;
`;

const ButtonArea = styled.div<{
  selected?: boolean;
}>`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(params) => (params.selected ? colors.component_main_color : colors.white)};
`;
