import React from 'react';
import { Spinner } from '@blueprintjs/core';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Footer } from 'shared/components/molecules/ContentsArea';
import styles from 'shared/styles/styles';
import { isMailAddressOptionally } from 'shared/utils/is';
import {
  CachedChannelEventConditionActions,
  ChannelEventConditionsActions,
  channelsIdEventConditionsNumActionsDeleteAPI,
  channelsIdEventConditionsNumActionsPostAPI,
  channelsIdEventConditionsNumActionsPutAPI,
} from 'user/api/channelsEventConditionsActions';
import MailNotificationField, { MailNotificationFieldProps } from './MailNotificationField';
import { MailAdress } from 'shared/models/MailAdress';
import { ChannelEventCondition } from 'user/api/channelsEventConditions';
import { CachedStreams, Stream } from 'user/api/streams';

/**
 * メールアドレスをnum: numberを保持しているものとそうでないものに分ける
 */
const devideMailAddressesByNum = (mail_addresses: MailNotificationDialogState['mail_addresses']) => {
  // numが存在するmail_addresses
  const mail_addresses_has_num: MailAdress<{ num: number }>[] = [];
  // numが存在しないmail_addresses
  const mail_addresses_hasnot_num: MailNotificationDialogState['mail_addresses'] = [];
  mail_addresses?.forEach((mail_address) => {
    if (typeof mail_address.num === 'number') {
      mail_addresses_has_num.push(mail_address as MailAdress<{ num: number }>);
      return;
    }
    mail_addresses_hasnot_num.push(mail_address);
  });
  return {
    mail_addresses_has_num,
    mail_addresses_hasnot_num,
  };
};

const getInitialMailAddresses = (actions: ChannelEventConditionsActions[]) => {
  const mail_addresses = actions.map((e) => {
    return { num: e.channel_event_condition_action_number, mail_address: e.mail_address };
  });
  return mail_addresses;
};
export interface MailNotificationDialogPropsKey {
  channelId: string;
  channelEventCondition: ChannelEventCondition;
  channelEventConditionNumber: number;
}
interface MailNotificationDialogProps extends MailNotificationDialogPropsKey {
  isOpen?: boolean;
  onClose: () => void;
}

interface MailNotificationDialogState {
  actions: ChannelEventConditionsActions[];
  enabled: boolean; // 通知設定の有効可否
  mail_addresses?: MailAdress<{ num?: number }>[]; // undefined: 未ロード、len==0: OFF、len>0: ON
  mail_message: string; // 1つ目のアクションの値を採用
  show_image_send: boolean;
  stream_id?: string | null;
  streams: Stream[];
}
/** メールアドレス通知設定ダイアログ */
export default class MailNotificationDialog extends React.PureComponent<
  MailNotificationDialogProps,
  MailNotificationDialogState
> {
  private cachedStreams = new CachedStreams({ data_types: 'IMAGE,VIDEO', with_output_streams: 'True' });
  constructor(props: MailNotificationDialogProps) {
    super(props);
    this.state = {
      enabled: false,
      actions: [],
      mail_message: '',
      show_image_send: props.channelEventCondition.condition_type === 'USECASE',
      streams: []
    };
  }
  componentDidMount() {
    this.cachedStreams.get().then(values => {
      this.setState({streams: values});
    })
    new CachedChannelEventConditionActions({
      channel_id: this.props.channelId,
      channel_event_condition_number: this.props.channelEventConditionNumber,
    })
      .get()
      .then((res) => {
        // 滞留の場合のメール画像添付判定
        const actions = res.filter((e) => e.action_type === 'Email');
        const mail_addresses = getInitialMailAddresses(actions);
        this.setState({
          actions: actions,
          enabled: mail_addresses.length > 0,
          stream_id: actions.length !== 0 ? actions[0].stream_id || undefined : undefined,
          mail_addresses: mail_addresses.length > 0 ? mail_addresses : [{ mail_address: '' }],
          mail_message: actions.length !== 0 ? actions[0].mail_message || '' : '',
        });
      });
  }
  private onChange: MailNotificationFieldProps['onChange'] = (args) => {
    const new_state = { ...this.state, ...args };
    this.setState(new_state);
  };
  private handleFinishClick = async () => {
    const { mail_addresses, mail_message, enabled, actions } = this.state;
    /**
     * 通知設定が有効の時だけ取り扱う
     * */
    const enabled_mail_addresses = enabled ? mail_addresses : [];
    const enabled_mail_message = enabled ? mail_message : '';
    // 入力チェック
    for (const m of enabled_mail_addresses ?? []) {
      if (!isMailAddressOptionally(m.mail_address)) {
        AlertDialog.show(
          <div>
            メールアドレスが入力されているにもかかわらず
            <br />
            無効のものがあります
            <br />
            ※空白の行は送信しません
          </div>,
        );
        return;
      }
    }
    if (this.state.show_image_send && this.state.stream_id === null) {
      AlertDialog.show(
        <div>
          画像の添付のストリームが選択されていません。
        </div>,
      );
      return;
    }
    const { mail_addresses_has_num, mail_addresses_hasnot_num } = devideMailAddressesByNum(enabled_mail_addresses);
    const _stream_id = this.state.stream_id || undefined;
    // データ削除 or 更新
    for (const action of actions) {
      const mail_address = mail_addresses_has_num.find((e) => e.num === action.channel_event_condition_action_number);

      if (mail_address === undefined || mail_address.mail_address === '') {
        await channelsIdEventConditionsNumActionsDeleteAPI({
          channel_id: action.channel_id,
          channel_event_condition_number: action.channel_event_condition_number,
          channel_event_condition_action_number: action.channel_event_condition_action_number,
        });
      } else if (mail_address.mail_address !== action.mail_address || action.mail_message !== enabled_mail_message || action.stream_id !== _stream_id) {
        await channelsIdEventConditionsNumActionsPutAPI({
          channel_id: action.channel_id,
          channel_event_condition_number: action.channel_event_condition_number,
          channel_event_condition_action_number: action.channel_event_condition_action_number,
          channel_event_condition_action_name: 'Email',
          ...(_stream_id ? {stream_id: _stream_id} : {}),
          mail_address: mail_address.mail_address,
          mail_message: enabled_mail_message,
        });
      }
    }
    // データ追加
    for (const mail_address of mail_addresses_hasnot_num) {
      if (!mail_address.mail_address) continue;
      await channelsIdEventConditionsNumActionsPostAPI({
        channel_id: this.props.channelId,
        channel_event_condition_number: this.props.channelEventConditionNumber,
        channel_event_condition_action_name: 'Email',
        action_type: 'Email',
        ...(_stream_id ? {stream_id: _stream_id} : {}),
        mail_address: mail_address.mail_address,
        mail_message: this.state.mail_message,
      });
    }
    AlertDialog.show('チャンネルイベント条件の更新に成功しました', () => this.props.onClose());
  };
  render() {
    return (
      <PfDialog
        title='メールアドレス通知設定'
        isOpen={this.props.isOpen === undefined || this.props.isOpen}
        onClose={this.props.onClose}
      >
        {this.state.mail_addresses ? (
          <MailNotificationField
            enabled={this.state.enabled}
            mail_addresses={this.state.mail_addresses}
            mail_message={this.state.mail_message}
            onChange={this.onChange}
            show_stream_id={this.state.show_image_send}
            stream_id={this.state.stream_id}
            streams={this.state.streams} />
        ) : (
          <Spinner />
        )}

        <Footer>
          <RoundedButton onClick={this.handleFinishClick} text='更新' style={{ width: styles.small_button_width }} />
        </Footer>
      </PfDialog>
    );
  }
}
