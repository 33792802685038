import { ISO8601 } from 'shared/models/ISO8601';
import { Query } from 'shared/models/Query';
import sendAxios from '../../shared/axios/sendAxios';
import { getClient } from './base';
import { ChannelEvent } from './channelsEvents';
import { OrderBy } from 'shared/models/OrderBy';
import { CommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';

/*** エンティティ ***/

export type Event = ChannelEvent;

export interface EventsWithPaging {
  items: Event[];
  has_next: boolean;
  esk: number | null;
}

const sample_event_1: Event = {
  tenant_id: '00000001',
  channel_id: '00000001',
  channel_event_number: 1,
  channel_event_name: 'チャンネルイベント１',
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  latest_comment: {
    user_id: 'aaa',
    user_name: '長谷川',
    comment: 'これご検知でした~',
    created_at: '2021-06-01T00:00:00+09:00' as ISO8601,
  },
  confirm: null,
};

const sample_event_2: Event = {
  tenant_id: '00000001',
  channel_id: '00000001',
  channel_event_number: 2,
  channel_event_name: 'チャンネルイベント１',
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  latest_comment: {
    user_id: 'aaa',
    user_name: '長谷川',
    comment: 'これご検知でした~',
    created_at: '2021-06-01T00:00:00+09:00' as ISO8601,
  },
  confirm: {
    user_id: 'bbb',
    user_name: '長谷',
    confirmed_at: '2021-06-01T00:00:00+09:00' as ISO8601,
  },
};

/*** [GET] /api/events ***/
export interface RequestEventsGet extends CommonAPIRequestType {
  // 開始作成日時
  exclusive_start_created_at?: number;
  order?: OrderBy;
  limit?: number;
}
/**
 * イベントの一覧を取得する
 * @param exclusive_start_created_at 開始作成日時
 * @param order 検索方法
 * @returns チャンネルのプロセスの一覧
 */
export const eventsGetAPI = (params: RequestEventsGet) => {
  const { exclusive_start_created_at, limit, order = 'ASC' } = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `/api/events`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    exclusive_start_created_at,
    order,
    limit,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<EventsWithPaging>(axios, path, query, form, method, {
    items: [sample_event_1, sample_event_2],
    has_next: false,
    esk: 1000,
  });
};
