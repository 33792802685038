import { SelectData } from 'shared/components/atoms/SelectBox';

/** CSV作成時の集計種類 */
export type ExportCsvStatistics = 'NONE' | 'TIMESTAMP' | 'KEY';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isExportCsvStatistics = (data: any): data is ExportCsvStatistics =>
  data === 'NONE' || data === 'TIMESTAMP' || data === 'KEY';

export const ExportCsvStatisticses: ExportCsvStatistics[] = ['NONE', 'TIMESTAMP', 'KEY'];

export function convertExportCsvStatistics(s?: string, undef?: boolean) {
  for (const v of ExportCsvStatisticses) if (v === s) return s;
  return undef ? undefined : ExportCsvStatisticses[0];
}

export const exportExportCsvStatisticsToName = (csv_statistics: ExportCsvStatistics) => {
  let name = '';
  switch (csv_statistics) {
    case 'NONE':
      name = '集計なし';
      break;
    case 'TIMESTAMP':
      name = '集計する時間を設定';
      break;
    case 'KEY':
      name = '集計するキーを設定';
      break;
  }
  return name;
};

export const convertExportCsvStatisticsToSelectDatas = (ecs: ExportCsvStatistics[]) => {
  const select_datas: SelectData[] = ecs.map((cs) => {
    return {
      name: exportExportCsvStatisticsToName(cs),
      value: cs,
    };
  });
  return select_datas;
};
