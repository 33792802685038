import { CommonAPIRequestType, isCommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';
import { Query } from 'shared/models/Query';
import sendAxios from '../../shared/axios/sendAxios';
import { getClient } from './base';
import sendDownloadAxios from 'shared/axios/sendDownloadAxios';
import { StringBoolean } from 'shared/models/StringBoolean';
import { ExportCsvStatistics } from 'shared/models/ExportCsvStatistics';
import { StatusType } from 'shared/models/StatusType';
import { ISO8601 } from 'shared/models/ISO8601';
import { PackageType } from 'shared/models/PackageType';
import { AxiosResponse } from 'axios';
import { CsvRow } from 'shared/models/CsvRow';
import { ExportCsvTimeUnit } from 'shared/models/ExportCsvTimeUnit';

/*** エンティティ ***/

export interface StreamPackage {
  tenant_id: string;
  stream_id: string;
  stream_package_number: number;
  line_count: number | undefined;
  stream_package_name: string;
  stream_data_number_from: number;
  stream_data_number_to: number;
  package_type: PackageType;
  export_filename: string;
  export_csv_encode: string;
  export_csv_stream_data_number: string;
  jsonpath_group_id: string;
  export_csv_statistics: ExportCsvStatistics;
  export_csv_time_unit: ExportCsvTimeUnit;
  csv_rows: {
    header_name: string;
    json_path: string;
    cell_format: string;
    cell_format_args: string;
    statistic_method?: string;
    fill: boolean;
  }[];
  fps: string;
  width: string;
  height: string;
  package_size: number;
  packaging_progress: number;
  packaging_done: boolean;
  status: StatusType;
  favorite: boolean;
  export_auto_csv_type?: string;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

export interface StreamPackageCsv {
  csv_index: number; // 元のcsvのindexを表す。
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  csv: Record<string, any>; // さまざまな属性が存在する。({列: 値}の関係)
}

export interface StreamPackageWithPaging {
  items: StreamPackage[];
  has_next: boolean;
  last_stream_package_number?: number;
}

export interface StreamPackageCsvWithPaging {
  items: StreamPackageCsv[];
  headers: string[];
  has_next: boolean;
}

const sample_stream_1: StreamPackage = {
  tenant_id: 'string',
  stream_id: '00000001',
  stream_package_number: 2,
  line_count: 10,
  stream_package_name: 'ストリーム1',
  stream_data_number_from: 1,
  stream_data_number_to: 5,
  package_type: 'CSV',
  export_filename: 'string',
  export_csv_encode: 'shift_jis',
  export_csv_stream_data_number: 'None',
  jsonpath_group_id: '00000001',
  export_csv_statistics: 'NONE',
  export_csv_time_unit: 'MINUTE',
  csv_rows: [
    {
      header_name: 'string',
      json_path: '$[*].a',
      cell_format: 'STRING',
      cell_format_args: 'string',
      fill: true,
    },
  ],
  fps: 'string',
  width: 'string',
  height: 'string',
  package_size: 99999,
  packaging_progress: 70,
  packaging_done: true,
  favorite: false,
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
};

const sample_stream_2: StreamPackage = {
  tenant_id: 'string',
  stream_id: '00000002',
  stream_package_number: 1,
  line_count: 20,
  stream_package_name: 'ストリーム2',
  stream_data_number_from: 19024604,
  stream_data_number_to: 19024670,
  package_type: 'CSV',
  export_filename: 'string',
  export_csv_encode: 'string',
  export_csv_stream_data_number: 'None',
  jsonpath_group_id: '00000002',
  export_csv_statistics: 'NONE',
  export_csv_time_unit: 'HOUR',
  csv_rows: [
    {
      header_name: 'string',
      json_path: 'string',
      cell_format: 'STRING',
      cell_format_args: 'string',
      fill: false,
    },
  ],
  fps: 'string',
  width: 'string',
  height: 'string',
  package_size: 7088,
  packaging_progress: 100,
  packaging_done: true,
  favorite: false,
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
};

const sample_csv1: StreamPackageCsv = {
  csv_index: 0,
  csv: {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
    E: 0,
    F: 0,
    G: 0,
    H: 0,
    I: 0,
    J: 0,
  },
};

/*** Caching mechanism ***/

export class CachedStreamPackages {
  private searched = false;
  private cache: StreamPackage[] = [];
  private params: RequestStreamsIdPackagesGet;
  constructor(params: RequestStreamsIdPackagesGet) {
    this.params = params;
  }
  async get() {
    if (!this.searched) {
      const esk: string | number | undefined = undefined;
      for (;;) {
        const res: AxiosResponse<StreamPackageWithPaging> = await streamsIdPackagesGetAPI({
          ...this.params,
          exclusive_start_key: esk,
        });
        if (res.status === 200) {
          this.cache = [...this.cache, ...res.data.items];
          break;
          // バックエンドAPIがlast_evaluated_stream_data_numberを返してないのでコメントアウト
          // if (!res.data.has_next) {
          //   break;
          // }
          // else {
          //   esk = res.data.last_evaluated_stream_data_number;
          // }
        } else {
          break;
        }
      }
      this.searched = true;
    }
    return this.cache;
  }
}

/*** [GET] /api/streams/{stream_id}/packages ***/

export interface RequestStreamsIdPackagesGet {
  stream_id: string;
  limit?: number;
  only_favorite?: 'True';
  status?: StatusType;
  exclusive_start_key?: string | number;
}

export const streamsIdPackagesGetAPI = (params: RequestStreamsIdPackagesGet) => {
  const {
    stream_id,
    limit,
    only_favorite,
    status,
    exclusive_start_key,
    disabled_load,
    disabled_error_message,
    ended_load,
  } = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/streams/${stream_id}/packages`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    limit,
    only_favorite,
    status,
    exclusive_start_key,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<StreamPackageWithPaging>(axios, path, query, form, method, {
    items: [sample_stream_1, sample_stream_2],
    has_next: false,
  });
};

/*** [POST] /api/streams/{stream_id}/packages ***/

export interface RequestStreamsIdPackagesPost extends CommonAPIRequestType {
  stream_id: string;
  stream_package_name: string;
  stream_data_number_from: string;
  stream_data_number_to: string;
  package_type: PackageType;
  export_filename: string;
  export_csv_encode?: string;
  export_csv_stream_data_number?: string;
  export_csv_statistics: ExportCsvStatistics;
  export_csv_time_unit?: ExportCsvTimeUnit;
  jsonpath_group_id?: string;
  csv_rows?: CsvRow[];
  fps?: string;
  width?: string;
  height?: string;
  favorite?: StringBoolean;
  export_auto_csv_type?: string;
}

export const streamsIdPackagesPostAPI = (params: RequestStreamsIdPackagesPost) => {
  const { stream_id, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/streams/${stream_id}/packages`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (value !== undefined) {
      form.append(key, value);
    }
  }
  // csv_rowsだけ別処理
  if (params.csv_rows && params.csv_rows.length > 0) {
    const roop_number = params.csv_rows.length || 0;
    for (let i = 0; i < roop_number; i++) {
      for (const [key, value] of Object.entries(params.csv_rows[i])) {
        if (key === 'cell_format_args') {
          if (value === '') {
            continue;
          }
        }
        form.append('csv_rows[' + i + '].' + key, String(value));
      }
    }
  }

  // 送信
  return sendAxios<StreamPackage>(axios, path, query, form, method, sample_stream_1);
};

/*** [GET] /api/streams/{stream_id}/packages/{stream_package_number} ***/

export interface RequestStreamsIdPackagesNumberGet extends CommonAPIRequestType {
  stream_id: string;
  stream_package_number: string;
}

export const streamsIdPackagesNumberGetAPI = (params: RequestStreamsIdPackagesNumberGet) => {
  const { stream_id, stream_package_number, disabled_load, disabled_error_message, ended_load } =
    toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/streams/${stream_id}/packages/${stream_package_number}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<StreamPackage>(axios, path, query, form, method, sample_stream_1);
};

/*** [PUT] /api/streams/{stream_id}/packages/{stream_package_number} ***/

export interface RequestStreamsIdPackagesNumberPut extends CommonAPIRequestType {
  stream_id: string;
  stream_package_number: string;
  stream_package_name: string;
  favorite?: StringBoolean; // True || False
}

export const streamsIdPackagesNumPutAPI = (params: RequestStreamsIdPackagesNumberPut) => {
  const { stream_id, stream_package_number, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/streams/${stream_id}/packages/${stream_package_number}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'stream_id' || key === 'stream_package_number' || isCommonAPIRequestType(key)) continue;
    form.append(key, value);
  }

  // 送信
  return sendAxios<StreamPackage>(axios, path, query, form, method, sample_stream_1);
};

/*** [GET] /api/streams/{stream_id}/packages/{stream_package_number}/download ***/

export interface RequestStreamsIdPackagesNumberDownloadGet extends CommonAPIRequestType {
  stream_id: string;
  stream_package_number?: string | number;
}
/** streamsIdPackagesNumberDownloadGetAPI のアクセスURLを生成します。 */
export function getStreamsIdPackagesNumberDownloadGetAPIUrl(params: RequestStreamsIdPackagesNumberDownloadGet) {
  return `/api/streams/${params.stream_id}/packages/${params.stream_package_number}/download`;
}
export const streamsIdPackagesNumberDownloadGetAPI = (params: RequestStreamsIdPackagesNumberDownloadGet) => {
  const { disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'multipleTransmission',
  });
  // クライアントを定義
  const axios = getClient({ responseType: 'blob', disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = getStreamsIdPackagesNumberDownloadGetAPIUrl(params);
  // const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendDownloadAxios(axios, path, query, form, new ArrayBuffer(8));
};

/*** [DELETE] /api/streams/{id} ***/

export interface RequestStreamsIdPackagesNumberDelete extends CommonAPIRequestType {
  stream_id: string;
  stream_package_number: string;
}

export const streamsIdPackagesNumberDeleteAPI = (params: RequestStreamsIdPackagesNumberDelete) => {
  const { stream_id, stream_package_number, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'multipleTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/streams/${stream_id}/packages/${stream_package_number}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<StreamPackage>(axios, path, query, form, method, sample_stream_1);
};

/*** [GET] /api/streams/{stream_id}/packages/{stream_package_number} ***/
/*** ストリームパッケージで作成されたCSVをJSON形式で取得できるAPI ***/

export interface RequestStreamsIdPackagesNumberCsvGet {
  stream_id: string;
  stream_package_number: number;
  start_csv_index?: number; // csvの取得を始めるindex
  acquisitions?: number; // csvを取得する件数
}

export const streamsIdPackagesNumberCsvGetAPI = (params: RequestStreamsIdPackagesNumberCsvGet) => {
  const {
    stream_id,
    stream_package_number,
    start_csv_index,
    acquisitions,
    disabled_load,
    disabled_error_message,
    ended_load,
  } = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/streams/${stream_id}/packages/${stream_package_number}/csv`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    start_csv_index,
    acquisitions,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<StreamPackageCsvWithPaging>(axios, path, query, form, method, {
    items: Array(50)
      .fill(null)
      .map((_, i) => {
        const d = { ...sample_csv1 };
        d.csv_index = i;
        return d;
      }),
    headers: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'],
    has_next: false,
  });
};
