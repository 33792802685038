import { DashboardWidget } from 'user/api/dashboardsWidgets';
import { WidgetState } from 'user/pages/Dashboards/DetailPage/UsualDashboardWidgets/DashboardWidgetCard';
import Busod1Widget from './Busod1Widget';
import Busod2Widget from './Busod2Widget';
import Busod3Widget from './Busod3Widget';
import GraphWidget from './GraphWidget';
import HeatmapWidget from './HeatmapWidget';
import LiveTextWidget from './LiveTextWidget';
import MapWidget from './MapWidget';
import VideoPlayerWidget from './VideoPlayerWidget';
import StreamDetailWidget from './StreamDetailWidget';
import SumUpGraphWidget from './SumUpGraphWidget';

export interface WidgetProps {
  widget: DashboardWidget;
  state: WidgetState;
  onStateChanged: (state: WidgetState) => void;
  handleUpdateDlCsvList?: (dlName: string, dlUrl: string) => void;
}

export default function getWidgetComponents(widget_type: string) {
  switch (widget_type) {
    case 'LIVETEXT':
      return LiveTextWidget;
    case 'GRAPH':
      return GraphWidget;
    case 'AREACOUNT':
      return GraphWidget;
    case 'SUMUPGRAPH':
      return SumUpGraphWidget;
    case 'VIDEOPLAYER':
      return VideoPlayerWidget;
    case 'MAP':
      return MapWidget;
    case 'BUSOD1':
      return Busod1Widget;
    case 'BUSOD2':
      return Busod2Widget;
    case 'BUSOD3':
      return Busod3Widget;
    case 'HEATMAP':
      return HeatmapWidget;
    case 'STREAM':
      return StreamDetailWidget;
    default:
      return 'div';
  }
}
