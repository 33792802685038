import React, { useState } from 'react';
import { ICROSSLINE } from './Config';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import { Footer } from 'shared/components/atoms/ContentsArea';
import PfDialog from 'shared/components/atoms/PfDialog';
import DrawDrawCrosslines from 'shared/components/molecules/DrawCrosslines.tsx';

interface LineEditModalProps {
  isOpen: boolean;
  value: ICROSSLINE[];
  image_url: string;
  onClose: (value?: ICROSSLINE[]) => void;
}
export function LineEditModal(params: LineEditModalProps) {
  const [crosslines, setCrosslines] = useState<number[][][]>(params.value.map((cl) => cl.co));
  const handleSave = () => {
    params.onClose(
      crosslines.map((d, i) => {
        return { name: `LINE${i}`, co: d };
      }),
    );
  };
  const handleCrosslinesChange = (crosslines: number[][][]) => {
    setCrosslines(crosslines);
  };
  return (
    <>
      <PfDialog
        isOpen={params.isOpen}
        title='検知ライン設定'
        onClose={() => params.onClose()}
        contentProps={{ style: { display: 'flex', flexDirection: 'column' } }}
        large
      >
        <DrawDrawCrosslines imgsrc={params.image_url} crosslines={crosslines} onChange={handleCrosslinesChange} />
        <Footer>
          <RoundedButton text='キャンセル' onClick={() => params.onClose()} is_margin_right is_white />
          <RoundedButton text='保存' onClick={handleSave} />
        </Footer>
      </PfDialog>
    </>
  );
}

interface LineEditModalButtonProps {
  value: ICROSSLINE[];
  image_url?: string;
  onChange: (value: ICROSSLINE[]) => void;
}
/** 検知ライン設定ダイアログを開くためのボタンです。 */
export function LineEditModalButton(params: LineEditModalButtonProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = (value?: ICROSSLINE[]) => {
    setIsOpen(false);
    if (value !== undefined) {
      params.onChange(value);
    }
  };
  return (
    <>
      <RoundedButton text={`設定`} onClick={handleOpen} />
      {isOpen && params.image_url && (
        <LineEditModal isOpen={isOpen} value={params.value} image_url={params.image_url} onClose={handleClose} />
      )}
    </>
  );
}
