import styles from 'shared/styles/styles';
import styled from 'styled-components';

const PfBoldText = styled.div`
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: ${styles.interval_narrow_margin};
`;

export default PfBoldText;
