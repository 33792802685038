// -- basic library --
import React, { useState } from 'react';

// -- redux library --
import { useSelector } from 'react-redux';

// -- http connection library --

// -- external components --
import InputBox from 'shared/components/atoms/InputBox';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Content, Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import { channelsIdProcessesNumPutAPI, RequestChannelsIdProcessesPut } from 'user/api/channelsProcesses';
import { systemSelector } from 'user/redux/slices/systemSlice';

// -- types --

type Params = {
  channel_id: string;
  channel_process_number: string;
  channel_process_name: string;
  isOpen?: boolean;
  onClose: (canceled?: boolean) => void;
};

// -- main component --
const EditChannelProcessNameDialog: React.FC<Params> = (params) => {
  // -- redux preparations --
  const system_state = useSelector(systemSelector);

  // -- local states --
  const [channel_process_name, setChannelProcessName] = useState<string>(params.channel_process_name);

  const handleFinishClick = async () => {
    const request_bodies: RequestChannelsIdProcessesPut = {
      channel_id: params.channel_id,
      channel_process_number: params.channel_process_number,
      channel_process_name: channel_process_name,
    };

    const res = await channelsIdProcessesNumPutAPI(request_bodies);

    // resの正常を持って完了と判定する
    if (res.status === 200) {
      params.onClose();
      AlertDialog.show('推論動画名の編集に成功しました');
    }
  };

  // -- render part --
  return (
    <PfDialog
      title={`推論動画名の編集`}
      isOpen={params.isOpen === undefined ? true : params.isOpen}
      onClose={() => params.onClose(true)}
    >
      <Content>
        {system_state.loading.preloading ? (
          <Spinner />
        ) : (
          <>
            <InputComponent text='推論動画名'>
              <InputBox
                placeholder='入力してください'
                value={channel_process_name}
                onChange={(e) => setChannelProcessName(e.currentTarget.value)}
              />
            </InputComponent>
            <Footer>
              <RoundedButton onClick={() => params.onClose(true)} text='キャンセル' is_margin_right is_white />
              <RoundedButton onClick={handleFinishClick} text='更新' />
            </Footer>
          </>
        )}
      </Content>
    </PfDialog>
  );
};

// -- styled components --

// -- finally export part --

export default EditChannelProcessNameDialog;
