import { isCellFormat } from 'shared/models/CellFormat';
import { CsvRow } from 'shared/models/CsvRow';
import { isValidMetricFill } from 'shared/utils/is';
import { isJsonPath } from 'shared/utils/is';
import { isNotOnlySpace } from 'shared/utils/is';

const validateStreamDataCsvRows = (csv_rows: CsvRow[], allow_empty = false): Error | void => {
  // データの長さにおけるヴァリデーション
  if (csv_rows.length > 200) {
    throw new Error(`CSV定義は最大で200行までです。`);
  }
  if (!allow_empty && csv_rows.length < 1) {
    throw new Error(`CSV定義は最小で1行です。`);
  }
  // CSVデータの中身のヴァリデーション
  for (let i = 0; i < csv_rows.length; i++) {
    validateStreamDataCsvRow(csv_rows[i], i);
  }
};

const validateStreamDataCsvRow = (csv_row: CsvRow, index?: number): Error | void => {
  const index_text = typeof index === 'number' ? `${index + 1}行目の` : '';
  // データの長さにおけるヴァリデーション
  if (!isNotOnlySpace(csv_row.header_name)) {
    throw new Error(`${index_text}ヘッダ名を入力してください`);
  }
  if (!isJsonPath(csv_row.json_path)) {
    throw new Error(`${index_text}JSONパスを入力してください\n※JSONパスは$から始める`);
  }
  if (!isCellFormat(csv_row.cell_format)) {
    throw new Error(`${index_text}出力式を選択してください\n`);
  }
  if (!isValidMetricFill(csv_row.fill)) {
    throw new Error(`${index_text}複製を選択してください`);
  }
  if (csv_row.cell_format === 'SUBSTRING' && !isNotOnlySpace(csv_row.cell_format_args)) {
    throw new Error(`${index_text}出力式が[SUBSTRING]の時は、引数は必須です。`);
  }
};

export default validateStreamDataCsvRows;
