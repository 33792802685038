import React from 'react';
import FunctionalText from 'shared/components/atoms/FunctionalText';
import styles from 'shared/styles/styles';
import styled from 'styled-components';

interface ResultsTableFooterProps {
  style?: React.CSSProperties;
  onSelectedDataDownload: () => void;
  is_selected_data_download_disabled?: boolean;
  onAllDownload: () => void;
  is_all_download_disabled?: boolean;
}

/**
 * ファイル処理の推論結果一覧テーブルのフッター
 */
const ResultsTableFooter = (props: ResultsTableFooterProps) => {
  return (
    <div style={{ display: 'block', ...props.style }}>
      <DLFunctionArea>
        <FunctionalText
          text={'>チェックしたデータを一括ダウンロード'}
          onClick={props.onSelectedDataDownload}
          disabled={props.is_selected_data_download_disabled}
        />
        <FunctionalText
          text={'>全てダウンロード'}
          onClick={props.onAllDownload}
          disabled={props.is_all_download_disabled}
        />
      </DLFunctionArea>
    </div>
  );
};

// -- styled components --
const DLFunctionArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${styles.interval_narrow_margin};
`;

export default ResultsTableFooter;
