import Axios from 'axios';
import { CommonAPIRequestType, isCommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';
import { Query } from 'shared/models/Query';
import { getClient } from 'user/api/base';
import sendAxios from '../../shared/axios/sendAxios';
import { TableauRole } from 'shared/models/TableauRole';

/*** エンティティ ***/

export interface CognitoSession {
  challenge_name: string;
  session: string;
  is_admin: boolean;
  tableau_role: TableauRole;
  authentication_result: {
    access_token: string;
    expires_in: number;
    token_type: string;
    refresh_token: string;
  };
}

export const sample_cognito_session_1: CognitoSession = {
  challenge_name: 'NEW',
  session: 'session',
  is_admin: true,
  tableau_role: 'Explorer',
  authentication_result: {
    access_token: 'string',
    expires_in: 0,
    token_type: 'string',
    refresh_token: 'string',
  },
};

/** [GET] /api/accounts/info */
// このAPIコールはaxiosを使っていません。
// 将来のどこかで全てのaxios利用を廃止するためのサンプル実装となります。
// (axiosを使う合理的理由が見当たらないため廃止する目標。)
export interface ResponseAccountsInfoGetAPI {
  tenant_id: string;
  user_id: string;
  user_name: string;
}
export async function accountsInfoGetAPI(): Promise<ResponseAccountsInfoGetAPI | null> {
  const res = await fetch('/api/accounts/info');
  if (res.status === 200) return res.json();
  return null;
}

/*** [POST] /api/accounts/login ***/

export interface RequestAccountsLoginPost extends CommonAPIRequestType {
  login_id: string;
  password: string;
}

export const accountsLoginPostAPI = (params: RequestAccountsLoginPost) => {
  const { disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/accounts/login`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (isCommonAPIRequestType(key)) continue;
    form.append(key, value);
  }

  // 送信
  return sendAxios<CognitoSession>(axios, path, query, form, method, sample_cognito_session_1);
};

/*** [POST] /api/accounts/refresh ***/

export interface RequestAccountsRefreshPost extends CommonAPIRequestType {
  refresh_token: string;
}

export const accountsRefreshPostAPI = (params: RequestAccountsRefreshPost) => {
  // クライアントを定義
  const axios = Axios.create({ responseType: 'json' });

  // パス・メソッドを定義
  const path = `/api/accounts/refresh`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (isCommonAPIRequestType(key)) continue;
    form.append(key, value);
  }

  // 送信
  return sendAxios<CognitoSession>(axios, path, query, form, method, sample_cognito_session_1);
};

/*** [POST] /api/accounts/login/newpassword ***/

export interface RequestAccountsLoginNewpasswordPost extends CommonAPIRequestType {
  login_id: string;
  new_password: string;
  session: string;
}

export const accountsLoginNewpasswordPostAPI = (params: RequestAccountsLoginNewpasswordPost) => {
  const { disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/accounts/login/newpassword`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (isCommonAPIRequestType(key)) continue;
    form.append(key, value);
  }

  // 送信
  return sendAxios<CognitoSession>(axios, path, query, form, method, sample_cognito_session_1);
};

/*** [POST] /api/accounts/forgotpassword ***/

export interface RequestAccountsForgotpasswordPost extends CommonAPIRequestType {
  login_id: string;
}

export const accountsForgotpasswordPostAPI = (params: RequestAccountsForgotpasswordPost) => {
  const { disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/accounts/forgotpassword`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (isCommonAPIRequestType(key)) continue;
    form.append(key, value);
  }

  // 送信
  return sendAxios<null>(axios, path, query, form, method, null);
};

/*** [POST] /api/accounts/forgotpassword/confirm ***/

export interface RequestAccountsForgotpasswordConfirmPost extends CommonAPIRequestType {
  confirmation_code: string;
  login_id: string;
  password: string;
}

export const accountsForgotpasswordConfirmPostAPI = (params: RequestAccountsForgotpasswordConfirmPost) => {
  const { disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/accounts/forgotpassword/confirm`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (isCommonAPIRequestType(key)) continue;
    form.append(key, value);
  }

  // 送信
  return sendAxios<null>(axios, path, query, form, method, null);
};

/*** [PUT] /api/accounts/password ***/

export interface RequestAccountsPasswordPut extends CommonAPIRequestType {
  previous_password: string;
  next_password: string;
}

export const accountsPasswordPutAPI = (params: RequestAccountsPasswordPut) => {
  const { disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/accounts/password`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (isCommonAPIRequestType(key)) continue;
    form.append(key, value);
  }

  // 送信
  return sendAxios<null>(axios, path, query, form, method, null);
};
