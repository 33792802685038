import React, { useEffect, useState } from 'react';
import { KonvaEventObject } from 'konva/types/Node';
import { Stage, Layer, Rect, Text, Line, Group, Star } from 'react-konva';
import { colors } from 'shared/styles/colors';
import { dateToHM, dateToMD, secondsToTime } from 'shared/utils/converter/date';
import { toISO8601 } from 'shared/models/ISO8601';
import styled from 'styled-components';

// -- type declaration --

interface Params {
  width: number;
  height: number;
  type: string;
  selected_video_time: string;
  draggable: boolean;
  video_ref: React.RefObject<HTMLVideoElement>;
  onTimeChange: (time: string) => Promise<void>;
  setSelectingVideoDate: (selecting_video_date?: string) => void;
}

// -- external functions --
const getWidth = (rate: number, canvasWidth: number) => {
  return canvasWidth * rate;
};

const getHeight = (rate: number, canvasHeight: number) => {
  return canvasHeight * rate;
};

const getDisplayBarTime = (date: string | Date) => {
  const new_date = new Date(date);
  if (new_date.getHours() === 0 && new_date.getMinutes() === 0 && new_date.getSeconds() === 0) {
    return dateToMD(new_date);
  }
  return dateToHM(toISO8601(new_date));
};

const addTimeToDate = (date: string, time: number) => {
  const d = new Date(date);
  d.setSeconds(d.getSeconds() + time);
  return toISO8601(d);
};

const getTimeLists = (selected_date: string, type: string, index: number, passed_time?: number) => {
  let new_selected_date = selected_date;
  // 経過時間が存在していたら追加する
  if (passed_time) {
    new_selected_date = addTimeToDate(selected_date, passed_time);
  }
  const start_date = new Date(new_selected_date);
  if (type === '24H') {
    start_date.setHours(0);
    start_date.setMinutes(0);
    start_date.setSeconds(0);
    start_date.setDate(start_date.getDate() + (index - 2));
    return [getDisplayBarTime(start_date), '03:00', '06:00', '09:00', '12:00', '15:00', '18:00', '21:00'];
  } else if (type === '1H') {
    start_date.setMinutes(0);
    start_date.setSeconds(0);
    start_date.setHours(start_date.getHours() + (index - 2));
    const time1 = new Date(start_date);
    time1.setMinutes(start_date.getMinutes() + 10);
    const time2 = new Date(start_date);
    time2.setMinutes(start_date.getMinutes() + 20);
    const time3 = new Date(start_date);
    time3.setMinutes(start_date.getMinutes() + 30);
    const time4 = new Date(start_date);
    time4.setMinutes(start_date.getMinutes() + 40);
    const time5 = new Date(start_date);
    time5.setMinutes(start_date.getMinutes() + 50);
    return [
      getDisplayBarTime(start_date),
      getDisplayBarTime(time1),
      getDisplayBarTime(time2),
      getDisplayBarTime(time3),
      getDisplayBarTime(time4),
      getDisplayBarTime(time5),
    ];
  }
  return [];
};

const getWidthFromDate = (width: number, date: string, type: string) => {
  const day = new Date(date);
  const hours = day.getHours();
  const minutes = day.getMinutes();
  const seconds = day.getSeconds();
  if (type === '1H') {
    const totalTime = minutes * 60 + seconds;
    return width * (totalTime / (60 * 60));
  }
  const totalTime = hours * 3600 + minutes * 60 + seconds;
  return width * (totalTime / (24 * 60 * 60));
};

const getDateFromWidth = (width: number, x: number, date: string, type: string) => {
  const selectedDate = new Date(date);
  let totalSeconds = 0;
  if (type === '1H') {
    totalSeconds = (x / width) * (60 * 60);
  } else if (type === '24H') {
    totalSeconds = (x / width) * (24 * 60 * 60);
  }
  const hour_minute_second = secondsToTime(totalSeconds);
  selectedDate.setMinutes(selectedDate.getMinutes() + hour_minute_second.min);
  selectedDate.setSeconds(selectedDate.getSeconds() + hour_minute_second.sec);
  selectedDate.setHours(selectedDate.getHours() + hour_minute_second.hour);
  return toISO8601(selectedDate);
};

const generateSelectedBar = (width: number, date: string, type: string) => {
  if (date === '') {
    return width / 2;
  }
  return width / 2 - getWidthFromDate(width, date, type);
};

// -- main component --
const M3u8PlayBar: React.FC<Params> = (params) => {
  const bufferHeight = 10;
  const bufferWidth = 15;
  // -- local states --
  const [selectedBar, setSelectedBar] = useState(0);
  const [isDrag, setIsDrag] = useState(false);

  // -- reference preparations --

  // -- handlers --
  const selectedBarDragStart = () => {
    setIsDrag(true);
  };

  const handleDragMove = (e: KonvaEventObject<DragEvent>) => {
    const newPos = e.target.position();
    e.target.position({ x: newPos.x, y: bufferHeight });
    const former_x: number = generateSelectedBar(params.width, params.selected_video_time, params.type);
    const requestDate: string = getDateFromWidth(
      params.width,
      former_x - newPos.x,
      params.selected_video_time,
      params.type,
    );
    params.setSelectingVideoDate(requestDate);
  };

  const selectedBarDragEnd = async (e: KonvaEventObject<DragEvent>) => {
    const x: number = e.target.x();
    const former_x: number = generateSelectedBar(params.width, params.selected_video_time, params.type);
    let requestDate: string = getDateFromWidth(params.width, former_x - x, params.selected_video_time, params.type);
    if (new Date(requestDate) > new Date()) {
      requestDate = toISO8601(new Date());
    }
    params.onTimeChange(requestDate).then(() => {
      params.setSelectingVideoDate(undefined);
    });
    setIsDrag(false);
  };

  // -- onload function --

  useEffect(() => {
    setSelectedBar(generateSelectedBar(params.width, params.selected_video_time, params.type));
  }, [params.selected_video_time, params.type]); /* eslint-disable-line */

  useEffect(() => {
    const countup = setInterval(() => {
      if (params.video_ref.current && !isDrag && params.selected_video_time) {
        let passed_time = 0;
        //readyStateの参考
        // https://developer.mozilla.org/ja/docs/Web/API/HTMLMediaElement/readyState
        // 2以上であれば必要最低限再生できる。
        // このときcurrentTimeを同期させる(そうでない時はcurrentTimeが読み取られていないのでただ単に現時点から3600秒マイナスにした位置に移動してしまう)
        if (params.video_ref.current && params.video_ref.current.readyState >= 2) {
          passed_time = params.video_ref.current.currentTime - 3600;
        }
        const new_selected_video_time = addTimeToDate(params.selected_video_time, passed_time);
        setSelectedBar(generateSelectedBar(params.width, new_selected_video_time, params.type));
      }
    }, 1000);
    return () => {
      clearInterval(countup);
    };
  }, [selectedBar]); /* eslint-disable-line */

  // -- render part --
  return (
    <Stage
      width={params.width}
      height={params.height + bufferHeight * 2}
      style={{ cursor: 'ew-resize', opacity: params.draggable ? 1.0 : 0.4 }}
    >
      <Layer>
        <Group key={`flame-group`} x={0} y={bufferHeight} width={params.width} height={params.height}>
          <Rect
            id='flame'
            x={getWidth(0, params.width)}
            y={getHeight(0.25, params.height)}
            width={getWidth(1, params.width)}
            height={getHeight(1 - 0.25, params.height)}
            stroke='black'
            strokeWidth={1}
          />
          <Line
            points={[
              getWidth(0, params.width),
              getHeight(0.5, params.height),
              getWidth(1, params.width),
              getHeight(0.5, params.height),
            ]}
            stroke={colors.component_small_border_color}
          />
        </Group>
        <CenterLineGroup key={`center-line-group`} x={0} y={bufferHeight} width={params.width} height={params.height}>
          <Line
            x={getWidth(0.5, params.width)}
            y={getWidth(0.25, params.height)}
            points={[
              getWidth(0.5 - 0.5, params.width),
              getHeight(0.25 - 0.25, params.height),
              getWidth(0.5 - 0.5, params.width),
              getHeight(1 - 0.25, params.height),
            ]}
            stroke={colors.blue}
          />
          <Star
            x={getWidth(0.5, params.width)}
            y={getHeight(1, params.height)}
            numPoints={3}
            innerRadius={14 / 2}
            outerRadius={14}
            scale={{
              x: 0.5,
              y: 0.5,
            }}
            opacity={1}
            fill={colors.blue}
          />
        </CenterLineGroup>
        <Group
          key={'bar-group'}
          id={'bar-group'}
          x={selectedBar}
          y={bufferHeight}
          width={params.width}
          height={params.height}
          draggable={params.draggable}
          clipY={bufferHeight}
          onDragStart={selectedBarDragStart}
          onDragMove={handleDragMove}
          onDragEnd={selectedBarDragEnd}
        >
          {new Array(5).fill('').map((_, date_index) => {
            let passed_time: undefined | number = undefined;
            //readyStateの参考
            // https://developer.mozilla.org/ja/docs/Web/API/HTMLMediaElement/readyState
            // 2以上であれば必要最低限再生できる。
            // このときcurrentTimeを同期させる(そうでない時はcurrentTimeが読み取られていないのでただ単に現時点から3600秒マイナスにした位置に移動してしまう)
            if (params.video_ref.current && params.video_ref.current.readyState >= 2) {
              passed_time = params.video_ref.current.currentTime - 3600;
            }
            const times = getTimeLists(params.selected_video_time, params.type, date_index, passed_time);
            return times.map((date, index) => {
              const times_length = times.length;
              const total_bar_number = params.type === '1H' ? 10 : 9;
              const bold_bar_number_index = params.type === '1H' ? 5 : 3;
              return (
                <Group key={`bar-${date_index}-${index}`}>
                  {new Array(total_bar_number).fill('').map((_, index2) => (
                    <Group key={`time-line-${index}-${index2}`} id={`time-line-${index}-${index2}`}>
                      <Line
                        x={
                          getWidth(
                            (1 / times_length) * index + (1 / (total_bar_number * times_length)) * index2,
                            params.width,
                          ) + getWidth(date_index - 2, params.width)
                        }
                        y={getHeight(0.5, params.height)}
                        points={[
                          0,
                          getHeight(0.5, params.height) - getHeight(0.5, params.height),
                          0,
                          getHeight(index2 % bold_bar_number_index === 0 ? 0.7 : 0.6, params.height) -
                            getHeight(0.5, params.height),
                        ]}
                        stroke={colors.component_small_border_color}
                      />
                    </Group>
                  ))}

                  <Text
                    key={`text-${index}`}
                    id={`text-${index}`}
                    x={
                      getWidth((1 / times_length) * index, params.width) +
                      getWidth(date_index - 2, params.width) -
                      bufferWidth
                    }
                    y={getHeight(0.7, params.height)}
                    text={date}
                  />
                </Group>
              );
            });
          })}
          <Rect x={-selectedBar} y={0} width={getWidth(2, params.width)} height={getHeight(1, params.height)} />
        </Group>
      </Layer>
    </Stage>
  );
};

// -- styled components --

// const EventGroup = styled(Group)``;
const CenterLineGroup = styled(Group)``;

// -- finally export part --

export default M3u8PlayBar;
