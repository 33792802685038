// -- basic library --
import React, { useState, useEffect } from 'react';

import PfDialog from 'shared/components/atoms/PfDialog';
import CreateDialog from './CreateDialog';
import ProcessProvisioningDialog from './ProcessProvisioningDialog';

// -- external functions --

// -- external datas --

// -- type declaration --

interface Params {
  stream_id: string;
  isOpen: boolean;
  onClose: (p: { isCanceled?: boolean; isCreated?: boolean; isSettingProcess?: boolean }) => void;
}
// -- main component --

const CreateFileProcessesIdDetailFileDialog: React.FC<Params> = (params) => {
  // -- local state --
  const [page_number, setPageNumber] = useState<number>(1); // 1がファイルアップロード, 2がアップロード完了
  const [s3_transfer_id, setS3TransferId] = useState<string>('');

  // -- handlers --
  const onCreateFinished = (s3_transfer_id: string) => {
    setPageNumber(2);
    setS3TransferId(s3_transfer_id);
  };

  useEffect(() => {
    // コンポーネントがアンマウントされた時にs3_transfer_idを''にする。
    return () => {
      setS3TransferId('');
    };
  }, []);

  // -- render part --
  return (
    <PfDialog
      isOpen={params.isOpen}
      onClose={() => params.onClose({ isCanceled: true, isCreated: page_number === 1 ? false : true })}
      title='ファイル作成'
      canOutsideClickClose={false}
    >
      <>
        {page_number === 1 ? (
          <CreateDialog {...params} onClose={params.onClose} onCreateFinished={onCreateFinished} />
        ) : null}
        {page_number === 2
          ? s3_transfer_id.length > 0 && (
              <ProcessProvisioningDialog {...params} onClose={params.onClose} s3_transfer_id={s3_transfer_id} />
            )
          : null}
      </>
    </PfDialog>
  );
};

// -- styled components --

// -- finally export part --

export default CreateFileProcessesIdDetailFileDialog;
