export type MetricGraphType = 'LINE' | 'BAR' | 'AREA' | 'SCATTER' | 'CIRCLE' | '';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isMetricGraphType = (data: any): data is MetricGraphType =>
  data === 'LINE' || data === 'BAR' || data === 'AREA' || data === 'SCATTER' || data === 'CIRCLE' || data === '';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toMetricGraphType = (data: any) => {
  if (isMetricGraphType(data)) return data;
  return '';
};

export const graph_types: MetricGraphType[] = ['LINE', 'BAR', 'AREA', 'SCATTER'];
