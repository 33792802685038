import React from 'react';
import { ProgressBar, ProgressBarProps } from '@blueprintjs/core';
import styled from 'styled-components';

/**
 * コンポーネントに付属するプログレスバー(自動リロードボタンの下につけたりする)
 */
type PassedTimeProgressBarParam = {
  goal_time: number; // ミリ秒単位、-1 は無効
  rendering_interval?: number; // ミリ秒単位、デフォルトは1000
} & ProgressBarProps;

interface PassedTimeProgressBarState {
  passed_time: number; // ミリ秒単位
  start_date: Date;
}
export default class PassedTimeProgressBar extends React.Component<
  PassedTimeProgressBarParam,
  PassedTimeProgressBarState
> {
  private intervalId: NodeJS.Timer | undefined;
  constructor(props: PassedTimeProgressBarParam) {
    super(props);
    this.state = {
      passed_time: 0,
      start_date: new Date(),
    };
  }
  componentDidMount() {
    this.setState({
      passed_time: 0,
      start_date: new Date(),
    });
    this.setupTimer();
  }
  componentDidUpdate(prevProps: PassedTimeProgressBarParam) {
    // 自動リロードの間隔が変更された場合、タイマーを再セットアップします。
    if (this.props.goal_time !== prevProps.goal_time) {
      this.setState({
        passed_time: 0,
        start_date: new Date(),
      });
      this.setupTimer();
    }
  }
  componentWillUnmount() {
    this.teardownTimer();
  }
  private setupTimer = () => {
    // 事前に動いていた場合はクリアー
    this.teardownTimer();
    // タイマーのセットアップ
    if (this.props.goal_time !== -1) {
      // レンダリングする時間間隔(デフォルトは1000)
      const r_interval = this.props.rendering_interval || 1000;
      this.intervalId = setInterval(() => {
        const now_date = new Date();
        this.setState({
          passed_time: (now_date.getTime() - this.state.start_date.getTime()) % this.props.goal_time,
        });
      }, r_interval);
    }
  };
  private teardownTimer = () => {
    if (this.intervalId !== undefined) {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
    }
  };
  render() {
    const new_props: PassedTimeProgressBarParam = { ...this.props };
    new_props.stripes = this.props.stripes === undefined ? false : this.props.stripes;
    new_props.animate = this.props.animate === undefined ? false : this.props.animate;
    return (
      <CustomProgressBar
        {...new_props}
        value={this.props.goal_time === -1 ? undefined : this.state.passed_time / this.props.goal_time}
      />
    );
  }
}

// -- styled components --

const CustomProgressBar = styled(ProgressBar)`
  height: 3px;
  &.bp3-progress-bar .bp3-progress-meter {
    background-color: rgba(51, 136, 221, 1);
  }
`;
