// -- basic library --
import React from 'react';

// -- external components --
import { Menu, MenuItem, Position } from '@blueprintjs/core';
import PassedTimeProgressBar from 'shared/components/atoms/PassedTimeProgressBar';
import RadioMenuItem from 'shared/components/atoms/RadioMenuItem';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import styles from 'shared/styles/styles';

import ToggleButton from './ToggleButton';
import Popover from '../atoms/Popover';

// -- type declaration --
interface AutoReloadButtonProps {
  enable_auto_reload: boolean;
  onAutoReloadChange: (ear: boolean) => void;
  auto_reload_interval: number;
  onAutoReloadIntervalChange: (ari: number) => void;
  style?: React.CSSProperties;
}

// -- main component --

/** 自動的にリロードされるコンポーネント**/
export default function AutoReloadButton(params: AutoReloadButtonProps) {
  // -- preparations --

  // -- render part --
  return (
    <Popover
      content={
        <Menu>
          <MenuItem
            text={
              <ToggleButton
                onClick={() => params.onAutoReloadChange(!params.enable_auto_reload)}
                checked={params.enable_auto_reload}
              />
            }
            onClick={() => {}}
          />
          <RadioMenuItem
            text='10秒'
            checked={params.auto_reload_interval === 10000}
            onClick={() => params.onAutoReloadIntervalChange(10000)}
          />
          <RadioMenuItem
            text='30秒'
            checked={params.auto_reload_interval === 30000}
            onClick={() => params.onAutoReloadIntervalChange(30000)}
          />
          <RadioMenuItem
            text='1分'
            checked={params.auto_reload_interval === 60000}
            onClick={() => params.onAutoReloadIntervalChange(60000)}
          />
          <RadioMenuItem
            text='5分'
            checked={params.auto_reload_interval === 300000}
            onClick={() => params.onAutoReloadIntervalChange(300000)}
          />
          <RadioMenuItem
            text='10分'
            checked={params.auto_reload_interval === 600000}
            onClick={() => params.onAutoReloadIntervalChange(600000)}
          />
        </Menu>
      }
      position={Position.BOTTOM}
    >
      <div style={{ marginLeft: styles.interval_narrow_margin }}>
        <RoundedButton text='自動リロード' onClick={() => {}} />
        {params.enable_auto_reload && <PassedTimeProgressBar goal_time={params.auto_reload_interval} />}
      </div>
    </Popover>
  );
}

// -- styled components --

// -- finally export part --
