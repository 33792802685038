// -- basic library --
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Content, Footer } from 'shared/components/atoms/ContentsArea';
import InputBox from 'shared/components/atoms/InputBox';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import { SelectData } from 'shared/components/atoms/SelectBox';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import InputComponent from 'shared/components/molecules/InputComponent';
import RadioBox from 'shared/components/molecules/RadioBox';
import useObj from 'shared/hooks/useObj/useObj';
import { DashboardType, dashboardTypeToJp } from 'shared/models/DashboardType';
import { TableauDataType } from 'shared/models/TableauDataType';
import { isTableauExplorer, isTableauViewer } from 'shared/models/TableauRole';
import { dashboardsPostAPI } from 'user/api/dashboards';
import { authSelector } from 'user/redux/slices/authSlice';
import { dashboardPostValidator } from 'user/utils/validators/dashboards';

// -- types --
interface DashboardsCreateDialogProps {
  handleDialogCloseClick: () => void;
  is_open: boolean;
}

// -- main component --
function DashboardsCreateDialog({ handleDialogCloseClick, is_open }: DashboardsCreateDialogProps) {
  // -- local states --
  const { obj: form, updateObj: updateForm } = useObj<{
    dashboard_name: string;
    dashboard_type: DashboardType;
    tableau_data_type: TableauDataType | '';
  }>({
    dashboard_name: '',
    dashboard_type: 'usual',
    tableau_data_type: 'count_data',
  });
  const auth_state = useSelector(authSelector);

  const can_create_tableau_dashboard = isTableauExplorer(auth_state.tableau_role);
  // todo 一時的にviewerも許可、explorer権限購入したらこの変数は消す
  const can_create_tableau_dashboard_viewer = isTableauViewer(auth_state.tableau_role);

  const use_tableau_dashboard = form.dashboard_type === 'tableau';
  const dashboard_type_selects: SelectData[] = useMemo(() => {
    const result = [{ name: dashboardTypeToJp('usual'), value: 'usual' }];
    // todo 一時的にviewerも許可
    if (can_create_tableau_dashboard || can_create_tableau_dashboard_viewer) {
      result.push({ name: dashboardTypeToJp('tableau'), value: 'tableau' });
    }
    return result;
  }, [can_create_tableau_dashboard, can_create_tableau_dashboard_viewer]);

  const handleFinishClick = async () => {
    try {
      const request = dashboardPostValidator(form);
      const res = await dashboardsPostAPI(request);
      if (res.status === 200) {
        AlertDialog.show('ダッシュボードの作成に成功しました');
        handleDialogCloseClick();
      }
    } catch (e) {
      if (e instanceof Error) {
        AlertDialog.show(e.message);
      }
      return;
    }
  };

  const { dashboard_name, dashboard_type } = form;

  // -- render part --
  return (
    <PfDialog title='ダッシュボード作成' isOpen={is_open} onClose={handleDialogCloseClick}>
      <Content>
        <InputComponent text='ダッシュボード名' required>
          <InputBox
            title='ダッシュボード名'
            placeholder='入力してください(必須)'
            value={dashboard_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateForm({ dashboard_name: e.currentTarget.value })}
          />
        </InputComponent>
        {can_create_tableau_dashboard || can_create_tableau_dashboard_viewer ? (
          <InputComponent text='ダッシュボードタイプ' required>
            <RadioBox
              datas={dashboard_type_selects}
              selectedValue={dashboard_type}
              handleChangeClick={(value) => updateForm({ dashboard_type: value as DashboardType })}
            />
          </InputComponent>
        ) : null}
        {use_tableau_dashboard && (
          // todo 現状はカウントしかダッシュボードを用意しないためコメントアウト中
          <></>
          // <InputComponent text='Tableauデータタイプ' required>
          //   <RadioBox
          //     datas={select_tableau_data_type}
          //     selectedValue={tableau_data_type}
          //     handleChangeClick={(value) => updateForm({ tableau_data_type: value as TableauDataType })}
          //   />
          // </InputComponent>
        )}

        <Footer>
          <RoundedButton onClick={handleFinishClick} text_type='CREATE' />
        </Footer>
      </Content>
    </PfDialog>
  );
}

// -- finally export part --

export default DashboardsCreateDialog;
