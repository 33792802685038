import React from 'react';
import PfDialog from 'shared/components/atoms/PfDialog';
import PfHlsPlayer from 'shared/components/atoms/PfHlsPlayer';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import styled from 'styled-components';
import { soracomParameterReflectionPostAPI } from 'user/api/device';
import { AppParamResponse, streamsProcessinfoAppparamGetAPI } from 'user/api/streams';
import { streamsIdDataNumberM3u8Url } from 'user/api/streamsData';

interface InferreddataThumbnailPlayerDialogProps {
  device_id: string;
  stream_id: string;
  stream_data_number: number;
  root_channel_process_pk: string;
  isOpen: boolean;
  onCloseClick: () => void;
}
interface InferreddataThumbnailPlayerDialogStete {
  playerAborted: boolean;
  appParameter: AppParamResponse | undefined;
}
export default class InferreddataThumbnailPlayerDialog extends React.PureComponent<
  InferreddataThumbnailPlayerDialogProps,
  InferreddataThumbnailPlayerDialogStete
> {
  private playerRef = React.createRef<HTMLVideoElement>();
  constructor(props: InferreddataThumbnailPlayerDialogProps) {
    super(props);
    this.state = {
      playerAborted: false,
      appParameter: undefined,
    };
  }

  componentDidMount() {
    this.getAppParameter();
  }

  private getAppParameter = async () => {
    const res = await streamsProcessinfoAppparamGetAPI({
      root_channel_process_pk: this.props.root_channel_process_pk,
    });
    if (res.status === 200) {
      this.setState({
        appParameter: res.data.items,
      });
    }
  };

  // 設定反映ボタンを押下した時の関数
  private handleReflectionClick = async () => {
    ConfirmDialog.show('デバイス設定反映に1分ほどかかりますが、よろしいですか？', () => this.reflectionProcess());
  };

  private reflectionProcess = async () => {
    if (this.state.appParameter) {
      // [TODO] ここの型どうにかしたい
      const postAppParameter: Record<string, string | number | number[][][] | Record<string, string>> = {
        ...this.state.appParameter,
      };
      const res = await soracomParameterReflectionPostAPI({
        device_id: this.props.device_id,
        app_parameter: postAppParameter,
      });
      if (res.status === 200) {
        AlertDialog.show('デバイス設定反映が完了しました。');
      }
    }
  };

  render() {
    const url = streamsIdDataNumberM3u8Url(this.props.stream_id, this.props.stream_data_number);
    return (
      <PfDialog
        isOpen={this.props.isOpen}
        onClose={this.props.onCloseClick}
        title='ビデオデータの再生'
        style={{ maxWidth: 1000 }}
      >
        <PlayerArea>
          {!this.state.playerAborted && (
            <PfHlsPlayer
              playerRef={this.playerRef}
              url={url}
              width='auto'
              height={370}
              controls={true}
              loop={false}
              muted
            />
          )}
          {this.state.appParameter && (
            <RoundedCardRightArea>
              <TextsArea>
                <TextArea>
                  <div>閾値</div>
                  <ValueText> {this.state.appParameter.confidenceThreshold}</ValueText>
                </TextArea>
              </TextsArea>
              <RoundedButton onClick={() => this.handleReflectionClick()} text='デバイスに設定反映' />
            </RoundedCardRightArea>
          )}
        </PlayerArea>
      </PfDialog>
    );
  }
}

// -- styled components --

const PlayerArea = styled.div`
  display: flex;
  justify-content: center;
`;

const RoundedCardRightArea = styled.div`
  margin-left: 20px;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const TextsArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const ValueText = styled.div`
  margin-top: 5px;
  font-weight: bold;
`;
