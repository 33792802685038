// -- basic library --
import React from 'react';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableHeaderType, TableBodyClickType } from 'shared/components/molecules/Table/type';

const headers: TableHeaderType[] = [
  {
    id: 'role_id',
    label: 'ロールID',
    width: 'auto',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'role_name',
    label: 'ロール名',
    width: 'auto',
    sortable: true,
    search_props: {
      type: 'name',
      default_display: true,
    },
  },
];

interface Params {
  datas: TableRoleType[];
  selected_datas: TableRoleType[];
  handleCheckClick: (datas: TableRoleType[]) => void;
  footer_option: {
    text: string;
    handleClick: () => void;
    disabled?: boolean;
  };
}

export type TableRoleType = {
  id: string;
  role_id: TableBodyClickType;
  role_name: string;
};

// -- external functions --

// -- main component --
const RolesTable: React.FC<Params> = (params) => {
  // -- render part --
  return (
    <BaseTable
      bodies={params.datas}
      headers={headers}
      table_name='roles'
      selected_bodies={params.selected_datas}
      handleCheckClick={params.handleCheckClick}
      id_abridgement
      footer_option={params.footer_option}
    />
  );
};

// -- styled components --

// -- finally export part --
export default RolesTable;
