// -- basic library --
import React from 'react';

// -- external components --
import FileBox from 'shared/components/atoms/FileBox';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import { isRect } from 'shared/models/Rect';

// -- external functions --
import { isNDimentionTypeArray, is2DLen2Array, isRangeLen2Array } from 'shared/utils/is';
import { getJsonReadedAsTextFromFile } from 'shared/utils/get';
import { SettingExpImpValue } from 'user/components/molecules/SettingExpImpButton/types';

export interface ImportSettingUseValuesKey {
  crosslines?: boolean;
  trimedImage?: boolean;
  stayarea_crosslines?: boolean;
}

interface ImportSettingFileDialogProps {
  isOpen: boolean;
  onClose: (value?: SettingExpImpValue) => void;
  /**
   * trueにしたvalueのkeyはバリデーションを行いvalueとして受け取れる
   */
  use_values?: ImportSettingUseValuesKey;
}
interface ImportSettingFileDialogState {
  value?: SettingExpImpValue;
  file_name?: string;
}
/** application/jsonファイルを選択して、読み込み、jsonデータを渡すダイアログです */
export default class ImportSettingFileDialog extends React.PureComponent<
  ImportSettingFileDialogProps,
  ImportSettingFileDialogState
> {
  constructor(props: ImportSettingFileDialogProps) {
    super(props);
    this.state = {};
  }
  private onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files !== null && e.currentTarget.files[0]) {
      this.setState({ file_name: e.currentTarget.files[0].name });
      // ここで、受け取ったファイルを読み込んで配列にしてvalueに格納
      getJsonReadedAsTextFromFile(e.currentTarget.files[0], (result) => this.setState({ value: result }));
    }
  };
  /**
   * 入力チェックを行い、エラーの場合はメッセージを含む例外をスローします。
   */
  private validate = () => {
    const { value } = this.state;
    const { use_values } = this.props;
    // 検知ラインのバリデーション
    if (use_values?.crosslines && value?.crosslines) {
      const crosslines = value?.crosslines;
      if (!isNDimentionTypeArray(crosslines, 3, 'number')) {
        throw new Error(
          '入力されたjsonファイルの「crosslines」が無効です。\n number[0~4][2][2]の形式である必要があります',
        );
      }
      if (crosslines.length > 4) {
        throw new Error(
          '入力されたjsonファイルの「crosslines」が無効です。\n crosslines.length(crosslinesの要素数)は4以下です ',
        );
      }
      for (let i = 0; i < crosslines.length; i++) {
        if (!is2DLen2Array(crosslines[i])) {
          throw new Error(
            `入力されたjsonファイルの「crosslines」が無効です。\n *${i}番目の配列がnumber[n][2]の条件を満たしていません。`,
          );
        }
      }
    }
    // クロップのバリデーション
    if (use_values?.trimedImage && value?.trimedImage && value.trimedImage.cropArea) {
      const cropArea = value.trimedImage.cropArea;
      if (!isRect(cropArea)) {
        throw new Error(
          `入力されたjsonファイルの「cropArea」が無効です。\n *「top, right, bottom, left」は必須かつ、全てnumberである必要があります`,
        );
      }
    }
    // 滞留検知ラインのバリデーション
    if (use_values?.stayarea_crosslines && value?.stayarea_crosslines) {
      const stayarea_crosslines = value?.stayarea_crosslines;
      if (!isNDimentionTypeArray(stayarea_crosslines, 3, 'number')) {
        throw new Error(
          '入力されたjsonファイルの「stayarea_crosslines」が無効です。\n number[0~5][2][2]の形式である必要があります',
        );
      }
      if (stayarea_crosslines.length > 5) {
        throw new Error(
          '入力されたjsonファイルの「stayarea_crosslines」が無効です。\n stayarea_crosslines.length(stayarea_crosslinesの要素数)は5以下です ',
        );
      }
      for (let i = 0; i < stayarea_crosslines.length; i++) {
        const sc = stayarea_crosslines[i];
        if (!isRangeLen2Array(sc, 4, 5)) {
          throw new Error(
            `入力されたjsonファイルの「stayarea_crosslines」が無効です。\n *${i}番目の配列がnumber[4 or 5][2]の条件を満たしていません。`,
          );
        }
      }
      // 交差している and はみ出しているは、視覚的に見えないとわからないので、エラーチェックしていない(OKボタンの時にはエラーチェックが出るので、API通信時は問題ないです。)
    }
  };
  private onImport = () => {
    try {
      this.validate();
    } catch (e) {
      if (e instanceof Error) {
        AlertDialog.show(e.message);
      }
      return;
    }
    this.props.onClose(this.state.value);
  };
  render() {
    return (
      <PfDialog
        isOpen={this.props.isOpen}
        title='設定ファイルを選択'
        onClose={() => this.props.onClose()}
        contentProps={{ style: { display: 'flex', flexDirection: 'column' } }}
      >
        <InputComponent text='設定ファイルを選択'>
          <FileBox
            id='import-setting-file'
            onChange={this.onChange}
            title=''
            placeholder={`ファイルを選択してください(application/json)`}
            button_text='ファイルを選択'
            accept='application/json'
            file_name={this.state.file_name}
          />
        </InputComponent>
        <Footer>
          <RoundedButton
            text='キャンセル'
            onClick={() => this.props.onClose()}
            is_white={true}
            is_margin_right={true}
          />
          <RoundedButton text='インポート' onClick={this.onImport} />
        </Footer>
      </PfDialog>
    );
  }
}
