import React from 'react';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableHeaderType, TableBodyClickType } from 'shared/components/molecules/Table/type';

interface Params {
  datas: TableUserType[];
  selected_datas: TableUserType[];
  handleCheckClick: (datas: TableUserType[]) => void;
  footer_option: {
    text: string;
    handleClick: () => void;
    disabled?: boolean;
  };
}

export type TableUserType = {
  id: string; // 内部処理用user_id
  user_id: TableBodyClickType; // user_id
  login_id: string;
  user_name: string;
  mail_address: string;
  user_type: string;
  role_name: string;
};

// -- external functions --

// -- main component --
const UsersTable: React.FC<Params> = (params) => {
  const headers: TableHeaderType[] = [
    {
      id: 'user_id',
      label: 'ユーザーID',
      sortable: true,
      search_props: {
        type: 'name',
      },
    },
    {
      id: 'login_id',
      label: 'ログインID',
      sortable: true,
      search_props: {
        type: 'name',
      },
    },
    {
      id: 'user_name',
      label: '名前',
      sortable: true,
      search_props: {
        type: 'name',
        default_display: true,
      },
    },
    {
      id: 'mail_address',
      label: 'メールアドレス',
      search_props: {
        type: 'name',
      },
    },
    {
      id: 'user_type',
      label: 'ユーザタイプ',
      search_props: {
        type: 'name',
      },
    },
    {
      id: 'role_name',
      label: 'ロール',
      width: 'auto',
      search_props: {
        type: 'name',
      },
    },
  ];
  // -- render part --
  return (
    <BaseTable
      bodies={params.datas}
      headers={headers}
      table_name='users'
      selected_bodies={params.selected_datas}
      handleCheckClick={params.handleCheckClick}
      id_abridgement
      footer_option={params.footer_option}
    />
  );
};

// -- styled components --

// -- finally export part --
export default UsersTable;
