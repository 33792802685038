import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import InputBox from 'shared/components/atoms/InputBox';
import InputNumberBox from 'shared/components/atoms/InputNumberBox';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Content, Footer, SubTextBasicProcess } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import checkAppParameterRequired from 'user/utils/checkAppParameterRequired';
import { isNotOnlySpace, isValidDelaySeconds } from 'shared/utils/is';
import { Channel, channelsIdGetAPI } from 'user/api/channels';
import { channelsIdProcessesPostAPI } from 'user/api/channelsProcesses';
import { getAllProcesses, Process, ProcessAppParameter } from 'user/api/processes';
import { Stream, CachedStreams } from 'user/api/streams';
import AppParameterInput from 'user/components/molecules/AppParameterInput';
import { systemSelector } from 'user/redux/slices/systemSlice';
import ChannelProcessHeartbeatPart from './ChannelProcessHeartbeatPart';
import StreamDataRangeSelectionPart from './StreamDataRangeSelectionPart';
import StreamSelectionPart from './StreamSelectionPart';
import { isISO8601, ISO8601 } from 'shared/models/ISO8601';
import { dateToTimeStamp } from 'shared/utils/converter/date';
import SelectBox from 'shared/components/atoms/SelectBox';
import { keyBy } from 'shared/utils/converter';

type Params = {
  channel_id: string;
  is_open?: boolean;
  onClose: () => void;
};

// -- main component --
const CreateChannelProcessDialog: React.FC<Params> = (params) => {
  // -- redux preparations --
  const system_state = useSelector(systemSelector);

  // -- local states --
  const [channel, setChannel] = useState<Channel | undefined>(undefined);
  const [processes, setProcesses] = useState<Process[] | undefined>(undefined);
  const processes_record: Record<string, Process> = useMemo(() => {
    return processes ? keyBy(processes, (p) => p.process_id) : {};
  }, [processes]);
  const [streams, setStreams] = useState<Stream[] | undefined>(undefined);
  const [process_app_parameters, setProcessAppParameters] = useState<ProcessAppParameter[]>([]);
  // const [stream_count, setStreamCount] = useState<number>(0);
  // const [from_file_indexes, setFromFileIndexes] = useState<number[]>([]);
  // -- 入力用states --
  const [selected_process, setSelectedProcess] = useState<Process | undefined>();
  const [selectedStreamIds, setSelectedStreamIds] = useState<string[][]>([]);
  // const [input_streams, setInputStreams] = useState<{
  //   [index: number]: ChannelStream;
  // }>({});
  const [channel_process_name, setChannelProcessName] = useState<string>('');
  const [app_parameter, setAppParameter] = useState<Record<string, string>>({});
  const [heartbeat, setHeartbeat] = useState<Record<string, string>>({});
  const [delaySeconds, setDelaySeconds] = useState<number>(0);
  const [isTargetSpecify, setTargetSpecify] = useState<boolean>(false);
  const [targetTermFrom, setTargetTermFrom] = useState<ISO8601 | ''>('');
  const [targetTermTo, setTargetTermTo] = useState<ISO8601 | ''>('');

  // -- get external datas --
  const getProcessesDatas = () => {
    getAllProcesses().then((res) => setProcesses(res));
  };

  const getStreamsDatas = async () => {
    // [TODO] APIを必要に応じた回数だけ叩く
    // 暫定仕様で、可能な限りデータを取得している
    const stream_items: Stream[] = await new CachedStreams({
      with_output_streams: 'True',
      with_in_channel_streams: 'True',
    }).get();
    setStreams(stream_items);
  };

  const getChannelData = async (channel_id: string) => {
    const res = await channelsIdGetAPI({ channel_id: channel_id });
    if (res.status === 200) {
      setChannel(res.data);
    }
  };

  const handleChannelNameChangeClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChannelProcessName(e.currentTarget.value);
  };

  // プロセスが変わった時の関数
  const handleProcessChange = (process: Process) => {
    // プロセスに応じた入力データ(ストリーム)数とIDとapp_parameterの初期値を設定
    // let input_stream_count = 0;
    const app_parameter: { [key: string]: string } = {};
    let app_parameters: ProcessAppParameter[] = [];
    if (process) {
      // input_stream_count = process.input_stream_count;
      app_parameters = process.app_parameters;
      for (let i = 0; i < process.app_parameters.length; i++) {
        const key = process.app_parameters[i].key;
        app_parameter[key] = process.app_parameters[i].default_value;
      }
    }
    // setStreamCount(input_stream_count);
    setSelectedProcess(process);
    setAppParameter(app_parameter);
    setHeartbeat({});
    setProcessAppParameters(app_parameters);

    // // 入力データ(ストリーム)初期化
    // const stream: ChannelStream = {
    //   id: null,
    //   data: new File([], ''),
    // };
    // const return_from_file_indexes: number[] = []
    // const old_input_streams = { ...input_streams };
    // const return_input_streams: {
    //   [index: number]: ChannelStream;
    // } = {};
    // for (let index = 0; index < input_stream_count; index++) {
    //     if(from_file_indexes.indexOf(index) !== -1){
    //         return_from_file_indexes.push(index)
    //     }
    //   return_input_streams[index] = old_input_streams[index] ? old_input_streams[index] : stream;
    // }
    // setFromFileIndexes(return_from_file_indexes)
    // setInputStreams(return_input_streams);
    setSelectedStreamIds([]);
  };

  const handleStreamSelectionChange = (value: string[][]) => {
    setSelectedStreamIds(value);
  };
  const handleStreamDataRangeChange = (
    specify: boolean,
    stream_data_number_from?: ISO8601 | '',
    stream_data_number_to?: ISO8601 | '',
  ) => {
    setTargetSpecify(specify);
    if (stream_data_number_from !== undefined) {
      setTargetTermFrom(stream_data_number_from);
    }
    if (stream_data_number_to !== undefined) {
      setTargetTermTo(stream_data_number_to);
    }
  };

  // // ストリームにファイルが入力された時の関数
  // const handleInputSourceFileChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
  //   const file = e.currentTarget.files?.item(0);
  //   const stream = {
  //     id: null,
  //     data: new File([], ''),
  //   };
  //   if (file) {
  //     stream.data = file;
  //   }
  //   const old_input_streams = { ...input_streams };
  //   old_input_streams[index] = stream;
  //   setInputStreams(old_input_streams);
  // };

  // // ストリームが変わった時の関数
  // const handleStreamChange = (e: React.ChangeEvent<HTMLSelectElement>, index: number) => {
  //   const stream: ChannelStream = {
  //     id: null,
  //     data: new File([], ''),
  //   };
  //   if (e.currentTarget.value === 'fromFile') {
  //     setFromFileIndexes([...from_file_indexes, index]);
  //   } else {
  //     setFromFileIndexes(from_file_indexes.filter((from_file_index) => from_file_index !== index));
  //     stream.id = e.currentTarget.value === '' ? null : e.currentTarget.value;
  //   }

  //   const old_input_streams = { ...input_streams };
  //   old_input_streams[index] = stream;
  //   setInputStreams(old_input_streams);
  // };

  //app_parameterがどれかでも変わった時の関数
  const handleSetAppParameter = (value: string, key: string) => {
    const new_app_parameter = { ...app_parameter };
    new_app_parameter[key] = value;
    setAppParameter(new_app_parameter);
  };

  const handleFinishClick = async (channel_id: string, app_parameters: ProcessAppParameter[]) => {
    if (!isNotOnlySpace(channel_process_name)) {
      AlertDialog.show('入力欄に空欄があります');
      return;
    }
    if (!checkAppParameterRequired(app_parameter, app_parameters)) {
      AlertDialog.show('プロセスの詳細条件の入力が正しくありません');
      return;
    }
    if (!isValidDelaySeconds(delaySeconds)) {
      AlertDialog.show('配信の遅延を入力する場合は、0以上900以下の整数でなければなりません。※省略時は0となります。');
      return;
    }
    // const stream_ids2: string[][] = [];
    // for (const [i, v] of Object.entries(inputs.streams)) {
    //   const stream = v;
    //   if (stream.id) {
    //     stream_ids2.push([stream.id]);
    //     continue;
    //   }
    //   if (!stream.data.name) continue;

    //   let data_type = 'IMAGE';
    //   if (stream.data?.type.indexOf('image') !== -1) {
    //     data_type = 'IMAGE';
    //   } else if (stream.data?.type.indexOf('video') !== -1) {
    //     data_type = 'VIDEO';
    //   } else if (stream.data?.type.indexOf('audio') !== -1) {
    //     data_type = 'AUDIO';
    //   } else if (stream.data?.type.indexOf('application/pdf') !== -1) {
    //     data_type = 'PDF';
    //   } else if (stream.data?.type.indexOf('application/json') !== -1) {
    //     data_type = 'METRIC';
    //   }

    //   const res2 = await streamsPostAPI({
    //     stream_name: `${channel_process_name}_stream${Number(i) + 1}`,
    //     data_number_type: 'TIMESTAMP',
    //     data_type: data_type,
    //     data_store_period: '-1',
    //   });
    //   const res3 = await streamsIdDataPostAPI({
    //     stream_id: res2.data.stream_id,
    //     stream_data: stream.data,
    //   });

    //   stream_ids2.push([res3.data.stream_id]);
    // }

    // app_parameterにおいて、必須でない and 入力がスペースのみの場合は送らないようにデータ整形
    const app_parameter_for_post: { [key: string]: string } = {};
    for (let i = 0; i < app_parameters.length; i++) {
      const key = app_parameters[i].key;
      // 存在しない or スペースならスキップ
      if (!app_parameter[key] || !isNotOnlySpace(app_parameter[key])) continue;
      // すでにバリデーション済みなので存在するデータは全て送る
      app_parameter_for_post[key] = app_parameter[key];
    }

    const res4 = await channelsIdProcessesPostAPI({
      channel_id: channel_id,
      channel_process_name: channel_process_name,
      process_id: selected_process?.process_id || '',
      input_stream_ids: selectedStreamIds,
      app_parameter: app_parameter_for_post,
      heartbeat: heartbeat,
      delay_seconds: Number.isNaN(delaySeconds) ? undefined : delaySeconds,
      target_term_from: isTargetTermEnable() && isISO8601(targetTermFrom) ? dateToTimeStamp(targetTermFrom) : undefined,
      target_term_to: isTargetTermEnable() && isISO8601(targetTermTo) ? dateToTimeStamp(targetTermTo) : undefined,
    });

    // res4の正常を持って完了と判定する
    if (res4.status === 200) {
      params.onClose();
      AlertDialog.show('新規チャンネルプロセスの追加に成功しました');
    }
  };
  const isStreamSelected = () => {
    // ストリームが選択されていない
    if (selectedStreamIds.length !== selected_process?.input_stream_constraints.length) {
      return false;
    }
    for (const v of selectedStreamIds) {
      for (const w of v) {
        if (w === '') return false;
      }
    }
    return true;
  };
  /** 最初の入力データ(ストリーム)がTIMESTAMPかどうか判定します */
  const isTargetTermEnable = () => {
    if (selectedStreamIds.length === 0) {
      return false;
    }
    for (const s of streams || []) {
      if (s.stream_id === selectedStreamIds[0][0]) {
        return s.data_number_type === 'TIMESTAMP';
      }
    }
    return false;
  };
  // -- onload function --
  useEffect(() => {
    (async function () {
      // プロセス情報の取得
      await getProcessesDatas();
      await getStreamsDatas();
      await getChannelData(params.channel_id);
    })();
  }, []); /* eslint-disable-line */

  // -- render part --
  return (
    <PfDialog
      title='チャンネルプロセス作成'
      isOpen={params.is_open === undefined ? true : params.is_open}
      onClose={params.onClose}
    >
      <Content>
        {system_state.loading.preloading ||
        streams === undefined ||
        processes === undefined ||
        channel === undefined ? (
          <Spinner />
        ) : (
          <>
            <InputComponent text='チャンネルプロセス名' required>
              <InputBox
                title='チャンネルプロセス名'
                placeholder='入力してください'
                value={channel_process_name}
                onChange={handleChannelNameChangeClick}
              />
            </InputComponent>
            <InputComponent text='プロセス' required>
              <SubTextBasicProcess>基本プロセスを選択してください</SubTextBasicProcess>
              <SelectBox
                data-testid='process-select'
                value={selected_process?.process_id ?? ''}
                datas={processes.map((p) => {
                  return { name: p.process_name, value: p.process_id };
                })}
                onChange={(e) => handleProcessChange(processes_record[e.currentTarget.value])}
                long
              />
            </InputComponent>
            {selected_process && (
              <>
                <StreamSelectionPart
                  process={selected_process}
                  streams={streams}
                  selected_stream_ids={selectedStreamIds}
                  onChange={handleStreamSelectionChange}
                />
                {isTargetTermEnable() && (
                  <StreamDataRangeSelectionPart
                    is_specify={isTargetSpecify}
                    stream_data_number_from={targetTermFrom}
                    stream_data_number_to={targetTermTo}
                    onChange={handleStreamDataRangeChange}
                  />
                )}
                {process_app_parameters.length !== 0 ? (
                  <InputComponent text='プロセスの詳細条件' required>
                    {process_app_parameters.map((d, index) => {
                      return (
                        <AppParameterInput
                          key={index}
                          process_app_parameter={d}
                          app_parameter={String(app_parameter[d.key])}
                          streams={streams.filter((v) => selectedStreamIds.flat().indexOf(v.stream_id) !== -1)}
                          onChange={(app_parameter_value) => handleSetAppParameter(app_parameter_value, d.key)}
                        />
                      );
                    })}
                  </InputComponent>
                ) : null}
                <ChannelProcessHeartbeatPart
                  key={selected_process.process_id}
                  process={selected_process}
                  value={heartbeat}
                  onChange={(v) => setHeartbeat(v)}
                />
                <InputComponent text='配信の遅延'>
                  <InputNumberBox value={delaySeconds} onChange={setDelaySeconds} min={0} max={900} />
                </InputComponent>
              </>
            )}
            {/* {Array(stream_count)
                .fill(null)
                .map((_, index) => {
                  return (
                    <div key={index}>
                      <InputComponent text={`入力データ(${index + 1})`} required>
                        <SelectBox
                          default_value=""
                          handleChangeClick={(e) => handleStreamChange(e, index)}
                          datas={[
                            ...streams.map((d) => {
                              return {
                                name: d.stream_name,
                                value: d.stream_id,
                              };
                            }),
                            {
                              name: 'ファイルから選択する',
                              value: 'fromFile',
                            },
                          ]}
                          long={true}
                        />
                        {from_file_indexes.indexOf(index) >= 0 ? (
                          <FileBox
                            id={`channel_stream_input_file${index + 100}`}
                            handleChangeClick={(e) => handleInputSourceFileChange(e, index)}
                            key={index + 100}
                            text=""
                            placeholder="ファイルを選択してください"
                            button_text="ファイルを選択"
                            accept="video/*, image/*, audio/*, application/json, application/pdf"
                            file_name={input_streams[index].data.name}
                            style={{ marginTop: styles.interval_narrow_margin }}
                          />
                        ) : null}
                      </InputComponent>
                    </div>
                  );
                })} */}
            <Footer>
              <RoundedButton
                onClick={() => handleFinishClick(params.channel_id, process_app_parameters)}
                text_type='CREATE'
                disabled={Boolean(
                  !isNotOnlySpace(channel_process_name) ||
                    !isStreamSelected() ||
                    !checkAppParameterRequired(app_parameter, process_app_parameters),
                )}
              />
            </Footer>
          </>
        )}
      </Content>
    </PfDialog>
  );
};

// -- styled components --

// -- finally export part --

export default CreateChannelProcessDialog;
