import { Branded } from '.';

/**
 * 文字列の時刻型
 * hh:mm
 */
export type TimeString = Branded<string, 'TimeString'>;
/**
 *
 * @param str: string
 * @return boolean: hh:mm の形式ならばtrue
 */
export const isTimeString = (str?: string | null): str is TimeString => {
  if (typeof str !== 'string') return false;
  const correctPattern = new RegExp('^[0-9]{2}:[0-9]{2}$');
  const match = str.match(correctPattern);
  return match !== null;
};

export const toTimeString = (str: string): TimeString => {
  if (isTimeString(str)) return str;
  const today = new Date();
  const hour = ('00' + today.getHours()).slice(-2);
  const minute = ('00' + today.getMinutes()).slice(-2);
  return `${hour}:${minute}` as TimeString;
};

export const timeStringHour = (timeString: TimeString) => {
  const hms = timeString.split(':');
  return Number(hms[0]);
};

export const timeStringMinute = (timeString: TimeString) => {
  const hms = timeString.split(':');
  return Number(hms[1]);
};

export const timeStringSecond = (timeString: TimeString) => {
  const hms = timeString.split(':');
  return hms[2] ? Number(hms[2]) : 0;
};

export const timeStringToSecond = (timeString: TimeString): number => {
  const hour = timeStringHour(timeString);
  const minute = timeStringMinute(timeString);
  const second = timeStringSecond(timeString);
  return hour * 3600 + minute * 60 + second;
};
