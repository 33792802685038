// -- basic library --
import React from 'react';
import { DeleteIcon } from 'shared/components/atoms/Icons';
import styles from 'shared/styles/styles';
import { StreamDataFileType } from 'shared/models';
import styled from 'styled-components';

import FileBox from '../atoms/FileBox';
import { FlexCenterDiv } from './ContentsArea';

// -- external datas --

// -- type declaration --
interface Params {
  files: StreamDataFileType[];
  setFiles: (files: StreamDataFileType[]) => void;
  accept?: string;
  test_id?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
}

// -- main component --
/**
 * 複数ファイルを選択できるコンポーネント
 * **/
const MultiFileBox: React.FC<Params> = (params) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files !== null) {
      const new_stream_data_files = [];
      for (let i = 0; i < e.currentTarget.files.length; i++) {
        const file = e.currentTarget.files[i];
        const file_name = file.name || '';
        new_stream_data_files.push({
          file: file,
          file_name: file_name,
        });
      }
      params.setFiles([...params.files, ...new_stream_data_files]);
    }
  };

  const deleteFileWhereIndex = (index: number) => {
    const new_stream_data_files = params.files.filter((f, i) => i !== index);
    params.setFiles(new_stream_data_files);
  };
  // -- render part --
  return (
    <div style={{ ...params.style, display: 'block', width: '100%' }}>
      {params.files.map((file, i) => {
        return (
          <MarginFlexCenterDiv align_center={true} key={`${file.file_name}_${i}`}>
            <FileBox accept={params.accept} file_name={file.file_name} id={`${file.file_name}_${i}`} />
            <DeleteIcon onClick={() => deleteFileWhereIndex(i)} iconSize={20} style={{ marginLeft: 3 }} />
          </MarginFlexCenterDiv>
        );
      })}

      <FileBox
        disabled={params.disabled}
        onChange={onChange}
        placeholder={`${params.accept}`}
        button_text='ファイルを選択'
        accept={params.accept}
        id='file-selector'
        style={{ width: '70%', maxWidth: 330 }}
        multiple={true}
      />
    </div>
  );
};

const MarginFlexCenterDiv = styled(FlexCenterDiv)`
  margin-bottom: ${styles.interval_narrow_margin};
`;

// -- finally export part --

export default MultiFileBox;
