// -- basic library --
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import AppParameterInput from 'user/components/molecules/AppParameterInput';
import { Footer, SubTextBasicProcess } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import ProcessPanels from 'shared/components/molecules/ProcessPanels';
import checkAppParameterRequired from 'user/utils/checkAppParameterRequired';
import { isNotOnlySpace } from 'shared/utils/is';
import { Channel, channelsIdGetAPI } from 'user/api/channels';
import { channelsIdProcessesPostAPI } from 'user/api/channelsProcesses';
import { CachedProcesses, Process, ProcessAppParameter } from 'user/api/processes';
import { Stream, CachedStreams } from 'user/api/streams';
import ChannelProcessHeartbeatPart from 'user/dialogs/CreateChannelProcessDialog/ChannelProcessHeartbeatPart';
import StreamSelectionPart from 'user/dialogs/CreateChannelProcessDialog/StreamSelectionPart';
import { systemSelector } from 'user/redux/slices/systemSlice';

// -- types --

interface Params {
  channel_id: string;
  stream: Stream;
  cachedStreams: CachedStreams;
  cachedProcesses: CachedProcesses;
  stream_data_number: string | number;
  inferreddata_name: string;
  isOpen?: boolean;
  onClose: (p?: { isCanceled?: boolean }) => void;
}

// -- main component --
const ProcessPanel: React.FC<Params> = (params) => {
  // -- redux preparations --
  const system_state = useSelector(systemSelector);

  // -- local states --
  const [channel, setChannel] = useState<Channel | undefined>(undefined);
  const [processes, setProcesses] = useState<Process[] | undefined>(undefined);

  const [streams, setStreams] = useState<Stream[] | undefined>(undefined);
  const [process_app_parameters, setProcessAppParameters] = useState<ProcessAppParameter[]>([]);

  // -- 入力用states --
  const [selectedProcess, setSelectedProcess] = useState<Process | undefined>();
  const [selectedStreamIds, setSelectedStreamIds] = useState<string[][]>([]);
  const [app_parameter, setAppParameter] = useState<Record<string, string>>({});
  const [heartbeat, setHeartbeat] = useState<Record<string, string>>({});

  const getProcesses = () => {
    params.cachedProcesses.get().then((data) => {
      setProcesses(data);
    });
  };

  const getStreamsDatas = () => {
    params.cachedStreams.get().then((data) => {
      // streamDataの親streamだけは選択できるようにする。with_in_channel_streams: 'False'としているため、この操作がないと、選択できなくなる
      setStreams([params.stream, ...data]);
    });
  };

  const getChannelData = async (channel_id: string) => {
    const res = await channelsIdGetAPI({ channel_id: channel_id });
    if (res.status === 200) {
      setChannel(res.data);
    }
  };

  // validate
  /**
   * 入力チェックを行い、エラーの場合はメッセージを含む例外をスローします。
   */
  const validate = () => {
    if (!selectedProcess) {
      throw new Error('プロセスを選択してください');
    }
    if (!selectedProcess.can_be_oneshot) {
      throw new Error('指定のプロセスはワンショットに対応していない為、選択できません。');
    }
    if (!isStreamSelected()) {
      throw new Error('データを選択してください');
    }
    if (!checkAppParameterRequired(app_parameter, process_app_parameters)) {
      throw new Error('プロセスの詳細条件を正しく入力してください');
    }
  };

  // -- handlers --

  // プロセスが変わった時の関数
  const handleProcessChange = (process: Process) => {
    // プロセスに応じた入力データ(ストリーム)数とIDとapp_parameterの初期値を設定
    const app_parameter: { [key: string]: string } = {};
    let app_parameters: ProcessAppParameter[] = [];
    if (process) {
      app_parameters = process.app_parameters;
      for (let i = 0; i < process.app_parameters.length; i++) {
        const key = process.app_parameters[i].key;
        app_parameter[key] = process.app_parameters[i].default_value;
      }
    }
    setSelectedProcess(process);
    setAppParameter(app_parameter);
    setHeartbeat({});
    setProcessAppParameters(app_parameters);
    // 入力データ(ストリーム)が必要な場合、1つ目の入力streamは、streamDataの親フォルダ(親stream)を指定する
    if (process.input_stream_constraints.length > 0) {
      setSelectedStreamIds([[params.stream.stream_id]]);
    }
  };

  const handleStreamSelectionChange = (value: string[][]) => {
    setSelectedStreamIds(value);
  };

  //app_parameterがどれかでも変わった時の関数
  const handleSetAppParameter = (value: string, key: string) => {
    const new_app_parameter = { ...app_parameter };
    new_app_parameter[key] = value;
    setAppParameter(new_app_parameter);
  };

  const handleFinishClick = async (p: {
    app_parameters: ProcessAppParameter[];
    channel: Channel;
    selectedProcess: Process | undefined;
    inputs: {
      app_parameter: Record<string, string>;
    };
  }) => {
    // 入力チェック
    try {
      validate();
    } catch (e) {
      if (e instanceof Error) {
        AlertDialog.show(e.message);
      }
      return;
    }

    // app_parameterにおいて、必須でない and 入力がスペースのみの場合は送らないようにデータ整形
    const app_parameter_for_post: { [key: string]: string } = {};
    for (let i = 0; i < p.app_parameters.length; i++) {
      const key = p.app_parameters[i].key;
      // 存在しない or スペースならスキップ
      if (!p.inputs.app_parameter[key] || !isNotOnlySpace(p.inputs.app_parameter[key])) continue;
      // すでにバリデーション済みなので存在するデータは全て送る
      app_parameter_for_post[key] = p.inputs.app_parameter[key];
    }

    const res = await channelsIdProcessesPostAPI({
      channel_id: params.channel_id,
      channel_process_name: params.inferreddata_name,
      process_id: p.selectedProcess?.process_id || '',
      input_stream_ids: selectedStreamIds,
      app_parameter: app_parameter_for_post,
      heartbeat: heartbeat,
      input_stream_data_number: String(params.stream_data_number),
      is_oneshot: 'True',
    });

    // resの正常を持って完了と判定する
    if (res.status === 200) {
      params.onClose();
      AlertDialog.show('新規チャンネルプロセスの追加に成功しました');
    }
  };
  const isStreamSelected = () => {
    // ストリームが選択されていない
    if (selectedStreamIds.length !== selectedProcess?.input_stream_constraints.length) {
      return false;
    }
    for (const v of selectedStreamIds) {
      for (const w of v) {
        if (w === '') return false;
      }
    }
    return true;
  };
  // -- onload function --
  useEffect(() => {
    (async function () {
      getProcesses();
      getStreamsDatas();
      await getChannelData(params.channel_id);
    })();
  }, []); /* eslint-disable-line */

  // -- render part --
  return (
    <>
      {system_state.loading.preloading || streams === undefined || channel === undefined || processes === undefined ? (
        <Spinner />
      ) : (
        <>
          <InputComponent text='プロセス' required>
            <SubTextBasicProcess>基本プロセスを選択してください</SubTextBasicProcess>
            <ProcessPanels
              processes={processes}
              selected_id={selectedProcess?.process_id || ''}
              onClick={handleProcessChange}
            />
          </InputComponent>
          {selectedProcess && (
            <>
              <StreamSelectionPart
                process={selectedProcess}
                streams={streams}
                selected_stream_ids={selectedStreamIds}
                onChange={handleStreamSelectionChange}
                fixed_first_input_stream={true}
              />
              {process_app_parameters.length !== 0 ? (
                <InputComponent text='プロセスの詳細条件' required>
                  {process_app_parameters.map((d, index) => {
                    return (
                      <AppParameterInput
                        key={index}
                        process_app_parameter={d}
                        app_parameter={String(app_parameter[d.key])}
                        stream_data_number={Number(params.stream_data_number)}
                        streams={
                          selectedProcess.input_stream_constraints.length <= 0
                            ? []
                            : streams.filter((v) => selectedStreamIds.flat().indexOf(v.stream_id) !== -1)
                        }
                        onChange={(app_parameter_value) => handleSetAppParameter(app_parameter_value, d.key)}
                      />
                    );
                  })}
                </InputComponent>
              ) : null}
              <ChannelProcessHeartbeatPart
                key={selectedProcess.process_id}
                process={selectedProcess}
                value={heartbeat}
                onChange={(v) => setHeartbeat(v)}
              />
            </>
          )}
          <Footer>
            <RoundedButton
              onClick={() =>
                handleFinishClick({
                  app_parameters: process_app_parameters,
                  channel: channel,
                  selectedProcess: selectedProcess,
                  inputs: {
                    app_parameter: app_parameter,
                  },
                })
              }
              text='登録'
            />
          </Footer>
        </>
      )}
    </>
  );
};

// -- styled components --

// -- finally export part --

export default ProcessPanel;
