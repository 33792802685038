import React from 'react';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { is2DLen2Array } from 'shared/utils/is';
import { checkXYWithinRange } from 'shared/utils/else/crosslineFunctions';
import { checkRangeTrimRect } from 'shared/utils/else/trimFunctions';
import { TrimedImage } from 'shared/models';
import { streamsIdDataGetAPI, streamsIdDataNumberThumbnailGetAPI } from 'user/api/streamsData';
import CrossLineStepPanel from './CrossLineStepPanel';
// -- type declaration --

interface CrossLine1DialogProps {
  is_open: boolean;
  stream_id: string;
  stream_data_number?: number;
  value: string; // app_parameterのstringifiedされたjson
  onClose: (value?: string) => void;
}
interface CrossLine1DialogState {
  imgurl: string;
  trimed_image: TrimedImage;
  crosslines: number[][][];
}

/** JSON文字列をパースします。異常値の場合は {} にします。 */
function parse(s: string) {
  try {
    return JSON.parse(s);
  } catch (e) {
    return {};
  }
}

export default class CrossLine1Dialog extends React.PureComponent<CrossLine1DialogProps, CrossLine1DialogState> {
  constructor(props: CrossLine1DialogProps) {
    super(props);
    this.state = {
      imgurl: '',
      trimed_image: {
        url: '',
        rotate: 0,
      },
      crosslines: [],
    };
  }

  componentDidMount() {
    // パラメータにストリームデータ番号がある場合はそのサムネイルを取得する
    // 無い場合はストリームの最終のストリームデータを取得する（最新映像）
    if (this.props.stream_data_number) {
      this.loadThumbnail(this.props.stream_id, this.props.stream_data_number);
    } else {
      streamsIdDataGetAPI({ stream_id: this.props.stream_id, scan_index_forward: 'False' }).then((res) => {
        if (res.status === 200 && res.data.items.length !== 0) {
          this.loadThumbnail(this.props.stream_id, res.data.items[0].stream_data_number);
        }
      });
    }
    this.loadValue();
  }

  // サムネイル画像の取得
  private loadThumbnail = (streamId: string, streamDataNumber: number) => {
    streamsIdDataNumberThumbnailGetAPI({
      stream_id: streamId,
      stream_data_number: String(streamDataNumber),
      stream_type: 'original',
    }).then((res) => {
      if (res.status === 200) {
        const blob = new Blob([res.data], { type: 'image/png' });
        const url = window.URL || window.webkitURL;
        this.setState({ imgurl: url.createObjectURL(blob) });
        // ローカルでテストする用
        // this.setState({
        //   imgurl: 'https://idea-security-demo-hls-tmp-hasegawa.s3.ap-northeast-1.amazonaws.com/tairyuu1.jpeg',
        // });
      }
    });
  };
  // app_parameterはstringifyされてるからparseしてstateに格納
  private loadValue = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const app_parameter: any = parse(this.props.value);
    let new_trimed_image = { ...this.state.trimed_image };
    if (app_parameter.cropArea) {
      new_trimed_image = {
        ...new_trimed_image,
        cropArea: app_parameter.cropArea,
      };
    }
    if (app_parameter.cropRotate) {
      new_trimed_image = {
        ...new_trimed_image,
        rotate: app_parameter.cropRotate,
      };
    }
    this.setState({
      trimed_image: new_trimed_image,
    });
    if (app_parameter.crossLine) {
      this.setState({
        crosslines: app_parameter.crossLine,
      });
    }
  };

  // トリミングで完了を押した時の関数
  private onTrimPanelChange = (trimed_image: TrimedImage) => {
    // トリミングされた画像のデータを更新
    this.setState({ trimed_image });
  };

  /**
   * 入力チェックを行い、エラーの場合はメッセージを含む例外をスローします。
   */
  private validate = () => {
    if (
      !this.state.trimed_image.cropArea ||
      !checkRangeTrimRect({
        rect: this.state.trimed_image.cropArea,
        size: {
          // coordinateは割合で保持しているので0~1
          width: 1,
          height: 1,
        },
      })
    ) {
      throw new Error(`クロップエリアが、画像をはみ出しています。`);
    }
    if (this.state.crosslines.length < 1) {
      throw new Error(`検知ラインを描画してください`);
    }
    if (this.state.crosslines.length < 1) {
      throw new Error(`検知ラインを描画してください`);
    }
    if (this.state.crosslines.length > 4) {
      throw new Error(`検知ラインの最大描画数は4です`);
    }
    for (let i = 0; i < this.state.crosslines.length; i++) {
      const crossline = this.state.crosslines[i];
      if (!is2DLen2Array(crossline)) {
        throw new Error(
          `${i}番目の検知ラインの座標の形が正しくありません。\n 検知ラインはnumber[2][2]を満たす必要があります。`,
        );
      }
      if (
        !checkXYWithinRange({
          crossline: crossline,
          size: {
            // 割合で保持しているので1を超えないかどうか
            width: 1,
            height: 1,
          },
        })
      ) {
        throw new Error(`${i}番目の検知ラインの座標が画面をはみ出しています。`);
      }
    }
  };

  // パネルを終了する時の関数
  // ここで、トリミングと検知ラインのデータを呼び出し元に送る
  private onPanelFinish = () => {
    // 入力チェック
    try {
      this.validate();
    } catch (e) {
      if (e instanceof Error) {
        AlertDialog.show(e.message);
      }
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const joined_coordinates: Record<string, any> = {
      cropArea: this.state.trimed_image.cropArea,
      crossLine: this.state.crosslines,
    };
    if (this.state.trimed_image.size?.width) {
      joined_coordinates.width = this.state.trimed_image.size.width;
    }
    if (this.state.trimed_image.size?.height) {
      joined_coordinates.height = this.state.trimed_image.size.height;
    }
    // 回転が存在していれば追加する
    if (this.state.trimed_image.rotate !== undefined) {
      joined_coordinates.cropRotate = this.state.trimed_image.rotate;
    }
    this.props.onClose(JSON.stringify(joined_coordinates));
  };
  render() {
    return (
      <PfDialog isOpen={this.props.is_open} onClose={() => this.props.onClose()} title='検知ライン設定' large={true}>
        <CrossLineStepPanel
          imgsrc={this.state.imgurl}
          trimed_image={this.state.trimed_image}
          crosslines={this.state.crosslines}
          onClose={() => this.props.onClose()}
          onCrosslinesChange={(crosslines) =>
            this.setState({
              crosslines: crosslines,
            })
          }
          onTrimedImageChange={this.onTrimPanelChange}
          onPanelFinish={this.onPanelFinish}
        />
      </PfDialog>
    );
  }
}

interface CrossLine1DialogButtonProps {
  streamId?: string;
  streamDataNumber?: number;
  value: string; // app_parameterのstringifiedされたjson
  onChange: (value: string) => void;
}
interface CrossLine1DialogButtonState {
  isOpen: boolean;
}
/** 検知ライン設定ダイアログを開くためのボタンです。 */
export class CrossLine1DialogButton extends React.PureComponent<
  CrossLine1DialogButtonProps,
  CrossLine1DialogButtonState
> {
  constructor(props: CrossLine1DialogButtonProps) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  private handleOpen = () => {
    this.setState({ isOpen: true });
  };
  private handleClose = (value?: string) => {
    this.setState({ isOpen: false });
    if (value !== undefined) {
      this.props.onChange(value);
    }
  };
  render() {
    return (
      <>
        <RoundedButton text='検知ライン設定' onClick={this.handleOpen} disabled={(this.props.streamId || '') === ''} />
        {this.state.isOpen && this.props.streamId && (
          <CrossLine1Dialog
            is_open={true}
            stream_id={this.props.streamId}
            value={this.props.value}
            stream_data_number={this.props.streamDataNumber}
            onClose={this.handleClose}
          />
        )}
      </>
    );
  }
}
