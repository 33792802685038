import { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy } from 'shared/utils/converter';
import { CachedChannelProcesses, ChannelProcess, RequestchannelsIdProcessesGet } from 'user/api/channelsProcesses';

/**
 * チャンネルプロセス一覧を取得するcustom hooks
 */
export const useChannelProcesses = ({
  channel_id,
  exclusive_start_channel_process_number,
}: RequestchannelsIdProcessesGet) => {
  const [channel_processes, setChannelProcesses] = useState<ChannelProcess[] | undefined>(undefined);
  const channel_processes_record: Record<string, ChannelProcess> = useMemo(() => {
    return channel_processes ? keyBy(channel_processes, (c) => String(c.channel_process_number)) : {};
  }, [channel_processes]);
  const loadChannelProcesses = useCallback(
    async (request?: RequestchannelsIdProcessesGet) => {
      const r = request ?? { channel_id, exclusive_start_channel_process_number } ?? {};
      const new_channel_processes = await new CachedChannelProcesses(r).get();
      setChannelProcesses(new_channel_processes);
    },
    [channel_id, exclusive_start_channel_process_number],
  );

  useEffect(() => {
    loadChannelProcesses();
  }, [loadChannelProcesses]);

  return useMemo(() => {
    return {
      channel_processes,
      channel_processes_record,
      setChannelProcesses,
      loadChannelProcesses,
    };
  }, [channel_processes, channel_processes_record, setChannelProcesses, loadChannelProcesses]);
};
