import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setLoginId, setRememberMe } from 'shared/utils/else/localStorage';
import { setAccessKey, setRefreshToken } from 'shared/utils/else/sessionStorageFunctions';
import RootState from '../rootState';
import { TableauRole } from 'shared/models/TableauRole';

// state の型
export interface ReduxAuthState {
  is_logged_in: boolean;
  session: string;
  is_admin: boolean;
  tableau_role: TableauRole;
}

// state の初期値
const initialState: ReduxAuthState = {
  is_logged_in: false,
  session: '',
  is_admin: false,
  tableau_role: 'Not-used',
};

// actions と reducers の定義
const slice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    signInFirst: (
      state,
      action: PayloadAction<{
        session: ReduxAuthState['session'];
        login_id: string;
        remember_me?: boolean;
        is_admin: ReduxAuthState['is_admin'];
        tableau_role: ReduxAuthState['tableau_role'];
      }>,
    ): void => {
      state.session = action.payload.session;
      state.is_admin = action.payload.is_admin;
      state.tableau_role = action.payload.tableau_role;
      // remember_meとlogin_idは別途local storageに保存する
      setRememberMe(action.payload.remember_me);
      setLoginId(action.payload.login_id);
    },
    signIn: (
      state,
      action: PayloadAction<{
        access_key: string;
        refresh_token?: string;
        session: ReduxAuthState['session'];
        login_id: string;
        remember_me?: boolean;
        is_admin: ReduxAuthState['is_admin'];
        tableau_role: ReduxAuthState['tableau_role'];
      }>,
    ): void => {
      const { access_key, refresh_token, session, is_admin, remember_me, login_id, tableau_role } = action.payload;
      state.session = session;
      state.is_admin = is_admin;
      state.tableau_role = tableau_role;
      state.is_logged_in = true;

      // access_keyとrefresh_tokenは別途session storageに保存する
      setAccessKey(access_key);
      setRefreshToken(refresh_token);
      // remember_meとlogin_idは別途local storageに保存する
      setRememberMe(remember_me);
      setLoginId(login_id);
    },
    signOut: (state): void => {
      state.is_logged_in = false;
      state.session = '';
      // access_keyとrefresh_tokenは別途session storageに保存する
      setAccessKey('');
      setRefreshToken('');
    },
    refreshAccessKey: (state, action: PayloadAction<{ access_key: string }>) => {
      // access_keyとrefresh_tokenは別途session storageに保存する
      setAccessKey(action.payload.access_key);
    },
  },
});

export default slice;
export const authSelector = (state: RootState): ReduxAuthState => state.auth;
export const authActions = slice.actions;
