// -- basic library --
import React from 'react';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import styled from 'styled-components';
import { CsvRow } from 'shared/models/CsvRow';

interface Params {
  bodies: CsvRow[];
  showStatistics: boolean;
}

// -- main component --

const CSVTable: React.FC<Params> = (params) => {
  // -- local states --

  // -- functions --

  // -- render part --
  const header = params.showStatistics
    ? ['ヘッダ名', 'JSONパス', '出力式', '引数', '集計', '複製']
    : ['ヘッダ名', 'JSONパス', '出力式', '引数', '複製'];
  return (
    <WholeArea>
      {/* テーブル */}
      <TableArea>
        <Table>
          <Thead>
            <tr>
              {/* テーブルヘッダー */}
              {header.map((h, i) => (
                <Th
                  key={i}
                  style={{
                    width: `calc(100% / ${header.length})`,
                    paddingLeft: styles.interval_narrow_margin,
                  }}
                  last_child={i === header.length - 1 ? true : false}
                >
                  <ThChildrensArea>{h}</ThChildrensArea>
                </Th>
              ))}
            </tr>
          </Thead>

          {/* テーブルボディー */}
          <Tbody>
            {/* ボディーデータをソートした後に、一ページ分のデータに切り分ける */}
            {params.bodies.map((body, index1) => {
              return (
                <Tr key={index1}>
                  <Td key={`header_name_${index1}`} last_row={index1 + 1 === params.bodies.length ? true : false}>
                    {body.header_name}
                  </Td>
                  <Td key={`json_path_${index1}`} last_row={index1 + 1 === params.bodies.length ? true : false}>
                    {body.json_path}
                  </Td>
                  <Td key={`cell_format_${index1}`} last_row={index1 + 1 === params.bodies.length ? true : false}>
                    {body.cell_format}
                  </Td>
                  <Td key={`cell_format_args_${index1}`} last_row={index1 + 1 === params.bodies.length ? true : false}>
                    {body.cell_format_args}
                  </Td>
                  {params.showStatistics && (
                    <Td
                      key={`statistic_method_${index1}`}
                      last_row={index1 + 1 === params.bodies.length ? true : false}
                    >
                      {body.statistic_method}
                    </Td>
                  )}
                  <Td key={`fill_${index1}`} last_row={index1 + 1 === params.bodies.length ? true : false} last_child>
                    {params.bodies[index1].fill}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableArea>

      {/* フッター */}
    </WholeArea>
  );
};

// -- styled components --

const WholeArea = styled.div`
  width: 100%;
  height: 100%;
  display: block;
`;

const TableArea = styled.div`
  max-width: 100%;
  width: 100%;
  height: calc(100% - 25px - 25px - ${styles.interval_narrow_margin} * 2);
  max-height: 200px;
  overflow-y: scroll;
  border-radius: ${styles.table_radius_px};
`;

const Table = styled.table`
  width: 100%;
  height: 100%;
  border-spacing: 0;
  border: 1px solid ${colors.component_small_border_color};
  border-collapse: separate;
  border-radius: ${styles.table_radius_px};
  table-layout: fixed;
  overflow: hidden;
`;

const Thead = styled.thead`
  width: 100%;
  height: 45px;
  max-height: 45px;
  background-color: ${colors.gray};
`;

const Tbody = styled.tbody`
  width: 100%;
  height: calc(100% - 45px);
  background-color: ${colors.white};
`;

const Tr = styled.tr<{
  checked?: boolean;
}>`
  width: 100%;
  height: 35px;
  ${(params) => (params.checked ? `background-color: ${colors.table_checked_background_color};` : '')}
  &:hover {
    background-color: ${(params) =>
      params.checked ? colors.table_checked_background_color : colors.table_hover_background_color};
  }
`;

const Th = styled.th<{
  first_child?: boolean;
  last_child?: boolean;
}>`
  vertical-align: middle;
  border-right: ${(params) => (params.last_child ? 'none' : `1px solid ${colors.component_small_border_color}`)};
  border-bottom: 1px solid ${colors.component_small_border_color};
`;

const ThChildrensArea = styled.div`
  display: flex;
  align-items: center;
`;

const Td = styled.td<{
  first_child?: boolean;
  last_row?: boolean;
  last_child?: boolean;
}>`
  vertical-align: middle;
  border-right: ${(params) => (params.last_child ? 'none' : `1px solid ${colors.component_small_border_color}`)};
  border-bottom: ${(params) => (params.last_row ? 'none' : `1px solid ${colors.component_small_border_color}`)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: ${styles.interval_narrow_margin};
`;

// -- finally export part --

export default CSVTable;
