// ストリームデータを複数アップロードする

// -- http connection library --
import { SQSBoolean } from 'shared/components/atoms/SQS';
import ProgressPercentNumberDialog from 'shared/components/molecules/ProgressPercentNumberDialog';
import {
  MultiPart,
  RequestS3TransfersIdPartsSignaturePost,
  s3TransfersIdCompletePostAPI,
  s3TransfersIdPartsSignaturePostAPI,
  s3TransfersIdUploadUrlPutAPI,
} from 'user/api/s3Transfers';
import {
  RequestStreamsIdDataUploadPost,
  streamsIdDataNumUploadPutAPI,
  streamsIdDataUploadPostAPI,
} from 'user/api/streamsData';

// -- external components --

// -- external functions --
import sumArray from '../../shared/utils/else/sum';
import { StreamDataFileType } from 'shared/models';

// ファイルを分割して、部分ごとに処理する関数
const parseAndProcessFile = async (p: {
  file: File; // ファイル
  process: (result: string | ArrayBuffer | null | undefined, part_number: number) => Promise<void>; // 1周ごとに行う関数
  finish: () => Promise<void>; // 完了時に行う関数
  onChangeProgressBar: (inc: number) => void; //進捗率を更新する関数
  abort: SQSBoolean;
}) => {
  const fileSize = p.file.size;
  const chunkSize = 30 * 1024 * 1024; // 30MBずつに分割する
  let offset = 0;
  // part_number 1ずつインクリメントする
  let part_number = 0;
  //処理する関数を格納する(終わるまで再帰的に呼び出す)
  let block: ((offset: number, chunkSize: number, file: File) => void) | null = null;

  block = async (_offset: number, chunkSize: number, _file: File) => {
    // blockが呼ばれた際に、中止チェック
    if (p.abort.getFlag) {
      block = null;
      return;
    }
    const r = new FileReader();
    const blob = _file.slice(_offset, chunkSize + _offset);
    r.onload = async (evt: ProgressEvent<FileReader>) => {
      if (!evt.target?.error) {
        offset += chunkSize;
        part_number += 1;
        await p.process(evt.target?.result, part_number); // callback for handling read chunk
      } else {
        console.log('Read error: ' + evt.target.error);
        return;
      }
      // プログレスバーを更新したり、次のblockを呼ぶ操作は、中止でない時のみ行う
      if (!p.abort.getFlag) {
        //プログレスバーを更新する
        p.onChangeProgressBar(offset / fileSize);
        if (offset >= fileSize) {
          await p.finish();
          return;
        }
        // abortされたのか毎度確認する。abortされたら処理をやめる
        block?.(offset, chunkSize, p.file);
      }
    };
    r.readAsArrayBuffer(blob);
  };

  block(offset, chunkSize, p.file);
};

// 分割したStreamDataの断片をアップロードする。
const partUpload = async (p: {
  s3_transfer_id: string;
  part_number: number;
  result: string | ArrayBuffer | null | undefined;
  pushPartsFunction: (part: MultiPart) => void;
}) => {
  const request_parts_signature_post: RequestS3TransfersIdPartsSignaturePost = {
    transfer_id: p.s3_transfer_id,
    part_number: p.part_number,
  };
  // データをアップロードする用の署名付きurlを取得(これは分割分叩く)
  // パートアップロード用のurlを取得
  const res_sig = await s3TransfersIdPartsSignaturePostAPI(request_parts_signature_post);
  if (res_sig.status !== 200) {
    return;
  }
  // パートアップロード用のurlに対して分割ファイルをアップロード
  const res_upload = await s3TransfersIdUploadUrlPutAPI({
    upload_url: res_sig.data.upload_url,
    data: p.result || null,
  });
  const e_tag = res_upload.headers?.etag;
  const content_length =
    res_upload.headers && res_upload.headers['content-length'] && Number(res_upload.headers['content-length']) <= 0
      ? 0
      : 1;
  // content-lengthが0の場合は成功。content-lengthが0より大きいときは、index.htmlが帰ってくるエラーとなる。
  if (content_length === 0 && e_tag) {
    // partのデータを格納する
    p.pushPartsFunction({
      part_number: p.part_number,
      e_tag: e_tag,
    });
  }
};

// マルチパートアップロードの完了を通知するAPIを叩く
const partsUploadComplete = async (p: {
  parts: MultiPart[];
  s3_transfer_id: string;
  onFinish: (s3_transfer_id: string) => Promise<void>; // 完了時の関数
}) => {
  const res = await s3TransfersIdCompletePostAPI({
    parts: p.parts,
    transfer_id: p.s3_transfer_id,
  });
  if (res.status === 200) {
    await p.onFinish(p.s3_transfer_id);
  }
};

/**
 * 複数のファイルをアップロードする関数
 * **/
const uploadMultiStreamDataFile = async (p: {
  stream_datas: StreamDataFileType[];
  request_params: RequestStreamsIdDataUploadPost;
  onS3Transfer?: (s3_transfer_id: string) => Promise<void>; // 完了時にs3_transfer_idを利用する関数
  onConfirm?: () => void; // ダイアログの確認ボタンを押した時の関数
}) => {
  // 目標アップロードファイル数
  const goal_file_length = p.stream_datas.length;
  // アップロードが完了したファイル数
  let finished_number = 0;
  // 中止フラッグクラス。trueになったら後続を止める
  const abort = new SQSBoolean();

  //各ファイルの進捗率をテーブルとして格納する
  // i番目の要素のファイル進捗率(0~1)
  const progress_table: number[] = new Array(goal_file_length).fill(0);
  // 各ファイルの進捗率を更新する関数
  const onChangeProgressBar = (inc: number, index: number) => {
    // 最大で1
    progress_table[index] = Math.min(inc, 1);
    // テーブルの合計/ファイル数
    const s = sumArray(progress_table) / goal_file_length;
    ProgressPercentNumberDialog.updateValue(s);
  };

  // 1つのファイルアップロードが完了したら呼び出す関数
  const onFinishFunc = async (s3_transfer_id: string) => {
    // アップロード済みのファイル数を1つ増やす
    finished_number += 1;
    // 進捗率を表示するダイアログを更新
    ProgressPercentNumberDialog.updateFinishedNumber(finished_number);
    // もし、全てのファイルをアップロードできていたら、終了
    if (finished_number === goal_file_length) {
      if (p.onS3Transfer) {
        await p.onS3Transfer(s3_transfer_id);
      }
    }
  };

  // 進捗ダイアログを表示する
  ProgressPercentNumberDialog.show(0, goal_file_length, {
    onAbort: () => abort.updateFlag(true),
    onConfirm: p.onConfirm,
  });
  // アップロードは並列に行う
  await Promise.all(
    p.stream_datas.map(async (sd, i) => {
      // (async function () {
      // プロセス情報の取得
      if (sd.file.size >= 100 * 1024 * 1024) {
        // 100MB以上でマルチパートアップロード
        // マルチパートアップロードの開始を知らせるAPIのrequest_params
        const new_request_params: RequestStreamsIdDataUploadPost = {
          ...p.request_params,
          original_file_name: sd.file_name,
          multipart_upload: 'True',
        };
        // マルチパートアップロードの開始を知らせるAPI
        const res_post = await streamsIdDataUploadPostAPI(new_request_params);
        if (res_post.status !== 200) return;

        // マルチパートアップロードの細切れ送信の返却データであるpartデータをとっておく(完了の時に使う)
        const parts: MultiPart[] = [];
        // ファイルを分割して、マルチパートアップロードを行う。
        await parseAndProcessFile({
          file: sd.file,
          process: async (result: string | ArrayBuffer | null | undefined, part_number: number) => {
            await partUpload({
              part_number: part_number,
              result: result,
              s3_transfer_id: res_post.data.s3_transfer_id,
              pushPartsFunction: (part: MultiPart) => parts.push(part),
            });
          },
          finish: async () =>
            partsUploadComplete({
              s3_transfer_id: res_post.data.s3_transfer_id,
              parts: parts,
              onFinish: onFinishFunc,
            }),
          onChangeProgressBar: (inc) => onChangeProgressBar(inc, i),
          abort: abort,
        });
      } else {
        // 100MB未満で通常アップロード
        const request_params: RequestStreamsIdDataUploadPost = p.request_params;
        request_params.original_file_name = sd.file_name;
        const res_post = await streamsIdDataUploadPostAPI(request_params);
        if (res_post.status !== 200) return;
        // upload_urlが帰ってくるときは、通常アップロード
        if (res_post.data.upload_url) {
          const request_put_params = {
            upload_url: res_post.data.upload_url,
            stream_data: sd,
          };
          const res_put = await streamsIdDataNumUploadPutAPI(request_put_params);
          // 中止されていなかったら処理を行う
          if (!abort.getFlag) {
            // 100MB未満は通常アップロードなので、アップロードが完了したら進捗率を1(100%)にする
            onChangeProgressBar(1, i);
            if (res_put.status === 200) {
              await onFinishFunc(res_post.data.s3_transfer_id);
            }
          }
        }
      }
    }),
  );
};

export default uploadMultiStreamDataFile;
