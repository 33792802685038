import { CommonAPIRequestType, isCommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';
import { ISO8601 } from 'shared/models/ISO8601';
import { OrderBy } from 'shared/models/OrderBy';
import { Query } from 'shared/models/Query';
import { StatusType } from 'shared/models/StatusType';
import sendAxios from '../../shared/axios/sendAxios';
import { getClient } from './base';

/*** エンティティ ***/

export interface ChannelEventComment {
  tenant_id: string;
  channel_id: string;
  channel_event_number: number;
  channel_event_coment_number: number;
  user_id: string;
  user_name: string;
  comment: string;
  status: StatusType;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

export interface ChannelEventsComentsWithPaging {
  items: ChannelEventComment[];
  has_next: boolean;
}

const sample_channel_event_comment_1: ChannelEventComment = {
  tenant_id: '00000001',
  channel_id: '00000001',
  channel_event_number: 1,
  channel_event_coment_number: 1,
  user_id: 'aaa',
  user_name: '長谷川',
  comment: 'これご検知でした~',
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
};

const sample_channel_event_comment_2: ChannelEventComment = {
  tenant_id: '00000001',
  channel_id: '00000001',
  channel_event_number: 1,
  channel_event_coment_number: 2,
  user_id: 'aaa',
  user_name: '石田',
  comment: '',
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
};

const sample_channel_event_comment_3: ChannelEventComment = {
  tenant_id: '00000001',
  channel_id: '00000001',
  channel_event_number: 1,
  channel_event_coment_number: 3,
  user_id: 'aaa',
  user_name: '田中',
  comment: 'これご検知でした~',
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
};

/*** [GET] /api/channels/{channel_id}/events{channel_event_number}/comments ***/
export interface RequestChannelsIdEventsNumCommentsGet {
  channel_id: string;
  channel_event_number: string | number;
  exclusive_start_channel_event_comment_number?: string;
  order_by?: OrderBy;
}
/**
 * チャンネルのイベントに設定されたコメントの一覧
 * @param channel_id チャンネルID
 * @param channel_event_number イベント番号
 * @returns チャンネルのイベントに設定されたコメントの一覧
 */
export const channelsIdEventsNumCommentsGetAPI = (params: RequestChannelsIdEventsNumCommentsGet) => {
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `/api/channels/${params.channel_id}/events/${params.channel_event_number}/comments`;
  const method = 'get';

  const ex = params.exclusive_start_channel_event_comment_number || '';
  const or = params.order_by || 'ASC';

  // [get, put]クエリストリングを定義
  const query: Query = {
    exclusive_start_channel_event_comment_number: ex,
    order_by: or,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<ChannelEventsComentsWithPaging>(axios, path, query, form, method, {
    items: [
      sample_channel_event_comment_1,
      sample_channel_event_comment_2,
      sample_channel_event_comment_3,
      sample_channel_event_comment_1,
      sample_channel_event_comment_2,
      sample_channel_event_comment_3,
    ],
    has_next: false,
  });
};

/*** [POST] /api/channels/{channel_id}/events/{channel_event_number}/comments ***/

/**
 * チャンネルのイベントに設定されたコメントを追加する
 * @param channel_id チャンネルID
 * @param channel_event_number イベント番号
 * @returns チャンネルのイベントに設定されたコメント
 */

export interface RequestChannelsIdEventsNumCommentsPost extends CommonAPIRequestType {
  channel_id: string;
  channel_event_number: string | number;
  comment: string;
}

export const channelsIdEventsNumCommentsPostAPI = (params: RequestChannelsIdEventsNumCommentsPost) => {
  const { channel_id, channel_event_number, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/channels/${channel_id}/events/${channel_event_number}/comments`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'channel_id' || key === 'channel_event_number' || isCommonAPIRequestType(key)) {
      continue;
    }
    form.append(key, value);
  }

  // 送信
  return sendAxios<ChannelEventComment>(axios, path, query, form, method, sample_channel_event_comment_1);
};
