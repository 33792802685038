import { Stream } from 'user/api/streams';
import { SelectData } from 'shared/components/atoms/SelectBox';

const convertStreamsToSelectDatas = (streams: Stream[]): SelectData[] => {
  const select_datas: SelectData[] = streams.map((st) => {
    return {
      name: st.stream_name,
      value: st.stream_id,
    };
  });
  // 該当するデータでなければ固定で返却
  return select_datas;
};

export default convertStreamsToSelectDatas;
