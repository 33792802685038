import React from 'react';
import { Icon as OriginalIcon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import DatePicker from 'react-datepicker';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import { dayOfWeekStr } from 'shared/utils/constants';
import { dateToMD } from 'shared/utils/converter/date';
import { getWeekDates } from 'shared/utils/get';
import { getAddedDay, getDayOfWeek, getDayOfWeelColor } from 'shared/utils/get';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import { toISO8601 } from 'shared/models/ISO8601';

interface M3u8VideoSelectorProps {
  selected_video_date: string;
  is_loaded: boolean;
  getM3u8: (inputs: { happened_at: string; speed?: number }) => Promise<void>;
  parentWidth?: number;
  display_button_dates_number: number;
}

interface M3u8VideoSelectorState {
  selected_button_index: number;
  display_button_dates?: string[];
  // display_button_dates_number: number;
}

// -- main component --
/**
 * M3u8の動画再生プレイヤーにおける日付を選択する部分
 */
export default class M3u8DateSelector extends React.PureComponent<M3u8VideoSelectorProps, M3u8VideoSelectorState> {
  constructor(props: M3u8VideoSelectorProps) {
    super(props);
    this.state = {
      selected_button_index: 0,
      display_button_dates: undefined,
      // display_button_dates_number: this.props.parentWidth && this.props.parentWidth > 700 ? 7 : 4,
    };
  }
  componentDidMount() {
    const dt = this.props.selected_video_date ? new Date(this.props.selected_video_date) : new Date();
    const new_dislay_button_dates = getWeekDates(dt, this.props.display_button_dates_number);
    const new_selected_button_index = new_dislay_button_dates.indexOf(dateToMD(dt, false));
    this.setState({ display_button_dates: new_dislay_button_dates, selected_button_index: new_selected_button_index });
  }

  componentDidUpdate(prevProps: M3u8VideoSelectorProps) {
    // 典型的な使い方(props を比較することを忘れないでください)
    if (
      this.props.selected_video_date !== prevProps.selected_video_date ||
      this.props.display_button_dates_number !== prevProps.display_button_dates_number
    ) {
      const dt = new Date(this.props.selected_video_date);
      const md = dateToMD(dt, false);
      let new_selected_button_index = 0;
      // this.state.display_button_datesが存在しないか、存在していてもmdの範囲外ならば更新する
      if (this.state.display_button_dates === undefined || this.state.display_button_dates.indexOf(md) < 0) {
        const new_dislay_button_dates = getWeekDates(dt, this.props.display_button_dates_number);
        this.setState({ display_button_dates: new_dislay_button_dates });
        new_selected_button_index = new_dislay_button_dates.indexOf(md);
      } else {
        new_selected_button_index = this.state.display_button_dates.indexOf(md);
      }
      this.setState({ selected_button_index: new_selected_button_index });
    }
  }

  private onCalenderChange = async (date: Date) => {
    this.setState({ display_button_dates: getWeekDates(date, this.props.display_button_dates_number) });
    await this.props.getM3u8({
      happened_at: toISO8601(date),
    });
  };

  private onClickLive = async () => {
    const dt = new Date();
    const new_display_button_dates = getWeekDates(dt, this.props.display_button_dates_number);
    this.setState({ display_button_dates: new_display_button_dates });
    await this.props.getM3u8({ happened_at: toISO8601(dt, 'NONE') });
  };

  private onDateButtonChange = async (selected_date_button_index: number) => {
    if (this.state.display_button_dates === undefined) {
      return;
    }
    const month = Number(this.state.display_button_dates[selected_date_button_index].split('/')[0]) - 1;
    const day = Number(this.state.display_button_dates[selected_date_button_index].split('/')[1]);
    const date = new Date();
    date.setMonth(month);
    date.setDate(day);
    await this.props.getM3u8({ happened_at: toISO8601(date) });
  };
  render() {
    return (
      <SelectDateArea>
        <DatePicker
          disabled={!this.props.is_loaded}
          selected={this.props.selected_video_date ? new Date(this.props.selected_video_date) : null}
          onChange={this.onCalenderChange}
          title='カレンダーから日付を取得する'
          customInput={
            <Button>
              <Calender icon={IconNames.CALENDAR} iconSize={26} color={colors.main_font_color} />
            </Button>
          }
          maxDate={getAddedDay(new Date(), 0)}
        />
        {this.state.display_button_dates
          ? this.state.display_button_dates.map((date, index) => (
              <div key={index} style={{ marginRight: 5 }}>
                <RoundedButton
                  key={index}
                  onClick={() => this.onDateButtonChange(index)}
                  disabled={!this.props.is_loaded}
                  style={{
                    opacity: this.props.is_loaded ? 1 : styles.opacity_disabled,
                    width: 50,
                    height: styles.super_small_button_height,
                  }}
                  is_white={index !== this.state.selected_button_index}
                  text={date}
                ></RoundedButton>
                <ButtonText color={getDayOfWeelColor(date)}>{getDayOfWeek(date, dayOfWeekStr)}</ButtonText>
              </div>
            ))
          : null}
        <RoundedButton
          disabled={!this.props.is_loaded}
          onClick={() => this.onClickLive()}
          text='LIVE'
          style={{
            width: styles.super_small_button_width,
            height: styles.super_small_button_height,
          }}
        />
      </SelectDateArea>
    );
  }
}

// -- styled components --

const SelectDateArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const Calender = styled(OriginalIcon)`
  width: auto;
  height: auto;
`;

const Button = styled.button`
  box-shadow: none;
  margin-right: 30px;
  margin-top: 3px;
  background-color: ${colors.white};
  border: none;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed !important;
    opacity: 0.5;
  }
  &:hover {
    background-color: ${colors.gray};
  }
`;

const ButtonText = styled.div<{
  color: string;
}>`
  text-align: center;
  color: ${(params) => params.color};
`;
