/** CSVの文字コートタイプ */
export type CsvEncodeType = 'UTF-8' | 'shift_jis';
export const CsvEncodeTypes: CsvEncodeType[] = ['UTF-8', 'shift_jis'];
export function convertCsvEncodeType(s?: string, undef?: boolean) {
  for (const v of CsvEncodeTypes) if (v === s) return s;
  return undef ? undefined : CsvEncodeTypes[0];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isCsvEncodeType = (data: any): data is CsvEncodeType => data === 'UTF-8' || data === 'shift_jis';
