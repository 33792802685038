import { useMemo } from 'react';
import SelectBox from 'shared/components/atoms/SelectBox';
import styles from 'shared/styles/styles';
import { keyBy } from 'shared/utils/converter';
import { ProcessInfo } from 'user/api/streamsProcessInfo';

export interface ProcessInfoSelectorProps {
  process_infos?: ProcessInfo[];
  selected_process_info: ProcessInfo | null;
  onChange(process_info: ProcessInfo | null): void;
}
export function ProcessInfoSelector({
  process_infos,
  selected_process_info,
  onChange: setProcessInfo,
}: ProcessInfoSelectorProps) {
  const process_infos_record: Record<string, ProcessInfo> = useMemo(() => {
    return process_infos ? keyBy(process_infos, (p) => String(p.channel_process_number)) : {};
  }, [process_infos]);
  const customOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const new_process_number = e.currentTarget.value;
    const new_process_info = process_infos_record[new_process_number] ?? null;
    setProcessInfo(new_process_info);
  };
  return (
    <SelectBox
      datas={
        process_infos?.map((process_info) => {
          return {
            name: process_info.channel_process_name,
            value: String(process_info.channel_process_number),
          };
        }) ?? []
      }
      value={String(selected_process_info?.channel_process_number ?? '')}
      onChange={customOnChange}
      style={{ marginRight: styles.interval_narrow_margin }}
    />
  );
}
