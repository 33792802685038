// -- basic library --
import { memo, ReactNode } from 'react';
import { Spinner, Alert, AlertProps } from '@blueprintjs/core';
import styled from 'styled-components';

// -- main component --

const OverWrappedLoader = memo(function OverWrappedLoader() {
  // -- render part --
  return (
    <CustomAlert isOpen={true} data-testid='loader'>
      <Spinner intent='primary' size={100} />
    </CustomAlert>
  );
});

interface CustomAlertProps extends AlertProps {
  children?: ReactNode;
}

// -- styled components --
const CustomAlert = styled((props: CustomAlertProps) => <Alert {...props} />)`
  width: 100vw;
  max-width: 100vw;
  height: 100vh;

  background: transparent;
  box-shadow: none;
  .bp3-alert-body {
    width: 100%;
    height: 100%;
    .bp3-alert-contents {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: cente;
      justify-content: center;
    }
  }

  .bp3-alert-footer {
    display: none;
  }
`;

// -- finally export part --

export default OverWrappedLoader;
