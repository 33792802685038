// -- basic library --
import React, { useCallback, useMemo, useState } from 'react';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import { Content, Footer } from 'shared/components/molecules/ContentsArea';
import DrawPolygonObject from 'shared/components/molecules/DrawPolygons';
import StepPanel from 'shared/components/molecules/StepPanel';
import TrimPanel from 'shared/components/molecules/TrimPanel';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import { TrimedImage } from 'shared/models';
import styled from 'styled-components';
import { StayAreaType } from 'shared/models/StayAreaType';

// -- type declaration --
interface StayAreaStepPanelProps {
  imgsrc: string;
  trimed_image?: TrimedImage;
  cross_line: StayAreaType['crossLine'];
  onCrossLineChange: (cross_line: StayAreaType['crossLine']) => void;
  onTrimedImageChange: (trimed_image: TrimedImage) => void;
  onPanelFinish(): void;
  onClose(): void;
}

// -- main component --
/**
 * StayAreaDialogの中に差し込むステップパネル
 * 画像トリミング -> エリア入力を行う
 * **/
const StayAreaStepPanel: React.FC<StayAreaStepPanelProps> = ({
  imgsrc,
  trimed_image,
  cross_line,
  onCrossLineChange,
  onTrimedImageChange,
  onPanelFinish,
  onClose,
}: StayAreaStepPanelProps) => {
  // -- local states --
  const [step, setStep] = useState<number>(1);

  const onTrimPanelFinish = useCallback(() => {
    setStep(2);
  }, [setStep]);

  // [TODO] TrimePanel内に書いてあるFooterのボタンを本コンポーネント移す。
  // reac-cropperのcrop属性に状態を変更できる関数を常時割り当てれば、
  // 可能であるが、処理が非常に重くなってしまう(おそらく変更する状態や処理の数が多いため)
  const panel_element = useMemo(() => {
    switch (step) {
      case 1:
        return (
          <>
            {imgsrc.length > 0 ? (
              <TrimPanel
                imgsrc={imgsrc}
                trimed_image={trimed_image}
                onFinish={onTrimPanelFinish}
                onClose={onClose}
                onTrimedImageChange={onTrimedImageChange}
                setting_exp_imp_button_props={{
                  setStayareaCrosslines: onCrossLineChange,
                  stayarea_crosslines: cross_line,
                  hide_export: true,
                  hide_import: false,
                }}
              />
            ) : (
              <Spinner />
            )}
          </>
        );
      case 2:
        return (
          <>
            {trimed_image === undefined ? (
              <Spinner />
            ) : (
              <>
                <DrawPolygonObject
                  polygons={cross_line}
                  imgsrc={imgsrc}
                  onChange={onCrossLineChange}
                  trimed_image={trimed_image}
                  setting_exp_imp_button_props={{
                    hide_import: true,
                    hide_export: false,
                  }}
                />
                <Footer
                  style={{
                    marginTop: styles.interval_narrow_margin,
                  }}
                >
                  <RoundedButton
                    text='戻る'
                    onClick={() => setStep(1)}
                    is_white={true}
                    is_margin_right={true}
                    style={{
                      width: styles.small_button_width,
                    }}
                  />
                  <RoundedButton
                    text='完了'
                    onClick={onPanelFinish}
                    style={{
                      width: styles.small_button_width,
                    }}
                  />
                </Footer>
              </>
            )}
          </>
        );
      default:
        return <></>;
    }
  }, [
    imgsrc,
    onClose,
    cross_line,
    onCrossLineChange,
    onPanelFinish,
    onTrimedImageChange,
    step,
    trimed_image,
    onTrimPanelFinish,
  ]);

  // -- render part --
  return (
    <NContent>
      <StepPanel texts={['トリミング', '検知ライン引き']} selected_number={step} />
      <ScreenImageArea>{panel_element}</ScreenImageArea>
    </NContent>
  );
};

// -- styled components --

const ScreenImageArea = styled.div`
  width: 100%;
  margin-top: ${styles.interval_margin};
  display: block;
`;

const NContent = styled(Content)`
  // background-color: ${colors.dark_gray};
  display: flex;
  flex-direction: column;
  use-select: none;
`;

// -- finally export part --

export default StayAreaStepPanel;
