import React from 'react';
import CheckBoxWithText from 'shared/components/molecules/CheckBoxWithText';
import InputComponent from 'shared/components/molecules/InputComponent';
import InputDoubleBox from 'shared/components/molecules/InputDoubleBox';
import { ISO8601, toISO8601 } from 'shared/models/ISO8601';

interface StreamDataRangeSelectionPartProps {
  is_specify: boolean;
  stream_data_number_from?: ISO8601 | '';
  stream_data_number_to?: ISO8601 | '';
  onChange: (specify: boolean, stream_data_number_from?: ISO8601 | '', stream_data_number_to?: ISO8601 | '') => void;
}
/** TIMESTAMPストリーム用の対象データ選択パート */
export default class StreamDataRangeSelectionPart extends React.PureComponent<StreamDataRangeSelectionPartProps> {
  private handleChange = (
    specify: boolean,
    stream_data_number_from?: ISO8601 | '',
    stream_data_number_to?: ISO8601 | '',
  ) => {
    if (specify) {
      this.props.onChange(true, stream_data_number_from, stream_data_number_to);
    } else {
      this.props.onChange(false, '', '');
    }
  };
  private handleFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { is_specify, stream_data_number_to } = this.props;
    const new_value = e.currentTarget.value === '' ? e.currentTarget.value : toISO8601(e.currentTarget.value, 'NONE');
    this.handleChange(is_specify, new_value, stream_data_number_to);
  };

  private handleToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { is_specify, stream_data_number_from } = this.props;
    const new_value = e.currentTarget.value === '' ? e.currentTarget.value : toISO8601(e.currentTarget.value, 'NONE');
    this.handleChange(is_specify, stream_data_number_from, new_value);
  };
  render() {
    const { stream_data_number_from, stream_data_number_to, is_specify } = this.props;
    return (
      <InputComponent text='対象データ'>
        <CheckBoxWithText
          data-testid='specify-target-data'
          text='指定する'
          checked={is_specify}
          onClick={() => this.handleChange(!is_specify, stream_data_number_from, stream_data_number_to)}
        />
        {is_specify && (
          <InputDoubleBox
            placeholders={['入力してください', '入力してください']}
            value1={stream_data_number_from ?? ''}
            value2={stream_data_number_to ?? ''}
            handleChangeClick1={this.handleFromChange}
            handleChangeClick2={this.handleToChange}
            interval_text='〜'
            types={['datetime-local', 'datetime-local']}
            steps={[1, 1]}
          />
        )}
      </InputComponent>
    );
  }
}
