// -- basic library --
import React, { useCallback, useEffect, useState } from 'react';
import history from 'shared/browserHistory';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { PageWholeArea, Title, TopArea, BottomArea } from 'shared/components/molecules/ContentsArea';
import { userTypeToJP } from 'shared/models/UserType';
import { isNotSelected } from 'shared/utils/is';
import { CachedUsers, usersIdDeleteAPI } from 'user/api/users';
import { useRoles } from 'user/hooks/useRoles/useRoles';
import { loadWrapperFunc } from 'user/utils/loadWrapperFunc';
import { getLoginId } from 'shared/utils/else/localStorage';
import { preloadGroup } from 'user/utils/preloadGroup';
import UsersCreateDialog from '../CreateDialog';
import UsersDetailDialog from '../DetailDialog/DetailUsers';
import UsersTable, { TableUserType } from './UsersTable';

// -- main component --
const Users: React.FC = () => {
  // -- local states --
  const { roles, roles_record } = useRoles({});
  const [table_bodies, setTableBodies] = useState<TableUserType[] | undefined>(undefined);
  const [selected_bodies, setSelectedBodies] = useState<TableUserType[]>([]);

  // 作成用states
  const [open_create_dialog, setOpenCreateDialog] = useState<boolean>(false);
  // 詳細(更新)用states
  const [open_set_dialog, setOpenSetDialog] = useState<boolean>(false);
  const [user_id, setUserId] = useState<string>('');

  // -- handlers --

  // ユーザーを作成するダイアログを出す
  const handleCreateClick = () => {
    setOpenCreateDialog(true);
  };
  // ユーザーを更新する画面にいく関数
  const handleSetClick = (user_id: string) => {
    setUserId(user_id);
    setOpenSetDialog(true);
  };

  // ユーザーを削除する関数
  const handleOKClick = async (selected_datas: TableUserType[]) => {
    // 削除関数定義
    const deletes = async () => {
      await Promise.all(
        selected_datas.map((sd) =>
          usersIdDeleteAPI({
            user_id: sd.id,
          }),
        ),
      );
      await loadTableBodies();
      setSelectedBodies([]);
    };
    // 実行
    await loadWrapperFunc(deletes);
  };

  const handleCancelClick = () => {
    history.push('/users');
  };

  const handleDeleteClick = async (selected_bodies: TableUserType[]) => {
    const current_user_login_id = getLoginId();
    if (selected_bodies.find((data) => data.login_id === current_user_login_id)) {
      AlertDialog.show(
        <div>
          現在ログインしているユーザーが選択されています。
          <br />
          ログイン中のユーザーは削除できません。
        </div>,
      );
      return;
    }
    ConfirmDialog.show(
      <div>
        [確認]選択されているユーザーを削除します。
        <br />
        本当によろしいですか？
      </div>,
      () => handleOKClick(selected_bodies),
      handleCancelClick,
      undefined,
    );
  };

  // テーブルのチェックをする関数
  const handleCheckClick = (bodies: TableUserType[]) => {
    setSelectedBodies(bodies);
  };

  // データを取得する関数
  const loadTableBodies = useCallback(async () => {
    const users = await new CachedUsers({}).get();

    const return_table_bodies: TableUserType[] = users.map((user) => {
      const role_name = roles_record[user.role_id]?.role_name;
      const user_type = userTypeToJP(user.user_type);

      const return_table_body: TableUserType = {
        id: user.user_id,
        user_id: {
          value: user.user_id,
          onClick: () => handleSetClick(user.user_id),
        },
        login_id: user.login_id,
        user_name: user.user_name,
        mail_address: user.mail_address,
        user_type,
        role_name,
      };
      return return_table_body;
    });

    setTableBodies(return_table_bodies);
  }, [roles_record]);

  // 作成用関数
  // ダイアログの閉じる関数
  const handleCreateDialogCloseClick = async () => {
    setOpenCreateDialog(false);
    await loadTableBodies();
  };

  // 詳細(更新)用関数
  // ダイアログを閉じる関数
  const handleSetDialogCloseClick = async () => {
    setOpenSetDialog(false);
    await loadTableBodies();
  };

  // -- onload function --
  useEffect(() => {
    preloadGroup(loadTableBodies());
  }, [loadTableBodies]);

  // -- render part --
  return (
    <PageWholeArea data-testid='User'>
      <TopArea>
        <Title text='ユーザー一覧' />
        <RoundedButton text='ユーザー作成' onClick={handleCreateClick} />
      </TopArea>
      <BottomArea>
        {table_bodies !== undefined ? (
          <UsersTable
            datas={table_bodies}
            selected_datas={selected_bodies}
            handleCheckClick={handleCheckClick}
            footer_option={{
              text: '＞チェックしたユーザーを削除する',
              handleClick: () => handleDeleteClick(selected_bodies),
              disabled: isNotSelected(selected_bodies.length),
            }}
          />
        ) : (
          <Spinner />
        )}

        {open_create_dialog && (
          <UsersCreateDialog
            handleDialogCloseClick={handleCreateDialogCloseClick}
            roles={roles}
            is_open={open_create_dialog}
          />
        )}

        {open_set_dialog && (
          <UsersDetailDialog
            is_open={open_set_dialog}
            handleDialogCloseClick={handleSetDialogCloseClick}
            user_id={user_id}
            roles={roles}
          />
        )}
      </BottomArea>
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default Users;
