// -- basic library --
import React from 'react';
import InputBox from 'shared/components/atoms/InputBox';
import { convertDataStorePeriodUnitType, DataStorePeriodType, isUnspecified } from 'shared/models/DataStorePeriod';

import SelectBox from '../atoms/SelectBox';

interface Params {
  data_store_period: DataStorePeriodType;
  setDataStorePeriod: (data_store_period: DataStorePeriodType) => void;
}

// -- main component --

/***
 * ストリームなどの、データ保存期間を単位(unit)と共に選択できるコンポーネント
 * ***/
const SelectBoxDataStorePeriod: React.FC<Params> = (params) => {
  // unitが変わった時の関数
  const handleUnitChangeClick = (unit: string) => {
    const cunit = convertDataStorePeriodUnitType(unit);
    const new_data_store_period = { ...params.data_store_period };

    // unitに応じて、data_store_periodを初期化
    // 更新後のunitがUNSPECIFIEDの時は、'-1'で初期化
    if (isUnspecified(cunit)) {
      new_data_store_period.data_store_period = -1;
    } else if (isUnspecified(params.data_store_period.unit)) {
      // 更新前のunitがUNSPECIFIEDだったら、'-1'から''に変換
      new_data_store_period.data_store_period = NaN;

      // それ以外の時は変更する必要なし
    }
    // unit更新
    new_data_store_period.unit = cunit;
    params.setDataStorePeriod(new_data_store_period);
  };

  // データの保存期間が変わった時の関数
  const handlePeriodChangeClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const new_data_store_period: number = e.currentTarget.value.length > 0 ? Number(e.currentTarget.value) : NaN;
    params.setDataStorePeriod({ ...params.data_store_period, data_store_period: new_data_store_period });
  };

  // -- render part --
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <SelectBox
        datas={[
          { name: '日', value: 'DAY' },
          { name: '時間', value: 'HOUR' },
          { name: '分', value: 'MINUTE' },
          { name: '指定しない', value: 'UNSPECIFIED' },
        ]}
        value={params.data_store_period.unit}
        onChange={(e) => handleUnitChangeClick(e.currentTarget.value)}
        default_text='null'
      />
      {!isUnspecified(params.data_store_period.unit) && (
        <InputBox
          title='データ保存期間'
          placeholder='1~9999の整数'
          value={params.data_store_period.data_store_period}
          onChange={handlePeriodChangeClick}
          type='number'
        />
      )}
    </div>
  );
};

// -- finally export part --

export default SelectBoxDataStorePeriod;
