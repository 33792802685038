import React from 'react';
import ContentBodyMat from 'shared/components/atoms/ContentBodyMat';
import { EditIcon } from 'shared/components/atoms/Icons';
import InputDialog from 'shared/components/molecules/InputDialog';
import { Stream, streamsIdPutAPI } from 'user/api/streams';

interface StreamingEndpointPanelProps {
  stream: Stream;
  onStreamChange: () => void;
}
export default class StreamingEndpointPanel extends React.PureComponent<StreamingEndpointPanelProps> {
  onEdgeboxKeyEdit = () => {
    InputDialog.show('エッジボックスの識別キー', '', '', this.props.stream.edgebox_key || '', this.onChangeEdgeboxKey);
  };
  onChangeEdgeboxKey = (value: string | undefined, isCancel: boolean) => {
    if (!isCancel) {
      streamsIdPutAPI({
        stream_id: this.props.stream.stream_id,
        edgebox_key: value,
      }).then((res) => {
        if (res.status === 200) {
          this.props.onStreamChange();
        }
      });
    }
  };
  render() {
    return (
      <ContentBodyMat>
        <div>ストリーミングのエンドポイント</div>
        <div>{this.props.stream.streaming_endpoint}</div>
        {this.props.stream.streaming_endpoint !== null && (
          <div>
            <div>エッジボックスの識別キー</div>
            <div>
              {this.props.stream.edgebox_key} <EditIcon onClick={() => this.onEdgeboxKeyEdit()} />
            </div>
          </div>
        )}
      </ContentBodyMat>
    );
  }
}
