// -- basic library --
import React, { useEffect, useState } from 'react';
import account_icon from 'assets/account_icon.png';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import CheckBoxWithText from 'shared/components/molecules/CheckBoxWithText';
import { PageWholeArea, Title, Content, TopArea } from 'shared/components/molecules/ContentsArea';
import IconButton from 'shared/components/molecules/IconButton';

import styles from 'shared/styles/styles';
import styled from 'styled-components';
import { CachedChannels, Channel } from 'user/api/channels';
import { preloadGroup } from 'user/utils/preloadGroup';

// -- main component --
const Accounts: React.FC = () => {
  // -- local states --
  const [event_occurred, setEventOccurred] = useState<boolean>(false);
  const [channels, setChannels] = useState<Channel[] | undefined>(undefined);
  const [channels_checkes, setChannelsCheckes] = useState<{ [ch_id: string]: boolean } | undefined>(undefined);

  // -- get datas --
  const getChannels = async () => {
    const channel_items: Channel[] = await new CachedChannels({ channel_type: 'STANDARD' }).get();
    // channel_idでもってbooleanを格納するobject
    const new_channel_checkes: { [ch_id: string]: boolean } = {};
    channel_items.forEach((channel_item) => {
      new_channel_checkes[channel_item.channel_id] = false;
    });
    setChannelsCheckes(new_channel_checkes);
    setChannels(channel_items);
  };

  // -- handlers --
  const handleDecoyButtonClick = () => {
    AlertDialog.show('この機能は未完成です');
  };

  // チャンネルのチェックボックスをクリックする時の関数
  const handleChannelCheckes = (channel_id: string) => {
    const new_channel_checkes = { ...channels_checkes };
    const ch = new_channel_checkes[channel_id];
    new_channel_checkes[channel_id] = !ch;
    setChannelsCheckes(new_channel_checkes);
  };

  // -- onload function --
  useEffect(() => {
    preloadGroup(getChannels());
  }, []);

  // -- render part --
  return (
    <PageWholeArea>
      <TopArea>
        <Title text='アカウント設定' />
      </TopArea>
      <Content
        style={{
          display: 'flex',
        }}
      >
        <LeftArea>
          <IconButton
            img_src={account_icon}
            style={{
              width: 100,
              height: 100,
            }}
            handleClick={handleDecoyButtonClick}
          />
        </LeftArea>
        <RightArea>
          <RowArea>
            <BoldText>名前</BoldText>
            <Text>長谷川正樹</Text>
            <RoundedButton
              text='変更'
              onClick={handleDecoyButtonClick}
              style={{
                width: styles.super_small_button_width,
                height: styles.super_small_button_height,
              }}
            />
          </RowArea>
          <RowArea>
            <BoldText>メールアドレス</BoldText>
            <Text>aaaa@gmail.com</Text>
            <RoundedButton
              text='変更'
              onClick={handleDecoyButtonClick}
              style={{
                width: styles.super_small_button_width,
                height: styles.super_small_button_height,
              }}
            />
          </RowArea>
          <RowArea>
            <BoldText>ユーザーID</BoldText>
            <Text>Yyyyyyy</Text>
            <RoundedButton
              text='変更'
              onClick={handleDecoyButtonClick}
              style={{
                width: styles.super_small_button_width,
                height: styles.super_small_button_height,
              }}
            />
          </RowArea>
          <RowArea>
            <BoldText>パスワード</BoldText>
            <Text>●●●●</Text>
            <RoundedButton
              text='変更'
              onClick={handleDecoyButtonClick}
              style={{
                width: styles.super_small_button_width,
                height: styles.super_small_button_height,
              }}
            />
          </RowArea>
          {channels === undefined || channels_checkes === undefined ? (
            <Spinner />
          ) : (
            <AlertSettingArea>
              <RowArea>
                <BoldText>通知設定</BoldText>
              </RowArea>
              <RowArea>
                <CheckBoxWithText
                  text='イベント発生'
                  checked={event_occurred}
                  onClick={() => setEventOccurred(!event_occurred)}
                  id='event_occured'
                />
              </RowArea>
              <RowArea
                style={{
                  flexWrap: 'wrap',
                  height: 'auto',
                  paddingLeft: '30px',
                }}
              >
                {channels.map((channel, index) => {
                  const channel_id = channel.channel_id;
                  return (
                    <CheckBoxWithText
                      key={index}
                      text={channel.channel_name}
                      checked={channels_checkes[channel_id]}
                      onClick={() => handleChannelCheckes(channel_id)}
                      id={`channel_checkbox_${index}`}
                      style={{
                        marginRight: styles.interval_margin,
                        marginBottom: styles.interval_margin,
                      }}
                    />
                  );
                })}
              </RowArea>
            </AlertSettingArea>
          )}
        </RightArea>
      </Content>
    </PageWholeArea>
  );
};

// -- styled components --
const LeftArea = styled.div`
  width: 140px;
`;

const RightArea = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 140px);
`;

const RowArea = styled.div`
  height: 45px;
  display: flex;
`;

const BoldText = styled.div`
  width: 150px;
  font-weight: bold;
`;

const Text = styled.div`
  width: 270px;
`;

const AlertSettingArea = styled.div`
  margin-top: ${styles.interval_margin};
  display: flex;
  flex-direction: column;
`;

// -- finally export part --

export default Accounts;
