import React from 'react';
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import { dateToHM, dateToMD } from 'shared/utils/converter/date';
import { sampleGraghColors } from 'shared/styles/colors';
import { MetricGraphType } from 'shared/models/MetricGraphType';

// -- types --
export interface TempWidgetParams {
  widget_datas: {
    datapoint: string | number;
    [key: string]: string | number;
  }[];
  use_datas_type: {
    [key: string]: {
      type?: MetricGraphType;
    };
  };
}

export type RangeType = 'MIN' | 'HOUR' | 'DAY' | 'WEEK' | 'MONTH';

const rangeTypeToTickInterval = (range_type?: RangeType | null) => {
  // デフォルト1時間(unixtime基準なので1秒換算)
  let interval = 60 * 60;
  if (!range_type) {
    return interval;
  }
  if (range_type === 'MIN') {
    interval = 60 * 30;
  } else if (range_type === 'HOUR') {
    interval = 60 * 60;
  } else if (range_type === 'DAY') {
    interval = 60 * 60 * 24;
  } else if (range_type === 'MONTH') {
    interval = 60 * 60 * 24;
  }
  return interval;
};

export interface CartesianGraphSetting {
  y_min?: number;
  y_max?: number;
}

const convertDate = (unix: number, graph_type?: RangeType | null) => {
  const dt = new Date(unix * 1000);
  if (graph_type === 'MONTH' || graph_type === 'WEEK' || graph_type === 'DAY') {
    return dateToMD(dt);
  } else {
    // 0時0分の場合は[月/日]で表示させる
    // if(dt.getHours() === 0 && dt.getMinutes() === 0){
    //   return convertDateIntoMD(dt)
    // }
    return dateToHM(dt);
  }
};

const getTicks = (data: CartesianGraphData[], isTimestampTick: boolean, range_type?: RangeType | null) => {
  if (!range_type) return undefined;
  if (!isTimestampTick) return undefined;
  // DAYの場合は24時間ごとの幅で時刻を表示させる

  // 各range_typeに応じてx軸の日付を表示させる間隔を変える
  // 実際はx軸の横幅によって、その中から自動的に表示される為全て日付が表示されるわけではない。
  // MIN -> 30分
  // HOUR -> 1時間
  // DAY -> 24時間
  // MONTH -> 24時間
  const time_interval = rangeTypeToTickInterval(range_type);
  let ticks: (string | number)[] | undefined = undefined;
  const ti = data.filter((d) => Number(d.datapoint) % time_interval === 0);
  ticks = ti.map((t) => {
    return Number(t.datapoint);
  });
  return ticks;
};

const getYMinDomain = (graph_setting?: CartesianGraphSetting) => {
  if (!graph_setting) {
    return 'auto';
  }
  if (typeof graph_setting.y_min === 'number') {
    return graph_setting.y_min;
  }
  return 'auto';
};

const getYMaxDomain = (graph_setting?: CartesianGraphSetting) => {
  if (!graph_setting) {
    return (dataMax: number | string) => (typeof dataMax === 'number' ? Math.max(dataMax, 4) : dataMax);
  }
  if (typeof graph_setting.y_max === 'number') {
    return graph_setting.y_max;
  }
  return (dataMax: number | string) => (typeof dataMax === 'number' ? Math.max(dataMax, 4) : dataMax);
};

export interface CartesianGraphData {
  datapoint: string | number;
  [dataKey: string]: string | number;
}
export interface SymbolType {
  dataKey: string;
  name: string;
  type?: MetricGraphType;
  stackId?: string;
}
export interface CartesianGraghProps {
  /** グラフに描画される線の属性 */
  symbolTypes: SymbolType[];
  /** グラフに描画するデータ。datapointがX軸ラベル、keyにデータ */
  data: CartesianGraphData[];
  /** X軸がタイムスタンプかどうか(表示形式に影響) */
  isTimestampTick: boolean;
  range_type?: RangeType | null;
  /** 任意の設定パラメーター(JSON)*/
  graph_setting?: CartesianGraphSetting;
}

export const CartesianGragh: React.FC<CartesianGraghProps> = (params) => {
  return (
    <ResponsiveContainer minWidth={200} minHeight={200}>
      <ComposedChart data={params.data}>
        <CartesianGrid strokeDasharray='3 3' />
        {params.isTimestampTick ? (
          <XAxis
            dataKey='datapoint'
            ticks={getTicks(params.data, params.isTimestampTick, params.range_type)}
            tickFormatter={(datapoint: number) => {
              return convertDate(datapoint, params.range_type);
            }}
            interval='preserveStart'
          />
        ) : (
          <XAxis dataKey='datapoint' />
        )}
        <YAxis
          type='number'
          domain={
            params.data.length ? [getYMinDomain(params.graph_setting), getYMaxDomain(params.graph_setting)] : undefined
          }
        >
          <Label value='' offset={0} position='insideTopLeft' />
        </YAxis>
        <Tooltip
          labelFormatter={(label: number) => {
            return new Date(label * 1000);
          }}
        />
        <Legend />
        {params.symbolTypes.map((s: SymbolType, index: number) => {
          return getGraphTypeElement(s.type, s.dataKey, s.name, s.stackId, index);
        })}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const renderCustomizedLabel = (p: {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
}) => {
  const RADIAN = Math.PI / 180;
  const radius = p.innerRadius + (p.outerRadius - p.innerRadius) * 0.5;
  const x = p.cx + radius * Math.cos(-p.midAngle * RADIAN);
  const y = p.cy + radius * Math.sin(-p.midAngle * RADIAN);

  return (
    <text x={x} y={y} fill='white' textAnchor={x > p.cx ? 'start' : 'end'} dominantBaseline='central'>
      {`${(p.percent * 100).toFixed(0)}%`}
    </text>
  );
};

export interface PolarGraphData {
  name: string;
  value: string | number;
}
export interface PolarGraghProps {
  /** グラフに描画される線の属性 */
  // symbolTypes: SymbolType[];
  /** グラフに描画するデータ。datapointがX軸ラベル、keyにデータ */
  data: PolarGraphData[];
  range_type?: RangeType;
  /** 任意の設定パラメーター(JSON)*/
  graph_setting?: CartesianGraphSetting;
}

export const PolarGragh: React.FC<PolarGraghProps> = (params) => {
  return (
    <ResponsiveContainer minWidth={200} minHeight={200} width='100%' height='100%'>
      <PieChart>
        <Pie
          data={params.data}
          dataKey='value'
          cx='50%'
          cy='50%'
          fill='#8884d8'
          labelLine={false}
          label={renderCustomizedLabel}
        >
          {params.data.map((dt, index) => {
            return <Cell key={`cell-${dt.name}-${index}`} fill={sampleGraghColors[index % sampleGraghColors.length]} />;
          })}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};

function getGraphTypeElement(
  type: MetricGraphType | undefined,
  dataKey: string,
  name: string,
  stackId: string | undefined,
  index: number,
) {
  if (type === 'BAR') {
    return (
      <Bar
        type='linear'
        dataKey={dataKey}
        fill={sampleGraghColors[index % sampleGraghColors.length]}
        isAnimationActive={false}
        key={dataKey}
        name={name}
        stackId={stackId}
        z={1}
      />
    );
  } else if (type === 'SCATTER') {
    return (
      <Scatter
        dataKey={dataKey}
        fill={sampleGraghColors[index % sampleGraghColors.length]}
        key={dataKey}
        name={name}
        isAnimationActive={false}
      />
    );
  } else if (type === 'AREA') {
    return (
      <Area
        dataKey={dataKey}
        fill={sampleGraghColors[index % sampleGraghColors.length]}
        isAnimationActive={false}
        key={dataKey}
        name={name}
        stackId={stackId}
        stroke={sampleGraghColors[index % sampleGraghColors.length]}
      />
    );
  } else {
    return (
      <Line
        type='monotone'
        dataKey={dataKey}
        stroke={sampleGraghColors[index % sampleGraghColors.length]}
        isAnimationActive={false}
        key={dataKey}
        name={name}
        dot={false}
        z={-1}
      />
    );
  }
}

// export default CartesianGragh;
