// -- basic library --
import React, { useRef, useEffect, useCallback } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Content, Footer } from 'shared/components/molecules/ContentsArea';
import styles from 'shared/styles/styles';
import { TrimedImage, SizeWH } from 'shared/models';
import styled from 'styled-components';
import SettingExpImpButton, {
  SettingExpImpButtonProps,
} from '../../../user/components/molecules/SettingExpImpButton/SettingExpImpButton';
import 'cropperjs/dist/cropper.css';

// -- external functions --

// -- type declaration --
export interface TrimPanelParams extends React.HTMLAttributes<HTMLDivElement> {
  imgsrc: string;
  onClose: () => void;
  onFinish: () => void;
  trimed_image?: TrimedImage;
  onTrimedImageChange: (trimed_image: TrimedImage) => void;
  setting_exp_imp_button_props?: SettingExpImpButtonProps;
}

// -- main component --
/***
 * 画像をトリミングしたり、回転したりするコンポーネント
 * CROSSLINE, POLYGONなどで使用する
 * ***/
const TrimPanel: React.FC<TrimPanelParams> = (params) => {
  // -- reference preparations --

  // -- local states --
  const cropperRef: React.Ref<ReactCropperElement> = useRef(null);
  const parentRef: React.LegacyRef<HTMLDivElement> = useRef<HTMLDivElement>(null);

  // -- handlers --

  // cropper objectを取得
  const getCropper = (): Cropper | undefined => {
    return cropperRef?.current?.cropper;
  };

  // クロッパーの座標を取得
  const getCropCoordinate = (cropper: Cropper) => {
    const data = cropper.getData();
    // 画像のデータ(画像は一定なので、回転してもheight, widthはそのまま)
    // よって回転時にimageDataのwidth, heightは逆を考えないといけない
    const imageData = cropper.getImageData();
    const cropBoxData = cropper.getCropBoxData();
    const canvasData = cropper.getCanvasData();
    const new_crop_box_data = { ...cropBoxData };
    // 画像の余白部分
    const marginWidth = canvasData.left;
    const marginHeight = canvasData.top;
    const coordinate =
      data.rotate % 180 === 0
        ? {
            left: (cropBoxData.left - marginWidth) / imageData.width,
            top: (cropBoxData.top - marginHeight) / imageData.height,
            right: (cropBoxData.left + cropBoxData.width - marginWidth) / imageData.width,
            bottom: (cropBoxData.top + cropBoxData.height - marginHeight) / imageData.height,
          }
        : {
            left: (cropBoxData.left - marginWidth) / imageData.height,
            top: (cropBoxData.top - marginHeight) / imageData.width,
            right: (cropBoxData.left + cropBoxData.width - marginWidth) / imageData.height,
            bottom: (cropBoxData.top + cropBoxData.height - marginHeight) / imageData.width,
          };
    // 座標のバリデーションで最大1 最小0とする
    for (const [key, value] of Object.entries(coordinate)) {
      if (['left', 'right', 'top', 'bottom'].includes(key)) {
        const k = key as 'left' | 'right' | 'top' | 'bottom';
        let v = value;
        // 最小値は0とする
        v = Math.max(v, 0);
        // 最大値は1とする
        v = Math.min(v, 1);
        coordinate[k] = v;
      }
    }
    new_crop_box_data.left =
      data.rotate % 180 === 0
        ? coordinate.left * imageData.width + marginWidth
        : coordinate.left * imageData.height + marginWidth;
    new_crop_box_data.top =
      data.rotate % 180 === 0
        ? coordinate.top * imageData.height + marginHeight
        : coordinate.top * imageData.width + marginHeight;
    new_crop_box_data.width =
      data.rotate % 180 === 0
        ? imageData.width * Math.abs(coordinate.right - coordinate.left)
        : imageData.height * Math.abs(coordinate.right - coordinate.left);
    new_crop_box_data.height =
      data.rotate % 180 === 0
        ? imageData.height * Math.abs(coordinate.bottom - coordinate.top)
        : imageData.width * Math.abs(coordinate.bottom - coordinate.top);
    // 座標のバリデーションをかけたので、その分クロッパーの情報を更新する
    cropper.setCropBoxData(new_crop_box_data);
    return coordinate;
  };

  // 初期値にapp_parameter.CropAreaの情報をぶちこむ
  const onReady = useCallback((trimed_image?: TrimedImage) => {
    const rotate = trimed_image?.rotate || 0;
    // trimed_imageの情報がなければreturn
    if (!trimed_image || !trimed_image.cropArea) return;
    // cropperの準備ができたらapiから受け取ったデータを初期値として格納
    const cropper: Cropper | undefined = getCropper();
    if (cropper === undefined) return;
    // 回転させる
    cropper.rotate(rotate * 90);
    const imageData = cropper.getImageData();
    const canvasData = cropper.getCanvasData();

    // 画像の余白部分
    const marginWidth = canvasData.left;
    const marginHeight = canvasData.top;
    // クロッパーの情報を更新する
    const new_crop_box_data = { ...cropper.getCropBoxData() };
    // 余白分が既に座標としてあるのでそこから動かす
    new_crop_box_data.left =
      rotate % 2 === 0
        ? trimed_image.cropArea.left * imageData.width + marginWidth
        : trimed_image.cropArea.left * imageData.height + marginWidth;
    new_crop_box_data.top =
      rotate % 2 === 0
        ? trimed_image.cropArea.top * imageData.height + marginHeight
        : trimed_image.cropArea.top * imageData.width + marginHeight;
    // 座標から、width, heightを指定する
    new_crop_box_data.width =
      rotate % 2 === 0
        ? imageData.width * Math.abs(trimed_image.cropArea.right - trimed_image.cropArea.left)
        : imageData.height * Math.abs(trimed_image.cropArea.right - trimed_image.cropArea.left);
    new_crop_box_data.height =
      rotate % 2 === 0
        ? imageData.height * Math.abs(trimed_image.cropArea.bottom - trimed_image.cropArea.top)
        : imageData.width * Math.abs(trimed_image.cropArea.bottom - trimed_image.cropArea.top);
    cropper.setCropBoxData(new_crop_box_data);
  }, []);

  // 回転時の関数
  const handleClickRotate = () => {
    const cropper: Cropper | undefined = getCropper();
    if (cropper === undefined) {
      AlertDialog.show('cropper is undefined.');
      return;
    }
    cropper.rotate(90);
  };

  // 次へボタンを押下した時の関数
  const handleFinish = () => {
    const cropper: Cropper | undefined = getCropper();
    if (cropper === undefined) {
      AlertDialog.show('cropper is undefined.');
      return;
    }
    // 座標 最大0 最小1
    const crop_coordinate = getCropCoordinate(cropper);
    const data = cropper.getData();
    const cropBoxData = cropper.getCropBoxData();

    // 画像の少数を切り捨てる。
    // 至る所でmath.floorを使用すると、座標がどんどん小さくなってしまうので1回だけ
    const size: SizeWH = {
      width: Math.floor(cropBoxData.width),
      height: Math.floor(cropBoxData.height),
    };
    const t = cropper.getCroppedCanvas();
    const trimed_image: TrimedImage = {
      url: t.toDataURL('image/jpeg'),
      cropArea: crop_coordinate,
      size: size,
      rotate: data.rotate / 90,
    };
    params.onTrimedImageChange(trimed_image);
    // DrawCrossLineのパネルに移動
    params.onFinish();
  };

  // params.trimed_imageが変わったら再レンダリングしてほしい
  useEffect(() => {
    // params_trimed_image.cropAreaが存在していて、変更された時は再びonReady()
    // useEffect内で呼ばないと、onReady()が動いてくれない
    if (params.trimed_image?.cropArea) {
      onReady(params.trimed_image);
    }
  }, [params.trimed_image, onReady]);

  // -- render part --
  return (
    <Content style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} ref={parentRef}>
      <TopArea>
        <RoundedButton text='回転' onClick={handleClickRotate} small={true} is_margin_right={true} />
        {params.setting_exp_imp_button_props && (
          <SettingExpImpButton
            {...params.setting_exp_imp_button_props}
            trimedImage={params.trimed_image}
            setTrimedImage={params.onTrimedImageChange}
          />
        )}
      </TopArea>
      <Cropper
        style={{ height: 580, width: 580 }} // 正方形の方が処理しやすい
        preview='.img-preview'
        src={params.imgsrc}
        viewMode={0}
        guides={true}
        minCropBoxHeight={50}
        minCropBoxWidth={50}
        background={false}
        responsive={true}
        zoomable={false}
        scalable={true}
        autoCropArea={1}
        ref={cropperRef}
        ready={() => onReady(params.trimed_image)}
      />
      <Footer
        style={{
          marginTop: styles.interval_narrow_margin,
        }}
      >
        <RoundedButton
          text='キャンセル'
          onClick={params.onClose}
          is_white={true}
          is_margin_right={true}
          style={{
            width: styles.small_button_width,
          }}
        />
        <RoundedButton
          text='次へ'
          onClick={handleFinish}
          style={{
            width: styles.small_button_width,
          }}
        />
      </Footer>
    </Content>
  );
};

// -- styled components --

const TopArea = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: ${styles.interval_narrow_margin};
`;

// -- finally export part --

export default TrimPanel;
