import InputNumberBox from 'shared/components/atoms/InputNumberBox';
import { Table, Tbody, Td, Th, Thead, Tr } from 'shared/components/atoms/PfTable';
import SelectBox from 'shared/components/atoms/SelectBox';
import CheckBoxWithText from 'shared/components/molecules/CheckBoxWithText';
import InputComponent from 'shared/components/molecules/InputComponent';
import { TableArea } from 'shared/components/molecules/Table/tableStyles';

/**
 * JSON.stringifiedされたarea_routingを安全に読み込む関数
 */
const loadAreaRouting = (area_routing?: string) => {
  let area_routing_in = [];
  let area_routing_out = [];
  try {
    if (area_routing) {
      const json_area_routing = JSON.parse(area_routing);
      if (Array.isArray(json_area_routing.in)) {
        area_routing_in = json_area_routing.in;
      }
      if (Array.isArray(json_area_routing.out)) {
        area_routing_out = json_area_routing.out;
      }
    }
  } catch (e) {
    // emptyにできないので処理を書く
    area_routing_in = [];
    area_routing_out = [];
  }
  return {
    area_routing_in,
    area_routing_out,
  };
};

type Params = {
  stayareas: string;
  onStayareasChange: (stayareas: string) => void;
  staying_time?: number;
  onStayingTimeChange: (staying_time?: number) => void;
  area_routing?: string;
  onAreaRoutingChange: (area_routing: string) => void;
};

/** 滞留エリアユースケースの固有の設定ペインです。 */
const UsecaseConditionStayalertPane: React.FC<Params> = (params) => {
  const { area_routing_in, area_routing_out } = loadAreaRouting(params.area_routing);

  const handleStayareasChange = (area: string): void => {
    params.onStayareasChange(area);
  };
  const handleAreaRoutingChange = (io: 'in' | 'out', value: string[]) => {
    params.onAreaRoutingChange(
      JSON.stringify(io === 'in' ? { in: value, out: area_routing_out } : { in: area_routing_in, out: value }),
    );
  };
  return (
    <>
      <InputComponent text='滞留エリア' required>
        <SelectBox
          onChange={(e) => handleStayareasChange(e.currentTarget.value)}
          value={params.stayareas}
          datas={[
            { name: 'AREA0', value: 'AREA0' },
            { name: 'AREA1', value: 'AREA1' },
            { name: 'AREA2', value: 'AREA2' },
            { name: 'AREA3', value: 'AREA3' },
            { name: 'AREA4', value: 'AREA4' },
          ]}
        />
      </InputComponent>
      <InputComponent text='滞留時間(秒)'>
        <CheckBoxWithText
          checked={params.staying_time !== undefined}
          text={'判定する'}
          onClick={() => params.onStayingTimeChange(params.staying_time === undefined ? 60 : undefined)}
        />
        {params.staying_time !== undefined && (
          <InputNumberBox
            placeholder='例: 60'
            value={params.staying_time}
            onChange={(v) => params.onStayingTimeChange(v)}
            min={1}
            max={3600 * 24}
          />
        )}
      </InputComponent>
      <InputComponent text='滞留エリアルート'>
        <TableArea>
          <AreaRoutingTable
            labels={['LINE0', 'LINE1', 'LINE2', 'LINE3', 'LINE4']}
            area_routing_in={area_routing_in}
            area_routing_out={area_routing_out}
            onChange={(io, value) => handleAreaRoutingChange(io, value)}
          />
        </TableArea>
      </InputComponent>
    </>
  );
};

type AreaRoutingTableParams = {
  labels: string[];
  area_routing_in: string[];
  area_routing_out: string[];
  onChange: (io: 'in' | 'out', value: string[]) => void;
};
const AreaRoutingTable: React.FC<AreaRoutingTableParams> = (params) => {
  const handleInChange = (label: string, b: boolean) => {
    handleChange('in', params.area_routing_in, label, b);
  };
  const handleOutChange = (label: string, b: boolean) => {
    handleChange('out', params.area_routing_out, label, b);
  };
  const handleChange = (io: 'in' | 'out', c: string[], label: string, b: boolean) => {
    if (b) {
      params.onChange(io, [...c, label]);
    } else {
      params.onChange(
        io,
        c.filter((v) => v !== label),
      );
    }
  };
  return (
    <Table>
      <Thead>
        <tr>
          <Th>ライン</Th>
          <Th>IN</Th>
          <Th>OUT</Th>
        </tr>
      </Thead>
      <Tbody>
        {params.labels.map((label, index1) => {
          return (
            <Tr key={index1}>
              <Td align='center'>{label}</Td>
              <Td align='center'>
                <CheckBoxWithText
                  checked={params.area_routing_in.includes(label)}
                  text={''}
                  onClick={() => handleInChange(label, !params.area_routing_in.includes(label))}
                />
              </Td>
              <Td align='center'>
                <CheckBoxWithText
                  checked={params.area_routing_out.includes(label)}
                  text={''}
                  onClick={() => handleOutChange(label, !params.area_routing_out.includes(label))}
                />
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default UsecaseConditionStayalertPane;
