import { useState } from 'react';
import history from 'shared/browserHistory';
import Spinner from 'shared/components/atoms/Spinner';
import { PageWholeArea, Title, TopArea, BottomArea } from 'shared/components/molecules/ContentsArea';
import PageSwitchingTabs from 'shared/components/molecules/PageSwitchingTabs';
import TabButton from 'shared/components/molecules/TabButton';
import { useDevices } from 'user/hooks/useDevices/useDevices';
import DevicesGrid from './DevicesGrid';
import DevicesTable from './DevicesTable';

const handleTabsClick = (id: string) => {
  switch (id) {
    case 'soracom':
      history.push(`/devices`);
      break;
    case 'counterBox':
      history.push(`/edge/devices`);
      break;
    case 'android':
      history.push(`/android/devices`);
      break;
  }
};

// -- main component --
const Devices = () => {
  // -- local states --
  const [tab_id, setTabId] = useState<string>('table');
  const { devices } = useDevices({ status: 'ACTIVE' });

  // -- render part --
  return (
    <PageWholeArea data-testid='Devices'>
      <TopArea>
        <Title text='デバイス' />
        <TabButton tabId={tab_id} onChange={(tabId) => setTabId(tabId)} />
      </TopArea>
      <PageSwitchingTabs
        display_datas={[
          { id: 'soracom', text: 'SORACOMデバイス' },
          { id: 'counterBox', text: 'Counterboxデバイス' },
          { id: 'android', text: 'Androidデバイス' },
        ]}
        handleClick={(id: string) => handleTabsClick(id)}
        selected_id='soracom'
        initial_selected_id='soracom'
      />
      {devices === undefined ? (
        <Spinner />
      ) : (
        <BottomArea>
          {tab_id === 'table' && <DevicesTable devices={devices} />}
          {tab_id === 'grid' && <DevicesGrid devices={devices} />}
        </BottomArea>
      )}
    </PageWholeArea>
  );
};

// -- finally export part --

export default Devices;
