import React from 'react';
import { AddIcon, DeleteIcon } from 'shared/components/atoms/Icons';
import SelectBox, { SelectData } from 'shared/components/atoms/SelectBox';
import InputComponent from 'shared/components/molecules/InputComponent';
import { Process } from 'user/api/processes';
import { Stream } from 'user/api/streams';

interface StreamSelectionPartProps {
  process: Process;
  streams: Stream[];
  selected_stream_ids: string[][];
  fixed_first_input_stream?: boolean; // 1つ目の入力データ(ストリーム)を固定する場合は指定する
  fixed_input_stream_index?: number;
  onChange: (value: string[][]) => void;
}
/** チャンネルプロセスのストリームを選択する部分 */
export default class StreamSelectionPart extends React.PureComponent<StreamSelectionPartProps> {
  private handleSelectionChange = (v: string[], i: number) => {
    const a = [...this.props.selected_stream_ids];
    // propsでプロセスの制約と選択値にアンマッチが起きている場合
    // リストを拡張します。
    while (a.length < i) {
      a.push(['']);
    }
    a[i] = v;
    this.props.onChange(a);
  };
  /** 安全に選択ストリームIDを取得します。 */
  private getSelectedStreamIds = (i: number) => {
    if (this.props.selected_stream_ids.length > i) {
      return this.props.selected_stream_ids[i];
    }
    return [];
  };
  render() {
    return (
      <>
        {this.props.process.input_stream_constraints.map((c, i) => {
          return (
            <InputComponent text={`入力データ(${i + 1})`} required key={i}>
              <StreamConstraintSelectBoxList
                testid={`input-stream-${i + 1}`}
                data_type={c.data_type}
                data_number_type={c.data_number_type}
                max_repeats={c.max_repeats}
                selectedStreamIds={this.getSelectedStreamIds(i)}
                streams={this.props.streams}
                fixed_first_input_stream={
                  (i === 0 && this.props.fixed_first_input_stream === true) || this.props.fixed_input_stream_index === i
                }
                onChange={(v) => this.handleSelectionChange(v, i)}
              />
            </InputComponent>
          );
        })}
      </>
    );
  }
}

/** 入力データ(ストリーム)制約ごとのストリーム選択ボックス */
interface StreamConstraintSelectBoxListProps {
  data_type: string;
  data_number_type: string;
  max_repeats: number;
  selectedStreamIds: string[];
  streams: Stream[];
  fixed_first_input_stream: boolean; // 1つ目の入力データ(ストリーム)を固定する場合は指定する
  onChange: (value: string[]) => void;
  testid?: string;
}
class StreamConstraintSelectBoxList extends React.PureComponent<StreamConstraintSelectBoxListProps> {
  private handleChangeClick = (s: string, i: number) => {
    const a = [...this.getSelectedStreamIds()];
    a[i] = s;
    this.props.onChange(a);
  };
  private handleAddClick = () => {
    this.props.onChange([...this.getSelectedStreamIds(), '']);
  };
  private handleDeleteClick = (i: number) => {
    const a = [...this.props.selectedStreamIds];
    a.splice(i, 1);
    this.props.onChange(a);
  };
  /** リストボックスに表示するストリームアイテムを作成する */
  private get_datas = () => {
    const ret: SelectData[] = [];
    const data_types = this.props.data_type.split(',');
    const data_number_types = this.props.data_number_type.split(',');
    for (const o of this.props.streams) {
      if (data_types.indexOf(o.data_type) !== -1 && data_number_types.indexOf(o.data_number_type) !== -1) {
        ret.push({ name: o.stream_name, value: o.stream_id });
      }
    }
    return ret;
  };
  private get_fixed_datas = () => {
    const ret: SelectData[] = [];
    for (const o of this.props.streams) {
      if (o.stream_id === this.props.selectedStreamIds[0]) {
        ret.push({ name: o.stream_name, value: o.stream_id });
      }
    }
    return ret;
  };
  /** 安全に選択リストを返します。 */
  private getSelectedStreamIds = () => {
    if (this.props.selectedStreamIds.length === 0) {
      // 最低1要素が必要です
      return [''];
    } else if (this.props.selectedStreamIds.length > this.props.max_repeats) {
      return this.props.selectedStreamIds.slice(0, this.props.max_repeats);
    }
    return this.props.selectedStreamIds;
  };
  render() {
    const datas = this.get_datas();
    const selectedStreamIds = this.getSelectedStreamIds();
    const testid = `${this.props.testid}`;
    return (
      <>
        {selectedStreamIds.map((s, i) => {
          return (
            <div key={i}>
              <SelectBox
                data-testid={`${i}-${testid}`}
                onChange={(e) => this.handleChangeClick(e.currentTarget.value, i)}
                value={s}
                default_text={i === 0 && this.props.fixed_first_input_stream ? 'null' : undefined}
                datas={i === 0 && this.props.fixed_first_input_stream ? this.get_fixed_datas() : datas}
              />
              {i !== 0 && <DeleteIcon onClick={() => this.handleDeleteClick(i)} />}
            </div>
          );
        })}
        {selectedStreamIds.length !== this.props.max_repeats && (
          <div>
            <AddIcon onClick={this.handleAddClick} />
          </div>
        )}
      </>
    );
  }
}
