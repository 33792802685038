export type WidgetType =
  | 'GRAPH'
  | 'LIVETEXT'
  | 'VIDEOPLAYER'
  | 'MAP'
  | 'BUSOD1'
  | 'BUSOD2'
  | 'BUSOD3'
  | 'HEATMAP'
  | 'AREACOUNT'
  | 'SUMUPGRAPH';
const widget_type_record = {
  GRAPH: 0,
  LIVETEXT: 0,
  VIDEOPLAYER: 0,
  MAP: 0,
  BUSOD1: 0,
  BUSOD2: 0,
  BUSOD3: 0,
  HEATMAP: 0,
  AREACOUNT: 0,
  SUMUPGRAPH: 0,
} as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isWidgetType = (data: any): data is WidgetType => typeof data === 'string' && data in widget_type_record;
