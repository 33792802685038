import { AppParameter } from 'shared/models/AppParameter';
import { CommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';
import { HeartbeatParameter } from 'shared/models/HeartbeatParameter';
import { ISO8601 } from 'shared/models/ISO8601';
import { ProcessingStateType } from 'shared/models/ProcessingStateType';
import { Query } from 'shared/models/Query';
import { StatusType } from 'shared/models/StatusType';
import sendAxios from 'shared/axios/sendAxios';
import { getClient } from './base';
import { AxiosResponse } from 'axios';

/*** エンティティ ***/

export interface ChannelProcess {
  tenant_id: string;
  channel_id: string;
  channel_process_number: number;
  channel_process_name: string;
  status: StatusType;
  process_id: string;
  input_stream_ids: string[];
  output_stream_ids: string[];
  app_parameter: AppParameter;
  heartbeat: HeartbeatParameter;
  is_oneshot: boolean;
  input_stream_data_number?: number;
  delay_seconds: number;
  target_term_from?: number;
  target_term_to?: number;
  processing_state?: ProcessingStateType;
  processing_progress?: number;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

export interface ChannelProcessesWithPaging {
  items: ChannelProcess[];
  has_next: boolean;
  last_evaluated_channel_process_number?: string;
}

const sample_channel_process_1: ChannelProcess = {
  tenant_id: '00000001',
  channel_id: '00000001',
  channel_process_number: 1,
  channel_process_name: 'string',
  processing_progress: 100,
  processing_state: 'FINISHED',
  status: 'ACTIVE',
  process_id: '00000001',
  input_stream_ids: ['00000001', '00000001', '00000002'],
  output_stream_ids: ['00000001', '00000001', '00000002'],
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  app_parameter: {
    STAYAREA:
      '{"cropArea":{"left":0,"top":0,"right":0.6002837082435345,"bottom":0.5907854593211207},"crossLine":[[[0.3635456536816382,0.3018955888419316],[0.6307878590964862,0.3018955888419316],[0.6307878590964862,0.7053438647040006],[0.3635456536816382,0.7053438647040006]],[[0.7428571710446483,0.6001714509108971],[0.5281393635449898,0.6746379408609469],[0.41752426171227763,0.3709153197875098],[0.5638781766102893,0.10873792686187064],[0.7649449722365822,0.2504260080254266]]],"height":214,"width":348,"cropRotate":0}',
  },
  input_stream_data_number: 1,
  is_oneshot: false,
  heartbeat: {},
  delay_seconds: 0,
};

const sample_channel_process_2: ChannelProcess = {
  tenant_id: '00000001',
  channel_id: '00000001',
  channel_process_number: 2,
  channel_process_name: 'string',
  processing_progress: 30,
  processing_state: 'PROVISIONING',
  status: 'ACTIVE',
  process_id: '00000002',
  input_stream_ids: ['00000001', '00000001', '00000002'],
  output_stream_ids: ['00000001', '00000001', '00000002'],
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  app_parameter: { by_gender_count: 'true' },
  input_stream_data_number: 2,
  is_oneshot: false,
  heartbeat: {},
  delay_seconds: 0,
};

const sample_channel_process_3: ChannelProcess = {
  tenant_id: '00000001',
  channel_id: '00000001',
  channel_process_number: 3,
  channel_process_name: 'string',
  status: 'ACTIVE',
  process_id: '00000003',
  input_stream_ids: ['00000002', '00000002', '00000002'],
  output_stream_ids: ['00000002', '00000002', '00000002'],
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  input_stream_data_number: 3,
  app_parameter: {},
  heartbeat: {},
  is_oneshot: false,
  delay_seconds: 0,
};

/*** Caching mechanism ***/

export class CachedChannelProcesses {
  private searched = false;
  private params: RequestchannelsIdProcessesGet;
  private cache: ChannelProcess[] = [];
  constructor(params: RequestchannelsIdProcessesGet) {
    this.params = params;
  }
  async get() {
    if (!this.searched) {
      let has_next = true;
      let exclusive_start_key: string | undefined = undefined;
      while (has_next) {
        const res: AxiosResponse<ChannelProcessesWithPaging> = await channelsIdProcessesGetAPI({
          ...this.params,
          exclusive_start_channel_process_number: exclusive_start_key,
        });
        has_next = false;
        if (res.status === 200) {
          this.cache = [...this.cache, ...res.data.items];
          has_next = res.data.has_next;
          exclusive_start_key = res.data.last_evaluated_channel_process_number;
        }
      }
      this.searched = true;
    }
    return this.cache;
  }
}

/*** [GET] /api/channels/{id}/processes ***/

export interface RequestchannelsIdProcessesGet extends CommonAPIRequestType {
  channel_id: string;
  exclusive_start_channel_process_number?: string;
  limit_process?: string;
}
/**
 * チャンネルに設定されたプロセスの一覧を取得する
 * @returns チャンネルのプロセスの一覧
 */
export const channelsIdProcessesGetAPI = (params: RequestchannelsIdProcessesGet) => {
  const {
    channel_id,
    exclusive_start_channel_process_number = '',
    limit_process = '',
    disabled_load,
    disabled_error_message,
    ended_load,
  } = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/channels/${channel_id}/processes`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    exclusive_start_channel_process_number,
    limit_process,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<ChannelProcessesWithPaging>(axios, path, query, form, method, {
    items: [sample_channel_process_1, sample_channel_process_2, sample_channel_process_3],
    has_next: false,
  });
};

/*** [POST] /api/channels/{id}/processes ***/

export interface RequestChannelsIdProcessesPost extends CommonAPIRequestType {
  channel_id: string;
  channel_process_name: string;
  process_id: string;
  input_stream_ids: string[][];
  app_parameter?: AppParameter;
  heartbeat?: HeartbeatParameter;
  is_oneshot?: 'True';
  input_stream_data_number?: string;
  delay_seconds?: number;
  target_term_from?: number;
  target_term_to?: number;
}

export const channelsIdProcessesPostAPI = (params: RequestChannelsIdProcessesPost) => {
  const {
    channel_id,
    channel_process_name,
    process_id,
    input_stream_ids,
    app_parameter,
    heartbeat,
    is_oneshot,
    input_stream_data_number,
    delay_seconds,
    target_term_from,
    target_term_to,
    disabled_load,
    disabled_error_message,
    ended_load,
  } = toAPIRequestParams({ ...params, api_send_type: params.api_send_type ?? 'changeableOneTransmission' });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/channels/${channel_id}/processes`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  form.append('channel_process_name', channel_process_name);
  form.append('process_id', process_id);
  for (let i = 0; i < input_stream_ids.length; i++) {
    for (let j = 0; j < input_stream_ids[i].length; j++) {
      form.append(`input_stream_id[${i}:${j}]`, input_stream_ids[i][j]);
    }
  }
  if (app_parameter) {
    for (const [key, value] of Object.entries(app_parameter)) {
      form.append('app_parameter.' + key, value);
    }
  }
  if (heartbeat) {
    for (const [key, value] of Object.entries(heartbeat)) {
      form.append('heartbeat.' + key, value);
    }
  }
  if (is_oneshot) {
    form.append('is_oneshot', is_oneshot);
  }
  if (input_stream_data_number) {
    form.append('input_stream_data_number', input_stream_data_number);
  }
  if (typeof delay_seconds === 'number') {
    form.append('delay_seconds', String(delay_seconds));
  }
  if (typeof target_term_from === 'number') {
    form.append('target_term_from', String(target_term_from));
  }
  if (typeof target_term_to === 'number') {
    form.append('target_term_to', String(target_term_to));
  }

  // 送信
  return sendAxios<ChannelProcess>(axios, path, query, form, method, sample_channel_process_1);
};

/*** [PUT] /api/channels/{id}/processes/{channel_process_number} ***/

export interface RequestChannelsIdProcessesPut extends CommonAPIRequestType {
  channel_id: string;
  channel_process_number: string;
  channel_process_name: string;
  app_parameter?: AppParameter;
  heartbeat?: HeartbeatParameter;
  delay_seconds?: number;
}

export const channelsIdProcessesNumPutAPI = (params: RequestChannelsIdProcessesPut) => {
  const {
    channel_id,
    channel_process_number,
    channel_process_name,
    app_parameter,
    heartbeat,
    delay_seconds,
    disabled_load,
    disabled_error_message,
    ended_load,
  } = toAPIRequestParams({ ...params, api_send_type: params.api_send_type ?? 'changeableOneTransmission' });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/channels/${channel_id}/processes/${channel_process_number}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  form.append('channel_process_name', channel_process_name);
  if (app_parameter) {
    for (const [key, value] of Object.entries(app_parameter)) {
      form.append('app_parameter.' + key, value);
    }
  }
  if (heartbeat) {
    for (const [key, value] of Object.entries(heartbeat)) {
      form.append('heartbeat.' + key, value);
    }
  }
  if (typeof delay_seconds === 'number') {
    form.append('delay_seconds', String(delay_seconds));
  }

  // 送信
  return sendAxios<ChannelProcess>(axios, path, query, form, method, sample_channel_process_1);
};

/*** [DELETE] /api/channels/{id}/processes/{channel_process_number} ***/

export interface RequestChannelsIdProcessesChannelProcessNumberDelete extends CommonAPIRequestType {
  channel_id: string;
  channel_process_number: string;
}

export const channelsIdProcessesChannelProcessNumberDeleteAPI = (
  params: RequestChannelsIdProcessesChannelProcessNumberDelete,
) => {
  const { channel_id, channel_process_number, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'multipleTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/channels/${channel_id}/processes/${channel_process_number}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<ChannelProcess>(axios, path, query, form, method, sample_channel_process_1);
};

/*** [GET] /api/channels/{id}/processes/{channel_process_number} ***/

export interface RequestChannelsIdProcessesChannelProcessNumberGet {
  channel_id: string;
  channel_process_number: string;
}

/**
 * チャンネルに設定された指定のプロセスを取得する
 * @param channel_id チャンネルID
 * @returns チャンネルのプロセス
 */
export const channelsIdProcessesNumGetAPI = (params: RequestChannelsIdProcessesChannelProcessNumberGet) => {
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `/api/channels/${params.channel_id}/processes/${params.channel_process_number}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<ChannelProcess>(axios, path, query, form, method, sample_channel_process_1);
};

/*** [POST] /api/channels/{channel_id}/processflow ***/
export interface ProcessflowStep {
  input_stream_ids: string[][];
  app_parameter?: AppParameter;
  heartbeat?: HeartbeatParameter;
}

export interface RequestChannelsIdProcessflowPost extends CommonAPIRequestType {
  channel_id: string;
  process_flow_id: string;
  channel_process_name: string;
  steps: ProcessflowStep[];
  is_oneshot?: 'True';
  input_stream_data_number?: (string | number)[];
  delay_seconds?: number;
  target_term_from?: number;
  target_term_to?: number;
}

/**
 * チャンネルに指定のプロセスフローを設定する
 * @param channel_id チャンネルID
 * @returns チャンネルのプロセス
 */
export const channelsIdProcessflowPostAPI = (params: RequestChannelsIdProcessflowPost) => {
  const {
    channel_id,
    channel_process_name,
    process_flow_id,
    steps,
    is_oneshot,
    input_stream_data_number,
    delay_seconds,
    target_term_from,
    target_term_to,
    disabled_load,
    disabled_error_message,
    ended_load,
  } = toAPIRequestParams({ ...params, api_send_type: params.api_send_type ?? 'changeableOneTransmission' });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/channels/${channel_id}/processflow`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  form.append('channel_process_name', channel_process_name);
  form.append('process_flow_id', process_flow_id);
  for (let i = 0; i < steps.length; i++) {
    const step = steps[i];
    // step[i].input_stream_id[j:k]
    for (let j = 0; j < step.input_stream_ids.length; j++) {
      for (let k = 0; k < step.input_stream_ids[j].length; k++) {
        form.append(`step[${i}].input_stream_id[${j}:${k}]`, step.input_stream_ids[j][k]);
      }
    }
    // step[i].app_parameter.X
    if (step.app_parameter) {
      for (const [key, value] of Object.entries(step.app_parameter)) {
        form.append(`step[${i}].app_parameter.` + key, value);
      }
    }
    // step[i].heartbeat.X
    if (step.heartbeat) {
      for (const [key, value] of Object.entries(step.heartbeat)) {
        form.append(`step[${i}].heartbeat.` + key, value);
      }
    }
  }

  if (is_oneshot) {
    form.append('is_oneshot', is_oneshot);
  }
  if (input_stream_data_number !== undefined) {
    form.append('input_stream_data_number', String(input_stream_data_number));
  }
  if (typeof delay_seconds === 'number') {
    form.append('delay_seconds', String(delay_seconds));
  }
  if (typeof target_term_from === 'number') {
    form.append('target_term_from', String(target_term_from));
  }
  if (typeof target_term_to === 'number') {
    form.append('target_term_to', String(target_term_to));
  }

  // 送信
  return sendAxios<ChannelProcess>(axios, path, query, form, method, sample_channel_process_1);
};
