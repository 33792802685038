import { PolarGraphData } from 'shared/components/molecules/PfGraph';
import { CommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';
import { LineDirectionType } from 'shared/models/LineDirectionType';
import { Query } from 'shared/models/Query';
import { StreamDeviceGraphTargetType } from 'shared/models/StreamDeviceGraphTarget';
import sendAxios from '../../shared/axios/sendAxios';
import { getClient } from './base';
import { WidgetPeriod } from 'shared/models/WidgetPeriod';
import { StreamDeviceGraph } from 'shared/models/StreamDeviceGraph';

/*** エンティティ ***/

const sample_graph_candidate1: StreamDeviceGraph = {
  id: 'LINE0_LR_GENDER_CIRCLE',
  target: 'GENDER',
  direction: 'LR',
  graph_type: 'CIRCLE',
  line_number: 0,
};

const sample_graph_candidate2: StreamDeviceGraph = {
  id: 'LINE0_LR_GENERATION_CIRCLE',
  target: 'GENERATION',
  direction: 'LR',
  graph_type: 'CIRCLE',
  line_number: 0,
};

export interface DatapointItem {
  datapoint: number;
  [key: string]: number;
}

const sample_graph_data1: PolarGraphData = {
  name: 'female',
  value: 6,
};

const sample_graph_data2: PolarGraphData = {
  name: 'male',
  value: 9,
};

/*** [GET] /api/streams/graphs/candidates ***/

export interface RequestStreamsGraphsCandidatesGet {
  stream_id: string;
}

export interface StreamsGraphsCandidatesGetResponse {
  graph_candidates: StreamDeviceGraph[];
}

export const streamsIdGraphsCandidatesGetAPI = (params: RequestStreamsGraphsCandidatesGet) => {
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `/api/streams/${params.stream_id}/graphs/candidates`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<StreamsGraphsCandidatesGetResponse>(axios, path, query, form, method, {
    graph_candidates: [sample_graph_candidate1, sample_graph_candidate2],
  });
};

/*** [GET] /api/streams/{stream_id}/graphs/data ***/

export interface RequestStreamsGraphsDataGet extends CommonAPIRequestType {
  stream_id: string;
  stream_device_graph_id: string;
  start?: string; // メトリクスの収集開始番号
  end?: string; // メトリクスの収集終了
  period?: WidgetPeriod;
}

export type StreamsGraphsDataGetResponse =
  | {
      stream_device_graph_id: string;
      line_number: number;
      direction: LineDirectionType;
      target: StreamDeviceGraphTargetType;
      graph_type: 'BAR' | 'TABLE';
      items: DatapointItem[];
    }
  | {
      stream_device_graph_id: string;
      line_number: number;
      direction: LineDirectionType;
      target: StreamDeviceGraphTargetType;
      graph_type: 'CIRCLE';
      items: PolarGraphData[];
    };

export const streamsIdGraphsDatGetAPI = (params: RequestStreamsGraphsDataGet) => {
  const { stream_id, stream_device_graph_id, start, end, period, disabled_load, disabled_error_message, ended_load } =
    toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/streams/${stream_id}/graphs/data`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    stream_device_graph_id,
    start,
    end,
    period,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<StreamsGraphsDataGetResponse>(axios, path, query, form, method, {
    items: [sample_graph_data1, sample_graph_data2],
    stream_device_graph_id: 'LINE0_LR_GENDER_CIRCLE',
    target: 'GENDER',
    direction: 'LR',
    graph_type: 'CIRCLE',
    line_number: 0,
  });
};
