// -- basic library --
import React, { useEffect, useState } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import history from 'shared/browserHistory';
import Spinner from 'shared/components/atoms/Spinner';
import { PageWholeArea, Title, Content } from 'shared/components/molecules/ContentsArea';
import PageSwitchingTabs from 'shared/components/molecules/PageSwitchingTabs';
import styles from 'shared/styles/styles';
import { systemSelector } from 'user/redux/slices/systemSlice';
import { preloadGroup } from 'user/utils/preloadGroup';
import { EdgeDevice, edgeDevicesKeyGetAPI } from 'user/api/edgeDevices';
import RecordedVideos from '../RecordedVideosPage';
import EdgeDevicesOverviews from '../OverviewsPage/OverviewsPage';
import AiCamCard from '../AISetting';

export interface EventData {
  edge_device_key: string;
  happened_at: string;
  is_checked: string;
}

// -- main component --

const EdgeDevicesDetail: React.FC<
  RouteComponentProps<{
    edge_device_key: string;
    panel_id?: string;
  }>
> = (params) => {
  const system_state = useSelector(systemSelector);

  // -- local states --
  const [edge_device, setEdgeDevice] = useState<EdgeDevice | undefined>(undefined);

  // -- handlers --

  const loadEdgeDevice = async (edge_device_key: string) => {
    const res = await edgeDevicesKeyGetAPI({ edge_device_key: edge_device_key });
    if (res.status === 200) {
      setEdgeDevice(res.data);
    }
  };

  // タブを切り替える時に使う関数
  const handleTabsClick = (tab_id: string, edge_device_key: string) => {
    history.push(`/edge/devices/${edge_device_key}/${tab_id}`);
  };

  // -- onload function --
  useEffect(() => {
    preloadGroup(loadEdgeDevice(params.match.params.edge_device_key));
  }, [params.match.params.edge_device_key]);

  // -- render part --
  return (
    <PageWholeArea data-testid='EdgeDeviceDetail'>
      {system_state.loading.preloading || edge_device === undefined ? (
        <Spinner />
      ) : (
        <Content>
          <Title
            text={edge_device.heartbeat_edge_device_name}
            style={{
              marginTop: styles.interval_narrow_margin,
              marginBottom: styles.interval_margin,
            }}
          />
          <React.Fragment>
            <PageSwitchingTabs
              display_datas={[
                {
                  text: 'デバイス概要',
                  id: 'overviews',
                },
                {
                  text: '録画動画一覧',
                  id: 'recorded-movies',
                },
                {
                  text: 'AI設定',
                  id: 'ai-setting',
                },
              ]}
              handleClick={(id: string) => handleTabsClick(id, params.match.params.edge_device_key)}
              selected_id={params.match.params.panel_id}
              initial_selected_id='overviews'
            />
            <Switch>
              <Route
                exact
                path='/edge/devices/:edge_device_key/overviews'
                render={() => <EdgeDevicesOverviews edge_device={edge_device} />}
              />
              <Route
                exact
                path='/edge/devices/:edge_device_key/recorded-movies'
                render={() => <RecordedVideos edge_device={edge_device} />}
              />
              <Route
                exact
                path='/edge/devices/:edge_device_key/ai-setting'
                render={() => <AiCamCard edge_device={edge_device} />}
              />
            </Switch>
          </React.Fragment>
        </Content>
      )}
    </PageWholeArea>
  );
};

// -- finally export part --

export default EdgeDevicesDetail;
