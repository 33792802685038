import { SelectData } from 'shared/components/atoms/SelectBox';
import { assertNever } from 'shared/utils/else/assertNever';

export type TableauDataType = 'count_data' | 'retention_data';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isTableauDataType = (data: any): data is TableauDataType =>
  data === 'count_data' || data === 'retention_data';

export const tableauDataTypeToJp = (tableau_data_type: TableauDataType) => {
  switch (tableau_data_type) {
    case 'count_data':
      return 'カウント';
    case 'retention_data':
      return '滞留';
    default:
      assertNever(tableau_data_type);
      return '';
  }
};

export const select_tableau_data_type: SelectData[] = [
  {
    name: tableauDataTypeToJp('count_data'),
    value: 'count_data',
  },
  {
    name: tableauDataTypeToJp('retention_data'),
    value: 'retention_data',
  },
];
