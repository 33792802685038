// -- basic library --
import React from 'react';

// -- https --
import history from 'shared/browserHistory';

// -- external components --
import Spinner from 'shared/components/atoms/Spinner';

// -- external functions --
import AlertDialog from 'shared/components/molecules/AlertDialog';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import ConfirmText from 'shared/components/molecules/ConfirmText';
import { Content } from 'shared/components/molecules/ContentsArea';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableText } from 'shared/components/molecules/Table/tableStyles';
import {
  TableHeaderType,
  TableBodyClickType,
  TableBodyMultipleValueType,
} from 'shared/components/molecules/Table/type';
import { isNotSelected } from 'shared/utils/is';
import { dateToYMDHM } from 'shared/utils/converter/date';
import { Channel } from 'user/api/channels';
import {
  ChannelEvent,
  channelsIdEventsChannelEventNumberConfirmPostAPI,
  channelsIdEventsGetAPI,
} from 'user/api/channelsEvents';
import { eventsGetAPI, Event } from 'user/api/events';
import { loadWrapperFunc } from 'user/utils/loadWrapperFunc';
import { keyBy } from 'shared/utils/converter';
import { Search } from 'shared/models/Search';
import { EventsDownloadDialog } from './EventsDownloadDialog';

// -- type declaration --

// テーブルの列の情報まとめたデータ
const headers: TableHeaderType[] = [
  {
    id: 'created_at',
    label: '発生日時',
    width: `15%`,
    sortable: true,
    search_props: {
      type: 'datetime',
    },
  },
  {
    id: 'channel_name',
    label: 'チャンネル名',
    width: `12%`,
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'channel_event_name',
    label: 'チャンネルイベント名',
    width: `17%`,
    sortable: true,
    search_props: {
      type: 'name',
      default_display: true,
    },
  },
  {
    id: 'confirmed',
    label: '確認',
    width: `9%`,
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'confirmed_username',
    label: '確認者',
    width: `10%`,
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'comment',
    label: 'コメント',
    width: `auto`,
    search_props: {
      type: 'name',
    },
  },
];

// BaseTableのbodyのtype
export type TableEventsType = {
  id: string;
  created_at: TableBodyClickType;
  channel_name: string;
  channel_event_name: string;
  confirmed: TableBodyMultipleValueType<string>;
  confirmed_username: string;
  comment: TableBodyMultipleValueType<{
    user_id: string;
    user_name: string;
    comment: string;
    created_at: string;
  } | null>;
  channel_id: string;
  channel_event_number: string;
};

const commentToTableBodyComment = (
  comment: {
    user_id: string;
    user_name: string;
    comment: string;
    created_at: string;
  } | null,
): TableEventsType['comment'] => {
  const value = comment;
  const comment_text = comment?.comment;
  const created_at = comment ? dateToYMDHM(comment.created_at) : '不明';
  const available_value = `${created_at} ${comment_text}`;
  const displayable_value = (
    <div>
      <TableText title={created_at} style={{ fontSize: '0.4em' }}>
        {created_at}
      </TableText>
      <TableText title={comment_text}>{comment_text}</TableText>
    </div>
  );

  return {
    value,
    available_value,
    displayable_value,
  };
};

interface EventsTableProps {
  channels?: Channel[];
  selected_channel_id: string;
  onEventSelect?: (channelId: string, channelEventNumber: number) => void;
}

interface EventsTableState {
  selected_bodies: TableEventsType[];
  bodies?: TableEventsType[];
  has_next_from_channel_event_number: boolean;
  has_next_from_created_at: boolean;
  exclusive_start_channel_event_number?: number;
  exclusive_start_created_at?: number;
  show_download_dialog: boolean;
  download_dialog_serches?: Search[];
}

export default class EventsTable extends React.PureComponent<EventsTableProps, EventsTableState> {
  constructor(props: EventsTableProps) {
    super(props);
    this.state = {
      selected_bodies: [],
      has_next_from_channel_event_number: false,
      has_next_from_created_at: false,
      show_download_dialog: false
    };
  }
  async componentDidMount() {
    this.reload();
  }
  async componentDidUpdate(prevProps: EventsTableProps) {
    if (this.props.selected_channel_id !== prevProps.selected_channel_id) {
      this.reload();
    }
  }
  public reload = async () => {
    await this.loadEvents();
  };
  private handleDetailClick = (event: ChannelEvent) => {
    if (this.props.onEventSelect) {
      this.props.onEventSelect(event.channel_id, event.channel_event_number);
    }
  };

  private handleCheckClick = (bodies: TableEventsType[]) => {
    this.setState({
      selected_bodies: bodies,
    });
  };

  // /user/api/eventsからイベントのデータを取得する関数
  private getEventDatas = async (event_has_next: boolean, event_exclusive_start_key?: number | null) => {
    let items: Event[] = [];
    if (!event_has_next) {
      return [];
    }
    let has_next: boolean = event_has_next;
    let esk = event_exclusive_start_key ?? undefined;
    const res = await eventsGetAPI({
      exclusive_start_created_at: esk,
      order: 'DESC',
    });
    has_next = false;
    if (res.status === 200) {
      items = items.concat(res.data.items);
      has_next = res.data.has_next;
      esk = res.data.esk ?? undefined;
    }
    this.setState({
      has_next_from_created_at: has_next,
      exclusive_start_created_at: esk,
      has_next_from_channel_event_number: false,
      exclusive_start_channel_event_number: undefined,
    });
    return items;
  };

  // /user/api/channnels/{channel_id}/eventsからイベントのデータを取得する関数
  private getChannelEventDatas = async (
    channel_id: string,
    has_next_for_event_number: boolean,
    exclusive_start_event_number?: number,
  ) => {
    let event_items: Event[] = [];
    let has_next = has_next_for_event_number;
    if (!has_next) {
      return [];
    }
    let esk: number | undefined = exclusive_start_event_number;
    const res = await channelsIdEventsGetAPI({
      channel_id,
      exclusive_start_channel_event_number: esk,
      order: 'DESC',
    });
    has_next = false;
    if (res.status === 200) {
      event_items = event_items.concat(res.data.items);
      has_next = res.data.has_next;
      esk = res.data.esk ?? undefined;
    }
    this.setState({
      has_next_from_channel_event_number: has_next,
      exclusive_start_channel_event_number: esk,
      has_next_from_created_at: false,
      exclusive_start_created_at: undefined,
    });
    return event_items;
  };

  private loadEvents = async () => {
    let events = [];
    // 特定のチャンネルが指定されているとき
    if (this.has_channel_id) {
      const { selected_channel_id } = this.props;
      events = await this.getChannelEventDatas(selected_channel_id, true);
    } else {
      events = await this.getEventDatas(true);
    }
    this.setState({ bodies: this.eventsToBodies(events) });
  };

  // テーブルのページングで新しいデータを追加取得する
  private addEvents = async () => {
    let new_bodies = this.state.bodies;
    let next_bodies: TableEventsType[] = [];
    // 特定のチャンネルが選ばれているとき
    if (this.has_channel_id) {
      const { has_next_from_channel_event_number, exclusive_start_channel_event_number } = this.state;
      if (!has_next_from_channel_event_number) return;
      const next_events = await this.getChannelEventDatas(
        this.props.selected_channel_id,
        has_next_from_channel_event_number,
        exclusive_start_channel_event_number,
      );
      next_bodies = this.eventsToBodies(next_events);
    } else {
      const { has_next_from_created_at, exclusive_start_created_at } = this.state;
      if (!has_next_from_created_at) return;
      const next_events = await this.getEventDatas(has_next_from_created_at, exclusive_start_created_at);
      next_bodies = this.eventsToBodies(next_events);
    }
    new_bodies = new_bodies ? [...new_bodies, ...next_bodies] : next_bodies;
    this.setState({
      bodies: new_bodies,
    });
  };

  /** 「ダウンロードする」ボタンの処理ハンドラ */
  private handleDownloadClick = async (searches: Search[]) => {
    this.setState({
      show_download_dialog: true,
      download_dialog_serches: searches
    });
  }
  private handleDownloadDialogClose = () => {
    this.setState({show_download_dialog: false})
  }

  // 初期化関数
  private initializeActions = () => {
    this.setState({
      selected_bodies: [],
      has_next_from_created_at: false,
      has_next_from_channel_event_number: false,
      exclusive_start_channel_event_number: undefined,
      exclusive_start_created_at: undefined,
    });
  };

  // テーブルしたの確認済みにするテキストの関数
  private handleConfirmButtonClick = async (selected_bodies: TableEventsType[]) => {
    // 確認済み関数定義
    const multipleConfirm = async () => {
      let can_not_confirm_flag = false;
      for (let i = 0; i < selected_bodies.length; i++) {
        if (selected_bodies[i].confirmed.value === 'true') {
          can_not_confirm_flag = true;
          continue;
        }
        await channelsIdEventsChannelEventNumberConfirmPostAPI({
          channel_id: selected_bodies[i].channel_id,
          channel_event_number: selected_bodies[i].channel_event_number,
          api_send_type: 'multipleTransmission',
        });
      }
      if (can_not_confirm_flag) {
        AlertDialog.show(`すでに確認済みのデータは確認済みにできませんでした`);
      }
      await this.loadEvents();
      this.initializeActions();
    };

    await loadWrapperFunc(multipleConfirm);
  };

  // dialogで確認を促す
  private handleConfirm = async (selected_bodies: TableEventsType[]) => {
    ConfirmDialog.show(
      '[確認]選択されている項目を確認済みします。本当によろしいですか？',
      () => this.handleConfirmButtonClick(selected_bodies),
      () => history.push(`/events`),
      undefined,
    );
  };

  private get has_channel_id(): boolean {
    return this.props.selected_channel_id !== 'all';
  }

  private get channels_record(): Record<string, Channel> | undefined {
    const { channels } = this.props;
    const record = channels ? keyBy(channels, (c) => c.channel_id) : undefined;
    return record;
  }

  private eventsToBodies = (events: ChannelEvent[]) => {
    const channels_record = this.channels_record;
    return events.map((event) => {
      const channel_id = event.channel_id;
      const confirm = event.confirm;
      const latest_comment = event.latest_comment;
      const body: TableEventsType = {
        id: `${channel_id}:${event.channel_event_number}`,
        created_at: {
          value: dateToYMDHM(event.created_at),
          onClick: () => this.handleDetailClick(event),
        },
        channel_name: channels_record?.[channel_id]?.channel_name ?? '',
        channel_event_name: event.channel_event_name,
        confirmed: {
          value: confirm ? 'true' : 'false',
          available_value: confirm ? '確認済み' : '未確認',
          displayable_value: <ConfirmText confirmed={!!confirm} />,
        },
        confirmed_username: confirm ? confirm.user_name : '',
        comment: commentToTableBodyComment(latest_comment),
        channel_id: channel_id,
        channel_event_number: String(event.channel_event_number),
      };
      return body;
    });
  };

  private get has_next() {
    return this.has_channel_id ? this.state.has_next_from_channel_event_number : this.state.has_next_from_created_at;
  }

  render() {
    const { bodies } = this.state;
    return (
      <Content>
        {bodies !== undefined ? (
          <BaseTable
            bodies={bodies}
            headers={headers}
            table_name='events'
            selected_bodies={this.state.selected_bodies}
            handleCheckClick={this.handleCheckClick}
            id_abridgement={true}
            handleAddDatas={this.addEvents}
            has_next={this.has_next}
            footer_option={{
              text: '＞チェックした項目を確認済みにする',
              handleClick: () => this.handleConfirm(this.state.selected_bodies),
              disabled: isNotSelected(this.state.selected_bodies.length),
            }}
            onDownloadClick={this.handleDownloadClick}
          />
        ) : (
          <Spinner />
        )}
        {this.state.show_download_dialog && 
          <EventsDownloadDialog
            channel_id={this.has_channel_id ? this.props.selected_channel_id : undefined}
            channels_record={this.channels_record}
            searches={this.state.download_dialog_serches || []}
            onClose={this.handleDownloadDialogClose}
          />
        }
      </Content>
    );
  }
}
