// コンテンツの白背景マット
import { Card, CardProps } from '@blueprintjs/core';
import styles from 'shared/styles/styles';
import styled from 'styled-components';

interface ContentBodyMatProps extends CardProps {
  flexible?: boolean;
}

export const ContentBodyMat = styled(({ flexible: _flexible, ...props }: ContentBodyMatProps) => <Card {...props} />)`
  width: 100%;
  height: 100%;
  display: ${({ flexible }) => (flexible ? 'flex' : 'block')};
  box-shadow: none;
  padding: 30px;
  border-radius: ${styles.border_radius};
`;

export default ContentBodyMat;
