import React from 'react';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/types/Node';
import { Rect as RectRefType } from 'konva/types/shapes/Rect';
import { Image as KonvaImage, Layer as KonvaLayer, Rect, Stage as KonvaStage, Transformer } from 'react-konva';
import ObjectMenu from './ObjectMenu';
import PaneBox from './components/PaneBox';
import { Crop } from './types';

interface CanvasPaneProps extends React.HTMLAttributes<HTMLDivElement> {
  streamId?: string;
  streamDataNumber?: number;
  crops: Crop[];
  onImageSizeChange: (width: number, height: number) => void;
  onCropsChange: (crops: Crop[]) => void;
}
interface CanvasPaneState {
  stageWidth: number;
  stageHeight: number;
  stageScale: number;
  image?: Konva.Image;
  selectedCrop?: Crop;
}
/** クロップエディタのキャンバス部分です。 */
export default class CanvasPane extends React.PureComponent<CanvasPaneProps, CanvasPaneState> {
  container: HTMLDivElement | null | undefined;
  rand = new Date().getTime();
  constructor(props: CanvasPaneProps) {
    super(props);
    this.state = {
      stageWidth: 100,
      stageHeight: 100,
      stageScale: 1.0,
    };
  }
  componentDidMount() {
    this.checkSize();
    this.loadImage();
    // here we should add listener for "container" resize
    // take a look here https://developers.google.com/web/updates/2016/10/resizeobserver
    // for simplicity I will just listen window resize
    window.addEventListener('resize', this.checkSize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.checkSize);
  }
  private checkSize = () => {
    if (this.container) {
      const width = this.container.offsetWidth;
      const height = this.container.offsetHeight;
      this.setState({
        stageWidth: width,
        stageHeight: height,
        stageScale: this.calcScale(this.state.image, width, height),
      });
    }
  };
  private loadImage = () => {
    const url = this.props.streamDataNumber
      ? `/api/streams/${this.props.streamId}/data/${this.props.streamDataNumber}/thumbnail?stream_type=original&.rand=${this.rand}`
      : `/api/streams/${this.props.streamId}/thumbnail?.rand=${this.rand}`;
    Konva.Image.fromURL(url, (img: Konva.Image) => {
      this.setState({ image: img, stageScale: this.calcScale(img, this.state.stageWidth, this.state.stageHeight) });
      this.props.onImageSizeChange(img.width(), img.height());
    });
  };
  private calcScale = (img: Konva.Image | undefined, stageWidth: number, stageHeight: number) => {
    if (img === undefined) {
      return 1.0;
    } else {
      const scaleWidth = stageWidth / img.width();
      const scaleHeight = stageHeight / img.height();
      return Math.min(scaleWidth, scaleHeight, 1.0);
    }
  };
  render() {
    return (
      <PaneBox {...this.props}>
        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
          <div style={{ flexGrow: 1 }}>
            <div
              style={{ width: '100%', height: '100%' }}
              ref={(node) => {
                this.container = node;
              }}
            >
              <CanvasStage
                width={this.state.stageWidth}
                height={this.state.stageHeight}
                scale={this.state.stageScale}
                image={this.state.image}
                crops={this.props.crops}
                onCropsChange={this.props.onCropsChange}
              />
            </div>
          </div>
          <div style={{ color: '#c0c0c0' }}>
            scale: {this.state.stageScale} &nbsp; image: ({this.state.image?.width()} x {this.state.image?.height()})
            &nbsp; stage: ({this.state.stageWidth} x {this.state.stageHeight}) &nbsp;
          </div>
        </div>
      </PaneBox>
    );
  }
}

interface CanvasStageProps {
  width: number;
  height: number;
  scale: number;
  image?: Konva.Image;
  crops: Crop[];
  onCropsChange: (crops: Crop[]) => void;
}
interface CanvasStageState {
  selectedCrop?: Crop;
  objectMenuProps?: { crop: Crop; style: { top: number; left: number } };
}
/** キャンバスに描画される KonvaStage をコントロールします。 */
class CanvasStage extends React.PureComponent<CanvasStageProps, CanvasStageState> {
  private imageRef = React.createRef<Konva.Image>();
  constructor(props: CanvasStageProps) {
    super(props);
    this.state = {};
  }
  private handleMoved = (crop: Crop, x: number, y: number, width: number, height: number) => {
    const imageWidth = this.props.image?.width() || 1;
    const imageHeight = this.props.image?.height() || 1;
    const newCrops = [];
    for (const o of this.props.crops) {
      if (o === crop) {
        newCrops.push({
          ...o,
          bounds: {
            x1: x / imageWidth,
            y1: y / imageHeight,
            x2: (x + width) / imageWidth,
            y2: (y + height) / imageHeight,
          },
        });
      } else {
        newCrops.push(o);
      }
    }
    this.props.onCropsChange(newCrops);
  };
  private handleCheckDeselect = (evt: KonvaEventObject<MouseEvent>) => {
    if (evt.target === evt.target.getStage() || evt.target === this.imageRef.current) {
      this.setState({ selectedCrop: undefined });
    }
    this.setState({ objectMenuProps: undefined });
  };
  private handleSelect = (e: KonvaEventObject<MouseEvent>, crop: Crop) => {
    if (e.evt.button === 0) {
      this.setState({ selectedCrop: crop, objectMenuProps: undefined });
    } else if (e.evt.button === 2) {
      this.setState({
        objectMenuProps: {
          crop: crop,
          style: {
            top: e.evt.y + 4,
            left: e.evt.x + 4,
          },
        },
      });
    }
  };
  private handleItemDeleteClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, crop: Crop) => {
    const newCrops = [];
    for (const o of this.props.crops) {
      if (o !== crop) {
        newCrops.push(o);
      }
    }
    this.props.onCropsChange(newCrops);
    this.setState({ objectMenuProps: undefined });
  };
  render() {
    const imageWidth = this.props.image?.width() || 1;
    const imageHeight = this.props.image?.height() || 1;
    return (
      <>
        <KonvaStage
          width={this.props.width}
          height={this.props.height}
          scale={{ x: this.props.scale, y: this.props.scale }}
          onMouseDown={this.handleCheckDeselect}
          onContextMenu={(e) => e.evt.preventDefault()}
        >
          {/* 最下段にビデオ映像を描画 */}
          <KonvaLayer>
            {this.props.image && <KonvaImage ref={this.imageRef} image={this.props.image.image()} />}
          </KonvaLayer>
          <KonvaLayer>
            {this.props.crops.map((crop, i) => {
              return (
                <KonvaObject
                  key={i}
                  crop={crop}
                  imageWidth={imageWidth}
                  imageHeight={imageHeight}
                  isSelected={crop === this.state.selectedCrop}
                  onSelect={(e) => this.handleSelect(e, crop)}
                  onMoved={(x: number, y: number, width: number, height: number) =>
                    this.handleMoved(crop, x, y, width, height)
                  }
                />
              );
            })}
          </KonvaLayer>
        </KonvaStage>
        {this.state.objectMenuProps !== undefined && (
          <ObjectMenu
            {...this.state.objectMenuProps}
            style={{ position: 'absolute', ...this.state.objectMenuProps.style }}
            onCropDelete={(e, crop) => this.handleItemDeleteClick(e, crop)}
          />
        )}
      </>
    );
  }
}

const KonvaObject = (props: {
  crop: Crop;
  isSelected: boolean;
  imageWidth: number;
  imageHeight: number;
  onSelect: (e: KonvaEventObject<MouseEvent>) => void;
  onMoved: (x: number, y: number, width: number, height: number) => void;
}) => {
  const shapeRef: React.LegacyRef<RectRefType> = React.useRef(null);
  const trRef = React.useRef<Konva.Transformer>(null);
  React.useEffect(() => {
    if (props.isSelected) {
      // we need to attach transformer manually
      if (trRef.current && shapeRef.current) {
        trRef.current.nodes([shapeRef.current]);
        trRef.current.getLayer()?.batchDraw();
      }
    }
  }, [props.isSelected]);
  const handleDragEnd = (e: KonvaEventObject<DragEvent>) => {
    props.onMoved(e.target.x(), e.target.y(), e.target.width(), e.target.height());
  };
  const handleTransformEnd = (evt: Konva.KonvaEventObject<Event>) => {
    // transformer is changing scale of the node
    // and NOT its width or height
    // but in the store we have only width and height
    // to match the data better we will reset scale on transform end
    const node = evt.target;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    // we will reset it back
    node.scaleX(1);
    node.scaleY(1);
    props.onMoved(
      node.x(),
      node.y(),
      // set minimal value
      Math.max(5, node.width() * scaleX),
      Math.max(node.height() * scaleY),
    );
  };
  const baseProps: Konva.ShapeConfig = {
    draggable: true,
    x: props.crop.bounds.x1 * props.imageWidth,
    y: props.crop.bounds.y1 * props.imageHeight,
    onDragMove: () => {},
    onDragEnd: handleDragEnd,
    onClick: props.onSelect,
    onTransformEnd: handleTransformEnd,
  };
  const width = (props.crop.bounds.x2 - props.crop.bounds.x1) * props.imageWidth;
  const height = (props.crop.bounds.y2 - props.crop.bounds.y1) * props.imageHeight;
  const elm = <Rect {...baseProps} ref={shapeRef} width={width} height={height} stroke='#ffff00' strokeWidth={2} />;
  return (
    <>
      {elm}
      {props.isSelected && <Transformer rotateEnabled={false} ref={trRef} />}
    </>
  );
};
