import React, { useEffect, useState } from 'react';
import Spinner from 'shared/components/atoms/Spinner';
import { Content } from 'shared/components/molecules/ContentsArea';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableHeaderType } from 'shared/components/molecules/Table/type';
import { isSelected1 } from 'shared/utils/is';
import { dateToYMDHMS } from 'shared/utils/converter/date';
import { AndroidDevice } from 'user/api/androidDevices';
import AndroidDeviceNotifyButton from './AndroidDeviceNotifyButton';

// -- type declaration --

// デバイステーブルのtype
type TableAndroidDeviceType = {
  id: string;
  android_device_name: string;
  android_device_status: string;
  android_device_appid: string;
  android_device_os_ver: string;
  android_device_app_ver: string;
  android_device_updated_at: string;
  created_at: string;
  detail_element: JSX.Element;
};

// テーブルの列の情報まとめたデータ
const headers: TableHeaderType[] = [
  {
    id: 'id',
    label: 'デバイスID',
    width: '100px',
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'android_device_name',
    label: 'デバイス名',
    width: '17%',
    sortable: true,
    search_props: {
      type: 'name',
      default_display: true,
    },
  },
  {
    id: 'android_device_status',
    label: '状態',
    width: 'auto',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'android_device_appid',
    label: 'アプリID',
    width: 'auto',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'android_device_os_ver',
    label: 'OS Ver',
    width: 'auto',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'android_device_app_ver',
    label: 'アプリVer',
    width: 'auto',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'android_device_updated_at',
    label: '最終更新日時',
    width: 'auto',
    sortable: true,
    search_props: {
      type: 'datetime',
    },
  },
  {
    id: 'created_at',
    label: '登録日時',
    width: 'auto',
    sortable: true,
    search_props: {
      type: 'datetime',
    },
  },
  {
    id: 'detail_element',
    label: '',
    useTableButtonStyles: true,
  },
];

export interface AndroidDevicesTableProps {
  android_devices: AndroidDevice[];
  onDelete: (androidDevice: AndroidDevice) => void;
}

// -- main component --
/**
 * Androidデバイス一覧の表示テーブルです。
 */
const AndroidDevicesTable: React.FC<AndroidDevicesTableProps> = (params) => {
  // -- local states --
  const [table_bodies, setTableBodies] = useState<TableAndroidDeviceType[] | undefined>(undefined);
  const [selected_bodies, setSelectedBodies] = useState<TableAndroidDeviceType[]>([]);

  // -- handlers --
  const handleCheckClick = (datas: TableAndroidDeviceType[]) => {
    setSelectedBodies(datas);
  };
  const handleDeleteClick = () => {
    const android_device_id: string = selected_bodies[0].id;
    params.android_devices.forEach((androidDevice) => {
      if (androidDevice.android_device_id === android_device_id) {
        params.onDelete(androidDevice);
      }
    });
  };
  const getDatas = (devices: AndroidDevice[]) => {
    const return_table_datas: TableAndroidDeviceType[] = [];
    for (let i = 0; i < devices.length; i++) {
      const return_top_table_data: TableAndroidDeviceType = {
        id: devices[i].android_device_id,
        android_device_name: devices[i].android_device_name || '',
        android_device_status: devices[i].android_device_status || '',
        android_device_appid: devices[i].android_device_appid || '',
        android_device_os_ver: devices[i].android_device_os_ver || '',
        android_device_app_ver: devices[i].android_device_app_ver || '',
        android_device_updated_at: dateToYMDHMS(devices[i].android_device_updated_at || ''),
        created_at: dateToYMDHMS(devices[i].created_at),
        detail_element: <AndroidDeviceNotifyButton android_device={devices[i]} />,
      };

      return_table_datas.push(return_top_table_data);
    }
    setTableBodies(return_table_datas);
  };

  // -- onload function --
  useEffect(() => {
    getDatas(params.android_devices);
  }, [params.android_devices]);

  // -- render part --
  return (
    <Content>
      {table_bodies === undefined ? (
        <Spinner />
      ) : (
        <BaseTable
          bodies={table_bodies}
          headers={headers}
          table_name='android_devices'
          selected_bodies={selected_bodies}
          handleCheckClick={handleCheckClick}
          footer_option={{
            text: '＞チェックしたAndroidデバイスを削除する',
            handleClick: handleDeleteClick,
            disabled: !isSelected1(selected_bodies.length),
          }}
        />
      )}
    </Content>
  );
};

// -- styled components --

// -- finally export part --

export default AndroidDevicesTable;
