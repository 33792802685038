/**
 * イベント一覧をダウンロードするためのダイアログとダウンロード処理です
 */

import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import PfDialog from "shared/components/atoms/PfDialog";
import PfProgressBar from "shared/components/atoms/PfProgressBar";
import RoundedButton from "shared/components/atoms/RoundedButton";
import { Content, Footer } from "shared/components/molecules/ContentsArea";
import InputComponent from "shared/components/molecules/InputComponent";
import InputDoubleBox from "shared/components/molecules/InputDoubleBox";
import { Search } from "shared/models/Search"
import { dateToYMDHMS } from "shared/utils/converter/date";
import { isDateWithinRange } from "shared/utils/is";
import { Channel } from "user/api/channels";
import { ChannelEvent, ChannelEventsWithPaging, channelsIdEventsGetAPI } from "user/api/channelsEvents";
import { eventsGetAPI } from 'user/api/events';

type Param = {
  channel_id?: string;
  channels_record?: Record<string, Channel>;
  searches: Search[];
  onClose: () => void; // 閉じるアクション
}
export const EventsDownloadDialog = (params: Param) => {
  const [value, setValue] = useState<string>('');
  const [value2, setValue2] = useState<string>('');
  const [progress, setProgress] = useState<number>(-1); // 進捗率 (-1 は非表示)
  const handleDownload = async () => {
    // 処理開始
    setProgress(0);
    // Date型に変換
    // const dt_date = new Date(dt);
    const start_date = new Date(value);
    const end_date = new Date(value2);
    // 対象データの取得
    const bodies: string[] = [];
    bodies.push([
      'チャンネルID',
      'チャンネルイベント番号',
      '発生日時',
      'チャンネル名',
      'チャンネルイベント名',
      '確認',
      '確認者',
      'コメント',
      '追加情報'
    ].join(','))
    if (params.channel_id) {
      // チャンネルIDがある場合
      let esk: number | undefined = undefined;
      for (;;) {
        const res: AxiosResponse<ChannelEventsWithPaging, any> = await channelsIdEventsGetAPI({
          channel_id: params.channel_id,
          exclusive_start_channel_event_number: esk,
        });
        let lastTime = 0;
        if (res.status === 200) {
          for (const val of res.data.items) {
            const dt_date = new Date(val.created_at);
            lastTime = dt_date.getTime();
            if (end_date.getTime() < dt_date.getTime()) {
              // 強制的に検索を終了
              res.data.esk = null;
              break;
            }
            if (!isDateWithinRange({dt: val.created_at, start: value, end: value2})) {
              continue;
            }
            bodies.push([
              val.channel_id,
              val.channel_event_number,
              dateToYMDHMS(val.created_at),
              params.channels_record?.[val.channel_id]?.channel_name || '',
              val.channel_event_name,
              val.confirm ? '確認済み' : '未確認',
              val.confirm?.user_name,
              val.latest_comment?.comment,
              getAdditionalInfo(val)
            ].join(','))
          }
          if (res.data.esk) {
            esk = res.data.esk;
          } else {
            break;
          }
        } else {
            break;
        }
        setProgress((lastTime - start_date.getTime()) / (end_date.getTime() - start_date.getTime()));
      }
    } else {
      // チャンネルIDがない場合
      let esk: number | undefined = Math.floor(start_date.getTime() / 1000);
        let lastTime = 0;
        for (;;) {
        const res: AxiosResponse<ChannelEventsWithPaging, any> = await eventsGetAPI({
          exclusive_start_created_at: esk,
        });
        if (res.status === 200) {
          for (const val of res.data.items) {
            const dt_date = new Date(val.created_at);
            lastTime = dt_date.getTime();
            if (end_date.getTime() < dt_date.getTime()) {
              // 強制的に検索を終了
              res.data.esk = null;
              break;
            }
            bodies.push([
              val.channel_id,
              val.channel_event_number,
              dateToYMDHMS(val.created_at),
              params.channels_record?.[val.channel_id]?.channel_name || '',
              val.channel_event_name,
              val.confirm ? '確認済み' : '未確認',
              val.confirm?.user_name,
              val.latest_comment?.comment,
              getAdditionalInfo(val)
            ].join(','))
          }
          if (res.data.esk) {
            esk = res.data.esk;
          } else {
            break;
          }
        } else {
            break;
        }
        setProgress((lastTime - start_date.getTime()) / (end_date.getTime() - start_date.getTime()));
      }
    }
    // プログレスバーを 100% に更新
    setProgress(1);

    // 取得した2次元配列をcsv形式に成型
    const csv_data = bodies.join('\r\n');
    // ダウンロード
    // BOMの設定
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, csv_data], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a')
    link.href = url
    link.download = 'events.csv'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
    // ダイアログの終了
    // （プログレスバーの100%を表示するため1秒ウェイトして閉じる）
    setTimeout(() => params.onClose(), 1000);
  }
  useEffect(() => {
    for (const search of params.searches) {
      if (search.search_key === 'created_at') {
        setValue(search.value);
        setValue2(search.value2 || '');
      }
    }
  }, []); /* eslint-disable-line */
  return (
    <PfDialog
        title='ダウンロード'
        isOpen={true}
        onClose={params.onClose}>
      <Content>
        <InputComponent text='発生日時' required>
          <InputDoubleBox
            value1={value}
            value2={value2}
            handleChangeClick1={e => setValue(e.currentTarget.value)}
            handleChangeClick2={e => setValue2(e.currentTarget.value)}
            types={['datetime-local', 'datetime-local']}
          />
        </InputComponent>
        {progress !== -1 &&
          <div style={{marginBottom: "10px"}}><PfProgressBar value={progress} width="100%" /></div>
        }
        <Footer>
          <RoundedButton
            onClick={handleDownload}
            text_type='CREATE'
            disabled={Boolean(value === '' || value2 === '') || progress !== -1}
          />
        </Footer>
      </Content>
    </PfDialog>
  );
}

function getAdditionalInfo(val: ChannelEvent) {
  if (val.stayer_area) {
    return `"エリア: ${val.stayer_area}, 滞留時間: ${val.stayer_time}秒, クラス:${val.stayer_label}"`;
  }
  return '';
}
