import { Dashboard } from 'user/api/dashboards';
import { dashboardsIdWidgetsGetAPI, dashboardsIdWidgetsNumPutAPI, DashboardWidget } from 'user/api/dashboardsWidgets';
import { CachedStreams } from 'user/api/streams';
import Spinner from 'shared/components/atoms/Spinner';
import React from 'react';
import DashboardWidgetCard from './DashboardWidgetCard';
import { RouteComponentProps } from 'react-router';
import { ReactSortable } from 'react-sortablejs';
import { WidgetScaleType } from 'shared/models/WidgetScaleType';
import { DateString } from 'shared/models/DateString';
import { TimeString } from 'shared/models/TimeString';

type DashboardWidgetsViewProps = RouteComponentProps<{
  dashboard_id: string;
  panel_id?: string;
}> & {
  dashboard: Dashboard;
  metricStreams: CachedStreams;
  playerStreams: CachedStreams;
  autoReloadInterval: number; // ミリ秒単位、-1 は無効
  scale?: WidgetScaleType | null;
  start_date?: DateString | null;
  end_date?: DateString | null;
  start_time?: TimeString | null;
  end_time?: TimeString | null;
  handleUpdateDlCsvList: (dlName: string, dlUrl: string) => void;
};
interface DashboardWidgetsViewState {
  widgets?: DashboardWidget[];
  sortable_widgets: ({ id: string } & DashboardWidget)[];
}

// ReactSortableに代入するlistにはidが必要なのでそれ用の型
interface WidgetList extends DashboardWidget {
  id: number;
}
/**
 * ウィジェットカードが並ぶビューです。
 * ・ウィジェットカードを並べる
 */
export default class DashboardWidgetsView extends React.PureComponent<
  DashboardWidgetsViewProps,
  DashboardWidgetsViewState
> {
  private intervalId: ReturnType<typeof setInterval> | undefined;
  private widgetRefs: React.RefObject<DashboardWidgetCard>[] = [];
  constructor(props: DashboardWidgetsViewProps) {
    super(props);
    this.state = {
      sortable_widgets: [],
    };
  }
  componentDidMount() {
    this.reload();
    this.setupTimer();
  }
  componentDidUpdate(prevProps: DashboardWidgetsViewProps) {
    // ダッシュボードが変更された場合、リロードします。
    if (this.props.dashboard.updated_at !== prevProps.dashboard.updated_at) {
      this.reload();
    }
    // 自動リロードの間隔が変更された場合、タイマーを再セットアップします。
    if (this.props.autoReloadInterval !== prevProps.autoReloadInterval) {
      this.setupTimer();
    }
  }
  componentWillUnmount() {
    this.teardownTimer();
  }
  private reload() {
    dashboardsIdWidgetsGetAPI({
      dashboard_id: this.props.dashboard.dashboard_id,
      ordered: 'True',
    }).then((res) => {
      if (res.status === 200) {
        this.setState({ widgets: res.data.items });
      }
    });
  }
  private setupTimer = () => {
    // 事前に動いていた場合はクリアー
    this.teardownTimer();
    // タイマーのセットアップ
    if (this.props.autoReloadInterval !== -1) {
      this.intervalId = setInterval(() => {
        for (const r of this.widgetRefs) {
          r.current?.handleReloadWidget();
        }
      }, this.props.autoReloadInterval);
    }
  };
  private teardownTimer = () => {
    if (this.intervalId !== undefined) {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
    }
  };
  // ReactSortableに入れるデータにはidが存在していないといけないので、idを加える
  private get getList(): WidgetList[] {
    const { widgets } = this.state;

    if (!widgets) return [];
    return widgets.map((d) => {
      return {
        ...d,
        id: d.dashboard_widget_number,
      };
    });
  }
  // 逆にReactSortableから帰ってくるデータのidがいらないので、消して更新する
  private setList = async (new_widgets: WidgetList[]) => {
    // 現在のwidgetsと新しいwidgetsを比較し、変更がある場合はtrueとする
    let changed_flag = false;
    const { widgets } = this.state;
    const widgets_length = widgets?.length ?? 0;
    const new_widgets_length = new_widgets.length;
    const nw: DashboardWidget[] = new_widgets.map((nw, i) => {
      // 両者の配列の長さが一致しているかつwidgetsが存在時のみ評価
      if (widgets_length === new_widgets_length && widgets) {
        // dashboard_numberを比較し変化が1つでもあればchanged_flagはtrue
        changed_flag = changed_flag || nw.dashboard_widget_number !== widgets[i].dashboard_widget_number;
      } else {
        // 長さが違う場合やwidgetsが存在しない場合は、必ず変更されるので問答無用でtrue
        changed_flag = true;
      }
      // nwのidを削除したいけど、deleteは使いたくないから以下のように指定する。
      const { id: _removed, ...res } = nw;
      // idのないobjectを返す
      return res;
    });
    // 変更されていない場合はstateを更新しない
    if (!changed_flag) return;
    this.setState({
      widgets: nw,
    });
  };
  // Sortが完了した時に、Widgetのorder情報を更新する
  private onSort = async () => {
    const nw = this.state.widgets;
    if (!nw) return;
    // 新しいデータの順番通りにorderをPUTする
    for (let i = 0; i < nw.length; i++) {
      const widget = nw[i];
      // orderが変わっていないデータは更新しない(無駄な通信を控える)
      if (nw[i].order === i) continue;
      // order以外は変更がないように指定する。
      const res = await dashboardsIdWidgetsNumPutAPI({
        ...widget,
        dashboard_widget_number: String(widget.dashboard_widget_number),
        metrics: widget.metrics || [],
        order: String(i),
      });
      if (res.status !== 200) break;
    }
    // 変更が完了したらreloadする
    this.reload();
  };
  render() {
    if (this.state.widgets) {
      this.widgetRefs.length = this.state.widgets.length;
      for (let i = 0; i < this.widgetRefs.length; i++) {
        if (this.widgetRefs[i] === undefined) {
          this.widgetRefs[i] = React.createRef<DashboardWidgetCard>();
        }
      }
    } else {
      this.widgetRefs.length = 0;
    }
    return (
      <div style={{ width: '100%' }}>
        {this.state.widgets ? (
          <ReactSortable
            list={this.getList} // idという属性が追加で必要
            setList={this.setList}
            onSort={this.onSort}
            handle='.DragHandle'
            style={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {this.state.widgets.map((widget, index) => (
              <DashboardWidgetCard
                {...this.props}
                ref={this.widgetRefs[index]}
                key={widget.dashboard_widget_number}
                widget={widget}
                handleUpdateDlCsvList={this.props.handleUpdateDlCsvList}
                onDeleted={() => this.reload()}
                onWidgetChanged={() => this.reload()}
                metricStreams={this.props.metricStreams}
                playerStreams={this.props.playerStreams}
              />
            ))}
          </ReactSortable>
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}
