import React from 'react';
import DateRangeWidgetBase from './base/DateRangeWidgetBase';
import StreetMap from 'shared/components/molecules/StreetMap';
import { WidgetProps } from './getWidgetType';

interface MapWidgetState {}
/**
 * マップ描画ウィジェットです。
 */
export default class MapWidget extends React.PureComponent<WidgetProps, MapWidgetState> {
  constructor(props: WidgetProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <DateRangeWidgetBase
        widget={this.props.widget}
        state={this.props.state}
        onInitMetrics={() => {}}
        onStateChanged={this.props.onStateChanged}
        onMetricsChanged={() => {}}
        onScaleChanged={() => {}}
        hide_scale={true}
      >
        <div style={{ width: '100%', height: '100%' }}>
          <StreetMap street_map_datas={[]} />
        </div>
      </DateRangeWidgetBase>
    );
  }
}
