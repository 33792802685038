import { SizeWH } from 'shared/models';
import { Rect } from 'shared/models/Rect';

/** Rectがsizeの範囲内の時はtrue それ以外はfalse **/
export const checkRangeTrimRect = (p: { rect: Rect; size: SizeWH }) => {
  if (p.rect.top < 0) {
    return false;
  }
  if (p.rect.right > p.size.width) {
    return false;
  }
  if (p.rect.bottom > p.size.height) {
    return false;
  }
  if (p.rect.left < 0) {
    return false;
  }
  return true;
};
