import React from 'react';
import { IconNames } from '@blueprintjs/icons';
import PfHlsPlayer from 'shared/components/atoms/PfHlsPlayer';
import { streamsStreamIdVideoM3U8GetAPIUrl } from 'user/api/streams';
import { streamsIdDataNumberM3u8Url } from 'user/api/streamsData';
import PaneBox from './components/PaneBox';

interface LivePreviewPaneProps extends React.HTMLAttributes<HTMLDivElement> {
  streamId?: string;
  streamDataNumber?: number;
  editorTempId?: string;
}
interface LivePreviewPaneState {
  refreshCount: number;
}
export default class LivePreviewPane extends React.PureComponent<LivePreviewPaneProps, LivePreviewPaneState> {
  constructor(props: LivePreviewPaneProps) {
    super(props);
    this.state = {
      refreshCount: 0,
    };
  }
  private handleRefreshClick = () => {
    this.setState({ refreshCount: this.state.refreshCount + 1 });
  };
  render() {
    return (
      <PaneBox
        {...this.props}
        paneTitle='Live Preview'
        paneTitleIcons={[{ name: 'refresh', icon: IconNames.REFRESH, onClick: this.handleRefreshClick }]}
      >
        {this.props.streamId ? (
          this.props.streamDataNumber ? (
            <PfHlsPlayer
              key={this.state.refreshCount}
              width='256'
              height='144'
              controls={true}
              url={
                streamsIdDataNumberM3u8Url(this.props.streamId, this.props.streamDataNumber) +
                `?et=${this.props.editorTempId || ''}`
              }
            />
          ) : (
            <PfHlsPlayer
              key={this.state.refreshCount}
              width='256'
              height='144'
              controls={true}
              url={
                streamsStreamIdVideoM3U8GetAPIUrl({
                  stream_id: this.props.streamId,
                  start: Math.floor(new Date().getTime() / 1000) - 60,
                }) + `&et=${this.props.editorTempId || ''}`
              }
            />
          )
        ) : (
          <div
            style={{ width: '256px', height: '144px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            ビデオデータがありません。
          </div>
        )}
      </PaneBox>
    );
  }
}
