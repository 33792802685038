// -- basic library --
import React, { useCallback, useEffect, useState } from 'react';
import history from 'shared/browserHistory';
import Spinner from 'shared/components/atoms/Spinner';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { PageWholeArea, Title, TopArea, BottomArea } from 'shared/components/molecules/ContentsArea';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableBodyType, TableHeaderType, TableBodyClickType } from 'shared/components/molecules/Table/type';
import { isNotSelected } from 'shared/utils/is';
import { CachedStreamPackagesFavorite } from 'user/api/streamPackagesFavorite';
import { RequestStreamsIdPackagesNumberPut, streamsIdPackagesNumPutAPI } from 'user/api/streamsPackage';
import DetailStreamPackageDialog from 'user/dialogs/DetailStreamPackageDialog';
import { useStreams } from 'user/hooks/useStreams/useStreams';
import { preloadGroup } from 'user/utils/preloadGroup';

export interface TableStreamPackageFavoriteType extends TableBodyType {
  id: string;
  stream_package_name: TableBodyClickType;
  stream_name: string;
  stream_id: string;
  stream_package_number: string;
}

// テーブルの列の情報まとめたデータ
const headers: TableHeaderType[] = [
  {
    id: 'stream_package_name',
    label: 'ストリームパッケージ名',
    sortable: true,
    search_props: {
      type: 'name',
      default_display: true,
    },
  },
  {
    id: 'stream_name',
    label: 'データ名',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
];

// -- main component --
const StreamsPackagesFavorite: React.FC = () => {
  // -- local states --
  const [table_bodies, setTableBodies] = useState<TableStreamPackageFavoriteType[] | undefined>(undefined);
  const [selected_bodies, setSelectedBodies] = useState<TableStreamPackageFavoriteType[]>([]);
  const { streams_record } = useStreams({ with_output_streams: 'True', with_in_channel_streams: 'True' });

  // 詳細ダイアログ用のstate
  const [details, setDetails] = useState<
    | {
        stream_id: string;
        stream_package_number: string;
        is_open: boolean;
      }
    | undefined
  >(undefined);

  const loadTableBodies = useCallback(async () => {
    new CachedStreamPackagesFavorite({ status: 'ACTIVE' }).get().then((datas) => {
      const tbs: TableStreamPackageFavoriteType[] = [];
      datas.forEach((data) => {
        const stream = streams_record[data.stream_id];
        tbs.push({
          id: `${data.stream_id}:${data.stream_package_number}`,
          stream_package_name: {
            value: data.stream_package_name,
            onClick: () => handleDetailClick(data.stream_id, String(data.stream_package_number)),
          },
          stream_name: stream?.stream_name || '',
          stream_id: data.stream_id,
          stream_package_number: String(data.stream_package_number),
        });
      });
      setTableBodies(tbs);
    });
  }, [streams_record]);

  // -- handlers --
  const handleDetailClick = (stream_id: string, stream_package_number: string) => {
    setDetails({
      stream_id,
      stream_package_number,
      is_open: true,
    });
  };

  const onCloseDetail = async () => {
    setDetails(undefined);
    await loadTableBodies();
  };

  const handleCheckClick = (datas: TableStreamPackageFavoriteType[]) => {
    setSelectedBodies(datas);
  };

  const handleOKClick = async (selected_bodies: TableStreamPackageFavoriteType[]) => {
    for (let i = 0; i < selected_bodies.length; i++) {
      const body = selected_bodies[i];
      const request_bodies: RequestStreamsIdPackagesNumberPut = {
        stream_id: body.stream_id,
        stream_package_number: body.stream_package_number,
        stream_package_name: body.stream_package_name.value,
        favorite: 'False', // お気に入りを解除する
      };
      await streamsIdPackagesNumPutAPI(request_bodies);
    }
    setSelectedBodies([]);
    await loadTableBodies();
  };

  const handleCancelClick = () => {
    history.push('/streams-packages-favorite');
  };

  const handleUnfavoriteClick = async () => {
    ConfirmDialog.show(
      '[確認]\n選択されているストリームパッケージのお気に入りを解除します。\n本当によろしいですか？',
      () => handleOKClick(selected_bodies),
      handleCancelClick,
      undefined,
    );
  };

  // -- onload function --
  useEffect(() => {
    preloadGroup(loadTableBodies());
  }, [loadTableBodies]);

  // -- render part --
  return (
    <PageWholeArea data-testid='Stream'>
      <TopArea>
        <Title text='ストリームパッケージお気に入り一覧' />
      </TopArea>
      <BottomArea>
        {table_bodies !== undefined ? (
          <BaseTable
            headers={headers}
            table_name='streams-packages-favorite'
            bodies={table_bodies}
            selected_bodies={selected_bodies}
            handleCheckClick={handleCheckClick}
            footer_option={{
              text: '＞チェックしたストリームパッケージのお気に入り解除',
              handleClick: handleUnfavoriteClick,
              disabled: isNotSelected(selected_bodies.length),
            }}
          />
        ) : (
          <Spinner />
        )}
        {details && details.is_open && streams_record[details.stream_id] && (
          <DetailStreamPackageDialog
            stream={streams_record[details.stream_id]}
            stream_package_number={details.stream_package_number}
            isOpen={details.is_open}
            is_hide_copy_button
            onClose={onCloseDetail}
            onCopyCreate={() => {}}
          />
        )}
      </BottomArea>
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default StreamsPackagesFavorite;
