import React from 'react';
import { DashboardHeatmapMetric, DashboardWidget } from 'user/api/dashboardsWidgets';
import FixedDateScaleSettingDialog from 'user/dialogs/FixedDateScaleSettingDialog';
import { getDateScaleLabel } from 'shared/utils/get';
import { TopButtonArea } from 'shared/components/atoms/ContentsArea';
import { WidgetState } from 'user/pages/Dashboards/DetailPage/UsualDashboardWidgets/DashboardWidgetCard';
import useDateRangeWidgetBase3 from './useDateRangeWidgetBase3';
import { GraphArea } from './GraphArea';

interface DateRangeWidgetBase3Props {
  widget: DashboardWidget;
  state: WidgetState;
  underAreaControl?: JSX.Element;
  underAreaControlDirection?: 'row' | 'column';
  onInitMetrics: () => void;
  onStateChanged: (state: WidgetState) => void;
  onMetricsChanged: (metrics: DashboardHeatmapMetric[]) => void;
  children?: React.ReactNode;
}
/**
 * グラフ描画ウィジェットです。
 *  - ヒートマップ用の新しいメトリクス取得APIをコールします。
 */
export default function DateRangeWidgetBase3({
  children,
  widget,
  state,
  onMetricsChanged,
  onInitMetrics,
  onStateChanged,
  underAreaControl,
  underAreaControlDirection,
}: DateRangeWidgetBase3Props) {
  const hide_scale = true;
  // 必要なhooksや関数を取得
  const { date_dialog_open, onDateDialogOpen, handleDateUpdated } = useDateRangeWidgetBase3({
    state,
    widget,
    onMetricsChanged,
    onInitMetrics,
    onStateChanged,
  });

  const { start_date, end_date, start_time, end_time, fixed } = state;
  const direction = underAreaControlDirection || 'row';
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <TopButtonArea>
        <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={onDateDialogOpen}>
          {getDateScaleLabel({
            start_date,
            end_date,
            start_time,
            end_time,
            scale: !hide_scale ? state.scale : undefined,
            fixed,
          })}
        </span>
      </TopButtonArea>
      <GraphArea>{children}</GraphArea>
      <div style={{ width: '100%', display: 'flex', flexDirection: direction }}>{underAreaControl}</div>
      {date_dialog_open && (
        <FixedDateScaleSettingDialog
          onClose={handleDateUpdated}
          widget={widget}
          hide_scale={hide_scale}
          defaults={{
            start_date,
            end_date,
            start_time,
            end_time,
          }}
        />
      )}
    </div>
  );
}
