// -- basic library --
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import history from 'shared/browserHistory';
import RoundedAnchorButton from 'shared/components/atoms/RoundedAnchorButton';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { BottomArea, Content, Title, TopArea } from 'shared/components/molecules/ContentsArea';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableHeaderType } from 'shared/components/molecules/Table/type';
import { table_cell_button_style } from 'shared/styles/styles';
import { isNotSelected } from 'shared/utils/is';
import { dateToYMDHMS } from 'shared/utils/converter/date';
import { toFormatBytes } from 'shared/utils/converter';
import { getProgress } from 'shared/utils/get';
import styled from 'styled-components';
import {
  streamsIdPackagesNumberDeleteAPI,
  StreamPackage,
  getStreamsIdPackagesNumberDownloadGetAPIUrl,
  streamsIdPackagesGetAPI,
} from 'user/api/streamsPackage';
import CreateStreamDataDownloadPackagesDialog from 'user/pages/Streams/DetailPage/StreamDataDownloadPackagesPanel/CreateStreamDataDownloadPackagesDialog';
import DetailStreamPackageDialog from 'user/dialogs/DetailStreamPackageDialog';
import { authSelector } from 'user/redux/slices/authSlice';
import { loadWrapperFunc } from 'user/utils/loadWrapperFunc';
import { Stream } from 'user/api/streams';
import stream from 'stream';
import getDlpkgLabel from 'user/utils/getDlpkgLabel';

// テーブルの列の情報まとめたデータ
const headers: TableHeaderType[] = [
  {
    id: 'id',
    label: 'ID',
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'download_name',
    label: 'ダウンロード名',
    search_props: {
      type: 'name',
      default_display: true,
    },
  },
  {
    id: 'progress',
    label: '進捗',
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'size',
    label: 'データサイズ',
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'create_date',
    label: '作成日',
    search_props: {
      type: 'datetime',
    },
  },
  {
    id: 'button_element',
    label: '',
    useDoubleTableButtonStyles: true,
  },
];

// -- type declaration --

type Params = RouteComponentProps<{ stream_id: string }>;

export type TableStreamDataDownloadPackageType = {
  id: string;
  download_name: string;
  progress: string;
  size: string;
  create_date: string;
  button_element: JSX.Element;
};

// -- external functions --

// -- main component --
const StreamDataDownloadPackagesPanel: React.FC<Params & {stream: Stream}> = (params) => {
  const auth_state = useSelector(authSelector);
  const table_massege = auth_state.is_admin ? '＞チェックした項目を削除する' : '＞項目削除は管理者のみできます';

  // -- local states --
  const [table_bodies, setTableBodies] = useState<TableStreamDataDownloadPackageType[] | undefined>(undefined);
  const [selected_bodies, setSelectedBodies] = useState<TableStreamDataDownloadPackageType[]>([]);
  const [exclusive_stream_package_number, setExclusiveStreamPackageNumber] = useState<number | undefined>(undefined);
  const [hasNext, setHasNext] = useState<boolean>(false);
  // const { stream } = useStream({ stream_id: params.match.params.stream_id });

  // 作成用states
  const [is_create_open, setIsCreateOpen] = useState<boolean>(false);

  // 詳細(更新)用states
  const [is_set_open, setIsSetOpen] = useState<boolean>(false);
  const [stream_package_number, setStreamPackageNumber] = useState<string | undefined>(undefined);
  const [jsonpath_group_id] = useState<string>('');

  const query = new URLSearchParams(useLocation().search);

  // -- handlers --
  const handleCheckClick = (datas: TableStreamDataDownloadPackageType[]) => {
    setSelectedBodies(datas);
  };

  // 作成用画面関数
  const onCreateOpen = () => {
    setIsCreateOpen(true);
  };

  const onCreateClose = async () => {
    setIsCreateOpen(false);
    setStreamPackageNumber(undefined);
    await loadData();
  };

  // 詳細(更新)用画面関数
  const onSetOpen = () => {
    setIsSetOpen(true);
  };

  const onSetClose = async () => {
    setIsSetOpen(false);
    setStreamPackageNumber(undefined);
    await loadData();
  };

  const onCopyCreate = () => {
    setIsSetOpen(false);
    setIsCreateOpen(true);
  };

  // 詳細ボタンをクリックした時
  const onDetailClick = (stream_package_number: string) => {
    setStreamPackageNumber(stream_package_number);
    onSetOpen();
  };

  const loadData = async () => {
    addEvents(true);
  };

  const toTableDataByStreamPackage = (stream_id: string, items: StreamPackage[]) => {
    const return_table_datas: TableStreamDataDownloadPackageType[] = [];
    for (let i = 0; i < items.length; i++) {
      const return_table_data: TableStreamDataDownloadPackageType = {
        id: String(items[i].stream_package_number),
        download_name: items[i].stream_package_name,
        progress: getProgress(items[i].packaging_progress, items[i].packaging_done),
        size: toFormatBytes(items[i].package_size, 2),
        create_date: dateToYMDHMS(items[i].created_at),
        button_element: (
          <ButtonElementsArea>
            <RoundedButton
              text_type='DETAIL'
              onClick={() => onDetailClick(String(items[i].stream_package_number))}
              style={{
                ...table_cell_button_style,
              }}
              stop_propagation
            />
            {items[i].packaging_done && items[i].packaging_progress === 100 ? (
              <RoundedAnchorButton
                text='ダウンロード'
                href={getStreamsIdPackagesNumberDownloadGetAPIUrl({
                  stream_id: stream_id,
                  stream_package_number: items[i].stream_package_number,
                })}
                style={{
                  fontSize: '0.8em',
                  ...table_cell_button_style,
                }}
              />
            ) : (
              // ダウンロードボタンの無効時はボタン要素で描画
              <RoundedButton
                text='ダウンロード'
                style={{
                  fontSize: '0.8em',
                  ...table_cell_button_style,
                }}
                stop_propagation
                disabled={true}
              />
            )}
          </ButtonElementsArea>
        ),
      };
      return_table_datas.push(return_table_data);
    }
    return return_table_datas;
  }

  // ページング時の追加レコードの読込
  // reset=true にすると最初から取りなおします。
  const addEvents = async (reset?: boolean) => {
    const esk = reset ? undefined : exclusive_stream_package_number;
    const pre_table_bodies = reset ? [] : table_bodies || [];
    // APIコール
    const stream_id = params.match.params.stream_id;
    const res = await streamsIdPackagesGetAPI({stream_id: stream_id, exclusive_start_key: esk});
    if (res.status === 200) {
      const ret = res.data;
      const table_datas = toTableDataByStreamPackage(stream_id, ret.items);
      setTableBodies([...pre_table_bodies, ...table_datas]);
      setExclusiveStreamPackageNumber(ret.last_stream_package_number);
      setHasNext(ret.has_next);
    }
  }

  const handleOKClick = async (stream_id: string) => {
    // 削除関数定義
    const deletes = async () => {
      // 削除
      await Promise.all(
        selected_bodies.map((sd) =>
          streamsIdPackagesNumberDeleteAPI({
            stream_id,
            stream_package_number: sd.id,
          }),
        ),
      );
      // 取得
      await loadData();
      setSelectedBodies([]);
    };
    // 実行
    await loadWrapperFunc(deletes);
  };

  const isUserType = () => !auth_state.is_admin;

  const handleCancelClick = (stream_id: string) => {
    history.push(`/streams/${stream_id}/data_download_packages`);
  };

  const handleDeleteClick = (stream_id: string) => {
    ConfirmDialog.show(
      <div>
        [確認]選択されているデータを削除します。
        <br />
        削除して本当によろしいですか?
      </div>,
      () => handleOKClick(stream_id),
      () => handleCancelClick(stream_id),
      undefined,
    );
  };

  // -- onload function --
  useEffect(() => {
    (async function () {
      // プロセス情報の取得
      await loadData();
    })();
    // 最初に作成ダイアログを表示するかどうか
    if (query.get('is_create_open') === 'true') {
      setIsCreateOpen(true);
    }
  }, []); /* eslint-disable-line */

  // -- render part --
  return (
    <Content>
      <TopArea>
        <Title text='' />
        <RoundedButton text={ getDlpkgLabel(params.stream) + '作成'} onClick={onCreateOpen} style={{ width: 300 }} />
      </TopArea>
      <BottomArea>
        {table_bodies !== undefined ? (
          <BaseTable
            bodies={table_bodies}
            headers={headers}
            table_name='streamsDownloadPackages'
            selected_bodies={selected_bodies}
            handleCheckClick={handleCheckClick}
            handleAddDatas={addEvents}
            has_next={hasNext}
            footer_option={{
              text: table_massege,
              handleClick: () => handleDeleteClick(params.match.params.stream_id),
              disabled: isNotSelected(selected_bodies.length) || isUserType(),
            }}
          />
        ) : (
          <Spinner />
        )}
        {is_create_open && params.stream && (
          <CreateStreamDataDownloadPackagesDialog
            stream={params.stream}
            stream_package_number={stream_package_number}
            jsonpath_group_id={jsonpath_group_id}
            isOpen={is_create_open}
            onClose={onCreateClose}
          />
        )}
        {is_set_open && stream && stream_package_number !== undefined && (
          <DetailStreamPackageDialog
            stream={params.stream}
            stream_package_number={stream_package_number}
            isOpen={is_set_open}
            onClose={onSetClose}
            onCopyCreate={onCopyCreate}
          />
        )}
      </BottomArea>
    </Content>
  );
};

// -- styled components --
const ButtonElementsArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

// -- finally export part --
export default StreamDataDownloadPackagesPanel;
