import RoundedAnchorButton from 'shared/components/atoms/RoundedAnchorButton';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import styles from 'shared/styles/styles';
import styled from 'styled-components';

export interface ResultTopAreaProps {
  top_text?: string;
  button_text: string;
  button_disabled?: boolean;
  download_url?: string;
}
export function ResultTopArea({ top_text, button_text, button_disabled, download_url }: ResultTopAreaProps) {
  return (
    <TopArea>
      <TopText>{top_text}</TopText>
      <RightTopArea>
        {button_disabled ? (
          <RoundedButton text={button_text} disabled={true} />
        ) : (
          <RoundedAnchorButton text={button_text} href={download_url} />
        )}
      </RightTopArea>
    </TopArea>
  );
}

const TopArea = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: ${styles.interval_narrow_margin};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

// -- styled components --
const RightTopArea = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TopText = styled.div`
  width: 100%;
`;
