// -- basic library --
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import history from 'shared/browserHistory';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { PageWholeArea, Title, TopArea, BottomArea } from 'shared/components/molecules/ContentsArea';
import { streamDataNumberTypeToJP } from 'shared/models/StreamDataNumberType';
import { streamDataTypeToJP } from 'shared/models/StreamDataType';

import styles from 'shared/styles/styles';
import { isSelected } from 'shared/utils/is';
import { channelsIdProcessesChannelProcessNumberDeleteAPI } from 'user/api/channelsProcesses';
import { streamsIdDeleteAPI } from 'user/api/streams';
import CreateStreamDialog from 'user/pages/Streams/CreateDialog';
import { authSelector } from 'user/redux/slices/authSlice';
import { loadWrapperFunc } from 'user/utils/loadWrapperFunc';

import StreamTable, { TableStreamPageType } from './StreamTable';
import { useStreamsWithPaging } from 'user/hooks/useStreamsWithPaging/useStreamsWithPaging';
import PageSwitchingTabs from 'shared/components/molecules/PageSwitchingTabs';
import { useLocation } from 'react-router-dom';

// -- main component --
const Streams: React.FC = () => {
  const auth_state = useSelector(authSelector);
  const table_massege = auth_state.is_admin ? '＞チェックした項目を削除する' : '＞項目削除は管理者のみできます';

  // -- local states --
  const [selected_bodies, setSelectedBodies] = useState<TableStreamPageType[]>([]);
  const { streams, loadStreams, addStreams, next_meta_data } = useStreamsWithPaging({
    with_output_streams: 'True',
  });
  const table_bodies: TableStreamPageType[] | undefined = useMemo(() => {
    return streams?.map((stream) => {
      const data_number_type_jp = streamDataNumberTypeToJP(stream.data_number_type);
      const data_type_jp = streamDataTypeToJP(stream.data_type);
      return {
        id: stream.stream_id,
        stream_name: {
          value: stream.stream_name,
          url: `/streams/${stream.stream_id}/details`,
        },
        data_number_type: data_number_type_jp,
        data_type: data_type_jp,
        input_channel_event_conditions: stream.input_channel_event_conditions,
        input_channel_processes: stream.input_channel_processes,
        output_channel_process: stream.output_channel_process === null ? '' : stream.output_channel_process,
      };
    });
  }, [streams]);
  const location = useLocation();
  const [tabId, setTabId] = useState<string>('video');
  const [tab_selected_table_bodies, setTabSelectedTableBodies] = useState<TableStreamPageType[]>([])

  // 作成用states
  const [open_create_dialog, setOpenCreateDialog] = useState<boolean>(false);

  // -- handlers --
  const handleCheckClick = (datas: TableStreamPageType[]) => {
    setSelectedBodies(datas);
  };

  const handleCreateClick = () => {
    setOpenCreateDialog(true);
  };

  const handleOKClick = async (selected_datas: TableStreamPageType[]) => {
    // 削除関数定義
    const deletes = async () => {
      let flag = false;
      for (let i = 0; i < selected_datas.length; i++) {
        if (selected_datas[i].input_channel_event_conditions.length !== 0) {
          flag = true;
          continue;
        }
        if (selected_datas[i].input_channel_processes.length !== 0) {
          flag = true;
          continue;
        }
        // 出力ストリームの場合はチャンネルプロセスを削除
        if (selected_datas[i].output_channel_process !== '') {
          const s = selected_datas[i].output_channel_process.split(':');
          await channelsIdProcessesChannelProcessNumberDeleteAPI({
            channel_id: s[0],
            channel_process_number: s[1],
          });
        } else {
          await streamsIdDeleteAPI({
            stream_id: selected_datas[i].id,
          });
        }
      }
      if (flag) {
        AlertDialog.show(`使用中のストリームデータは削除できませんでした`);
      }
      await loadStreams();
      setSelectedBodies([]);
    };
    // 実行
    await loadWrapperFunc(deletes);
  };

  const isUserType = () => {
    return !auth_state.is_admin;
  };

  const handleDeleteClick = async () => {
    ConfirmDialog.show(
      '[確認]\n選択されているデータを削除します。\n本当によろしいですか？',
      () => handleOKClick(selected_bodies),
      undefined,
      undefined,
    );
  };

  /**
   * ビデオとCSVデータの切り替え
   * ブラウザヒストリーへのセットと画面状態の更新
   */
  const handleTabsClick = (id: string) => {
    if (tabId !== id) {
      history.push(`/streams?tabId=${id}`);
      setSelectedBodies([]);
    }
  }

  // ストリームの削除が可能かどうか判定する
  // 一つでも他のプロセスかイベント定義の入力データ(ストリーム)となっている場合は削除不可
  const isStreamsDeleteBtnActive = () => {
    if (selected_bodies.length === 0) return false;
    for (const v of selected_bodies) {
      if (v.input_channel_event_conditions.length !== 0 || v.input_channel_processes.length !== 0) {
        return false;
      }
    }
    return true;
  };

  // 作成用画面の関数
  const handleCreateStreamDialogClose = async (canceled?: boolean) => {
    setOpenCreateDialog(false);
    if (canceled) {
      return;
    }
    await loadStreams();
  };

  useEffect(() => {
    setTabSelectedTableBodies(filterByTab(table_bodies || [], tabId || 'video'));
  }, [table_bodies, tabId])

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    setTabId(query.get('tabId') || 'video');
  }, [location])

  // -- render part --
  return (
    <PageWholeArea data-testid='Stream'>
      <TopArea>
        <Title text='データ' />
        {auth_state.is_admin && (
          <RoundedButton
            onClick={handleCreateClick}
            text='入力データ作成'
            style={{ marginRight: styles.interval_narrow_margin }}
            disabled={isSelected(selected_bodies.length)}
          />
        )}
      </TopArea>
      <PageSwitchingTabs
        display_datas={[{id: 'metric', text: 'CSVデータ'}, {id: 'video', text: 'ビデオデータ'}]}
        handleClick={(id: string) => handleTabsClick(id)}
        selected_id={tabId || 'video'}
        initial_selected_id='details' />
      <BottomArea>
        {table_bodies !== undefined ? (
          <StreamTable
            bodies={tab_selected_table_bodies}
            selected_bodies={selected_bodies}
            handleCheckClick={handleCheckClick}
            handleAddDatas={addStreams}
            handleAPISearch={() => loadStreams(undefined)}
            has_next={next_meta_data.has_next}
            footer_option={{
              text: table_massege,
              handleClick: handleDeleteClick,
              disabled: !isStreamsDeleteBtnActive() || isUserType(),
            }}
          />
        ) : (
          <Spinner />
        )}

        {open_create_dialog && (
          <CreateStreamDialog isOpen={open_create_dialog} onClose={handleCreateStreamDialogClose} />
        )}
      </BottomArea>
    </PageWholeArea>
  );
};

/** 現在のタブ選択状態に応じてテーブル明細をフィルターします。 */
function filterByTab(items: TableStreamPageType[], id: string) {
  // (入力されるデータは streamDataTypeToJP によって日本語化されているので注意)
  const t = id === 'video' ? ['ビデオ', '画像'] : ['メトリック'];
  return items.filter(value => t.includes(value.data_type));
}


// -- styled components --

// -- finally export part --

export default Streams;
