import SelectBox from 'shared/components/atoms/SelectBox';
import InputComponent from 'shared/components/molecules/InputComponent';
import {
  ChannelEventConditionUsecaseTypeType,
  convertChannelEventConditionUsecaseTypeType,
} from 'shared/models/ChannelEventConditionUsecaseType';
import { Stream } from 'user/api/streams';
import UsecaseConditionStayalertPane from './UsecaseConditionStayalertPane';

type Params = {
  streams?: Stream[];
  usecase_type?: ChannelEventConditionUsecaseTypeType;
  onUsecaseTypeChange: (arg0: ChannelEventConditionUsecaseTypeType | undefined) => void;
  stayareas: string;
  onStayareasChange: (stayareas: string) => void;
  staying_time?: number;
  onStayingTimeChange: (staying_time?: number) => void;
  area_routing?: string;
  onAreaRoutingChange: (area_routing: string) => void;
};

/** ユースケースイベントのみで入力するフィールド群です。 */
const UsecaseConditionPane: React.FC<Params> = (params) => {
  return (
    <>
      <InputComponent text='ユースケース' required>
        <SelectBox
          datas={[
            {
              name: '滞留イベントアラート',
              value: 'STAY_ALERT',
            },
          ]}
          value={params.usecase_type || 'STAY_ALERT'}
          onChange={(e) =>
            params.onUsecaseTypeChange(convertChannelEventConditionUsecaseTypeType(e.currentTarget.value))
          }
          default_text='null'
        />
      </InputComponent>
      <UsecaseConditionStayalertPane
        stayareas={params.stayareas}
        onStayareasChange={params.onStayareasChange}
        staying_time={params.staying_time}
        onStayingTimeChange={params.onStayingTimeChange}
        area_routing={params.area_routing}
        onAreaRoutingChange={params.onAreaRoutingChange}
      />
    </>
  );
};

export default UsecaseConditionPane;
