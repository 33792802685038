import { StreamDeviceGraph } from 'shared/models/StreamDeviceGraph';
import { Device } from 'user/api/device';

/** 固定ピンが付けられている時はtrue, それ以外はfalse**/
const getPinnedStatus = (params: { device: Device; stream_id: string; stream_graph: StreamDeviceGraph }) => {
  let bol = false;
  if (
    params.device.graph_stream_id &&
    params.device.stream_device_graph_id &&
    params.device.graph_stream_id === params.stream_id &&
    params.device.stream_device_graph_id === params.stream_graph.id
  ) {
    bol = true;
  }
  return bol;
};

export default getPinnedStatus;
