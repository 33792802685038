// -- basic library --
import React, { useState } from 'react';
import FileBox from 'shared/components/atoms/FileBox';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import MultiFileBox from 'shared/components/molecules/MultiFileBox';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';

import { isMIMEtype } from 'shared/utils/is';
import { isSequence } from 'user/utils/streamFunctions';
import { StreamDataFileType } from 'shared/models';
import styled from 'styled-components';
import { Stream } from 'user/api/streams';

// -- type declaration --
interface Params {
  stream?: Stream | null;
  files: StreamDataFileType[];
  setFiles: (files: StreamDataFileType[]) => void;
  accept?: string;
}

// -- main component --
/**
 * ストリームデータを入力するファイルボックス
 * **/
const StreamDataFileBox: React.FC<Params> = (params) => {
  const [dropping, setDropping] = useState<boolean>(false);

  // ストリームデータを1つだけ選ぶ場合の関数
  const onChange1File = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files !== null && e.currentTarget.files[0]) {
      const file = e.currentTarget.files[0];
      params.setFiles([
        {
          file: file,
          file_name: file.name || '',
        },
      ]);
    }
  };

  const onDragOver = (event: React.DragEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (!dropping) {
      setDropping(true);
    }
    event.dataTransfer.dropEffect = 'move';
  };

  const onDrop = (event: React.DragEvent<HTMLInputElement>) => {
    event.preventDefault();
    // 無効なファイルのリスト
    const invalid_file_dropped: string[] = [];
    const data = event.dataTransfer.files;
    const new_stream_data_files = [];
    for (let i = 0; i < data.length; i++) {
      const file = data[i];
      const file_name = file.name || '';
      // MIMEタイプが正しければファイルを更新
      if (isMIMEtype(file.type, params.accept)) {
        new_stream_data_files.push({
          file: file,
          file_name: file_name,
        });
      } else {
        // 無効なファイルはリストに追加
        invalid_file_dropped.push(file_name);
      }
    }
    if (isSequence(params.stream)) {
      //SEQUENCEの時は、追加する
      params.setFiles([...params.files, ...new_stream_data_files]);
    } else if (new_stream_data_files.length >= 1) {
      //それ以外は、0番目の要素を新しいものとして更新する
      params.setFiles(new_stream_data_files.slice(0, 1));
    }
    setDropping(false);
    // 無効なファイルがある場合は、伝える
    if (invalid_file_dropped.length > 0) {
      AlertDialog.show(
        `[${invalid_file_dropped.join(', ')}]は、アップロードできませんでした。\n※${
          params.accept
        }のファイル型のみアップロード可能。`,
      );
    }
  };
  // -- render part --
  return (
    <WholeArea onDragOver={onDragOver} onDrop={onDrop} onDragLeave={() => setDropping(false)} drop={dropping}>
      <Text style={{ marginBottom: styles.interval_margin }}>※アップロードするファイルをここにドロップできます</Text>
      {isSequence(params.stream) ? (
        <MultiFileBox files={params.files} setFiles={params.setFiles} accept={params.accept} />
      ) : (
        <FileBox
          onChange={onChange1File}
          placeholder={params.accept ? `ファイルを選択してください(${params.accept})` : `ファイルを選択してください`}
          button_text='ファイルを選択'
          accept={params.accept}
          file_name={params.files.length > 0 ? params.files[0].file_name : ''}
        />
      )}
    </WholeArea>
  );
};

const WholeArea = styled.div<{
  drop?: boolean;
}>`
  width: 100%;
  height: auto;
  padding: ${styles.interval_narrow_margin};
  display: flex;
  flex-direction: column;
  border: ${(p) => `3px dashed ${p.drop ? colors.component_border_color : colors.component_small_border_color}`};
`;

const Text = styled.div`
  font-weight: ${styles.font_weight};
  font-size: larger;
`;

// -- finally export part --

export default StreamDataFileBox;
