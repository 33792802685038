import React from 'react';
import CheckBoxWithText from 'shared/components/molecules/CheckBoxWithText';
import InputComponent from 'shared/components/molecules/InputComponent';
import InputCronText from 'shared/components/molecules/InputCronText';
import styles from 'shared/styles/styles';
import styled from 'styled-components';
import { Process } from 'user/api/processes';

interface ChannelProcessHeartbeatPartProps {
  process: Process;
  value: { [key: string]: string };
  onChange: (value: { [key: string]: string }) => void;
}
/**
 * チャンネルプロセス設定でHeartbeatの設定部分
 */
export default class ChannelProcessHeartbeatPart extends React.PureComponent<ChannelProcessHeartbeatPartProps> {
  private getHeartbeat = (key: string) => {
    for (const v of this.props.process.heartbeats) {
      if (v.key === key) {
        return v;
      }
    }
    return null;
  };
  private onSetClick = (key: string, value: boolean) => {
    const new_value = { ...this.props.value };
    if (value) {
      const hb = this.getHeartbeat(key);
      if (hb && hb.default_schedule.split(' ').length === 5) {
        new_value[key] = hb.default_schedule;
      } else {
        new_value[key] = '0 0 * * *';
      }
    } else {
      delete new_value[key];
    }
    this.props.onChange(new_value);
  };
  private onInputChange = (key: string, value: string) => {
    const new_value = { ...this.props.value };
    new_value[key] = value;
    this.props.onChange(new_value);
  };
  render() {
    if (this.props.process.heartbeats.length === 0) {
      return null;
    }
    return (
      <InputComponent text='イベントスケジュール'>
        {this.props.process.heartbeats.map((hb, i) => {
          const value = this.props.value[hb.key];
          const isSet = value !== undefined;
          return (
            <StyledRow key={i}>
              <StyledRowTitle>{hb.name}</StyledRowTitle>
              <StyledRowContent>
                <CheckBoxWithText checked={isSet} text='指定する' onClick={() => this.onSetClick(hb.key, !isSet)} />
                <InputCronText
                  value={value ? value : ''}
                  onChange={(v) => this.onInputChange(hb.key, v)}
                  disabled={!isSet}
                />
              </StyledRowContent>
            </StyledRow>
          );
        })}
      </InputComponent>
    );
  }
}

export const StyledRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: ${styles.interval_narrow_margin};
`;

export const StyledRowTitle = styled.div`
    margin-right: ${styles.interval_margin};
    width: 200px;
    max-width: 200px
    white-space: nowrap;
    display: flex;
`;

export const StyledRowContent = styled.div``;
