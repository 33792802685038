import { Button, ButtonProps } from '@blueprintjs/core';
import styled from 'styled-components';

export interface SelectableBlueprintButton extends ButtonProps {
  selected?: boolean;
  title?: string;
}
/**
 * クリックして選択することができるBlueprint製のButton
 */
const BlueprintButton = styled((props: SelectableBlueprintButton) => <Button {...props} />)`
  opacity: ${({ selected }) => (selected ? 1 : 0.5)};
`;

export default BlueprintButton;
