// -- basic library --
import React, { useEffect, useState } from 'react';

import InputBox from 'shared/components/atoms/InputBox';
import PfDialog from 'shared/components/atoms/PfDialog';
import SelectBox from 'shared/components/atoms/SelectBox';
import InputComponent from 'shared/components/molecules/InputComponent';
import PageSwitchingTabs from 'shared/components/molecules/PageSwitchingTabs';
import { Device } from 'user/api/device';
import { CachedProcesses } from 'user/api/processes';
import { CachedProcessFlowInfos, CachedProcessFlows } from 'user/api/processflows';
import { CachedStreams, Stream, streamsIdGetAPI } from 'user/api/streams';
import { CachedStreamDatas, StreamData } from 'user/api/streamsData';
import ProcessFlowPanel from './ProcessFlowPanel';
import ProcessPanel from './ProcessPanel';

// -- types --

interface Params {
  device: Device;
  isOpen?: boolean;
  onClose: (p?: { isCanceled?: boolean }) => void;
}

// -- main component --
const CreateInferreddataDialog: React.FC<Params> = (params) => {
  // -- redux preparations --

  // -- local states --
  const [tab_id, setTabId] = useState<string>('process-flow');
  // このページ配下で取得するデータは、ここで取得する。(なぜならば、タブを切り替えた時に、いちいち取得しなきゃいけなくなるから)
  const [cachedProcessFlowInfos] = useState<CachedProcessFlowInfos>(new CachedProcessFlowInfos());
  const [stream, setStream] = useState<Stream>();
  const [selected_stream_data_number, setSelectedStreamDataNumber] = useState<string>('');
  const [cachedStreams] = useState<CachedStreams>(
    new CachedStreams({
      with_output_streams: 'True',
      data_types: 'VIDEO,IMAGE',
      data_number_types: 'SEQUENCE',
    }),
  );
  const [stream_datas, setStreamDatas] = useState<StreamData[]>([]);
  const [cachedProcesses] = useState<CachedProcesses>(new CachedProcesses({ can_be_oneshot: true }));
  const [cachedProcessFlows] = useState<CachedProcessFlows>(new CachedProcessFlows({}));
  const [inferreddata_name, setInferreddataName] = useState<string>('');

  const getStream = async () => {
    if (!params.device.input_stream_id) return;
    const res = await streamsIdGetAPI({ stream_id: params.device.input_stream_id });
    if (res.status === 200) {
      setStream(res.data);
    }
  };

  const getStreamDatas = async () => {
    if (!params.device.input_stream_id) return;
    const cachedStreamDatas = await new CachedStreamDatas({ stream_id: params.device.input_stream_id }).get();
    setStreamDatas(cachedStreamDatas);
  };

  // -- onload function --
  useEffect(() => {
    (async function () {
      await getStream();
      await getStreamDatas();
    })();
  }, []); /* eslint-disable-line */

  const handleTabsClick = (tab_id: string) => {
    setTabId(tab_id);
  };

  const handleInferreddataNameChangeClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInferreddataName(e.currentTarget.value);
  };

  const getDisplayDatas = () => {
    const display_datas = [
      {
        id: 'process-flow',
        text: 'プロセスフロー',
      },
      {
        id: 'process',
        text: 'プロセス',
      },
    ];
    return display_datas;
  };

  // -- render part --
  return (
    <PfDialog
      title='プロセスの登録'
      isOpen={params.isOpen === undefined ? true : params.isOpen}
      onClose={() => params.onClose()}
    >
      <div>
        <InputComponent text='推論動画名' required>
          <InputBox
            title='推論動画名'
            placeholder='入力してください'
            value={inferreddata_name}
            onChange={handleInferreddataNameChangeClick}
          />
        </InputComponent>
        <InputComponent text='録画動画一覧' required>
          <SelectBox
            value={selected_stream_data_number}
            datas={stream_datas.map((sd) => {
              return { name: sd.stream_data_name, value: String(sd.stream_data_number) };
            })}
            onChange={(e) => setSelectedStreamDataNumber(e.currentTarget.value)}
          />
        </InputComponent>
        {params.device.channel_id && stream ? (
          <>
            <PageSwitchingTabs
              display_datas={getDisplayDatas()}
              handleClick={handleTabsClick}
              selected_id={tab_id}
              initial_selected_id='process-flow'
            />
            {tab_id === 'process-flow' && (
              <ProcessFlowPanel
                {...params}
                channel_id={params.device.channel_id}
                stream={stream}
                stream_data_number={selected_stream_data_number}
                cachedProcessflowInfos={cachedProcessFlowInfos}
                cachedStreams={cachedStreams}
                cachedProcessFlows={cachedProcessFlows}
                inferreddata_name={inferreddata_name}
              />
            )}
            {tab_id === 'process' && (
              <ProcessPanel
                {...params}
                channel_id={params.device.channel_id}
                stream={stream}
                stream_data_number={selected_stream_data_number}
                cachedStreams={cachedStreams}
                cachedProcesses={cachedProcesses}
                inferreddata_name={inferreddata_name}
              />
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </PfDialog>
  );
};

// -- styled components --

// -- finally export part --

export default CreateInferreddataDialog;
