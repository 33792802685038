import { CommonAPIRequestType, isCommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';
import { ISO8601 } from 'shared/models/ISO8601';
import { StatusType } from 'shared/models/StatusType';
import sendAxios from '../../shared/axios/sendAxios';
import { getClient } from './base';

/*** エンティティ ***/

export interface S3Transfer {
  transfer_id: string;
  ttl_timestamp: number;
  tenant_id: string;
  stream_id: string;
  stream_data_number: number;
  stream_data_name: string;
  s3_content_type: string;
  status: StatusType;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

const sample_s3_transfer1: S3Transfer = {
  transfer_id: '00000001',
  ttl_timestamp: 800000000,
  tenant_id: '00000001',
  stream_id: '00000001',
  stream_data_number: 3,
  stream_data_name: 'ストリーム1',
  s3_content_type: 's/s',
  status: 'ACTIVE',
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: null,
};

export interface MultiPart {
  part_number: number;
  e_tag: string;
}

/*** [GET] /api/s3-transfer/{transfer_id} ***/

export interface RequestS3TransferIdGet extends CommonAPIRequestType {
  transfer_id: string;
}

export const s3TransfersIdGetAPI = (params: RequestS3TransferIdGet) => {
  const { disabled_load, disabled_error_message, transfer_id } = params;
  // クライアントを定義
  const axios = getClient({ disabled_error_message, disabled_load });

  // パス・メソッドを定義
  const path = `/api/s3-transfer/${transfer_id}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<S3Transfer>(axios, path, query, form, method, sample_s3_transfer1);
};

export interface RequestS3TransfersIdPartsSignaturePost extends CommonAPIRequestType {
  transfer_id: string;
  part_number: number;
}

export interface ResponseS3TransfersIdPartsSignaturePost {
  upload_url: string;
}

/***
 * [POST] /api/s3-transfer/{transfer_id}/parts-signature
 * マルチパート用の署名付きURLを生成する
 * ***/
export const s3TransfersIdPartsSignaturePostAPI = (params: RequestS3TransfersIdPartsSignaturePost) => {
  const { transfer_id, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/s3-transfer/${transfer_id}/parts-signature`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'transfer_id' || isCommonAPIRequestType(key)) {
      continue;
    }
    form.append(key, value);
  }

  // 送信
  return sendAxios<ResponseS3TransfersIdPartsSignaturePost>(axios, path, query, form, method, {
    upload_url: 'sample-url',
  });
};

export interface RequestS3TransfersIdUploadUrlPut extends CommonAPIRequestType {
  upload_url: string;
  data: string | ArrayBuffer | null;
}

/***
 * [PUT] /upload_url
 * 各パートアップロードを行います。
 * ***/
export const s3TransfersIdUploadUrlPutAPI = (params: RequestS3TransfersIdUploadUrlPut) => {
  const { upload_url, data, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `${upload_url}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query = {};

  // [put, post]リクエストボディを定義
  // const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return sendAxios<any>(axios, path, query, data, method, '');
};

export interface RequestS3TransfersIdCompletePost {
  transfer_id: string;
  parts: MultiPart[];
}

export interface ResponseS3TransfersIdCompletePost {
  completed: boolean;
}

/***
 * [POST] /api/s3-transfer/{transfer_id}/complete
 * マルチパートアップロードを完了する
 * ***/
export const s3TransfersIdCompletePostAPI = (params: RequestS3TransfersIdCompletePost) => {
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `/api/s3-transfer/${params.transfer_id}/complete`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'parts') {
      continue;
    }
    form.append(key, value);
  }

  // multipartsだけ別処理
  if (params.parts && params.parts.length > 0) {
    // s3の仕様上10000回が限界
    const roop_number = Math.min(params.parts.length || 0, 10000);
    for (let i = 0; i < roop_number; i++) {
      for (const [key, value] of Object.entries(params.parts[i])) {
        form.append('parts[' + i + '].' + key, String(value));
      }
    }
  }

  // 送信
  return sendAxios<ResponseS3TransfersIdCompletePost>(axios, path, query, form, method, {
    completed: true,
  });
};
