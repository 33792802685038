import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import InputBox from 'shared/components/atoms/InputBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Content } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import { isNotOnlySpace } from 'shared/utils/is';
import styled from 'styled-components';
import { channelsIdGetAPI, channelsIdPutAPI, Channel } from 'user/api/channels';
import { systemSelector } from 'user/redux/slices/systemSlice';

// -- type declaration --

type Params = RouteComponentProps<{ channel_id: string }>;

// -- main component --
const ChannelsIdDetailOverView: React.FC<Params> = (params) => {
  // -- redux preparations --
  const system_state = useSelector(systemSelector);
  // local_states
  // 入力用states
  const [channel_name, setChannelName] = useState<string>('');
  const [channel, setChannel] = useState<Channel | null>(null);

  const getDatas = useCallback(async (channel_id: string) => {
    const res = await channelsIdGetAPI({ channel_id });
    if (res.status === 200) {
      setChannelName(res.data.channel_name);
      setChannel(res.data);
    }
  }, []);

  const handleFinish = async (channel_id: string, inputs: { channel_name: string }) => {
    if (!isNotOnlySpace(inputs.channel_name)) {
      AlertDialog.show(<div>チャンネル名は必須です</div>);
      return;
    }
    const post_data = {
      channel_id: channel_id,
      channel_name: inputs.channel_name,
    };
    const res = await channelsIdPutAPI(post_data);
    if (res.status === 200) {
      AlertDialog.show(<div>チャンネルの更新に成功しました</div>);
      await getDatas(channel_id);
    }
  };

  // -- onload function --
  useEffect(() => {
    (async function () {
      // プロセス情報の取得
      await getDatas(params.match.params.channel_id);
    })();
  }, [getDatas, params.match.params.channel_id]);

  // -- render part --
  return (
    <HalfPanel>
      {!system_state.loading.preloading && channel ? (
        <>
          <InputComponent text='チャンネルID'>
            <div>{params.match.params.channel_id}</div>
          </InputComponent>
          <InputComponent text='チャンネル名' required>
            <InputBox
              title='チャンネル名'
              placeholder='入力してください(必須)'
              value={channel_name}
              onChange={(e) => setChannelName(e.currentTarget.value)}
            />
          </InputComponent>
          <RoundedButton
            onClick={() =>
              handleFinish(params.match.params.channel_id, {
                channel_name: channel_name,
              })
            }
            disabled={Boolean(!isNotOnlySpace(channel_name))}
            text_type='UPDATE'
          />
        </>
      ) : (
        <Spinner />
      )}
    </HalfPanel>
  );
};

// -- styled components --
const HalfPanel = styled(Content)`
  width: 50%;
`;

// -- finally export part --
export default ChannelsIdDetailOverView;
