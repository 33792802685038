import { DashboardBusOdMetric } from 'user/api/dashboardsWidgets';
import { Table, Tbody, Td, Th, Thead, Tr } from 'shared/components/atoms/PfTable';
import SelectBox, { SelectData } from 'shared/components/atoms/SelectBox';
import Spinner from 'shared/components/atoms/Spinner';
import React from 'react';
import DateRangeWidgetBase2 from './base/DateRangeWidgetBase2';
import { WidgetProps } from './getWidgetType';

interface Busod3WidgetState {
  data?: DashboardBusOdMetric[];
  route_long_name: string;
}
/**
 * バス乗降グラフ描画ウィジェットです。
 */
export default class Busod3Widget extends React.PureComponent<WidgetProps, Busod3WidgetState> {
  constructor(props: WidgetProps) {
    super(props);
    this.state = {
      data: undefined,
      route_long_name: '',
    };
  }

  private getStopNames(data: DashboardBusOdMetric[], expected_route_long_name: string | undefined): string[] {
    const ret: string[] = [];
    const s: Set<string> = new Set();
    for (const item of data) {
      if (item.gtfs) {
        const route_long_name = item.gtfs.route_long_name;
        if (expected_route_long_name && route_long_name !== expected_route_long_name) {
          continue;
        }
        const stop_name = item.gtfs.stop_name || 'Unknown';
        if (!s.has(stop_name)) {
          s.add(stop_name);
          ret.push(stop_name);
        }
      }
    }
    return ret;
  }
  private getOdSummary(data: DashboardBusOdMetric[], expected_route_long_name: string | undefined): IOdSummary {
    const ret: IOdSummary = {};
    const t_stop_name: { [t: number]: string } = {}; // 時刻ごとの乗車バス停名
    for (const item of data) {
      if (item.gtfs) {
        const route_long_name = item.gtfs.route_long_name;
        if (expected_route_long_name && route_long_name !== expected_route_long_name) {
          continue;
        }
        const stop_name = item.gtfs.stop_name;
        // フレーム時刻の乗車バス停名を記録
        t_stop_name[item.t] = stop_name;
        // OD降車の場合に乗車バス停名を取得
        for (const obj of item.objects) {
          if (obj.od.state === 'OUT') {
            const t = obj.od.in_t;
            const origin_stop_name = t_stop_name[t] || 'Unknown';
            let origin = ret[origin_stop_name];
            if (origin === undefined) {
              origin = {};
              ret[origin_stop_name] = origin;
            }
            const count = origin[stop_name] || 0;
            origin[stop_name] = count + 1;
          }
        }
      }
    }
    return ret;
  }
  /** 路線名セレクトボックスの選択肢を返します。 */
  private getSelectBoxData(data: DashboardBusOdMetric[]): SelectData<string>[] {
    const ret: SelectData<string>[] = [{ name: '全て', value: '' }];
    const s: Set<string> = new Set();
    for (const item of data) {
      if (item.gtfs) {
        const route_long_name = item.gtfs.route_long_name;
        if (!s.has(route_long_name)) {
          s.add(route_long_name);
          ret.push({ name: route_long_name, value: route_long_name });
        }
      }
    }
    return ret;
  }

  private onMetricsChanged = (data: DashboardBusOdMetric[]) => {
    this.setState({ data });
  };

  private onInitMetrics = () => {
    this.setState({ data: undefined });
  };
  render() {
    const { data } = this.state;
    return (
      <DateRangeWidgetBase2
        widget_type={'BUSOD3'}
        widget={this.props.widget}
        state={this.props.state}
        data={this.state.data}
        route_long_name={this.state.route_long_name}
        onInitMetrics={this.onInitMetrics}
        onStateChanged={this.props.onStateChanged}
        onMetricsChanged={this.onMetricsChanged}
        handleUpdateDlCsvList={this.props.handleUpdateDlCsvList}
      >
        {data ? (
          <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <div>
              <SelectBox
                onChange={(e) => this.setState({ route_long_name: e.currentTarget.value })}
                value={this.state.route_long_name}
                datas={this.getSelectBoxData(data)}
                default_text={'null'}
              />
            </div>
            <div style={{ flexGrow: 1, overflow: 'scroll', height: '1vh' }}>
              <TriangleTable
                stop_names={this.getStopNames(data, this.state.route_long_name)}
                od_summary={this.getOdSummary(data, this.state.route_long_name)}
              />
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </DateRangeWidgetBase2>
    );
  }
}

type IOdSummary = { [origin: string]: { [destination: string]: number } };
interface TriangleTableProps {
  stop_names: string[];
  od_summary: IOdSummary;
}
class TriangleTable extends React.PureComponent<TriangleTableProps> {
  private getTdTags(stop_name: string, maxCount: number) {
    const origin = this.props.od_summary[stop_name];
    if (origin === undefined) {
      return (
        <>
          {this.props.stop_names.map((e, i) => {
            return <Td key={i}></Td>;
          })}
        </>
      );
    }
    return (
      <>
        {this.props.stop_names.map((e, i) => {
          return (
            <Td
              key={i}
              style={{
                backgroundColor: this.getBgColor(origin[e], maxCount),
                color: this.getColor(origin[e], maxCount),
              }}
            >
              {origin[e] ? origin[e] : null}
            </Td>
          );
        })}
      </>
    );
  }
  private getBgColor(value: number, maxCount: number) {
    if (value === undefined) {
      return undefined;
    }
    // R,G要素を0~255で比率にする
    const _hex = (255 - Math.round((value / maxCount) * 255)).toString(16);
    const _hex2 = _hex.length === 1 ? '0' + _hex : _hex;
    return '#' + _hex2 + _hex2 + 'ff';
  }
  private getColor(value: number, maxCount: number) {
    if (value === undefined) {
      return undefined;
    }
    if (255 - (value / maxCount) * 255 < 128) {
      return '#ffffff';
    } else {
      return undefined;
    }
  }
  /** 乗降者の最大値を返します。 */
  private getMax() {
    let value = 0;
    const od_summary = this.props.od_summary;
    for (const origin_stop_name in od_summary) {
      if (origin_stop_name === 'Unknown') {
        continue;
      }
      const dest_values = od_summary[origin_stop_name];
      for (const dest_stop_name in dest_values) {
        value = Math.max(value, dest_values[dest_stop_name]);
      }
    }
    return value;
  }
  render() {
    const maxCount = this.getMax();
    return (
      <Table style={{ width: 'auto', height: 'auto' }}>
        <Thead>
          <Tr>
            <Th></Th>
            {this.props.stop_names.map((e, i) => {
              return (
                <Th key={i} style={{ whiteSpace: 'nowrap' }}>
                  {e}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {this.props.stop_names.map((e, i) => {
            return (
              <Tr key={i}>
                <Td>{e}</Td>
                {this.getTdTags(e, maxCount)}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    );
  }
}
