/* 歯車アイコンとポップオーバーメニューを表現します。 */

import { useState } from "react";
import Popover from "../atoms/Popover";
import gear_icon from "assets/gear.svg"
import { Menu, MenuItem } from "@blueprintjs/core";

export interface GearPopoverItem {
  label: string;  // メニューのラベル
  onClick: (item: GearPopoverItem) => void;  // メニュークリック時のイベントハンドラー
}
interface GearPopoverParam {
  menuItems: GearPopoverItem[];
}
/**
 * 歯車アイコンとポップオーバーメニューです。
 * メニューアイテムごとに onClick イベントを発生させることができます。
 */
export function GearPopover(param: GearPopoverParam) {
  const [isOpen, setOpen] = useState<boolean>(false);
  return (
    <Popover
      content={
        <Menu>
          {param.menuItems.map(value => {
            return <MenuItem key={value.label} onClick={() => value.onClick(value)} text={value.label} />
          })}
        </Menu>
      }
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      position="bottom-right">
      <img
        src={gear_icon}
        style={{ width: 24, height: 24, cursor: 'pointer', userSelect: 'none' }}
        onClick={() => setOpen(!isOpen)}/>
    </Popover>
  );
}
