import React from 'react';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { CachedStreams } from 'user/api/streams';
import LayerPropertyDialog from '../LayerPropertyDialog';
import LayerMenu from './LayerMenu';
import { EditorList, EditorListItem } from './components/EditorList';
import PaneBox from './components/PaneBox';
import { Layer } from './types';

interface LayersPaneProps extends React.HTMLAttributes<HTMLDivElement> {
  layers: Layer[];
  selectedLayer?: Layer;
  onLayersChange: (layers: Layer[]) => void;
  onLayerSelectionChange: (layer: Layer) => void;
}
interface LayersPaneState {
  layerMenuProps?: { layer: Layer; style: { top: number; left: number } };
  layerPropertyDialogProps?: { layer: Layer };
}
export default class LayersPane extends React.PureComponent<LayersPaneProps, LayersPaneState> {
  private streams = new CachedStreams({ with_output_streams: 'True' });
  constructor(props: LayersPaneProps) {
    super(props);
    this.state = {};
  }
  private handleAddClick = () => {
    this.setState({ layerMenuProps: undefined });
    this.props.onLayersChange([...this.props.layers, { name: 'Layer ' + (this.props.layers.length + 1), objects: [] }]);
  };
  private handleItemClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, layer: Layer) => {
    this.setState({ layerMenuProps: undefined });
    this.props.onLayerSelectionChange(layer);
  };
  private handleItemRightClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, layer: Layer) => {
    e.preventDefault();
    this.setState({
      layerMenuProps: {
        layer: layer,
        style: {
          top: e.clientY + 4,
          left: e.clientX + 4,
        },
      },
    });
  };
  private handleItemDeleteClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, layer: Layer) => {
    e.stopPropagation();
    const newLayers: Layer[] = [];
    for (const o of this.props.layers) {
      if (o !== layer) {
        newLayers.push(o);
      }
    }
    this.props.onLayersChange(newLayers);
    this.setState({ layerMenuProps: undefined });
  };
  private handleLayerPropertyOpen = (e: React.MouseEvent<HTMLElement, MouseEvent>, layer: Layer) => {
    e.stopPropagation();
    this.setState({
      layerPropertyDialogProps: { layer: layer },
      layerMenuProps: undefined,
    });
  };
  private handleLayerPropertyCancelClose = () => {
    this.setState({ layerPropertyDialogProps: undefined });
  };
  private handleLayerPropertyOkClose = (prevLayer: Layer, newLayer: Layer) => {
    const newLayers: Layer[] = [];
    for (const o of this.props.layers) {
      if (o !== prevLayer) {
        newLayers.push(o);
      } else {
        newLayers.push(newLayer);
      }
    }
    this.props.onLayersChange(newLayers);
    this.setState({ layerPropertyDialogProps: undefined });
  };
  render() {
    return (
      <PaneBox
        {...this.props}
        paneTitle='Layers'
        paneTitleIcons={[{ name: 'add', icon: IconNames.ADD, onClick: this.handleAddClick }]}
        onClick={() => this.setState({ layerMenuProps: undefined })}
      >
        <EditorList>
          {this.props.layers.map((layer, i) => {
            return (
              <EditorListItem
                key={i}
                selected={layer === this.props.selectedLayer}
                onClick={(e) => this.handleItemClick(e, layer)}
                onContextMenu={(e) => this.handleItemRightClick(e, layer)}
              >
                <div style={{ flexGrow: 1 }}>{layer.name}</div>
                <div>
                  <Icon icon={IconNames.DELETE} onClick={(e) => this.handleItemDeleteClick(e, layer)} />
                </div>
              </EditorListItem>
            );
          })}
        </EditorList>
        {this.state.layerMenuProps !== undefined && (
          <LayerMenu
            {...this.state.layerMenuProps}
            style={{ position: 'absolute', ...this.state.layerMenuProps.style }}
            onLayerProperty={(e, layer) => this.handleLayerPropertyOpen(e, layer)}
            onLayerDelete={(e, layer) => this.handleItemDeleteClick(e, layer)}
          />
        )}
        {this.state.layerPropertyDialogProps !== undefined && (
          <LayerPropertyDialog
            {...this.state.layerPropertyDialogProps}
            streams={this.streams}
            onCancelClose={this.handleLayerPropertyCancelClose}
            onOkClose={this.handleLayerPropertyOkClose}
          />
        )}
      </PaneBox>
    );
  }
}
