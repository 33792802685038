/**
 * 絶対または相対的な日時を表すオブジェクト
 *  - number は絶対日時(UNIX時刻)を表します
 *  - 'now' は現在のシステム日時を表します
 *  - 'minute' は現在分
 *  - 'hour' は現在時
 *  - 'today' は当日(startの場合は00:00:00、endの場合は23:59:59)
 *  - 'month' は現在月
 *  - 'year' は現在年
 *  - 'sunday' ~ 'saturday' は前のまたは次の該当曜日
 *  - '*+N' '*-N' により演算ができます。Nには 's' 'm' 'h' 'D' 'M' 'Y' 'W' を付けることで秒分時日月年週を表します
 */
export default class FeatureDatetime {
  private value: number | string;
  constructor(value: number | string) {
    this.value = value;
  }
  getTime(isEnd: boolean) {
    if (typeof this.value === 'number') return this.value;
    else return getTimeInternal(this.value, isEnd);
  }
  getLabel() {
    if (typeof this.value === 'number') return toDateStr(this.value);
    else return getLabelInternal(this.value);
  }
  getValue() {
    return this.value;
  }
}

// ----- 文字列変換関数 -----

function toDateStr(val: number) {
  const dt = new Date(val * 1000);
  const y = dt.getFullYear();
  const m = dt.getMonth() + 1;
  const d = dt.getDate();
  const H = dt.getHours();
  const M = dt.getMinutes();
  return (
    y +
    '/' +
    (m < 10 ? '0' + m : String(m)) +
    '/' +
    (d < 10 ? '0' + d : String(d)) +
    ' ' +
    (H < 10 ? '0' + H : String(H)) +
    ':' +
    (M < 10 ? '0' + M : String(M))
  );
}

// ----- キーワード変換関数 -----

function getTimeInternal(value: string, isEnd: boolean) {
  // 基礎日付の変換
  const dt = new Date();
  dt.setMilliseconds(0);
  if (value.startsWith('now')) {
    value = value.substring(3);
  } else if (value.startsWith('minute')) {
    toMinute(dt, isEnd);
    value = value.substring(6);
  } else if (value.startsWith('hour')) {
    toHour(dt, isEnd);
    value = value.substring(4);
  } else if (value.startsWith('today')) {
    toToday(dt, isEnd);
    value = value.substring(5);
  } else if (value.startsWith('month')) {
    toMonth(dt, isEnd);
    value = value.substring(5);
  } else if (value.startsWith('year')) {
    toYear(dt, isEnd);
    value = value.substring(4);
  } else {
    for (const wd of WEEKDAYS) {
      if (value.startsWith(wd)) {
        toWeekday(dt, wd, isEnd);
        value = value.substring(wd.length);
      }
    }
  }
  // 移行演算
  try {
    while (value.startsWith('-') || value.startsWith('+')) {
      // 演算子
      const op = value[0];
      value = value.substring(1);
      // オペレータ
      const i1 = value.indexOf('-');
      const i2 = value.indexOf('+');
      let val: string;
      if (i1 !== -1 && i2 !== -1) {
        val = value.substring(0, Math.min(i1, i2));
        value = value.substring(val.length);
      } else if (i1 === -1 && i2 === -1) {
        val = value;
        value = '';
      } else {
        val = value.substring(0, Math.max(i1, i2));
        value = value.substring(val.length);
      }
      if (val.length <= 1) continue; // 無効
      const n = Number(val.substring(0, val.length - 1)) * (op === '+' ? 1 : -1);
      const r = val[val.length - 1];
      // 演算処理
      switch (r) {
        case 's':
          dt.setSeconds(dt.getSeconds() + n);
          break;
        case 'm':
          dt.setMinutes(dt.getMinutes() + n);
          break;
        case 'h':
          dt.setHours(dt.getHours() + n);
          break;
        case 'D':
          dt.setDate(dt.getDate() + n);
          break;
        case 'M':
          dt.setMonth(dt.getMonth() + n);
          break;
        case 'Y':
          dt.setFullYear(dt.getFullYear() + n);
          break;
        case 'W':
          dt.setDate(dt.getDate() + n * 7);
          break;
      }
    }
  } catch (e) {
    console.log(e);
  }
  return Math.floor(dt.getTime() / 1000);
}

function getLabelInternal(value: string) {
  switch (value) {
    case 'now':
      return '現在';
    case 'today':
      return '今日';
    case 'today-1D':
      return '昨日';
    case 'month':
      return '今月';
    case 'year':
      return '今年';
    case 'Sunday':
      return '日曜日';
    case 'Monday':
      return '月曜日';
    case 'Tuesday':
      return '火曜日';
    case 'Wednesday':
      return '水曜日';
    case 'Thursday':
      return '木曜日';
    case 'Friday':
      return '金曜日';
    case 'Saturday':
      return '土曜日';
  }
  return 'カスタム';
}

// ----- 単純変換関数 -----

function toMinute(dt: Date, isEnd: boolean) {
  isEnd ? dt.setSeconds(59) : dt.setSeconds(0);
}

function toHour(dt: Date, isEnd: boolean) {
  toMinute(dt, isEnd);
  isEnd ? dt.setMinutes(59) : dt.setMinutes(0);
}

function toToday(dt: Date, isEnd: boolean) {
  toHour(dt, isEnd);
  isEnd ? dt.setHours(23) : dt.setHours(0);
}

function toMonth(dt: Date, isEnd: boolean) {
  toToday(dt, isEnd);
  if (isEnd) {
    dt.setMonth(dt.getMonth() + 1);
    dt.setDate(0);
  } else {
    dt.setDate(1);
  }
}

function toYear(dt: Date, isEnd: boolean) {
  toMonth(dt, isEnd);
  isEnd ? dt.setMonth(11) : dt.setMonth(0);
}

type WEEKDAY = 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';
const WEEKDAYS: WEEKDAY[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
function toWeekday(dt: Date, wd: WEEKDAY, isEnd: boolean) {
  // 当日に変換
  toToday(dt, isEnd);
  const target = WEEKDAYS.indexOf(wd);
  if (target !== -1) {
    while (target !== dt.getDay()) dt.setDate(dt.getDate() - 1);
  }
}
