// -- basic library --
import React, { useEffect, useState } from 'react';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import kurukuru from 'assets/kurukuru.gif';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import { Content, Footer } from 'shared/components/molecules/ContentsArea';
import { colors } from 'shared/styles/colors';
import styled from 'styled-components';
import { s3TransfersIdGetAPI } from 'user/api/s3Transfers';
import { StreamData, streamsIdDataNumberGetAPI } from 'user/api/streamsData';

interface Params {
  stream_id: string;
  s3_transfer_id: string;
  onClose: (p: {
    isCanceled?: boolean;
    isCreated?: boolean;
    isSettingProcess?: boolean;
    stream_data_number?: string | number;
  }) => void;
}
// -- main component --

const ProcessProvisioningDialog: React.FC<Params> = (params) => {
  // -- local state --
  const [stream_data_number, setStreamDataNumber] = useState<number>(-1);
  const [stream_data, setStreamData] = useState<StreamData | undefined>(undefined);

  // -- functions --

  // s3_transfer_idをもって、stream_data_numberを取得する
  // 30回まで取得し、それでも取得できない場合はエラーメッセージ
  const getStreamDataNumber = async () => {
    if (params.s3_transfer_id.length <= 0) return;
    // 30回s3Transferデータの取得を試みる
    let count = 30;
    let interval_id: ReturnType<typeof setInterval> | undefined = undefined;
    // 1秒間隔でs3transferデータを取得しようとする
    interval_id = setInterval(async () => {
      if (params.s3_transfer_id.length <= 0) return;
      if (count > 0) {
        const res_s3_transfer = await s3TransfersIdGetAPI({
          transfer_id: params.s3_transfer_id,
          disabled_error_message: count <= 0 ? false : true, // 最後だけエラーメッセージを表示する。それ以外はいちいちエラーメッセージは出さない
        });
        // s3TransferTableのデータを取得できれば、次のページに遷移する
        if (res_s3_transfer.status === 200) {
          setStreamDataNumber(res_s3_transfer.data.stream_data_number);
          clearInterval(interval_id);
          return;
        }
        count--;
      } else {
        clearInterval(interval_id);
        return;
      }
    }, 5000);
  };

  // -- onload function --

  // s3_transfer_idをもって、stream_data_numberを取得する。
  useEffect(() => {
    (async function () {
      // ストリームデータの番号の取得
      await getStreamDataNumber();
    })();
  }, []); /* eslint-disable-line */

  // stream_data_numberをもってstream_dataを取得する
  // stream_data?.is_ready_for_processがtrueになるまで取得し続ける
  useEffect(() => {
    let interval_id: ReturnType<typeof setInterval> | undefined = undefined;
    // stream_data_numberがまだ取得できていない場合はreturn
    if (stream_data_number <= 0) {
      return;
    }
    // プロセスの準備ができたらreturn
    if (stream_data?.is_ready_for_process) {
      return;
    }
    interval_id = setInterval(async () => {
      // プロセスの準備ができていれば取得処理を終了
      if (stream_data?.is_ready_for_process) {
        return false;
      }
      const res = await streamsIdDataNumberGetAPI({
        stream_id: params.stream_id,
        stream_data_number: stream_data_number,
      });
      if (res.status === 200) {
        setStreamData(res.data);
      }
      // プロセスの準備ができていればclearInterval
      if (res.data.is_ready_for_process) {
        clearInterval(interval_id);
        return false;
      }
    }, 5000); // 5秒ごとにデータ取得

    return () => {
      clearInterval(interval_id);
    };
  }, [stream_data, stream_data_number]); /* eslint-disable-line */

  // -- render part --
  return (
    <Content>
      {stream_data === undefined ? (
        <Spinner />
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            <h2>アップロードが完了しました。</h2>
            <h2 style={{ marginBottom: 45 }}>続けて、プロセスを設定しますか？</h2>
            {stream_data.is_ready_for_process ? (
              <Icon icon={IconNames.CONFIRM} iconSize={150} color={colors.component_main_color} />
            ) : (
              <Img src={kurukuru} alt='ファイル処理中の画像' />
            )}
            <h2>
              {stream_data.is_ready_for_process ? 'ファイル処理が完了しました' : 'ファイルの処理中...お待ちください'}
            </h2>
          </div>
          <Footer>
            <RoundedButton
              text='しない'
              onClick={() =>
                params.onClose({
                  isCanceled: true,
                  isCreated: true,
                })
              }
              is_white
              is_margin_right
            />
            <RoundedButton
              text='する'
              onClick={() =>
                params.onClose({
                  isCreated: true,
                  isSettingProcess: true,
                  stream_data_number: stream_data_number,
                })
              }
              disabled={!stream_data.is_ready_for_process}
            />
          </Footer>
        </>
      )}
    </Content>
  );
};

// -- styled components --

const Img = styled.img`
  width: 150px;
  height: auto;
  max-width: 100%;
  max-height: 100%;
`;

// -- finally export part --

export default ProcessProvisioningDialog;
