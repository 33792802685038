// -- basic library --
import React, { useState } from 'react';

// -- redux library --
import { useSelector } from 'react-redux';
import InputBox from 'shared/components/atoms/InputBox';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import StreamDataTypeRadioBox from 'shared/components/molecules/StreamDataTypeRadioBox';
import styles from 'shared/styles/styles';
import { isNotOnlySpace } from 'shared/utils/is';
import { channelsPostAPI } from 'user/api/channels';
import { convertFileStreamDataType, FileStreamDataType } from 'shared/models/FileStreamDataType';
import { systemSelector } from 'user/redux/slices/systemSlice';

interface Params {
  isOpen: boolean;
  onClose: (isCanceled: boolean) => void;
}

// -- main component --

const CreateFileProcessesDialog: React.FC<Params> = (params) => {
  // -- redux preparations --
  const system_state = useSelector(systemSelector);

  // 入力用states
  const [folder_name, setFolderName] = useState<string>('');
  const [file_stream_data_type, setFileStreamDataType] = useState<FileStreamDataType | undefined>('VIDEO'); // 'IMAGE' or 'VIDEO'

  // validate
  /**
   * 入力チェックを行い、エラーの場合はメッセージを含む例外をスローします。
   */
  const validate = () => {
    if (!isNotOnlySpace(folder_name)) {
      throw new Error('フォルダ名を入力してください');
    }
    if (!(file_stream_data_type === 'VIDEO' || file_stream_data_type === 'IMAGE')) {
      throw new Error('ストリーム形式は[画像ファイル, 動画ファイル]のいずれかを選択しなければなりません');
    }
  };

  // -- handlers --

  // 完了ボタンを押した時の処理
  const handleFinish = async () => {
    // 入力チェック
    try {
      validate();
    } catch (e) {
      if (e instanceof Error) {
        AlertDialog.show(e.message);
      }
      return;
    }

    // channelを作成するAPI
    channelsPostAPI({
      channel_name: folder_name,
      channel_type: 'FILE',
      file_stream_data_type: file_stream_data_type,
    }).then((res) => {
      //成功すれば完了
      if (res.status === 200) {
        AlertDialog.show('新規フォルダ追加に成功しました', () => {
          params.onClose(false);
        });
      }
    });
  };

  // -- render part --
  return (
    <PfDialog isOpen={params.isOpen} onClose={() => params.onClose(true)} title='フォルダ作成'>
      {system_state.loading.preloading ? (
        <Spinner />
      ) : (
        <>
          <InputComponent text='フォルダ名' required>
            <InputBox
              title='フォルダ名'
              placeholder='入力してください'
              value={folder_name}
              onChange={(e) => setFolderName(e.currentTarget.value)}
            />
          </InputComponent>
          <InputComponent text='データの形式' required>
            <StreamDataTypeRadioBox
              hide_metrics
              data_type={file_stream_data_type === undefined ? '' : file_stream_data_type}
              setDataType={(value: string) => setFileStreamDataType(convertFileStreamDataType(value, true))}
            />
          </InputComponent>
          <Footer>
            <RoundedButton text_type='CREATE' onClick={handleFinish} style={{ width: styles.small_button_width }} />
          </Footer>
        </>
      )}
    </PfDialog>
  );
};

// -- styled components --

// -- finally export part --

export default CreateFileProcessesDialog;
