// -- basic library --
import React from 'react';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableHeaderType, TableBodyUrlType, BaseTableProps } from 'shared/components/molecules/Table/type';

// テーブルの列の情報まとめたデータ
const headers: TableHeaderType[] = [
  {
    id: 'stream_name',
    label: 'データ名',
    width: `auto`,
    sortable: true,
    search_props: {
      type: 'name',
      default_display: true,
    },
  },
  {
    id: 'data_number_type',
    label: 'データ番号の基準',
    width: '15%',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'data_type',
    label: 'データの種類',
    width: '15%',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
];
// -- type declaration --

interface Params extends Omit<BaseTableProps, 'headers' | 'table_name'> {
  bodies: TableStreamPageType[];
  selected_bodies: TableStreamPageType[];
  handleCheckClick: (datas: TableStreamPageType[]) => void;
  footer_option: {
    text: string;
    handleClick: () => void;
    disabled?: boolean;
  };
}

export type TableStreamPageType = {
  id: string;
  stream_name: TableBodyUrlType;
  data_number_type: string;
  data_type: string;
  input_channel_event_conditions: string[];
  input_channel_processes: string[];
  output_channel_process: string;
};

// -- main component --
const StreamTable: React.FC<Params> = (params) => {
  // -- handlers --

  // -- render part --
  return <BaseTable {...params} headers={headers} table_name='streams' />;
};

// -- styled components --

// -- finally export part --
export default StreamTable;
