/* eslint-disable @typescript-eslint/no-explicit-any */
// -- http connection library --
import axios, { AxiosRequestConfig, CreateAxiosDefaults } from 'axios';
import { getAccessKey } from 'shared/utils/else/sessionStorageFunctions';
import { onReady, onSuccess, onError } from 'user/api/base/handlers';

// -- main axios base --
const getClient = (args: {
  responseType?: AxiosRequestConfig['responseType'];
  header_options?: CreateAxiosDefaults<any>['headers'];
  disabled_load?: boolean; // load処理を無効化できる
  disabled_error_message?: boolean; // エラーメッセージを無効化できる
  ended_load?: boolean; // load処理の終了動作を行う
}) => {
  const { responseType = 'json', header_options = {}, disabled_load, disabled_error_message, ended_load } = args;

  // -- authorized http client --
  const client = axios.create({
    headers: {
      'x-api-key': getAccessKey(),
      ...header_options,
    },
    responseType: responseType,
  });

  // -- set interceptors --
  client.interceptors.request.use((request) => onReady(request, disabled_load));
  client.interceptors.response.use(
    (response) => onSuccess(response, ended_load),
    (err) => onError(err, disabled_error_message),
  );
  // -- return part --
  return client;
};

export default getClient;
