import { PolarGraphData } from 'shared/components/molecules/PfGraph';

/**number[]の合計値を返却する **/
const sumArray = (arr: number[]): number => {
  try {
    return arr.reduce((accumulator, curr) => {
      return accumulator + curr;
    });
  } catch (e) {
    return 0;
  }
};

/**合計値を返却する **/
export const sumObject = (object: Record<string, number>): number => {
  try {
    let result = 0;
    Object.values(object).forEach((value) => {
      result += value;
    });
    return result;
  } catch (e) {
    return 0;
  }
};

/**PolarGraphData[]の合計値を返却する **/
export const sumPolarGraphData = (polar_graph_datas: PolarGraphData[]): number => {
  try {
    let result = 0;
    for (const pgd of polar_graph_datas) {
      result += Number(pgd.value);
    }
    return result;
  } catch (e) {
    return 0;
  }
};

export default sumArray;
