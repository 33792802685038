import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import InputBox from 'shared/components/atoms/InputBox';
import InputNumberBox from 'shared/components/atoms/InputNumberBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import AppParameterInput from 'user/components/molecules/AppParameterInput';
import { Content, Footer, SubTextBasicProcess, SubTextDetails } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import ProcessPanels from 'shared/components/molecules/ProcessPanels';
import RadioBox from 'shared/components/molecules/RadioBox';
import styles from 'shared/styles/styles';
import checkAppParameterRequired from 'user/utils/checkAppParameterRequired';
import { isNotOnlySpace, isValidDelaySeconds } from 'shared/utils/is';
import { channelsIdPutAPI, channelsPostAPI } from 'user/api/channels';
import { channelsIdProcessesPostAPI } from 'user/api/channelsProcesses';
import { Process, ProcessAppParameter, getAllProcesses } from 'user/api/processes';
import { CachedStreams, Stream } from 'user/api/streams';
import ChannelProcessHeartbeatPart from 'user/dialogs/CreateChannelProcessDialog/ChannelProcessHeartbeatPart';
import StreamDataRangeSelectionPart from 'user/dialogs/CreateChannelProcessDialog/StreamDataRangeSelectionPart';
import StreamSelectionPart from 'user/dialogs/CreateChannelProcessDialog/StreamSelectionPart';
import { systemSelector } from 'user/redux/slices/systemSlice';
import { AppParameter } from 'shared/models/AppParameter';
import { keyBy } from 'shared/utils/converter';
import { isISO8601, ISO8601 } from 'shared/models/ISO8601';
import { dateToTimeStamp } from 'shared/utils/converter/date';

// -- checkers --
const isCreateButtonDisabled = (params: {
  channel_name: string;
  channel_process_name: string;
  selectedProcess?: Process;
  selectedStreamIds: string[][];
  app_parameter: { [key: string]: string };
  process_app_parameters: ProcessAppParameter[];
  select_process_timing: string;
}) => {
  // 返却がtrueの時はdisabled = true
  // つまりボタンが押せない状態
  if (params.select_process_timing === 'now') {
    // ストリームが選択されていない
    if (params.selectedStreamIds.length !== params.selectedProcess?.input_stream_constraints.length) {
      return true;
    }
    for (const v of params.selectedStreamIds) {
      for (const w of v) {
        if (w === '') return true;
      }
    }
    return Boolean(
      !isNotOnlySpace(params.channel_name) ||
        !isNotOnlySpace(params.channel_process_name) ||
        !checkAppParameterRequired(params.app_parameter, params.process_app_parameters),
    );
  } else if (params.select_process_timing === 'after') {
    return Boolean(!isNotOnlySpace(params.channel_name));
  } else {
    return true;
  }
};

type SelectProcessTiming = 'now' | 'after';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isSelectProcessTiming = (data: any): data is SelectProcessTiming => data === 'now' || data === 'after';

interface Params {
  handleDialogCloseClick: () => void;
}

// -- main component --

const ChannelsCreateDialog = memo(function ChannelsCreateDialog(params: Params) {
  const system_state = useSelector(systemSelector);

  // -- local states --
  const [processes, setProcesses] = useState<Process[] | undefined>(undefined);
  const [streams, setStreams] = useState<Stream[] | undefined>(undefined);
  const [process_app_parameters, setProcessAppParameters] = useState<ProcessAppParameter[]>([]);
  const [select_process_timing, setSelectProcessTiming] = useState<SelectProcessTiming>('now');
  // 入力用states
  const [channel_name, setChannelName] = useState<string>('');
  const [channel_process_name, setChannelProcessName] = useState<string>('');
  const [selected_process, setSelectedProcess] = useState<Process | undefined>();
  const [selected_stream_ids, setSelectedStreamIds] = useState<string[][]>([]);
  const [app_parameter, setAppParameter] = useState<Record<string, string>>({});
  const [heartbeat, setHeartbeat] = useState<Record<string, string>>({});
  const [delay_seconds, setDelaySeconds] = useState<number>(0);
  const [is_target_specify, setTargetSpecify] = useState<boolean>(false);
  const [target_term_from, setTargetTermFrom] = useState<ISO8601 | ''>('');
  const [target_term_to, setTargetTermTo] = useState<ISO8601 | ''>('');

  // -- handlers --

  // プロセスが変わった時の関数
  const handleProcessChange = (process: Process) => {
    // プロセスに応じた入力データ(ストリーム)数とIDとapp_parameterの初期値を設定
    const app_parameter: AppParameter = {};
    let app_parameters: ProcessAppParameter[] = [];
    if (process) {
      app_parameters = process.app_parameters;
      for (let i = 0; i < process.app_parameters.length; i++) {
        const key = process.app_parameters[i].key;
        app_parameter[key] = process.app_parameters[i].default_value;
      }
    }
    setSelectedProcess(process);
    setAppParameter(app_parameter);
    setHeartbeat({});
    setProcessAppParameters(app_parameters);

    setSelectedStreamIds([]);
  };

  const handleStreamSelectionChange = (value: string[][]) => {
    setSelectedStreamIds(value);
  };

  //app_parameterがどれかでも変わった時の関数
  const handleSetAppParameter = (value: string, key: string) => {
    const new_app_parameter = { ...app_parameter };
    new_app_parameter[key] = value;
    setAppParameter(new_app_parameter);
  };

  // select_process_timgingが変わった時の関数
  const handleSelectProcessTimingChangeClick = (value: string) => {
    if (isSelectProcessTiming(value)) {
      setSelectProcessTiming(value);
    }
    // プロセス名以外のデータはすべて初期化する
    setChannelProcessName('');
    setSelectedProcess(undefined);
    setSelectedStreamIds([]);
    setAppParameter({});
    setProcessAppParameters([]);
  };

  const handleStreamDataRangeChange = (
    specify: boolean,
    stream_data_number_from?: ISO8601 | '',
    stream_data_number_to?: ISO8601 | '',
  ) => {
    setTargetSpecify(specify);
    if (stream_data_number_from !== undefined) {
      setTargetTermFrom(stream_data_number_from);
    }
    if (stream_data_number_to !== undefined) {
      setTargetTermTo(stream_data_number_to);
    }
  };

  // 完了ボタンを押した時の処理
  const handleFinish = async (streams: Stream[], app_parameters: ProcessAppParameter[]) => {
    if (!isNotOnlySpace(channel_name)) {
      AlertDialog.show('チャンネル名が空欄です');
      return;
    }
    // プロセスを選ぶタイミングが後からの場合はチャンネルのみを作って返す
    if (select_process_timing === 'after') {
      const res1 = await channelsPostAPI({
        channel_name: channel_name,
      });
      if (res1.status === 200) {
        params.handleDialogCloseClick();
        AlertDialog.show('新規チャンネルの追加に成功しました');
      }
      // 通信が成功しようが失敗しようが関数の処理は終了する
      return;
    }

    // チャンネルを選ぶタイミングが今の場合は通常通りの手順を踏む
    if (!isNotOnlySpace(channel_process_name)) {
      AlertDialog.show('入力欄に空欄があります');
      return;
    }
    // プロセスを後から設定する場合はプロセスに依存するAPI処理をスキップする
    if (!checkAppParameterRequired(app_parameter, app_parameters)) {
      AlertDialog.show('プロセスの詳細条件の入力が正しくありません');
      return;
    }
    if (!isValidDelaySeconds(delay_seconds)) {
      AlertDialog.show('配信の遅延を入力する場合は、0以上900以下の整数でなければなりません。※省略時は0となります。');
      return;
    }

    const res1 = await channelsPostAPI({
      channel_name: channel_name,
    });

    // app_parameterにおいて、必須でない and 入力がスペースのみの場合は送らないようにデータ整形
    const app_parameter_for_post: { [key: string]: string } = {};
    for (let i = 0; i < app_parameters.length; i++) {
      const key = app_parameters[i].key;
      // 存在しない or スペースならスキップ
      if (!app_parameter[key] || !isNotOnlySpace(app_parameter[key])) continue;
      // すでにバリデーション済みなので存在するデータは全て送る
      app_parameter_for_post[key] = app_parameter[key];
    }

    const res2 = await channelsIdProcessesPostAPI({
      channel_id: res1.data.channel_id,
      channel_process_name: channel_process_name,
      process_id: selected_process?.process_id || '',
      input_stream_ids: selected_stream_ids,
      app_parameter: app_parameter_for_post,
      heartbeat: heartbeat,
      delay_seconds: Number.isNaN(delay_seconds) ? undefined : delay_seconds,
      target_term_from:
        isTargetTermEnable() && isISO8601(target_term_from) ? dateToTimeStamp(target_term_from) : undefined,
      target_term_to: isTargetTermEnable() && isISO8601(target_term_to) ? dateToTimeStamp(target_term_to) : undefined,
    });

    const streams_record = keyBy(streams, (s: Stream) => s.stream_id);

    // ストリームデータの種類がIMAGEかVIDEOの最初のストリームIDをサムネイルに指定
    for (const stream_ids of selected_stream_ids) {
      for (const stream_id of stream_ids) {
        const stream = streams_record[stream_id];
        if (stream && (stream.data_type === 'IMAGE' || stream.data_type === 'VIDEO')) {
          await channelsIdPutAPI({
            channel_id: res1.data.channel_id,
            thumbnail_stream_id: stream_id,
          });
          break;
        }
      }
    }

    // res2の正常を持って完了と判定する
    if (res2.status === 200) {
      params.handleDialogCloseClick();
      AlertDialog.show('チャンネルの作成に成功しました');
    }
  };

  /** 最初の入力データ(ストリーム)がTIMESTAMPかどうか判定します */
  const isTargetTermEnable = () => {
    if (selected_stream_ids.length === 0) {
      return false;
    }
    for (const s of streams || []) {
      if (s.stream_id === selected_stream_ids[0][0]) {
        return s.data_number_type === 'TIMESTAMP';
      }
    }
    return false;
  };

  // -- onload function --
  useEffect(() => {
    (async function () {
      setProcesses(await getAllProcesses());
      // ストリーム一覧の取得
      setStreams(await new CachedStreams({ with_output_streams: 'True' }).get());
    })();
  }, []);

  // -- render part --
  return (
    <Content>
      {system_state.loading.preloading || streams === undefined || processes === undefined ? (
        <Spinner />
      ) : (
        <>
          <InputComponent text='チャンネル名' required>
            <InputBox
              title='チャンネル名'
              placeholder='入力してください'
              value={channel_name}
              onChange={(e) => setChannelName(e.currentTarget.value)}
            />
          </InputComponent>
          {select_process_timing === 'now' ? (
            <InputComponent text='チャンネルプロセス名' required>
              <InputBox
                title='チャンネルプロセス名'
                placeholder='入力してください'
                value={channel_process_name}
                onChange={(e) => setChannelProcessName(e.currentTarget.value)}
              />
            </InputComponent>
          ) : null}
          <InputComponent text='プロセス' required={select_process_timing === 'now'}>
            <RadioBox
              id='is_select_process'
              datas={[
                { name: 'ここで追加する', value: 'now' },
                { name: '後で追加する', value: 'after' },
              ]}
              selectedValue={select_process_timing}
              handleChangeClick={handleSelectProcessTimingChangeClick}
            />
            {select_process_timing === 'now' ? (
              <>
                <SubTextBasicProcess style={{ marginTop: styles.interval_margin }}>
                  基本プロセスを選択してください
                </SubTextBasicProcess>
                <ProcessPanels
                  processes={processes}
                  selected_id={selected_process?.process_id || ''}
                  onClick={handleProcessChange}
                />
              </>
            ) : null}
          </InputComponent>
          {select_process_timing === 'now' && selected_process && (
            <>
              <StreamSelectionPart
                process={selected_process}
                streams={streams}
                selected_stream_ids={selected_stream_ids}
                onChange={handleStreamSelectionChange}
              />
              {isTargetTermEnable() && (
                <StreamDataRangeSelectionPart
                  is_specify={is_target_specify}
                  stream_data_number_from={target_term_from}
                  stream_data_number_to={target_term_to}
                  onChange={handleStreamDataRangeChange}
                />
              )}
            </>
          )}
          {select_process_timing === 'now' && process_app_parameters.length !== 0 && (
            <InputComponent text='プロセスの詳細条件' required>
              <SubTextDetails>詳細条件を設定できます</SubTextDetails>
              {process_app_parameters.map((d, index) => {
                return (
                  <AppParameterInput
                    key={index}
                    process_app_parameter={d}
                    app_parameter={String(app_parameter[d.key])}
                    streams={streams.filter((v) => selected_stream_ids.flat().indexOf(v.stream_id) !== -1)}
                    onChange={(app_parameter_value) => handleSetAppParameter(app_parameter_value, d.key)}
                  />
                );
              })}
            </InputComponent>
          )}
          {select_process_timing === 'now' && selected_process && (
            <ChannelProcessHeartbeatPart
              process={selected_process}
              value={heartbeat}
              onChange={(v) => setHeartbeat(v)}
            />
          )}
          {selected_process && (
            <InputComponent text='配信の遅延'>
              <InputNumberBox value={delay_seconds} onChange={setDelaySeconds} min={0} max={900} />
            </InputComponent>
          )}

          <Footer>
            <RoundedButton
              text_type='CREATE'
              onClick={() => handleFinish(streams, process_app_parameters)}
              style={{ width: styles.small_button_width }}
              disabled={isCreateButtonDisabled({
                channel_name: channel_name,
                channel_process_name: channel_process_name,
                selectedProcess: selected_process,
                selectedStreamIds: selected_stream_ids,
                app_parameter: app_parameter,
                process_app_parameters: process_app_parameters,
                select_process_timing: select_process_timing,
              })}
            />
          </Footer>
        </>
      )}
    </Content>
  );
});

// -- styled components --

// -- finally export part --

export default ChannelsCreateDialog;
