export type Check = boolean | undefined;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isCheck = (data: any): data is Check => data === '既読' || data === '未読';

export const checkToJP = (check: Check) => {
  switch (check) {
    case true:
      return '既読';
    default:
      return '未読';
  }
};
