// ストリームデータのサムネイル画像を取得して表示します

import React, { ImgHTMLAttributes } from 'react';
import { Spinner } from '@blueprintjs/core';
import { streamsIdDataNumberThumbnailGetAPI } from 'user/api/streamsData';

interface StreamThumbnailImageProps {
  stream_id: string;
  stream_data_number: number;
}
interface StreamThumbnailImageState {
  thumbnail_blob?: string;
}
export default class StreamThumbnailImage extends React.PureComponent<
  StreamThumbnailImageProps & ImgHTMLAttributes<HTMLImageElement>,
  StreamThumbnailImageState
> {
  constructor(props: StreamThumbnailImageProps) {
    super(props);
    this.state = {
      thumbnail_blob: undefined,
    };
  }
  componentDidMount() {
    streamsIdDataNumberThumbnailGetAPI({
      stream_id: this.props.stream_id,
      stream_data_number: String(this.props.stream_data_number),
      stream_type: '',
    }).then((res) => {
      if (res.status === 200) {
        const blob = new Blob([res.data], { type: 'image/png' });
        const url = window.URL || window.webkitURL;
        this.setState({ thumbnail_blob: url.createObjectURL(blob) });
      }
    });
  }
  render() {
    return (
      <>
        {this.state.thumbnail_blob ? (
          <img src={this.state.thumbnail_blob} alt='' {...this.props} />
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </>
    );
  }
}
