// -- basic library --
import React, { useState } from 'react';
import InputBox from 'shared/components/atoms/InputBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import SelectBox from 'shared/components/atoms/SelectBox';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Content, Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import RadioBox from 'shared/components/molecules/RadioBox';
import styles from 'shared/styles/styles';
import { isUserType, UserType } from 'shared/models/UserType';
import { isNotOnlySpace, isValidEmailFormat, isMailAddressOptionally } from 'shared/utils/is';
import { RequestUsersPost, usersPostAPI } from 'user/api/users';
import { Role } from 'user/api/roles';
import PfDialog from 'shared/components/atoms/PfDialog';

// -- types --
interface Params {
  handleDialogCloseClick: () => void;
  roles?: Role[];
  is_open: boolean;
}
// -- main component --

const UsersCreateDialog: React.FC<Params> = ({ roles, handleDialogCloseClick, is_open }) => {
  // -- local states --
  // 入力用states
  const [login_id, setLoginId] = useState<string>('');
  const [user_name, setUserName] = useState<string>('');
  const [mail_address, setMailAddress] = useState<string | null>('');
  const [user_type, setUserType] = useState<UserType>('ADMIN');
  const [selected_role, setSelectedRole] = useState<string>('');

  const handleCancel = () => {
    handleDialogCloseClick();
  };

  // mail_addressをチェックする関数
  const handleMailAddressChangeClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value === '') {
      setMailAddress(null);
      return;
    }
    setMailAddress(e.currentTarget.value);
  };

  const handleFinish = async (inputs: {
    user_name: string;
    login_id: string;
    mail_address: string | null;
    user_type: string;
    role: string | undefined;
  }) => {
    if (!isNotOnlySpace(inputs.user_name) || !isNotOnlySpace(inputs.login_id)) {
      AlertDialog.show('ユーザー名とログインIDは必須入力です');
      return;
    }
    if (!isMailAddressOptionally(inputs.mail_address)) {
      AlertDialog.show(
        <div>
          メールアドレスは空白のみの時省略します。
          <br />
          入力する場合は正しいフォーマットで送信してください
        </div>,
      );
      return;
    }
    if (!isUserType(inputs.user_type)) {
      AlertDialog.show('ユーザータイプを選択してください');
      return;
    }
    const users_post_data: RequestUsersPost = {
      login_id: inputs.login_id,
      user_name: inputs.user_name,
      user_type: inputs.user_type,
      role_id: inputs.role,
    };
    if (
      inputs.mail_address !== null &&
      isNotOnlySpace(inputs.mail_address) &&
      isValidEmailFormat(inputs.mail_address)
    ) {
      users_post_data.mail_address = inputs.mail_address;
    }
    const res = await usersPostAPI(users_post_data);
    if (res.status === 200) {
      AlertDialog.show('ユーザーの作成に成功しました');
      handleDialogCloseClick();
    }
  };

  // -- render part --
  return (
    <PfDialog isOpen={is_open} onClose={handleCancel} title='ユーザー作成'>
      <Content>
        <InputComponent text='ログインID' required>
          <InputBox
            title='ログインID'
            placeholder='入力してください(必須)'
            value={login_id}
            onChange={(e) => setLoginId(e.currentTarget.value)}
          />
        </InputComponent>
        <InputComponent text='名前' required>
          <InputBox
            title='名前'
            placeholder='入力してください(必須)'
            value={user_name}
            onChange={(e) => setUserName(e.currentTarget.value)}
          />
        </InputComponent>
        <InputComponent text='メールアドレス'>
          <InputBox
            title='メールアドレス'
            placeholder='入力してください'
            value={mail_address === null ? '' : mail_address}
            onChange={handleMailAddressChangeClick}
          />
        </InputComponent>
        <InputComponent text='ユーザタイプ'>
          <RadioBox
            datas={[
              { name: '管理者', value: 'ADMIN' },
              { name: '一般', value: 'GENERAL' },
            ]}
            selectedValue={user_type}
            handleChangeClick={(value) => {
              if (isUserType(value)) {
                setUserType(value);
              }
            }}
          />
        </InputComponent>
        {user_type === 'GENERAL' && roles && (
          <InputComponent text='ロール'>
            <SelectBox
              onChange={(e) => setSelectedRole(e.currentTarget.value)}
              value={selected_role}
              datas={roles.map((role) => {
                return {
                  name: role.role_name,
                  value: role.role_id,
                };
              })}
            />
          </InputComponent>
        )}
        <Footer>
          <RoundedButton
            onClick={handleCancel}
            style={{ marginRight: styles.interval_narrow_margin }}
            text='キャンセル'
            is_white={true}
          />

          <RoundedButton
            onClick={() =>
              handleFinish({
                user_name: user_name,
                login_id: login_id,
                mail_address: mail_address,
                user_type: user_type,
                role: selected_role,
              })
            }
            disabled={Boolean(
              !isMailAddressOptionally(mail_address) || !isNotOnlySpace(login_id) || !isNotOnlySpace(user_name),
            )}
            text_type='CREATE'
          />
        </Footer>
      </Content>
    </PfDialog>
  );
};

// -- styled components --

// -- finally export part --

export default UsersCreateDialog;
