import { WidgetScaleType } from 'shared/models/WidgetScaleType';
import { DashboardWidgetMetric } from 'user/api/dashboardsWidgets';

/** 期間が日／月の場合に再集計します。 */
export function convertScale(data: DashboardWidgetMetric, scale: WidgetScaleType) {
  const month = scale === 'MONTH';
  // 期間（日／月）ごとのデータポイントの添え字の[開始, 終了]を取得
  const dpis: number[][] = [];
  let t = 0;
  data.datapoints.forEach((dp, i) => {
    const date = new Date(dp * 1000);
    const _t = date.getMonth() * 100 + (month ? 0 : date.getDate());
    if (t !== _t) {
      dpis.push([i, -1]);
      t = _t;
    }
    const dpi = dpis[dpis.length - 1];
    if (dpi) dpi[1] = i;
  });
  // 集計期間ごとの集計処理
  const _datapoints: number[] = []; // 変換後の新しいデータポイント
  const _metrics: DashboardWidgetMetric['metrics'] = data.metrics.map((m) => {
    return { metric_name: m.metric_name, statistic_method: m.statistic_method, data: [] };
  });
  dpis.forEach((dpi) => {
    // データポイントは開始値
    _datapoints.push(data.datapoints[dpi[0]]);
    // メトリクスデータは再集計
    data.metrics.forEach((m, i) => {
      const slice = m.data.slice(dpi[0], dpi[1] + 1).filter((v) => v !== undefined && v !== null);
      let value = 0;
      try {
        switch (m.statistic_method) {
          case 'Raw':
            value = 0; // Rawタイプはダッシュボードウィジェットで非サポート
            break;
          case 'Top':
            value = slice[0];
            break;
          case 'Minimum':
            value = Math.min(...slice);
            break;
          case 'Maximum':
            value = Math.max(...slice);
            break;
          case 'Sum':
            value = slice.reduce((p, c) => p + c);
            break;
          case 'Average':
            // 厳密にいうとこの平均計算法は誤差が生まれる
            // [TODO] 平均の元となった値の数を取る必要がある
            value = slice.reduce((p, c) => p + c) / slice.length;
            break;
          case 'Count':
            // Count の場合、APIが既にカウント値を返しているため、それを合計する
            value = slice.reduce((p, c) => p + c);
            break;
        }
      } catch (e) {
        console.log(e);
      }
      _metrics[i].data.push(value);
    });
  });
  data.datapoints = _datapoints;
  data.metrics = _metrics;
}
