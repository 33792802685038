import React, { useEffect, useState } from 'react';
import DatetimeInput from 'shared/components/atoms/DatetimeInput';
import InputBox from 'shared/components/atoms/InputBox';
import InputNumberBox from 'shared/components/atoms/InputNumberBox';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import SelectBox, { SelectData } from 'shared/components/atoms/SelectBox';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import RadioBox from 'shared/components/molecules/RadioBox';
import styles from 'shared/styles/styles';

import { isNotOnlySpace, isValidNumber, isBetweenRange } from 'shared/utils/is';
import { getSpecifiedMinute } from 'shared/utils/get';
import { CachedChannels, Channel } from 'user/api/channels';
import { devicesMoviedataPostAPI, RequestDevicesMoviedatasPost } from 'user/api/deviceMoviedatas';
import { CachedStreams } from 'user/api/streams';

interface Params {
  isOpen: boolean;
  onClose: (canceled?: boolean) => void;
  device_id: string;
}

type SelectSreaIdArea = 'in_device' | 'select';
/**
 * 録画予約設定ダイアログです。
 *
 * このダイアログでS+Cameraへの録画予約設定を行うことができます。
 */
const CreateMoviedataDialog: React.FC<Params> = (params) => {
  // 5分後の時刻を初期値に設定
  const [data_acquisition_at, setDataAcquisitionAt] = useState<string>(getSpecifiedMinute(300000));
  const [recording_time, setRecordingTime] = useState<number>(1);
  const [stream_id, setStreamId] = useState<string>('');
  const [stream_data_name, setStreamDataName] = useState<string>('');
  const [stream_id_names, setStreamIdNames] = useState<SelectData[]>([]);
  // デバイス内のストリーム(in_device)にするか、ここでストリームを選択(select)するか、
  const [stream_id_area, setStreamIdArea] = useState<SelectSreaIdArea>('in_device');
  const [cached_streams] = useState<CachedStreams>(
    new CachedStreams({
      with_output_streams: 'True',
      data_types: 'VIDEO,IMAGE',
    }),
  );
  const [cached_channels] = useState<CachedChannels>(
    new CachedChannels({
      channel_type: 'FILE',
    }),
  );

  const onStreamIdAreaChange = (value: string) => {
    if (value === 'in_device' || value === 'select') {
      setStreamIdArea(value);
    }
  };

  /**
   * 入力チェックを行い、エラーの場合はメッセージを含む例外をスローします。
   */
  const validate = () => {
    if (!isNotOnlySpace(data_acquisition_at) || Number(data_acquisition_at) < Number(getSpecifiedMinute(300000))) {
      throw new Error('録画開始日時を現在時刻+5分以降で入力してください');
    }
    if (!isValidNumber({ num: recording_time }) || !isBetweenRange(recording_time, 1, 60)) {
      throw new Error('録画時間を1~60の数字で入力してください');
    }
    if (stream_id_area === 'select' && !isNotOnlySpace(stream_id)) {
      throw new Error('登録するデータを選択してください');
    }
    if (!isNotOnlySpace(stream_data_name)) {
      throw new Error('ストリームデータ名を入力してください');
    }
  };

  const handleFinish = () => {
    // 入力チェック
    try {
      validate();
    } catch (e) {
      if (e instanceof Error) {
        AlertDialog.show(e.message);
      }
      return;
    }

    const request_bodies: RequestDevicesMoviedatasPost = {
      device_id: params.device_id,
      data_acquisition_at: data_acquisition_at,
      recording_time: recording_time,
      stream_data_name: stream_data_name,
    };
    // in_deviceの時は、stream_idは指定せず、use_device_stream_idを'True'に
    if (stream_id_area === 'in_device') {
      request_bodies.use_device_stream_id = 'True';
    } else if (stream_id_area === 'select') {
      // selectの時は、stream_idを指定する。
      request_bodies.stream_id = stream_id;
    }

    devicesMoviedataPostAPI(request_bodies).then((res) => {
      if (res.status === 200) {
        AlertDialog.show('プロセスの追加に成功しました', () => {
          params.onClose();
        });
      }
    });
  };

  // 指定のstreamsから、SelectData型のstream_id_namesを作成する
  const getStreamIdNames = async () => {
    // ストリーム一覧から、データを読み取る
    const streams = await cached_streams.get();
    const new_stream_id_names: SelectData[] = [];
    streams.forEach((stream) => {
      new_stream_id_names.push({
        name: stream.stream_name,
        value: stream.stream_id,
      });
    });

    // ファイル処理から、データを読み取る
    const channel_items: Channel[] = await cached_channels.get();
    channel_items.forEach((channnel) => {
      if (channnel.input_stream_ids[0]) {
        new_stream_id_names.push({
          name: channnel.channel_name,
          value: channnel.input_stream_ids[0],
        });
      }
    });
    setStreamIdNames(new_stream_id_names);
  };

  // -- onload function --
  useEffect(() => {
    (async function () {
      await getStreamIdNames();
    })();
  }, []); /* eslint-disable-line */

  return (
    <PfDialog isOpen={params.isOpen} onClose={() => params.onClose(true)} title='録画予約設定'>
      <InputComponent text='録画開始日時' required>
        <DatetimeInput
          title='録画開始日時'
          onChange={(e) => setDataAcquisitionAt(e.currentTarget.value)}
          value={data_acquisition_at}
        />
      </InputComponent>
      <InputComponent text='録画時間（分）' required>
        <InputNumberBox title='録画時間（分）' value={recording_time} onChange={setRecordingTime} min={1} max={60} />
      </InputComponent>
      <InputComponent text='登録するデータ' required>
        <RadioBox
          handleChangeClick={onStreamIdAreaChange}
          selectedValue={stream_id_area}
          datas={[
            {
              name: 'デバイス内のデータ',
              value: 'in_device',
            },
            {
              name: '選択する',
              value: 'select',
            },
          ]}
        />
        {stream_id_area === 'select' && (
          <SelectBox
            value={stream_id}
            onChange={(e) => setStreamId(e.currentTarget.value)}
            datas={stream_id_names}
            long={true}
            style={{
              marginTop: styles.interval_narrow_margin,
            }}
          />
        )}
      </InputComponent>
      <InputComponent text='ストリームデータ名' required>
        <InputBox
          title='ストリームデータ名'
          placeholder='入力してください'
          value={stream_data_name}
          onChange={(e) => setStreamDataName(e.currentTarget.value)}
        />
      </InputComponent>
      <Footer>
        <RoundedButton onClick={() => handleFinish()} text='作成' />
      </Footer>
    </PfDialog>
  );
};

export default CreateMoviedataDialog;
