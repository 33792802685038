import {
  AreaCountSetting,
  Metric,
  RequestDashboardsIdWidgetsNumPut,
  RequestDashboardsIdWidgetsPost,
  SumUpGraphSettings,
} from 'user/api/dashboardsWidgets';
import { isWidgetScaleType, WidgetScaleType } from 'shared/models/WidgetScaleType';
import { isAllOrNothing, isNumberStrUndefined } from 'shared/utils/is';
import checkMetrics from 'user/utils/checkMetrics';
import checkAreaCountSettings from 'user/utils/checkAreaCountSettings';
import { isNotOnlySpace } from 'shared/utils/is';
import { toNaNOrNumber } from 'shared/utils/converter';
import { DateString, dateStringToSecond, isDateString } from 'shared/models/DateString';
import { isTimeString, TimeString, timeStringToSecond } from 'shared/models/TimeString';
import { isWidgetType } from 'shared/models/WidgetType';

export interface WidgetPostValidatorProps {
  dashboard_id: string;
  dashboard_widget_name: string;
  widget_type: string;
  metrics?: Metric[];
  widget_setting: {
    y_min?: string;
    y_max?: string;
    start_date?: DateString;
    end_date?: DateString;
    start_time?: TimeString;
    end_time?: TimeString;
    scale?: WidgetScaleType;
  };
  html?: string;
  html_script?: string;
  graph_type?: string;
  jsonpath_group_id?: string;
  player_stream_id?: string;
  od_stream_id?: string;
  od_age?: boolean;
  od_gender?: boolean;
  hm_stream_id?: string;
  gp_stream_id?: string;
  ac_stream_id?: string;
  area_count_settings?: AreaCountSetting[];
  sum_up_settings?: SumUpGraphSettings;
}
/**
 * Widgetで[POST][PUT]データをバリデーションして返却
 * @param WidgetPostPutValidatorProps,is_post(POSTの時はtrue)
 * @return RequestDashboardsIdWidgetsPost
 */
export const widgetPostValidator = (params: WidgetPostValidatorProps): RequestDashboardsIdWidgetsPost => {
  const {
    dashboard_widget_name,
    widget_type,
    html,
    html_script,
    graph_type,
    jsonpath_group_id,
    player_stream_id,
    widget_setting,
    metrics,
    dashboard_id,
    od_stream_id,
    od_age,
    od_gender,
    hm_stream_id,
    gp_stream_id,
    ac_stream_id,
    area_count_settings,
    sum_up_settings,
  } = params;
  const { start_time, end_time, start_date, end_date, scale, y_min, y_max } = widget_setting;
  if (!isWidgetType(widget_type)) {
    throw new Error('ウィジェットタイプは必須です');
  }
  const request: RequestDashboardsIdWidgetsPost = {
    dashboard_id: dashboard_id,
    dashboard_widget_name: dashboard_widget_name,
    widget_type: widget_type,
    widget_setting: {
      start_date: isDateString(start_date) ? start_date : null,
      end_date: isDateString(end_date) ? end_date : null,
      start_time: isTimeString(start_time) ? start_time : null,
      end_time: isTimeString(end_time) ? end_time : null,
      scale: isWidgetScaleType(scale) ? scale : undefined,
    },
  };

  if (!isNotOnlySpace(dashboard_widget_name)) {
    throw new Error('ウィジェット名は必須です');
  }
  // 開始日時, 終了日時のバリデーション
  try {
    validateStartEndDate({ start_date, end_date });
  } catch (e) {
    if (e instanceof Error) throw e;
  }
  // 開始時刻, 終了時刻のバリデーション
  try {
    validateStartEndTime({ start_time, end_time });
  } catch (e) {
    if (e instanceof Error) throw e;
  }
  if (widget_type === 'GRAPH') {
    if (jsonpath_group_id === '') {
      throw new Error('JSONパスグループは必須です\n項目を選択してください');
    }
    if (gp_stream_id === '') {
      throw new Error('データは必須です\n項目を選択してください');
    }
    if (graph_type === '') {
      throw new Error('グラフタイプは必須です\n項目を選択してください');
    }
    request.jsonpath_group_id = jsonpath_group_id;
    request.gp_stream_id = gp_stream_id;
    request.graph_type = graph_type;
    if (metrics !== undefined) {
      if (!checkMetrics(widget_type, metrics)) {
        throw new Error('メトリクスは必須です\n全ての項目を入力・選択してください\n※JSONパスは$から始める');
      }
      request.metrics = metrics;
    }
    //y_minは整数
    if (!isNumberStrUndefined(y_min, true, true)) {
      throw new Error('y軸最小値を設定する場合は整数である必要があります。');
    }
    //y_maxは整数
    if (!isNumberStrUndefined(y_max, true, true)) {
      throw new Error('y軸最大値を設定する場合は整数である必要があります。');
    }
    const n_y_min = toNaNOrNumber(y_min);
    const n_y_max = toNaNOrNumber(y_max);
    //y_min < y_max
    if (!Number.isNaN(n_y_min) && !Number.isNaN(n_y_max) && n_y_min >= n_y_max) {
      throw new Error('[y軸最小値 ＜ y軸最大値]である必要があります。');
    }
    // 数字として存在するなら格納(整数判定は上で実施済み)
    if (!Number.isNaN(n_y_min)) {
      request.widget_setting = { ...request.widget_setting, y_min: n_y_min };
    }
    // 数字として存在するなら格納(整数判定は上で実施済み)
    if (!Number.isNaN(n_y_max)) {
      request.widget_setting = { ...request.widget_setting, y_max: n_y_max };
    }
  } else if (widget_type === 'LIVETEXT') {
    if (html === undefined || !isNotOnlySpace(html)) {
      throw new Error('HTMLは必須です');
    }
    request.html = html;
    request.html_script = html_script;
    if (metrics !== undefined) {
      if (!checkMetrics(widget_type, metrics)) {
        throw new Error('メトリクスは必須です\n全ての項目を入力・選択してください\n※JSONパスは$から始める');
      }
      // LIVETEXTの時はmetricsにはいらないデータ列があるので削除
      const removedMetrics = [];
      for (const m of metrics) {
        const { graph_type: _graph_type, accumulation: _accumulation, stack_id: _stack_id, ...rm } = m;
        removedMetrics.push(rm);
      }
      request.metrics = removedMetrics;
    }
  } else if (widget_type === 'VIDEOPLAYER') {
    if (typeof player_stream_id !== 'string' || !isNotOnlySpace(player_stream_id)) {
      throw new Error('データは必須です');
    }
    request.player_stream_id = player_stream_id;
  } else if (widget_type === 'BUSOD1' || widget_type === 'BUSOD2' || widget_type === 'BUSOD3') {
    if (typeof od_stream_id !== 'string' || !isNotOnlySpace(od_stream_id)) {
      throw new Error('データは必須です');
    }
    request.od_stream_id = od_stream_id;
    if (widget_type === 'BUSOD1' || widget_type === 'BUSOD2') {
      request.od_age = od_age;
      request.od_gender = od_gender;
    }
  } else if (widget_type === 'HEATMAP') {
    if (typeof hm_stream_id !== 'string' || !isNotOnlySpace(hm_stream_id)) {
      throw new Error('データは必須です');
    }
    request.hm_stream_id = hm_stream_id;
  } else if (widget_type === 'AREACOUNT') {
    if (graph_type === '') {
      throw new Error('グラフタイプは必須です\n項目を選択してください');
    }
    request.graph_type = graph_type;
    if (ac_stream_id === '') {
      throw new Error('データは必須です\n項目を選択してください');
    }
    request.ac_stream_id = ac_stream_id;
    if (metrics !== undefined) {
      if (!checkMetrics(widget_type, metrics)) {
        throw new Error('表示名、エリア名、カウント対象は必須です\n全ての項目を入力・選択してください');
      }
      request.metrics = metrics;
      request.area_count_settings = area_count_settings;
    }
  } else if (widget_type === 'SUMUPGRAPH') {
    if (graph_type === '') {
      throw new Error('グラフタイプは必須です\n項目を選択してください');
    }
    request.graph_type = graph_type;
    if (metrics !== undefined) {
      if (!checkMetrics(widget_type, metrics)) {
        throw new Error('全ての項目を入力・選択してください');
      }
      // SUMUPGRAPHの場合、sum_up_idは必須
      for (let i = 0; i < metrics.length; i++) {
        if (!metrics[i].sum_up_id) {
          throw new Error('合算IDは必須です');
        }
      }
      request.metrics = metrics;
      if (sum_up_settings !== undefined) {
        request.sum_up_settings = sum_up_settings;
      }
    }
  }
  return request as RequestDashboardsIdWidgetsPost;
};

export interface WidgetPutValidatorProps {
  dashboard_id: string;
  dashboard_widget_name: string;
  dashboard_widget_number: string | number;
  widget_type: string;
  metrics?: Metric[];
  widget_setting: {
    y_min?: string;
    y_max?: string;
    start_date?: DateString | null;
    end_date?: DateString | null;
    start_time?: TimeString | null;
    end_time?: TimeString | null;
    scale?: WidgetScaleType | null;
  };
  html?: string;
  html_script?: string;
  graph_type?: string;
  jsonpath_group_id?: string;
  player_stream_id?: string;
  od_stream_id?: string;
  od_age?: boolean;
  od_gender?: boolean;
  hm_stream_id?: string;
  gp_stream_id?: string;
  ac_stream_id?: string;
  area_count_settings?: AreaCountSetting[];
  sum_up_settings?: SumUpGraphSettings;
}
/**
 * Widgetで[PUT]データをバリデーションして返却
 * @param WidgetPostPutValidatorProps, is_post(POSTの時はtrue)
 * @returns WidgetPutValidatorProps
 */
export const widgetPutValidator = (params: WidgetPutValidatorProps): RequestDashboardsIdWidgetsNumPut => {
  const {
    dashboard_widget_name,
    dashboard_widget_number,
    widget_type,
    html,
    html_script,
    graph_type,
    jsonpath_group_id,
    player_stream_id,
    widget_setting,
    metrics,
    dashboard_id,
    od_stream_id,
    od_age,
    od_gender,
    hm_stream_id,
    gp_stream_id,
    ac_stream_id,
    area_count_settings,
    sum_up_settings,
  } = params;
  const { start_time, end_time, start_date, end_date, scale, y_min, y_max } = widget_setting;
  if (!isWidgetType(widget_type)) {
    throw new Error('ウィジェットタイプは必須です');
  }
  const request: RequestDashboardsIdWidgetsNumPut = {
    dashboard_id,
    dashboard_widget_name,
    dashboard_widget_number: String(dashboard_widget_number),
    widget_type: widget_type,
    widget_setting: {
      start_date: isDateString(start_date) ? start_date : null,
      end_date: isDateString(end_date) ? end_date : null,
      start_time: isTimeString(start_time) ? start_time : null,
      end_time: isTimeString(end_time) ? end_time : null,
      scale: isWidgetScaleType(scale) ? scale : null,
    },
    area_count_settings,
    sum_up_settings,
  };

  if (!isNotOnlySpace(dashboard_widget_name)) {
    throw new Error('ウィジェット名は必須です');
  }
  // 開始日時, 終了日時のバリデーション
  try {
    validateStartEndDate({ start_date, end_date });
  } catch (e) {
    if (e instanceof Error) throw e;
  }
  // 開始時刻, 終了時刻のバリデーション
  try {
    validateStartEndTime({ start_time, end_time });
  } catch (e) {
    if (e instanceof Error) throw e;
  }
  if (widget_type === 'GRAPH') {
    request.jsonpath_group_id = jsonpath_group_id;
    request.gp_stream_id = gp_stream_id;
    request.graph_type = graph_type;

    if (metrics !== undefined) {
      if (!checkMetrics(widget_type, metrics)) {
        throw new Error('メトリクスは必須です\n全ての項目を入力・選択してください\n※JSONパスは$から始める');
      }
      request.metrics = metrics;
    }

    //y_minは整数
    if (!isNumberStrUndefined(y_min, true, true)) {
      throw new Error('y軸最小値を設定する場合は整数である必要があります。');
    }
    //y_maxは整数
    if (!isNumberStrUndefined(y_max, true, true)) {
      throw new Error('y軸最大値を設定する場合は整数である必要があります。');
    }
    const n_y_min = toNaNOrNumber(y_min);
    const n_y_max = toNaNOrNumber(y_max);
    //y_min < y_max
    if (!Number.isNaN(n_y_min) && !Number.isNaN(n_y_max) && n_y_min >= n_y_max) {
      throw new Error('[y軸最小値 ＜ y軸最大値]である必要があります。');
    }
    // 数字として存在するなら格納(整数判定は上で実施済み)
    if (!Number.isNaN(n_y_min)) {
      request.widget_setting = { ...request.widget_setting, y_min: n_y_min };
    }
    // 数字として存在するなら格納(整数判定は上で実施済み)
    if (!Number.isNaN(n_y_max)) {
      request.widget_setting = { ...request.widget_setting, y_max: n_y_max };
    }
  } else if (widget_type === 'LIVETEXT') {
    if (html === undefined || !isNotOnlySpace(html)) {
      throw new Error('HTMLは必須です');
    }
    request.html = html;
    request.html_script = html_script;
    if (metrics !== undefined) {
      if (!checkMetrics(widget_type, metrics)) {
        throw new Error('メトリクスは必須です\n全ての項目を入力・選択してください\n※JSONパスは$から始める');
      }
      // LIVETEXTの時はmetricsにはいらないデータ列があるので削除
      const removedMetrics = [];
      for (const m of metrics) {
        const { graph_type: _graph_type, accumulation: _accumulation, stack_id: _stack_id, ...rm } = m;
        removedMetrics.push(rm);
      }
      request.metrics = removedMetrics;
    }
  } else if (widget_type === 'VIDEOPLAYER') {
    if (typeof player_stream_id !== 'string' || !isNotOnlySpace(player_stream_id)) {
      throw new Error('データは必須です');
    }
    request.player_stream_id = player_stream_id;
  } else if (widget_type === 'BUSOD1' || widget_type === 'BUSOD2' || widget_type === 'BUSOD3') {
    if (typeof od_stream_id !== 'string' || !isNotOnlySpace(od_stream_id)) {
      throw new Error('データは必須です');
    }
    request.od_stream_id = od_stream_id;
    if (widget_type === 'BUSOD1' || widget_type === 'BUSOD2') {
      request.od_age = od_age;
      request.od_gender = od_gender;
    }
  } else if (widget_type === 'HEATMAP') {
    if (typeof hm_stream_id !== 'string' || !isNotOnlySpace(hm_stream_id)) {
      throw new Error('データは必須です');
    }
    request.hm_stream_id = hm_stream_id;
  } else if (widget_type === 'AREACOUNT') {
    if (typeof ac_stream_id !== 'string' || !isNotOnlySpace(ac_stream_id)) {
      throw new Error('データは必須です');
    }
    request.ac_stream_id = ac_stream_id;
    if (graph_type === '') {
      throw new Error('グラフタイプは必須です\n項目を選択してください');
    }
    request.graph_type = graph_type;
    if (area_count_settings != undefined) {
      if (!checkAreaCountSettings(widget_type, area_count_settings)) {
        throw new Error('表示名、エリア名、カウント対象は必須です\n全ての項目を入力・選択してください');
      }
      request.area_count_settings = area_count_settings;
    }
    if (metrics !== undefined) {
      if (!checkMetrics(widget_type, metrics)) {
        throw new Error('内部エラーが発生しました\nもう一度項目を設定してください');
      }
      request.metrics = metrics;
    }
  } else if (widget_type === 'SUMUPGRAPH') {
    if (graph_type === '') {
      throw new Error('グラフタイプは必須です\n項目を選択してください');
    }
    request.graph_type = graph_type;
    if (metrics !== undefined) {
      if (!checkMetrics(widget_type, metrics)) {
        throw new Error('全ての項目を入力・選択してください\n※合算IDは必須です');
      }
      // SUMUPGRAPHの場合、sum_up_idは必須
      for (let i = 0; i < metrics.length; i++) {
        if (!metrics[i].sum_up_id) {
          throw new Error('合算IDは必須です');
        }
      }
      request.metrics = metrics;
      if (sum_up_settings !== undefined) {
        request.sum_up_settings = sum_up_settings;
      }
    }
  }
  return request;
};

export const validateStartEndDate = (params: { start_date?: DateString | null; end_date?: DateString | null }) => {
  const { start_date, end_date } = params;
  if (!isAllOrNothing(start_date, end_date)) {
    throw new Error('[開始日時, 終了日時]を入力する場合は両方入力してください');
  }
  if (isDateString(start_date) && isDateString(end_date)) {
    // 開始日時 < 終了日時 チェック(両方存在するときだけ)
    if (dateStringToSecond(start_date) > dateStringToSecond(end_date)) {
      throw new Error('[開始日時 <= 終了日時]である必要があります。');
    }
  }
  return { start_date, end_date };
};

export const validateStartEndTime = (params: { start_time?: TimeString | null; end_time?: TimeString | null }) => {
  const { start_time, end_time } = params;
  if (!isAllOrNothing(start_time, end_time)) {
    throw new Error('[開始時刻, 終了時刻]を入力する場合は両方入力してください');
  }
  // 開始時刻 < 終了時刻 チェック(両方存在するときだけ)
  if (isTimeString(start_time) && isTimeString(end_time)) {
    if (timeStringToSecond(start_time) > timeStringToSecond(end_time)) {
      throw new Error('[開始時刻 <= 終了時刻]である必要があります。');
    }
  }
  return { start_time, end_time };
};
