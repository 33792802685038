// -- basic library --
import React, { useCallback, useEffect, useState } from 'react';

// -- redux library --

// -- http connection library --

// -- external components --
import { Spinner } from '@blueprintjs/core';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import { Content, Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';

// -- external functions --
import styles from 'shared/styles/styles';
import PfDialog from 'shared/components/atoms/PfDialog';
import { notificationsIdGetAPI } from 'user/api/notifications';
import { Notification } from 'user/api/notifications';
import MarkdownText from 'shared/components/molecules/MarkdownText';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { notificationsIdCheckPostAPI } from 'user/api/notificationsCheck';
import { resetNotificationSessionStorage } from 'shared/utils/else/sessionStorageFunctions';
import styled from 'styled-components';
import { dateToYMD } from 'shared/utils/converter/date';

// -- types --
type Params = {
  notification_id: string;
  check?: boolean;
  is_open: boolean;
  handleDialogCloseClick: (canceled?: boolean) => void;
};

// -- main component --

const DetailNotifications: React.FC<Params> = ({ notification_id, check, is_open, handleDialogCloseClick }) => {
  // -- local states --;
  const [notification, setNotification] = useState<Notification>();

  const loadInitialData = useCallback(async () => {
    const res = await notificationsIdGetAPI({ notification_id });
    if (res.status === 200) {
      setNotification(res.data);
    }
  }, [notification_id]);

  const handleCheckClick = async () => {
    const res = await notificationsIdCheckPostAPI({
      notification_id: notification_id,
    });
    if (res.status === 200) {
      resetNotificationSessionStorage();
      AlertDialog.show('既読にしました', () => window.location.reload());
      handleDialogCloseClick();
    }
  };

  // -- onload function --
  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  // -- render part --
  return (
    <PfDialog isOpen={is_open} onClose={() => handleDialogCloseClick(true)} title='お知らせ詳細'>
      <Content>
        {notification ? (
          <>
            <TitleArea>
              [{dateToYMD(notification.show_date)}] {notification.title}
            </TitleArea>
            <InputComponent text=''>
              <MarkdownText content={notification.content} />
            </InputComponent>
            <Footer>
              <RoundedButton
                onClick={() => handleCheckClick()}
                style={{ marginRight: styles.interval_narrow_margin }}
                text='既読にする'
                disabled={check}
              />
            </Footer>
          </>
        ) : (
          <Spinner />
        )}
      </Content>
    </PfDialog>
  );
};

// -- styled components --
const TitleArea = styled.div`
  display: block;
  align-items: center;
  margin-bottom: ${styles.interval_margin};
  font-family: inherit;
  font-weight: ${styles.font_weight};
  font-size: ${styles.normal_text_size};
`;

// -- finally export part --

export default DetailNotifications;
