// -- basic library --
import React from 'react';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import styled from 'styled-components';

// 選択パネル１枚
export class SelectPanel extends React.Component<{
  id: string;
  selected_id: string;
  text: string;
  img_src?: string;
  handleClick: (id: string) => void;
}> {
  public render() {
    return (
      <WholeArea
        onClick={() => this.props.handleClick(this.props.id)}
        selected={this.props.selected_id === this.props.id}
        key={this.props.id}
      >
        {this.props.img_src ? <Img src={this.props.img_src} alt='' /> : null}
        <Text title={this.props.text}>{this.props.text}</Text>
      </WholeArea>
    );
  }
}
const WholeArea = styled.div<{
  selected?: boolean;
}>`
  width: 110px;
  min-width: 110px;
  height: 120px;
  border: ${(params) =>
    params.selected ? `4px solid ${colors.component_main_color}` : `1px solid ${colors.component_small_border_color}`};
  margin-right: ${styles.interval_margin};
  margin-bottom: ${styles.interval_margin};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Text = styled.div`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: ${styles.normal_text_size};
  color: ${colors.component_main_color};
`;

const Img = styled.img`
  width: 40px;
  height: auto;
  margin-bottom: ${styles.interval_narrow_margin};
`;

// -- type declaration --

interface Params {
  selected_id: string;
  display_datas: { id: string; text: string; img_src?: string }[];
  handleClick: (id: string) => void;
  style?: React.CSSProperties;
}

// -- main component --

const SelectPanelArea: React.FC<Params> = (params) => {
  // -- render part --
  return (
    <SelectPanelWholeArea style={params.style}>
      {params.display_datas.map((display_data, index) => (
        <SelectPanel
          id={display_data.id}
          text={display_data.text}
          img_src={display_data.img_src}
          handleClick={params.handleClick}
          selected_id={params.selected_id}
          key={index}
        />
      ))}
    </SelectPanelWholeArea>
  );
};

// -- styled components --

// プロセスパネルの全体領域
const SelectPanelWholeArea = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

// -- finally export part --

export default SelectPanelArea;
