import { colors } from 'shared/styles/colors';
import styled from 'styled-components';

export const PIOutput = styled.ul`
  width: 100%;
  margin: 0px;
`;

export const PIOutputStream = styled.li<{ selected?: boolean }>`
  margin-bottom: 5px;
  height: 15px;
  cursor: pointer;
  color: ${({ selected }) => (selected ? colors.component_main_color : 'inherit')};
  &:hover {
    text-decoration: underline;
  }
`;
