import { memo } from 'react';
import dashboard_svg from 'assets/dashboard.svg';
import event_svg from 'assets/event.svg';
import notification_svg from 'assets/notification.svg';
import stream_svg from 'assets/stream.svg';
import file_process_svg from 'assets/task-tray.svg';
import { useSelector } from 'react-redux';
import PfSideMenu, { SideMenuItem } from 'shared/components/molecules/PfSideMenu';
import { authSelector } from 'user/redux/slices/authSlice';

const side_menu_items: SideMenuItem[] = [
  {
    id: 'notifications',
    text: 'お知らせ',
    img_src: notification_svg,
  },
  {
    id: 'dashboards',
    text: 'ダッシュボード',
    img_src: dashboard_svg,
  },
  {
    id: 'events',
    text: 'アラート',
    img_src: event_svg,
  },
  {
    id: 'streams',
    text: 'データ',
    img_src: stream_svg,
    custom_url: '/streams?tabId=metric'
  },
  {
    id: 'file-processes',
    text: 'ファイル処理',
    img_src: file_process_svg,
    for_admin: true,
  },
];
/**
 * [ユーザ画面]サイドメニュー
 */
export const PfUserSideMenu = memo(function PfUserSideMenu() {
  const auth_state = useSelector(authSelector);
  return <PfSideMenu default_id='dashboards' side_menu_items={side_menu_items} is_admin_user={auth_state.is_admin} />;
});
