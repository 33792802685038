// -- basic library --
import React, { useCallback, useEffect, useState } from 'react';

// -- redux library --
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import history from 'shared/browserHistory';

// -- external components --
import { PageWholeArea, Title } from 'shared/components/molecules/ContentsArea';
import PageSwitchingTabs from 'shared/components/molecules/PageSwitchingTabs';
import styles from 'shared/styles/styles';
import { Channel, channelsIdGetAPI } from 'user/api/channels';
import { systemSelector } from 'user/redux/slices/systemSlice';

// -- external datas --

// -- external functions --
import { preloadGroup } from 'user/utils/preloadGroup';
import ChannelsIdDetailEventConditions from './ChannelsIdDetailEventConditions';
import ChannelsIdDetailInputStreams from './ChannelsIdDetailInputStreams';
import ChannelsIdDetailOutputStreams from './ChannelsIdDetailOutputStreams';
import ChannelsIdDetailOverView from './ChannelsIdDetailOverView';
import ChannelsIdDetailsProcesses from './ChannelsIdDetailsProcesses';

// -- main component --
const ChannelsDetail: React.FC<
  RouteComponentProps<{
    channel_id: string;
    panel_id?: string;
  }>
> = (params) => {
  const system_state = useSelector(systemSelector);

  // -- local states --
  const [channel, setChannel] = useState<Channel | null>(null);

  // -- functions --
  const handleTabsClick = (tab_id: string, channel_id: string) => {
    history.push(`/channels/${channel_id}/${tab_id}`);
  };

  const loadChannel = useCallback(async () => {
    // チャンネル情報を取得
    const res = await channelsIdGetAPI({ channel_id: params.match.params.channel_id });
    if (res.status === 200) {
      setChannel(res.data);
    }
  }, [params.match.params.channel_id]);

  // -- onload function --
  useEffect(() => {
    preloadGroup(
      (async function () {
        await loadChannel();
      })(),
    );
  }, [loadChannel]);

  // -- render part --
  return (
    <PageWholeArea data-testid='ChannelDetail'>
      <Title
        text='チャンネルの詳細'
        style={{
          marginTop: styles.interval_narrow_margin,
          marginBottom: styles.interval_margin,
        }}
      />
      {!system_state.loading.preloading && channel && (
        <React.Fragment>
          <PageSwitchingTabs
            display_datas={[
              { id: 'overviews', text: 'チャンネル概要' },
              { id: 'processes', text: 'プロセス定義' },
              { id: 'event-conditions', text: 'イベント定義' },
              { id: 'input-streams', text: '入力データ' },
              { id: 'output-streams', text: '出力データ' },
            ]}
            handleClick={(id: string) => handleTabsClick(id, params.match.params.channel_id)}
            selected_id={params.match.params.panel_id}
            initial_selected_id='overviews'
          />
          <Switch>
            <Route
              exact
              path='/channels/:channel_id/overviews'
              render={(props) => <ChannelsIdDetailOverView {...props} />}
            />
            <Route
              exact
              path='/channels/:channel_id/processes'
              render={(props) => <ChannelsIdDetailsProcesses {...props} />}
            />
            <Route
              exact
              path='/channels/:channel_id/event-conditions'
              render={(props) => <ChannelsIdDetailEventConditions {...props} />}
            />
            <Route
              exact
              path='/channels/:channel_id/input-streams'
              render={(props) => <ChannelsIdDetailInputStreams {...props} />}
            />
            <Route
              exact
              path='/channels/:channel_id/output-streams'
              render={(props) => <ChannelsIdDetailOutputStreams {...props} />}
            />
          </Switch>
        </React.Fragment>
      )}
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default ChannelsDetail;
