// -- basic library --
import React from 'react';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableHeaderType, TableBodyUrlType } from 'shared/components/molecules/Table/type';

interface Params {
  bodies: TableDashbordsType[];
  selected_bodies: TableDashbordsType[];
  handleCheckClick: (datas: TableDashbordsType[]) => void;
  footer_option: {
    text: string;
    handleClick: () => void;
    disabled?: boolean;
  };
}

export type TableDashbordsType = {
  id: string;
  dashboard_name: TableBodyUrlType;
  created_at: string;
  updated_at: string;
  creator_user_name: string;
};

// -- external functions --
// テーブルの列の情報まとめたデータ
const headers: TableHeaderType[] = [
  {
    id: 'dashboard_name',
    label: 'ダッシュボード名',
    width: 'auto',
    sortable: true,
    search_props: {
      type: 'name',
      default_display: true,
    },
  },
  {
    id: 'created_at',
    label: '作成日時',
    width: '18%',
    sortable: true,
    search_props: {
      type: 'datetime',
    },
  },
  {
    id: 'updated_at',
    label: '更新日時',
    width: '18%',
    sortable: true,
    search_props: {
      type: 'datetime',
    },
  },
  {
    id: 'creator_user_name',
    label: '作成者',
    width: '12%',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
];

// -- main component --
const DashboardsTable: React.FC<Params> = (params) => {
  // -- render part --
  return (
    <BaseTable
      bodies={params.bodies}
      headers={headers}
      table_name='dashboards'
      selected_bodies={params.selected_bodies}
      handleCheckClick={params.handleCheckClick}
      id_abridgement
      footer_option={params.footer_option}
    />
  );
};

// -- styled components --

// -- finally export part --
export default DashboardsTable;
