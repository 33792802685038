import React, { useState, useEffect, memo } from 'react';
import browserHistory from 'shared/browserHistory';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import { FlexCenterDiv, TopButtonArea } from 'shared/components/molecules/ContentsArea';
import { PolarGraphData } from 'shared/components/molecules/PfGraph';
import RoundedWidgetCard from 'shared/components/molecules/RoundedGraphCard';
import { WidgetScaler } from 'shared/components/molecules/WidgetScaler';
import styles from 'shared/styles/styles';
import { WidgetScaleType } from 'shared/models/WidgetScaleType';
import FeatureDatetime from 'shared/models/FeatureDatetime';
import { getDateLabel } from 'shared/utils/get';
import { getWidgetRequestInfos } from 'shared/utils/get';
import { getStreamGraphTitle } from 'shared/models/StreamDeviceGraph';
import mergePolarGraphs from 'shared/utils/else/mergePolarGraphs';
import { Device } from 'user/api/device';
import { StreamDeviceGraph } from 'shared/models/StreamDeviceGraph';
import { DatapointItem, StreamsGraphsDataGetResponse, streamsIdGraphsDatGetAPI } from 'user/api/streamsGraphs';
import DatetimeRangeDialog from 'user/dialogs/DatetimeRangeDialog';
import GraphPart from 'user/pages/Devices/DetailPage/CameraAnalysisResultPage/GraphPart';
import { streamDeviceGraphTypeToText } from 'shared/models/StreamDeviceGraphType';

// -- external functions --

interface DevicesGridCardProps {
  device: Device;
}

// -- main component --

const DevicesGridCard = memo(function DevicesGridCard(props: DevicesGridCardProps) {
  // -- local states --
  const [graph_data, setGraphData] = useState<DatapointItem[] | PolarGraphData[] | undefined>(undefined);
  const [stream_graph, setStreamGraph] = useState<StreamDeviceGraph | null | undefined>(undefined);
  const [start, setStart] = React.useState<FeatureDatetime>(new FeatureDatetime('today'));
  const [end, setEnd] = React.useState<FeatureDatetime>(new FeatureDatetime('today'));
  const [scale, setScale] = React.useState<WidgetScaleType>('HOUR');
  const [select_date_dialog, setSelectDateDialog] = React.useState<boolean>(false);
  // -- functions --

  /** グラフのデータを取得し、読み込む関数**/
  const loadGraphData = async (p: {
    device: Device;
    scale: WidgetScaleType;
    start: FeatureDatetime;
    end: FeatureDatetime;
  }) => {
    // stream_device_graph_idとgraph_stream_idが存在しない場合は、return
    if (!p.device.stream_device_graph_id || !p.device.graph_stream_id) {
      setGraphData([]);
      setStreamGraph(null);
      return;
    }
    // 初期化
    setGraphData(undefined);
    const request_infos = getWidgetRequestInfos(p.start.getTime(false), p.end.getTime(true), p.scale);

    let new_graph_data: StreamsGraphsDataGetResponse['items'] = [];
    let new_stream_graph: StreamDeviceGraph | null | undefined = undefined;
    for (const ri of request_infos) {
      const res = await streamsIdGraphsDatGetAPI({
        stream_device_graph_id: p.device.stream_device_graph_id,
        stream_id: p.device.graph_stream_id,
        period: ri.period,
        start: ri.start + '',
        end: ri.end + '',
      });
      if (res.status === 200) {
        if (res.data.graph_type === 'BAR') {
          new_graph_data = [...(new_graph_data as DatapointItem[]), ...(res.data.items as DatapointItem[])];
        } else if (res.data.graph_type === 'CIRCLE') {
          new_graph_data = mergePolarGraphs(new_graph_data as PolarGraphData[], res.data.items as PolarGraphData[]);
        } else if (res.data.graph_type === 'TABLE') {
          new_graph_data = [...(new_graph_data as DatapointItem[]), ...(res.data.items as DatapointItem[])];
        }
        if (new_stream_graph === undefined) {
          new_stream_graph = {
            id: p.device.stream_device_graph_id,
            target: res.data.target,
            direction: res.data.direction,
            line_number: res.data.line_number,
            graph_type: res.data.graph_type,
          };
        }
      }
    }
    setStreamGraph(new_stream_graph);
    setGraphData(new_graph_data);
  };

  // 詳細画面に遷移する
  const onDetailClick = (device_id: string) => {
    browserHistory.push(`/devices/${device_id}/camera-analysis-result`);
  };

  // scaleを変更する関数
  const onScaleChanged = async (rangeType: WidgetScaleType) => {
    setScale(rangeType);
    await loadGraphData({
      device: props.device,
      scale: rangeType,
      start: start,
      end: end,
    });
  };

  // 日付を選択するダイアログを開く関数
  const onSelectDateDialogOpen = () => {
    setSelectDateDialog(true);
  };

  // 日付を選択するダイアログを閉じる際の関数
  const onSelectDateDialogClose = async (isCanceled: boolean, start?: FeatureDatetime, end?: FeatureDatetime) => {
    setSelectDateDialog(false);
    if (isCanceled || !start || !end) return;
    setStart(start);
    setEnd(end);
    await loadGraphData({
      device: props.device,
      scale: scale,
      start: start,
      end: end,
    });
  };

  // stream_graphに応じたelementを取得する
  const getElm = (stream_graph: StreamDeviceGraph | null | undefined) => {
    switch (stream_graph) {
      case undefined:
        return <Spinner />;
      case null:
        return <DammyGridCard />;
      default:
        return (
          <>
            <FlexCenterDiv align_center style={{ width: '100%', height: 20, margin: `2px 0px` }}>
              <FlexCenterDiv align_center style={{ width: '100%', height: '100%' }}>
                {getStreamGraphTitle(stream_graph)}
              </FlexCenterDiv>
              <RoundedButton
                text='リロード'
                onClick={() =>
                  loadGraphData({
                    device: props.device,
                    scale: scale,
                    start: start,
                    end: end,
                  })
                }
                style={{ width: 120, height: '100%', marginRight: styles.interval_x_narrow_margin, fontSize: '0.8em' }}
              />
            </FlexCenterDiv>
            <TopButtonArea>
              <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={onSelectDateDialogOpen}>
                {getDateLabel(start, end)}
              </span>
              {stream_graph?.graph_type !== 'TABLE' && <WidgetScaler value={scale} onChange={onScaleChanged} />}
            </TopButtonArea>

            <div style={{ width: '100%', height: 'calc(100% - 20px - 20px - 2 * 2px - 36px - 2 * 2px)' }}>
              <GraphPart type={streamDeviceGraphTypeToText(stream_graph.graph_type)} graph_data={graph_data} />
            </div>
            {select_date_dialog && <DatetimeRangeDialog start={start} end={end} onClose={onSelectDateDialogClose} />}
          </>
        );
    }
  };

  // -- onload function --
  useEffect(() => {
    (async function () {
      await loadGraphData({
        device: props.device,
        scale: scale,
        start: start,
        end: end,
      });
    })();
  }, [scale, start, end, props.device]);

  return (
    <RoundedWidgetCard large={stream_graph?.graph_type === 'TABLE'}>
      <div style={{ width: '100%', height: '100%' }}>
        <FlexCenterDiv align_center style={{ width: '100%', height: 20 }}>
          <FlexCenterDiv align_center style={{ width: '100%', height: '100%' }}>
            {props.device.device_name}
          </FlexCenterDiv>
          <RoundedButton
            text='詳細'
            onClick={() => onDetailClick(props.device.device_id)}
            style={{ width: 70, height: '100%', marginRight: styles.interval_x_narrow_margin, fontSize: '0.8em' }}
          />
        </FlexCenterDiv>
        {getElm(stream_graph)}
      </div>
    </RoundedWidgetCard>
  );
});

// -- finally export part --

export default DevicesGridCard;

const DammyGridCard = memo(function DammyGridCard() {
  return (
    <div style={{ display: 'block', width: '100%', height: '100%' }}>
      <FlexCenterDiv align_center={true} justufy_center={true} style={{ width: '100%', height: 'calc(100% - 20px)' }}>
        表示するグラフがピンづけされていません
      </FlexCenterDiv>
    </div>
  );
});
