import React from 'react';
import { Icon } from '@blueprintjs/core';
import { IconName } from '@blueprintjs/icons';
import { colors } from 'shared/styles/colors';
import styled from 'styled-components';

interface PaneBoxProps {
  paneTitle?: string;
  paneTitleIcons?: {
    name: string;
    icon: IconName;
    onClick: (name: string) => void;
  }[];
}
export default function PaneBox(props: PaneBoxProps & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <StyledPaneBox {...props}>
      {props.paneTitle && (
        <StyledPaneTitle>
          <div style={{ flexGrow: 1 }}>{props.paneTitle}</div>
          {props.paneTitleIcons && (
            <div style={{ cursor: 'pointer' }}>
              {props.paneTitleIcons.map((e, i) => {
                return <Icon key={i} icon={e.icon} onClick={() => e.onClick(e.name)} />;
              })}
            </div>
          )}
        </StyledPaneTitle>
      )}
      <div style={{ flexGrow: 1, margin: '2px' }}>{props.children}</div>
    </StyledPaneBox>
  );
}

const StyledPaneBox = styled.div`
  background-color: ${colors.dark_gray};
  border: 1px solid rgb(145, 145, 145);
  display: flex;
  flex-direction: column;
`;

const StyledPaneTitle = styled.div`
  background-color: ${colors.gray};
  border: 1px solid rgb(145, 145, 145);
  display: flex;
  margin: 1px;
`;
