import React, { useCallback } from 'react';
import FunctionalText from 'shared/components/atoms/FunctionalText';
import InputComponent from 'shared/components/molecules/InputComponent';
import { WidgetScaler } from 'shared/components/molecules/WidgetScaler';
import styles from 'shared/styles/styles';
import { DateString, isDateString } from 'shared/models/DateString';
import { isTimeString, TimeString, toTimeString } from 'shared/models/TimeString';
import { WidgetScaleType } from 'shared/models/WidgetScaleType';
import { assertNever } from 'shared/utils/else/assertNever';
import InputDoubleBox from './InputDoubleBox';

export interface WidgetFixedDatePanelProps {
  start_date: DateString | null;
  end_date: DateString | null;
  start_time: TimeString;
  end_time: TimeString;
  scale: WidgetScaleType | null;
  setStartDate(start_date: DateString | null): void;
  setEndDate(end_date: DateString | null): void;
  setStartTime(start_time: TimeString): void;
  setEndTime(end_time: TimeString): void;
  setScale?(scale: WidgetScaleType | null): void;
  hide_scale?: boolean;
}
type DateTimePropety = 'start_date' | 'end_date' | 'start_time' | 'end_time';
/** ウィジェットの日時固定設定を行うパネル*/
export const WidgetFixedDateSettingPanel = (props: WidgetFixedDatePanelProps) => {
  const {
    start_date,
    end_date,
    start_time,
    end_time,
    scale,
    setStartDate,
    setEndDate,
    setStartTime,
    setEndTime,
    setScale,
    hide_scale,
  } = props;

  const onReset = useCallback(() => {
    setStartDate(null);
    setEndDate(null);
    setStartTime(toTimeString('00:00'));
    setEndTime(toTimeString('23:59'));
    setScale?.(null);
  }, [setStartDate, setEndDate, setStartTime, setEndTime, setScale]);

  const onDateTimeChange = useCallback(
    (property: DateTimePropety) => (e: React.ChangeEvent<HTMLInputElement>) => {
      switch (property) {
        case 'start_date':
          if (isDateString(e.currentTarget.value)) {
            setStartDate(e.currentTarget.value);
          }
          break;
        case 'end_date':
          if (isDateString(e.currentTarget.value)) {
            setEndDate(e.currentTarget.value);
          }
          break;
        case 'start_time':
          if (isTimeString(e.currentTarget.value)) {
            setStartTime(e.currentTarget.value);
          }
          break;
        case 'end_time':
          if (isTimeString(e.currentTarget.value)) {
            setEndTime(e.currentTarget.value);
          }
          break;
        default:
          assertNever(property);
          break;
      }
    },
    [setStartDate, setEndDate, setStartTime, setEndTime],
  );

  return (
    <>
      <InputComponent text='日付'>
        <InputDoubleBox
          value1={start_date ?? ''}
          value2={end_date ?? ''}
          handleChangeClick1={onDateTimeChange('start_date')}
          handleChangeClick2={onDateTimeChange('end_date')}
          types={['date', 'date']}
          value_texts={['開始', '終了']}
        />
      </InputComponent>

      <InputComponent text='時刻'>
        <InputDoubleBox
          value1={start_time ?? ''}
          value2={end_time ?? ''}
          handleChangeClick1={onDateTimeChange('start_time')}
          handleChangeClick2={onDateTimeChange('end_time')}
          types={['time', 'time']}
          value_texts={['開始', '終了']}
        />
      </InputComponent>
      {!hide_scale && setScale && (
        <InputComponent text='表示単位'>
          <WidgetScaler value={scale} onChange={setScale} />
        </InputComponent>
      )}

      <div
        style={{
          width: '100%',
          height: 35,
          display: 'flex',
          justifyContent: 'center',
          marginBottom: styles.interval_margin,
        }}
      >
        <FunctionalText text='設定解除' onClick={onReset} style={{ fontSize: 'larger' }} />
      </div>
    </>
  );
};
