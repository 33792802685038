import React, { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import InputBox from 'shared/components/atoms/InputBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Content, Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import JudgeTimingsInput from 'shared/components/molecules/JudgeTimingsInput';
import ToggleButton from 'shared/components/molecules/ToggleButton';
import { ObservationModeType } from 'shared/models/ObservationModeType';
import styles from 'shared/styles/styles';

import { ChannelEventConditionUsecaseTypeType } from 'shared/models/ChannelEventConditionUsecaseType';
import {
  ChannelEventCondition,
  channelsIdEventConditionsChannelEventConditionNumberPutAPI,
} from 'user/api/channelsEventConditions';
import {
  CachedChannelEventConditionActions,
  channelsIdEventConditionsNumActionsDeleteAPI,
  channelsIdEventConditionsNumActionsPostAPI,
  channelsIdEventConditionsNumActionsPutAPI,
} from 'user/api/channelsEventConditionsActions';
import { Stream } from 'user/api/streams';
import { channelsEventConditionsIdPutValidator } from 'user/utils/validators/channels';
import ClipVideoField from '../ClipVideoField';
import GeneralConditionPane from '../CreateDialog/GeneralConditionPane';
import UsecaseConditionPane from '../CreateDialog/UsecaseConditionPane';

type Params = RouteComponentProps<{
  channel_id: string;
}> & {
  channel_event_condition: ChannelEventCondition;
  streams?: Stream[];
  streams_record: Record<string, Stream>;
  handleCloseClick: () => void;
};

// -- main component --
const ChannelsIdDetailEventConditionsDetail: React.FC<Params> = (params) => {
  // -- local states --
  const [channel_event_condition_name, setChannelEventConditionName] = useState<string>(
    params.channel_event_condition.channel_event_condition_name || '',
  );
  const condition_type = params.channel_event_condition.condition_type || 'GENERAL'; // not changable
  const [observation_mode, setObservationMode] = useState<ObservationModeType | undefined>(
    params.channel_event_condition.observation_mode,
  );
  const [evaluation_period, setEvaluationPeriod] = useState<number>(params.channel_event_condition.evaluation_period);
  const [aggregation_period, setAggregationPeriod] = useState<number>(
    params.channel_event_condition.aggregation_period,
  );
  const [json_path, setJsonPath] = useState<string>(params.channel_event_condition.json_path || '');
  const [statistic_method, setStatisticMethod] = useState<string>(
    params.channel_event_condition.statistic_method || '',
  );
  const [evaluate_condition_operator, setEvaluateConditionOperator] = useState<string>(
    params.channel_event_condition.evaluate_condition.operator || '',
  );
  const [evaluate_condition_value1, setEvaluateConditionValue1] = useState<number>(
    params.channel_event_condition.evaluate_condition.values[0]
      ? params.channel_event_condition.evaluate_condition.values[0]
      : NaN,
  ); // number
  const [evaluate_condition_value2, setEvaluateConditionValue2] = useState<number>(
    params.channel_event_condition.evaluate_condition.values[1]
      ? params.channel_event_condition.evaluate_condition.values[1]
      : NaN,
  ); // number
  const [alarm_datapoints, setAlarmDatapoints] = useState<number>(params.channel_event_condition.alarm_datapoints);
  const [soracom_device_reboot, setSoracomDeviceReboot] = useState<boolean>(false);
  const [stream_id, setStreamId] = useState<string | null>(null);
  const [periods_before, setPeriodsBefore] = useState<number>(NaN); // number
  const [periods_after, setPeriodsAfter] = useState<number>(NaN); // number
  const [judge_timings, setJudgeTimings] = useState<number[][]>(
    params.channel_event_condition.judge_timings || [[0, 2359]],
  );
  const [usecase_type, setUsecaseType] = useState<ChannelEventConditionUsecaseTypeType | undefined>(
    params.channel_event_condition.usecase_type || 'STAY_ALERT',
  );
  const [stayareas, setStayareas] = useState<string>(params.channel_event_condition.stayareas || '');
  const [staying_time, setStayingTime] = useState<number | undefined>(
    params.channel_event_condition.staying_time || undefined,
  );
  const [area_routing, setAreaRouting] = useState<string>(
    params.channel_event_condition.area_routing ?? '{"in":[],"out":[]}',
  );

  const is_timestamp = useMemo(() => {
    const stream = params.streams_record[params.channel_event_condition.input_stream_id];
    return stream?.data_number_type === 'TIMESTAMP';
  }, [params.streams_record, params.channel_event_condition]);

  // -- functions --

  const handleClipVideoChange = (
    enabled: boolean,
    streamId: string | null,
    periodsBefore: number,
    periodsAfter: number,
  ) => {
    setStreamId(enabled ? streamId : null);
    setPeriodsBefore(enabled ? periodsBefore : NaN);
    setPeriodsAfter(enabled ? periodsAfter : NaN);
  };

  const handleFinishClick = async (channel_id: string, channel_event_condition_number: string) => {
    // 入力チェック
    try {
      // バリデーションを行い、requestデータを取得
      const request = channelsEventConditionsIdPutValidator({
        channel_id,
        channel_event_condition_name,
        condition_type: condition_type || 'GENERAL',
        channel_event_condition_number,
        evaluation_period,
        aggregation_period,
        json_path,
        statistic_method,
        evaluate_condition_operator,
        evaluate_condition_value1,
        evaluate_condition_value2,
        alarm_datapoints,
        is_timestamp,
        stream_id,
        periods_after,
        periods_before,
        observation_mode,
        judge_timings,
        usecase_type,
        stayareas,
        staying_time,
        area_routing,
      });
      // --------- チャンネルイベント条件の更新 ---------
      const res = await channelsIdEventConditionsChannelEventConditionNumberPutAPI(request);
      if (res.status !== 200) {
        AlertDialog.show('チャンネルイベント条件の更新に失敗しました');
        return;
      }
      // --------- イベントアクション（クリップビデオ） ------
      const actions = await new CachedChannelEventConditionActions({
        channel_id: channel_id,
        channel_event_condition_number: channel_event_condition_number,
      }).get();
      const clipVideoAction = actions.find((action) => action.action_type === 'ClipVideo');
      if (clipVideoAction === undefined && stream_id !== null) {
        channelsIdEventConditionsNumActionsPostAPI({
          channel_id: channel_id,
          channel_event_condition_number: res.data.channel_event_condition_number,
          channel_event_condition_action_name: 'ClipVideo',
          action_type: 'ClipVideo',
          stream_id: stream_id,
          periods_before: String(periods_before),
          periods_after: String(periods_after),
        });
      } else if (clipVideoAction !== undefined && stream_id !== null) {
        channelsIdEventConditionsNumActionsPutAPI({
          channel_id: channel_id,
          channel_event_condition_number: res.data.channel_event_condition_number,
          channel_event_condition_action_number: String(clipVideoAction.channel_event_condition_action_number),
          channel_event_condition_action_name: 'ClipVideo',
          stream_id: stream_id,
          periods_before: String(periods_before),
          periods_after: String(periods_after),
        });
      } else if (clipVideoAction !== undefined && stream_id === null) {
        channelsIdEventConditionsNumActionsDeleteAPI({
          channel_id: channel_id,
          channel_event_condition_number: res.data.channel_event_condition_number,
          channel_event_condition_action_number: String(clipVideoAction.channel_event_condition_action_number),
        });
      }
      // --------- イベントアクション（ソラコムデバイス再起動） ------
      const soracomDeviceRebootAction = actions.find((action) => action.action_type === 'SoracomDeviceReboot');
      if (
        (condition_type || 'GENERAL') == 'GENERAL' &&
        soracomDeviceRebootAction === undefined &&
        soracom_device_reboot
      ) {
        channelsIdEventConditionsNumActionsPostAPI({
          channel_id: channel_id,
          channel_event_condition_number: res.data.channel_event_condition_number,
          channel_event_condition_action_name: 'SoracomDeviceReboot',
          action_type: 'SoracomDeviceReboot',
        });
      } else if (soracomDeviceRebootAction !== undefined && !soracom_device_reboot) {
        channelsIdEventConditionsNumActionsDeleteAPI({
          channel_id: channel_id,
          channel_event_condition_number: res.data.channel_event_condition_number,
          channel_event_condition_action_number: String(
            soracomDeviceRebootAction.channel_event_condition_action_number,
          ),
        });
      }
      AlertDialog.show('チャンネルイベント条件の更新に成功しました');
      params.handleCloseClick();
    } catch (e) {
      if (e instanceof Error) {
        AlertDialog.show(e.message);
      }
      return;
    }
  };

  // -- onload function --
  useEffect(() => {
    const channel_event_condition = params.channel_event_condition;
    new CachedChannelEventConditionActions({
      channel_id: channel_event_condition.channel_id,
      channel_event_condition_number: channel_event_condition.channel_event_condition_number,
    })
      .get()
      .then((res) => {
        const action = res.find((action) => action.action_type === 'ClipVideo');
        if (action) {
          setStreamId(action.stream_id);
          setPeriodsBefore(action.periods_before);
          setPeriodsAfter(action.periods_after);
        }
        const reboot = res.find((action) => action.action_type === 'SoracomDeviceReboot');
        if (reboot) {
          setSoracomDeviceReboot(true);
        }
      });
  }, []); /* eslint-disable-line */

  // -- render part --
  return (
    <Content>
      <InputComponent text='チャンネルイベント条件名' required>
        <InputBox
          title='チャンネルイベント条件名'
          placeholder='入力してください(必須)'
          value={channel_event_condition_name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setChannelEventConditionName(e.currentTarget.value)}
        />
      </InputComponent>
      <InputComponent text='入力データ名'>
        <div>{params.streams_record[params.channel_event_condition.input_stream_id]?.stream_name ?? ''}</div>
      </InputComponent>
      <InputComponent text='イベント条件の種類'>
        {condition_type === 'GENERAL' ? '汎用イベント' : 'ユースケースイベント'}
      </InputComponent>
      {condition_type === 'GENERAL' && (
        <GeneralConditionPane
          mode='Edit'
          streams={params.streams}
          input_stream_id={params.channel_event_condition.input_stream_id}
          is_timestamp={is_timestamp}
          evaluation_period={evaluation_period}
          onEvaluationPeriodChange={setEvaluationPeriod}
          aggregation_period={aggregation_period}
          onAggregationPeriodChange={setAggregationPeriod}
          json_path={json_path}
          onJsonPathChange={setJsonPath}
          statistic_method={statistic_method}
          onStatisticMethodChange={setStatisticMethod}
          evaluate_condition_operator={evaluate_condition_operator}
          onEvaluateConditionOperatorChange={setEvaluateConditionOperator}
          evaluate_condition_value1={evaluate_condition_value1}
          evaluate_condition_value2={evaluate_condition_value2}
          onEvaluateConditionValue1Change={setEvaluateConditionValue1}
          onEvaluateConditionValue2Change={setEvaluateConditionValue2}
          alarm_datapoints={alarm_datapoints}
          onAlarmDatapointsChange={setAlarmDatapoints}
          observation_mode={observation_mode}
          onObservationModeChange={setObservationMode}
          exec_type={params.channel_event_condition.exec_type}
          onExecTypeChange={() => {}}
        />
      )}
      {condition_type === 'USECASE' && (
        <UsecaseConditionPane
          streams={params.streams}
          usecase_type={usecase_type}
          onUsecaseTypeChange={setUsecaseType}
          stayareas={stayareas}
          onStayareasChange={setStayareas}
          staying_time={staying_time}
          onStayingTimeChange={setStayingTime}
          area_routing={area_routing}
          onAreaRoutingChange={setAreaRouting}
        />
      )}
      <InputComponent text='イベントの判定を行う時間帯'>
        <JudgeTimingsInput value={judge_timings} onChange={(value) => setJudgeTimings(value)} />
      </InputComponent>
      {(condition_type || 'GENERAL') == 'GENERAL' && (
        <InputComponent text='自動再起動設定'>
          <ToggleButton
            checked={soracom_device_reboot}
            onClick={() => setSoracomDeviceReboot(!soracom_device_reboot)}
            style={{
              marginBottom: styles.interval_narrow_margin,
            }}
          />
        </InputComponent>
      )}
      <ClipVideoField
        enabled={stream_id !== null}
        stream_id={stream_id}
        periods_before={periods_before}
        periods_after={periods_after}
        onChange={handleClipVideoChange}
      />
      <Footer>
        <RoundedButton
          onClick={() =>
            handleFinishClick(
              params.match.params.channel_id,
              params.channel_event_condition.channel_event_condition_number,
            )
          }
          text_type='UPDATE'
          style={{ width: styles.small_button_width }}
        />
      </Footer>
    </Content>
  );
};

// -- styled components --

// -- finally export part --

export default ChannelsIdDetailEventConditionsDetail;
