// クリップビデオの設定フォーム
import React from 'react';
import SelectBox from 'shared/components/atoms/SelectBox';
import { AppParamRowArea, AppParamRowText } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import InputDoubleNumberBox from 'shared/components/molecules/InputDoubleNumberBox';
import ToggleButton from 'shared/components/molecules/ToggleButton';
import styles from 'shared/styles/styles';
import { CachedStreams, Stream } from 'user/api/streams';

interface ClipVideoFieldProps {
  enabled: boolean;
  stream_id: string | null;
  periods_before: number; // number
  periods_after: number; // number
  onChange: (enabled: boolean, streamId: string | null, periodsBefore: number, periodsAfter: number) => void;
}
interface ClipVideoFieldState {
  streams: Stream[];
}
/** クリップビデオ欄 */
export default class ClipVideoField extends React.PureComponent<ClipVideoFieldProps, ClipVideoFieldState> {
  private cachedStreams = new CachedStreams({ data_types: 'IMAGE,VIDEO', with_output_streams: 'True' });
  constructor(props: ClipVideoFieldProps) {
    super(props);
    this.state = {
      streams: [],
    };
  }
  componentDidMount() {
    this.cachedStreams.get().then((res) => {
      this.setState({ streams: res });
    });
  }
  handleToggleClick = () => {
    if (!this.props.enabled) {
      this.props.onChange(true, '', 300, 300);
    } else {
      this.props.onChange(false, null, NaN, NaN);
    }
  };
  render() {
    return (
      <InputComponent text='クリップビデオ'>
        <ToggleButton
          checked={this.props.enabled}
          onClick={this.handleToggleClick}
          style={{
            marginBottom: styles.interval_narrow_margin,
          }}
        />
        {this.props.enabled && (
          <>
            <SelectBox
              onChange={(e) =>
                this.props.onChange(true, e.currentTarget.value, this.props.periods_before, this.props.periods_after)
              }
              value={this.props.stream_id || ''}
              datas={this.state.streams.map((e) => {
                return { name: e.stream_name, value: e.stream_id };
              })}
            />

            {/* AppParamRowArea コンポーネントのリファクタリングは後日行います。 */}
            <AppParamRowArea>
              <AppParamRowText>クリップ範囲(分)</AppParamRowText>
              <InputDoubleNumberBox
                value1={this.props.periods_before / 60}
                value2={this.props.periods_after / 60}
                interval_text={'分前から、'}
                maxes={[60, 60]}
                mins={[0, 0]}
                steps={[1, 1]}
                handleChangeClick1={(value) =>
                  this.props.onChange(true, this.props.stream_id, value * 60, this.props.periods_after)
                }
                handleChangeClick2={(value) =>
                  this.props.onChange(true, this.props.stream_id, this.props.periods_before, value * 60)
                }
              />
            </AppParamRowArea>
          </>
        )}
      </InputComponent>
    );
  }
}
