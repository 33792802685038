import { DashboardWidgetMetric } from 'user/api/dashboardsWidgets';
import { AccumulationType } from 'shared/models/AccumulationType';
import { WidgetScaleType } from 'shared/models/WidgetScaleType';
import Spinner from 'shared/components/atoms/Spinner';
import { CartesianGraphData, SymbolType, CartesianGragh } from 'shared/components/molecules/PfGraph';
import React from 'react';
import DateRangeWidgetBase from './base/DateRangeWidgetBase';
import { WidgetProps } from './getWidgetType';

interface GraphWidgetState {
  data?: CartesianGraphData[];
  scale: WidgetScaleType | null;
}
/**
 * グラフ描画ウィジェットです。
 */
export default class GraphWidget extends React.PureComponent<WidgetProps, GraphWidgetState> {
  constructor(props: WidgetProps) {
    super(props);
    this.state = {
      data: undefined,
      scale: props.state.scale && typeof props.state.scale === 'string' ? props.state.scale : 'HOUR',
    };
  }

  componentDidUpdate(prevProps: WidgetProps) {
    if (this.props.state !== prevProps.state && this.props.state.scale) {
      this.setState({ scale: this.props.state.scale });
    }
  }

  private getSymbolTypes = () => {
    const types: SymbolType[] = [];
    this.props.widget.metrics?.forEach((m, i) => {
      types.push({
        dataKey: 'data' + i,
        name: m.name,
        type: m.graph_type,
        stackId: m.stack_id,
      });
    });
    return types;
  };
  private handleDataChanged = (metric: DashboardWidgetMetric) => {
    if (!this.props.widget.metrics) {
      this.setState({ data: [] });
      return;
    }
    const data: CartesianGraphData[] = [];
    // データポイントを整備
    for (const dp of metric.datapoints) {
      data.push({
        datapoint: dp,
      });
    }
    // 現在時刻のデータポイント
    const now = Math.floor(new Date().getTime() / 1000);
    // メトリクスを転送
    for (let i = 0; i < metric.metrics.length; i++) {
      const m = metric.metrics[i];
      const metric_name = 'data' + i;
      try {
        const accumulator = new Accumulator(this.props.widget.metrics[i].accumulation);
        for (let j = 0; j < data.length; j++) {
          const datum = data[j];
          const metric = m.data[j];
          // 現在時刻より未来のデータはプロットしない
          if (now < Number(datum.datapoint)) break;
          // メトリクス値のセット
          datum[metric_name] = accumulator.calc(Number(datum.datapoint), metric);
        }
      } catch (e) {
        console.log(e);
      }
    }
    this.setState({ data: data });
  };
  private handleScaleChanged = (rangeType: WidgetScaleType) => {
    this.setState({ scale: rangeType });
  };
  private onInitMetrics = () => {
    this.setState({ data: undefined });
  };
  render() {
    return (
      <DateRangeWidgetBase
        widget={this.props.widget}
        state={this.props.state}
        hide_scale={false}
        fill_out_of_range_data_with_0={true}
        data={this.state.data}
        symbol_types={this.getSymbolTypes()}
        range_type={this.state.scale !== null ? this.state.scale : undefined}
        onInitMetrics={this.onInitMetrics}
        onStateChanged={this.props.onStateChanged}
        onMetricsChanged={this.handleDataChanged}
        onScaleChanged={this.handleScaleChanged}
        handleUpdateDlCsvList={this.props.handleUpdateDlCsvList}
      >
        {this.state.data ? (
          <CartesianGragh
            data={this.state.data}
            symbolTypes={this.getSymbolTypes()}
            isTimestampTick={true}
            range_type={this.state.scale}
            graph_setting={this.props.widget.widget_setting}
          />
        ) : (
          <Spinner />
        )}
      </DateRangeWidgetBase>
    );
  }
}

/** 累積処理クラス */
class Accumulator {
  type: AccumulationType;
  lastDate?: Date;
  value: number;
  isPast: (dt: Date) => boolean;
  constructor(type?: AccumulationType) {
    this.type = type || 'NONE';
    this.lastDate = undefined;
    this.value = 0;
    this.isPast = this.getPast(this.type);
  }
  private getPast(type: AccumulationType) {
    switch (type) {
      case 'HOUR':
        return this.isPastHour;
      case 'DAY':
        return this.isPastDay;
      case 'NONE':
        return this.isPastNone;
    }
  }
  private isPastHour(dt: Date) {
    return this.lastDate === undefined || this.isPastDay(dt) || this.lastDate.getHours() !== dt.getHours();
  }
  private isPastDay(dt: Date) {
    return (
      this.lastDate === undefined ||
      this.lastDate.getFullYear() !== dt.getFullYear() ||
      this.lastDate.getMonth() !== dt.getMonth() ||
      this.lastDate.getDay() !== dt.getDay()
    );
  }
  private isPastNone() {
    return true;
  }
  calc(datapoint: number, value: number) {
    const dt = new Date(datapoint * 1000);
    if (this.isPast(dt)) this.value = 0;
    // 値を更新
    this.lastDate = dt;
    this.value += value;
    return this.value;
  }
}
