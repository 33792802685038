import React from 'react';
import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import RoundedCard from 'shared/components/atoms/RoundedCard';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { Content } from 'shared/components/molecules/ContentsArea';
import { Stream } from 'user/api/streams';
import { StreamData, streamsIdDataNumberDeleteAPI, streamsIdDataGetAPI } from 'user/api/streamsData';
import StreamThumbnailImage from 'user/components/molecules/StreamThumbnailImage';
import CreateOneshotChannelProcessDialog from 'user/pages/Streams/DetailPage/StreamDetailPanel/CreateOneshotChannelProcessDialog';
import DetailStreamDataDialog from 'user/dialogs/DetailStreamDataDialog';
import { store } from 'user/redux/store';
import DataThumbnailPlayerDialog from './DataThumbnailPlayerDialog';

interface DataThumbnailPanelProps {
  stream: Stream;
}
interface DataThumbnailPanelState {
  stream_data_list?: StreamData[];
  player?: { stream_id: string; stream_data_number: number }; // 再生プレーヤのパラメータ
  is_channel_process_dialog_open: boolean;
  channel_process_dialog_props?: { stream: Stream; stream_data: StreamData };
  is_edit_stream_data_dialog_open: boolean; // ストリームデータ更新ダイアログのopen
  edit_atream_data_props?: { stream: Stream; streamData: StreamData }; // ストリームデータ更新ダイアログのprops
  auth: boolean;
}
export default class DataThumbnailPanel extends React.PureComponent<DataThumbnailPanelProps, DataThumbnailPanelState> {
  constructor(props: DataThumbnailPanelProps) {
    super(props);
    const state = store.getState();
    const is_admin = state.auth.is_admin;
    this.state = {
      is_channel_process_dialog_open: false,
      is_edit_stream_data_dialog_open: false,
      auth: is_admin,
    };
  }
  componentDidMount() {
    this.loadStreamData();
  }
  private handlePlayClick = (stream_id: string, stream_data_number: number) => {
    this.setState({
      player: {
        stream_id: stream_id,
        stream_data_number: stream_data_number,
      },
    });
  };
  private handlePlayerCloseClick = () => {
    this.setState({ player: undefined });
  };
  /**
   * ストリームデータのリストを再読み込みします。
   */
  private loadStreamData = () => {
    streamsIdDataGetAPI({ stream_id: this.props.stream.stream_id }).then((res) => {
      if (res.status === 200) {
        this.setState({ stream_data_list: res.data.items });
      }
    });
  };
  private handleApplyChannelProcessMenuClick = (sd: StreamData) => {
    this.setState({
      is_channel_process_dialog_open: true,
      channel_process_dialog_props: {
        stream: this.props.stream,
        stream_data: sd,
      },
    });
  };
  private handleEditStreamDataDialogOpen = (sd: StreamData) => {
    this.setState({
      is_edit_stream_data_dialog_open: true,
      edit_atream_data_props: {
        stream: this.props.stream,
        streamData: sd,
      },
    });
  };
  private handleEditStreamDataDialogClose = (canceled?: boolean) => {
    this.setState({
      is_edit_stream_data_dialog_open: false,
      edit_atream_data_props: undefined,
    });
    if (!canceled) {
      this.loadStreamData();
    }
  };
  private handleChannelProcessDialogClose = () => {
    this.setState({ is_channel_process_dialog_open: false });
  };
  private handleStreamDataDeleteMenuClick = (sd: StreamData) => {
    ConfirmDialog.show('このストリームデータを削除してもよろしいですか？', () => this.deleteStreamData(sd));
  };
  private deleteStreamData = (sd: StreamData) => {
    streamsIdDataNumberDeleteAPI({
      stream_id: sd.stream_id,
      stream_data_number: sd.stream_data_number,
      api_send_type: 'changeableOneTransmission',
    }).then((res) => {
      if (res.status === 200) {
        AlertDialog.show('ストリームデータを削除しました。', () => this.loadStreamData());
      }
    });
  };
  render() {
    const cardStyle: React.CSSProperties = {
      width: 320,
      height: 320,
      padding: '20px',
      margin: '15px',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
    };
    const enableOnClick = this.props.stream.data_type === 'VIDEO';
    return (
      <Content style={{ display: 'flex', flexWrap: 'wrap' }}>
        {this.state.stream_data_list ? (
          this.state.stream_data_list.map((sd) => (
            <RoundedCard
              style={cardStyle}
              onClick={() => (enableOnClick ? this.handlePlayClick(sd.stream_id, sd.stream_data_number) : null)}
              key={sd.stream_data_number}
              menu={
                this.state.auth ? (
                  <Menu>
                    <MenuItem text='データの詳細' onClick={() => this.handleEditStreamDataDialogOpen(sd)} />
                    <MenuDivider />
                    <MenuItem
                      text='チャンネルプロセスの適用'
                      onClick={() => this.handleApplyChannelProcessMenuClick(sd)}
                      disabled={!sd.is_ready_for_process}
                    />
                    <MenuDivider />
                    <MenuItem text='データの削除' onClick={() => this.handleStreamDataDeleteMenuClick(sd)} />
                  </Menu>
                ) : undefined
              }
            >
              <div
                style={{
                  height: 240,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <StreamThumbnailImage
                  stream_id={sd.stream_id}
                  stream_data_number={sd.stream_data_number}
                  style={{ maxWidth: 280, maxHeight: 240 }}
                />
              </div>
              <div style={{ flexGrow: 1 }}>
                {sd.stream_data_number}
                <br />
                {sd.stream_data_name}
                <br />
                {sd.original_file_name}
              </div>
            </RoundedCard>
          ))
        ) : (
          <Spinner />
        )}
        {this.state.player && (
          <DataThumbnailPlayerDialog
            isOpen={true}
            onCloseClick={this.handlePlayerCloseClick}
            stream_id={this.state.player.stream_id}
            stream_data_number={this.state.player.stream_data_number}
          />
        )}
        {this.state.is_channel_process_dialog_open && this.state.channel_process_dialog_props && (
          <CreateOneshotChannelProcessDialog
            isOpen={true}
            {...this.state.channel_process_dialog_props}
            onClose={this.handleChannelProcessDialogClose}
          />
        )}
        {this.state.is_edit_stream_data_dialog_open && this.state.edit_atream_data_props && (
          <DetailStreamDataDialog
            stream={this.state.edit_atream_data_props.stream}
            streamData={this.state.edit_atream_data_props.streamData}
            isOpen={this.state.is_edit_stream_data_dialog_open}
            onClose={this.handleEditStreamDataDialogClose}
          />
        )}
      </Content>
    );
  }
}
