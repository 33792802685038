import { useCallback, useEffect, useMemo, useState } from 'react';
import { Dashboard, dashboardsIdGetAPI } from 'user/api/dashboards';

/**
 * 指定のダッシュボードを取得するcustom hooks
 */
export const useDashboard = ({ dashboard_id }: { dashboard_id: string }) => {
  const [dashboard, setDashboard] = useState<Dashboard | undefined>(undefined);
  const loadDashboard = useCallback(async () => {
    const res = await dashboardsIdGetAPI({ dashboard_id });
    if (res.status === 200) {
      setDashboard(res.data);
    }
  }, [dashboard_id]);

  useEffect(() => {
    loadDashboard();
  }, [loadDashboard]);

  return useMemo(() => {
    return { dashboard, setDashboard };
  }, [dashboard, setDashboard]);
};
