import { RouteComponentProps } from 'react-router-dom';
import { useDashboard } from 'user/hooks/useDashboard/useDashboard';
import TableauDashboardWidgets from './TableauDashboardWidgets';
import UsualDashboardWidgets from './UsualDashboardWidgets';
import { authSelector } from 'user/redux/slices/authSlice';
import { useSelector } from 'react-redux';

type DashboardsDetailPageProps = RouteComponentProps<{
  dashboard_id: string;
  panel_id?: string;
}>;

export default function DashboardsDetailPage(props: DashboardsDetailPageProps) {
  const { dashboard } = useDashboard({ dashboard_id: props.match.params.dashboard_id });
  const auth_state = useSelector(authSelector);
  const { is_admin, tableau_role } = auth_state;
  switch (dashboard?.dashboard_type) {
    case 'tableau':
      return <TableauDashboardWidgets dashboard={dashboard} is_admin={is_admin} tableau_role={tableau_role} />;
    case 'usual':
      return <UsualDashboardWidgets {...props} is_admin={is_admin} />;
    default:
      // dashboard?.dashboard_typeが存在しないものはusualとして扱う
      return <UsualDashboardWidgets {...props} is_admin={is_admin} />;
  }
}
