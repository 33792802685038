import { AxiosResponse } from 'axios';
import { ChannelEventConditionStatisticMethod } from 'shared/models/ChannelEventConditionStatisticMethod';
import { ChannelEventConditionTypeType } from 'shared/models/ChannelEventConditionType';
import { ChannelEventConditionUsecaseTypeType } from 'shared/models/ChannelEventConditionUsecaseType';
import { ChannelEventConEvaluateCondition } from 'shared/models/channels/ChannelEventConEvaluateCondition';
import { CommonAPIRequestType, isCommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';
import { ExecTypeType } from 'shared/models/ExecTypeType';
import { ISO8601 } from 'shared/models/ISO8601';
import { ObservationModeType } from 'shared/models/ObservationModeType';
import { Query } from 'shared/models/Query';
import { StatusType } from 'shared/models/StatusType';
import sendAxios from '../../shared/axios/sendAxios';
import { getClient } from './base';

/*** エンティティ ***/

export interface ChannelEventCondition {
  tenant_id: string;
  channel_id: string;
  channel_event_condition_number: string;
  channel_event_condition_name: string;
  condition_type: ChannelEventConditionTypeType;
  status: StatusType;
  exec_type: ExecTypeType;
  input_stream_id: string;
  current_status: 'OK' | 'ALARM';
  is_active: boolean;
  observation_mode: ObservationModeType;
  evaluation_period: number;
  aggregation_period: number;
  json_path: string;
  statistic_method: ChannelEventConditionStatisticMethod;
  evaluate_condition: ChannelEventConEvaluateCondition;
  alarm_datapoints: number;
  usecase_type?: ChannelEventConditionUsecaseTypeType;
  stayareas?: string;
  staying_time?: number;
  area_routing: string | null;
  judge_timings?: number[][];
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

export interface ChannelEventConditionsWithPaging {
  items: ChannelEventCondition[];
  has_next: boolean;
}

const sample_channel_event_condition_1: ChannelEventCondition = {
  tenant_id: '00000001',
  channel_id: '00000001',
  channel_event_condition_number: '1',
  channel_event_condition_name: 'channel_event_condition_1',
  condition_type: 'GENERAL',
  status: 'ACTIVE',
  exec_type: 'ADD',
  is_active: true,
  input_stream_id: '00000002',
  current_status: 'OK',
  observation_mode: 'TO_ALARM',
  evaluation_period: 1,
  aggregation_period: 1,
  json_path: 'string',
  statistic_method: 'Minimum',
  evaluate_condition: {
    operator: 'Equal',
    values: [1],
  },
  area_routing: null,
  alarm_datapoints: 1,
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
};

const sample_channel_event_condition_2: ChannelEventCondition = {
  tenant_id: '00000001',
  channel_id: '00000001',
  channel_event_condition_number: '2',
  channel_event_condition_name: 'channel_event_condition_2',
  condition_type: 'GENERAL',
  status: 'ACTIVE',
  exec_type: 'ADD',
  is_active: false,
  input_stream_id: '00000002',
  current_status: 'OK',
  observation_mode: 'TO_ALARM',
  evaluation_period: 0,
  aggregation_period: 0,
  json_path: 'string',
  statistic_method: 'Minimum',
  area_routing: null,
  evaluate_condition: {
    operator: 'Between',
    values: [0, 1],
  },
  alarm_datapoints: 0,
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
};

/*** Caching mechanism ***/

export class CachedChannelEventConditions {
  private searched = false;
  private cache: ChannelEventCondition[] = [];
  private params: RequestChannelsIdEventConditionsGet;
  constructor(params: RequestChannelsIdEventConditionsGet) {
    this.params = params;
  }
  async get() {
    if (!this.searched) {
      const res: AxiosResponse<ChannelEventConditionsWithPaging> = await channelsIdEventConditionsGetAPI({
        ...this.params,
      });
      // ページング非対応
      if (res.status === 200) {
        this.cache = [...this.cache, ...res.data.items];
      }
      this.searched = true;
    }

    return this.cache;
  }
}

/*** [GET] /api/channels/{id}/event-conditions ***/
export interface RequestChannelsIdEventConditionsGet extends CommonAPIRequestType {
  channel_id: string;
  exclusive_start_channel_event_condition_number?: string;
}

/**
 * チャンネルに設定されたプロセスの一覧を取得する
 * @param channel_id チャンネルID
 * @returns チャンネルのプロセスの一覧
 */
export const channelsIdEventConditionsGetAPI = (params: RequestChannelsIdEventConditionsGet) => {
  const {
    channel_id,
    exclusive_start_channel_event_condition_number = '',
    disabled_load,
    disabled_error_message,
    ended_load,
  } = toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/channels/${channel_id}/event-conditions`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {
    exclusive_start_channel_event_condition_number,
  };

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<ChannelEventConditionsWithPaging>(axios, path, query, form, method, {
    items: [sample_channel_event_condition_1, sample_channel_event_condition_2],
    has_next: false,
  });
};

/*** [POST] /api/channels/{id}/event-conditions ***/

export interface RequestChannelsIdEventConditionsPost extends CommonAPIRequestType {
  channel_id: string;
  channel_event_condition_name: string;
  condition_type: string;
  exec_type?: ExecTypeType;
  input_stream_id: string;
  observation_mode?: ObservationModeType;
  evaluation_period?: string;
  aggregation_period?: string;
  json_path: string;
  statistic_method: string;
  evaluate_condition_operator: string;
  evaluate_condition_values?: number[];
  alarm_datapoints: string;
  judge_timings: string; // number[] の文字列化
  usecase_type: string;
  stayareas: string;
  staying_time?: string;
  area_routing?: string;
}

export const channelsIdEventConditionsPostAPI = (params: RequestChannelsIdEventConditionsPost) => {
  const { channel_id, evaluate_condition_values, disabled_load, disabled_error_message, ended_load } =
    toAPIRequestParams({
      ...params,
      api_send_type: params.api_send_type ?? 'changeableOneTransmission',
    });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/channels/${channel_id}/event-conditions`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'channel_id' || isCommonAPIRequestType(key)) {
      continue;
    }
    if (key === 'evaluate_condition_values') {
      continue;
    }
    if (typeof value === 'string') {
      form.append(key, value);
    } else if (typeof value === 'number') {
      form.append(key, String(value));
    }
  }
  // evaluate_condition_valuesだけ別処理
  if (evaluate_condition_values && evaluate_condition_values.length > 0) {
    const roop_number = evaluate_condition_values.length || 0;
    for (let i = 0; i < roop_number; i++) {
      form.append('evaluate_condition_value[' + i + ']', String(evaluate_condition_values[i]));
    }
  }

  // 送信
  return sendAxios<ChannelEventCondition>(axios, path, query, form, method, sample_channel_event_condition_1);
};

/*** [GET] /api/channels/{id}/event-conditions/{channel_event_condition_number} ***/

export interface RequestChannelsIdEventConditionsChannelEventConditionNumberGet {
  channel_id: string;
  channel_event_condition_number: string;
}

export const channelsIdEventConditionsNumGetAPI = (
  params: RequestChannelsIdEventConditionsChannelEventConditionNumberGet,
) => {
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `/api/channels/${params.channel_id}/event-conditions/${params.channel_event_condition_number}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<ChannelEventCondition>(axios, path, query, form, method, sample_channel_event_condition_1);
};

/*** [PUT] /api/channels/{id}/event-conditions/{channel_process_number} ***/

export interface RequestChannelsIdEventConditionsChannelEventConditionNumberPut extends CommonAPIRequestType {
  channel_id: string;
  channel_event_condition_name?: string;
  condition_type: string;
  channel_event_condition_number: string;
  observation_mode?: ObservationModeType;
  evaluation_period?: string;
  aggregation_period?: string;
  json_path: string;
  statistic_method: string;
  evaluate_condition_operator: string;
  evaluate_condition_values?: number[];
  alarm_datapoints: string;
  judge_timings: string; // number[] の文字列化
  usecase_type: string;
  stayareas: string;
  staying_time?: string;
  area_routing?: string;
}

export const channelsIdEventConditionsChannelEventConditionNumberPutAPI = (
  params: RequestChannelsIdEventConditionsChannelEventConditionNumberPut,
) => {
  const {
    channel_id,
    channel_event_condition_number,
    evaluate_condition_values,
    disabled_load,
    disabled_error_message,
    ended_load,
  } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'changeableOneTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/channels/${channel_id}/event-conditions/${channel_event_condition_number}`;
  const method = 'put';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'channel_id' || key === 'channel_event_condition_number' || isCommonAPIRequestType(key)) {
      continue;
    }
    if (key === 'evaluate_condition_values') {
      continue;
    }
    if (typeof value === 'string') {
      form.append(key, value);
    } else if (typeof value === 'number') {
      form.append(key, String(value));
    }
  }
  // evaluate_condition_valuesだけ別処理
  if (evaluate_condition_values && evaluate_condition_values.length > 0) {
    const roop_number = evaluate_condition_values.length || 0;
    for (let i = 0; i < roop_number; i++) {
      form.append('evaluate_condition_value[' + i + ']', String(evaluate_condition_values[i]));
    }
  }

  // 送信
  return sendAxios<ChannelEventCondition>(axios, path, query, form, method, sample_channel_event_condition_1);
};

/*** [DELETE] /api/channels/{id}/event-conditions/{channel_process_number} ***/

export interface RequestChannelsIdEventConditionsChannelEventConditionNumberDelete extends CommonAPIRequestType {
  channel_id: string;
  channel_event_condition_number: string;
}

export const channelsIdEventConditionsChannelEventConditionNumberDeleteAPI = (
  params: RequestChannelsIdEventConditionsChannelEventConditionNumberDelete,
) => {
  const { channel_id, channel_event_condition_number, disabled_load, disabled_error_message, ended_load } =
    toAPIRequestParams({
      ...params,
      api_send_type: params.api_send_type ?? 'multipleTransmission',
    });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/channels/${channel_id}/event-conditions/${channel_event_condition_number}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();

  // 送信
  return sendAxios<ChannelEventCondition>(axios, path, query, form, method, sample_channel_event_condition_1);
};

/*** [POST] /api/channels/{id}/event-conditions/{channel_event_condition_number}/activate ***/

export interface RequestChannelsIdEventConditionsNumActivatePost extends CommonAPIRequestType {
  channel_id: string;
  channel_event_condition_number: string;
  is_active: 'True' | 'False';
}

export const channelsIdEventConditionsNumActivatePostAPI = (
  params: RequestChannelsIdEventConditionsNumActivatePost,
) => {
  const { channel_id, channel_event_condition_number, disabled_load, disabled_error_message, ended_load } =
    toAPIRequestParams({
      ...params,
      api_send_type: params.api_send_type ?? 'changeableOneTransmission',
    });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/channels/${channel_id}/event-conditions/${channel_event_condition_number}/activate`;
  const method = 'post';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (key === 'channel_id' || key === 'channel_event_condition_number' || isCommonAPIRequestType(key)) {
      continue;
    }
    form.append(key, value);
  }

  // 送信
  return sendAxios<ChannelEventCondition>(axios, path, query, form, method, sample_channel_event_condition_1);
};
