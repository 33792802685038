import { memo, useCallback, useEffect, useState } from 'react';
import Spinner from 'shared/components/atoms/Spinner';
import { Content } from 'shared/components/molecules/ContentsArea';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableBodyUrlType, TableHeaderType } from 'shared/components/molecules/Table/type';
import { toEdgeDeviceStatusJP } from 'shared/models/EdgeDeviceStatus';
import { ISO8601, toISO8601 } from 'shared/models/ISO8601';
import { dateToYMDHMS } from 'shared/utils/converter/date';
import { getSpecifiedMinute } from 'shared/utils/get';
import { isSelected1 } from 'shared/utils/is';
import { EdgeDevice } from 'user/api/edgeDevices';

// -- type declaration --

// デバイステーブルのtype
type TableEdgeDeviceType = {
  id: string;
  edge_device_name: TableBodyUrlType;
  edge_device_status: string;
  edge_device_heartbeat_at: string;
  edge_device_app_ver: string;
};

// テーブルの列の情報まとめたデータ
const headers: TableHeaderType[] = [
  {
    id: 'edge_device_name',
    label: 'デバイス名',
    width: '17%',
    sortable: true,
    search_props: {
      type: 'name',
      default_display: true,
    },
  },
  {
    id: 'edge_device_app_ver',
    label: 'バージョン',
    width: 'auto',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'edge_device_status',
    label: 'ステータス',
    width: 'auto',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'edge_device_heartbeat_at',
    label: 'ハートビート時刻',
    width: 'auto',
    sortable: true,
    search_props: {
      type: 'datetime',
    },
  },
];

export interface EdgeDevicesTableProps {
  edge_devices: EdgeDevice[];
  onDelete: (edged_evice: EdgeDevice) => void;
}

// -- main component --
/**
 * counterboxデバイス一覧の表示テーブルです。
 */
const EdgeDevicesTable = memo(function EdgeDevicesTable({ edge_devices, onDelete }: EdgeDevicesTableProps) {
  // -- local states --
  const [table_bodies, setTableBodies] = useState<TableEdgeDeviceType[] | undefined>(undefined);
  const [selected_bodies, setSelectedBodies] = useState<TableEdgeDeviceType[]>([]);
  const [now, setNow] = useState<ISO8601>(toISO8601(Number(getSpecifiedMinute(0)) * 1000));

  // -- handlers --
  const handleCheckClick = (datas: TableEdgeDeviceType[]) => {
    setSelectedBodies(datas);
  };
  const handleDeleteClick = () => {
    const edge_device_key: string = selected_bodies[0].id;
    edge_devices.forEach((edge_device) => {
      if (edge_device.edge_device_key === edge_device_key) {
        onDelete(edge_device);
      }
    });
  };

  const loadTableBodies = useCallback(() => {
    const return_table_datas: TableEdgeDeviceType[] = [];
    for (let i = 0; i < edge_devices.length; i++) {
      const return_top_table_data: TableEdgeDeviceType = {
        id: edge_devices[i].edge_device_key,
        edge_device_name: {
          value: edge_devices[i].heartbeat_edge_device_name,
          url: `/edge/devices/${edge_devices[i].edge_device_key}/overviews`,
        },
        edge_device_app_ver: edge_devices[i].app_version,
        // nowとheartbeat_atを比較し、10秒以内であれば稼働中、10秒以上であればオフラインを返す
        edge_device_status: toEdgeDeviceStatusJP({ now: now, edge_device_heartbeat_at: edge_devices[i].heartbeat_at }),
        edge_device_heartbeat_at: dateToYMDHMS(edge_devices[i].heartbeat_at),
      };

      return_table_datas.push(return_top_table_data);
    }
    setTableBodies(return_table_datas);
  }, [edge_devices, now]);

  // -- onload function --
  useEffect(() => {
    loadTableBodies();
    // 30秒ごとに現在時刻nowを更新
    const interval = setInterval(() => {
      const currentTime = toISO8601(Date.now());
      setNow(currentTime);
    }, 30000);

    return () => clearInterval(interval);
  }, [now, edge_devices]); /* eslint-disable-line */

  // -- render part --
  return (
    <Content>
      {table_bodies === undefined ? (
        <Spinner />
      ) : (
        <BaseTable
          bodies={table_bodies}
          headers={headers}
          table_name='edge_devices'
          selected_bodies={selected_bodies}
          handleCheckClick={handleCheckClick}
          footer_option={{
            text: '＞チェックしたcounterboxデバイスを削除する',
            handleClick: handleDeleteClick,
            disabled: !isSelected1(selected_bodies.length),
          }}
        />
      )}
    </Content>
  );
});

// -- styled components --

// -- finally export part --

export default EdgeDevicesTable;
