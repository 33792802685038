// -- basic library --
import React, { useRef } from 'react';
import { Table, Tbody, Td, Th, Thead, Tr } from 'shared/components/atoms/PfTable';
import getStreamGraphCsvs from 'user/pages/Devices/DetailPage/CameraAnalysisResultPage/getStreamGraphCsvs';
import styled from 'styled-components';
import { DatapointItem } from 'user/api/streamsGraphs';

export interface StreamTableGraphType {
  time: string;
  max_name: string;
  LR: number;
  RL: number;
}

interface Params {
  graph_data: DatapointItem[];
}

const headers = [
  {
    key: 'time',
    name: '時間',
  },
  {
    key: 'max_name',
    name: '最大キー',
  },
  {
    key: 'LR',
    name: 'LR',
  },
  {
    key: 'RL',
    name: 'RL',
  },
];

// -- main component --

const CameraTableGraph: React.FC<Params> = (params) => {
  const ref = useRef<HTMLDivElement>(null);
  // テーブルのサイドを固定にするか
  const side_sticky = true;
  // テーブルのヘッドを固定にするか
  const head_sticky = true;

  const csvs = getStreamGraphCsvs(params.graph_data);

  // -- render part --
  return (
    <Wrapper ref={ref}>
      <Table sticky={head_sticky || side_sticky}>
        <Thead>
          {headers.map((head, i) => {
            return (
              <Th
                head_sticky={head_sticky}
                side_sticky={side_sticky}
                key={`${head}_${i}`}
                style={{ width: 100, textAlign: 'center' }}
              >
                {head.name}
              </Th>
            );
          })}
        </Thead>
        <Tbody>
          {csvs.map((csv, i) => {
            return (
              <Tr key={`tr_${csv}_${i}`}>
                {headers.map((head, j) => {
                  if (head.key === 'time' || head.key === 'max_name' || head.key === 'LR' || head.key === 'RL') {
                    return (
                      <Td
                        side_sticky={side_sticky}
                        key={`${head.key}_${i}_${j}`}
                        last_row={i === csvs.length - 1}
                        style={{ textAlign: 'center' }}
                      >
                        {csv[head.key]}
                      </Td>
                    );
                  }
                  return <></>;
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Wrapper>
  );
};

// -- styled components --
const Wrapper = styled.div`
  display: block;
  width: 100%;
  max-width: 100%;
  max-height: 500px;
  overflow: scroll;
`;

// -- finally export part --

export default CameraTableGraph;
