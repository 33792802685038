import React, { useCallback } from 'react';
import InputBox from 'shared/components/atoms/InputBox';
import InputComponent from 'shared/components/molecules/InputComponent';

export interface WidgetGraphSettingPanelProps {
  y_min?: string;
  y_max?: string;
  setYMin(y_min: string): void;
  setYMax(y_max: string): void;
}
/** ウィジェットのグラフ設定を行うパネル*/
export const WidgetGraphSettingPanel = (props: WidgetGraphSettingPanelProps) => {
  const { y_min, y_max, setYMin, setYMax } = props;
  const onChangeYMin = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setYMin(e.currentTarget.value);
    },
    [setYMin],
  );
  const onChangeYMax = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setYMax(e.currentTarget.value);
    },
    [setYMax],
  );
  return (
    <>
      <InputComponent text='Y軸最小値'>
        <InputBox
          type='number'
          placeholder='入力された場合、入力値をy軸の最小値とします'
          value={y_min ?? ''}
          onChange={onChangeYMin}
        />
      </InputComponent>
      <InputComponent text='Y軸最大値'>
        <InputBox
          type='number'
          placeholder='入力された場合、入力値をy軸の最大値とします'
          value={y_max ?? ''}
          onChange={onChangeYMax}
        />
      </InputComponent>
    </>
  );
};
