// チャンネルのサムネイル画像を取得して表示します
import React, { ImgHTMLAttributes } from 'react';
import Spinner from 'shared/components/atoms/Spinner';

import { colors } from 'shared/styles/colors';
import { toRequestBool } from 'shared/utils/converter';
import styled from 'styled-components';
import { channelsIdThumbnailGetAPI } from 'user/api/channels';

interface ChannelThumbnailImageProps extends React.HTMLAttributes<HTMLDivElement> {
  channel_id: string;
  event_number?: number;
  from_event_page?: boolean;
}
interface ChannelThumbnailImageState {
  thumbnail_blob?: string;
}
export default class ChannelThumbnailImage extends React.PureComponent<
  ChannelThumbnailImageProps & ImgHTMLAttributes<HTMLImageElement>,
  ChannelThumbnailImageState
> {
  constructor(props: ChannelThumbnailImageProps) {
    super(props);
    this.state = {
      thumbnail_blob: undefined,
    };
  }
  componentDidMount() {
    if (process.env.NODE_ENV !== 'production') {
      this.setState({
        thumbnail_blob: 'https://idea-security-demo-hls-tmp-hasegawa.s3-ap-northeast-1.amazonaws.com/video000.jpg',
      });
      return;
    }
    channelsIdThumbnailGetAPI({
      channel_id: this.props.channel_id,
      from_event_page: toRequestBool(this.props.from_event_page),
    }).then((res) => {
      if (res.status === 200) {
        const blob = new Blob([res.data], { type: 'image/png' });
        const url = window.URL || window.webkitURL;
        this.setState({ thumbnail_blob: url.createObjectURL(blob) });
      }
    });
  }
  componentDidUpdate(prevProps: ChannelThumbnailImageProps) {
    // 典型的な使い方(props を比較することを忘れないでください)
    if (this.props.channel_id !== prevProps.channel_id) {
      if (process.env.NODE_ENV !== 'production') {
        this.setState({
          thumbnail_blob: 'https://idea-security-demo-hls-tmp-hasegawa.s3-ap-northeast-1.amazonaws.com/video000.jpg',
        });
        return;
      }
      channelsIdThumbnailGetAPI({
        channel_id: this.props.channel_id,
        from_event_page: toRequestBool(this.props.from_event_page),
      }).then((res) => {
        if (res.status === 200) {
          const blob = new Blob([res.data], { type: 'image/png' });
          const url = window.URL || window.webkitURL;
          this.setState({ thumbnail_blob: url.createObjectURL(blob) });
        }
      });
    }
  }
  render() {
    /**
     * from_event_pageというpropを<div>タグなどに渡すと、想定外のpropとしてerrorが生じる
     * そのため、from_event_page以外を渡すようにしている
     * */
    const { from_event_page: _from_event_page, ...remain_props } = this.props;
    return (
      <div {...remain_props}>
        {this.state.thumbnail_blob ? (
          <ImgArea>
            <Img src={this.state.thumbnail_blob} alt='' {...remain_props} />
            {this.props.event_number ? <TextArea>{this.props.event_number}件のアラートがあります</TextArea> : null}
          </ImgArea>
        ) : (
          <Spinner />
        )}
      </div>
    );
  }
}

const ImgArea = styled.div`
  position: relative;
`;

const Img = styled.img`
  width: auto;
  height: auto;
`;

const TextArea = styled.div`
  min-width: 220px;
  height: 30px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${colors.alert_color};
  color: ${colors.alert_color};
  background-color: ${colors.white};
  border-radius: 15px;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
`;
