import { assertNever } from 'shared/utils/else/assertNever';

export type DashboardType = 'usual' | 'tableau';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isDashboardType = (data: any): data is DashboardType => data === 'usual' || data === 'tableau';

export const dashboardTypeToJp = (dashboard_type: DashboardType) => {
  switch (dashboard_type) {
    case 'usual':
      return '通常';
    case 'tableau':
      return 'Tableau';
    default:
      assertNever(dashboard_type);
      return '';
  }
};
