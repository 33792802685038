import { Metric, SUAreaCountSetting } from 'user/api/dashboardsWidgets';
import { Stream } from 'user/api/streams';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import SelectBox from 'shared/components/atoms/SelectBox';
import React from 'react';
import { MetricGraphType } from 'shared/models/MetricGraphType';
import styles from 'shared/styles/styles';
import { FooterArea, Table, TableArea, Tbody, Td, Th, Thead, Tr, WholeArea } from './StyledComponent';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { MenuItem, Select } from '@material-ui/core';
import { area_name, count_target } from 'shared/utils/constants';
import Spinner from 'shared/components/atoms/Spinner';
import InputBox from 'shared/components/atoms/InputBox';

export interface SumUpGraphAreaCountSettingTableValues {
  stream_id?: string;
  graph_type?: MetricGraphType;
}
interface SumUpGraphAreaCountSettingTableProps {
  index: number;
  metricStreams: Stream[];
  onMetricChange: (p: Metric[]) => void;
  values: SumUpGraphAreaCountSettingTableValues;
  metrics: Metric[];
  area_count_settings: SUAreaCountSetting[];
  name: string[];
  onAreaCountSettingsChange: (p: SUAreaCountSetting[]) => void;
}
interface SumUpGraphAreaCountSettingTableState {}

export default class SumUpGraphAreaCountSettingTable extends React.PureComponent<
  SumUpGraphAreaCountSettingTableProps,
  SumUpGraphAreaCountSettingTableState
> {
  constructor(props: SumUpGraphAreaCountSettingTableProps) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    // Createの場合は初期化
    if (this.props.metrics.length == 1 && !this.props.metrics[0].json_path) {
      this.props.onMetricChange([]);
      this.props.onAreaCountSettingsChange([]);
    }
    this.InputCheck();
  }
  // props.name が更新されたときに実行
  componentDidUpdate(new_props: SumUpGraphAreaCountSettingTableProps) {
    // props.name が変更されたか確認
    if (new_props.name !== this.props.name) {
      const new_metrics = this.props.metrics.map((metric) => {
        const sum_up_id = Number(metric.sum_up_id);
        // 配列のインデックスに合わせるために -1
        const name_index = sum_up_id - 1;
        // 新しい name を取得
        const new_name = this.props.name[name_index];
        return { ...metric, name: new_name };
      });

      // メトリクスを更新
      this.props.onMetricChange(new_metrics);
    }
  }

  private InputCheck = () => {
    return this.props.values.stream_id && this.props.values.graph_type;
  };
  new_metrics: Metric[] = [];
  new_area_count_settings: SUAreaCountSetting[] = [];
  private handleChange = (value: string, index: number, key: string) => {
    const new_metrics = [...this.props.metrics];
    const new_area_count_settings = this.props.area_count_settings.slice();
    if (key === 'sum_up_id') {
      new_area_count_settings[index].sum_up_id = value;
      new_metrics[index].sum_up_id = value;
    }
    if (key === 'area_name') {
      const area_name = value;
      new_area_count_settings[index].area_name = value;
      const count_target = new_area_count_settings[index].count_target.join(', ');
      const json_path = '$.`areacount(' + area_name + ', ' + count_target + ')`';
      new_metrics[index].json_path = json_path;
    }
    // 現状stack_idを使用しない想定のためコメントアウト
    // if (key === 'stack_id') {
    //   new_area_count_settings[index].stack_id = value;
    //   new_metrics[index].stack_id = value;
    // }
    this.props.onMetricChange(new_metrics);
    this.props.onAreaCountSettingsChange(new_area_count_settings);
  };
  private handleAddRow = () => {
    const new_metrics = this.props.metrics.slice();
    const new_area_count_settings = this.props.area_count_settings.slice();
    if (new_metrics.length >= 100) {
      return;
    }
    new_metrics.push({
      name: this.props.index + '-' + new_metrics.length,
      stream_id: this.props.values.stream_id || '',
      json_path: '',
      statistic_method: 'Sum',
      accumulation: 'NONE',
      stack_id: '',
      sum_up_id: '1',
      graph_type: this.props.values.graph_type,
    });
    this.props.onMetricChange(new_metrics);
    new_area_count_settings.push({
      area_name: '',
      count_target: [],
      sum_up_id: '1',
      // stack_id: '',
    });
    this.props.onAreaCountSettingsChange(new_area_count_settings);
  };
  private handleDeleteRow = () => {
    const new_metrics = this.props.metrics.slice();
    const new_area_count_settings = this.props.area_count_settings.slice();
    if (new_metrics.length <= 1 || this.new_area_count_settings.length) {
      AlertDialog.show('少なくとも1つ以上の入力が必要です');
      return;
    }
    new_metrics.pop();
    new_area_count_settings.pop();
    this.props.onMetricChange(new_metrics);
    this.props.onAreaCountSettingsChange(new_area_count_settings);
  };

  private handleCountTargetChange = (e: React.ChangeEvent<{ value: unknown }>, index: number) => {
    const selected_values = e.target.value as string[];
    const new_metrics = this.props.metrics.slice();
    const new_area_count_settings = this.props.area_count_settings.slice();

    const updated_count_target = selected_values.map((value) => {
      const found_item = count_target.find((item) => item.value === value);
      return found_item ? found_item.value : '';
    });

    new_area_count_settings[index].count_target = updated_count_target;
    this.props.onAreaCountSettingsChange(new_area_count_settings);

    const area_name = new_area_count_settings[index].area_name;
    const new_count_target = updated_count_target.join(', ');
    const json_path = '$.`areacount(' + area_name + ', ' + new_count_target + ')`';
    new_metrics[index].json_path = json_path;
    this.props.onMetricChange(new_metrics);
  };

  render() {
    return (
      <>
        {this.props.values.stream_id && this.props.values.graph_type && (
          <>
            {count_target ? (
              <WholeArea>
                {/* テーブル */}
                <TableArea>
                  <Table>
                    <Thead>
                      <tr>
                        {/* テーブルヘッダー */}
                        <Th style={{ width: 215 }}>表示名</Th>
                        <Th style={{ width: 70 }}>合算ID</Th>
                        <Th style={{ width: 100 }}>エリア名</Th>
                        <Th style={{ width: 120 }}>カウント対象</Th>
                        {/* <Th style={{ width: 70 }}>スタック</Th> */}
                      </tr>
                    </Thead>

                    {/* テーブルボディー */}
                    <Tbody>
                      {/* ボディーデータをソートした後に、一ページ分のデータに切り分ける */}
                      {this.props.area_count_settings.map((body, index) => {
                        return (
                          <Tr key={index}>
                            <Td key={`name_${index}`} last_row={index + 1 === this.props.metrics.length ? true : false}>
                              <InputBox
                                value={this.props.name[Number(this.props.metrics[index].sum_up_id) - 1] || ''}
                                onChange={() => {}}
                              />
                            </Td>
                            <Td
                              key={`sum_up_id_${index}`}
                              last_row={index + 1 === this.props.metrics.length ? true : false}
                            >
                              <SelectBox
                                value={this.props.metrics[index].sum_up_id || ''}
                                datas={Array.from({ length: this.props.name.length }, (_, i) => {
                                  return {
                                    value: (i + 1).toString(),
                                    name: (i + 1).toString(),
                                  };
                                })}
                                onChange={(e) => this.handleChange(e.currentTarget.value, index, 'sum_up_id')}
                                long
                              />
                            </Td>
                            <Td
                              key={`area_name_${index}`}
                              last_row={index + 1 === this.props.metrics.length ? true : false}
                            >
                              <SelectBox
                                value={body.area_name}
                                onChange={(e) => this.handleChange(e.currentTarget.value, index, 'area_name')}
                                datas={area_name.map((d) => {
                                  return {
                                    value: d,
                                    name: d,
                                  };
                                })}
                                style={{ width: '100%' }}
                              />
                            </Td>
                            <Td
                              key={`count_target_${index}`}
                              last_row={index + 1 === this.props.metrics.length ? true : false}
                            >
                              <Select
                                multiple
                                value={body.count_target}
                                onChange={(e) => this.handleCountTargetChange(e, index)}
                                style={{ width: '100%' }}
                              >
                                {count_target.map((item) => (
                                  <MenuItem key={item.name} value={item.value}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Td>
                            {/* <Td
                              key={`stack_id_${index}`}
                              last_row={index + 1 === this.props.metrics.length ? true : false}
                            >
                              <SelectBox
                                value={body.stack_id || ''}
                                datas={['1', '2', '3', '4', '5', '6', '7', '8', '9'].map((d) => {
                                  return {
                                    value: d,
                                    name: d,
                                  };
                                })}
                                onChange={(e) => this.handleChange(e.currentTarget.value, index, 'stack_id')}
                                long
                              />
                            </Td> */}
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableArea>

                {/* フッター */}
                <FooterArea>
                  <RoundedButton
                    text='削除'
                    onClick={this.handleDeleteRow}
                    style={{
                      width: styles.super_small_button_width,
                      height: styles.super_small_button_height,
                      marginRight: styles.interval_narrow_margin,
                    }}
                    is_white
                  />
                  <RoundedButton
                    text='追加'
                    onClick={this.handleAddRow}
                    style={{
                      width: styles.super_small_button_width,
                      height: styles.super_small_button_height,
                    }}
                  />
                </FooterArea>
              </WholeArea>
            ) : (
              <Spinner />
            )}
          </>
        )}
      </>
    );
  }
}
