// -- basic library --
import React from 'react';

// -- http connection library --

// -- external components --
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';

// -- external functions --
import DrawDrawCrosslines from 'shared/components/molecules/DrawCrosslines.tsx';
import { is2DLen2Array } from 'shared/utils/is';
import { checkXYWithinRange } from 'shared/utils/else/crosslineFunctions';
import { streamsIdDataGetAPI, streamsIdDataNumberThumbnailGetAPI } from 'user/api/streamsData';

interface CrossLine2DialogProps {
  is_open: boolean;
  value: string;
  stream_id: string;
  stream_data_number?: number;
  onClose: (value?: string) => void;
}
interface CrossLine2DoalogState {
  crosslines: number[][][]; // 線, 始点と終点, xとy
  imgsrc: string;
}
/** 検知ライン設定ダイアログ */
export default class CrossLine2Dialog extends React.PureComponent<CrossLine2DialogProps, CrossLine2DoalogState> {
  constructor(props: CrossLine2DialogProps) {
    super(props);
    this.state = {
      crosslines: parse(props.value),
      imgsrc: '',
    };
  }
  componentDidMount() {
    // パラメータにストリームデータ番号がある場合はそのサムネイルを取得する
    // 無い場合はストリームの最終のストリームデータを取得する（最新映像）
    if (this.props.stream_data_number) {
      this.loadThumbnail(this.props.stream_id, this.props.stream_data_number);
    } else {
      streamsIdDataGetAPI({ stream_id: this.props.stream_id, scan_index_forward: 'False' }).then((res) => {
        if (res.status === 200 && res.data.items.length !== 0) {
          this.loadThumbnail(this.props.stream_id, res.data.items[0].stream_data_number);
        }
      });
    }
  }

  // サムネイル画像の取得
  private loadThumbnail = (streamId: string, streamDataNumber: number) => {
    streamsIdDataNumberThumbnailGetAPI({
      stream_id: streamId,
      stream_data_number: String(streamDataNumber),
      stream_type: 'original',
    }).then((res) => {
      if (res.status === 200) {
        const blob = new Blob([res.data], { type: 'image/png' });
        const url = window.URL || window.webkitURL;
        this.setState({ imgsrc: url.createObjectURL(blob) });
        // ローカルでテストする用
        // this.setState({ imgsrc: 'https://idea-security-demo-hls-tmp-hasegawa.s3.ap-northeast-1.amazonaws.com/tairyuu1.jpeg' })
      }
    });
  };
  /**
   * 入力チェックを行い、エラーの場合はメッセージを含む例外をスローします。
   */
  private validate = () => {
    if (this.state.crosslines.length < 1) {
      throw new Error(`検知ラインを描画してください`);
    }
    if (this.state.crosslines.length > 4) {
      throw new Error(`検知ラインの最大描画数は4です`);
    }
    for (let i = 0; i < this.state.crosslines.length; i++) {
      const crossline = this.state.crosslines[i];
      if (!is2DLen2Array(crossline)) {
        throw new Error(
          `${i}番目の検知ラインの座標の形が正しくありません。\n 検知ラインはnumber[2][2]を満たす必要があります。`,
        );
      }
      if (
        !checkXYWithinRange({
          crossline: crossline,
          size: {
            // 割合で保持しているので1を超えないかどうか
            width: 1,
            height: 1,
          },
        })
      ) {
        throw new Error(`${i}番目の検知ラインの座標が画面をはみ出しています。`);
      }
    }
  };
  // 検知ラインの図形の設定
  private handleFinish = () => {
    // 入力チェック
    try {
      this.validate();
    } catch (e) {
      if (e instanceof Error) {
        AlertDialog.show(e.message);
      }
      return;
    }
    this.props.onClose(JSON.stringify(this.state.crosslines));
  };
  render() {
    return (
      <PfDialog
        isOpen={this.props.is_open}
        title='検知ライン設定'
        onClose={() => this.props.onClose()}
        contentProps={{ style: { display: 'flex', flexDirection: 'column' } }}
        large
      >
        <DrawDrawCrosslines
          crosslines={this.state.crosslines}
          imgsrc={this.state.imgsrc}
          onChange={(crosslines) => this.setState({ crosslines: crosslines })}
        />
        <div style={{ textAlign: 'center' }}>
          <RoundedButton text='キャンセル' is_white={true} onClick={() => this.props.onClose()} is_margin_right />
          <RoundedButton text='OK' onClick={this.handleFinish} />
        </div>
      </PfDialog>
    );
  }
}
/** JSON文字列をパースします。異常値の場合は [] にします。 */
function parse(s: string) {
  try {
    return JSON.parse(s);
  } catch (e) {
    return [];
  }
}

interface CrossLine2ButtonProps {
  value: string;
  streamId?: string;
  streamDataNumber?: number;
  onChange: (value: string) => void;
}
interface CrossLine2ButtonState {
  isOpen: boolean;
}
/** 検知ライン設定ダイアログを開くためのボタンです。 */
export class CrossLine2Button extends React.PureComponent<CrossLine2ButtonProps, CrossLine2ButtonState> {
  constructor(props: CrossLine2ButtonProps) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  private handleOpen = () => {
    this.setState({ isOpen: true });
  };
  private handleClose = (value?: string) => {
    this.setState({ isOpen: false });
    if (value !== undefined) {
      this.props.onChange(value);
    }
  };
  render() {
    return (
      <>
        <RoundedButton text='検知ライン設定' onClick={this.handleOpen} disabled={(this.props.streamId || '') === ''} />
        {this.state.isOpen && this.props.streamId && (
          <CrossLine2Dialog
            is_open={true}
            value={this.props.value}
            stream_id={this.props.streamId}
            stream_data_number={this.props.streamDataNumber}
            onClose={this.handleClose}
          />
        )}
      </>
    );
  }
}
