import sendAxios from 'shared/axios/sendAxios';
import { AppParameter } from 'shared/models/AppParameter';
import { CommonAPIRequestType, toAPIRequestParams } from 'shared/models/CommonAPIType';
import { CsvRow } from 'shared/models/CsvRow';
import { ISO8601 } from 'shared/models/ISO8601';
import { ProcessOutputType } from 'shared/models/ProcessOutputType';
import { Query } from 'shared/models/Query';
import { StatusType } from 'shared/models/StatusType';
import { StreamDataType } from 'shared/models/StreamDataType';
import { getClient } from './base';
import { JsonpathGroup } from './jsonpathGroups';
import { Process } from './processes';

/**
 * ルートチャンネルプロセスのストリームデータからチャンネルプロセスを取得する際のinterface
 */
export interface ChannelProcessByRoot {
  channel_process_number: number;
  channel_process_name: string;
  process_id: string;
  process_flow_id: string;
  input_stream_ids: string[];
  output_stream_ids: string[];
  process: Process;
  jsonpath_group?: JsonpathGroup;
  csv_rows?: CsvRow[];
  app_parameter: AppParameter;
  is_oneshot: boolean;
  input_stream_data_number?: number;
  delay_seconds: number;
  status: StatusType;
  created_at: ISO8601;
  updated_at: ISO8601;
  deleted_at: ISO8601 | null;
}

export interface ChannelProcessesByRoot {
  processes: ChannelProcessByRoot[];
}

const sample_channel_process_1: ChannelProcessByRoot = {
  channel_process_number: 1,
  channel_process_name: 'string',
  status: 'ACTIVE',
  process_id: '00000001',
  process_flow_id: '00000001',
  process: {
    tenant_id: '00000001',
    process_id: '00000001',
    process_name: 'プロセス名1',
    status: 'ACTIVE',
    input_stream_count: 1,
    input_stream_constraints: [
      {
        data_type: 'VIDEO',
        data_number_type: 'TIMESTAMP',
        max_repeats: 1,
      },
    ],
    output_stream_count: 5,
    app_parameters: [
      {
        key: 'jsonapth_group',
        name: 'JSONパスグループ名1',
        description: '',
        default_value: '00000001',
        input_type: 'JSONPATHGROUPS',
        list_items: [],
        required: false,
        validate_regax: '',
      },
      {
        key: '',
        name: '',
        description: '',
        default_value: '',
        input_type: 'CROSSLINE',
        list_items: [],
        required: false,
        validate_regax: '',
      },
    ],
    heartbeats: [
      {
        key: '',
        name: '',
        description: '',
        default_schedule: '',
        required: false,
      },
    ],
    can_be_oneshot: false,
  },
  jsonpath_group: {
    jsonpath_group_id: '00000001',
    jsonpath_group_name: 'JSONパスグループ名1',
    export_csv_statistics: 'NONE',
    category: 'AI',
    display: ['DASHBOARD'],
    csv_rows: [],
    memo: null,
    status: 'ACTIVE',
    created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
    updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
    deleted_at: null,
  },
  input_stream_ids: ['00000001', '00000001', '00000002'],
  output_stream_ids: ['00000001', '00000001', '00000002'],
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  updated_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  deleted_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  app_parameter: {
    jsonpath_group: '00000001',
    polygons: JSON.stringify([0.1, 0.2, 0.3, 0.3, 0.4, 0.4, 0.2, 0.2]),
  },
  input_stream_data_number: 1,
  is_oneshot: false,
  delay_seconds: 0,
};

export interface ProcessInfo {
  channel_id: string;
  channel_process_number: number;
  channel_process_name: string;
  processing_progress: number;
  created_at: ISO8601;
  outputs: ProcessInfoOutput[];
}

export interface ProcessInfoOutput {
  output_type: ProcessOutputType;
  output_name?: string;
  data_type: StreamDataType;
  stream_id: string;
  stream_data_number?: number;
  stream_package_number?: number;
  downloadable: boolean | null;
}

export interface StreamProcesses {
  processes: ProcessInfo[];
}

const sample_processinfo: ProcessInfo = {
  channel_id: '000000001',
  channel_process_number: 1,
  channel_process_name: 'プロセス1',
  processing_progress: 100,
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  outputs: [
    {
      output_type: 'DATA',
      data_type: 'VIDEO',
      stream_id: '000000001',
      stream_data_number: 1,
      downloadable: true,
    },
    {
      output_type: 'PACKAGE',
      data_type: 'METRIC',
      stream_id: '000000001',
      stream_package_number: 2,
      downloadable: false,
    },
  ],
};

const sample_processinfo2: ProcessInfo = {
  channel_id: '000000002',
  channel_process_number: 2,
  channel_process_name: 'プロセス2',
  processing_progress: 100,
  created_at: '2020-01-01T00:00:00+09:00' as ISO8601,
  outputs: [
    {
      output_type: 'DATA',
      data_type: 'VIDEO',
      stream_id: '000000001',
      stream_data_number: 3,
      downloadable: null,
    },
    {
      output_type: 'PACKAGE',
      data_type: 'METRIC',
      stream_id: '000000001',
      stream_package_number: 4,
      downloadable: null,
    },
  ],
};

/*** [GET] /api/streams/{stream_id}/data/{stream_data_number}/processinfo ***/

export interface RequestStreamsIdDataNumberProcessinfoGet extends CommonAPIRequestType {
  stream_id: string;
  stream_data_number: number;
}

export const streamsIdDataNumberProcessinfoGetAPI = (params: RequestStreamsIdDataNumberProcessinfoGet) => {
  const { stream_id, stream_data_number, disabled_load, disabled_error_message, ended_load } =
    toAPIRequestParams(params);
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/streams/${stream_id}/data/${stream_data_number}/processinfo`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<StreamProcesses>(axios, path, query, form, method, {
    processes: [sample_processinfo, sample_processinfo2],
  });
};

/***
 * [GET] /api/streams/processinfo/byroot/{channel_id}/{channel_process_number}
 * channel_idとchannel_process_numberをrootにもつチャンネルプロセスを取得する
 *
 * ***/

export interface RequestStreamsProcessinfoByrootIdNumGet {
  channel_id: string;
  channel_process_number: string;
}

export const streamsProcessinfoByrootIdNumGetAPI = (params: RequestStreamsProcessinfoByrootIdNumGet) => {
  // クライアントを定義
  const axios = getClient({});

  // パス・メソッドを定義
  const path = `/api/streams/processinfo/byroot/${params.channel_id}/${params.channel_process_number}`;
  const method = 'get';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<ChannelProcessesByRoot>(axios, path, query, form, method, {
    processes: [sample_channel_process_1],
  });
};

/***
 * [DELETE] /api/streams/processinfo/byroot/{channel_id}/{channel_process_number}
 * channel_idとchannel_process_numberをrootにもつチャンネルプロセスを削除する
 *
 * ***/

export interface RequestStreamsProcessinfoByrootIdNumDelete extends CommonAPIRequestType {
  channel_id: string;
  channel_process_number: string;
}

export const streamsProcessinfoByrootIdNumDeleteAPI = (params: RequestStreamsProcessinfoByrootIdNumDelete) => {
  const { channel_id, channel_process_number, disabled_load, disabled_error_message, ended_load } = toAPIRequestParams({
    ...params,
    api_send_type: params.api_send_type ?? 'multipleTransmission',
  });
  // クライアントを定義
  const axios = getClient({ disabled_load, disabled_error_message, ended_load });

  // パス・メソッドを定義
  const path = `/api/streams/processinfo/byroot/${channel_id}/${channel_process_number}`;
  const method = 'delete';

  // [get, put]クエリストリングを定義
  const query: Query = {};

  // [put, post]リクエストボディを定義
  const form = new FormData();
  // for (const [key, value] of Object.entries(params)) {
  //   form.append(key, value);
  // };

  // 送信
  return sendAxios<ChannelProcessesByRoot>(axios, path, query, form, method, {
    processes: [],
  });
};
