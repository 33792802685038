// -- basic library --
import React from 'react';

// -- http connection library --

// -- external components --
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';

// -- external functions --
import { StayAreaType, isStayArea, empty_stay_area } from 'shared/models/StayAreaType';
import { isRangeLen2Array } from 'shared/utils/is';
import { checkPolygonXYWithinRange, checkPolygonIntersect } from 'shared/utils/else/polygonFunctions';
import { TrimedImage } from 'shared/models';
import { streamsIdDataGetAPI, streamsIdDataNumberThumbnailGetAPI } from 'user/api/streamsData';
import StayAreaStepPanel from './StayAreaStepPanel';

/** JSON文字列をパースしStayAreaTypeを返却 */
function parse(s: string): StayAreaType {
  try {
    const parsed = JSON.parse(s);
    if (isStayArea(parsed)) return parsed;
    return empty_stay_area;
  } catch (e) {
    return empty_stay_area;
  }
}

// app_parameterはstringifyされてるからparseしてstateに格納

interface StayAreaDialogProps {
  is_open: boolean;
  value: string;
  stream_id: string;
  stream_data_number?: number;
  onClose: (value?: string) => void;
}
interface StayAreaDoalogState {
  cross_line: number[][][]; // エリア, 点, xy
  trimed_image?: TrimedImage;
  imgsrc: string;
}

/** 滞留エリア設定ダイアログ */
export default class StayAreaDialog extends React.PureComponent<StayAreaDialogProps, StayAreaDoalogState> {
  constructor(props: StayAreaDialogProps) {
    super(props);
    this.state = {
      cross_line: [],
      imgsrc: '',
    };
  }
  componentDidMount() {
    // パラメータにストリームデータ番号がある場合はそのサムネイルを取得する
    // 無い場合はストリームの最終のストリームデータを取得する（最新映像）
    if (this.props.stream_data_number) {
      this.loadThumbnail(this.props.stream_id, this.props.stream_data_number);
    } else {
      streamsIdDataGetAPI({ stream_id: this.props.stream_id, scan_index_forward: 'False' }).then((res) => {
        if (res.status === 200 && res.data.items.length !== 0) {
          this.loadThumbnail(this.props.stream_id, res.data.items[0].stream_data_number);
        }
      });
    }
    this.loadValue();
  }

  // サムネイル画像の取得
  private loadThumbnail = (streamId: string, stream_data_number?: number) => {
    streamsIdDataNumberThumbnailGetAPI({
      stream_id: streamId,
      stream_data_number: String(stream_data_number),
      stream_type: 'original',
    }).then((res) => {
      if (res.status === 200) {
        const blob = new Blob([res.data], { type: 'image/png' });
        const url = window.URL || window.webkitURL;
        this.setState({ imgsrc: url.createObjectURL(blob) });
        // ローカルでテストする用
        // this.setState({
        //   imgsrc: 'https://idea-security-demo-hls-tmp-hasegawa.s3.ap-northeast-1.amazonaws.com/tairyuu1.jpeg',
        // });
      }
    });
  };
  // app_parameter.STAYAREAをparseしてstateに格納
  private loadValue = () => {
    const { value } = this.props;
    const { trimed_image } = this.state;
    const stay_area_for_api: StayAreaType = parse(value);
    let new_trimed_image = { ...trimed_image };
    if (stay_area_for_api.cropArea) {
      new_trimed_image = { ...new_trimed_image, cropArea: stay_area_for_api.cropArea };
    }
    // 単純な存在条件だと0の時にfalse扱いになるため、typeで判定
    if (typeof stay_area_for_api.cropRotate === 'number') {
      new_trimed_image = { ...new_trimed_image, rotate: stay_area_for_api.cropRotate };
    }
    const new_state = { ...this.state, trimed_image: new_trimed_image, cross_line: stay_area_for_api.crossLine };
    this.setState(new_state);
  };
  // トリミングで完了を押した時の関数
  private onTrimedPanelChange = (trimed_image: TrimedImage) => {
    // トリミングされた画像のデータを更新
    this.setState({ trimed_image });
  };
  /**
   * 入力チェックを行い、エラーの場合はメッセージを含む例外をスローします。
   */
  private validate = () => {
    const { cross_line } = this.state;
    if (cross_line.length < 1) {
      throw new Error(`滞留エリアを描画してください`);
    }
    if (cross_line.length > 5) {
      throw new Error(`滞留エリアの最大描画数は5です`);
    }
    for (let i = 0; i < cross_line.length; i++) {
      const staya = cross_line[i];
      if (!isRangeLen2Array(staya, 4, 5)) {
        throw new Error(`${i}番目の図形の形式が異なります。\n 図形の座標は、number[4 or 5][2]を満たす必要があります。`);
      }
      if (
        !checkPolygonXYWithinRange({
          polygon: staya,
          size: {
            width: 1,
            height: 1,
          },
        })
      ) {
        throw new Error(`${i}番目の図形において、座標が画像サイズを超えています。`);
      }
      if (checkPolygonIntersect(staya)) {
        throw new Error(`${i}番目の図形において、直線が交差している点があります。\n交わる点が存在してはいけません`);
      }
    }
  };
  // 滞留検知の図形の設定
  private onFinish = () => {
    const { trimed_image, cross_line } = this.state;
    // 入力チェック
    try {
      this.validate();
    } catch (e) {
      if (e instanceof Error) {
        AlertDialog.show(e.message);
      }
      return;
    }
    const request_stay_area: StayAreaType = {
      cropArea: trimed_image?.cropArea,
      crossLine: cross_line,
      height: trimed_image?.size?.height,
      width: trimed_image?.size?.width,
      cropRotate: trimed_image?.rotate,
    };
    this.props.onClose(JSON.stringify(request_stay_area));
  };
  render() {
    const { cross_line, imgsrc, trimed_image } = this.state;
    return (
      <PfDialog
        isOpen={this.props.is_open}
        title='滞留エリア設定'
        onClose={() => this.props.onClose(undefined)}
        contentProps={{ style: { display: 'flex', flexDirection: 'column' } }}
        large
      >
        <StayAreaStepPanel
          imgsrc={imgsrc}
          trimed_image={trimed_image}
          cross_line={cross_line}
          onClose={() => this.props.onClose(undefined)}
          onCrossLineChange={(cross_line) => this.setState({ cross_line })}
          onTrimedImageChange={this.onTrimedPanelChange}
          onPanelFinish={this.onFinish}
        />
      </PfDialog>
    );
  }
}

interface StayAreaButtonProps {
  value: string;
  stream_id?: string;
  stream_data_number?: number;
  onChange: (value: string) => void;
}
interface StayAreaButtonState {
  is_open: boolean;
}
/** 滞留エリア設定ダイアログを開くためのボタンです。 */
export class StayAreaButton extends React.PureComponent<StayAreaButtonProps, StayAreaButtonState> {
  constructor(props: StayAreaButtonProps) {
    super(props);
    this.state = {
      is_open: false,
    };
  }
  private handleOpen = () => {
    this.setState({ is_open: true });
  };
  private handleClose = (value?: string) => {
    this.setState({ is_open: false });
    if (value !== undefined) {
      this.props.onChange(value);
    }
  };
  render() {
    return (
      <>
        <RoundedButton text='滞留エリア設定' onClick={this.handleOpen} disabled={(this.props.stream_id || '') === ''} />
        {this.state.is_open && this.props.stream_id && (
          <StayAreaDialog
            is_open={true}
            value={this.props.value}
            stream_id={this.props.stream_id}
            stream_data_number={this.props.stream_data_number}
            onClose={this.handleClose}
          />
        )}
      </>
    );
  }
}
