import React from 'react';
import PfAlert from 'shared/components/atoms/PfAlert';
import PfProgressBar from 'shared/components/atoms/PfProgressBar';

/**
 * プログレスバーと件数を表示するダイアログです。
 * シングルトンインスタンスを保持するため、画面上に２つ以上配置しないでください。
 * ダイアログの呼び出し方:
 * FileProcessesPercentNumberDialog.show(0, 4);
 * valueの更新の仕方
 * FileProcessesPercentNumberDialog.updateValue(0.3);
 * * valueの更新の仕方
 * FileProcessesPercentNumberDialog.updateFinishedNumber(1);
 * 中止のstateを取得
 * FileProcessesPercentNumberDialog.checkAborted()
 *
 */
interface FileProcessesPercentNumberDialogProps {}
interface FileProcessesPercentNumberDialogState {
  isOpen: boolean;
  value: number; // 0~1の割合
  onConfirm?: () => void;
  onAbort?: () => void;
  goal: number; //整数
  finished_number: number; //整数
}

export default class FileProcessesPercentNumberDialog extends React.PureComponent<
  FileProcessesPercentNumberDialogProps,
  FileProcessesPercentNumberDialogState
> {
  static instance?: FileProcessesPercentNumberDialog;
  constructor(props: FileProcessesPercentNumberDialogProps) {
    super(props);
    this.state = {
      isOpen: false,
      value: 0,
      goal: 0,
      finished_number: 0,
    };
  }
  componentDidMount() {
    FileProcessesPercentNumberDialog.instance = this;
  }
  componentWillUnmount() {
    FileProcessesPercentNumberDialog.instance = undefined;
  }
  static show(
    value: number,
    goal: number,
    option?: {
      onConfirm?: () => void;
      onAbort?: () => void;
    },
  ) {
    if (FileProcessesPercentNumberDialog.instance) {
      FileProcessesPercentNumberDialog.instance.setState({
        isOpen: true,
        value: value,
        goal: goal,
        onConfirm: option?.onConfirm,
        onAbort: option?.onAbort,
      });
    } else {
      alert(value);
    }
  }
  static updateValue(value: number) {
    if (FileProcessesPercentNumberDialog.instance) {
      FileProcessesPercentNumberDialog.instance.setState({ value: value });
    }
  }
  static updateFinishedNumber(fn: number) {
    if (FileProcessesPercentNumberDialog.instance) {
      FileProcessesPercentNumberDialog.instance.setState({ finished_number: fn });
    }
  }
  onConfirm() {
    if (!this.isFinished()) {
      return;
    }
    if (this.state.onConfirm) {
      this.state.onConfirm();
    }
    this.setState({ isOpen: false, value: 0, finished_number: 0, goal: 0, onAbort: undefined, onConfirm: undefined });
  }
  async onAbort() {
    // 完了していたら押せない
    if (this.isFinished()) {
      return;
    }
    // 中止を呼び出し元に知らせる
    if (this.state.onAbort) {
      this.state.onAbort();
    }
    this.setState({ isOpen: false, value: 0, finished_number: 0, goal: 0, onAbort: undefined, onConfirm: undefined });
  }
  isFinished() {
    const flag1 = this.state.finished_number >= this.state.goal;
    const flag2 = this.state.value >= 1;
    return flag1 && flag2;
  }
  render() {
    return (
      <>
        {this.state.isOpen ? (
          <PfAlert
            disabled_first_button={!this.isFinished()}
            disabled_last_button={this.isFinished()}
            cancelButtonText={'中止'}
            confirmButtonText={this.isFinished() ? 'OK' : undefined}
            onCancel={() => this.onAbort()}
            onConfirm={() => this.onConfirm()}
            isOpen={this.state.isOpen}
            body_width='100%'
          >
            <div style={{ display: 'block', width: '100%', textAlign: 'center' }}>
              <PfProgressBar value={this.state.value} width='100%' stripes={this.isFinished() ? false : true} />
              <div style={{ marginTop: 15 }}>{Math.min(Math.floor(this.state.value * 100), 100)}% / 100%</div>
              <div style={{ marginTop: 15 }}>
                {Math.min(~~this.state.finished_number, this.state.goal)}件 / {this.state.goal}件
              </div>
              <div style={{ marginTop: 15 }}>
                {this.isFinished() ? 'ファイル推論が完了しました' : '※中止すると、ファイル推論に失敗します。'}
              </div>
            </div>
          </PfAlert>
        ) : null}
      </>
    );
  }
}
