import React from 'react';
import { TextArea } from '@blueprintjs/core';
import { ChromePicker } from 'react-color';
import InputBox from 'shared/components/atoms/InputBox';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import CheckBox from 'shared/components/molecules/CheckBox';
import InputComponent from 'shared/components/molecules/InputComponent';
import { Layer, LayerObject, RectProps, TextProps } from '../EditorPanel/types';

interface ObjectPropertyDialogProps {
  layer: Layer;
  layerObject: LayerObject;
  onCancelClose: () => void;
  onOkClose: (layer: Layer, layerObject: LayerObject) => void;
}
interface ObjectPropertyDialogState {
  layerObject: LayerObject;
}
export default class ObjectPropertyDialog extends React.PureComponent<
  ObjectPropertyDialogProps,
  ObjectPropertyDialogState
> {
  constructor(props: ObjectPropertyDialogProps) {
    super(props);
    this.state = {
      layerObject: { ...props.layerObject },
    };
  }
  private handleChanged = (newProps: RectProps | TextProps) => {
    this.setState({ layerObject: { ...this.state.layerObject, ...newProps } });
  };
  private handleOkClick = () => {
    this.props.onOkClose(this.props.layer, Object.assign(this.props.layerObject, this.state.layerObject));
  };
  render() {
    return (
      <PfDialog isOpen={true} title='レイヤーオブジェクト' onClose={this.props.onCancelClose}>
        {this.props.layerObject.type === 'RECT' && (
          <RectForm rectProps={this.state.layerObject} onChanged={this.handleChanged} />
        )}
        {this.props.layerObject.type === 'TEXT' && (
          <TextForm textProps={this.state.layerObject} onChanged={this.handleChanged} />
        )}
        <div style={{ textAlign: 'center' }}>
          <RoundedButton text='キャンセル' is_white={true} onClick={() => this.props.onCancelClose()} />
          <RoundedButton text='OK' onClick={this.handleOkClick} />
        </div>
      </PfDialog>
    );
  }
}

export const RectForm: React.FC<{ rectProps: RectProps; onChanged: (rectProps: RectProps) => void }> = (props) => {
  const defaultMargin = { top: 0, left: 0, right: 0, bottom: 0 };
  const rgb = hex2rgb(props.rectProps.fill_color || '#000000');
  const color = {
    r: rgb[0],
    g: rgb[1],
    b: rgb[2],
    a: props.rectProps.opacity || 1,
  };
  return (
    <>
      <InputComponent text='背景色'>
        <ChromePicker
          color={color}
          onChangeComplete={(c) => props.onChanged({ ...props.rectProps, fill_color: c.hex, opacity: c.rgb.a || 1 })}
        />
      </InputComponent>
      <InputComponent text='エフェクト'>
        <CheckBox
          checked={props.rectProps.blur === true}
          onClick={() => props.onChanged({ ...props.rectProps, blur: props.rectProps.blur !== true })}
        />{' '}
        ぼかし加工
      </InputComponent>
      <InputComponent text='マージン'>
        top:{' '}
        <InputBox
          value={props.rectProps.margin?.top || 0}
          onChange={(e) =>
            props.onChanged({
              ...props.rectProps,
              margin: { ...defaultMargin, ...props.rectProps.margin, top: Number(e.currentTarget.value) },
            })
          }
          type='number'
          min={0}
          max={1}
        />
        left:{' '}
        <InputBox
          value={props.rectProps.margin?.left || 0}
          onChange={(e) =>
            props.onChanged({
              ...props.rectProps,
              margin: { ...defaultMargin, ...props.rectProps.margin, left: Number(e.currentTarget.value) },
            })
          }
          type='number'
          min={0}
          max={1}
        />
        right:{' '}
        <InputBox
          value={props.rectProps.margin?.right || 0}
          onChange={(e) =>
            props.onChanged({
              ...props.rectProps,
              margin: { ...defaultMargin, ...props.rectProps.margin, right: Number(e.currentTarget.value) },
            })
          }
          type='number'
          min={0}
          max={1}
        />
        bottom:{' '}
        <InputBox
          value={props.rectProps.margin?.bottom || 0}
          onChange={(e) =>
            props.onChanged({
              ...props.rectProps,
              margin: { ...defaultMargin, ...props.rectProps.margin, bottom: Number(e.currentTarget.value) },
            })
          }
          type='number'
          min={0}
          max={1}
        />
      </InputComponent>
    </>
  );
};

export const TextForm: React.FC<{ textProps: TextProps; onChanged: (textProps: TextProps) => void }> = (props) => {
  const rgb = hex2rgb(props.textProps.fill_color || '#000000');
  const color = {
    r: rgb[0],
    g: rgb[1],
    b: rgb[2],
    a: props.textProps.opacity || 1,
  };
  return (
    <>
      <InputComponent text='テキスト'>
        <TextArea
          defaultValue={props.textProps.text}
          onChange={(e) => props.onChanged({ ...props.textProps, text: e.currentTarget.value })}
          fill={true}
        />
      </InputComponent>
      <InputComponent text='フォントカラー'>
        <ChromePicker
          color={color}
          onChangeComplete={(c) => props.onChanged({ ...props.textProps, fill_color: c.hex, opacity: c.rgb.a || 1 })}
        />
      </InputComponent>
      <InputComponent text='フォントサイズ'>
        <InputBox
          value={props.textProps.font_size || 10}
          onChange={(e) => props.onChanged({ ...props.textProps, font_size: Number(e.currentTarget.value) })}
          type='number'
          min={10}
          max={240}
        />
      </InputComponent>
    </>
  );
};

function hex2rgb(hex: string) {
  if (hex.slice(0, 1) === '#') hex = hex.slice(1);
  if (hex.length === 3)
    hex = hex.slice(0, 1) + hex.slice(0, 1) + hex.slice(1, 2) + hex.slice(1, 2) + hex.slice(2, 3) + hex.slice(2, 3);

  return [hex.slice(0, 2), hex.slice(2, 4), hex.slice(4, 6)].map(function (str) {
    return parseInt(str, 16);
  });
}
