import { LineDirectionType } from './LineDirectionType';
import { convertDeviceGraphTypesToName, StreamDeviceGraphTargetType } from './StreamDeviceGraphTarget';
import { StreamDeviceGraphType } from './StreamDeviceGraphType';

export interface StreamDeviceGraph {
  id: string;
  graph_type: StreamDeviceGraphType;
  line_number: number;
  direction: LineDirectionType;
  target: StreamDeviceGraphTargetType;
}

export const getStreamGraphTitle = (stream_graph: StreamDeviceGraph) => {
  return `LINE${stream_graph.line_number}_${stream_graph.direction}_${convertDeviceGraphTypesToName(
    stream_graph.target,
  )}`;
};
