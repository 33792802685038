// -- basic library --
import React, { useState, useEffect, useRef } from 'react';

// -- redux library --

// -- http connection library --

// -- external components --
import InfiniteScroll from 'react-infinite-scroller';
import { Table, Tbody, Td, Th, Thead, Tr } from 'shared/components/atoms/PfTable';
import Spinner from 'shared/components/atoms/Spinner';
import styled from 'styled-components';
import { StreamPackageCsv, streamsIdPackagesNumberCsvGetAPI } from 'user/api/streamsPackage';

// -- external types --

// -- external functions --

// -- external datas --

// -- external functions --

interface Params {
  stream_id: string;
  stream_package_number: number;
}

// -- main component --

const CSVTable: React.FC<Params> = (params) => {
  // -- get external datas --

  // -- redux preparations --
  const ref = useRef<HTMLDivElement>(null);

  // -- local states --
  const [csvs, setCsvs] = useState<StreamPackageCsv[]>([]);
  const [headers, setHeaders] = useState<string[] | undefined>(undefined);
  const [hasmore, setHasmore] = useState<boolean>(true);
  // テーブルのサイドを固定にするか
  const side_sticky = true;
  // テーブルのヘッドを固定にするか
  const head_sticky = true;

  // -- functions --
  const getStreamPackageCsv = () => {
    streamsIdPackagesNumberCsvGetAPI({
      stream_id: params.stream_id,
      stream_package_number: params.stream_package_number,
      start_csv_index: 0,
      acquisitions: 50,
    }).then((res) => {
      if (res.status === 200) {
        const new_csvs = [];
        // csv_indexと配列のindexが合うように指定する。
        for (const d of res.data.items) {
          new_csvs[d.csv_index] = { ...d };
        }
        setCsvs(new_csvs);
        let new_headers = res.data.headers;
        // もし、headersが空配列ならば、データの0番目からヘッダー情報を得る。
        if (new_headers.length <= 0) {
          new_headers = new_csvs[0] ? Object.keys(new_csvs[0].csv) : [];
        }
        setHeaders(new_headers);
        // 次のデータがないのならばhasmoreをfalseにする
        if (!res.data.has_next) {
          setHasmore(false);
        }
      }
    });
  };
  const loadCsvMore = () => {
    if (csvs.length >= 1000 || !hasmore) {
      return;
    }
    streamsIdPackagesNumberCsvGetAPI({
      stream_id: params.stream_id,
      stream_package_number: params.stream_package_number,
      start_csv_index: csvs[csvs.length - 1] ? csvs[csvs.length - 1].csv_index + 1 : 0,
      acquisitions: 50,
    }).then((res) => {
      if (res.status === 200) {
        const new_csvs = [...csvs];
        // csv_indexと配列のindexが合うように指定する。
        for (const d of res.data.items) {
          new_csvs[d.csv_index] = { ...d };
        }
        setCsvs(new_csvs);
        // データの数が1000を超える or 次のデータがないのならば、これ以上データを取得しない
        if (new_csvs.length >= 1000 || !res.data.has_next) {
          setHasmore(false);
        }
      }
    });
  };

  useEffect(() => {
    getStreamPackageCsv();
  }, []); /* eslint-disable-line */

  // -- render part --
  return (
    <Wrapper ref={ref}>
      {headers === undefined ? (
        <Spinner />
      ) : (
        <InfiniteScroll
          pageStart={0}
          loadMore={() => loadCsvMore()}
          hasMore={hasmore}
          loader={<Spinner />}
          getScrollParent={() => ref.current}
          useWindow={false}
        >
          <Table sticky={head_sticky || side_sticky}>
            <Thead>
              <Th
                head_sticky={head_sticky}
                side_sticky={side_sticky}
                key={`index`}
                style={{ width: 100, textAlign: 'center' }}
              >
                index
              </Th>
              {headers.map((head, i) => {
                return (
                  <Th
                    head_sticky={head_sticky}
                    side_sticky={side_sticky}
                    key={`${head}_${i}`}
                    style={{ width: 100, textAlign: 'center' }}
                  >
                    {head}
                  </Th>
                );
              })}
            </Thead>
            <Tbody>
              {csvs.map((csv: StreamPackageCsv, i) => {
                return (
                  <Tr key={`tr_${csv}_${i}`}>
                    <Td
                      side_sticky={side_sticky}
                      key={`index_${i}`}
                      last_row={i === csvs.length - 1}
                      style={{ textAlign: 'center' }}
                    >
                      {csv.csv_index + 1}
                    </Td>
                    {Object.keys(csv.csv).map((key, j) => {
                      return (
                        <Td
                          side_sticky={side_sticky}
                          key={`${csv.csv[key]}_${i}_${j}`}
                          last_row={i === csvs.length - 1}
                          style={{ textAlign: 'center' }}
                        >
                          {csv.csv[key]}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </InfiniteScroll>
      )}
    </Wrapper>
  );
};

// -- styled components --
const Wrapper = styled.div`
  display: block;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: scroll;
`;

// -- finally export part --

export default CSVTable;
