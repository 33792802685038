import React from 'react';
import RoundedSelect from 'shared/components/atoms/RoundedSelect';

interface InputCronTextProps {
  value: string;
  onChange: (v: string) => void;
  disabled?: boolean;
}
/**
 * Cronのスケジュールの入力コンポーネントです。
 * (インターフェースを簡易にするため、全てのCron書式には対応していません。)
 *
 * 以下の書式のみサポート、Xは複数要素、Yは一要素
 * X月Y日Y時Y分
 * 毎月X日Y時Y分
 * 毎週X曜日Y時Y分
 * 毎日X時Y分
 * 毎時X分
 * 毎分
 *
 * 月・日・時・分・曜日を m d h i w で表し、大文字 MDHIWは複数選択可、小文字 mdhiwは単一選択。
 * 毎分の場合は、S を特殊状態とする。
 */
export default class InputCronText extends React.PureComponent<InputCronTextProps> {
  private split() {
    try {
      const val = this.props.value.split(' ');
      if (val.length === 5) return val;
      // eslint-disable-next-line no-empty
    } catch (e) {}
    return ['0', '0', '*', '*', '*'];
  }
  private type(val: string[]) {
    if (val[0] === '*') return 'S'; // 毎分
    if (val[1] === '*') return 'I'; // 毎時
    if (val[4] !== '*') return 'Whi'; // 毎週
    if (val[2] === '*') return 'Hi'; // 毎日
    if (val[3] === '*') return 'Dhi'; // 毎月
    return 'Mdhi'; // 指定日
  }
  private handleTypeChange = (v: string) => {
    switch (v) {
      case 'Mdhi':
        this.props.onChange('0 0 1 1 *');
        break;
      case 'Dhi':
        this.props.onChange('0 0 1 * *');
        break;
      case 'Hi':
        this.props.onChange('0 0 * * *');
        break;
      case 'I':
        this.props.onChange('0 * * * *');
        break;
      case 'S':
        this.props.onChange('* * * * *');
        break;
      case 'Whi':
        this.props.onChange('0 0 * * 0');
        break;
    }
  };
  private handleCompChange = (pos: number, v: string) => {
    const val = this.split();
    val[pos] = v;
    this.props.onChange(val.join(' '));
  };
  render() {
    if (this.props.disabled) {
      return null;
    }
    const val = this.split();
    const type = this.type(val);
    return (
      <>
        <RoundedSelect defaultValue={type} onChange={(e) => this.handleTypeChange(e.currentTarget.value)}>
          <option value='Mdhi'>--</option>
          <option value='Dhi'>毎月</option>
          <option value='Hi'>毎日</option>
          <option value='I'>毎時</option>
          <option value='S'>毎分</option>
          <option value='Whi'>毎週</option>
        </RoundedSelect>
        {type.indexOf('M') !== -1 && (
          // [TODO] To Multi
          <RoundedSelect defaultValue={val[3]} onChange={(e) => this.handleCompChange(3, e.currentTarget.value)}>
            {[...Array(12)].map((_, i) => {
              return (
                <option value={i + 1} key={i + 1}>
                  {i + 1}月
                </option>
              );
            })}
          </RoundedSelect>
        )}
        {type.indexOf('D') !== -1 && (
          // [TODO] To Multi
          <RoundedSelect defaultValue={val[2]} onChange={(e) => this.handleCompChange(2, e.currentTarget.value)}>
            {[...Array(31)].map((_, i) => {
              return (
                <option value={i + 1} key={i + 1}>
                  {i + 1}日
                </option>
              );
            })}
          </RoundedSelect>
        )}
        {type.indexOf('d') !== -1 && (
          <RoundedSelect defaultValue={val[2]} onChange={(e) => this.handleCompChange(2, e.currentTarget.value)}>
            {[...Array(31)].map((_, i) => {
              return (
                <option value={i + 1} key={i + 1}>
                  {i + 1}日
                </option>
              );
            })}
          </RoundedSelect>
        )}
        {type.indexOf('W') !== -1 && (
          // [TODO] To Multi
          <RoundedSelect defaultValue={val[4]} onChange={(e) => this.handleCompChange(4, e.currentTarget.value)}>
            <option value='1'>月曜日</option>
            <option value='2'>火曜日</option>
            <option value='3'>水曜日</option>
            <option value='4'>木曜日</option>
            <option value='5'>金曜日</option>
            <option value='6'>土曜日</option>
            <option value='0'>日曜日</option>
          </RoundedSelect>
        )}
        {type.indexOf('H') !== -1 && (
          // [TODO] To Multi
          <RoundedSelect defaultValue={val[1]} onChange={(e) => this.handleCompChange(1, e.currentTarget.value)}>
            {[...Array(24)].map((_, i) => {
              return (
                <option value={i} key={i + 1}>
                  {i}時
                </option>
              );
            })}
          </RoundedSelect>
        )}
        {type.indexOf('h') !== -1 && (
          <RoundedSelect defaultValue={val[1]} onChange={(e) => this.handleCompChange(1, e.currentTarget.value)}>
            {[...Array(24)].map((_, i) => {
              return (
                <option value={i} key={i + 1}>
                  {i}時
                </option>
              );
            })}
          </RoundedSelect>
        )}
        {type.indexOf('I') !== -1 && (
          // [TODO] To Multi
          <RoundedSelect defaultValue={val[0]} onChange={(e) => this.handleCompChange(0, e.currentTarget.value)}>
            {[...Array(60)].map((_, i) => {
              return (
                <option value={i} key={i + 1}>
                  {i}分
                </option>
              );
            })}
          </RoundedSelect>
        )}
        {type.indexOf('i') !== -1 && (
          <RoundedSelect defaultValue={val[0]} onChange={(e) => this.handleCompChange(0, e.currentTarget.value)}>
            {[...Array(60)].map((_, i) => {
              return (
                <option value={i} key={i + 1}>
                  {i}分
                </option>
              );
            })}
          </RoundedSelect>
        )}
      </>
    );
  }
}
