import { Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { memo } from 'react';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { table_cell_button_style } from 'shared/styles/styles';
import { AndroidDevice, androidDevicesIdPushPostAPI } from 'user/api/androidDevices';

interface AndroidDeviceNotifyButtonProps {
  android_device: AndroidDevice;
}
/**
 * Androidデバイスへの「通知」を行うボタンです。
 * あらかじめ定義している「計測開始」と「計測終了」のプッシュ通知を行います。
 */
const AndroidDeviceNotifyButton = memo(function AndroidDeviceNotifyButton({
  android_device,
}: AndroidDeviceNotifyButtonProps) {
  /** 通知メニューアイテムをクリックしたときのイベントハンドラです。 */
  const handleNotifyClick = (actionLabel: string, action: string) => {
    ConfirmDialog.show(getConfirmMessage(actionLabel), () => sendPushNotification(action));
  };
  const getConfirmMessage = (actionLabel: string) => {
    return `デバイス ${android_device.android_device_name || ''} に ${actionLabel} メッセージをプッシュ通知します。`;
  };
  const getMenu = () => {
    return (
      <Menu>
        <MenuItem text='計測開始' onClick={() => handleNotifyClick('計測開始', 'start_measurement')} />
        <MenuItem text='計測終了' onClick={() => handleNotifyClick('計測終了', 'stop_measurement')} />
      </Menu>
    );
  };
  /** Androidデバイスにプッシュ通知を送るAPIをコールします。 */
  const sendPushNotification = (action: string) => {
    androidDevicesIdPushPostAPI({
      android_device_id: android_device.android_device_id,
      action: action,
    }).then((value) => {
      if (value.status === 200 && value.data.success === 1) {
        AlertDialog.show('プッシュ通知の送信に成功しました。');
      } else {
        AlertDialog.show('プッシュ通知の送信に失敗しました。\n(' + value.data.message + ')');
      }
    });
  };

  return (
    <span onClick={(e) => e.stopPropagation()}>
      <Popover2 placement='bottom-start' modifiers={{ arrow: { enabled: false } }} content={getMenu()}>
        <RoundedButton text='通知' style={table_cell_button_style} />
      </Popover2>
    </span>
  );
});

export default AndroidDeviceNotifyButton;
