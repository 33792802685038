import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import history from 'shared/browserHistory';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import ConfirmDialog from 'shared/components/molecules/ConfirmDialog';
import { PageWholeArea, Title, TopArea, BottomArea } from 'shared/components/molecules/ContentsArea';
import { isNotSelected, isSelected } from 'shared/utils/is';
import { dateToYMDHMS } from 'shared/utils/converter/date';
import { CachedDashboards, dashboardsIdDeleteAPI } from 'user/api/dashboards';
import { authSelector } from 'user/redux/slices/authSlice';
import { loadWrapperFunc } from 'user/utils/loadWrapperFunc';
import { preloadGroup } from 'user/utils/preloadGroup';
import DashboardsCreateDialog from '../CreateDialog';
import { TableDashbordsType } from './DashboardsTable';
import DashboardsTable from './DashboardsTable';

// -- main component --
const Dashboards: React.FC = () => {
  // -- redux preparations --
  const auth_state = useSelector(authSelector);
  const table_massege = auth_state.is_admin ? '＞チェックした項目を削除する' : '＞項目削除は管理者のみできます';
  const is_user_type = !auth_state.is_admin;

  // -- local states --
  const [selected_bodies, setSelectedBodies] = useState<TableDashbordsType[]>([]);
  const [table_bodies, setTableBodies] = useState<TableDashbordsType[] | undefined>(undefined);

  // 作成画面用
  const [open_create_dialog, setOpenCreateDialog] = useState<boolean>(false);

  // -- handlers --
  // クリックした時の関数たち

  const handleCreateClick = useCallback(() => {
    setOpenCreateDialog(true);
  }, []);

  const handleOKClick = (selected_bodies: TableDashbordsType[]) => {
    // 削除関数定義
    const deletes = async () => {
      await Promise.all(
        selected_bodies.map((sd) =>
          dashboardsIdDeleteAPI({
            dashboard_id: sd.id,
            ended_load: false,
          }),
        ),
      );

      await getDatas();
      setSelectedBodies([]);
    };
    // 実行
    loadWrapperFunc(deletes);
  };

  const handleCancelClick = () => {
    history.push('/dashboards');
  };

  const handleDeleteClick = async (selected_bodies: TableDashbordsType[]) => {
    if (selected_bodies.length <= 0) {
      AlertDialog.show('チェックした項目がありません');
      return;
    }
    ConfirmDialog.show(
      <div>
        [確認]選択されているダッシュボードを削除します。
        <br />
        本当によろしいですか？
      </div>,
      () => handleOKClick(selected_bodies),
      handleCancelClick,
      undefined,
    );
  };
  // テーブルをクリックする関数
  const handleCheckClick = (bodies: TableDashbordsType[]) => {
    setSelectedBodies(bodies);
  };

  // データを取得する関数たち

  const getDatas = async () => {
    const items = await new CachedDashboards({}).get();

    const return_table_bodies: TableDashbordsType[] = [];
    for (let i = 0; i < items.length; i++) {
      const return_body: TableDashbordsType = {
        id: items[i].dashboard_id,
        dashboard_name: {
          value: items[i].dashboard_name,
          url: `/dashboards/${items[i].dashboard_id}`,
        },
        created_at: dateToYMDHMS(items[i].created_at),
        updated_at: dateToYMDHMS(items[i].updated_at),
        creator_user_name: items[i].creator_user_name,
      };

      return_table_bodies.push(return_body);
    }
    setTableBodies(return_table_bodies);
  };

  // 作成用関数たち

  const handleDialogCloseClick = useCallback(async () => {
    setOpenCreateDialog(false);
    await getDatas();
  }, []);

  // -- onload function --
  useEffect(() => {
    preloadGroup(getDatas());
  }, []);

  // -- render part --
  return (
    <PageWholeArea data-testid='Dashboard'>
      <TopArea>
        <Title text='ダッシュボード' />
        {auth_state.is_admin && (
          <RoundedButton
            onClick={handleCreateClick}
            text='ダッシュボード作成'
            disabled={isSelected(selected_bodies.length)}
          />
        )}
      </TopArea>
      <BottomArea>
        {table_bodies !== undefined ? (
          <DashboardsTable
            bodies={table_bodies}
            selected_bodies={selected_bodies}
            handleCheckClick={handleCheckClick}
            footer_option={{
              text: table_massege,
              handleClick: () => handleDeleteClick(selected_bodies),
              disabled: isNotSelected(selected_bodies.length) || is_user_type,
            }}
          />
        ) : (
          <Spinner />
        )}
        {open_create_dialog && (
          <DashboardsCreateDialog handleDialogCloseClick={handleDialogCloseClick} is_open={open_create_dialog} />
        )}
      </BottomArea>
    </PageWholeArea>
  );
};

// -- styled components --

// -- finally export part --

export default Dashboards;
