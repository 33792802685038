// -- basic library --
import React from 'react';

// -- external datas --
import play_svg from 'assets/play.svg';
import FunctionalText from 'shared/components/atoms/FunctionalText';
import styles from 'shared/styles/styles';
import styled from 'styled-components';

// -- type declaration --
interface Params {
  is_video: boolean;
  text: string;
  onClick: (event?: React.MouseEvent<HTMLDivElement>) => void;
  color?: string;
}

// -- main component --

const StreamText: React.FC<Params> = (params) => {
  // -- render part --
  return (
    <WholeArea>
      <FunctionalText
        onClick={params.onClick}
        text={params.text}
        color={params.color}
        style={{
          fontSize: styles.normal_text_size,
          fontWeight: 'bold',
          maxWidth: '100%',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      />
      {params.is_video ? <IconImg src={play_svg} /> : null}
    </WholeArea>
  );
};

// -- styled components --

const WholeArea = styled.div`
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
`;

const IconImg = styled.img`
  width: 20px;
  height: 20px;
  margin-left: ${styles.interval_narrow_margin};
`;

// -- finally export part --

export default StreamText;
