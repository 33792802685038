import { memo, useCallback, useEffect, useState } from 'react';
import Spinner from 'shared/components/atoms/Spinner';
import { Content } from 'shared/components/molecules/ContentsArea';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableHeaderType, TableBodyUrlType } from 'shared/components/molecules/Table/type';
import { toSoracomStatusJP } from 'shared/models/SoracomStatus';
import { Device } from 'user/api/device';

// -- type declaration --

// デバイステーブルのtype
type TableDeviceType = {
  id: string;
  device_name: TableBodyUrlType;
  device_type: string;
  status: string;
};

// テーブルの列の情報まとめたデータ
const headers: TableHeaderType[] = [
  {
    id: 'device_name',
    label: 'デバイス名',
    width: '17%',
    sortable: true,
    search_props: {
      type: 'name',
      default_display: true,
    },
  },
  {
    id: 'id',
    label: '端末ID',
    width: '23%',
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'device_type',
    label: 'モデル名',
    width: 'auto',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'status',
    label: 'ステータス',
    width: '120px',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
];

export interface DevicesTableProps {
  devices: Device[];
}

// -- main component --
const DevicesTable = memo(function DevicesTable({ devices }: DevicesTableProps) {
  // -- local states --
  const [table_bodies, setTableBodies] = useState<TableDeviceType[] | undefined>(undefined);
  const [selected_bodies, setSelectedBodies] = useState<TableDeviceType[]>([]);

  // -- handlers --
  const handleCheckClick = useCallback((datas: TableDeviceType[]) => {
    setSelectedBodies(datas);
  }, []);

  const loadTableBodies = useCallback(() => {
    const return_table_datas: TableDeviceType[] = [];
    for (let i = 0; i < devices.length; i++) {
      const return_top_table_data: TableDeviceType = {
        id: devices[i].device_id,
        device_name: {
          value: devices[i].device_name,
          url: `/devices/${devices[i].device_id}/overviews`,
        },
        status: toSoracomStatusJP(devices[i].soracom_status),
        device_type: devices[i].device_type,
      };

      return_table_datas.push(return_top_table_data);
    }
    setTableBodies(return_table_datas);
  }, [devices]);

  // -- onload function --
  useEffect(() => {
    loadTableBodies();
  }, [loadTableBodies]);

  // -- render part --
  return (
    <Content>
      {table_bodies === undefined ? (
        <Spinner />
      ) : (
        <BaseTable
          bodies={table_bodies}
          headers={headers}
          table_name='devices'
          selected_bodies={selected_bodies}
          handleCheckClick={handleCheckClick}
        />
      )}
    </Content>
  );
});

// -- styled components --

// -- finally export part --

export default DevicesTable;
