import React, { useState, useEffect } from 'react';
import PfDialog from 'shared/components/atoms/PfDialog';
import { CheckBoxArea } from 'shared/components/atoms/PfTable';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import Spinner from 'shared/components/atoms/Spinner';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import CheckBoxWithText from 'shared/components/molecules/CheckBoxWithText';
import { Content, Footer } from 'shared/components/molecules/ContentsArea';
import { Stream } from 'user/api/streams';
import { StreamData, streamsIdDataGetAPI } from 'user/api/streamsData';
import FileCardDisplayingStreamData from 'user/components/molecules/FileCardDisplayingStreamData';

interface SelectStreamDataDialogProps {
  stream: Stream;
  stream_data_number: number | string;
  selected_stream_data_list: (number | string)[];
  handleSelectedStreamDataList: (stream_data_list: (number | string)[]) => void;
  all_selected: boolean;
  handleSetAllSelected: (checked: boolean) => void;
  handleSetStreamDatas: (streamdatas: (number | string)[]) => void;
  isOpen: boolean;
  onClose: (canceled?: boolean) => void;
}
const SelectStreamDataDialog: React.FC<SelectStreamDataDialogProps> = (params) => {
  const [stream_data_list, setStreamDataList] = useState<StreamData[]>();

  const loadStreamData = async () => {
    const res = await streamsIdDataGetAPI({
      stream_id: params.stream.stream_id,
    });
    if (res.status === 200) {
      setStreamDataList(res.data.items);
    }
  };

  const handleCardClick = (stream_data_number: number | string) => {
    // あれば削除
    if (params.selected_stream_data_list.includes(stream_data_number)) {
      // 新しい配列を再生成
      const new_stream_data_list = params.selected_stream_data_list.filter((n) => n !== stream_data_number);
      params.handleSelectedStreamDataList(new_stream_data_list);
      // 全選択ボタンのチェックも外す
      params.handleSetAllSelected(false);
    } else {
      // なければ追加
      const new_stream_data_list = [...params.selected_stream_data_list, stream_data_number];
      params.handleSelectedStreamDataList(new_stream_data_list);
    }
  };

  const handleAllSelected = () => {
    const newAllSelected = !params.all_selected;
    if (newAllSelected) {
      if (stream_data_list) {
        const all_stream_data_list: (number | string)[] = [];
        stream_data_list.forEach((sd) => {
          all_stream_data_list.push(sd.stream_data_number);
        });
        params.handleSelectedStreamDataList(all_stream_data_list);
      }
    } else {
      params.handleSelectedStreamDataList([params.stream_data_number]);
    }
    params.handleSetAllSelected(newAllSelected);
  };

  const handleFinishClick = () => {
    if (params.selected_stream_data_list.length === 0) {
      AlertDialog.show(`ファイルを1件以上選択してください`);
      return;
    }
    params.handleSetStreamDatas(params.selected_stream_data_list);
    params.onClose();
  };

  // -- onload function --
  useEffect(() => {
    (async function () {
      await loadStreamData();
    })();
  }, []); /* eslint-disable-line */

  // -- render part --
  return (
    <PfDialog isOpen={params.isOpen} onClose={() => params.onClose(true)} title='複数ファイル選択'>
      <CheckBoxArea>
        <CheckBoxWithText text='全選択' checked={params.all_selected} onClick={() => handleAllSelected()} />
      </CheckBoxArea>
      <Content
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          flexWrap: 'wrap',
        }}
      >
        {stream_data_list ? (
          stream_data_list.map((sd, i) => {
            return (
              <FileCardDisplayingStreamData
                stream={params.stream}
                stream_data={sd}
                key={`${sd.stream_id}_${sd.stream_data_number}_${i}`}
                card_checkbox={params.selected_stream_data_list.includes(sd.stream_data_number)}
                handleCardClick={(stream_data_number) => handleCardClick(stream_data_number)}
              />
            );
          })
        ) : (
          <Spinner />
        )}
      </Content>
      <Footer>
        <RoundedButton onClick={() => handleFinishClick()} text='決定' />
      </Footer>
    </PfDialog>
  );
};

export default SelectStreamDataDialog;
