import { colors } from 'shared/styles/colors';
import styled from 'styled-components';

export const EditorList = styled.div`
  border: 1px solid rgb(145, 145, 145);
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const EditorListItem = styled.div<{ selected: boolean }>`
  ${(props) => (props.selected ? `background-color: ${colors.gray};` : '')}
  color: ${(props) => (props.selected ? colors.dark_gray : colors.component_text_color)};
  cursor: pointer;
  display: flex;
  &:hover {
    background-color: ${colors.gray};
  }
`;
