import { Metric } from 'user/api/dashboardsWidgets';
import { Stream } from 'user/api/streams';
import { AccumulationType, AccumulationTypes, convertAccumulationType } from 'shared/models/AccumulationType';
import InputBox from 'shared/components/atoms/InputBox';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import JsonpathInput from 'user/components/molecules/JsonpathInput';
import SelectBox from 'shared/components/atoms/SelectBox';
import React, { useCallback, useState } from 'react';
import topmenu_icon_question from 'assets/topmenu_icon_question.png';

import { graph_types, MetricGraphType, toMetricGraphType } from 'shared/models/MetricGraphType';
import styles from 'shared/styles/styles';
import CheckBoxWithText from 'shared/components/molecules/CheckBoxWithText';
import { MetricStatisticMethod } from 'shared/models/MetricStatisticMethod';
import { Thead, Th, Tbody, Tr, Td, WholeArea, Table, TableArea, FooterArea } from './StyledComponent';
import { CheckBoxArea } from 'shared/components/atoms/PfTable';
import CheckBox from 'shared/components/molecules/CheckBox';
import { useStreamDataReplacementItems } from 'user/hooks/useStreamDataReplacementItems/useStreamDataReplacementItems';
import InputComponent from 'shared/components/molecules/InputComponent';
import styled from 'styled-components';
import { DownIcon, UpIcon } from 'shared/components/atoms/Icons';
import { Spinner } from '@blueprintjs/core';
import Popover from 'shared/components/atoms/Popover';
import { PopoverWholeArea } from 'shared/components/molecules/ContentsArea';
import IconButton from 'shared/components/molecules/IconButton';
import InputNumberBox from 'shared/components/atoms/InputNumberBox';

interface GraphMetricsTableProps {
  values: Metric[];
  streams: Stream[];
  gp_stream_id: string;
  graph_type: MetricGraphType;
  isGraphType: boolean;
  onCreate: (p: boolean) => void;
  onChange: (value: Metric[]) => void;
  isLiveText: boolean;
}
const GraphMetricsTable: React.FC<GraphMetricsTableProps> = (props) => {
  const [stream_display, setStreamDisplay] = useState<boolean>(false);
  const [selected_indexes, setSelectedIndexes] = useState<number[]>([]);
  const [replacement_camera_id, setReplacementCameraId] = useState<string>('');
  const [replacement_line_no, setReplacementLineNo] = useState<string>('');
  const handleChange = (value: string, index: number, key: string) => {
    const new_metrics = props.values.slice();
    if (key === 'name') {
      new_metrics[index].name = value;
    }
    if (key === 'json_path') {
      new_metrics[index].json_path = value;
    }
    if (key === 'stream_id') {
      new_metrics[index].stream_id = value;
    }
    if (key === 'accumulation') {
      new_metrics[index].accumulation = convertAccumulationType(value);
    }
    if (key === 'stack_id') {
      new_metrics[index].stack_id = value;
    }
    if (key === 'graph_type') {
      new_metrics[index].graph_type = toMetricGraphType(value);
    }
    props.onChange(new_metrics);
  };
  const [is_open_menu_popover, setIsOpenMenuPopover] = useState<boolean>(false);
  const open = useCallback(() => {
    setIsOpenMenuPopover(true);
  }, []);
  const close = useCallback(() => {
    setIsOpenMenuPopover(false);
  }, []);
  // テーブルのヘッダーがチェックがどうか判断
  const checkHeaderChecked = () => {
    return props.values.length > 0 && props.values.length === selected_indexes.length ? true : false;
  };
  // n行を追加するのnの部分
  const [add_row_number, setAddRowNumber] = useState<number>(1);
  const onChangeAddRowNumber = (value: number) => {
    if (Number.isNaN(value)) {
      setAddRowNumber(value);
    } else {
      setAddRowNumber(Math.min(Math.max(1, value), 500));
    }
  };

  const handleHeadCheckClick = () => {
    let arr: number[] = [];
    if (selected_indexes.length < props.values.length) {
      arr = new Array(props.values.length).fill(null).map((_, i) => i);
    }
    setSelectedIndexes(arr);
  };
  const handleCheckClick = (selected_index: number) => {
    let new_selected_indexes = [...selected_indexes];
    if (selected_indexes.includes(selected_index)) {
      new_selected_indexes = selected_indexes.filter((si) => si !== selected_index);
    } else {
      new_selected_indexes.push(selected_index);
    }
    setSelectedIndexes(new_selected_indexes);
  };
  const handleClickReprace = () => {
    // 置換ボタン押下
    const new_metrics = props.values.slice();
    new_metrics.forEach((metric, index) => {
      // selected_indexesに該当するかつmetric.json_pathが有効な場合metrics.json_pathを置換
      if (selected_indexes.includes(index) && metric.json_path) {
        const json_path = metric.json_path.split('.');
        if (json_path[1] == ('ts' || 'ts_send')) return;
        let new_json_path = '';
        for (let i = 0; i < json_path.length; i++) {
          // json_path[i]の最初の文字が'c'または'C'であり、replacement_camera_idが存在する場合置換
          if (replacement_camera_id && json_path[i] && (json_path[i][0] === 'c' || json_path[i][0] === 'C')) {
            json_path[i] = replacement_camera_id;
          }
          // json_path[i]が 'LINE' で始まるかどうかを確認し、replacement_line_noが存在する場合置換
          if (replacement_line_no && json_path[i].startsWith('LINE')) {
            const new_line = json_path[i].split('_');
            new_line[0] = replacement_line_no;
            new_json_path += '.' + new_line.join('_');
          } else {
            if (i != 0) {
              new_json_path += '.' + json_path[i];
            } else {
              new_json_path += json_path[i];
            }
          }
        }
        new_metrics[index].json_path = new_json_path;
      }
    });
    props.onChange(new_metrics);
    // 各項目の初期化
    setSelectedIndexes([]);
    setReplacementCameraId('');
    setReplacementLineNo('');
    AlertDialog.show('置換に成功しました。');
  };
  const handleChangeStatisticMethod = (value: MetricStatisticMethod, index: number) => {
    const new_metrics = props.values.slice();
    new_metrics[index].statistic_method = value;
    props.onChange(new_metrics);
  };
  const handleAddRow = () => {
    const new_metrics = props.values.slice();
    if (new_metrics.length >= 100) {
      AlertDialog.show('メトリクスは最大で100行までです。');
      return;
    }
    new_metrics.push({
      name: '',
      stream_id: props.gp_stream_id ? props.gp_stream_id : '',
      json_path: '',
      statistic_method: '',
      accumulation: 'NONE',
      stack_id: '1',
      graph_type: props.graph_type,
    });
    props.onChange(new_metrics);
  };
  // row_numberの数だけ上に行を追加する。(MAX500)
  const handleAddSelectedRowClick = (row_number = 1, selected_index: number[]) => {
    if (Number.isNaN(row_number)) {
      AlertDialog.show('行数を1~500の間で入力してください');
      return;
    }
    const new_metrics = props.values.slice();
    if (new_metrics.length >= 500) {
      AlertDialog.show('すでにCSV定義は最大の500行に達しています。');
      return;
    }
    // MINは1
    let add_row_number = Math.max(1, row_number);
    // 500行が最大なので、その差分がMAX
    if (add_row_number > 500 - new_metrics.length) {
      add_row_number = Math.min(add_row_number, 500 - new_metrics.length);
      AlertDialog.show(`CSV定義は最大で500行までの為、${500 - new_metrics.length}行のみ追加しました`);
    }

    const createNewRow = () => ({
      name: '',
      stream_id: props.gp_stream_id ? props.gp_stream_id : '',
      json_path: '',
      statistic_method: '' as MetricStatisticMethod,
      accumulation: 'NONE' as AccumulationType,
      stack_id: '1',
      graph_type: props.graph_type as MetricGraphType,
    });

    // 各 selected_index に新しい行を追加する
    // インデックスを昇順にソート
    selected_index.sort((a, b) => a - b);
    const rows_to_add = add_row_number;

    let offset = 0;
    const new_selected_index = [];

    for (let idx = 0; idx < selected_index.length; idx++) {
      const index = selected_index[idx] + offset;
      if (new_metrics.length + rows_to_add <= 500) {
        const new_rows = Array.from({ length: rows_to_add }, createNewRow);
        new_metrics.splice(index, 0, ...new_rows);
        // 追加した行数分、オフセットを増加
        offset += rows_to_add;
        // 新しいインデックスを保存
        new_selected_index.push(index + rows_to_add);
      } else {
        AlertDialog.show(`追加する行数が500行を超えるため、${500 - new_metrics.length}行のみ追加しました`);
        const remaining_rows = 500 - new_metrics.length;
        if (remaining_rows > 0) {
          const new_rows = Array.from({ length: remaining_rows }, createNewRow);
          new_metrics.splice(index, 0, ...new_rows);
          // 新しいインデックスを保存
          new_selected_index.push(index + remaining_rows);
        }
         // 500行を超えたらループを終了
        break;
      }
    }

    props.onChange(new_metrics);
    setSelectedIndexes(new_selected_index);
  };
  // CSV定義を削除する関数
  const handleDeleteSelectedRowClick = (selected_index: number[]) => {
    let new_metrics = props.values.slice();
    if (selected_index.length <= 0) {
      AlertDialog.show('削除する行を選択してください');
      return;
    }
    new_metrics = new_metrics.filter((ncr, i) => !selected_index.includes(i));
    props.onChange(new_metrics);
    // 選択されていたテーブルのindexは消去
    setSelectedIndexes([]);
  };
  const handleDeleteRow = () => {
    const new_metrics = props.values.slice();
    if (new_metrics.length <= 1) {
      AlertDialog.show('メトリクスは少なくとも1つ以上の入力が必要です');
      return;
    }
    new_metrics.pop();
    props.onChange(new_metrics);
  };
  const handleCreateClick = () => {
    //設定からやり直すをクリックした場合、グラフ情報設定を活性化し、グラフ情報を非活性にする
    props.onCreate(false);
  };
  const handleStreamDisplayCheck = () => {
    setStreamDisplay(stream_display ? false : true);
  };
  const statistic_methods = [
    { name: '最大値', value: 'Maximum' },
    { name: '最小値', value: 'Minimum' },
    { name: '合計値', value: 'Sum' },
    { name: '平均値', value: 'Average' },
    { name: '値の数', value: 'Count' },
  ];

  const { camera_id, line_no } = useStreamDataReplacementItems({ stream_id: props.gp_stream_id });

  const handleUpClick = (index: number) => {
    const new_metrics = [...props.values];
    if (index >= 1) {
      [new_metrics[index], new_metrics[index - 1]] = [new_metrics[index - 1], new_metrics[index]];
    }
    props.onChange(new_metrics);
  };

  const handleDownClick = (index: number) => {
    const new_metrics = [...props.values];
    if (index <= new_metrics.length - 2) {
      [new_metrics[index], new_metrics[index + 1]] = [new_metrics[index + 1], new_metrics[index]];
    }
    props.onChange(new_metrics);
  };

  return (
    <WholeArea>
      {/* 一括置換 */}
      <TopArea>
        <Popover
          content={
            <QuestionPopoverWholeArea>
              当置換は以下のような形式のJSONにのみ適用できます。
              <br />
              <br />
              $.c0.LINE0_LR_****
              <br />
              ($.カメラID.LINE番号_LR_****)
              <br />
              <br />
              上記以外のJSONパスグループに対して置換を行うとウィジェットの表示が無効となります。
            </QuestionPopoverWholeArea>
          }
          isOpen={is_open_menu_popover}
          onClose={close}
        >
          <IconButton
            img_src={topmenu_icon_question}
            style={{ width: 25, height: 25, marginLeft: 10 }}
            handleClick={open}
          />
        </Popover>
        {camera_id.length !== 0 || line_no.length !== 0 ? (
          <>
            <InputComponent text='カメラID' style={{ width: '70px', marginRight: '5px' }}>
              <SelectBox
                onChange={(e) => setReplacementCameraId(e.currentTarget.value)}
                value={replacement_camera_id || ''}
                datas={camera_id.map((c) => ({ name: c, value: c }))}
                style={{ width: 70 }}
              />
            </InputComponent>
            <InputComponent text='LINE番号' style={{ width: '70px', marginRight: '5px' }}>
              <SelectBox
                onChange={(e) => setReplacementLineNo(e.currentTarget.value)}
                value={replacement_line_no || ''}
                datas={line_no.map((l) => ({ name: l, value: l }))}
                style={{ width: 70 }}
              />
            </InputComponent>
            <RoundedButton
              disabled={!selected_indexes.length || (!replacement_line_no && !replacement_camera_id)}
              onClick={() => handleClickReprace()}
              text='置換'
              style={{
                width: styles.super_small_button_width,
                height: styles.super_small_button_height,
                marginRight: styles.interval_narrow_margin,
              }}
            />
          </>
        ) : (
          <Spinner size={20} />
        )}
      </TopArea>
      {/* テーブル */}
      <TableArea>
        <Table>
          <Thead>
            <tr>
              {/* テーブルヘッダー */}
              <Th style={{ width: 35 }}></Th>
              <Th style={{ width: 35 }}>
                <CheckBoxArea>
                  <CheckBox checked={checkHeaderChecked()} onClick={handleHeadCheckClick} />
                </CheckBoxArea>
              </Th>
              <Th style={{ width: 215 }}>表示名</Th>
              <Th style={{ width: 215 }}>JSONパス</Th>
              {stream_display && <Th style={{ width: 445 }}>対象データ</Th>}
              <Th style={{ width: 100 }}>集計方法</Th>
              <Th style={{ width: 100 }}>累積期間</Th>
              <Th style={{ width: 70 }}>スタック</Th>
              <Th style={{ width: 120 }}>グラフタイプ</Th>
            </tr>
          </Thead>

          {/* テーブルボディー */}
          <Tbody>
            {/* ボディーデータをソートした後に、一ページ分のデータに切り分ける */}
            {props.values.map((body, index1) => {
              return (
                <Tr key={index1}>
                  {/* テーブルボディーのチェックボックス */}
                  <Td>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <UpIcon onClick={() => handleUpClick(index1)} />
                      <DownIcon onClick={() => handleDownClick(index1)} />
                    </div>
                  </Td>
                  <Td
                    key={`checkbox_${index1}`}
                    style={{ width: 35 }}
                    last_row={index1 + 1 === props.values.length ? true : false}
                  >
                    <CheckBoxArea>
                      <CheckBox checked={selected_indexes.includes(index1)} onClick={() => handleCheckClick(index1)} />
                    </CheckBoxArea>
                  </Td>
                  <Td key={`name_${index1}`} last_row={index1 + 1 === props.values.length ? true : false}>
                    <InputBox value={body.name} onChange={(e) => handleChange(e.currentTarget.value, index1, 'name')} />
                  </Td>
                  <Td key={`json_path_${index1}`} last_row={index1 + 1 === props.values.length ? true : false}>
                    <JsonpathInput
                      value={body.json_path}
                      onJsonpathChanged={(item: string) => handleChange(item, index1, 'json_path')}
                      placeholder='$から始めてください'
                      stream_id={body.stream_id}
                    />
                  </Td>
                  {stream_display ? (
                    <Td key={`stream_id_${index1}`} last_row={index1 + 1 === props.values.length ? true : false}>
                      <SelectBox
                        value={body.stream_id || ''}
                        datas={props.streams.map((s) => {
                          return { name: s.stream_name, value: s.stream_id };
                        })}
                        onChange={(e) => handleChange(e.currentTarget.value, index1, 'stream_id')}
                        long
                      />
                    </Td>
                  ) : null}
                  <Td
                    key={`statistic_method_${index1}`}
                    last_row={index1 + 1 === props.values.length ? true : false}
                    last_child
                  >
                    <SelectBox
                      value={body.statistic_method}
                      datas={statistic_methods}
                      onChange={(e) =>
                        handleChangeStatisticMethod(e.currentTarget.value as MetricStatisticMethod, index1)
                      }
                      long
                    />
                  </Td>
                  {props.isGraphType && (
                    <>
                      <Td key={`accumulation_${index1}`} last_row={index1 + 1 === props.values.length ? true : false}>
                        <SelectBox
                          value={body.accumulation || ''}
                          datas={AccumulationTypes.map((d) => {
                            return {
                              value: d,
                              name: d,
                            };
                          })}
                          onChange={(e) => handleChange(e.currentTarget.value, index1, 'accumulation')}
                          long
                        />
                      </Td>
                      <Td key={`stack_id_${index1}`} last_row={index1 + 1 === props.values.length ? true : false}>
                        <SelectBox
                          value={body.stack_id || ''}
                          default_text=''
                          default_text_value=''
                          datas={['1', '2', '3', '4', '5', '6', '7', '8', '9'].map((d) => {
                            return {
                              value: d,
                              name: d,
                            };
                          })}
                          onChange={(e) => handleChange(e.currentTarget.value, index1, 'stack_id')}
                          long
                        />
                      </Td>
                      <Td key={`graph_type_${index1}`} last_row={index1 + 1 === props.values.length ? true : false}>
                        <SelectBox
                          value={body.graph_type || ''}
                          datas={graph_types.map((d) => {
                            return {
                              value: d,
                              name: d,
                            };
                          })}
                          onChange={(e) => handleChange(e.currentTarget.value, index1, 'graph_type')}
                          long
                        />
                      </Td>
                    </>
                  )}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableArea>
      <CheckBoxWithText text='対象データを表示する' checked={stream_display} onClick={handleStreamDisplayCheck} />

      {/* フッター */}
      <FooterArea>
        <RoundedButton
          text='設定からやり直す'
          onClick={handleCreateClick}
          style={{
            width: styles.small_button_width,
            height: styles.super_small_button_height,
            marginRight: styles.interval_narrow_margin,
          }}
          is_white
        />
        <RoundedButton
          text='削除'
          onClick={handleDeleteRow}
          style={{
            width: styles.super_small_button_width,
            height: styles.super_small_button_height,
            marginRight: styles.interval_narrow_margin,
          }}
          is_white
        />
        <InputNumberBox value={add_row_number} onChange={onChangeAddRowNumber} style={{ width: 75 }} />
        行
        <RoundedButton
          text='追加'
          onClick={handleAddRow}
          style={{
            width: styles.super_small_button_width,
            height: styles.super_small_button_height,
          }}
        />
        {selected_indexes.length > 0 && (
          <>
            <RoundedButton
              text='上に追加'
              onClick={() => handleAddSelectedRowClick(add_row_number, selected_indexes)}
              small={true}
              is_margin_right={true}
              style={{
                marginLeft: styles.interval_x_narrow_margin,
              }}
            />
            <RoundedButton
              text='チェックした行削除'
              onClick={() => handleDeleteSelectedRowClick(selected_indexes)}
              small={true}
              is_margin_right={true}
              style={{
                marginLeft: styles.interval_x_narrow_margin,
              }}
            />
          </>
        )}
      </FooterArea>
    </WholeArea>
  );
};

const TopArea = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 2px;
`;

const QuestionPopoverWholeArea = styled(PopoverWholeArea)`
  width: 530px;
  height: 160px;
`;

export default GraphMetricsTable;
