import {
  isEvaluationPeriod,
  isNotOnlySpace,
  isAggregationPeriod,
  isJsonPath,
  isMailAddresses,
  isStayingTime,
} from 'shared/utils/is';
import {
  RequestChannelsIdEventConditionsChannelEventConditionNumberPut,
  RequestChannelsIdEventConditionsPost,
} from 'user/api/channelsEventConditions';
import { isStatisticMethod } from 'shared/models/StatisticMethod';
import {
  ChannelEventConEvaluateCondition,
  isChannelEventConEvaluateConditionOperator,
} from 'shared/models/channels/ChannelEventConEvaluateCondition';
import { MailAdress } from 'shared/models/MailAdress';
import { ExecTypeType } from 'shared/models/ExecTypeType';
import { ObservationModeType } from 'shared/models/ObservationModeType';
import { isBetweenRange, isValidNumber } from 'shared/utils/is';

export interface ChannelsEventConditionsPostValidatorProps {
  channel_id: string;
  channel_event_condition_name: string;
  condition_type: string;
  input_stream_id: string | null;
  evaluation_period: number;
  aggregation_period: number;
  json_path: string;
  statistic_method: string;
  evaluate_condition_operator: string;
  evaluate_condition_value1: number;
  evaluate_condition_value2: number;
  alarm_datapoints: number;
  is_timestamp: boolean;
  alert_mail_addresses?: MailAdress[];
  mail_stream_id: string | null | undefined;
  stream_id: string | null;
  periods_after: number;
  periods_before: number;
  exec_type?: ExecTypeType;
  observation_mode?: ObservationModeType;
  judge_timings: number[][];
  usecase_type?: string;
  stayareas: string;
  staying_time?: number;
  area_routing?: string;
}
/**
 * ChannelEventConditionで[POST]データをバリデーションして返却
 */
export const channelsEventConditionsPostValidatorPostValidator = (
  params: ChannelsEventConditionsPostValidatorProps,
): RequestChannelsIdEventConditionsPost => {
  const {
    channel_id,
    channel_event_condition_name,
    condition_type,
    input_stream_id,
    evaluation_period,
    aggregation_period,
    json_path,
    statistic_method,
    evaluate_condition_operator,
    evaluate_condition_value1,
    evaluate_condition_value2,
    alarm_datapoints,
    is_timestamp,
    alert_mail_addresses,
    mail_stream_id,
    stream_id,
    periods_after,
    periods_before,
    exec_type,
    observation_mode,
    judge_timings,
    usecase_type,
    stayareas,
    staying_time,
    area_routing,
  } = params;
  if (!isNotOnlySpace(channel_event_condition_name)) {
    throw new Error('イベント条件名は必須です');
  }
  if (!input_stream_id) {
    throw new Error('入力データは必須です');
  }

  let evaluate_condition_values: ChannelEventConEvaluateCondition['values'] | undefined = undefined;

  // 条件タイプでの分岐
  if (condition_type === 'GENERAL') {
    if (
      !isEvaluationPeriod({
        is_timestamp,
        evaluation_period,
      })
    ) {
      throw new Error(
        'データ番号の基準がTIMESTAMPの入力データの場合\n「アラート状態を決定するまでの評価期間(秒)」は必須です\n※1以上3600以下',
      );
    }

    if (
      !isAggregationPeriod({
        is_timestamp,
        evaluation_period,
        aggregation_period,
      })
    ) {
      throw new Error(
        'データ番号の基準がTIMESTAMPの入力データの場合\n「評価期間のデータポイントの集約間隔」は必須です\n※集約間隔は評価期間の約数',
      );
    }

    if (!isJsonPath(json_path)) {
      throw new Error('JSONパスは「$」から始める必要があります');
    }
    if (!isStatisticMethod(statistic_method)) {
      throw new Error('集計方法は必須です');
    }
    if (!isChannelEventConEvaluateConditionOperator(evaluate_condition_operator)) {
      throw new Error('評価符号は必須です');
    }
    try {
      // 閾値のバリデーション
      validateChannelEventConEvaluateConditionValues(
        evaluate_condition_operator,
        evaluate_condition_value1,
        evaluate_condition_value2,
      );
      // アラート状態と判断するデータポイント数のバリデーション
      validateAlarmDataPoints({ is_timestamp, alarm_datapoints, evaluation_period, aggregation_period });
      // チャンネルイベント条件アクション名用のpeiodsのバリデーション
      validatePeriodsForEventConditions({ stream_id, periods_after, periods_before });
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }
    }

    if (alert_mail_addresses && !isMailAddresses(alert_mail_addresses)) {
      throw new Error('メールアドレスが入力されているにもかかわらず\n無効のものがあります\n※空白の行は送信しません');
    }

    evaluate_condition_values = [+evaluate_condition_value1];
    if (evaluate_condition_operator === 'Between') {
      evaluate_condition_values.push(+evaluate_condition_value2);
    }
  } else if (condition_type === 'USECASE') {
    if (usecase_type === 'STAY_ALERT') {
      if (!stayareas) {
        throw new Error('滞留エリアの選択が必要です');
      }
      const is_check = staying_time !== undefined;
      if (
        !isStayingTime({
          is_check,
          staying_time,
        })
      ) {
        throw new Error('滞留時間の入力が必要です\n※1以上86400以下');
      }
    }
    if (condition_type === 'USECASE' && mail_stream_id === null) {
      throw new Error('画像の添付のデータが選択されていません');
    }
  } else {
    throw new Error('イベント条件の種類が選択されていません');
  }
  const request: RequestChannelsIdEventConditionsPost = {
    channel_id,
    channel_event_condition_name,
    condition_type,
    evaluate_condition_values,
    evaluation_period: String(evaluation_period),
    aggregation_period: String(aggregation_period),
    observation_mode,
    exec_type,
    input_stream_id,
    json_path,
    statistic_method,
    evaluate_condition_operator,
    alarm_datapoints: isValidNumber({ num: alarm_datapoints }) ? String(alarm_datapoints) : '',
    judge_timings: JSON.stringify(judge_timings),
    usecase_type: usecase_type || '',
    stayareas,
    staying_time: staying_time !== undefined ? String(staying_time) : '',
    area_routing,
  };
  return request;
};

export interface ChannelsEventConditionsIdPutValidatorProps {
  channel_id: string;
  channel_event_condition_name: string;
  condition_type: string;
  channel_event_condition_number: string;
  evaluation_period: number;
  aggregation_period: number;
  json_path: string;
  statistic_method: string;
  evaluate_condition_operator: string;
  evaluate_condition_value1: number;
  evaluate_condition_value2: number;
  alarm_datapoints: number;
  is_timestamp: boolean;
  stream_id: string | null;
  periods_after: number;
  periods_before: number;
  observation_mode?: ObservationModeType;
  judge_timings: number[][];
  usecase_type?: string;
  stayareas: string;
  staying_time?: number;
  area_routing?: string;
}

export const channelsEventConditionsIdPutValidator = (params: ChannelsEventConditionsIdPutValidatorProps) => {
  const {
    channel_id,
    channel_event_condition_name,
    condition_type,
    channel_event_condition_number,
    evaluation_period,
    aggregation_period,
    json_path,
    statistic_method,
    evaluate_condition_operator,
    evaluate_condition_value1,
    evaluate_condition_value2,
    alarm_datapoints,
    is_timestamp,
    stream_id,
    periods_after,
    periods_before,
    observation_mode,
    judge_timings,
    usecase_type,
    stayareas,
    staying_time,
    area_routing,
  } = params;
  if (!isNotOnlySpace(channel_event_condition_name)) {
    throw new Error('イベント条件名は必須です');
  }
  let evaluate_condition_values: ChannelEventConEvaluateCondition['values'] | undefined = undefined;
  // 条件タイプでの分岐
  if (condition_type === 'GENERAL') {
    if (
      !isEvaluationPeriod({
        is_timestamp,
        evaluation_period,
      })
    ) {
      throw new Error(
        'データ番号の基準がTIMESTAMPの入力データの場合\n「アラート状態を決定するまでの評価期間(秒)」は必須です\n※1以上3600以下',
      );
    }

    if (
      !isAggregationPeriod({
        is_timestamp,
        evaluation_period,
        aggregation_period,
      })
    ) {
      throw new Error(
        'データ番号の基準がTIMESTAMPの入力データの場合\n「評価期間のデータポイントの集約間隔」は必須ですは必須です\n※集約間隔は評価期間の約数',
      );
    }
    if (!isJsonPath(json_path)) {
      throw new Error('JSONパスは「$」から始める必要があります');
    }
    if (!isStatisticMethod(statistic_method)) {
      throw new Error('集計方法は必須です');
    }
    if (!isChannelEventConEvaluateConditionOperator(evaluate_condition_operator)) {
      throw new Error('評価符号は必須です');
    }
    try {
      // 閾値のバリデーション
      validateChannelEventConEvaluateConditionValues(
        evaluate_condition_operator,
        evaluate_condition_value1,
        evaluate_condition_value2,
      );
      // アラート状態と判断するデータポイント数のバリデーション
      validateAlarmDataPoints({ is_timestamp, alarm_datapoints, evaluation_period, aggregation_period });
      // チャンネルイベント条件アクション名用のpeiodsのバリデーション
      validatePeriodsForEventConditions({ stream_id, periods_after, periods_before });
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }
    }

    evaluate_condition_values = [+evaluate_condition_value1];
    if (evaluate_condition_operator === 'Between') {
      evaluate_condition_values.push(+evaluate_condition_value2);
    }
  } else if (condition_type === 'USECASE') {
    if (usecase_type === 'STAY_ALERT') {
      if (!stayareas) {
        throw new Error('滞留エリアの選択が必要です');
      }
      const is_check = staying_time !== undefined;
      if (
        !isStayingTime({
          is_check,
          staying_time,
        })
      ) {
        throw new Error('滞留時間の入力が必要です\n※1以上86400以下');
      }
    }
  } else {
    throw new Error('イベント条件の種類が選択されていません');
  }
  const request: RequestChannelsIdEventConditionsChannelEventConditionNumberPut = {
    channel_id,
    channel_event_condition_name,
    condition_type,
    channel_event_condition_number,
    evaluate_condition_values,
    evaluation_period: String(evaluation_period),
    aggregation_period: String(aggregation_period),
    observation_mode,
    json_path,
    statistic_method,
    evaluate_condition_operator,
    alarm_datapoints: isValidNumber({ num: alarm_datapoints }) ? String(alarm_datapoints) : '',
    judge_timings: JSON.stringify(judge_timings),
    usecase_type: usecase_type || '',
    stayareas,
    staying_time: staying_time !== undefined ? String(staying_time) : '',
    area_routing,
  };
  return request;
};

/***
 * 評価閾値をチェックする関数
 * ***/
export const validateChannelEventConEvaluateConditionValues = (
  evaluate_condition_operator: string,
  evaluate_condition_value1: string | number,
  evaluate_condition_value2: string | number,
) => {
  const ecv1: number = evaluate_condition_value1 === '' ? NaN : Number(evaluate_condition_value1);
  const ecv2: number = evaluate_condition_value2 === '' ? NaN : Number(evaluate_condition_value2);
  if (evaluate_condition_operator === 'Between') {
    if (
      isValidNumber({
        num: ecv1,
        positive: true,
      }) &&
      isValidNumber({
        num: ecv2,
        positive: true,
      }) &&
      ecv1 < ecv2
    ) {
      return true;
    }
    throw new Error(
      '評価符号が「範囲」の場合、評価しきい値は2入力が必須です\n※正の整数\n※(左のしきい値) ＜ (右のしきい値)',
    );
  }
  if (
    isValidNumber({
      num: ecv1,
      positive: true,
    })
  ) {
    return true;
  }
  throw new Error('評価しきい値は必須です\n*正の整数');
};

/***
 * アラート状態と判断するデータポイント数をチェックする
 * ***/
export const validateAlarmDataPoints = (p: {
  is_timestamp: boolean;
  alarm_datapoints: string | number;
  evaluation_period: string | number;
  aggregation_period: string | number;
}) => {
  const nad: number = p.alarm_datapoints === '' ? NaN : +p.alarm_datapoints;
  const nep: number = p.evaluation_period === '' ? NaN : +p.evaluation_period;
  const nap: number = p.aggregation_period === '' ? NaN : +p.aggregation_period;
  if (Number.isNaN(nap)) {
    throw new Error('「アラート状態と判断するデータポイント数」は必須です');
  }
  if (
    !isValidNumber({
      num: nad,
      positive: true,
    })
  ) {
    throw new Error('「アラート状態と判断するデータポイント数」は正の整数である必要があります');
  }
  if (p.is_timestamp && !(nad <= nep / nap)) {
    throw new Error(
      'データ番号の基準がTIMESTAMPの入力データの場合\n(アラート状態と判断するデータポイント数) ≦ ((評価期間) / (集約間隔))',
    );
  }
  return true;
};

/***
 * periods_before と periods_afterのそれぞれのチェック関数
 * ***/
export const isValidPeriods = (period: number): boolean => {
  return (
    isValidNumber({
      num: period,
    }) && isBetweenRange(period, 0, 3600)
  );
};

/***
 * チャンネルイベント条件アクション名用のpeiodsチェック関数
 *
 * ***/
const validatePeriodsForEventConditions = (params: {
  periods_before: number;
  periods_after: number;
  stream_id: string | null;
}): boolean => {
  // stream_idがnullの時は、省略
  if (params.stream_id === null) {
    return true;
  }
  // stream_idを入力する必要あり
  if (!isNotOnlySpace(params.stream_id)) {
    throw new Error('クリップビデオのデータを選択してください。');
  }
  if (!isValidPeriods(params.periods_after) || !isValidPeriods(params.periods_before)) {
    throw new Error('クリップ範囲を選択してください。\n*どちらも0分以上60分以下');
  }

  return true;
};
