import { useCallback, useEffect, useState } from 'react';
import Spinner from 'shared/components/atoms/Spinner';
import { Content } from 'shared/components/molecules/ContentsArea';
import RoundedButtonAllowDisabled from 'shared/components/molecules/RoundedAnchorButtonAllowDisabled';
import BaseTable from 'shared/components/molecules/Table/BaseTable';
import { TableHeaderType, TableBodyMultipleValueType } from 'shared/components/molecules/Table/type';
import styles from 'shared/styles/styles';
import { dateToYMDHMS } from 'shared/utils/converter/date';
import hasProcessOutputDownloadableData from './hasProcessOutputDownloadableData';
import { isProcessOutputDownloadable } from 'user/utils/isProcessOutputResultDownlodable';
import { Stream } from 'user/api/streams';
import { StreamData } from 'user/api/streamsData';
import {
  downloadProcessOutput,
  getProcessOutputDLText,
  getProcessOutputDLUrl,
} from 'user/utils/getProcessOutputDLData';
import { loadWrapperFunc } from 'user/utils/loadWrapperFunc';
import ResultsTableFooter from './ResultsTableFooter';
import { ProcessInfoOutput, streamsIdDataNumberProcessinfoGetAPI } from 'user/api/streamsProcessInfo';
import { streamsIdPackagesNumberGetAPI } from 'user/api/streamsPackage';

interface FileProcessesResultsProps {
  stream: Stream;
  count?: number;
  streamDataList?: StreamData[];
}

export type TableFileProcessesResultsType = {
  id: string;
  process_flow_name: string;
  output_name: string;
  file_name: string;
  stream_data_number: number;
  inferred_at: string;
  line_count: number | '';
  download_element: TableBodyMultipleValueType<string>;
  output: ProcessInfoOutput;
};

const headers: TableHeaderType[] = [
  {
    id: 'process_flow_name',
    label: 'プロセスフロー名',
    sortable: true,
    search_props: {
      type: 'name',
      default_display: true,
    },
  },
  {
    id: 'output_name',
    label: '出力名',
    sortable: true,
    search_props: {
      type: 'name',
      default_display: true,
    },
  },
  {
    id: 'file_name',
    label: 'ファイル名',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'stream_data_number',
    label: 'ストリームデータ番号',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'line_count',
    label: '出力CSV行数',
    sortable: true,
    search_props: {
      type: 'name',
    },
  },
  {
    id: 'inferred_at',
    label: '推論日時',
    sortable: true,
    search_props: {
      type: 'datetime',
      default_display: true,
    },
  },
  {
    id: 'download_element',
    label: 'ダウンロード',
    useTableButtonStyles: true,
    search_props: {
      type: 'name',
    },
  },
];

/**
 * フォルダチャンネルの推論結果一覧を表示するパネル
 */
const FileProcessesResults = (params: FileProcessesResultsProps) => {
  const [table_bodies, setTableBodies] = useState<TableFileProcessesResultsType[] | undefined>(undefined);
  const [selected_bodies, setSelectedBodies] = useState<TableFileProcessesResultsType[]>([]);

  /**
   *  table_bodiesをロードする
   * [TODO hase] 現在は各々のstream_dataに対して、process_infoを取得し、その中のoutputをテーブルに詰め込んでいる。
   * これを一元取得するAPIを作成する。(これによりテーブルのページング取得(200件ずつなど)が可能となる。)
   * */

  const loadTableBodies = useCallback(async () => {
    if (!params.streamDataList) return;
    const file_processes_results: TableFileProcessesResultsType[] = [];
    await Promise.all(
      params.streamDataList.map(async (streamData) => {
        const res = await streamsIdDataNumberProcessinfoGetAPI({
          stream_id: streamData.stream_id,
          stream_data_number: streamData.stream_data_number,
        });
        if (res.status === 200) {
          await Promise.all(
            res.data.processes.map(async (process) => {
              await Promise.all(
                process.outputs.map(async (output) => {
                  const download_element_value = getProcessOutputDLText({
                    output_type: output.output_type,
                    stream_data_number: output.stream_data_number,
                    stream_package_number: output.stream_package_number,
                  });
                  let line_count: number | undefined = undefined;
                  if (output['stream_package_number']) {
                    const stream_packege = await streamsIdPackagesNumberGetAPI({
                      stream_id: output.stream_id,
                      stream_package_number: String(output['stream_package_number']),
                    });
                    if (stream_packege.status == 200) {
                      line_count = stream_packege.data.line_count ? stream_packege.data.line_count : undefined;
                    }
                  }
                  file_processes_results.push({
                    id: `${output.stream_id}_${output.stream_data_number}_${output.stream_package_number}`,
                    process_flow_name: process.channel_process_name,
                    output_name: output.output_name || '',
                    file_name: streamData.original_file_name || '',
                    stream_data_number: streamData.stream_data_number,
                    line_count: line_count != null ? line_count : '',
                    inferred_at: dateToYMDHMS(process.created_at),
                    output: output,
                    download_element: {
                      value: download_element_value,
                      available_value: download_element_value,
                      displayable_value: (
                        <RoundedButtonAllowDisabled
                          is_table_cell_style={true}
                          stop_propagation={true}
                          disabled={!isProcessOutputDownloadable(output.downloadable)}
                          download_url={getProcessOutputDLUrl({
                            stream_id: output.stream_id,
                            original_file_name: streamData.original_file_name,
                            output_type: output.output_type,
                            stream_data_number: output.stream_data_number,
                            stream_package_number: output.stream_package_number,
                          })}
                          button_text={download_element_value}
                        />
                      ),
                    },
                  });
                }),
              );
            }),
          );
        }
      }),
    );
    setTableBodies(file_processes_results);
  }, [params.streamDataList]);

  /**
   * 選択されている推論結果を一括ダウンロードする関数
   * **/
  const onSelectedDataDownoad = useCallback(async () => {
    // ダウンロード関数定義
    const multipleDownload = async () => {
      await Promise.all(
        selected_bodies.map(async (sb) => {
          if (isProcessOutputDownloadable(sb.output.downloadable)) {
            return downloadProcessOutput({
              stream_id: sb.output.stream_id,
              original_file_name: sb.file_name,
              output_type: sb.output.output_type,
              stream_data_number: sb.output.stream_data_number,
              stream_package_number: sb.output.stream_package_number,
            });
          }
          return undefined;
        }),
      );
      await loadTableBodies();
      setSelectedBodies([]);
    };
    // 実行
    await loadWrapperFunc(multipleDownload);
  }, [selected_bodies, loadTableBodies]);

  /**
   * 推論結果を全て一括ダウンロードする関数
   * **/
  const onAllDownload = useCallback(async () => {
    if (typeof table_bodies === 'undefined') return;
    // ダウンロード関数定義
    const multipleDownload = async () => {
      await Promise.all(
        table_bodies.map((tb) => {
          if (isProcessOutputDownloadable(tb.output.downloadable)) {
            return downloadProcessOutput({
              stream_id: tb.output.stream_id,
              original_file_name: tb.file_name,
              output_type: tb.output.output_type,
              stream_data_number: tb.output.stream_data_number,
              stream_package_number: tb.output.stream_package_number,
            });
          }
          return undefined;
        }),
      );
      await loadTableBodies();
      setSelectedBodies([]);
    };
    // 実行
    await loadWrapperFunc(multipleDownload);
  }, [table_bodies, loadTableBodies]);

  // [TODO hase] 選択されている推論結果を削除するAPI作成
  // ただ、プロセスごと消去する方法が合理的であり、その操作はこのテーブルのUIと動きが乖離する。
  // データ概要のページでプロセスを削除するAPIを用意し利用したい。

  // マウント時のロード
  useEffect(() => {
    void (async function () {
      await loadTableBodies();
    })();
  }, [loadTableBodies]);

  return (
    <Content>
      {table_bodies ? (
        <>
          <BaseTable
            bodies={table_bodies}
            headers={headers}
            bodies_per_page={20}
            table_name='file_processes_results_table'
            selected_bodies={selected_bodies}
            handleCheckClick={setSelectedBodies}
            id_abridgement={true}
          />
          <ResultsTableFooter
            style={{ marginTop: styles.interval_narrow_margin }}
            onSelectedDataDownload={onSelectedDataDownoad}
            is_all_download_disabled={!hasProcessOutputDownloadableData(table_bodies)}
            is_selected_data_download_disabled={!hasProcessOutputDownloadableData(selected_bodies)}
            onAllDownload={onAllDownload}
          />
        </>
      ) : (
        <Spinner />
      )}
    </Content>
  );
};

export default FileProcessesResults;
