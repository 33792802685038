// -- basic library --
import React, { useCallback } from 'react';

import Spinner from 'shared/components/atoms/Spinner';
import { FlexCenterDiv } from 'shared/components/molecules/ContentsArea';
import { CartesianGragh, PolarGragh, PolarGraphData } from 'shared/components/molecules/PfGraph';

// -- external functions --
import styles from 'shared/styles/styles';
import { sumPolarGraphData } from 'shared/utils/else/sum';
import styled from 'styled-components';
import { DatapointItem } from 'user/api/streamsGraphs';
import CameraTableGraph from './CameraTableGraph';

// -- type declaration --

interface Params {
  type: 'polar' | 'table' | 'cartesian' | null;
  graph_data?: DatapointItem[] | PolarGraphData[];
}

// -- external functions --

// -- main component --
/** グラフそのもの。typeによって表示するグラフが変わる**/
const GraphPart = ({ graph_data, type }: Params) => {
  const [total, setTotal] = React.useState<number | undefined>(undefined);

  const loadTotal = useCallback(() => {
    if (type === 'polar' && graph_data) {
      setTotal(sumPolarGraphData(graph_data as PolarGraphData[]));
    } else {
      setTotal(undefined);
    }
  }, [graph_data, type]);

  // -- onload function --
  React.useEffect(() => {
    loadTotal();
  }, [loadTotal]);

  // -- render part --
  return (
    <FlexCenterDiv
      style={{ width: '100%', height: '100%', flexDirection: 'column' }}
      justufy_center={true}
      align_center={true}
    >
      {typeof total === 'number' && <StyledGraphTitle>合計：{total}人</StyledGraphTitle>}
      {graph_data && type !== null ? <VariousGraph type={type} graph_data={graph_data} /> : <Spinner />}
    </FlexCenterDiv>
  );
};

interface VariousGraphProps {
  type: 'polar' | 'table' | 'cartesian';
  graph_data: DatapointItem[] | PolarGraphData[];
}

/** さまざまなタイプに応じたグラフを表示する**/
const VariousGraph: React.FC<VariousGraphProps> = (params) => {
  // -- render part --
  switch (params.type) {
    case 'polar':
      return (
        <PolarGragh
          {...{
            data: params.graph_data as PolarGraphData[],
          }}
        />
      );
    case 'cartesian':
      return (
        <CartesianGragh
          {...{
            data: params.graph_data as DatapointItem[],
            symbolTypes: [
              {
                dataKey: 'units',
                name: '人数',
                type: 'BAR',
              },
            ],
            isTimestampTick: true,
          }}
        />
      );
    case 'table':
      return <CameraTableGraph graph_data={params.graph_data as DatapointItem[]} />;
    default:
      return <></>;
  }
};

// -- styled components --

const StyledGraphTitle = styled.div`
  display: flex;
  align-items: center;
  justify-conten: center;
  margin-bottom: ${styles.interval_margin};
`;

// -- finally export part --
export default GraphPart;
