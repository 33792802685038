import { DatapointItem } from 'user/api/streamsGraphs';
import { StreamTableGraphType } from './CameraTableGraph';
import { dateToYMDHM } from 'shared/utils/converter/date';

/** DatapointItem[] -> StreamTableGraphType[] **/
const getStreamGraphCsvs = (items: DatapointItem[]) => {
  const result: StreamTableGraphType[] = [];
  for (const item of items) {
    const { datapoint, ...pi } = item;
    let LR = 0;
    let RL = 0;
    let max_jsonpath = '';
    let max_value = 0;
    for (const [k, v] of Object.entries(pi)) {
      if (k.indexOf('_LR_') > -1) {
        LR += v;
      } else if (k.indexOf('_RL_') > -1) {
        RL += v;
      }
      if (max_value < v) {
        max_jsonpath = k;
        max_value = v;
      }
    }
    const graph_data = getGraphDataFromJsonpath(max_jsonpath);
    const dt = new Date(datapoint * 1000);
    const max_name = getMaxName(graph_data);
    result.push({
      time: dateToYMDHM(dt),
      max_name: max_name,
      LR: LR,
      RL: RL,
    });
  }
  return result;
};

/** jsonpathから、graph_dataび要素を取得する**/
export const getGraphDataFromJsonpath = (jsonpath: string) => {
  const pattern = 'LINE([0-9])_(LR|RL)_(male|female)_([0-9]+_[0-9]+)';
  const correct = new RegExp(pattern, 'g');
  const result = [...jsonpath.matchAll(correct)];
  const graph_data = {
    line_number: -1,
    direction: '',
    gender: '',
    generation: '',
  };
  if (result.length > 0) {
    const data = result[0];
    for (let i = 0; i < 5; i++) {
      if (!data[i]) continue;
      if (i === 1) {
        graph_data.line_number = Number(data[i]);
      } else if (i === 2) {
        graph_data.direction = data[i];
      } else if (i === 3) {
        graph_data.gender = data[i];
      } else if (i === 4) {
        graph_data.generation = data[i];
      }
    }
  }
  return graph_data;
};

const getMaxName = (graph_data: { line_number: number; direction: string; gender: string; generation: string }) => {
  let max_name = '';
  if (
    ['LR', 'RL'].includes(graph_data.direction) &&
    ['male', 'female'].includes(graph_data.gender) &&
    !!graph_data.generation
  ) {
    max_name = `${graph_data.direction}_${graph_data.gender}_${graph_data.generation}`;
  }
  return max_name;
};

export default getStreamGraphCsvs;
