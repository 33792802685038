// -- basic library --
import React from 'react';
import RoundedAnchorButton from 'shared/components/atoms/RoundedAnchorButton';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import { table_cell_button_style } from 'shared/styles/styles';

// -- type declaration --
interface RoundedButtonAllowDisabledProps {
  disabled?: boolean;
  button_text: string;
  download_url: string;
  style?: React.CSSProperties;
  is_table_cell_style?: boolean;
  stop_propagation?: boolean;
}

// -- main component --

/***
 * disabledを許可する丸みを帯びたアンカーボタン
 * ***/
const RoundedButtonAllowDisabled = (props: RoundedButtonAllowDisabledProps) => {
  // style
  let new_style: React.CSSProperties = { ...props.style };
  if (props.is_table_cell_style) {
    new_style = {
      ...new_style,
      fontSize: '0.8em',
      ...table_cell_button_style,
    };
  }

  // -- render part --
  return props.disabled ? (
    <RoundedButton
      text={props.button_text}
      disabled={true}
      style={new_style}
      stop_propagation={props.stop_propagation}
    />
  ) : (
    <RoundedAnchorButton
      text={props.button_text}
      href={props.download_url}
      style={new_style}
      stop_propagation={props.stop_propagation}
    />
  );
};

// -- finally export part --

export default RoundedButtonAllowDisabled;
