// -- basic library --
import React from 'react';
import PfDialog from 'shared/components/atoms/PfDialog';
import { PolarGraphData } from 'shared/components/molecules/PfGraph';
import { WidgetScaleType } from 'shared/models/WidgetScaleType';
import FeatureDatetime from 'shared/models/FeatureDatetime';
import { getStreamGraphTitle } from 'shared/models/StreamDeviceGraph';
import { Device } from 'user/api/device';
import { Stream } from 'user/api/streams';
import { DatapointItem } from 'user/api/streamsGraphs';
import CameraAnalysisResultPart from 'user/pages/Devices/DetailPage/CameraAnalysisResultPage/CameraAnalysisResultPart';
import { StreamDeviceGraph } from 'shared/models/StreamDeviceGraph';
// -- types --
interface MetricResultDialogProps {
  isOpen: boolean;
  onClose: (isCanceled: boolean) => void;
  stream_graph: StreamDeviceGraph;
  stream: Stream;
  device: Device;
  start: FeatureDatetime;
  end: FeatureDatetime;
  scale: WidgetScaleType;
  setStart: (start: FeatureDatetime) => void;
  setEnd: (end: FeatureDatetime) => void;
  setScale: (scale: WidgetScaleType) => void;
  loadGraphData: (p: { scale?: WidgetScaleType; start?: FeatureDatetime; end?: FeatureDatetime }) => Promise<void>;
  graph_data?: DatapointItem[] | PolarGraphData[];
}

// -- main component --
const MetricResultDialog: React.FC<MetricResultDialogProps> = (params) => {
  const ih = Math.floor(window.innerHeight * 0.85);

  // -- render part --
  return (
    <PfDialog
      isOpen={params.isOpen}
      title={getStreamGraphTitle(params.stream_graph)}
      onClose={() => params.onClose(true)}
      style={{ height: ih + 'px' }}
    >
      <CameraAnalysisResultPart {...params} />
    </PfDialog>
  );
};

// -- finally export part --

export default MetricResultDialog;
