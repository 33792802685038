import React from 'react';
import { colors } from 'shared/styles/colors';
import styles from 'shared/styles/styles';
import styled from 'styled-components';

/**
 * グレー色の丸みの少ないキューブ型のボタン
 */
interface GrayCubeProps extends React.HTMLAttributes<HTMLDivElement> {
  text: string;
  selected?: boolean;
}
export default function GrayCube(props: GrayCubeProps) {
  return <CubeArea {...props}>{props.text}</CubeArea>;
}

// -- styled components --

const CubeArea = styled.div<{
  selected?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 25px;
  border-radius: 6px;
  background-color: ${(params) => (params.selected ? colors.main_font_color : colors.white)};
  color: ${(params) => (params.selected ? colors.white : colors.main_font_color)};
  border: 1px solid ${colors.main_font_color};
  font-family: inherit;
  font-weight: ${styles.font_weight};
`;
