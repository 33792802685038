// -- basic library --
import React, { useState } from 'react';

// -- http connection library --

// -- external components --
import InputBox from 'shared/components/atoms/InputBox';
import PfDialog from 'shared/components/atoms/PfDialog';
import RoundedButton from 'shared/components/atoms/RoundedButton';
import AlertDialog from 'shared/components/molecules/AlertDialog';
import { Footer } from 'shared/components/molecules/ContentsArea';
import InputComponent from 'shared/components/molecules/InputComponent';
import { dashboardTypeToJp } from 'shared/models/DashboardType';
import { isTableauDataType, tableauDataTypeToJp } from 'shared/models/TableauDataType';
import { isNotOnlySpace } from 'shared/utils/is';
import { Dashboard, dashboardsIdPutAPI, RequestDashboardsIdPut } from 'user/api/dashboards';

// -- external datas --

// -- external functions --

// -- types --
interface Params {
  isOpen: boolean;
  onClose: (isCanceled: boolean) => void;
  dashboard: Dashboard;
}

// -- main component --
const EditDashboardDialog: React.FC<Params> = ({ dashboard, isOpen, onClose }) => {
  // -- local states --
  const [dashboard_name, setDashboardName] = useState<string>(dashboard.dashboard_name ?? '');
  const use_tableau_dashboard = dashboard.dashboard_type === 'tableau';

  const handleFinishClick = async (inputs: { dashboard_name: string; dashboard_id: string }) => {
    const { dashboard_name, dashboard_id } = inputs;
    if (!isNotOnlySpace(dashboard_name)) {
      AlertDialog.show('ダッシュボード名は必須です');
      return;
    }

    const dashboards_put_data: RequestDashboardsIdPut = {
      dashboard_name,
      dashboard_id,
    };
    const res = await dashboardsIdPutAPI(dashboards_put_data);
    if (res.status === 200) {
      AlertDialog.show('ダッシュボードの更新に成功しました');
      onClose(false);
    }
  };

  // -- render part --
  return (
    <PfDialog isOpen={isOpen} title='ダッシュボード詳細' onClose={() => onClose(true)}>
      <InputComponent text='ダッシュボード名' required>
        <InputBox
          title='ダッシュボード名'
          placeholder='入力してください(必須)'
          value={dashboard_name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDashboardName(e.currentTarget.value)}
        />
      </InputComponent>
      <InputComponent text='ダッシュボードタイプ'>
        <div>{dashboardTypeToJp(dashboard.dashboard_type)}</div>
      </InputComponent>
      {use_tableau_dashboard && isTableauDataType(dashboard.tableau_data_type) && (
        <InputComponent text='Tableauデータタイプ'>
          <div>{tableauDataTypeToJp(dashboard.tableau_data_type)}</div>
        </InputComponent>
      )}

      <Footer>
        <RoundedButton
          onClick={() =>
            handleFinishClick({
              dashboard_name: dashboard_name,
              dashboard_id: dashboard.dashboard_id,
            })
          }
          text_type='UPDATE'
        />
      </Footer>
    </PfDialog>
  );
};

// -- finally export part --

export default EditDashboardDialog;
